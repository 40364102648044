<template>
  <!-- Internal router link -->
  <RouterLink
    v-if="route"
    class="app-link"
    :class="{ disabled }"
    :to="route"
    :replace="replace"
    @click.native="onPress"
  >
    <Txt v-if="text" :value="text" :size="size" :theme="theme" :type="txtType" v-bind="$attrs">
      <template v-for="(slot, name) in _omit($slots, ['default'])" :slot="name">
        <slot :name="name" />
      </template>
    </Txt>
    <slot v-else />
  </RouterLink>
  <!-- External link -->
  <a
    v-else-if="href"
    class="app-link"
    :class="{ disabled }"
    :href="href"
    :target="target"
    :rel="rel"
    @click="onPress"
  >
    <Txt v-if="text" :value="text" :size="size" :theme="theme" :type="txtType" v-bind="$attrs">
      <template v-for="(slot, name) in _omit($slots, ['default'])" :slot="name">
        <slot :name="name" />
      </template>
    </Txt>
    <slot v-else />
  </a>
  <!-- Classic button if no link (external or internal) specified -->
  <a v-else class="app-link" :class="{ disabled }" role="button" @click="onPress">
    <Txt v-if="text" :value="text" :size="size" :theme="theme" :type="txtType" v-bind="$attrs">
      <template v-for="(slot, name) in _omit($slots, ['default'])" :slot="name">
        <slot :name="name" />
      </template>
    </Txt>
    <slot v-else />
  </a>
</template>

<script>
  import _omit from 'lodash/omit'
  import Txt from '@/core/text/Txt/Txt'

  const Type = {
    ROUTER: 'router',
    EXTERNAL: 'external',
    VOID: 'void',
  }

  const { Size, Theme, Type: TxtType, Align } = Txt

  export const AppLinkType = Type
  export const AppLinkSize = Size

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,Theme,Type,TxtType,Align,
    name: 'AppLink',
    __enums: {
      Size,
      Theme,
      Type,
      TxtType,
      Align,
    },
    components: {
      Txt,
    },
    props: {
      /**
       * Type / behavior of the link
       * One of : "router", "external", "void"
       */
      type: {
        type: String,
        default: 'router',
      },
      /**
       * Destination link
       */
      to: {
        type: [Object, String],
        default: null,
      },
      /**
       * If 'true', the link is opened in another browser page
       * (i.e _blank)
       */
      blank: {
        type: Boolean,
        default: false,
      },
      /**
       * Setting replace prop will call router.replace() instead of
       * router.push() when clicked
       */
      replace: {
        type: Boolean,
        default: false,
      },
      /**
       * Deactive the link, if "true"
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Text to display in the link
       */
      text: {
        type: String,
        default: null,
      },
      /**
       * Size of the text to display used in the props text
       */
      size: {
        type: String,
        default: Size.S,
      },
      /**
       * Theme of the text to display used in the props text
       */
      theme: {
        type: String,
        default: Theme.DEFAULT,
      },
      /**
       * Type of the text to display used in the props text
       */
      txtType: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * To attribute of the <router-link> tag
       * @type {String}
       */
      route() {
        const { type, to, disabled, $router } = this

        return (!type || type === 'router') && to && !disabled && $router ? to : undefined
      },
      /**
       * Href attribute of the <a> tag
       * @type {String}
       */
      href() {
        const { type, to, disabled } = this

        return type === 'external' && to && !disabled ? to : undefined
      },
      /**
       * Target attribute of the <a> tag
       * @type {String}
       */
      target() {
        const { type, blank } = this

        return type === 'external' && blank ? '_blank' : undefined
      },
      /**
       * Rel attribute of the <a> tag
       * @type {String}
       */
      rel() {
        const { type, blank } = this

        return type === 'external' && blank ? 'noopener noreferrer' : undefined
      },
    },
    methods: {
      _omit,
      /**
       * Fired when the link is clicked
       * @returns {void}
       */
      onPress(event) {
        const { disabled } = this

        if (!disabled) {
          this.$emit('click', event)
        }

        event.stopPropagation()
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .app-link[href] {
    cursor: pointer
  }

  .app-link[role=button] {
    cursor: pointer
  }

  .app-link.disabled {
    cursor: default
  }
</style>
