<template>
  <!-- Internal router link -->
  <RouterLink
    v-if="route"
    :to="route"
    :replace="replace"
    :class="classes"
    @click.native="onPress"
  >
    <slot />
  </RouterLink>

  <!-- External link -->
  <a
    v-else-if="href"
    :href="href"
    :target="target"
    :rel="rel"
    :class="classes"
    @click="onPress"
  >
    <slot />
  </a>

  <!-- Classic button if no link (external or internal) specified -->
  <a
    v-else
    role="button"
    :class="classes"
    @click="onPress"
  >
    <slot />
  </a>
</template>

<script>
  const Type = {
    ROUTER: 'router',
    EXTERNAL: 'external',
    VOID: 'void',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Type,
    name: 'CLink',
    __enums: {
      Type,
    },
    props: {
      /**
       * Type / behavior of the link
       * One of : "router", "external", "void"
       */
      type: {
        type: String,
        default: Type.ROUTER,
      },
      /**
       * Destination link
       */
      to: {
        type: [Object, String],
        default: null,
      },
      /**
       * If 'true', the link is opened in another browser page
       * (i.e _blank)
       */
      blank: {
        type: Boolean,
        default: false,
      },
      /**
       * Setting replace prop will call router.replace() instead of
       * router.push() when clicked
       */
      replace: {
        type: Boolean,
        default: false,
      },
      /**
       * Deactive the link, if "true"
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { disabled, $style } = this

        return {
          [$style.root]: true,
          [$style.disabled]: !!disabled,
        }
      },
      /**
       * To attribute of the <router-link> tag
       * @type {String}
       */
      route() {
        const { type, to, disabled, $router } = this

        return (!type || type === Type.ROUTER) && to && !disabled && $router
          ? to
          : undefined
      },
      /**
       * Href attribute of the <a> tag
       * @type {String}
       */
      href() {
        const { type, to, disabled } = this

        return type === Type.EXTERNAL && to && !disabled
          ? to
          : undefined
      },
      /**
       * Target attribute of the <a> tag
       * @type {String}
       */
      target() {
        const { type, blank } = this

        return type === Type.EXTERNAL && blank
          ? '_blank'
          : undefined
      },
      /**
       * Rel attribute of the <a> tag
       * @type {String}
       */
      rel() {
        const { type, blank } = this

        return type === Type.EXTERNAL && blank
          ? 'noopener noreferrer'
          : undefined
      },
    },
    methods: {
      /**
       * Fired when the link is clicked
       * @returns {void}
       */
      onPress(event) {
        const { disabled } = this

        if (!disabled) {
          this.$emit('click', event)
        }

        event.stopPropagation()
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    &[href],
    &[role=button] {
      cursor: pointer
    }

    &.disabled {
      cursor: default
    }
  }
</style>
