<template>
  <div class="nav-bar">
    <div v-if="$slots['left-buttons']" :class="$('left-buttons')">
      <slot name="left-buttons" />
    </div>

    <DropdownMenu
      v-if="!$mq.desktopUp && menu"
      icon="icon-drag-drop"
      :menu="menu"
      :align="DropdownMenu.Align.LEFT"
      :class="$('mobile-menu')"
    />

    <AppLink v-if="logo" :to="logo.to" :type="logo.type" @click="onLogoPress">
      <img
        :class="!$mq.desktopUp ? $('mobile-logo') : $('logo')"
        :src="
          !$mq.desktopUp
            ? require('../../../assets/images/app/icon-small.svg')
            : require('../../../assets/images/app/logo-light.svg')
        "
      />
    </AppLink>

    <TabsMenu
      v-if="$mq.desktopUp && menuOptions"
      data-cy-navbar
      :options="menuOptions"
      :justify="justify"
      :wrap="false"
    />

    <Heading v-else-if="title" :level="Heading.Level.LEVEL4" :text="title" />

    <slot v-else name="menu" />

    <div :class="$('buttons')">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
  import AppLink from '@/core/controls/AppLink/AppLink'
  import DropdownMenu from '@/core/controls/DropdownMenu/DropdownMenu'
  import Heading from '@/core/text/Heading/Heading'
  import RouterMixin from '@/mixins/RouterMixin'
  import TabsMenu from '@/core/layout/TabsMenu/TabsMenu'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  DropdownMenu: DropdownMenu.__enums,
  Heading: Heading.__enums,
  TabsMenu: TabsMenu.__enums,
},

    name: 'NavBar',
    components: {
      AppLink,
      DropdownMenu,
      Heading,
      TabsMenu,
    },
    mixins: [RouterMixin],
    props: {
      /**
       * Props passed to the Comet logo (to, track, ...)
       */
      logo: {
        type: Object,
        default: null,
      },
      /**
       * Array describing the menu entries (name + path)
       */
      menu: {
        type: Array,
        default: null,
      },
      /**
       *  TabsMenu's horizontal alignment (left, right, center)
       */
      justify: {
        type: String,
        default: TabsMenu.LEFT,
      },
      /**
       * Title to display at the center of the navBar, cannot be displayed
       * at the same time as the menu
       */
      title: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * Computed menu entries (with css classes)
       * @type {Array.<Object>}
       */
      menuOptions() {
        const { menu, route } = this

        return menu
          ? menu.map(option => ({
              to: option.path,
              label: option.name,
              sup: option.sup,
              badge: option.badge,
              badgeTheme: option.badgeTheme,
              disabled: !!option.disabled,
              icon: option.icon,
              track: option.track,
              type: option.type,
              classes: {
                highlight: !!option.highlight,
                // The option is active if the current path
                // is exactly the path or a subsection within the path
                active:
                  route.path.indexOf(option.path) === 0 ||
                  route.path.startsWith(`${option.path}/`) ||
                  route.matched[route.matched.length - 1].path.indexOf(option.path) === 0,
                disabled: !!option.disabled,
              },
            }))
          : null
      },
    },
    methods: {
      /**
       * Fired when the main logo is pressed
       * @returns {void}
       */
      onLogoPress(event) {
        this.$emit('logo-click', event)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_grid.styl'
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_variables.styl'

  .nav-bar {
    box-sizing: border-box
    width: 100%
    background-color: var(--color-background)
    display: flex
    height: $app-navbar-height
    min-height: $app-navbar-height
    max-height: $app-navbar-height
    flex-direction: row
    flex-flow: row nowrap
    align-items: center
    justify-content: center
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), inset 0 -2px var(--color-border)
    z-index: 10
    padding: 0 16px


    @media desktop-up {
      padding: 0 24px
    }

    &-logo {
      height: 24px
    }

    &-mobile-logo {
      width: 40px
      height: 40px
      max-width: inherit
      margin-right: 10px
    }

    &-mobile-menu {
      min-width: 40px

      >>> .icon-drag-drop {
        font-size: 40px
      }
    }

    .tabs-menu {
      display: none

      @media desktop-up {
        display: flex
        flex: 1
        margin-left: 40px
      }
    }

    .heading {
      display: none

      @media desktop-up {
        display: block
        margin: 0 auto
      }
    }

    .app-link {
      margin-right: auto

      @media desktop-up {
        margin-right: 0px
      }
    }

    &-left-buttons {
      margin-right: 24px
    }

    &-buttons {
      display: flex

      & > * {
        margin-left: 24px

        @media phone-tablet {
          margin-left: 16px
        }

        &:first-child {
          margin-left: 0
        }
      }
    }
  }
</style>
