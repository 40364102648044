<template>
  <div class="d-flex flex-row flex-nowrap">
    <template v-for="button in visibleButtons">
      <slot
        v-if="button.slot && $slots[button.slot]"
        :name="button.slot"
      />

      <CModalConfirm
        v-else
        :key="button.id"
        :active="!!button.secure"
        :color="button.color"
        :title="button.secureTitle"
        :body="button.secureBody"
        :confirm-label="button.secureConfirmLabel"
        :cancel-label="button.secureCancelLabel"
        @confirm="e => button.onPress && button.onPress(e, item)"
      >
        <template v-slot:activator="{ disabled, on: onConfirm }">
          <VTooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <VBtn
                v-bind="_pick(button, ['to', 'href', 'target'])"
                :outlined="precedence(button.outlined, outlined)"
                :x-small="precedence(button.xSmall, xSmall)"
                :small="precedence(button.small, small)"
                :large="precedence(button.large, large)"
                :x-large="precedence(button.xLarge, xLarge)"
                :color="precedence(button.color, 'secondary')"
                :disabled="button.disabled || disabled"
                class="ml-2 mb-2"
                @click="e => !button.secure && button.onPress && button.onPress(e, button)"
                v-on="{ ...onTooltip, ...onConfirm }"
              >
                <VIcon
                  v-if="button.icon"
                  v-bind="{ xSmall, small, large, xLarge, ...iconProps, ...button.iconProps }"
                  :color="(
                    precedence(button.outlined, outlined)
                      ? button.color
                      : undefined
                  )"
                  v-text="button.icon"
                />

                {{ button.label }}
              </VBtn>
            </template>

            <span v-html="button.tooltip" />
          </VTooltip>
        </template>
      </CModalConfirm>
    </template>

    <VMenu
      v-if="moreButton"
      v-model="expanded"
      bottom
      :auto="!list"
      :left="list"
      offset-y
      :nudge-bottom="8"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on: onMenu }">
        <VTooltip bottom>
          <template v-slot:activator="{ on: onTooltip }">
            <VBtn
              v-model="expanded"
              v-bind="_omit(moreButton, ['icon'])"
              class="ml-2 mb-2"
              v-on="{ ...onTooltip, ...onMenu }"
            >
              <VIcon
                color="secondary"
                v-bind="_pick(moreButton, ['xSmall', 'small', 'large', 'xLarge'])"
                v-text="moreButton.icon"
              />
            </VBtn>
          </template>

          <span v-text="__('cp:c-button-group:more')" />
        </VTooltip>
      </template>

      <VList
        v-if="list"
      >
        <template v-for="item in hiddenButtons">
          <slot
            v-if="item.slot && $slots[item.slot]"
            :name="item.slot"
          />
          <CModalConfirm
            v-else
            :key="item.id"
            :active="!!item.secure"
            :color="item.color"
            :title="item.secureTitle"
            :body="item.secureBody"
            :confirm-label="item.secureConfirmLabel"
            :cancel-label="item.secureCancelLabel"
            @confirm="e => item.onPress && item.onPress(e, item)"
          >
            <template v-slot:activator="{ disabled, on }">
              <VListItem
                v-bind="_pick(item, ['to', 'href', 'target'])"
                :disabled="item.disabled || disabled"
                :dense="xSmall || small"
                :color="item.color"
                @click="e => !item.secure && item.onPress && item.onPress(e, item)"
                v-on="on"
              >
                <VListItemIcon
                  v-if="item.icon"
                  class="justify-center"
                >
                  <VIcon
                    v-bind="{ xSmall, small, large, xLarge, ...iconProps, ...item.iconProps }"
                    :color="item.color"
                    v-text="item.icon"
                  />
                </VListItemIcon>

                <VListItemTitle
                  :class="(
                    item.color &&
                    item.color !== 'secondary' &&
                    (item.color.split(' ').length === 1
                      ? `${item.color.split(' ')[0]}--text`
                      : `${item.color.split(' ')[0]}--text text--${item.color.split(' ')[1]}`
                    )
                  )"
                  v-html="item.label || item.tooltip"
                />
              </VListItem>
            </template>
          </CModalConfirm>
        </template>
      </VList>

      <VList
        v-else
      >
        <template v-for="button in hiddenButtons">
          <VListItem
            :key="button.id"
            :dense="xSmall || small"
          >
            <slot
              v-if="button.slot && $slots[button.slot]"
              :name="button.slot"
            />

            <CModalConfirm
              v-else
              :key="button.id"
              :active="!!button.secure"
              :color="button.color"
              :title="button.secureTitle"
              :body="button.secureBody"
              :confirm-label="button.secureConfirmLabel"
              :cancel-label="button.secureCancelLabel"
              @confirm="e => button.onPress && button.onPress(e, item)"
            >
              <template v-slot:activator="{ disabled, on: onConfirm }">
                <VTooltip left>
                  <template v-slot:activator="{ on: onTooltip }">
                    <VBtn
                      v-bind="_pick(button, ['to', 'href', 'target'])"
                      :outlined="precedence(button.outlined, outlined)"
                      :x-small="precedence(button.xSmall, xSmall)"
                      :small="precedence(button.small, small)"
                      :large="precedence(button.large, large)"
                      :x-large="precedence(button.xLarge, xLarge)"
                      :color="precedence(button.color, 'secondary')"
                      :disabled="button.disabled || disabled"
                      class="my-1"
                      @click="e => !button.secure && button.onPress && button.onPress(e, button)"
                      v-on="{ ...onTooltip, ...onConfirm }"
                    >
                      <VIcon
                        v-if="button.icon"
                        v-bind="{ xSmall, small, large, xLarge, ...iconProps, ...button.iconProps }"
                        :color="button.color"
                        v-text="button.icon"
                      />

                      {{ button.label }}
                    </VBtn>
                  </template>

                  <span v-html="button.tooltip" />
                </VTooltip>
              </template>
            </CModalConfirm>
          </VListItem>
        </template>
      </VList>
    </VMenu>
  </div>
</template>

<script>
  import _omit from 'lodash/omit'
  import _pick from 'lodash/pick'

  import { precedence } from '@comet/utils'

  export default {
    name: 'CButtonGroup',
    props: {
      /**
       * Properties transmitted for each button
       * @type {Array.<Object>}
       */
      items: {
        type: Array,
        required: true,
      },
      /**
       * Number of items that are always visible. The ohters are stored in a
       * dropdown menu on the left side.
       */
      max: {
        type: Number,
        default: 3,
      },
      /**
       * Display the remaining buttons due to the "max" limit as a list
       * in the dropdown menu, instead of buttons.
       */
      list: {
        type: Boolean,
        default: false,
      },
      /**
       * Makes the background transparent and applies a thin border
       */
      outlined: {
        type: Boolean,
        default: false,
      },
      /**
       * Removes the button box shadow
       */
      depressed: {
        type: Boolean,
        default: false,
      },
      /**
       * Display buttons with an icon-only style (rounded)
       */
      icon: {
        type: Boolean,
        default: false,
      },
      /**
       * Custom sizes applied to the avatar
       */
      xSmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      xLarge: {
        type: Boolean,
        default: false,
      },
      /**
       * Custom properties straighly passed to the VIcon component
       * @type {Object}
       */
      iconProps: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        /**
         * Is set to "true", when the dropdown displaying more actions is expanded.
         * @type {Boolean}
         */
        expanded: false,
      }
    },
    computed: {
      /**
       * List of buttons that must be always shown (not stucked in an expandable menu)
       * @type {Array.<Object>}
       */
      visibleButtons() {
        const { items, max } = this
        const buttons = items.filter(i => i.id !== '$more')

        return buttons.slice(0, max)
      },
      /**
       * List of buttons that must be always shown (not stucked in an expandable menu)
       * @type {Array.<Object>}
       */
      hiddenButtons() {
        const { items, max } = this
        const buttons = items.filter(i => i.id !== '$more')

        return buttons.slice(max)
      },
      /**
       * Properties of the "more" button, displayed on a the left if there is more
       * actions specified than the visibility limit.
       * @type {Object}
       */
      moreButton() {
        const { items, max, outlined, xSmall, small, large, xLarge, expanded } = this

        if (items.length <= max) {
          return null
        }

        const button = items?.$more || {}

        return {
          outlined: button.outlined || outlined,
          xSmall: button.xSmall || xSmall,
          small: button.small || small,
          large: button.large || large,
          xLarge: button.xLarge || xLarge,
          color: button.color || 'secondary',
          icon: expanded
            ? 'mdi-close'
            : 'mdi-dots-vertical',
          ...button,
        }
      },
    },
    methods: {
      _omit,
      _pick,
      precedence,
    },
  }
</script>
