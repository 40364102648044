<template>
  <div :class="['star-rating', classes]">
    <i v-for="index in max" :key="index" :class="icon(index)" />
  </div>
</template>

<script>
  const Theme = {
    DEFAULT: 'default',
    BRAND: 'brand',
    GREY: 'grey',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Theme,
    name: 'StarRating',
    __enums: {
      Theme,
    },
    props: {
      /**
       * Rating value
       */
      value: {
        type: Number,
        required: true,
      },
      /**
       * Max number of stars
       */
      max: {
        type: Number,
        default: 5,
      },
      /**
       * Theme :
       * "default", "brand", "grey"
       * @type {StarRating.Theme}
       */
      theme: {
        type: String,
        default: Theme.DEFAULT,
      },
    },
    computed: {
      /**
       * CSS classes applied on root
       * @type {Object.<Boolean>}
       */
      classes() {
        const { theme } = this

        return {
          [`star-rating--theme-${theme}`]: !!theme,
        }
      },
    },
    methods: {
      /**
       * Star icon depending on rating value (empty, half or full)
       * @param {Number}
       * @returns {String}
       */
      icon(index) {
        const { value } = this

        // Round to nearest value (0.5)
        const roundedValue = Math.round(value * 2) / 2

        // eslint-disable-next-line no-nested-ternary
        const type =
          index <= roundedValue ? 'full' : index - roundedValue === 0.5 ? 'half' : 'empty'

        return `icon-star-${type}`
      },
    },
  }
</script>

<style lang="stylus" scoped>


  .star-rating {
    text-align: center

    i {
      margin: 2px
    }

    &&--theme-default {
      color: var(--color-font)
    }

    &&--theme-brand {
      color: var(--color-brand)
    }

    &&--theme-grey {
      color: var(--color-font-light)
    }
  }
</style>
