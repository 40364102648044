
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FreelancePermissions"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"permissions"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showProfile"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showMissions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showStore"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showInfos"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showExperiences"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showQualification"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showInstructor"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"askForQualification"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showPreferences"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"showCompany"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":256}};
    doc.loc.source = {"body":"fragment FreelancePermissions on User {\n  id\n  permissions {\n    id\n    showProfile\n    showMissions\n    showStore\n    showInfos\n    showExperiences\n    showQualification\n    showInstructor\n    askForQualification\n    showPreferences\n    showCompany\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
