/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */

import hash from 'object-hash'
import _debounce from 'lodash/debounce'

import { isEqualObject } from '@/utils/transform/object'
import AppTypes from '@/types/app'

import { unsubscribeWatchers } from './unwatch'

/**
 * Private method used to update submission metadata as 'submitting', ...
 * @param {Boolean} submitting
 * @param {Boolean} submitted
 * @param {Boolean} submitFailed
 * @returns {void}
 */
function updateSubmitInfo(submitting, submitted, submitFailed) {
  const { form } = this

  form.submitting = submitting
  form.submitted = submitted
  form.submitFailed = submitFailed
  form.submittable = form.valid && (form.debounce > 0 || (!submitting && !form.disabled))
  form.disabled = submitting
}

/**
 * Handler fired when the async submission fails, with the passed 'error'.
 * The 'id' is the unique identifier of the submission, stored in 'queue'.
 * @param {String} id
 * @returns {void}
 */
function onSubmitSuccess(id, returnValue) {
  const { form, $store } = this

  const queue = this.FormMixin_queue
  const pendingValues = this.FormMixin_pendingValues

  if (returnValue === false) {
    // If the handler returns "false", the submission is considered as
    // silently failed
    updateSubmitInfo.call(this, false, true, true)
  } else {
    // The submit is the last one, there is no other in the pipe
    const isLastSubmit = queue.length === 1 && queue[0] === id

    // There is no pending values or the form fields was not modified since
    // the last 'update'
    const isConsistent =
      pendingValues === undefined || isEqualObject(pendingValues, form.fields, ['id'])

    if (isLastSubmit && isConsistent) {
      // If there is pending values, we apply them on the form fields
      if (pendingValues) {
        // Unsubscribe all the field watchers to avoid any auto submit or
        // Vuelidate alteration when filling the fields with the pending data.
        unsubscribeWatchers.call(this)

        this.form = {
          ...this.form,
          initial: {
            ...this.form.initial,
            ...pendingValues,
          },
          fields: {
            ...this.form.fields,
            ...pendingValues,
          },
        }

        this.FormMixin_pendingValues = undefined
      }

      // Reset the form submission info if in auto-save mode,
      // else mark the form as 'submitted' with success
      updateSubmitInfo.call(this, false, !form.debounce, false)

      // Dispatch a message to inform that the submission succeded
      if (form.notify && $store) {
        $store.dispatch('addMessage', { type: AppTypes.MessageType.SUCCESS })
      }
    }
  }

  // We remove the occurrence(s) of this submit's 'id' from the queue
  this.FormMixin_queue = queue.filter(v => v !== id)
}

/**
 * Handler fired when the async submission fails, with the passed 'error'.
 * The 'id' is the unique identifier of the submission, stored in 'queue'.
 * @param {Object} error
 * @param {String} id
 * @returns {void}
 * @throws {Error}
 */
function onSubmitFailure(id, error) {
  const { form, $store } = this

  // We forget the pending field values received in the previous changes
  this.FormMixin_pendingValues = undefined

  // We remove the occurrence(s) of this submit's 'id' from the queue
  this.FormMixin_queue = this.FormMixin_queue.filter(v => v !== id)

  // Mark the form submission as in failure
  updateSubmitInfo.call(this, false, true, true)

  // Dispatch a message to inform that the submission failed
  if (form.notify && !error.__notified && $store) {
    $store.dispatch('addMessage', { type: AppTypes.MessageType.ERROR })
  }

  throw error
}

/**
 * Must be called with the submission function of the "form component" as
 * unique parameter.
 * This allows to update the status of the submission in the store
 * (i.e. 'submitting', 'submitted', 'submitFailed' meta props).
 * @param {Function} fn
 * @returns {*}
 */
function submit(fn, ...args) {
  const { form, $v } = this

  // Declare the whole form as touched, causing the error extraction on
  // every fields
  if ($v && $v.$touch) {
    $v.$touch()
  }

  const result = fn
    ? fn.call(this, ...args) // Callback function 'fn' passed as argument
    : form.onSubmit
    ? form.onSubmit.call(this, ...args) // Auto-save case with 'form.onSubmit' provided
    : null // No, callback is provided

  // Mark the form as submitting, reseting 'submitted' and 'submitFailed'
  updateSubmitInfo.call(this, true, false, false)

  // If the callback doesn't return a promise (duck-typing)
  if (!result || !result.then) {
    // Update the submission info for non-async action
    updateSubmitInfo.call(this, false, true, !form.submittable || result === false)

    return result
  }

  // A unique ID to identify an async submit's position in the queue when
  // it ends.
  const id = hash({
    name: form.name,
    ts: Date.now(),
    args: JSON.stringify(args),
  })

  // We add this ID to the queue of pending submits
  this.FormMixin_queue = [...this.FormMixin_queue, id]

  return result.then(onSubmitSuccess.bind(this, id)).catch(onSubmitFailure.bind(this, id))
}

/**
 * Submit function on which we applied a debounce according to the
 * form's prop.
 * @returns {Function}
 */
function submitWithDebounce(path, ...args) {
  const { form } = this

  if (form) {
    const debouncers = this.FormMixin_debouncers

    if (!debouncers[path]) {
      debouncers[path] = _debounce(submit.bind(this), form.debounce)
    }

    return debouncers[path](form.onSubmit, path, ...args)
  }

  return null
}

export { updateSubmitInfo, submit, submitWithDebounce }
