<template>
  <div :class="$style.dateTimeField">
    <DateField
      :value="date"
      :class="$style.date"
      :label="dateLabel || __('cp:fields:date-time-field:date:label')"
      :range="range || defaultRange"
      :open-date="openDate"
      :disabled="disabled"
      :helper="helper"
      :error="error"
      :placeholder="__('cp:fields:date-time-field:date:placeholder')"
      :pattern="pattern"
      type="date"
      @input="onDateInput"
    />

    <Field :label="timeLabel || __('cp:fields:date-time-field:time:label')" :disabled="!value">
      <div :class="$style.time">
        <SearchSelect
          ref="hours"
          :class="$style.hours"
          :value="hours"
          :options="hoursRange"
          :placeholder="__('cp:fields:date-time-field:hour:placeholder')"
          :disabled="disabled"
          :clear-on-select="true"
          @input="onHourInput"
        />

        <SearchSelect
          ref="minutes"
          :class="$style.minutes"
          :value="minutes"
          :options="minutesRange"
          :placeholder="__('cp:fields:date-time-field:minutes:placeholder')"
          :disabled="disabled"
          :clear-on-select="true"
          @input="onMinuteInput"
        />
      </div>
    </Field>
  </div>
</template>

<script>
  import moment from 'moment-timezone'
  import _range from 'lodash/range'
  import _padStart from 'lodash/padStart'

  import DateField from '@/core/fields/DateField/DateField'
  import SearchSelect from '@/core/inputs/SearchSelect/SearchSelect'
  import Field from '@/core/layout/Field/Field'

  import ClockMixin from '@/mixins/ClockMixin'
  import I18nMixin from '@/mixins/I18nMixin'

  /**
   * Normalize a time (hours/minutes) value into a 2 characters string
   * @param {Number}
   * @returns {String}
   */
  function normalizeTime(t) {
    return _padStart(t, 2, '0')
  }

  /**
   * Format number range for the hours and minutes
   * @param   {Number} min
   * @param   {Number} max
   * @param   {Number} step
   * @returns {Array.<Object>}
   */
  function formatTimeNumbers(min, max, step) {
    return _range(min, max, step).map(v => ({
      id: normalizeTime(v),
      name: normalizeTime(v),
    }))
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  SearchSelect: SearchSelect.__enums,
  DateField: DateField.__enums,
  Field: Field.__enums,
},

    name: 'DateTimeField',
    components: {
      SearchSelect,
      DateField,
      Field,
    },
    mixins: [ClockMixin, I18nMixin],
    props: {
      /**
       * Current date
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Format used to display the date in the input (see locales)
       */
      pattern: {
        type: String,
        default: 'shortdate',
      },
      /**
       * Allowed dates
       */
      range: {
        type: Object,
        default: null,
      },
      /**
       * Interval between minutes options
       */
      minutesStep: {
        type: Number,
        default: 5,
      },
      /**
       * Minimum selectable hour
       */
      minHours: {
        type: Number,
        default: 0,
      },
      /**
       * Disable the picker input
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Error if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Date picker's label
       */
      dateLabel: {
        type: String,
        default: null,
      },
      /**
       * Date picker's label
       */
      timeLabel: {
        type: String,
        default: null,
      },
      /**
       * Helper text to display under the textarea
       */
      helper: {
        type: String,
        default: null,
      },
      /**
       * If set, open on that date
       */
      openDate: {
        type: Date,
        default: null,
      },
    },
    computed: {
      /**
       * Options for hours dropdown
       * @type {Array}
       */
      hoursRange() {
        return formatTimeNumbers(this.minHours, 24, 1)
      },
      /**
       * Options for minutes dropdown
       * @type {Array}
       */
      minutesRange() {
        return formatTimeNumbers(0, 60, this.minutesStep)
      },
      /**
       * Plain date without hours and minutes
       * @type {Moment}
       */
      date() {
        const { value } = this

        if (!value) {
          return null
        }

        return moment(value).toDate()
      },
      /**
       * Hours extracted from the date value
       * @type {Number}
       */
      hours() {
        const { value } = this

        if (!value) {
          return null
        }

        return normalizeTime(moment(value).hours())
      },
      /**
       * Minutes extracted from the date value
       * @type {Number}
       */
      minutes() {
        const { value, minutesStep } = this

        if (!value) {
          return null
        }

        const minutes = moment(value).minutes()

        // Round down minutes to comply with steps
        return normalizeTime(minutes - (minutes % minutesStep))
      },
      /**
       * Default allowed dates
       * @type {Object}
       */
      defaultRange() {
        const { now, yesterdayDate } = this

        const nextYear = now()
          .add(1, 'year')
          .toDate()

        return { to: yesterdayDate(), from: nextYear }
      },
    },
    methods: {
      /**
       * Called when the date picker is updated
       * @returns {void}
       */
      onDateInput(date) {
        const { hours, minutes, $refs, onInput } = this

        onInput(date, hours, minutes)

        const [nextInput] = $refs.hours.$el.getElementsByTagName('input')
        if (!hours && nextInput) {
          nextInput.focus()
        }
      },
      /**
       * Called when the hours dropdown is updated
       * @returns {void}
       */
      onHourInput(hours) {
        const { date, minutes, $refs, onInput } = this

        onInput(date, hours, minutes)

        const [nextInput] = $refs.minutes.$el.getElementsByTagName('input')
        if (!minutes && nextInput) {
          nextInput.focus()
        }
      },
      /**
       * Called when the minutes dropdown is updated
       * @returns {void}
       */
      onMinuteInput(minutes) {
        const { date, hours, onInput } = this

        onInput(date, hours, minutes)
      },
      /**
       * Emit an input event with the given date
       * @param {Moment|null} date
       * @param {String} hours
       * @param {String} minutes
       */
      onInput(date, hours, minutes) {
        const { now } = this

        // Default to now() if date hasn't been set yet (e.g: hours first)
        const dateTime = moment(date || now())
          .hours(parseInt(hours, 10))
          .minutes(parseInt(minutes, 10))
          .format()

        this.$emit('input', dateTime)
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_grid.styl'

  .dateTimeField {
    display: flex
    align-items: center

    > *:not(:last-child) {
      margin-right: 24px
    }

    .time {
      display: flex
      align-items: center

      > *:not(:last-child) {
        margin-right: 16px
      }
    }

    .hours,
    .minutes {
      max-width: 152px
    }
  }
</style>
