<template>
  <VAutocomplete
    :value="value"
    :class="$style.root"
    v-bind="$attrs"
    :search-input.sync="search"
    :placeholder="__('cp:mission-field:placeholder')"
    :no-data-text="__('cp:mission-field:placeholder')"
    :items="missions"
    :prepend-inner-icon="value ? 'mdi-rocket' : 'mdi-magnify'"
    :append-icon="null"
    solo
    hide-no-data
    hide-selected
    no-filter
    return-object
    @input="onInput"
  >
    <template v-slot:selection="{ item: mission }">
      <div class="mr-4">
        <span class="font-weight-medium" v-text="mission.title" />
        <span class="grey--text mx-1" v-text="'·'" />
        <span v-text="mission.project.company.name" />
      </div>
      <span class="ml-auto grey--text caption" v-text="`#${mission.id}`" />
    </template>

    <template v-slot:item="{ item: mission }">
      <div class="mr-4">
        <span class="font-weight-medium" v-text="mission.title" />
        <span class="grey--text mx-1" v-text="'·'" />
        <span v-text="mission.project.company.name" />
      </div>
      <span class="ml-auto grey--text caption" v-text="`#${mission.id}`" />
    </template>
  </VAutocomplete>
</template>

<script>
  import query from './query.gql'

  export default {
    name: 'MissionField',
    props: {
      value: {
        type: Object,
        default: null,
      },
      filters: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        missions: [],
        search: '',
      }
    },
    apollo: {
      missions: {
        query,
        context: {
          debounceKey: 'mission-field',
        },
        variables() {
          return {
            search: this.search,
            filters: this.filters,
          }
        },
        update(data) {
          return data?.missionsSearch?.[0]?.missions?.items ?? []
        },
        skip() {
          if (!this.search) {
            return true
          }

          if (this.search.startsWith('#') && this.search.length === 1) {
            return true
          }

          if (!this.search.startsWith('#') && this.search.length < 3) {
            return true
          }

          return false
        },
      },
    },
    methods: {
      onInput(mission) {
        this.$emit('input', mission)
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    :global(.v-select__selections > input:not(:first-child)) {
      width: 1px
      min-width: 1px
    }
  }
</style>
