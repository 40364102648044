export default function resizeObserverErrorPrevention() {
  const debounce = (callback, delay) => {
    let tid
    return function(...args) {
      const ctx = self
      tid && clearTimeout(tid)
      tid = setTimeout(() => {
        callback.apply(ctx, args)
      }, delay)
    }
  }

  const _ = window.ResizeObserver

  window.ResizeObserver = class ResizeObserver extends _ {
    constructor(callback) {
      const safeCallback = (...args) => {
        try {
          callback(...args)
        } catch (error) {
          if (error.message !== 'ResizeObserver loop completed with undelivered notifications') {
            throw error
          }
        }
      }

      // Debounce the wrapped callback
      const debouncedCallback = debounce(safeCallback, 20)

      super(debouncedCallback)
    }
  }
}
