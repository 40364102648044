<template>
  <Field
    :id="uid"
    :label="label"
    :error="error"
    :disabled="disabled"
    :helper="helper"
    :sup-label="supLabel"
    class="date-field"
  >
    <slot v-if="$slots.label" slot="label" name="label" />

    <DatePicker
      :id="uid"
      :value="value"
      :type="type"
      :pattern="pattern"
      :placeholder="placeholder"
      :year-first="yearFirst"
      :month-only="monthOnly"
      :year-only="yearOnly"
      :alignment="alignment"
      :language="language"
      :range="range"
      :custom-disabler="customDisabler"
      :disabled="disabled"
      :clear-button="clearButton"
      v-bind="$attrs"
      @input="onInput"
      @opened="onOpen"
    />
  </Field>
</template>

<script>
  import DatePicker from '@/core/inputs/DatePicker/DatePicker'
  import Field from '@/core/layout/Field/Field'

  import { uuid } from '@/utils/app'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  DatePicker: DatePicker.__enums,
  Field: Field.__enums,
},

    name: 'DateField',
    components: {
      DatePicker,
      Field,
    },
    props: {
      /**
       * Unique identifier used to match the input and peer it with its label
       */
      id: {
        type: String,
        default: null,
      },
      /**
       * Expected type of the "value".
       * Could be either 'iso' (string) or 'date' (JS Date Object)
       */
      type: {
        type: String,
        default: 'iso',
      },
      /**
       * Current date of the DatePicker.
       * It could be either a Date object or a String. If it is a String,
       * it would be parsed by momentJS and translate as a Date.
       */
      value: {
        type: [Date, Object, String],
        default: null,
      },
      /**
       * Format used to display the date in the input (see locales)
       */
      pattern: {
        type: String,
        default: 'shortdate',
      },
      /**
       * Placeholder string to display when empty
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * If 'true', the DatePicker opens first on the year panel,
       * then month, ...
       */
      yearFirst: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the DatePicker don't ask for a day date (only month
       * and year)
       */
      monthOnly: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the DatePicker only ask for a year date
       */
      yearOnly: {
        type: Boolean,
        default: false,
      },
      /**
       * Side on which the opened calendar panel is aligned
       * can be "left", "center" or "right"
       */
      alignment: {
        type: String,
        default: 'left',
      },
      /**
       * Specify the language used to format dates
       */
      language: {
        type: String,
        default: 'fr',
      },
      /**
       * Disable the picker input
       */
      range: {
        type: Object,
        default: () => {},
      },
      /**
       * Disable the picker input
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Error if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Date picker's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Custom function to filter dates
       */
      customDisabler: {
        type: Function,
        default: null,
      },
      /**
       * Helper text to display under the textarea
       */
      helper: {
        type: String,
        default: null,
      },
      /**
       * If true, display an icon to clear date
       */
      clearButton: {
        type: Boolean,
        default: false,
      },
      /**
       * Label's superscript text
       */
      supLabel: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * Unique identifier used to match the input and peer it with its label
       */
      uid() {
        const { id, _uid } = this

        return id || uuid(_uid)
      },
    },
    methods: {
      /**
       * On input, emit event to notify the parent
       * @returns {void}
       */
      onInput(value) {
        this.$emit('input', value)
      },
      /**
       * On input, emit event to notify the parent
       * @returns {void}
       */
      onOpen() {
        this.$emit('open')
      },
    },
  }
</script>
