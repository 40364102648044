<template>
  <!--Using "<Component>" doesn't seem to work with transition tag-->
  <TransitionGroup
    tag="div"
    :name="animation"
    :appear="true"
    :class="classes"
    @before-appear="onSlideEnter"
    @after-appear="onSlideLeave"
    @appear-cancelled="onSlideLeave"
    @before-enter="onSlideEnter"
    @after-leave="onSlideLeave"
    @leave-cancelled="onSlideLeave"
  >
    <slot />
  </TransitionGroup>
</template>

<script>
  import _isNumber from 'lodash/isNumber'

  import RouterMixin from '@/mixins/RouterMixin'

  import { dispatchResize } from '@/utils/dom'

  /**
   * Compute the animation's name
   * @param {*} to
   * @param {*} from
   * @return {String}
   */
  function computeAnimationName(to, from) {
    if (!_isNumber(to) || !_isNumber(from)) {
      return 'nav-fade'
    }

    return to > from ? 'slide-left' : 'slide-right'
  }

  export default {
    name: 'WithSlide',
    mixins: [RouterMixin],
    props: {
      /**
       * The index of the currently displayed panel among the others.
       * It allons to determine if we must perform a left-to-right animation
       * or a right-to-left one.
       */
      index: {
        default: null,
        type: Number,
      },
    },
    data() {
      return {
        /**
         * CSS classes applied to the Transition component
         * @type {String}
         */
        animation: undefined,
        /**
         * Is "true" while the animation is playing
         * This allows to apply a relative position to the container only
         * when animating and so well contain the absolute moving slides.
         * However, on mobile, the relative position freezes the scroll
         * (and we don't know why).
         * @type {Boolean}
         */
        animating: undefined,
      }
    },
    computed: {
      /**
       * CSS classes applied on the root element
       * @type {Object}
       */
      classes() {
        const { animation, animating } = this

        return {
          'with-slide': true,
          'with-slide--animating': !!animating,
          [`with-slide--${animation}`]: true,
        }
      },
    },
    watch: {
      index: {
        immediate: true,
        handler(to, from) {
          this.animation = computeAnimationName(to, from)
        },
      },
    },
    methods: {
      /**
       * Fired before the animation starts
       */
      onSlideEnter() {
        this.animating = true
      },
      /**
       * Fired after the animation processed entirely
       */
      onSlideLeave() {
        this.animating = false

        /* Some components (ex: vue-slider) rely on js to compute their
        position once displayed but the animation fucks that up because
        it changes their position ; we need to force them to recompute
        after the animation, a fake resize event does the trick */
        dispatchResize()
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_layout.styl'

  .with-slide {
    width: 100%
    height: 100%

    @media web {
      position: relative
    }

    @media phone-tablet {
      &&--animating {
        position: relative
      }
    }
  }
</style>
