<template>
  <VCard
    v-if="skill"
    :min-width="400"
    :max-width="500"
    :raised="true"
    :class="$style.skillInfoCard"
  >
    <Txt v-if="skill.name" :theme="Txt.Theme.BRAND" :size="Txt.Size.XS" :value="skill.name" />

    <Txt
      v-if="skill.description"
      :theme="Txt.Theme.GREY"
      :size="Txt.Size.XXXS"
      :raw="true"
      :value="
        __('cp:core:skill:skill-info-card:description', {
          description: skill.description,
          link: skill.relatedLink,
        })
      "
    />

    <div>
      <Txt
        v-if="skill.firstReleased"
        :theme="Txt.Theme.GREY"
        :size="Txt.Size.XXXS"
        :raw="true"
        :value="
          __('cp:core:skill:skill-info-card:creation-date', { firstReleased: skill.firstReleased })
        "
      />

      <Txt
        v-if="skill.latestStableVersion"
        :theme="Txt.Theme.GREY"
        :size="Txt.Size.XXXS"
        :raw="true"
        :value="
          __('cp:core:skill:skill-info-card:stable-version', {
            latestStableVersion: skill.latestStableVersion,
          })
        "
      />

      <Txt
        v-if="skill.aliases && skill.aliases.length"
        :theme="Txt.Theme.GREY"
        :size="Txt.Size.XXXS"
        :raw="true"
        :value="__('cp:core:skill:skill-info-card:aliases', { aliases: skill.aliases.join(', ') })"
      />
    </div>

    <div v-if="skill.relatedSkills && skill.relatedSkills.length > 0">
      <Txt
        :theme="Txt.Theme.GREY"
        :size="Txt.Size.XXXS"
        :value="__('cp:core:skill:skill-info-card:skills-related')"
        :class="$style.skillLabel"
      />

      <div :class="$style.skills">
        <Tag
          v-for="s in skill.relatedSkills"
          :key="s.id"
          :name="s.name"
          :theme="Tag.Theme.SECONDARY_ALT"
        />
      </div>
    </div>
  </VCard>
</template>

<script>
  import I18nMixin from '@/mixins/I18nMixin'

  import Tag from '@/core/badges/Tag/Tag'
  import Txt from '@/core/text/Txt/Txt'

  import query from './query.gql'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Tag: Tag.__enums,
  Txt: Txt.__enums,
},

    name: 'SkillInfoCard',
    components: {
      Tag,
      Txt,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Id of the skill we want to display info
       */
      id: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        /**
         * skill fetched by Apollo
         * @type {Object}
         */
        skill: null,
      }
    },
    apollo: {
      skill: {
        query,
        fetchPolicy: 'network-only',
        variables() {
          return { id: this.id }
        },
        skip() {
          return !this.id
        },
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_grid.styl'

  .skillInfoCard {
    padding: 8px 12px
    detail-grid()
    display: grid !important

    .skills {
      display: flex

      *:not(:first-child) {
        margin-left: 4px
      }
    }

    .skillLabel {
      margin-bottom: 4px
    }
  }
</style>
