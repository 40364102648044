<template>
  <div
    class="d-flex flex-row flex-wrap"
    :class="classes"
  >
    <template v-for="item in value">
      <CChipTalent
        :key="item.id"
        :dense="dense"
        :label="item.name"
        :wished="item.wishedInMissions"
        :duration="item.duration"
        disabled
      />
    </template>

    <slot />
  </div>
</template>

<script>
  import _camelCase from 'lodash/camelCase'

  export default {
    name: 'FreelancerSkills',
    props: {
      /**
       * List of skills to show
       * @type {Array.<Skill>}
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Reduces the chips' height
       */
      dense: {
        type: Boolean,
        default: false,
      },
      /**
       * Removes background and applies border and text color
       */
      outlined: {
        type: Boolean,
        default: false,
      },
      /**
       * Color applied to the entire chip
       */
      color: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { dense, color, $style } = this

        return {
          [$style.root]: true,
          [$style.dense]: dense,
          [$style[_camelCase(`color-${color}`)]]: !!color,
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    position: relative
    max-width: 100%
    padding: 4px 0
    white-space: normal
    contain: content

    > * {
      margin: 4px 8px 4px 0
    }

    .chip {
      border-color: var(--color-grey-40)
      background-color: var(--color-grey-10)
      opacity: 1 !important
    }

    .duration {
      position: relative
      left: 4px
      height: 20px
      display: flex
      align-items: center
      padding: 0 8px
      background: var(--color-grey-30)
      border-radius: 10px
    }

    &.dense .duration {
      height: 16px
      left: 8px
    }
  }
</style>
