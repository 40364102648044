<template>
  <Field :id="uid" :label="label" :disabled="disabled" :error="error">
    <slot name="info" />

    <SkillInput :id="uid" v-bind="$attrs" @input="onInput" />
  </Field>
</template>

<script>
  import Field from '@/core/layout/Field/Field'
  import SkillInput from '@/core/inputs/SkillInput/SkillInput'

  import { uuid } from '@/utils/app'

  const { Context, Size } = SkillInput

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
  SkillInput: SkillInput.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Context,Size,
    name: 'SkillField',
    __enums: {
      Context,
      Size,
    },
    components: {
      Field,
      SkillInput,
    },
    props: {
      /**
       * Unique identifier used to match the input and peer it with its label
       * (randomly generated if null or undefined)
       */
      id: {
        type: String,
        default: null,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Skill field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * Unique identifier used to match the input and peer it with its label
       */
      uid() {
        const { id, _uid } = this

        return id || uuid(_uid)
      },
    },
    methods: {
      onInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>
