<template>
  <div>
    <Component
      :is="component"
      v-if="component"
      :error="boundedError"
      :helper="boundedHelper"
      :value="value"
      class="bounded-field"
      v-bind="forwardedAttribute"
      v-on="$listeners"
    >
      <!-- Pass all the slots defined in BoundedField to the child component -->
      <template v-for="(slot, name) in $slots" :slot="name">
        <slot :name="name" />
      </template>

      <!-- Pass all the scoped slot of BoundedField to the child component -->
      <template v-for="(slot, name) in $scopedSlots" :slot="name" slot-scope="boundedField">
        <slot :name="name" v-bind="boundedField" />
      </template>
    </Component>
    <template v-else>
      <slot v-bind="{ boundedError, boundedHelper }" />
    </template>
  </div>
</template>

<script>
  import _omit from 'lodash/omit'

  import TextField from '@/core/fields/TextField/TextField'
  import TextareaField from '@/core/fields/TextareaField/TextareaField'
  import I18nMixin from '@/mixins/I18nMixin'

  import FormTypes from '@/types/form'

  const { BOUNDED_FIELD } = FormTypes.UnformattedError

  export default {
    name: 'BoundedField',
    mixins: [I18nMixin],
    props: {
      /**
       * Field's value
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Maximum amount of charactere autorized in the input
       */
      max: {
        type: Number,
        required: true,
      },
      /**
       * Field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Type of input we want to use in the field can be ["text", "textarea"]
       */
      inputType: {
        type: String,
        default: 'text',
      },
    },
    computed: {
      /**
       * Compute the component to return according to the inputType
       * @type {Component}
       */
      component() {
        const { inputType } = this

        switch (true) {
          case inputType === 'text':
            return TextField
          case inputType === 'textarea':
            return TextareaField
          default:
            return null
        }
      },
      /**
       * List of attribute forward to the child component
       * We omit the error and the helper because it's already handled
       * @type {Array}
       */
      forwardedAttribute() {
        return _omit(this.$attrs, ['error', 'inputType', 'helper']) || {}
      },
      /**
       * Error message computed according to the length of the value
       * @type {String}
       */
      boundedError() {
        const { value, error, max, __ } = this

        if (error === BOUNDED_FIELD) {
          return __('cp:core:fields:bounded-field:error', {
            len: (value || '').length,
            max,
          })
        }

        return error
      },
      /**
       * Helper message computed according to the length of the value
       * @type {String}
       */
      boundedHelper() {
        const { value, max, __ } = this

        return __('cp:core:fields:bounded-field:helper', {
          len: (value || '').length,
          max,
        })
      },
    },
  }
</script>
