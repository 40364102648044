<template>
  <VBtn
    v-bind="$attrs"
    text
    x-small
    :rounded="$attrs.rounded || true"
    :color="$attrs.color || 'primary'"
    :elevation="$attrs.elevation || 0"
    target="_blank"
    rel="noopener noreferrer"
    :class="$style.root"
    v-on="$listeners"
  >
    <slot>
      {{ label }}
    </slot>

    <VIcon
      :size="12"
      class="ml-1"
      v-text="'mdi-open-in-new'"
    />
  </VBtn>
</template>

<script>
  export default {
    name: 'CButtonExternalLink',
    props: {
      /**
       * Button main label
       * (We need it because "v-text" override the whole inner content and remove the icon)
       * @type {String}
       */
      label: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="stylus" module>
  :global(#comet) {
    .root {
      &:global(.v-btn) {
        padding: 0px .7em
      }
    }
  }
</style>
