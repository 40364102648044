<template>
  <div class="field" :class="classes">
    <label v-if="label || $slots.label" :for="id">
      <Txt
        v-if="label"
        :class="{ [$('label')]: !supLabel }"
        :value="label"
        :size="Txt.Size.XXXS"
        :type="Txt.Type.OVERLINE"
        :theme="disabled ? 'grey-medium' : 'grey'"
        :inline="true"
        :for="id"
        :raw="raw"
      />
      <sup v-if="supLabel" :class="[$('sup'), supClasses]">
        {{ supLabel }}
      </sup>
      <slot name="label" />
    </label>

    <slot />

    <Txt
      :class="$('error')"
      :value="error || helper"
      :size="Txt.Size.XXXS"
      :raw="raw"
      :theme="!!error ? 'red' : 'grey'"
    />
  </div>
</template>

<script>
  import Txt from '@/core/text/Txt/Txt'

  const SupType = {
    DEFAULT: 'default',
    HIGHLIGHT: 'highlight',
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
  }

  export const FieldSupType = SupType

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ SupType,
    name: 'Field',
    __enums: {
      SupType,
    },
    components: {
      Txt,
    },
    props: {
      /**
       * Unique identifier used to link the Field's label to its input
       */
      id: {
        type: String,
        default: null,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript text
       */
      supLabel: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript color
       * @type {FieldSupType}
       */
      supType: {
        type: String,
        default: null,
      },
      /**
       * If 'true', the label never wraps and ends with an ellipsis if needed
       */
      ellipsis: {
        type: Boolean,
        default: false,
      },
      /**
       * Field's error if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Text to display under the Field to guide the user
       */
      helper: {
        type: String,
        default: null,
      },
      /**
       * If 'true, v-html will be used for the label and helper values
       */
      raw: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * List of CSS classes applied on the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { disabled, ellipsis, error } = this

        return {
          'field--disabled': !!disabled,
          'field--ellipsis': !!ellipsis,
          'field--invalid': !!error,
        }
      },
      /**
       * List of CSS classes applied on the sup
       * @type {Object.<Boolean>}
       */
      supClasses() {
        const { supType } = this

        return {
          [`field-sup--${supType}`]: !!supType,
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .field {
    position: relative
    max-width: 100%

    label {
      display: flex
    }

    &-invalid {
      box-shadow: inset 0 -1px 0 var(--color-input-error)
      transition: box-shadow 0.3s ease
    }

    &--ellipsis {
      // We don't wrap label to prevent graphical bugs, except on mobile
      // (we don't want to force the user to use landscape orientation)
      @media tablet-up {
        > label {
          ellipsis()
        }
      }
    }

    &-sup {
      line-height: 1

      &--highlight {
        color: var(--color-brand)
      }

      &--negative {
        color: var(--color-negative)
      }

      &--positive {
        color: var(--color-positive)
      }
    }

    &-error {
      position: absolute
      padding: 2px 8px 0
      white-space: pre-line
      line-height: 1
    }
  }
</style>
