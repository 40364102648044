<template>
  <VApp id="app">
    <Transition :name="transition" mode="out-in" appear>
      <RouterView />
    </Transition>

    <ToastMessages />

    <Component
      :is="`${modal.name}`"
      v-if="modal"
      v-bind="modal.params"
      v-on="{ dismiss: dismissModal }"
    />

    <SafetyBanner />
    <BrowserCompatibilityBanner />
    <HotReloadMonitor v-if="SHOW_HOT_RELOAD_INDICATOR" />
  </VApp>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import _get from 'lodash/get'
  import _find from 'lodash/find'

  import * as Sentry from '@sentry/browser'

  import BrowserCompatibilityBanner from '@/core/banners/BrowserCompatibilityBanner/BrowserCompatibilityBanner'
  import HotReloadMonitor from '@/core/banners/HotReloadMonitor/HotReloadMonitor'
  import OutdatedVersionModal from '@/modals/app/OutdatedVersionModal/OutdatedVersionModal'
  import SafetyBanner from '@/core/banners/SafetyBanner/SafetyBanner'
  import ToastMessages from '@/core/toasts/ToastMessages/ToastMessages'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import LinkedInMixin from '@/mixins/LinkedInMixin'
  import ModalMixin from '@/mixins/ModalMixin'
  import RouterMixin from '@/mixins/RouterMixin'
  import ViralLoopsMixin from '@/mixins/ViralLoopsMixin'
  import BackofficeNotifications from './BackofficeNotifications'

  import UserTypes from '@/types/user'
  import { extractEnvFromRoute } from '@/utils/app'
  import oauth from '@/utils/oauth'
  import config from '@/config'

  import query from './query.gql'

  // HANDLE THE ERROR : ResizeObserver loop completed with undelivered notifications
  import resizeObserverErrorPrevention from './resizeObserverErrorPrevention'
  resizeObserverErrorPrevention()

  const { FREELANCER, CORPORATE, TEAM_MEMBER } = UserTypes.UserRole
  const { PREVENT, FORCE, PUBLIC } = UserTypes.UserFetchPolicy

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  BrowserCompatibilityBanner: BrowserCompatibilityBanner.__enums,
  HotReloadMonitor: HotReloadMonitor.__enums,
  SafetyBanner: SafetyBanner.__enums,
  ToastMessages: ToastMessages.__enums,
},

    name: 'App',
    components: {
      BrowserCompatibilityBanner,
      HotReloadMonitor,
      SafetyBanner,
      ToastMessages,
    },
    mixins: [
      ApolloMixin,
      BackofficeNotifications,
      LinkedInMixin,
      ModalMixin,
      RouterMixin,
      ViralLoopsMixin,
    ],
    data() {
      return {
        /**
         * Informations from apollo relative to the connected user
         * @type {Object}
         */
        me: this.user,
        /**
         * Transition from the routes
         * @type {String}
         */
        transition: null,
        /**
         * As the syncing process is asynchronous, we need a flag to know that
         * the syncing is in progress. It ensures the sync won't fire twice.
         * (It happens on the Freelancer's signup: the mutate of viralloopmixin
         * fires twice the 'result()'. It seems to be related to Apollo's
         * lifecycle... but I haven't found the reason yet.)
         * @type {boolean}
         */
        isSyncing: false,
      }
    },
    constants: {
      SHOW_HOT_RELOAD_INDICATOR: config.showHotReloadIndicator,
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'isAuthenticated',
        user: 'selectUser',
        role: 'selectRole',
        isSynced: 'isSynced',
        isOutdated: 'isOutdated',
        isTeamMember: 'isTeamMember',
        isAnonymized: 'isAnonymized',
      }),
    },
    apollo: {
      me: {
        query,
        context: {
          retryCount: 7,
        },
        result(response) {
          const {
            configureSentryScope,
            hasBeenSynced,
            kickout,
            isSyncing,
            isSynced,
            sendReferralCode,
            redirect,
            updateUserInfo,
          } = this

          const me = _get(response, 'data.me', null)

          if (!me) {
            kickout()
          } else {
            const promise = updateUserInfo(me)

            if (!isSynced && !isSyncing) {
              this.isSyncing = true

              promise
                .then(sendReferralCode)
                .then(configureSentryScope)
                // Done as the late step to notice RedirectView that the infos
                // are fetched
                .then(() => {
                  this.isSyncing = false
                  hasBeenSynced()
                })
            }

            promise.catch(redirect)
          }
        },
        error(err) {
          const hasInternalServerError = _find(err.graphQLErrors, { code: 500 })

          if (hasInternalServerError) {
            this.kickout()
          }
        },
        skip() {
          const { route, isAuthenticated } = this
          const { userPolicy } = route.meta

          try {
            // Only load user in main window
            if (window.opener && window.opener.oauth) {
              return true
            }
          } catch (e) {
            // A cross domain error will be thrown
            // if window.opener is not from the same domain
            // Silence it and continue
          }

          return [PREVENT].includes(userPolicy) || (!isAuthenticated && userPolicy !== FORCE)
        },
        fetchPolicy: 'network-only',
      },
    },
    watch: {
      isAuthenticated(next, prev) {
        const { route, navigate } = this

        if (prev && !next && !route.path.match('^/[a-z]*/signout$')) {
          // When the session is just revoked (without routing moves)
          // the user is redirected to the appropriate signout page
          // according to the base of the current route path
          // to be sure that the logout is effective server-side.
          const env = extractEnvFromRoute(route)

          if (env) {
            navigate(`/${env}/signout`)
          } else {
            navigate('/signout')
          }
        }
      },
      /**
       * Checking if routes 'to' or 'from' ask for a transition to be applied.
       * route : { meta: { transitions: { in, out } } }
       */
      route(to, from) {
        const transitionIn = _get(to, 'meta.transitions.in')
        const transitionOut = _get(from, 'meta.transitions.out')

        if (transitionIn) {
          this.transition = transitionIn
        } else if (transitionOut) {
          this.transition = transitionOut
        }

        this.setAnonymization()
      },
      /**
       * Open outdated version modal when we detect that the client is outdated
       * @param {Boolean} oudated
       */
      async isOutdated(outdated) {
        const { openModal } = this

        if (outdated === true) {
          await openModal(OutdatedVersionModal)
        }
      },
    },
    created() {
      this.resetOutdatedVersion()

      try {
        oauth.handleOAuthRedirection()
      } catch (e) {
        // A cross domain error will be thrown
        // if window.opener is not from the same domain
        // Silence it and continue
      }
    },
    methods: {
      ...mapActions([
        'hasBeenSynced',
        'updateUserInfo',
        'resetOutdatedVersion',
        'updateAnonymization',
      ]),
      /**
       * Set anonymization in auth according to user policy
       * @returns {void}
       */
      setAnonymization() {
        const { updateAnonymization, route, isAnonymized } = this

        if (route.meta.userPolicy === PUBLIC) {
          updateAnonymization(true)
        } else if (isAnonymized) {
          updateAnonymization(false)
        }
      },
      /**
       * Adds user information to the Sentry bug report payload
       * @returns {void}
       */
      async configureSentryScope() {
        const sentry = _get(config, 'services.sentry')

        if (!sentry) {
          return null
        }

        const { user, freelancer, corporate, teamMember, role } = this
        const { id, email, firstName, lastName } = user || {}

        const username = `${firstName} ${lastName}`
        const freelancerId = freelancer && freelancer.id
        const corporateId = corporate && corporate.id
        const teamMemberId = teamMember && teamMember.id

        return Sentry.configureScope(scope => {
          scope.setTag('role', role)
          scope.setExtra('freelancerId', freelancerId)
          scope.setExtra('corporateId', corporateId)
          scope.setExtra('teamMemberId', teamMemberId)
          scope.setUser({
            id,
            username,
            email,
          })
        })
      },
      /**
       * Redirect the user according to its profile (roles) or straight
       * to the signout page.
       * @returns {void}
       */
      redirect(error) {
        const { role, navigate, kickout } = this

        if (error) {
          throw error
        }

        if (role === TEAM_MEMBER) {
          return navigate('/backoffice', true)
        }

        if (role === CORPORATE) {
          return navigate('/enterprise', true)
        }

        if (role === FREELANCER) {
          return navigate('/freelancer', true)
        }

        return kickout()
      },
      /**
       * Redirect the user according to its profile (roles) or straight
       * to the signout page.
       * @returns {void}
       */
      kickout(error) {
        const { route, navigate, isOutdated, openModal } = this

        // Prevent disconnection during outdated version errors
        if (isOutdated) {
          return openModal(OutdatedVersionModal)
        }

        const env = extractEnvFromRoute(route)

        if (error) {
          throw error
        }

        if (env) {
          return navigate(`/${env}/signout`, true)
        }

        return navigate('/signout', true)
      },
    },
  }
</script>

<style src="../../../assets/css/main.styl" lang="stylus" />
