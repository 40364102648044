import Vue from 'vue'
import Vuex from 'vuex'

import app from '@/store/modules/app'
import auth from '@/store/modules/auth'
import layout from '@/store/modules/layout'
import subscription from '@/store/modules/subscription'
import switches from '@/store/modules/switches'
import syncform from '@/store/modules/syncform'
import createPersistence from '@/store/plugins/persistence'
import config from '@/config'

/**
 * Create the Vuex store instance passing it all the
 * used modules
 * @returns {Object}
 */
function createStore() {
  Vue.use(Vuex)

  return new Vuex.Store({
    // Modules
    modules: {
      app,
      auth,
      layout,
      subscription,
      switches,
      syncform,
    },
    // Plugins
    plugins: [createPersistence()],
    // Enable 'strict' mode in dev env
    strict: config.vuex.strict,
  })
}

export default createStore
