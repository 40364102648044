<template>
  <div class="grid">
    <slot name="head">
      <div
        v-if="orderedColumns"
        :class="[
          'grid-head',
          {
            ['grid-head--sticky']: !!stickyHead,
            [headClass]: !!headClass,
          },
        ]"
      >
        <div
          v-for="column in orderedColumns"
          :key="column.id"
          class="grid-cell"
          :class="getHeaderClasses(column)"
        >
          <AppLink :disabled="!column.order || loading" @click="onColumnPress(column)">
            {{ column.label }}
            <i :class="[$('order-icon'), 'icon-chevron-top']" />
          </AppLink>
        </div>
      </div>
    </slot>

    <slot name="body">
      <TransitionGroup tag="div">
        <div v-show="loading" key="loading" class="grid-loading">
          <slot name="loading">
            <div
              v-for="i in _range(3)"
              :key="i"
              class="grid-row grid-row--placeholder"
              :class="[rowClass]"
            >
              <div
                v-for="column in orderedColumns"
                :key="column.id"
                class="grid-cell grid-cell--placeholder"
                :class="getCellClasses(column)"
              />
            </div>
          </slot>
        </div>

        <div
          v-show="!loading && isEmpty && placeholders"
          key="placeholders"
          class="grid-placeholders"
        >
          <div
            v-for="i in _range(3)"
            :key="i"
            class="grid-row grid-row--placeholder"
            :class="[rowClass]"
          >
            <div
              v-for="column in orderedColumns"
              :key="column.id"
              class="grid-cell grid-cell--placeholder"
              :class="getCellClasses(column)"
            />
          </div>
        </div>

        <div v-show="!loading && isEmpty && !placeholders" key="empty" class="grid-empty">
          <slot name="empty">
            {{ empty }}
          </slot>
        </div>

        <div v-show="!loading && !isEmpty" key="body" class="grid-body">
          <AppLink
            v-for="(item, i) in items"
            :key="item.id"
            :to="item.to"
            :type="item.blank ? 'external' : undefined"
            :blank="item.blank"
            data-cy-grid-item
            @click="() => (item.onPress ? item.onPress() : null)"
          >
            <slot :name="`full-row-${i}`" :item="item" :index="i">
              <div class="grid-row" :class="[rowClass, item.rowClass]">
                <slot :name="`row-${i}`" :item="item" :index="i">
                  <div
                    v-for="(column, j) in orderedColumns"
                    :key="column.id"
                    class="grid-cell"
                    :class="getCellClasses(column)"
                  >
                    <slot
                      :name="`cell-${column.id.toLowerCase()}-${i}`"
                      :value="item[column.id]"
                      :row="i"
                      :index="j"
                    >
                      {{ item[column.id] }}
                    </slot>
                  </div>
                </slot>
              </div>
            </slot>
          </AppLink>
        </div>
      </TransitionGroup>
    </slot>

    <div class="grid-foot">
      <slot name="foot"> </slot>
    </div>
  </div>
</template>

<script>
  import _range from 'lodash/range'

  import AppLink from '@/core/controls/AppLink/AppLink'

  import AppTypes from '@/types/app'

  const { ASC, DESC } = AppTypes.OrderDirection

  const ColumnAlign = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
  }

  const ColumnSize = {
    EXTRA_LARGE: 'extra-large',
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
    EXTRA_SMALL: 'extra-small',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ ColumnAlign,ColumnSize,
    name: 'Grid',
    __enums: {
      ColumnAlign,
      ColumnSize,
    },
    components: {
      AppLink,
    },
    props: {
      /**
       * Column names displayed as header
       * format: {
       *  id: 'name',
       *  label: 'Name',
       *  size: Grid.ColumnSize,
       *  hideOverflow: true|false
       * }
       * @type {Array.<Object>}
       */
      columns: {
        type: Array,
        default: null,
      },
      /**
       * Items to display as rows
       * @type {Array.<Object>}
       */
      items: {
        type: Array,
        default: null,
      },
      /**
       * Column names displayed as header
       * format: { column: 'name', direction: 'asc' }
       * @type {Object}
       */
      orderBy: {
        type: Object,
        default: null,
      },
      /**
       * If 'true', the grid is considered as loading data and displays a
       * spinner instead of its content
       */
      loading: {
        type: Boolean,
        default: false,
      },
      /*
       * If 'true', the grid head will be always visible
       */
      stickyHead: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', some fake rows are displayed instead of an empty message
       */
      placeholders: {
        type: Boolean,
        default: false,
      },
      /**
       * Message to display when the grid is empty
       */
      empty: {
        type: String,
        default: 'Aucun élément disponible pour le moment',
      },
      /**
       * CSS class applied on every rows in the grid
       */
      rowClass: {
        type: String,
        default: undefined,
      },
      /**
       * CSS class applied on every cells in the grid (except header cells)
       */
      cellClass: {
        type: String,
        default: undefined,
      },
      /**
       * CSS class applied on each cell in the grid (except header cells)
       */
      headClass: {
        type: String,
        default: undefined,
      },
    },
    computed: {
      /**
       * Augment the column list in order to inject a 'order' attribute
       * @type {Array.<Object>}
       */
      orderedColumns() {
        const { columns, orderBy } = this

        if (!columns) {
          return []
        }

        return columns
          .filter(c => !c.hide)
          .map(c => ({
            ...c,
            order: !orderBy ? false : c.order || false,
            direction:
              orderBy && c.id === orderBy.column
                ? (orderBy.direction || ASC).toLowerCase()
                : undefined,
          }))
      },
      /**
       * Has 'true' value if the grid is actually empty (without items)
       * @type {Boolean}
       */
      isEmpty() {
        const { items } = this

        return !items || !items.length
      },
    },
    methods: {
      _range,
      /**
       * Compute the CSS classes of a header cell belonging to the provided
       * 'column' according to its properties
       * @param {Object} column
       * @returns {Object.<Boolean>}
       */
      getHeaderClasses(column) {
        const { size, order, direction, align } = column

        return {
          [`grid-cell--${size}`]: !!size,
          [`grid-cell--${align}`]: !!align,
          'grid-cell--order': !!order,
          [`grid-cell--${direction}`]: !!direction,
        }
      },
      /**
       * Compute the CSS classes of a list cell belonging to the provided
       * 'column' according to its properties
       * @param {Object} column
       * @returns {Object.<Boolean>}
       */
      getCellClasses(column) {
        const { cellClass } = this
        const { size, align, hideOverflow, class: columnClass } = column

        return {
          [`grid-cell--${size}`]: !!size,
          [`grid-cell--${align}`]: !!align,
          'grid-cell--overflow': !!hideOverflow,
          [columnClass]: !!columnClass,
          [cellClass]: !!cellClass,
        }
      },
      /**
       * Called when a column title is clicked. This will lead to the firing
       * of an 'order-change' event.
       * @param {Object} column
       * @returns {void}
       */
      onColumnPress(column) {
        const { orderBy } = this

        const { id } = column
        const direction = orderBy && orderBy.column === id && orderBy.direction === ASC ? DESC : ASC

        this.$emit('order-by', { column: id, direction })
        this.$emit('update:orderBy', { column: id, direction })
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .grid {
    display: flex
    flex-direction: column
    box-sizing: border-box

    .grid-cell {
      flex: 1
      padding: 0 8px
      line-height: 24px

      &.grid-cell--extra-small { flex: .25 }
      &.grid-cell--small { flex: .5 }
      &.grid-cell--medium { flex: 1 }
      &.grid-cell--large { flex: 1.5 }
      &.grid-cell--large { flex: 1.5 }
      &.grid-cell--extra-large { flex: 2 }
      &.grid-cell--left { text-align: left }
      &.grid-cell--center { text-align: center }
      &.grid-cell--right { text-align: right }
      &.grid-cell--overflow {
        ellipsis()
      }
    }

    .grid-head a,
    .grid-body > a {
      font-family: inherit
      font-size: inherit
      color: inherit
      white-space: nowrap
    }

    .grid-head {
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      padding: 0 24px
      border: 3px solid transparent

      &--sticky {
        position: sticky
        top: 0
        background: var(--color-background-dual)
        z-index: 2
      }

      .grid-cell {
        max-height: 24px
        padding: 0

        > a {
          display: block
          font-size: 11px
          font-weight: $font-medium
          line-height: 24px
          text-transform: uppercase
          letter-spacing: 2px
          color: var(--color-font-light)
        }

        i {
          display: inline-block
          position: relative
          top: 4px
          padding: 0 0 0 2px
          font-size: 16px
          vertical-align: top
          transition: transform .2s ease
          visibility: hidden
        }

        &.grid-cell--order {
          .app-link {
            cursor: pointer
          }

          &.grid-cell--asc,
          &.grid-cell--desc {
            color: var(--color-font)

            i {
              visibility: visible
            }
          }

          &.grid-cell--desc i {
            transform: rotateZ(180deg)
          }
        }
      }
    }

    .grid-loading,
    .grid-empty,
    .grid-placeholders,
    .grid-body {
      display: flex
      flex: 1
      flex-direction: column
      align-self: stretch

      .grid-row {
        height: 72px
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        padding: 0 24px
        margin: 16px 0 0
        border-left-style: solid
        border-left-width: 3px
        border-left-color: transparent
        border-radius: $radius-small
        background-color: var(--color-background)
        box-shadow: 0px 1px 2px var(--color-brand-transparent)

        &:not(.grid-row--placeholder):hover {
          border-left-color: var(--color-brand)
          box-shadow: 0 4px 8px rgba(26, 26, 26, .1)
        }

        &.grid-row--placeholder {
          user-select: none
        }

        .grid-cell--placeholder {
          height: 16px
          background: linear-gradient(
            to right,
            transparent 8px,
            var(--color-placeholder) 8px,
            var(--color-placeholder) 80%,
            transparent 80%
          )
          line-height: 16px

          &.grid-cell--center {
            linear-gradient(
              to right,
              transparent 10%,
              var(--color-placeholder) 10%,
              var(--color-placeholder) 90%,
              transparent 90%
            )
          }

          &.grid-cell--right {
            linear-gradient(
              to right,
              transparent 20%,
              var(--color-placeholder) 20%,
              var(--color-placeholder) 8px,
              transparent 8px
            )
          }
        }
      }
    }

    .grid-loading {
      .grid-row--placeholder {
        @keyframes rowFadeIn {
          from { opacity: .4 }
        }

        user-select: none
        opacity: 1
        animation: rowFadeIn 1s infinite alternate
      }

      &.grid-cell--placeholder {
        @keyframes cellFadeIn {
          from { opacity: .7 }
        }

        opacity: 1
        animation: cellFadeIn 1s infinite alternate
      }
    }

    .grid-placeholders {
      .grid-row--placeholder {
        opacity: .7
      }
    }

    .grid-empty {
      min-height: 300px
      align-items: center
      justify-content: center
      color: var(--color-placeholder)
      box-sizing: border-box
    }
  }
</style>
