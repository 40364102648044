<template>
  <TextField
    class="siret-field"
    :label="label || __('cp:fields:siret-field:siret')"
    :value="value"
    :placeholder="__('cp:fields:siret-field:placeholder')"
    :error="error"
    :helper="siren"
    :disabled="disabled"
    name="siret"
    @input="onInput"
  />
</template>

<script>
  import TextField from '@/core/fields/TextField/TextField'

  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  TextField: TextField.__enums,
},

    name: 'SiretField',
    components: {
      TextField,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Optional label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Siret's value
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Format the siren to display it as a helper
       * Make sure to remove the spaces to not display a wrong siren and then
       * format it XXX XXX XXX
       * @type {String}
       */
      siren() {
        const { __, value } = this
        const siren = value
          ? value
              .toString()
              .replace(/ /g, '')
              .slice(0, 9)
              .replace(/(.{3})/g, '$1 ')
          : null

        return __('cp:fields:siret-field:siren', { siren })
      },
    },
    methods: {
      /**
       * Fired when the select's value changes
       */
      onInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>
