/**
 * Team member action type enum
 */
const TeamMemberActionType = {
  CALL: 'call',
  REVIEW: 'review',
}

/**
 * Team member action reason enum
 */
const TeamMemberActionReason = {
  VALIDATION: 'validation',
  MATCH_LIST_REVIEW: 'matchListReview',
  PROFILES_SELECTION: 'profilesSelection',
  MEETING_DATES_SELECTION: 'meetingDatesSelection',
  MEETING_SLOT_SELECTION: 'meetingSlotSelection',
  FREELANCE_MEETING_FEEDBACK: 'freelanceMeetingFeedback',
  PROFILE_CONFIRMATION: 'profileConfirmation',
  FREELANCE_MAIN_TERMS_VALIDATION: 'freelanceMainTermsValidation',
  RATING: 'rating',
  LOW_RATING_JUSTIFICATION: 'lowRatingJustification',
  CUSTOM: 'custom',
}

/**
 * Team member action severity
 */
const TeamMemberActionSeverity = {
  NORMAL: 'normal',
  HIGH: 'high',
}

const TeamMemberTypes = {
  TeamMemberActionSeverity,
  TeamMemberActionType,
  TeamMemberActionReason,
}

export default TeamMemberTypes
