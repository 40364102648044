<template>
  <VSelect
    v-bind="$attrs"
    :value="value"
    :items="formattedItems"
    :class="classes"
    :menu-props="{
      ...$attrs.menuProps,
      contentClass: mini ? $style.mini : undefined,
    }"
    v-on="_omit($listeners, ['input'])"
    @input="onSelectInput"
  >
    <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="props">
      <div :key="JSON.stringify({ name, item: props.item })">
        <slot v-if="name && slot" :name="name" v-bind="props" />
      </div>
    </template>
  </VSelect>
</template>

<script>
  import _omit from 'lodash/omit'

  import { combo } from '@comet/utils'

  export default {
    name: 'CFieldSelect',
    inheritAttrs: false,
    props: {
      /**
       * Value passed to the VSelect without type conversion.
       * @type {String|Number}
       */
      value: {
        type: [String, Number],
        default: null,
      },
      /**
       * Built-ins VSelect's list of possible items (value/text pairs).
       * @type {Array.<Object>}
       */
      items: {
        type: [Array],
        default: null,
      },
      /**
       * Collection of the possible values.
       * @type {Array|Object}
       */
      options: {
        type: [Array, Object],
        default: null,
      },
      /**
       * Function formatting all the possible values passed in 'options'.
       * @type {Function}
       */
      formatter: {
        type: Function,
        default: null,
      },
      /**
       * Make the select smaller than the smallest Vuetify proposed size, with a smaller menu.
       * @type {Boolean}
       */
      mini: {
        type: Boolean,
        default: false,
      },
      // todo: this should not be in this component
      error: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied on the root element
       * @type {Object}
       */
      classes() {
        const { mini, error, $style } = this

        return {
          [$style.root]: true,
          [$style.mini]: mini,
          [$style.error]: error,
        }
      },
      /**
       * List of all the possible options as value/text pairs
       * @type {Array.<Object>}
       */
      formattedItems() {
        const { items, options, formatter } = this

        if (items) {
          return items
        }

        if (options && formatter) {
          return combo(options, formatter)
        }

        return null
      },
    },
    methods: {
      _omit,
      /**
       * Fired when the Select value changes
       * @param {String} text
       */
      onSelectInput(val) {
        // Nullish the result to avoid having "undefined" values then in a form, as Vuetify natively do.
        this.$emit('input', val !== undefined && val !== '' ? val : null)
      },
    },
  }
</script>

<style lang="stylus" module>
  :global(#app) {
    .root.mini {
      --input-min-height: 20px
      --input-padding-vertical: 0
      --input-padding-horizontal: 0

      &:global(.v-input.v-text-field.v-select) {
        width: 80px
        min-width: 80px
        max-width: 80px

        :global(> .v-input__control) {
          :global(> .v-input__slot) {
            color: currentColor !important
            border: none
            border-radius: 10px
            background: var(--color-grey-30) !important

            :global(> .v-select__slot) {
              :global(> .v-select__selections) {
                :global(.v-select__selection.v-select__selection--comma) {
                  position: relative
                  left: 4px
                  width: 100%
                  height: auto
                  margin: 0
                  font-size: 11px
                  text-align: center
                }

                input {
                  padding: 0
                }
              }

              :global(> .v-input__append-inner) {
                position: relative
                top: 1px
                left: -2px
                width: 1em
                height: 1em
                padding: 0
                align-self: center

                :global(> .v-input__icon.v-input__icon--append) {
                  :global(> .v-icon) {
                    font-size: 1em
                    color: currentColor !important
                    transform: none !important
                  }
                }
              }
            }
          }
        }

        &:global(.v-select--is-menu-active > .v-input__control > .v-input__slot > .v-select__slot > .v-input__append-inner > .v-input__icon.v-input__icon--append > .v-icon) {
          transform: rotate(180deg) !important
        }
      }
    }

    .mini:global(.v-menu__content){
      &:global(> .v-list.v-select-list) {
        padding: 4px 2px !important

        :global(> .v-list-item) {
          min-height: 24px !important
          max-height: 24px
          padding: 0 8px !important
          text-align: right

          :global(.v-list-item__title) {
            font-size: 12px
          }
        }
      }
    }

    // todo: this should not be in this component
    .root.error:global(.v-input.v-text-field.v-select > .v-input__control > .v-input__slot) {
      border: solid 1px var(--color-input-error) !important
      color: var(--color-input-error) !important
      background: var(--v-background-base) !important
      & :global(.v-select__selection) {
        color: var(--color-input-error) !important
      }
    }
  }
</style>
