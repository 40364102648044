<template>
  <div
    ref="dropdown"
    class="dropdown-menu"
    :class="{ active, 'with-picture': withPicture, 'with-label': !!label, vertical }"
  >
    <button :class="$('button')" @click.prevent="onButtonPress" @blur="onBlur">
      <ProfilePicture
        v-if="withPicture"
        :url="picture"
        :size="ProfilePicture.Size.XXXS"
        :border="active ? ProfilePicture.Border.PRIMARY : ProfilePicture.Border.BACKGROUND"
        :class="$('profile-img')"
        :role="role"
      />
      <template v-if="label">
        <Txt v-if="!$mq.phoneTablet" :size="labelSize" :value="label" :class="$('label')" />
      </template>

      <Icon v-if="displayIcon && !iconIsOld(icon)" :name="icon" :class="$('icon')" />
      <i v-else-if="displayIcon" :class="icon" />
    </button>
    <Transition name="slide-y">
      <ul v-if="active" ref="menu" :class="align">
        <li
          v-for="item in mappedMenu"
          :key="item.name"
          :class="{ 'dropdown-item-separator': item.separator }"
        >
          <i v-if="iconIsOld(item.icon)" :class="item.icon" class="dropdown-item-icon" />
          <Icon v-else-if="item.icon" :name="item.icon" class="dropdown-item-icon" />

          <AppLink
            :to="item.path"
            :blank="item.blank"
            :type="item.type ? item.type : item.blank ? 'external' : 'router'"
            :class="{ 'dropdown-menu--with-icon': !!item.icon, 'primary--text': item.highlighted }"
            @mousedown.native="onMenuItemPress(item)"
          >
            {{ item.name }}
          </AppLink>

          <div v-if="item.separator" />
        </li>
      </ul>
    </Transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import AppLink from '@/core/controls/AppLink/AppLink'
  import Txt from '@/core/text/Txt/Txt'
  import Icon from '@/core/graphics/Icon/Icon'

  import ProfilePicture from '@/components/freelancer/ProfilePicture/ProfilePicture'
  import RouterMixin from '@/mixins/RouterMixin'

  import { iconIsOld } from '@/utils/image'

  const { Size } = Txt

  const Align = {
    LEFT: 'left',
    RIGHT: 'right',
  }

  export const DropdownMenuAlign = Align

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  Icon: Icon.__enums,
  ProfilePicture: ProfilePicture.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Align,LabelSize:Size,
    name: 'DropdownMenu',
    __enums: {
      Align,
      LabelSize: Size,
    },
    components: {
      AppLink,
      Icon,
      ProfilePicture,
      Txt,
    },
    mixins: [RouterMixin],
    props: {
      /**
       * Label of the dropdown menu
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Size of the dropdown label
       */
      labelSize: {
        type: String,
        default: Size.XS,
      },
      /**
       * CSS class of the icon displayed close to the label
       */
      icon: {
        type: String,
        default: 'chevron-bottom',
      },
      /**
       * If 'true', a picture is displayed with the given 'picture' url
       * or a placeholder
       */
      withPicture: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the dropdown icon will be displayed vertically
       * or a placeholder
       */
      vertical: {
        type: Boolean,
        default: false,
      },
      /**
       * Path or URL of the picture
       */
      picture: {
        type: String,
        default: null,
      },
      /**
       * Structure describing the menu entries
       * @type {Array.<Object>}
       */
      menu: {
        type: Array,
        required: true,
      },
      /**
       * dropdown panel position ("left" or "right")
       */
      align: {
        type: String,
        default: 'left',
      },
      /**
       * If an action is required on item press instead of redirection
       */
      onPressItem: {
        type: Function,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Boolean describing if the dropdown panel
         * should be opened or not
         * @type {Boolean}
         */
        active: false,
      }
    },
    computed: {
      ...mapGetters({
        role: 'selectRole',
      }),
      /**
       * Display icon :
       * - If not on mobile and there except if there is an image with no label
       * - If there is no label and no picture
       * @returns {[type]} [description]
       */
      displayIcon() {
        const { $mq, withPicture, label } = this

        return (!label && !withPicture) || (!$mq.phoneTablet && !(withPicture && !label))
      },
      /**
       * Augmented menu entries.
       * @type {Array.<Object>}
       */
      mappedMenu() {
        const { route, menu } = this

        return menu.map(item => ({
          ...item,
          highlighted: route.fullPath === item.path,
        }))
      },
    },
    created() {
      // Reference on the timeout set to close the panel when the dropdown
      // lose its focus (see 'onBlur' for more details)
      this.tmo = 0
      // Delay to wait before to hide the panel according to what is clicked
      this.delay = 0
    },
    methods: {
      iconIsOld,
      /**
       * Fired when the dropdown button is clicked
       * @returns {void}
       */
      onButtonPress() {
        this.active = !this.active
      },
      /**
       * Call the right function according to the parameter given
       * to the compoent
       * @param {Object} item
       */
      onMenuItemPress(item) {
        const { onPressItem, onBlur } = this

        // Add a 500ms delay before to hide the panel, only if a "link" item
        // has been clicked
        this.delay = item && item.path ? 500 : 0

        if (onPressItem) {
          onPressItem(item.id)
        } else if (item.onPress) {
          item.onPress()
        } else {
          this.$emit('click', item.id)
        }

        // Force the blur of the dropdown button after emitting the click.
        // On iOS, the button keeps the focus even after selecting an item.
        onBlur()
      },
      /**
       * Fired when the dropdown button looses focus
       * @returns {void}
       */
      onBlur() {
        const { tmo, delay } = this

        if (tmo) {
          clearTimeout(this.tmo)
        }

        // If the panel is hidden too quickly when a "link" item (<a>) is
        // clicked, it has no effect (may be due to a browser prevention).
        // So we wait a delay (500ms) to let the link default behavior applies
        // before to hide the panel.
        // But as we don't want to wait if a "link" item has not been clicked,
        // we set a different delay in 'onPress' according to the case
        this.tmo = setTimeout(() => {
          this.active = false
        }, delay)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .dropdown-menu {
    align-items: center
    display: flex
    position: relative

    &.vertical {
      > button i,
      > button .dropdown-menu-icon {
        transform: rotate(90deg)
      }

      &.active {
        > button i,
        > button .dropdown-menu-icon {
          transform: rotateZ(270deg)
        }
      }
    }

    &-profile-img {
      >>> .profile-picture-image {
        border: 1px solid var(--color-background)
      }
    }

    & > button {
      height: 40px
      display: flex
      padding: 0
      border: none
      background-color: transparent
      font-weight: $font-regular

      & > ^[0]-label {
        display: inline-block
        font-size: 16px
        line-height: 40px
        text-align: left
        text-transform: none
        vertical-align: top
      }

      > i,
      > .dropdown-menu-icon {
        height: 40px
        position: absolute
        top: 0
        right: 8px
        bottom: 0
        line-height: 40px
        transition: transform 0.2s ease
      }

      > .dropdown-menu-icon {
        font-size: 18px
      }

      > i {
        font-size: 24px
      }
    }

    > ul {
      min-width: 100%
      position: absolute
      top: 40px
      padding: 8px !important
      margin: 16px 0
      background-color: var(--color-background)
      box-shadow: 0px 2px 8px rgba(13, 22, 35, .1), 0px 2px 16px rgba(13, 22, 35, .05)
      box-sizing: border-box
      list-style-type: none
      overflow-y: scroll
      z-index: 11

      li:not(.dropdown-item-separator) {
        text-align: left
        white-space: nowrap
        flex: 1
        align-items: center
        position: relative

        a {
          display: block
          padding: 8px 24px
          font-family: $font
          font-size: 16px
          cursor: pointer
        }

        a.dropdown-menu--with-icon {
          padding: 12px 24px 12px 40px
        }

        &:hover a {
          background-color: var(--color-brand-transparent)
          color: var(--color-brand) !important
        }

        .dropdown-item-icon {
          position: absolute
          bottom: calc(50% - 9px)
          left: 9px
          font-size: 14px
        }

        i.dropdown-item-icon {
          font-size: 18px
        }
      }

      li.dropdown-item-separator {
        height: 1px
        margin: auto
        background: var(--color-border)
        margin: 8px 24px
      }

      &.left {
        left: 0
      }

      &.right {
        right: 0
      }
    }

    &.active {
      > button {
        color: var(--color-brand) !important

        >>> .profile-picture-image {
          border-color: var(--color-brand)
        }

        > i,
        > .dropdown-menu-icon {
          transform: rotateZ(180deg)
        }
      }
    }

    &.with-label {
      > button {
        padding: 0 44px 0 20px
      }
    }

    &.with-picture {
      &.with-label {
        min-width: 200px

        ^[0]-profile-img {
          margin-right: 12px
        }

        > button {
          padding: 0 44px 0 10px
        }
      }


      > ul {
        margin: 16px 0 0
      }
    }
  }
</style>
