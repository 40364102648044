import _camelCase from 'lodash/camelCase'
import _get from 'lodash/get'
import _kebabCase from 'lodash/kebabCase'

/**
 * The main custom Vue plugin (applied to all the instanciated Vue components)
 */
const VueApp = {
  install(Vue) {
    /**
     * This custom 'core' component allows to display slots content in functional
     * components
     */
    Vue.component('VNode', {
      functional: true,
      render(h, context) {
        return context.props.node
      },
    })

    /**
     * The global mixin applied to all the instanciated Vue components
     */
    Vue.mixin({
      /**
       * Expose to this component all the enums declared by the injected
       * components (provided by our custom 'enums' Webpack plugin under the
       * '__childrenEnums' option)
       */
      beforeCreate() {
        // Looking for a protected '__childrenEnums' option in this component,
        // and if so, it injects these enums right in the instance ('this')
        const enums = _get(this, '$options.__childrenEnums')

        if (enums) {
          // If there are defined enums in the exposed components
          // let's add them to the instance:
          Object.entries(enums).forEach(e => {
            const [name, value] = e

            if (value) {
              this[name] = value
            }
          })
        }
      },
      methods: {
        /**
         * Useful to well format CSS module classe names.
         */
        _camelCase,
        /**
         * (DEPRECATED)
         * With the use of CSS module, we now don't need it anymore.
         *
         * Concat the name of the component to the given 'key' in a
         * hyphen-separated format. This is used to easily build prefixed
         * CSS selectors.
         * @param {String} key
         * @returns {String}
         */
        $(key) {
          const name = _kebabCase(this.$options.name)

          return key ? `${name}-${key}` : name
        },
      },
    })
  },
}

export default VueApp
