import { __ } from '@comet/i18n'

import CompanyTypes from '@/types/company'

const {
  LegalForm,
  Structure,
  InvoiceType,
  PaymentConditions,
  CompanyActivity,
  CompanyOwner,
  CompanyNeeds,
  CompanySize,
} = CompanyTypes

function formatCompanyStructure(key) {
  const trans = {
    [Structure.START_UP]: __('ty:company:structure:start-up'),
    [Structure.BIG_COMPANY]: __('ty:company:structure:big-company'),
    [Structure.NONE]: __('ty:company:structure:none'),
  }

  return trans[key] || undefined
}

function formatCompanyLegalForm(key, short = false) {
  const trans = {
    [LegalForm.AE]: short ? __('ty:company:legal-form:short:ae') : __('ty:company:legal-form:ae'),
    [LegalForm.EI]: short ? __('ty:company:legal-form:short:ei') : __('ty:company:legal-form:ei'),
    [LegalForm.EIRL]: short
      ? __('ty:company:legal-form:short:eirl')
      : __('ty:company:legal-form:eirl'),
    [LegalForm.EURL]: short
      ? __('ty:company:legal-form:short:eurl')
      : __('ty:company:legal-form:eurl'),
    [LegalForm.NONE]: short
      ? __('ty:company:legal-form:short:none')
      : __('ty:company:legal-form:none'),
    [LegalForm.OTHER]: short
      ? __('ty:company:legal-form:short:other')
      : __('ty:company:legal-form:other'),
    [LegalForm.SA]: short ? __('ty:company:legal-form:short:sa') : __('ty:company:legal-form:sa'),
    [LegalForm.SARL]: short
      ? __('ty:company:legal-form:short:sarl')
      : __('ty:company:legal-form:sarl'),
    [LegalForm.SAS]: short
      ? __('ty:company:legal-form:short:sas')
      : __('ty:company:legal-form:sas'),
    [LegalForm.SASU]: short
      ? __('ty:company:legal-form:short:sasu')
      : __('ty:company:legal-form:sasu'),
    [LegalForm.SAU]: short
      ? __('ty:company:legal-form:short:sau')
      : __('ty:company:legal-form:sau'),
    [LegalForm.SOCIETE_DROIT_ETRANGER]: short
      ? __('ty:company:legal-form:short:societeDroitEtranger')
      : __('ty:company:legal-form:societeDroitEtranger'),
    [LegalForm.GIE]: short
      ? __('ty:company:legal-form:short:gie')
      : __('ty:company:legal-form:gie'),
  }

  return trans[key] || undefined
}

function formatInvoiceType(key) {
  const trans = {
    [InvoiceType.EMAIL]: __('ty:company:invoice-type:email'),
    [InvoiceType.PAPIER]: __('ty:company:invoice-type:paper'),
    [InvoiceType.EMAIL_PAPIER]: __('ty:company:invoice-type:email-paper'),
    [InvoiceType.PLATFORM]: __('ty:company:invoice-type:platform'),
  }

  return trans[key] || undefined
}

function formatPaymentConditions(key) {
  const trans = {
    [PaymentConditions.UPON_RECEIPT]: __('ty:company:payment-conditions:upon-receipt'),
    [PaymentConditions.END_OF_MONTH]: __('ty:company:payment-conditions:end-of-month'),
    [PaymentConditions.FIFTEENTH_OF_NEXT_MONTH]: __(
      'ty:company:payment-conditions:fifteenth-of-next-month',
    ),
  }

  return trans[key] || undefined
}

function formatActivity(key) {
  const trans = {
    [CompanyActivity.LIBERAL]: __('ty:company:activity:liberal'),
    [CompanyActivity.COMMERCIAL]: __('ty:company:activity:commercial'),
  }

  return trans[key] || undefined
}

function formatCompanyOwner(key) {
  const trans = {
    [CompanyOwner.FREELANCER]: __('ty:company:owner:freelancer'),
    [CompanyOwner.CORPORATE]: __('ty:company:owner:corporate'),
  }

  return trans[key] || undefined
}

function formatCompanyNeeds(key) {
  const trans = {
    [CompanyNeeds.NONE]: __('ty:company:needs:none'),
    [CompanyNeeds.ONE_FOUR]: '1-4',
    [CompanyNeeds.FIVE_NINE]: '5-9',
    [CompanyNeeds.TEN_TWENTYFOUR]: '10-24',
    [CompanyNeeds.TWENTYFIVE_FORTYNINE]: '25-49',
    [CompanyNeeds.FIFTY_MORE]: '50+',
  }

  return trans[key] || undefined
}

function formatCompanySize(key) {
  const trans = {
    [CompanySize.NINE]: '1-9',
    [CompanySize.FORTY_NINE]: '10-49',
    [CompanySize.NINETY_NINE]: '50-99',
    [CompanySize.TWO_HUNDRED_FORTY_NINE]: '100-249',
    [CompanySize.FOUR_HUNDRED_NINETY_NINE]: '250-499',
    [CompanySize.NINE_HUNDRED_NINETY_NINE]: '500-999',
    [CompanySize.FOUR_THOUSAND_NINE_HUNDRED_NINETY_NINE]: '1000-4999',
    [CompanySize.FIVE_THOUSANDS]: '5000+',
  }

  return trans[key] || undefined
}

/**
 * Format a hit ratio object
 * @param {Object} hitRatio
 * @returns {String}
 */
function formatHitRatio(hitRatio) {
  if (!hitRatio) {
    return '-'
  }

  const { numerator, denominator, value } = hitRatio

  if (!value) {
    return `-`
  }

  return `${numerator} / ${denominator} · ${Math.round(value * 100)}%`
}

export {
  formatActivity,
  formatCompanyLegalForm,
  formatCompanyStructure,
  formatInvoiceType,
  formatPaymentConditions,
  formatCompanyOwner,
  formatCompanyNeeds,
  formatCompanySize,
  formatHitRatio,
}
