<template>
  <div class="expandable-text">
    <div
      ref="text"
      :class="{ 'expandable-text--long': isTextHidden }"
      :style="isTextHidden ? expandableTextStyle : null"
    >
      <Txt v-bind="$attrs" :value="value" :theme="theme" />
    </div>

    <AppLink
      v-if="needButton"
      type="void"
      :text="showButton"
      :size="AppLink.Size.XXS"
      :theme="Txt.Theme.BRAND"
      @click="onChangeTextSize"
    />
  </div>
</template>

<script>
  import AppLink from '@/core/controls/AppLink/AppLink'
  import Txt from '@/core/text/Txt/Txt'

  import I18nMixin from '@/mixins/I18nMixin'

  const { Size, Theme } = Txt

  // Height (in px) of one line of text display in the component
  const LINE_HEIGHT = 20

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,Theme,
    name: 'ExpandableText',
    __enums: {
      Size,
      Theme,
    },
    components: {
      AppLink,
      Txt,
    },
    mixins: [I18nMixin],
    inheritAttrs: false,
    props: {
      /**
       * Text to display in the component
       */
      value: {
        type: String,
        required: true,
      },
      /**
       * Number of text lines to display when text is hidden
       */
      lines: {
        type: Number,
        default: 2,
      },
      /**
       * Text theme
       */
      theme: {
        type: String,
        default: Theme.GREY,
      },
    },
    data() {
      return {
        /**
         * Boolean if we show the button to hide / show the text
         */
        needButton: false,
        /**
         * Boolean if the text is shown or not
         */
        isTextHidden: false,
      }
    },
    computed: {
      /**
       * Compute the wording of the button if the description is hidden or not
       * @type {String}
       */
      showButton() {
        const { isTextHidden, __ } = this

        return isTextHidden
          ? __('cp:common:expandable-text:show-more')
          : __('cp:common:expandable-text:show-less')
      },
      /**
       * Expandable text style (max-height depending of prop lines)
       * @type {Object}
       */
      expandableTextStyle() {
        const { lines } = this

        return { 'max-height': `calc(${lines} * ${LINE_HEIGHT}px)` }
      },
    },
    watch: {
      value: {
        immediate: true,
        handler() {
          // When the value is changed, we show the text to the user
          this.isTextHidden = false

          // Then we wait the next tick to be sure the text is correctly displayed
          // and we check if the text still need a button
          this.$nextTick(() => this.checkTextHeight())
        },
      },
    },
    methods: {
      /**
       * Check if we need to display the button or not
       * @returns {void}
       */
      checkTextHeight() {
        const { $refs, value, lines } = this
        const textHeight = $refs.text ? $refs.text.clientHeight : 0

        this.needButton = textHeight > lines * LINE_HEIGHT
        this.isTextHidden = this.needButton

        // When rendered inside menus/modals, the text height is 0 at first
        // When it's the case, trigger the computation a bit later.
        if (textHeight === 0 && value) {
          setTimeout(() => this.checkTextHeight(), 100)
        }
      },
      /**
       * Hide / show the text
       * @returns {void}
       */
      onChangeTextSize() {
        const { isTextHidden } = this
        const event = isTextHidden ? 'expand' : 'collapse'

        this.$emit(event)

        this.isTextHidden = !isTextHidden
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .expandable-text {
    $line-height = 20px

    :first-child {
      white-space: pre-line
      line-height: $line-height

      &.expandable-text--long {
        overflow: hidden
      }
    }

    .app-link {
      text-decoration: underline
      color: var(--color-brand)
      cursor: pointer
    }
  }

  @media print {
    .expandable-text {
      :first-child {
        &.expandable-text--long {
          overflow: visible
          max-height: unset !important
        }
      }

      .app-link {
        display: none
      }
    }
  }
</style>
