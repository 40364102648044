<template>
  <div>
    <slot />

    <template v-if="!$slots.default">
      <div v-for="item in items" :key="item.id">
        <slot v-if="item" name="item" :item="item" />
      </div>
    </template>

    <Spinner v-if="loading" :class="$style.spinner" />

    <InfiniteLoading
      ref="infiniteLoading"
      :key="identifier"
      :identifier="identifier"
      :force-use-infinite-wrapper="false"
      @infinite="onBottomReached"
    >
      <template slot="spinner">
        <Spinner v-if="items && items.length && !loading" :class="$style.spinner" />
        <span v-else />
      </template>

      <template slot="no-results">
        <slot name="empty">
          <span class="quote small">
            {{ showEnd ? __('cp:infinite-scroll:no-results') : '' }}
          </span>
        </slot>
      </template>

      <template slot="no-more">
        <slot name="ended">
          <span class="quote small">
            {{ showEnd ? __('cp:infinite-scroll:no-more-results') : '' }}
          </span>
        </slot>
      </template>
    </InfiniteLoading>
  </div>
</template>

<script>
  import _isEqual from 'lodash/isEqual'

  import InfiniteLoading from './VueInfiniteLoading/VueInfiniteLoading'

  import ClockMixin from '@/mixins/ClockMixin'
  import I18nMixin from '@/mixins/I18nMixin'

  import Spinner from '@/core/controls/Spinner/Spinner'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  InfiniteLoading: InfiniteLoading.__enums,
  Spinner: Spinner.__enums,
},

    name: 'InfiniteScroll',
    components: {
      InfiniteLoading,
      Spinner,
    },
    mixins: [ClockMixin, I18nMixin],
    props: {
      /**
       * The list of loaded elements
       */
      items: {
        type: Array,
        default: null,
      },
      /**
       * The maximum number of elements
       * If ommited, InfiniteScroll will rely on the 'items' collection changes
       */
      total: {
        type: Number,
        default: -1,
      },
      /**
       * Whether to display a message at the bottom of the list
       */
      showEnd: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      const { now } = this

      return {
        /**
         * The identifier set each time the component is created.
         * This ensures that the InfiniteLoading component will be well
         * reset when re-drawing.
         * @type {String}
         */
        identifier: now().unix(),
      }
    },
    watch: {
      items: {
        deep: true,
        immediate: true,
        handler(next, prev) {
          const { infiniteLoading } = this.$refs
          const { total } = this

          // Notify ininite-loader each time it receives items if its the end
          // of the list or not
          if (next && infiniteLoading) {
            const { loaded, complete, reset } = infiniteLoading.stateChanger

            if (prev && prev.length > next.length) {
              reset()
            }

            if (total !== null && total !== undefined && total > 0) {
              if (next.length < total) {
                loaded()
              } else {
                complete()
              }
            } else if (next.length && !_isEqual(next, prev)) {
              loaded()
            } else {
              complete()
            }
          }
        },
      },
    },
    mounted() {
      const { now } = this
      const { infiniteLoading } = this.$refs

      this.identifier = now().unix()

      if (infiniteLoading) {
        infiniteLoading.stateChanger.reset()
      }
    },
    methods: {
      /**
       * Handler called when reaching the bottom of the list
       * @returns {void}
       */
      onBottomReached(state) {
        const { items } = this

        if (items) {
          this.$emit('bottom-reached', state)
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  .spinner {
    margin: 8px 0 24px
  }
</style>
