/* eslint-disable no-param-reassign */
import Vue from 'vue'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'

export const SYNC_FORM_CREATE = 'SYNC_FORM_CREATE'
export const SYNC_FORM_UPDATE = 'SYNC_FORM_UPDATE'
export const SYNC_FORM_DESTROY = 'SYNC_FORM_DESTROY'

const module = {
  // ====================  STATE  ==================== //

  state: {},

  // ====================  GETTERS  ==================== //

  getters: {
    /**
     * Return all the info of the form identified by 'name'
     * @type {Object}
     */
    selectSyncFormValues(state) {
      return name => _get(state, `${name}.fields`, null)
    },
    /**
     * Return 'true' if the form identified by 'name' is actually valid.
     * Else 'false'.
     * @type {Boolean}
     */
    isSyncFormValid(state) {
      return name => !!state[name] && !state[name].invalid
    },
  },

  // ====================  MUTATIONS  ==================== //

  mutations: {
    /**
     * Form initialisation
     */
    [SYNC_FORM_CREATE](state, form) {
      const { name } = form

      // If the form already exists, we take care to keep what would be
      // stored in the local storage.
      const existing = state[name] || {}

      Vue.set(state, name, {
        ..._cloneDeep(form),
        ...existing,
      })
    },
    /**
     * Form modifications (one field or more at a time)
     */
    [SYNC_FORM_UPDATE](state, form) {
      const { name } = form
      const synced = state[name] || {}

      Vue.set(state, name, {
        ...synced,
        ..._cloneDeep(form),
      })
    },
    /**
     * Destroy the form
     */
    [SYNC_FORM_DESTROY](state, name) {
      Vue.delete(state, name)
    },
  },

  // ====================  ACTIONS  ==================== //

  actions: {
    /**
     * Initialize a new form from the given 'name'
     * @param {Object} context
     * @param {Object} form
     * @returns {Promise}
     */
    createSyncForm({ commit }, form) {
      commit(SYNC_FORM_CREATE, form)
    },
    /**
     * Update the field identified by the given 'name'
     * with the new given 'value'. Also update all meta
     * data stored ($dirty, $invalid, ...)
     * @param {Object} context
     * @param {Object} form
     * @returns {Promise}
     */
    updateSyncForm({ commit }, form) {
      commit(SYNC_FORM_UPDATE, form)
    },
    /**
     * Remove the form identified by the given 'name' from
     * the store
     * @param {Object} context
     * @param {String} name
     * @returns {Promise}
     */
    destroySyncForm({ commit }, name) {
      commit(SYNC_FORM_DESTROY, name)
    },
  },
}

export default module
