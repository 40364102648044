<template>
  <Modal
    class="file-upload-modal-layout"
    :title="title || __('md:layout:file-upload-modal-layout:title')"
    :size="step === 2 ? size : 'small'"
  >
    <div :class="$('content')">
      <div v-if="step === 1" :class="$('step-1')">
        <form enctype="multipart/form-data">
          <img
            :class="$('icon')"
            src="../../../assets/images/illustrations/file.svg"
            alt="File icon"
          />

          <slot name="step-1" />

          <Txt
            :class="[$('size'), { 'file-upload-modal-layout-size-error': sizeError }]"
            :value="
              __('md:layout:file-upload-modal-layout:size:error', {
                maxFileSize,
                extensions,
              })
            "
          />

          <Txt
            v-if="form.errors.file"
            :class="$('mime-type')"
            :value="form.errors.file"
            :size="Txt.Size.XXXS"
            :theme="Txt.Theme.DANGER"
          />

          <NavButton
            :theme="NavButton.Theme.PRIMARY"
            :text="__('md:layout:file-upload-modal-layout:action:upload')"
          />

          <input
            type="file"
            :accept="mimeTypes"
            :class="$('upload-input')"
            @change="onFileChange"
          />
        </form>
      </div>

      <div v-if="step === 2" :class="$('step-2')">
        <div :class="$('info')">
          <div v-if="!edit" :class="$('file')">
            <Heading
              :level="Heading.Level.LEVEL4"
              :ellipsis="false"
              :text="__('md:layout:file-upload-modal-layout:file:title')"
            />
            <div :class="$('file-wrapper')">
              <img
                :class="$('icon')"
                src="../../../assets/images/illustrations/file.svg"
                alt="File icon"
              />
              <Txt
                v-if="form.fields.file"
                :class="$('name')"
                :value="form.fields.file.name"
                :size="Txt.Size.XS"
              />
            </div>
          </div>

          <slot name="step-2" />
        </div>

        <div :class="$('submit')">
          <NavButton
            v-if="!edit"
            theme="secondary"
            left-icon="icon-arrow-left"
            :disabled="status === FILE_STATUS.PENDING"
            @click.prevent="onBack"
          >
            {{ __('md:layout:file-upload-modal-layout:action:back') }}
          </NavButton>

          <NavButton
            :theme="NavButton.Theme.PRIMARY"
            :loading="status === FILE_STATUS.PENDING"
            :disabled="!isParentValid"
            :on-click="onValidate"
          >
            {{ __('md:layout:file-upload-modal-layout:action:submit') }}
          </NavButton>
        </div>
      </div>

      <div v-if="step === 3" :class="$('step-3')">
        <img
          :class="$('icon')"
          src="../../../assets/images/illustrations/file.svg"
          alt="File icon"
        />
        <Txt
          :value="__('md:layout:file-upload-modal-layout:success:text')"
          :size="Txt.Size.XS"
          :align="Txt.Align.CENTER"
        />
        <NavButton :theme="NavButton.Theme.PRIMARY" @click.prevent="dismissModal('uploaded')">
          {{ __('md:layout:file-upload-modal-layout:action:close') }}
        </NavButton>
      </div>

      <Pager v-if="!edit" :count="3" :value="step" :limit="0" theme="light" />
    </div>
  </Modal>
</template>

<script>
  import _get from 'lodash/get'
  import _flatten from 'lodash/flatten'

  import Heading from '@/core/text/Heading/Heading'
  import Modal from '@/core/layout/Modal/Modal'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import Pager from '@/core/layout/Pager/Pager'
  import Txt from '@/core/text/Txt/Txt'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import FormMixin from '@/mixins/FormMixin'
  import I18nMixin from '@/mixins/I18nMixin'
  import ModalMixin from '@/mixins/ModalMixin'

  import { remote } from '@/utils/validators'
  import AppTypes from '@/types/app'

  const DEFAULT_MAX_SIZE = 10 // Mo

  const MIME_TYPES = {
    jpg: ['image/jpeg', 'image/jpg'],
    png: ['image/png'],
    pdf: ['application/pdf'],
    csv: ['text/csv'],
    xlsx: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  }

  const FILE_STATUS = {
    NEW: 'new',
    PENDING: 'pending',
    DONE: 'done',
  }

  /**
   * Error codes returned by the API when something goes wrong
   * @type {String}
   */
  const { INVALID_FILE_TYPE } = AppTypes.AppError

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Heading: Heading.__enums,
  Modal: Modal.__enums,
  NavButton: NavButton.__enums,
  Pager: Pager.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ MIME_TYPES,
    name: 'FileUploadModalLayout',
    __enums: {
      MIME_TYPES,
    },
    components: {
      Heading,
      Modal,
      NavButton,
      Pager,
      Txt,
    },
    mixins: [ApolloMixin, FormMixin, I18nMixin, ModalMixin],
    props: {
      /**
       * Main title of the modal
       */
      title: {
        type: String,
        default: null,
      },
      /**
       * Size of the modal container
       */
      size: {
        type: String,
        default: 'small',
      },
      /**
       * Skip the file upload step
       * @type {Boolean}
       */
      edit: {
        type: Boolean,
        default: false,
      },
      /**
       * Upload function allowing to send the file and its related info.
       * It must return a promise.
       */
      onSubmit: {
        type: Function,
        required: true,
      },
      /**
       * Check if the form used in the parent component is valid or not
       * @type {Object}
       */
      isParentValid: {
        type: Boolean,
        default: true,
      },
      /**
       * List of valid mime types
       */
      acceptedMimeTypes: {
        type: Object,
        default: () => MIME_TYPES,
      },
      /**
       * Max allowed file size
       */
      maxFileSize: {
        type: Number,
        default: DEFAULT_MAX_SIZE,
      },
    },
    constants: {
      FILE_STATUS,
      MIME_TYPES,
    },
    data() {
      return {
        form: {
          name: 'file-upload-modal-layout',
          fields: {
            file: null,
          },
          validators: {
            file: { remote: remote(this, INVALID_FILE_TYPE) },
          },
        },
        /**
         * File status
         * @type {String}
         */
        status: FILE_STATUS.NEW,
        /**
         * Error if the file is too big
         * @type {Boolean}
         */
        sizeError: false,
        /**
         * Error if the file type is not supported
         * @type {Boolean}
         */
        mimeTypeError: false,
      }
    },
    computed: {
      /**
       * Current step in the upload process (select file, give more info, ...)
       * @returns {Number}
       */
      step() {
        const { form, status, edit } = this

        switch (true) {
          case status === FILE_STATUS.DONE:
            return 3
          case !!_get(form, 'fields.file') && !form.errors.file:
          case edit:
            return 2
          default:
            return 1
        }
      },
      /**
       * Joined list of accepted extensions (depending on mime types)
       * @return {String}
       */
      extensions() {
        const { acceptedMimeTypes } = this

        return Object.keys(acceptedMimeTypes).join(', ')
      },
      /**
       * List of valid mime types used for file input
       * @return {Array.String}
       */
      mimeTypes() {
        const { acceptedMimeTypes } = this

        return _flatten(Object.values(acceptedMimeTypes))
      },
    },
    methods: {
      /**
       * Keep the file before upload it
       */
      onFileChange(e) {
        const { maxFileSize } = this
        const [file] = e.target.files

        if (file) {
          const overweight = file.size > maxFileSize * 1024 * 1024

          this.sizeError = overweight
          this.form.fields.file = overweight ? null : file
        }
      },
      /**
       * Submit the selected file
       * @returns {Promise}
       */
      onValidate() {
        const { onSubmit, edit, dismissModal, populateMutationErrors } = this

        this.status = FILE_STATUS.PENDING

        return onSubmit(_get(this, 'form.fields.file'))
          .then(() => {
            if (edit) {
              dismissModal('updated')
              return
            }
            this.status = FILE_STATUS.DONE
          })
          .catch(e => {
            const { graphQLErrors = [] } = e

            populateMutationErrors(graphQLErrors)
            this.status = FILE_STATUS.NEW
          })
      },
      /**
       * Go to one step back
       */
      onBack() {
        const { step } = this

        if (step === 2) {
          this.form.fields.file = null
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_text.styl'

  .file-upload-modal-layout {
    &-content {
      .file-upload-modal-layout-icon {
        margin: 0 auto 24px
      }
    }

    &-name {
      margin: 0 10px
    }

    &-upload-input {
      z-index: 4
    }

    [class^="file-upload-modal-layout-step-"] {
      display: flex
      flex-direction: column
    }

    &-step-1 {
      text-align: center

      & ^[0]-size,
      & ^[0]-mime-type {
        margin-bottom: 35px

        &-error {
          form-error()
          text-align: center
        }
      }

      input[type=file] {
        position: absolute
        width: 100%
        top: 0
        left: 0
        right: 0
        bottom: 0
        height: auto
        opacity: 0
        cursor: pointer
      }
    }

    &-step-2 {
      & ^[0]-info {
        display: flex
        flex-direction: row

        > >>> div {
          min-width: 50%
        }

        & ^[0]-file {
          display: flex
          flex-direction: column
          flex: 1
          text-align: center

          &-wrapper {
            margin-top: 12px
          }
        }
      }

      & ^[0]-submit {
        display: flex
        margin: 40px auto 0

        .nav-button {
          min-width: 140px

          &:not(:last-child) {
            margin-right: 24px
          }
        }
      }
    }

    &-step-3 {
      .nav-button {
        margin: 32px auto 0
      }
    }

    .pager {
      margin-top: 50px
    }
  }
</style>
