import _get from 'lodash/get'
import _intersection from 'lodash/intersection'
import _isFunction from 'lodash/isFunction'
import _omit from 'lodash/omit'
import _union from 'lodash/union'
import _values from 'lodash/values'
import querystring from 'querystring'

import { __ } from '@comet/i18n'

import AppTypes from '@/types/app'
import UserTypes from '@/types/user'
import { extractEnvFromRoute } from '@/utils/app'
import { formatQuery } from '@/utils/transform/path'
import { int } from '@/utils/transform'

import { Amplify, Auth, Hub } from 'aws-amplify'
import { getFreelancerAmplifyConf, getTeamMemberAmplifyConf, generateAmplifyConf } from '../config'

import config from '@/config'

Hub.listen('auth', data => {
  if (data.payload.event === 'parsingCallbackUrl') {
    if (data.payload?.data?.url) {
      const splittedUrl = data.payload.data.url.split('?')
      if (splittedUrl.length > 1) {
        const payloadUrl = splittedUrl[0]
          .split('/')
          .slice(0, 4)
          .join('/')

        const currentAmplifyConfig = Amplify.configure()
        if (payloadUrl !== currentAmplifyConfig.oauth.redirectSignIn) {
          Amplify.configure(generateAmplifyConf(payloadUrl))
        }
      }
    }
  } else if (data.payload.event === 'customOAuthState') {
    /**
     * We send the successRedirectLink to have a return to the asked page after the login
     */
    const cognitoStates = querystring.parse(data.payload.data)
    Amplify.configure(getFreelancerAmplifyConf(Boolean(cognitoStates.linkToUser)))
    if (cognitoStates.provider === 'linkedIn') {
      window.location.href = `${config.urls.api}/auth/linkedin/callback?${data.payload.data}`
    } else {
      window.location.href = `${config.urls.api}/auth/freelance/google/callback?${data.payload.data}`
    }
  }
})

/**
 * - Error code when the user try to log in but he is already log in
 * - Error code when the link on the validation email has expired
 */
const { ALREADY_LOGGED_IN, INVALID_TOKEN } = AppTypes.AppError

/**
 * Router log activation / deactivation
 * @type {Boolean}
 */
const LOGS_ENABLED = config.logs.router

/**
 * Redirect to the 404 page if the destination URL is unknown, and
 * so return 'true'. If it is actually known, returns 'false'.
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @returns {Boolean}
 */
function redirectIfUnknownRoute(to, from, next) {
  if (!to.matched || !to.matched.length) {
    if (LOGS_ENABLED) {
      console.warn('[Router] Redirect - Unknown (guard)', to)
    }

    // Redirect to the 404 page
    next({ path: '/404', replace: true })
    return true
  }

  return false
}

/**
 * If the server send an error 452, it means the user is already logged in.
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @returns {Boolean}
 */
function redirectIfUserAlreadyLoggedIn(to, from, next) {
  if (int(to.query.apperror) === ALREADY_LOGGED_IN) {
    const env = extractEnvFromRoute(to)

    next({ path: `${env}/redirect` })
    return true
  }

  return false
}

/**
 * If the user accesses a "private" route and is not authenticated or does not
 * have the expected role, he is redirected to the "redirect" url specified in
 * the routes or by default on the sign-in page of the current env
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @param {Object} store
 * @returns {Boolean}
 */
async function redirectIfUnauthorizedForPrivateRoute(to, from, next, store) {
  const { dispatch, getters } = store
  const { isAuthenticated } = getters
  const env = extractEnvFromRoute(to)

  // Look if one of the routes or parents is flagged as "private"
  const restricted = to.matched.some(route => !!route.meta.private)

  if (!restricted) {
    return false
  }

  if (!isAuthenticated) {
    let dest = null

    if (LOGS_ENABLED) {
      console.warn('[Router] Redirect - Access a private route without authentication (guard)', to)
    }

    // If we are in the "backoffice" env, redirect straight to Google auth
    if (env === AppTypes.AppEnv.BACKOFFICE) {
      let currentUser
      let successRedirectLink

      Amplify.configure(getTeamMemberAmplifyConf())

      /**
       * Check if we are already login with amplify
       */
      try {
        currentUser = await Auth.currentAuthenticatedUser()
      } catch (error) {
        currentUser = undefined
      }

      /**
       * We prepare for redirection
       */
      if (currentUser) {
        successRedirectLink = `${config.publicURL}/backoffice/redirect${formatQuery({
          to: to.path,
        })}`

        try {
          /**
           * We send the successRedirectLink to have a return to the asked page after the login
           */
          window.location.href = `${config.urls.api}/auth/google/callback${formatQuery({
            to: successRedirectLink,
          })}`
        } catch (err) {
          next({ path: '/' })
        }

        return true
      } else {
        await Auth.federatedSignIn({ provider: 'Google' })
      }
    } else {
      const redirect = to.matched.reduce(
        (result, route) => route.meta.redirect || result,
        undefined,
      )

      if (redirect) {
        dest = redirect
      }

      // Else redirect to the sign-in page of the current env
      if (!dest) {
        dest = `${env}/signup`
      }

      // Do a logout action (which also remove an eventually expired cookie)
      dispatch('revoke')

      next({
        path: dest,
        query: {
          ...to.query,
          modal: undefined, // if we are not auth, no modal should be displayed on signin (fix the bug when a free is redirected to signin with a modal https://gitlab.com/comet11/mars/-/issues/93)
          to: `${to.path}${formatQuery(_omit(to.query, 'to'))}`,
        },
      })
      return true
    }
  } else {
    if (env === AppTypes.AppEnv.BACKOFFICE) {
      Amplify.configure(getTeamMemberAmplifyConf())
      try {
        await Auth.currentAuthenticatedUser()
      } catch (error) {
        await Auth.federatedSignIn({ provider: 'Google' })
      }
    }
  }

  // Find the authorized roles among all matched private routes
  const requiredRoles = to.matched.reduce((result, route) => {
    const isPrivate = _get(route, 'meta.private', false)
    const authorized = _get(route, 'meta.authorized', [])

    return isPrivate && authorized.length ? _intersection(result, authorized) : result
  }, _values(UserTypes.UserRole))

  const isAuthorized = requiredRoles.includes(getters.selectRole)

  if (!isAuthorized) {
    if (LOGS_ENABLED) {
      console.warn(
        '[Router] Redirect - Not authorized to access the private route regarding the role (guard)',
        to,
      )
    }

    next({ path: '/redirect', query: { env } })
    return true
  }

  return false
}

/**
 * Check if the route is protected by role and is public. If so, then
 * check if the user role match the route role otherwise redirect
 * to the correct env for the role
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @param {Object} store
 * @returns {Boolean}
 */
function redirectIfUnauthorizedForPublicRoute(to, from, next, store) {
  const { isAuthenticated, selectRole: userRole } = store.getters

  // Find the authorized roles among all matched public routes
  const requiredRoles = to.matched.reduce((result, route) => {
    const isPublic = !_get(route, 'meta.private', false)
    const authorized = _get(route, 'meta.authorized', [])

    return isPublic && authorized.length ? authorized : result
  }, [])

  // Determines if the user can access this public route (i.e. is not logged-in
  // under another role, not authorized here)
  const hasAccess = !isAuthenticated || !requiredRoles.length || requiredRoles.includes(userRole)

  if (!hasAccess) {
    if (LOGS_ENABLED) {
      console.warn(
        '[Router] Redirect - Not authorized to access the public route regarding the role (guard)',
        to,
      )
    }

    next({ path: '/redirect' })
    return true
  }

  return false
}

/**
 * Check if the user has the permission to access the page
 * @param {Object}    to
 * @param {Object}    from
 * @param {Function}  next
 * @param {Object}    store
 * @returns {Boolean}
 */
function redirectIfNotAllowed(to, from, next, store) {
  const { hasPermission } = store.getters

  const requiredPermissions = to.matched.reduce((result, route) => {
    const permissions = _get(route, 'meta.permissions', undefined)

    return _union(result, permissions)
  }, [])

  const isAllowed =
    !requiredPermissions || !requiredPermissions.length || hasPermission(requiredPermissions)

  if (!isAllowed) {
    if (LOGS_ENABLED) {
      console.warn(
        '[Router] Redirect - Not allowed to access the route regarding the permissions (guard)',
        to,
      )
    }

    next({ path: '/redirect' })
    return true
  }

  return false
}

/**
 * If the server send an error 554, it means the user clicked on a link
 * from an email, with an expired token.
 * @param {Object} to
 * @param {Object} from
 * @param {Function} next
 * @returns {Boolean}
 */
function redirectIfInvalidToken(to, from, next) {
  if (int(to.query.apperror) === INVALID_TOKEN) {
    if (LOGS_ENABLED) {
      console.warn('[Router] Redirect - Token invalid or expired (guard)', to)
    }

    next({
      path: '/',
      query: {
        modal: 'informative-modal',
        params: JSON.stringify({
          title: __('cp:app:error:invalid-token'),
          theme: 'danger-alt',
        }),
      },
    })
    return true
  }

  return false
}

/**
 * Check if the user (freelancer so far) has the right to access the Page
 * according to its status
 * @param {Object}    to
 * @param {Object}    from
 * @param {Function}  next
 * @param {Object}    store
 * @returns {Boolean}
 */
function redirectIfNotFreelancerStatus(to, from, next, store) {
  const status = _get(store, 'getters.selectFreelancer.status', null)

  if (status) {
    // Get all the "banned" and "only" status along the matched routes
    const restrictions = to.matched.reduce(
      (result, route) => {
        const banned = _get(route, 'meta.banned', [])
        const only = _get(route, 'meta.only', [])

        return {
          banned: [...result.banned, ...banned],
          only: [...result.only, ...only],
        }
      },
      {
        banned: [],
        only: [],
      },
    )

    // If only some status can access here, make sure the user has one of them
    // If not, redirect him/her to the main freelancer page
    if (restrictions.only.length && !restrictions.only.includes(status)) {
      next({ path: '/freelancer' })
      return true
    }

    // If there is some banned status on the route, make sure the user isn't one
    // of them. If so, redirect him to the "sorry" page
    // (Might evolve as for now we only have the WRONG_SCOPE which is banned)
    if (restrictions.banned.length && restrictions.banned.includes(status)) {
      next({ path: '/freelancer/sorry' })
      return true
    }
  }

  return false
}

/**
 * Update the app's page title according to meta data stored in the targeted
 * route
 * @param {Object} to
 * @returns {void}
 */
function getPageTitle(to) {
  const formatter = to.matched.reduce((result, route) => route.meta.title || result, undefined)

  let title = 'comet'

  if (formatter) {
    if (_isFunction(formatter)) {
      title += ` - ${formatter(to)}`
    } else {
      title += ` - ${formatter}`
    }
  }

  return `${title}`
}

/**
 * Register guards to the specified 'router'
 * @param {Object} router
 * @param {Object} store
 * return {void}
 */
function useRouterGuards(router, store) {
  // Add a "pre" hook on the router to handle (unauthorized, 404, ...)
  router.beforeEach(async (to, from, next) => {
    // Guard - 404
    if (redirectIfUnknownRoute(to, from, next, store)) {
      return
    }

    // Guard - Invalid connexion identified by the API
    if (redirectIfUserAlreadyLoggedIn(to, from, next)) {
      return
    }

    // Guard - Unauthorized to access a private route
    if (await redirectIfUnauthorizedForPrivateRoute(to, from, next, store)) {
      return
    }

    // Guard - Unauthorized to access a public route
    if (redirectIfUnauthorizedForPublicRoute(to, from, next, store)) {
      return
    }

    // Guard - Unauthorized because of permissions
    if (redirectIfNotAllowed(to, from, next, store)) {
      return
    }

    // Guard - Invalid or expired token when coming from an external link
    if (redirectIfInvalidToken(to, from, next)) {
      return
    }

    // Guard - Unauthorized - Freelancer Status Restrictions
    if (redirectIfNotFreelancerStatus(to, from, next, store)) {
      return
    }

    next()
  })

  router.afterEach(to => {
    // Hook - Page title
    // It is postponed because the browser history change is made after
    // calling these lines
    setTimeout(() => {
      document.title = getPageTitle(to)
    }, 100)
  })
}

export default useRouterGuards
