<template>
  <div class="date-picker" :class="[alignment, { disabled }]">
    <DatePickerComponent
      :id="id"
      :value="normalized"
      :format="formatter"
      :placeholder="placeholderLabel"
      :initial-view="initialView"
      :minimum-view="minimumView"
      :language="LANGUAGE_LIST[language]"
      :disabled-dates="disabledDates"
      :disabled="disabled"
      calendar-class="date-picker__calendar"
      :monday-first="mondayFirst"
      :calendar-button="true"
      :clear-button="clearButton"
      clear-button-icon="clear-icon icon-cross"
      calendar-button-icon="icon icon-calendar"
      :open-date="openDate || firstAvailableDate"
      @input="onInput"
      @opened="onOpen"
    />
  </div>
</template>

<script>
  import _pickBy from 'lodash/pickBy'
  import _identity from 'lodash/identity'

  import DatePickerComponent from 'vuejs-datepicker'
  import * as LANGUAGE_LIST from 'vuejs-datepicker/dist/locale'

  import moment from 'moment-timezone'
  import _findIndex from 'lodash/findIndex'

  import ClockMixin from '@/mixins/ClockMixin'
  import I18nMixin from '@/mixins/I18nMixin'

  const Alignment = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
  }

  /**
   * This function will run through all the disabled dates and return the first
   * date not in the list starting from the given start date. If the given start
   * date is in the disabledDates list, then we will iterate using the given
   * dateIterator
   * @param {Date} startDate first date we will compare with the disabledDates
   * @param {Array<Date>} disabledDates list of disabled
   * @param {Function} dateIterator iterator function
   */
  function findFirstAvailableDate(startDate, disabledDates, dateIterator) {
    let currentDisplayDate = dateIterator(startDate).toDate()

    if (disabledDates) {
      let checkOthersDates = true
      const isDateDisabled = d => moment(d).isSame(moment(currentDisplayDate), 'day')

      while (checkOthersDates) {
        const dateAlreadySelected = _findIndex(disabledDates, isDateDisabled)

        if (dateAlreadySelected !== -1) {
          currentDisplayDate = dateIterator(currentDisplayDate).toDate()
        } else {
          checkOthersDates = false
        }
      }
    }

    return currentDisplayDate
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  DatePickerComponent: DatePickerComponent.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Alignment,
    name: 'DatePicker',
    __enums: {
      Alignment,
    },
    components: {
      DatePickerComponent,
    },
    mixins: [ClockMixin, I18nMixin],
    props: {
      /**
       * Unique identifier used to link the Field's label to its input
       */
      id: {
        type: String,
        default: null,
      },
      /**
       * Expected type of the "value".
       * Could be either 'iso' (string) or 'date' (JS Date Object)
       */
      type: {
        type: String,
        default: 'iso',
      },
      /**
       * Current date of the DatePicker.
       * It could be either a Date object or a String. If it is a String,
       * it would be parsed by momentJS and translate as a Date.
       */
      value: {
        type: [Date, Object, String],
        default: null,
      },
      /**
       * Format used to display the date in the input (see locales)
       */
      pattern: {
        type: String,
        default: 'shortdate',
      },
      /**
       * Placeholder string to display when empty
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * If 'true', the DatePicker opens first on the year panel,
       * then month, ...
       */
      yearFirst: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the DatePicker don't ask for a day date (only month
       * and year)
       */
      monthOnly: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the DatePicker only ask for a year date
       */
      yearOnly: {
        type: Boolean,
        default: false,
      },
      /**
       * Side on which the opened calendar panel is aligned
       * can be "left", "center" or "right"
       */
      alignment: {
        type: String,
        default: Alignment.LEFT,
      },
      /**
       * Specify the language used to format dates
       */
      language: {
        type: String,
        default: 'fr',
      },
      /**
       * Disable the picker input
       * From the doc of vuejs-datepicker's 'disabled-dates':
       * - from: disable all dates after specific date
       * - to: disable all dates up to specific date
       * - dates: disable an array of dates
       * @type {Object} {from, to, dates}
       */
      range: {
        type: Object,
        default: () => {},
      },
      /**
       * Disable the picker input
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Custom function to disable dates
       */
      customDisabler: {
        type: Function,
        default: null,
      },
      /**
       * If set, open on that date
       */
      openDate: {
        type: Date,
        default: null,
      },
      /**
       * If true, display an icon to clear date
       */
      clearButton: {
        type: Boolean,
        default: false,
      },
    },
    constants: {
      LANGUAGE_LIST,
    },
    computed: {
      /**
       * Value normalized before to be served to the date-picker component
       * @type {Date}
       */
      normalized() {
        const { type, value } = this

        switch (type) {
          case 'iso':
            return value ? moment.utc(value).toDate() : value
          case 'date':
          default:
            return value
        }
      },
      /**
       * Define how the date would be displayed in the date picker
       * @type {Function}
       */
      formatter() {
        const { pattern, format, type } = this

        // With "iso" type, moment will parse the date as UTC
        // So we have to format the date as UTC below
        // (see "normalized" computed)
        return value => format('date', value, { to: pattern, utc: type === 'iso' })
      },
      /**
       * If set, open on that view
       * @type {String}
       */
      initialView() {
        const { yearFirst } = this

        return yearFirst ? 'year' : undefined
      },
      /**
       * If set, lower-level views won't show
       * @type {String}
       */
      minimumView() {
        const { monthOnly, yearOnly } = this

        // eslint-disable-next-line no-nested-ternary
        return monthOnly ? 'month' : yearOnly ? 'year' : undefined
      },
      /**
       * Determines what is the first day of week (sunday or monday) according
       * to the date picker's language
       * @returns {Boolean}
       */
      mondayFirst() {
        const { language } = this

        return language === 'fr'
      },
      /**
       * Placeholder to display when no date is selected
       * @returns {String}
       */
      placeholderLabel() {
        const { placeholder, monthOnly, __ } = this

        if (placeholder) {
          return placeholder
        }

        return monthOnly
          ? __('cp:form:date-picker:month-only:placeholder')
          : __('cp:form:date-picker:default:placeholder')
      },
      /**
       * Check which dates is disabled and return the date that should be
       * open in function of the disabled dates
       * @type {Date}
       */
      firstAvailableDate() {
        const { range, nowDate, value } = this

        if (!value && range && range.to && (!range.from || moment(range.from).isAfter(range.to))) {
          return findFirstAvailableDate(range.to, range.dates, d => moment(d).add(1, 'days'))
        }

        if (!value && range && range.from && (!range.to || moment(range.to).isBefore(range.from))) {
          return findFirstAvailableDate(range.from, range.dates, d => moment(d).subtract(1, 'days'))
        }

        return moment(value).isValid() ? moment(value).toDate() : nowDate()
      },
      /**
       * Returns disabled dates
       * @returns {Object}
       */
      disabledDates: {
        get() {
          const { customDisabler, range } = this

          // Use _pickBy and _identity to remove the undefined and null values
          return _pickBy(
            {
              ...range,
              customPredictor: customDisabler ? date => customDisabler(date) : null,
            },
            _identity,
          )
        },
      },
    },
    methods: {
      /**
       * Fired when the picker's date is modified
       * @param {Date} newValue
       * @returns {void}
       */
      onInput(newValue) {
        if (!newValue) {
          this.$emit('input', null)
          return
        }

        const { type, format } = this

        // As vuejs-datepicker tries to preserve the passed hours and minutes,
        // of the previous value, with a certain timezone (CE - UTC+1), and
        // serve it back with the same hours and minutes but a different
        // timezone (CEST - UTC+2), the received 'newValue' could be the day
        // before or the day after because of the timezone.
        // Ex:
        // Current value is: Wed Mar 14 2018 02:00:00 GMT+0100 (CET)
        // When click 20 Jul 2018: Fri Jul 20 2018 01:00:00 GMT+0200 (CEST)
        const trimed = moment
          .utc({
            year: newValue.getFullYear(),
            month: newValue.getMonth(),
            date: newValue.getDate(),
          })
          .startOf('day')

        const result =
          type === 'iso' ? format('date', trimed, { to: 'iso', utc: true }) : trimed.toDate()

        this.$emit('input', result)
      },
      /**
       * On open, emit event to notify the parent
       * @returns {void}
       */
      onOpen() {
        this.$emit('open')
      },
    },
  }
</script>

<style lang="stylus">
  @import '~@/assets/css/_variables.styl'


  .date-picker {
    position: relative

    input[type="text"] {
      background: transparent
      cursor: pointer
      box-shadow: none
      border: solid var(--color-input-border) 1px
      border-radius: $radius-medium
      padding: 0 45px 0 16px
      height: 40px
      width: 100%
      font-family: $font
      font-weight: $font-regular
      font-size: 16px
      text-overflow: ellipsis
      appearance: none

      &:focus {
        border-color: var(--color-input-highlight)
      }

      &:placeholder-shown:not(:hover):not(:focus) {
        background: var(--color-input-background-idle)
      }

      ::placeholder {
        color: var(--color-input-placeholder)
      }
    }

    &:not(.disabled) input[type="text"]:hover{
      border-color: var(--color-input-highlight)
    }

    .date-picker__calendar {
      top: 48px

      header {
        display: block !important
      }

      .cell {
        &.selected,
        &.selected.highlighted,
        &.selected:hover {
          color: var(--color-input-highlight)
          border-color: var(--color-input-border) !important
          background-color: var(--color-input-options-hover) !important
        }

        &:hover {
          border-color: rgba(26, 26, 26, .05) !important
          background-color: rgba(26, 26, 26, .05) !important
        }
      }
    }

    .clear-icon {
      position: absolute
      right: 45px
      top: 9px
      font-size: 22px
    }

    .icon {
      position: absolute
      margin-top: 12px
      right: 16px
    }

    &.left .date-picker__calendar {
      left: 0
    }

    &.center .date-picker__calendar {
      left: calc(50% - 150px)
    }

    &.right .date-picker__calendar {
      right: 0
    }
  }
</style>
