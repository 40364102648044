<template>
  <NavButton
    v-clipboard:copy="textToCopy"
    v-clipboard:success="onCopy"
    v-bind="$attrs"
    :class="$style.copyLinkButton"
    :text="copyLabel"
  />
</template>

<script>
  import NavButton from '@/core/controls/NavButton/NavButton'

  import I18nMixin from '@/mixins/I18nMixin'

  const { Theme } = NavButton

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme,
    name: 'CopyLinkButton',
    __enums: {
      Theme,
    },
    components: {
      NavButton,
    },
    mixins: [I18nMixin],
    props: {
      textToCopy: {
        type: String,
        required: true,
      },
    },
    data() {
      const { __ } = this

      return {
        /**
         * Wether or not hidden experiences are visible
         * @type {Boolean}
         */
        copyLabel: __('core:controls:copy-link-button:label'),
      }
    },
    methods: {
      /**
       * On copy, change the text inside the tooltip
       * @returns {void}
       */
      onCopy() {
        const { __ } = this

        this.copyLabel = __('core:controls:copy-link-button:copied-label')
      },
    },
  }
</script>

<style lang="stylus" module>
  .copyLinkButton {

  }
</style>
