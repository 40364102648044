<template>
  <Field
    class="select-field"
    :label="label"
    :error="error"
    :disabled="disabled"
    :sup-label="supLabel"
    :sup-type="supType"
  >
    <div :class="$('content')">
      <SingleSelect
        :value="value"
        :options="options"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="!postForm ? name : null"
        :error="error"
        :force-direction="forceDirection"
        :theme="theme"
        @input="onInput"
      />

      <div v-if="$slots.right" :class="$('slot-right')">
        <slot name="right" />
      </div>
    </div>

    <input
      v-if="postForm"
      type="hidden"
      :class="$('post-ghost-input')"
      :value="value"
      :name="name"
    />
  </Field>
</template>

<script>
  import _isArray from 'lodash/isArray'
  import _isObject from 'lodash/isObject'
  import _values from 'lodash/values'

  import Field from '@/core/layout/Field/Field'
  import SingleSelect from '@/core/inputs/SingleSelect/SingleSelect'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
  SingleSelect: SingleSelect.__enums,
},

    name: 'SelectField',
    components: {
      Field,
      SingleSelect,
    },
    props: {
      /**
       * Field's value
       */
      value: {
        type: [String, Number, Object, Boolean],
        default: null,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript text
       */
      supLabel: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript color
       */
      supType: {
        type: String,
        default: null,
      },
      /**
       * Wheter or not the parent form is
       * using a POST method attribute
       */
      postForm: {
        type: Boolean,
        default: false,
      },
      /**
       * Field's name
       */
      name: {
        type: String,
        default: null,
      },
      /**
       * Enumeration (or list of ids / keys) used to fill in the select's values
       */
      type: {
        type: [Object, Array],
        required: true,
      },
      /**
       * Formatting function providing translations for the enumaration's
       * values if 'type' is an enum, or the ids / keys if it is a list
       */
      formatter: {
        type: Function,
        required: true,
      },
      /**
       * Field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Placeholder
       */
      placeholder: {
        type: String,
        default: 'Sélectionner',
      },
      /**
       * To force direction : 'top' or 'bottom'
       */
      forceDirection: {
        type: String,
        default: null,
      },
      theme: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * List of all the available options provided in the select's dropdown
       * @type {Array.<Object>}
       */
      options() {
        const { type, formatter } = this

        const list = _isArray(type) ? type : _values(type)

        return list.map(info =>
          _isObject(info)
            ? { id: info.id, name: formatter(info) }
            : { id: info, name: formatter(info) },
        )
      },
    },
    methods: {
      /**
       * Fired when the select's value changes
       */
      onInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .select-field {
    &-content {
      display: flex
    }

    .single-select {
      flex: 1
    }

    &-slot-right {
      display: flex
      flex-direction: column
      align-self: center
      margin-left: 16px
    }
  }
</style>
