import queryString from 'query-string'

/**
 * Remove the query params from the given string
 * Ex : "johnny/haliday?toto=tata&titi=9" -> "johnny/haliday"
 * @param {String} str
 * @returns {Object}
 */
function parsePath(str = '') {
  if (!str) {
    return str
  }

  const index = str.indexOf('?')

  return index >= 0 ? str.substring(0, index) : str
}

/**
 * Parse the given location's query params string into
 * an object
 * Ex : "?toto=tata&titi=9" -> { toto: 'tata', titi: 9 }
 * @param {String} str
 * @returns {Object}
 */
function parseQuery(str = '') {
  const index = str.indexOf('?')
  const sub = index >= 0 ? str.substring(index) : ''

  return queryString.parse(sub, { arrayFormat: 'bracket' })
}

/**
 * Format the given query 'params' object into a location's
 * query params string
 * Ex : { toto: 'tata', titi: 9 }  ->  "?toto=tata&titi=9"
 * @param {Object} params
 * @returns {String}
 */
function formatQuery(params = {}) {
  const query = queryString.stringify(params, { arrayFormat: 'bracket' })

  return query ? `?${query}` : ''
}

export { parsePath, parseQuery, formatQuery }
