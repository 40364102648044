<template>
  <div :class="$style.ExpandableTaskList">
    <TaskList
      :limit="showAll ? 500 : limit"
      :tasks="tasks"
      :hoverable="false"
      v-bind="$attrs"
      v-on="$listeners"
    />

    <!-- We show the button only if there's more task than the given limit -->
    <button
      v-if="tasks && tasks.length > limit"
      :class="$style.showMore"
      @click="showAll = !showAll"
    >
      <Txt
        :size="Txt.Size.XS"
        :theme="Txt.Theme.BRAND"
        :value="
          showAll
            ? __('cp:core:badges:expandable-task-list:show-less')
            : __('cp:core:badges:expandable-task-list:show-more')
        "
      />
    </button>
  </div>
</template>

<script>
  import Txt from '@/core/text/Txt/Txt'
  import TaskList from './TaskList'
  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  TaskList: TaskList.__enums,
  Txt: Txt.__enums,
},

    name: 'ExpandableTaskList',
    components: {
      TaskList,
      Txt,
    },
    mixins: [I18nMixin],
    inheritAttrs: false,
    props: {
      /**
       * List of task that we will pass to the list
       * We need to define it in props to use it in the components before
       * passing them to the tasklist
       */
      tasks: {
        type: Array,
        default: null,
      },
      /**
       * Maximum number of task we pass to the component
       * Needed in props because we want to use it in this components
       */
      limit: {
        type: Number,
        default: 20,
      },
    },
    data() {
      return {
        /**
         * Show all the tasks or not
         * @type {Boolean}
         */
        showAll: false,
      }
    },
  }
</script>

<style lang="stylus" module>
  .showMore {
    background-color: var(--color-background)
    color: var(--color-brand)
    border: none
    padding: 0px
    text-decoration: underline
  }
</style>
