<template>
  <div class="language-list">
    <div class="language-list-labels">
      <Txt
        :value="__('cp:form:language-selector:label:name')"
        :inline="true"
        :ellipsis="true"
        :type="Txt.Type.OVERLINE"
        :size="Txt.Size.XXXS"
        theme="grey"
      />

      <Txt
        :value="__('cp:form:language-selector:label:level')"
        :inline="true"
        :ellipsis="true"
        :type="Txt.Type.OVERLINE"
        :size="Txt.Size.XXXS"
        theme="grey"
      />
    </div>
    <Language
      v-for="language in value"
      :key="language.id"
      :value="language"
      :disabled="disabled"
      @input="onInput"
      @remove="onRemove"
    />
  </div>
</template>

<script>
  import Txt from '@/core/text/Txt/Txt'

  import I18nMixin from '@/mixins/I18nMixin'

  import Language from './Language'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Language: Language.__enums,
  Txt: Txt.__enums,
},

    name: 'LanguageList',
    components: {
      Language,
      Txt,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * List of tasks
       * @type {Array.<Object>}
       */
      value: {
        type: Array,
        default: () => [],
      },
      /**
       * Disable all the controls of the list
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      /**
       * Handler called when a LanguageLine is modified
       * @param {Object} language
       * @returns {void}
       */
      onInput(language) {
        const { value } = this
        const { id, level } = language

        const newValue = value.map(l => (l.id === id ? { ...l, level } : l))

        this.$emit('input', newValue)
      },
      /**
       * Handler called when a LanguageLine is intended to be removed
       * @param {String} id
       * @returns {void}
       */
      onRemove(id) {
        const { value } = this

        const newValue = value.filter(l => l.id !== id)

        this.$emit('input', newValue)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_grid.styl'
  @import '~@/assets/css/_layout.styl'

  .language-list {
    .language-list-labels {
      display: none

      .txt {
        margin-bottom: 8px
      }

      > :first-child {
        width: 58%
        margin-right 24px
      }

      @media tablet-up {
        display: flex
        flex-direction: row
      }
    }

    .language {
      &:not(:last-child) {
        margin-bottom: 16px
      }
    }
  }
</style>
