<template>
  <div>
    <template v-for="(line, index) in lines">
      <div
        :key="index"
      >
        <span
          class="secondary--text"
          v-text="line.title"
        />:

        <CListEllipsed
          :items="line.items"
          :limit="limit[1]"
          inline
        />
      </div>
    </template>

    <VTooltip
      v-if="remaining"
      :max-width="360"
      right
    >
      <template v-slot:activator="{ on }">
        <span
          class="secondary--text"
          v-on="on"
          v-html="__('cp:c-list-ellipsed-deep:remaining', remaining, { count: remaining })"
        />
      </template>

      <span
        class="text--pre-line"
        v-html="tooltip"
      />
    </VTooltip>
  </div>
</template>

<script>
  import _flatten from 'lodash/flatten'

  import CListEllipsed from '../CListEllipsed/CListEllipsed'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  CListEllipsed: CListEllipsed.__enums,
},

    name: 'CListEllipsedDeep',
    components: {
      CListEllipsed,
    },
    props: {
      /**
       * Two dimensions list of Strings to display
       * @type {Object}
       */
      items: {
        type: Array,
        required: true,
      },
      /**
       * Array containg the both limits of the first and second dimension of the 'items'
       * @type {Number}
       */
      limit: {
        type: Array,
        default: () => [5, 5],
      },
    },
    computed: {
      /**
       * Computed text to display against the passed 'items' and 'limit' properties.
       * @type {String}
       */
      lines() {
        const { items, limit } = this

        if (!items) {
          return null
        }

        const extract = items.slice(0, limit[0])

        if (!extract || !extract.length) {
          return null
        }

        return extract
      },
      /**
       * Computed text displayed in the tooltip, showed when focusing the ellipse label
       * @type {String}
       */
      tooltip() {
        const { items, limit } = this
        const { __ } = this.$i18n

        if (!items) {
          return null
        }

        const extract = items.slice(limit[0])

        if (!extract || !extract.length) {
          return null
        }

        return extract
          .map(i => __('cp:c-list-ellipsed-deep:tooltip:item', {
            title: i.title,
            items: i.items.join(', '),
          }))
          .join('\n')
      },
      /**
       * Number of really ellipsed items according to the given properties
       * @type {Number}
       */
      remaining() {
        const { items, limit } = this

        if (!items) {
          return 0
        }

        return _flatten(items.slice(limit[0]).map(i => i.items)).length
      },
    },
  }
</script>
