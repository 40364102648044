<template>
  <div :class="classes">
    <div />
    <div />
    <div />
  </div>
</template>

<script>
  import _camelCase from 'lodash/camelCase'

  const Color = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
  }

  const Size = {
    L: 'l',
    M: 'm',
    S: 's',
    XS: 'xs',
    XXS: 'xxs',
  }

  // https://codepen.io/danielmorosan/pen/XmYBVx
  export default {
/* Injected by the custom 'enums' Webpack plugin */ Color,Size,
    name: 'CSpinner',
    __enums: {
      Color,
      Size,
    },
    props: {
      /**
       * Dots and background color
       * @type {Color}
       */
      color: {
        type: String,
        default: Color.SECONDARY,
      },
      /**
       * Whole spinner size changing dots width and paddings
       * @type {Size}
       */
      size: {
        type: String,
        default: Size.S,
      },
      /**
       * Displayed without background and padding
       * @type {Boolean}
       */
      solo: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { color, size, solo, $style } = this

        return {
          [$style.root]: true,
          [$style[_camelCase(`color-${color}`)]]: !!color,
          [$style[_camelCase(`size-${size}`)]]: !!size,
          [$style.solo]: !!solo,
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    box-sizing: content-box !important
    text-align: center
    white-space: nowrap

    background-color: var(--color-border)
    color: var(--color-font-light)

    > div {
      position: relative
      width: 16px
      height: 16px
      background-color: currentColor

      border-radius: 100%
      display: inline-block
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both
      animation: sk-bouncedelay 1.4s infinite ease-in-out both
    }

    > div:not(:last-child) {
      margin-right: 4px
    }

    > div:nth-child(1) {
      -webkit-animation-delay: -0.32s
      animation-delay: -0.32s
    }

    > div:nth-child(2) {
      -webkit-animation-delay: -0.16s
      animation-delay: -0.16s
    }

    &.solo {
      background-color: transparent
    }

    &.colorPrimary {
      color: var(--color-blue-40)

      &:not(.solo) {
        background-color: var(--color-blue-10)
      }
    }

    &.colorSecondary {
      color: var(--color-grey-60)

      &:not(.solo) {
        background-color: var(--color-grey-30)
      }
    }

    &.colorSuccess {
      color: var(--color-positive)

      &:not(.solo) {
        background-color: var(--color-positive-transparent)
      }
    }

    &.colorError {
      color: var(--color-warning)

      &:not(.solo) {
        background-color: var(--color-warning-transparent)
      }
    }

    &.colorWarning {
      color: var(--color-negative)

      &:not(.solo) {
        background-color: var(--color-negative-transparent)
      }
    }

    &.sizeL {
      width: 56px
      height: 16px
      line-height: 14px

      > div {
        width: 16px
        height: 16px

        &:not(:last-child) {
          margin-right: 4px
        }
      }

      &:not(.solo) {
        padding: 16px
        border-radius: 8px
      }
    }

    &.sizeM {
      width: 42px
      height: 12px
      line-height: 10px

      > div {
        width: 12px
        height: 12px

        &:not(:last-child) {
          margin-right: 3px
        }
      }

      &:not(.solo) {
        padding: 12px
        border-radius: 6px
      }
    }

    &.sizeS {
      width: 28px
      height: 8px
      line-height: 8px

      > div {
        top: -2px
        width: 8px
        height: 8px

        &:not(:last-child) {
          margin-right: 2px
        }
      }

      &:not(.solo) {
        padding: 8px
        border-radius: 4px
      }
    }

    &.sizeXs {
      width: 20px
      height: 6px
      line-height: 6px

      > div {
        top: -3px
        width: 6px
        height: 6px

        &:not(:last-child) {
          margin-right: 1px
        }
      }

      &:not(.solo) {
        padding: 6px
        border-radius: 4px
      }
    }


    &.sizeXxs {
      width: 14px
      height: 4px
      line-height: 4px

      > div {
        top: -2px
        width: 4px
        height: 4px

        &:not(:last-child) {
          margin-right: 1px
        }
      }

      &:not(.solo) {
        padding: 4px
        border-radius: 3px
      }
    }
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(.5)
      transform: scale(.5)
    } 40% {
      -webkit-transform: scale(.9)
      transform: scale(.9)
    }
  }
</style>
