import _deburr from 'lodash/deburr'

import { formatAddress } from './address'

import { formatAppError, formatMessage, formatPaginationLabel } from './app'

import {
  formatActivity,
  formatCompanyLegalForm,
  formatCompanyOwner,
  formatCompanyNeeds,
  formatCompanySize,
  formatCompanyStructure,
  formatInvoiceType,
  formatPaymentConditions,
  formatHitRatio,
} from './company'

import { formatCorporateAcquisition, formatCorporateRole } from './corporate'

import { calculateEstimatedEndDate, dateWithTime, formatMonthDuration } from './date'

import { formatDealDocumentType } from './deal'

import { formatExperienceType } from './experience'

import { formatValidationError } from './form'

import {
  formatCallAvailability,
  formatContract,
  formatDocumentType,
  formatEnvironmment,
  formatExperienceLevel,
  formatExperienceLevelYears,
  formatQualificationLevel,
  formatFreelancerAcquisition,
  formatFreelancerEvaluationReason,
  formatFreelancerEvaluationSubReason,
  formatFreelancerExperienceReferentRequestStatus,
  formatFreelancerModeFilter,
  formatFreelancerStatus,
  formatFreelanceHuntingInfoFreelanceStatus,
  formatAdditionalWorkerPosition,
  formatLanguageLevel,
  formatMissionHuntFAQ,
  formatMobility,
  formatPrefTime,
  formatShortDocumentType,
  formatShortWorkplace,
  formatFreelancerSkillDuration,
  formatFreelancerJobTitleDuration,
  formatWorkplace,
  getFreelancerColor,
  getMinExperienceLevelInYear,
} from './freelancer'

import {
  formatMatchStatus,
  formatMatchActionWording,
  formatMatchCorporateRefusalReason,
  formatMatchCorporateRefusalReasons,
  formatMatchFreelancerRefusal,
  formatMatchTeamMemberRefusalReason,
  formatMatchRefusalSource,
  formatMatchStatusLabel,
  formatPreMatchingSource,
  formatPostMeetingFreelancerRefusalReason,
  getMatchColor,
} from './match'

import { formatDefaultMeetingComment } from './meeting'

import {
  formatApplicantsReviewDate,
  formatCorporateMissionMode,
  formatFreelancerPeriodicRating,
  formatLegalModel,
  formatMissingFields,
  formatTeamMemberMissionFailureReason,
  formatCorporateMissionFailureReason,
  formatMissionOrder,
  formatMissionRefusalComment,
  formatMissionRefusalLabel,
  formatMissionSkillDuration,
  formatMissionShortStatus,
  formatMissionStatus,
  formatMissionOngoingStatus,
  formatMissionEmoji,
  formatMissionExpertise,
  formatMissionRenewable,
  formatProjectRhythm,
  formatProjectType,
  getMissionColor,
  getMissionTheme,
  formatMissionWorkingTime,
  formatMissionOffer,
  formatMissionOfferDescription,
  formatMissionOrigin,
  formatMissionOriginShort,
  formatMissionOriginDescription,
  formatMissionShortOffer,
  formatMissionOfferColor,
  formatMissionSource,
} from './mission'

import { formatWithCommission } from './retribution'

import {
  formatHardSkillReview,
  formatSoftSkillsReview,
  formatProblemSolvingReview,
  formatResponsibilityReview,
  formatWorkplaceReview,
  formatWorkEnvironmentReview,
  formatTeamHardSkillsReview,
  formatTeamSpiritReview,
} from './review'

import { formatActionTitle, formatActionInfos } from './teamMember'

import { formatAuthProvider } from './user'

import {
  formatKYCInfos,
  formatKYCRefusalAsked,
  formatKYCRefusalLabel,
  formatKYCRefusalMail,
  formatKYCRefusalReason,
  formatKYCStatus,
  formatKYCType,
  getKYCColor,
} from './kyc'

import {
  formatInterviewApprovalRating,
  formatInterviewDoubtCriteria,
  formatInterviewExpertiseLevel,
  formatInterviewTechnicalKnowledge,
  formatInterviewYesNo,
  formatInterviewQualificationStatus,
} from './qualification'

/**
 * Format the given number 'num' with leading zeros until 'size' length.
 * Can't handle numbers larger than 10 digits.
 * @param {Number} num
 * @returns {String}
 */
function pad(num, size) {
  /* eslint-disable prefer-template */
  const s = '0000000000' + num
  /* eslint-enable prefer-template */

  return s.substr(s.length - size)
}

/**
 * Flatten the given string by removing all special characters
 * and transforming it to lowercase.
 * @param {String} str
 * @returns {String}
 */
function flatten(str) {
  return str.toLowerCase().replace(/[ -]/g, '')
}

/**
 * Flatten the given string by removing all accent
 * and transforming it to lowercase.
 * @param {String} str
 * @returns {String}
 */
function flatten2(str) {
  if (!str) {
    return null
  }
  return _deburr(str.trim().toLowerCase())
}

/**
 * Truncate the given string if it is longer than the specified length 'len'
 * @param {String} str
 * @param {Number} len
 * @returns {String}
 */
function truncate(str, len = 100) {
  return str && str.length > Math.max(1, len - 3) ? `${str.substring(0, len - 3)}...` : str
}

/**
 * Truncate the given filename by keeping the extension visible after
 * the ellipsis
 * @param {String} name
 * @returns {String}
 */
function truncateFilename(name) {
  if (name && name.length > 20) {
    const split = name.split('?')[0].split('.')

    const extension = split.pop()

    return split
      .join('.')
      .substring(0, 20)
      .concat('...', extension)
  }

  return name
}

export {
  // address
  formatAddress,
  // app
  formatAppError,
  formatMessage,
  formatPaginationLabel,
  // company
  formatActivity,
  formatCompanyLegalForm,
  formatCompanyOwner,
  formatCompanyNeeds,
  formatCompanySize,
  formatCompanyStructure,
  formatInvoiceType,
  formatPaymentConditions,
  formatHitRatio,
  // corporate
  formatCorporateAcquisition,
  formatCorporateRole,
  // date
  calculateEstimatedEndDate,
  dateWithTime,
  formatMonthDuration,
  // deal
  formatDealDocumentType,
  // experience
  formatExperienceType,
  // form
  formatValidationError,
  // freelancer
  formatCallAvailability,
  formatContract,
  formatDocumentType,
  formatEnvironmment,
  formatExperienceLevel,
  formatExperienceLevelYears,
  formatQualificationLevel,
  formatFreelancerAcquisition,
  formatFreelancerEvaluationReason,
  formatFreelancerEvaluationSubReason,
  formatFreelancerExperienceReferentRequestStatus,
  formatFreelancerModeFilter,
  formatFreelancerStatus,
  formatFreelanceHuntingInfoFreelanceStatus,
  formatAdditionalWorkerPosition,
  formatLanguageLevel,
  formatMissionHuntFAQ,
  formatMobility,
  formatPrefTime,
  formatShortDocumentType,
  formatShortWorkplace,
  formatFreelancerSkillDuration,
  formatFreelancerJobTitleDuration,
  formatWorkplace,
  getFreelancerColor,
  getMinExperienceLevelInYear,
  // match
  formatMatchStatus,
  formatMatchActionWording,
  formatMatchCorporateRefusalReason,
  formatMatchCorporateRefusalReasons,
  formatMatchTeamMemberRefusalReason,
  formatMatchFreelancerRefusal,
  formatMatchRefusalSource,
  formatMatchStatusLabel,
  formatPreMatchingSource,
  formatPostMeetingFreelancerRefusalReason,
  getMatchColor,
  // meeting
  formatDefaultMeetingComment,
  // mission
  formatApplicantsReviewDate,
  formatCorporateMissionMode,
  formatFreelancerPeriodicRating,
  formatLegalModel,
  formatMissingFields,
  formatTeamMemberMissionFailureReason,
  formatCorporateMissionFailureReason,
  formatMissionOrder,
  formatMissionSkillDuration,
  formatMissionShortStatus,
  formatMissionStatus,
  formatMissionOngoingStatus,
  formatMissionEmoji,
  formatMissionExpertise,
  formatMissionRefusalComment,
  formatMissionRefusalLabel,
  formatMissionRenewable,
  formatProjectRhythm,
  formatProjectType,
  getMissionColor,
  getMissionTheme,
  formatMissionWorkingTime,
  formatMissionOffer,
  formatMissionOfferDescription,
  formatMissionOrigin,
  formatMissionOriginShort,
  formatMissionOriginDescription,
  formatMissionShortOffer,
  formatMissionOfferColor,
  formatMissionSource,
  // retribution
  formatWithCommission,
  // review
  formatHardSkillReview,
  formatSoftSkillsReview,
  formatProblemSolvingReview,
  formatResponsibilityReview,
  formatWorkplaceReview,
  formatWorkEnvironmentReview,
  formatTeamHardSkillsReview,
  formatTeamSpiritReview,
  // teamMember
  formatActionTitle,
  formatActionInfos,
  // user
  formatAuthProvider,
  // kyc
  formatKYCInfos,
  formatKYCRefusalAsked,
  formatKYCRefusalLabel,
  formatKYCRefusalMail,
  formatKYCRefusalReason,
  formatKYCStatus,
  formatKYCType,
  getKYCColor,
  // qualification
  formatInterviewApprovalRating,
  formatInterviewDoubtCriteria,
  formatInterviewExpertiseLevel,
  formatInterviewTechnicalKnowledge,
  formatInterviewYesNo,
  formatInterviewQualificationStatus,
  // others
  pad,
  flatten,
  flatten2,
  truncate,
  truncateFilename,
}
