<template>
  <VInput
    v-bind="$attrs"
    :disabled="disabled"
    :class="$style.root"
    v-on="$listeners"
  >
    <div
      ref="map"
      :class="$style.map"
    />

    <div :class="$style.content">
      <VCombobox
        :id="address ? `${address}-country` : 'country'"
        ref="country"
        data-cy-c-field-country
        :value="country.iso3"
        :items="countries"
        item-value="iso3"
        item-text="name"
        :menu-props="{ nudgeBottom: 4 , nudgeLeft: 8 }"
        :disabled="disabled"
        :class="$style.country"
        auto-select-first
        return-object
        solo
        @input="onCountryInput"
      >
        <template v-slot:item="{ item, on, attrs }">
          <VListItem
            v-if="item"
            v-bind="attrs"
            v-on="on"
          >
            <CIconFlag
              :iso="item.iso2"
              :name="item.name"
              :size="CIconFlag.Size.XS"
              rounded
              class="mr-4"
            />

            <span v-text="item.name" />
          </VListItem>
        </template>

        <template v-slot:prepend-inner>
          <CIconFlag
            :iso="country.iso2 || null"
            :name="country.name || __('cp:address-field:country:placeholder')"
            :size="CIconFlag.Size.XS"
            rounded
            :class="$style.flag"
          />
        </template>
      </VCombobox>

      <VAutocomplete
        :id="`${address}-address`"
        data-cy-c-field-address
        :value="address"
        :items="addresses"
        item-value="id"
        item-text="name"
        :placeholder="placeholder || __('cp:address-field:address:placeholder')"
        :no-data-text="__('cp:address-field:address:autocomplete:no-data')"
        :disabled="disabled || !country.iso2"
        :class="$style.address"
        auto-select-first
        hide-no-data
        no-filter
        solo
        @input="onAddressInput"
        @update:search-input="onAddressSearch"
      />
    </div>
  </VInput>
</template>

<script>
  import _isPlainObject from 'lodash/isPlainObject'
  import _keyBy from 'lodash/keyBy'
  import _orderBy from 'lodash/orderBy'

  import CIconFlag from '../CIconFlag/CIconFlag'

  import { formatAddress } from '@comet/i18n'
  import { float } from '@comet/utils'

  import countries from './countries.gql'

  /**
   * Unique identifier used when generating a fake address item, provided to the VAutocomplete
   * component, containing the current address information (for which we don't have a Google
   * Places 'place_id') in order to maintain a coherent text display.
   * @type {String}
   */
  const SELECTED_ADDRESS_ID = 'comet-select-address-item-id'

  /**
   * Parses the raw place object received from the Google Maps Place API
   * @param {Object} place
   * @return {Object}
   */
  function parsePlaceDetails(place) {
    let result = {}
    let type = null

    const ADDRESS_COMPONENTS = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      administrative_area_level_2: 'county',
      country: 'long_name',
      postal_code: 'short_name',
    }

    if (place) {
      for (let i = 0; i < place.address_components.length; i++) {
        type = place.address_components[i].types[0]

        if (ADDRESS_COMPONENTS[type]) {
          result[type] = place.address_components[i][ADDRESS_COMPONENTS[type]]
        }
      }

      result.latitude = place.geometry?.location?.lat?.().toFixed(6)
      result.longitude = place.geometry?.location?.lng?.().toFixed(6)
    }

    return result
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  CIconFlag: CIconFlag.__enums,
},

    name: 'CFieldAddress',
    components: {
      CIconFlag,
    },
    props: {
      /**
       * Built-in HTML 'id' parameters transmitted to children inputs
       * @type {String}
       */
      id: {
        type: String,
        default: 'address',
      },
      /**
       * Full Address object as defined by the API
       * @type {Address}
       */
      value: {
        type: Object,
        default: () => ({ country: { iso3: 'FRA' } }),
      },
      /**
       * Address input's placeholder text
       * @type {String}
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Disable all the inner inputs when enabled
       * @type {Boolean}
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        /**
         * List of all available countries (fetched from Apollo)
         * @type {Array.<Country>}
         */
        countries: [],
        /**
         * List of matching addresses according to the current user input
         * (fetched from Google Places API)
         * @type {Array.<Object>}
         */
        suggestions: [],
        /**
         * Is "true" if a request through the Google Places API is pending
         * @type {Boolean}
         */
        loading: false,
      }
    },
    computed: {
      /**
       * All details (fetched from Apollo) about the currently selected country
       * @type {Country}
       */
      country() {
        const { value, countriesHashedByIso3 } = this

        if (value === null) {
          return countriesHashedByIso3['FRA'] || {}
        }

        return value?.country?.iso3
          ? countriesHashedByIso3[value.country.iso3] || {}
          : {}
      },
      /**
       * The currently selected address 'id', which is actually the "fake" id
       * if an address is currently selected (see 'addresses'), and else 'null'.
       * @type {String}
       */
      address() {
        const { value } = this

        const address = value?.point
          ? SELECTED_ADDRESS_ID
          : null

        return address
      },
      /**
       * List of address items to provide to the VAutocomplete component, according to
       * the received Google Places API's suggestions and the current selected value.
       * @type {Array.<Object>}
       */
      addresses() {
        const { value, suggestions } = this

        const items = value?.point
          ? [{ id: SELECTED_ADDRESS_ID, name: formatAddress(value) }]
          : suggestions

        return items
      },
      /**
       * Available countries hashed with 'iso2' as key
       * @type {Object}
       */
      countriesHashedByIso2() {
        const { countries } = this

        return countries
          ? _keyBy(countries, c => c.iso2)
          : {}
      },
      /**
       * Available countries hashed with 'iso3' as key
       * @type {Object}
       */
      countriesHashedByIso3() {
        const { countries } = this

        return countries
          ? _keyBy(countries, c => c.iso3)
          : {}
      },
    },
    apollo: {
      countries: {
        query: countries,
        update(data) {
          const countries = data?.countries ?? []

          return _orderBy(countries, ['name'], ['asc'])
        },
        fetchPolicy: 'cache-first',
      },
    },
    mounted() {
      const { $refs } = this

      // Initialize the Google Maps Places API components
      this.token = new google.maps.places.AutocompleteSessionToken()
      this.autocomplete = new google.maps.places.AutocompleteService()
      this.places = new google.maps.places.PlacesService($refs.map)
    },
    methods: {
      /**
       * Fired when the country select recieves a new value.
       * It resets the potential entered address.
       * @param {Country} item
       */
      onCountryInput(item) {
        // If the country is deleted, the address must be removed too
        if (!item) {
          this.suggestions = []
          this.$emit('input', null)
          return
        }

        // We don't know why, but sometimes the VCombobox emits 'input' event with just
        // the country name as parameter ... (ex: "France")
        if (_isPlainObject(item)) {
          this.suggestions = []
          this.$emit('input', {
            country: item,
          })

          // Auto blur the input to ensure the entered (but hidden) text will be
          // selected when retrieving the focus, because of the "auto-select-first"
          // option
          setTimeout(() => this.$refs?.country.blur(), 0)
          return
        }
      },
      /**
       * Fired when an address is selected from the autocomplete input.
       * The given param 'id' matches the location "place_id".
       * @param {String} id
       */
      async onAddressInput(id) {
        const { value, country, fetchGoogleMapsPlace } = this

        // If the input is empty (i.e. the user deleted the entered value)
        // just emit an empty address.
        if (!id) {
          this.suggestions = []

          if (country?.id) {    // "country" can be an empty object
            this.$emit('input', { country })
          } else  {
            this.$emit('input', null)
          }

          return
        }

        // If an address is already selected (present in 'value'), we must
        // not perform a request upon the Google Place API.
        // We make this type of request only when an autocomplete result has
        // been selected and we want to gether more data and emit the
        // full address.
        if (id === SELECTED_ADDRESS_ID) {
          return
        }

        this.loading = true

        const place = await fetchGoogleMapsPlace(id)
        const details = parsePlaceDetails(place)

        this.$emit('input', {
          ...value,
          street: details?.street_number && details?.route
            ? `${details?.street_number} ${details?.route}`
            : details?.route || null,
          city: details?.locality || null,
          zipCode: details?.postal_code || null,
          state: details?.administrative_area_level_1 || null,
          point: {
            latitude: float(details?.latitude),
            longitude: float(details?.longitude),
          },
          country,
        })

        this.loading = false
      },
      /**
       * Fired when the user interacts with the address autocomplete input and so an
       * autocomplete request must be performed with the current entered 'text'.
       * @param {String} text
       * @return {Promise}
       */
      async onAddressSearch(text) {
        const { country, address, loading, fetchGoogleMapsAutocomplete } = this

        // If a current address is actually selected, but the input has been cleared,
        // just delete the entered address.
        if (!text && address === SELECTED_ADDRESS_ID) {
          this.suggestions = []

          if (country?.id) {    // "country" can be an empty object
            this.$emit('input', { country })
          } else  {
            this.$emit('input', null)
          }
        }

        // An autocomplete request is made upon the Google Places API only if there is a
        // text entered, a country selected, a request is not on its way, and a selected
        // address is not still fully entered in the input
        if (text && !loading && country.iso2 && address !== SELECTED_ADDRESS_ID) {
          this.loading = true

          const predictions = await fetchGoogleMapsAutocomplete(text, country.iso2.toLowerCase())

          this.suggestions = predictions?.map(p => ({ id: p.place_id, name: p.description }))
          this.loading = false
        }
      },
      /**
       * Performs a request upon the Google Maps Places API to receive all the places matching
       * the given 'value' text, and returns the  result as a JSON string.
       * @param {String} value
       * @param {String} country
       * @return {Promise}
       */
      async fetchGoogleMapsAutocomplete(value, country) {
        const { autocomplete, token } = this
        const { OK, ZERO_RESULTS } = google.maps.places.PlacesServiceStatus

        return new Promise((resolve, reject) => {
          const params = {
            input: value,
            sessionToken: token,
            componentRestrictions: {
              country,
            },
          }

          autocomplete.getPlacePredictions(params, (predictions, status) => {
            if (status !== OK && status !== ZERO_RESULTS) {
              reject(status)
              return
            }

            resolve(predictions)
          })
        })
      },
      /**
       * Performs a request upon the Google Maps Places API to retreive the whole details about
       * a place identified by the given 'id', and returns the  result as a JSON string.
       * @param {String} id
       * @return {Promise}
       */
      async fetchGoogleMapsPlace(id) {
        const { places, token } = this
        const { OK } = google.maps.places.PlacesServiceStatus

        return new Promise((resolve, reject) => {
          const params = {
            placeId: id,
            fields: ['place_id', 'name', 'geometry', 'address_component', 'formatted_address'],
            sessionToken: token,
          }

          places.getDetails(params, (result, status) => {
            if (status !== OK) {
              reject(status)
              return
            }

            resolve(result)
          })
        })
      },
    },
  }
</script>

<style lang="stylus" module>
  :global(#app) {
    .root {
      .map {
        position: absolute !important
        top: 0 !important
        left: 0 !important
        width: 0 !important
        height: 0 !important
        opacity: 0 !important
        pointer-events: none !important
        z-index: -1 !important
      }

      .content {
        width: 100%
        flex: 1
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: flex-start
      }

      .country {
        max-width: 56px
        margin-right: 8px

        &:global(.v-select.v-input.v-text-field .v-input__slot) {
          padding: 0 4px 0 4px

          :global(.v-input__prepend-inner) {
            width: 20px
            min-width: 20px
            max-width: 20px
            left: 4px !important
            padding: 0 !important
          }

          :global(.v-select__slot) {
            cursor: pointer

            :global(input) {
              width: 0
              min-width: 0
              max-width: 0
              opacity: 0
            }

            :global(.v-input__append-inner) {
              left: 4px
              padding: 0
            }
          }
        }

        .flag {
          cursor: pointer
        }
      }

      .address {
        &:global(.v-select.v-input.v-text-field .v-input__slot .v-input__append-inner) {
          display: none
        }
      }

      .country,
      .address {
        &:global(.v-input.v-text-field .v-input__control) {
          :global(.v-input__slot) {
            margin-bottom: 0
          }

          :global(.v-text-field__details) {
            display: none
          }
        }

      }
    }
  }
</style>
