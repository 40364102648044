<template>
  <div :class="$style.massivePlaceholder">
    <Illustration
      v-if="illustration"
      animation="oscillation"
      :path="`illustrations/${illustration}`"
      :class="$style.illustration"
    />

    <Heading
      :level="Heading.Level.LEVEL4"
      :text="title || __('core:layout:massive-placeholder:tilte')"
      :align="Heading.Align.CENTER"
      :class="$style.title"
    />

    <Txt
      v-if="description"
      :value="description"
      :size="Txt.Size.XS"
      :theme="Txt.Theme.GREY"
      :raw="true"
      :class="$style.description"
    />

    <div :class="$style.buttons">
      <NavButton
        v-if="actionTo || actionLabel"
        :to="actionTo || '/'"
        :text="actionLabel || __('core:layout:massive-placeholder:button')"
        :theme="NavButton.Theme.PRIMARY"
      />

      <slot v-else name="buttons" />
    </div>
  </div>
</template>

<script>
  import Heading from '@/core/text/Heading/Heading'
  import Illustration from '@/core/graphics/Illustration/Illustration'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import Txt from '@/core/text/Txt/Txt'

  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Heading: Heading.__enums,
  Illustration: Illustration.__enums,
  NavButton: NavButton.__enums,
  Txt: Txt.__enums,
},

    name: 'MassivePlaceholder',
    components: {
      Heading,
      Illustration,
      NavButton,
      Txt,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Optional illustration to show on top
       */
      illustration: {
        type: String,
        default: null,
      },
      /**
       * Placeholder main title
       */
      title: {
        type: String,
        required: true,
      },
      /**
       * Additionnal description
       */
      description: {
        type: String,
        default: null,
      },
      /**
       * The route to which we want to redirect the user as fallback
       */
      actionTo: {
        type: String,
        default: null,
      },
      /**
       * The label to display in the redirection button
       */
      actionLabel: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_grid.styl'

  .massivePlaceholder {
    display: flex
    flex-direction: column
    align-items: center
    margin: 40px 0
  }

  .illustration {
    min-width: 400px
    min-height: 400px

    @media phone {
      min-width: 260px
      min-height: 260px
    }
  }

  .title {
    margin-bottom: 24px !important
  }

  .description {
    max-width: 780px
    margin: 0 auto 24px
    white-space: pre-line
  }

  .buttons {
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center

    > * {
      margin: 16px 0 0 0
    }

    > *:not(:last-child) {
      margin: 16px 24px 0 0
    }
  }
</style>
