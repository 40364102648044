<template>
  <Modal
    :title="__('md:app:outdated-version-modal:title')"
    :dismissable="false"
    :closable="false"
    :size="Modal.Size.MEDIUM"
  >
    <Txt
      :class="$style.body"
      :size="Txt.Size.XS"
      :value="__('md:app:outdated-version-modal:body')"
    />

    <ActionFooter
      slot="actions"
      position="relative"
      :primary="{
        label: __('md:app:outdated-version-modal:reload'),
        onPress: reload,
      }"
    />
  </Modal>
</template>

<script>
  import { ActionFooter, Modal, Txt } from '@/core'

  import I18nMixin from '@/mixins/I18nMixin'
  import ModalMixin from '@/mixins/ModalMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  ActionFooter: ActionFooter.__enums,
  Modal: Modal.__enums,
  Txt: Txt.__enums,
},

    name: 'OutdatedVersionModal',
    components: {
      ActionFooter,
      Modal,
      Txt,
    },
    mixins: [I18nMixin, ModalMixin],
    methods: {
      /**
       * Reload the page
       */
      reload() {
        this.dismissModal()
        window.location.reload()
      },
    },
  }
</script>

<style lang="stylus" module>
  .body {
    white-space: pre-line
  }
</style>
