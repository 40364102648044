<template>
  <CSpinner
    v-if="loading"
    :size="CSpinner.Size.XS"
    :color="CSpinner.Color.PRIMARY"
  />

  <VChip
    v-else-if="succeeded"
    v-bind="$attrs"
    color="green lighten-5"
    :class="$style.root"
    class="overline success--text"
    v-on="$listeners"
    v-text="submittedLabel || __('cp:c-chip-form-state:success')"
  />

  <VChip
    v-else-if="form.submitting"
    v-bind="$attrs"
    color="orange lighten-5"
    :class="$style.root"
    class="overline warning--text"
    v-on="$listeners"
    v-text="submittingLabel || __('cp:c-chip-form-state:submitting')"
  />

  <VChip
    v-else-if="form.submitFailed"
    v-bind="$attrs"
    color="red lighten-5"
    :class="$style.root"
    class="overline error--text"
    v-on="$listeners"
    v-text="errorLabel || __('cp:c-chip-form-state:error')"
  />
</template>

<script>
  import CSpinner from '../CSpinner/CSpinner'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  CSpinner: CSpinner.__enums,
},

    name: 'CChipFormState',
    components: {
      CSpinner,
    },
    props: {
      /**
       * Form instance (as declared in the FormMixin
       * @type {Form}
       */
      form: {
        type: Object,
        required: true,
      },
      /**
       * Loading state (used to display a Spinner if the form is not ready yet)
       * @type {Boolean}
       */
      loading: {
        type: Boolean,
        default: false,
      },
      /**
       * Custom label displayed when the form is actually submitting
       * @type {String}
       */
      submittingLabel: {
        type: String,
        default: null,
      },
      /**
       * Custom label displayed when the form has successfully been submitted
       * @type {String}
       */
      submittedLabel: {
        type: String,
        default: null,
      },
      /**
       * Custom label displayed when the form submission failed
       * @type {String}
       */
      errorLabel: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Announce that the submission succeeded. We need it to delay the display.
         * @type {Boolean}
         */
        succeeded: false,
      }
    },
    watch: {
      form: {
        deep: true,
        immediate: true,
        handler(next) {
          if (this.succeeded && (next?.submitting || next?.submitFailed)) {
            clearTimeout(this.tmo)
            this.tmo = null
            this.succeeded = false
          }

          if (next?.submitted && !next?.submitFailed) {
            this.succeeded = true

            this.tmo = setTimeout(() => {
              clearTimeout(this.tmo)
              this.tmo = null
              this.succeeded = false
            }, 3000)
          }
        },
      },
    },
    created() {
      this.tmo = null
    },
  }
</script>

<style lang="stylus" module>
  :global(#app) {
    .root {
      &:global(.v-chip) {
        height: 20px
        padding: 4px 6px
        border-radius: 4px
        line-height: 20px
        user-select: none
      }
    }
  }
</style>
