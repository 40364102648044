<template>
  <div class="calling-code-selector" :class="classes">
    <Multiselect
      ref="multiSelect"
      class="calling-code-selector-multiselect"
      track-by="id"
      label="name"
      :custom-label="({ phonePrefix }) => `+${phonePrefix}`"
      :options="options"
      :searchable="true"
      :internal-search="false"
      :allow-empty="false"
      :max-height="1"
      :disabled="disabled"
      :class="error ? 'invalid' : ''"
      :value="selectedOption"
      placeholder=""
      @search-change="onSearch"
      @select="onCountrySelect"
    >
      <template slot="option" slot-scope="props">
        <div class="calling-code-selector-option-flag flag-icon">
          <img :src="generateFlagIconUrl(props.option.iso2)" />
        </div>
        <div class="calling-code-selector-option-name">
          {{ props.option.name }}
        </div>

        <div class="calling-code-selector-option-phone-prefix">
          {{ `+${props.option.phonePrefix}` }}
        </div>
      </template>
    </Multiselect>
    <span v-if="error" class="error">
      {{ error }}
    </span>
    <div v-if="selectedOption" class="calling-code-selector-selected-flag flag-icon">
      <img :src="generateFlagIconUrl(selectedOption.iso2)" />
    </div>
    <div v-else :class="$('no-selected-flag')" />
  </div>
</template>

<script>
  import _get from 'lodash/get'
  import _keyBy from 'lodash/keyBy'

  import Multiselect from 'vue-multiselect'

  import I18nMixin from '@/mixins/I18nMixin'

  import query from './query.gql'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Multiselect: Multiselect.__enums,
},

    name: 'CallingCodeSelector',
    components: {
      Multiselect,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Selected value (corresponding to a contry's 'id')
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Disable all the controls
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Error message to display in the component
       */
      error: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Countries (fetched by Apollo)
         * @type {Array.<Object>}
         */
        countries: [],
        /**
         * Options provided to the autocomple.
         * It is a subset of countries.
         * @type {Array.<Object>}
         */
        options: [],
      }
    },
    computed: {
      /**
       * Classes applied on the selector
       * @type {Object}
       */
      classes() {
        const { value, disabled } = this

        return {
          'calling-code-selector--empty': !value,
          'calling-code-selector--disabled': disabled,
        }
      },
      /**
       * Hashmap containing the countries indexed by the calling code value
       * @type {Object}
       */
      countriesMap() {
        const countries = this.countries || []

        return _keyBy(countries, 'iso2')
      },
      /**
       * Whole option that is currently selected
       * @type {?Object}
       */
      selectedOption() {
        const { value, countriesMap } = this

        if (!countriesMap) {
          return null
        }

        return value ? countriesMap[value] : null
      },
    },
    apollo: {
      countries: {
        query,
        update(data) {
          const unorderedCountries = _get(data, 'countries') || []

          const countries = [...unorderedCountries] // Avoid mutating data.countries when sorting
            .sort((a, b) => a.name.localeCompare(b.name))

          // Set the inital options that would
          // be displayed before the first search
          this.options = countries

          return countries
        },
      },
    },
    mounted() {
      // vue-multiselect doesn't manage the autocomplete attribute
      // So we have to do it manually:
      const el = _get(this, '$refs.multiSelect.$el')
      const input = el.querySelector('input')

      if (input) {
        input.setAttribute('autocomplete', 'tel-country-code')
      }
    },
    methods: {
      /**
       * Handler fired when a country is picked from the autocomplete input
       * @param {Object} country
       * @returns {void}
       */
      onCountrySelect(country) {
        const { value } = this

        if (country !== value) {
          this.$emit('input', country.iso2)
        }
      },
      /**
       * Handler fired when search is triggerd by the autocomple input
       */
      onSearch(value) {
        this.options = this.countries.filter(country => {
          const name = (_get(country, 'name') || '').toLowerCase()

          return !value || name.includes(value.toLowerCase())
        })
      },
      /**
       * Generate the URL to the flag icon
       * @param {String} iso2
       * @returns {String}
       */
      generateFlagIconUrl(iso2) {
        return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/4x3/${iso2.toLowerCase()}.svg`
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .calling-code-selector {
    position: relative
    cursor: pointer

    .flag-icon {
      display: flex
      align-items: center
      justify-content: center
      min-width: 20px
      pointer-events: none // Disable events on the flag, so clicking on it opens the select
    }

    .calling-code-selector-selected-flag {
      display: none
      position: absolute
      top: 10px
      left: 16px
      height: 40px
    }

    .calling-code-selector-multiselect {
      width: 100%
      box-sizing: content-box

      >>> .multiselect__tags {
        height: 18px
        width: 50px
        display: inline-block
        padding: 10px 0px 10px 48px
        line-height: 19px
        border: solid var(--color-input-border) 1px
        border-radius: $radius-medium
        caret-color: var(--color-input-highlight)

        &:hover {
          border: solid var(--color-input-highlight) 1px
          border-radius: $radius-medium
        }
      }

      .multiselect:not(.multiselect--disabled) {
        &:hover {
          border-color: var(--color-input-highlight)
        }

        &.multiselect--active {
          border-color: var(--color-input-highlight)
        }
      }

      >>> .multiselect__tags-wrap {
        display: none
      }

      >>> .multiselect__content-wrapper {
        position: relative
      }

      >>> .multiselect__tags.invalid input {
        box-shadow: inset 0 -1px 0 var(--color-input-error)
        transition: box-shadow 0.3s ease
      }

      >>> .multiselect__input {
        position: absolute
        width: 100px
        height: 40px
        top: 0
        left: 0px
        background: none
        box-shadow: none
        padding: 0 10px
        font-family: $font
        font-weight: $font-regular
        font-size: 16px
        border: none
        appearance: none
      }

      >>> .multiselect__content {
        width: 300px
        max-height: 191px
        position: absolute
        padding: 8px !important
        margin: 8px 0
        box-sizing: border-box
        background-color: #FFFFFF
        box-shadow: 0px 2px 8px rgba(0, 0, 0, .2)
        overflow-y: scroll
        z-index: 2
      }

      >>> .multiselect__option {
        ellipsis()
        display: flex
        padding: 8px 24px 8px 10px
        font-weight: $font-regular
      }

      >>> .multiselect__option:hover,
      >>> .multiselect__option--highlight {
        background-color: var(--color-input-options-hover)
      }

      .calling-code-selector-option-flag {
        margin-right: 20px
        vertical-align: middle
      }

      .calling-code-selector-option-name {
        ellipsis()
        display: inline-block
        vertical-align: middle
        flex-grow: 1
      }
    }

    &:not(.calling-code-selector--empty) {
      .calling-code-selector-multiselect:not(.multiselect--active) {
        >>> .multiselect__tags input {
          padding-left: 40px
        }

        ~ .calling-code-selector-selected-flag {
          display: block
        }
      }
    }

    .multiselect--disabled {
      >>> .multiselect__single,
      >>> .multiselect__input,
      >>> .calling-code-selector-selected-flag {
        opacity: .32
      }

      ~ .calling-code-selector-selected-flag {
        opacity: .32
      }
    }
  }
</style>
