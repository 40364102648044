<template>
  <VMenu
    v-model="menu"
    :close-on-content-click="false"
    :open-on-hover="true"
    :max-width="300"
    :open-delay="300"
  >
    <template v-slot:activator="{ on }">
      <VBtn
        small
        icon
        v-on="on"
      >
        <VIcon
          :small="true"
          color="blue-grey"
          v-text="'mdi-information-outline'"
        />
      </VBtn>
    </template>

    <VCard outlined class="pa-3">
      <div class="d-flex justify-space-between align-center pb-2">
        <div v-text="__('cp:ethical-reminder-popover:title')" />
        <VBtn
          small
          icon
          @click="menu = false"
        >
          <VIcon
            :small="true"
            v-text="'mdi-close'"
          />
        </VBtn>
      </div>
      <VDivider class="py-2" />
      <div
        class="caption"
        :class="$style.caption"
        v-text="__('cp:ethical-reminder-popover:gdpr-disclaimer')"
      />
    </VCard>
  </VMenu>
</template>

<script>
  export default {
    name: 'EthicalReminderPopover',
    data() {
      return { menu: false }
    },

  }
</script>

<style lang="stylus" module>
    .caption {
        white-space: pre-line
    }
</style>
