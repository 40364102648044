<template>
  <div class="country-selector" :class="classes">
    <Multiselect
      class="country-selector-multiselect"
      track-by="id"
      label="name"
      :placeholder="__('cp:form:country-selector:placeholder')"
      :options="countries"
      :searchable="true"
      :allow-empty="false"
      :max-height="1"
      :disabled="disabled"
      :class="error ? 'invalid' : ''"
      :value="selectedOption"
      @select="onCountrySelect"
    >
      <template slot="option" slot-scope="props">
        <div
          class="country-selector-option-flag flag-icon"
          :class="`flag-icon-${props.option.iso2.toLowerCase()}`"
        />
        <div class="country-selector-option-name">
          {{ props.option.name }}
        </div>
      </template>
    </Multiselect>
    <span v-if="error" class="error">
      {{ error }}
    </span>
    <div
      v-if="selectedOption"
      class="country-selector-selected-flag flag-icon"
      :class="`flag-icon-${selectedOption.iso2.toLowerCase()}`"
    />
    <Icon v-else name="search" :class="$('no-selected-flag')" />
  </div>
</template>

<script>
  import _get from 'lodash/get'
  import _keyBy from 'lodash/keyBy'
  import _values from 'lodash/values'
  import Multiselect from 'vue-multiselect'

  import Icon from '@/core/graphics/Icon/Icon'

  import I18nMixin from '@/mixins/I18nMixin'

  import query from './query.gql'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Multiselect: Multiselect.__enums,
  Icon: Icon.__enums,
},

    name: 'CountrySelector',
    components: {
      Multiselect,
      Icon,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Selected value (corresponding to a contry's 'id')
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Disable all the controls
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Error message to display in the component
       */
      error: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Countries (fetched by Apollo)
         * @type {Array.<Object>}
         */
        countries: [],
      }
    },
    computed: {
      /**
       * Classes applied on the selector
       * @type {Object}
       */
      classes() {
        const { value, disabled } = this

        return {
          'country-selector--empty': !value,
          'country-selector--disabled': disabled,
        }
      },
      /**
       * Hashmap containing the countries indexed by the 'iso3' value
       * @type {Object}
       */
      countriesMap() {
        const { countries } = this

        return _keyBy(countries, 'iso3')
      },
      /**
       * Whole option that is currently selected
       * @type {Object}
       */
      selectedOption() {
        const { value, countriesMap } = this

        if (!countriesMap) {
          return null
        }

        return value ? countriesMap[value] : null
      },
    },
    apollo: {
      countries: {
        query,
        update(data) {
          const countries = _get(data, 'countries') || []

          return [...countries] // Avoid mutating data.countries when sorting
            .sort((a, b) => a.name.localeCompare(b.name))
        },
      },
    },
    methods: {
      _values,
      /**
       * Handler fired when a country is picked from the autocomplete input
       * @param {Object} country
       * @returns {void}
       */
      onCountrySelect(country) {
        const { value } = this

        if (country !== value) {
          this.$emit('input', country.id)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .country-selector {
    position: relative
    cursor: pointer

    .flag-icon {
      pointer-events: none // Disable events on the flag, so clicking on it opens the select
      min-width: 20px
    }

    .country-selector-selected-flag {
      display: none
      position: absolute
      top: 0
      left: 16px
      height: 40px
    }

    &-no-selected-flag {
      display: block
      position: absolute
      top: 11px
      left: 16px
    }

    .multiselect--active ~ .country-selector-no-selected-flag {
      display: none
    }

    .country-selector-multiselect {
      width: 100%

      >>> .multiselect__tags {
        box-sizing: border-box
        width: 100%
        height: 42px
        padding: 10px 0px 10px 48px
        line-height: 19px
        border: solid var(--color-input-border) 1px
        border-radius: $radius-medium
        caret-color: var(--color-input-highlight)

        &:hover {
          border-color: var(--color-input-border)
        }
      }

      &.multiselect--active >>> .multiselect__tags {
        border-color: var(--color-input-highlight)
      }

      .multiselect:not(.multiselect--disabled) {
        &:hover {
          border-color: var(--color-input-border)
        }

        &.multiselect--active {
          border-color: var(--color-input-highlight)
        }
      }

      >>> .multiselect__tags-wrap {
        display: none
      }

      >>> .multiselect__content-wrapper {
        position: relative
      }

      >>> .multiselect__tags.invalid input {
        box-shadow: inset 0 -1px 0 var(--color-input-error)
        transition: box-shadow 0.3s ease
      }

      >>> .multiselect__input {
        box-sizing: border-box
        border: 0
        font-size: inherit
        position: absolute
        left: 16px
        max-width: calc(100% - 17px)
        appearance: none
      }

      >>> .multiselect__content {
        width: 100%
        max-height: 191px
        position: absolute
        padding: 8px !important
        margin: 8px 0
        box-sizing: border-box
        background-color: #FFFFFF
        box-shadow: 0px 2px 8px rgba(0, 0, 0, .2)
        overflow-y: scroll
        z-index: 2
      }

      >>> .multiselect__option {
        ellipsis()
        display: block
        padding: 8px 24px
        font-weight: $font-regular
      }

      >>> .multiselect__option:hover,
      >>> .multiselect__option--highlight {
        background-color: var(--color-input-options-hover)
      }

      .country-selector-option-flag {
        display: inline-block
        margin-right: 10px
        vertical-align: middle
      }

      .country-selector-option-name {
        ellipsis()
        display: inline-block
        vertical-align: middle
      }
    }

    &:not(.country-selector--empty) .country-selector-multiselect:not(.multiselect--active) {
      >>> .multiselect__tags input {
        padding-left: 40px
      }

      ~ .country-selector-selected-flag {
        display: block
      }
    }

    .multiselect--disabled {
      >>> .multiselect__single,
      >>> .multiselect__input,
      >>> .country-selector-selected-flag {
        opacity: .32
      }

      ~ .country-selector-selected-flag {
        opacity: .32
      }
    }
  }
</style>
