<script>
  import _throttle from 'lodash/throttle'
  import ResizeObserver from 'resize-observer-polyfill'

  export default {
    name: 'WithDimensions',
    data() {
      return {
        width: null,
        height: null,
      }
    },
    mounted() {
      const { $el } = this

      // Create the observer which will react to the $el changes.
      // _throttle: we don't want to react to every resize event, just
      // 'from time to time'. Every 200ms.
      const observer = new ResizeObserver(
        _throttle(entries => {
          if (entries[0]) {
            const { width, height } = entries[0].contentRect

            this.width = width
            this.height = height
          }
        }, 200),
      )

      observer.observe($el)
    },
    /**
     * Render function that only return the default scoped slot.
     * This pattern allow us to expose the "width" and "height" without
     * rendering any extra html
     */
    render() {
      const { width, height } = this

      return this.$scopedSlots.default({ width, height })
    },
  }
</script>
