<template>
  <div class="named-section" :class="classes">
    <div :class="$('header')">
      <div :class="$('head')" @click.prevent="handleClick">
        <Txt
          :theme="theme"
          :size="size"
          :type="type"
          :value="name"
          :raw="true"
          class="named-section-text"
          v-bind="$attrs"
        >
          <i v-if="leftIcon && iconIsOld(leftIcon)" slot="left" :class="[leftIcon, 'left-icon']" />
          <Icon v-else-if="leftIcon" slot="left" :name="leftIcon" class="left-icon" />

          <Icon
            v-if="collapsable"
            slot="right"
            name="chevron-right"
            :class="[
              $('collapse-arrow'),
              {
                [$('collapse-arrow--collapsed')]: isCollapsed,
              },
            ]"
          />
          <i
            v-else-if="rightIcon && iconIsOld(rightIcon)"
            slot="right"
            :class="[rightIcon, 'right-icon']"
          />
          <Icon v-else-if="rightIcon" slot="right" :name="rightIcon" class="right-icon" />
        </Txt>

        <slot name="sub" />
      </div>

      <slot name="right" />
    </div>

    <div
      :class="[
        $('content'),
        {
          [$('content--collapsed')]: isCollapsed,
        },
      ]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import Icon from '@/core/graphics/Icon/Icon'
  import Txt from '@/core/text/Txt/Txt'

  import { iconIsOld } from '@/utils/image'

  const { Size, Type, Theme } = Txt

  // Enum of possibilities for the spacing behind the text and the title
  // Default means we compute the spacing according to the size of the title
  const Spacing = {
    DEFAULT: 'default',
    NONE: 'none',
    XXSMALL: 'xxs',
    XSMALL: 'xs',
    SMALL: 's',
    MEDIUM: 'm',
    LARGE: 'l',
  }

  export const NamedSectionSpacing = Spacing
  export const NamedSectionTheme = Theme

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Icon: Icon.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,Type,Spacing,Theme,
    name: 'NamedSection',
    __enums: {
      Size,
      Type,
      Spacing,
      Theme,
    },
    components: {
      Icon,
      Txt,
    },
    inheritAttrs: false,
    props: {
      /**
       * Size of the name of the section
       */
      size: {
        type: String,
        default: Size.XXXS,
      },
      /**
       * Type of text for the name
       */
      type: {
        type: String,
        default: Type.OVERLINE,
      },
      /**
       * Theme of text for the name
       */
      theme: {
        type: String,
        default: Theme.GREY,
      },
      /**
       * If true, can be collapsed
       */
      collapsable: {
        type: Boolean,
        default: false,
      },
      /**
       * Initial isCollapsed value
       */
      collapsed: {
        type: Boolean,
        default: false,
      },
      /**
       * Name of the section to display at the top of the section
       */
      name: {
        type: String,
        required: true,
      },
      /**
       * Icon to display on the left of the title
       */
      leftIcon: {
        type: String,
        default: null,
      },
      /**
       * Icon to display on the right of the title
       */
      rightIcon: {
        type: String,
        default: null,
      },
      /**
       * Spacing between the title and the content, value defined in the enum
       * NamedSectionSpacing
       */
      spacing: {
        type: String,
        default: Spacing.DEFAULT,
      },
    },
    data() {
      const { collapsed } = this

      return {
        /**
         * Wether or not the section is collapsed
         * @type {Boolean}
         */
        isCollapsed: collapsed,
      }
    },
    computed: {
      /**
       * Classes
       * @returns {Object}
       */
      classes() {
        const { spacing, leftIcon, size, collapsable, $ } = this

        // Add the default class we want to apply
        const classes = {
          [$('left')]: leftIcon,
          [$('collapsable')]: collapsable,
        }

        // If the spacing have been specified then we use it
        if (spacing !== Spacing.DEFAULT) {
          classes[`named-section--spacing-${spacing}`] = true

          return classes
        }

        // Otherwise we compute the spacing accoring to the size of the text
        switch (size) {
          case 'm':
          case 'l':
          case 'xl':
          case 'xxl':
          case 'xxxl':
          case 'xxxxl':
          case 'xxxxxl':
            classes['named-section--spacing-s'] = true
            break
          default:
            classes['named-section--spacing-xs'] = true
            break
        }

        return classes
      },
    },
    methods: {
      iconIsOld,
      /**
       * Invert the isCollapsed section state
       * @returns {void}
       */
      handleClick() {
        const { collapsable, isCollapsed } = this

        if (collapsable) {
          this.isCollapsed = !isCollapsed
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .named-section {
    &-text {
      display: flex
    }

    &-head {
      display: flex
      flex-direction: column
    }

    &-header {
      display: flex
      align-items: center
    }

    &-collapsable &-head {
      cursor: pointer
    }

    .txt >>> sup {
      line-height: 10px
    }

    .left-icon {
      margin-right: 12px
      font-size: 10px
    }

    .right-icon {
      font-size: 10px
    }

    i.left-icon {
      font-size: inherit
    }

    &-collapse-arrow {
      position: relative
      left: 8px
      transform: rotate(90deg)
      transition: transform .2s ease-out

      &--collapsed {
        transform: rotate(0deg)
      }
    }

    &--spacing-none &-text {
      margin-bottom: 0
    }

    &--spacing-xxs &-text {
      margin-bottom: 4px
    }

    &--spacing-xs &-text {
      margin-bottom: 8px
    }

    &--spacing-s &-text {
      margin-bottom: 16px
    }

    &--spacing-m  &-text {
      margin-bottom: 24px
    }

    &--spacing-l  &-text {
      margin-bottom: 40px
    }

    &-left &-content {
      margin-left: 24px
    }

    &-content {
      max-height: none
    }

    &-collapsable &-content {
      max-height: 18000px // Required by the transition below
      height: auto
      visibility: visible
      opacity: 1
      transition: max-height .3s cubic-bezier(1,.07,0,.93), opacity .3s, visibility .3s

      &--collapsed {
        max-height: 0
        overflow: hidden
        opacity: 0
        visibility: hidden
      }
    }
  }
</style>
