<template>
  <Component :is="inline ? 'span' : tag" class="txt" :class="classes">
    <slot name="left" />

    <!-- if raw value -->
    <span v-if="value && raw" v-html="value" />

    <!-- The template here is just to avoid white-space: pre-line to add a useless line -->
    <template v-else-if="value">{{ value }}</template>

    <slot v-else />

    <sup v-if="sup">{{ sup }}</sup>

    <slot name="right" />
  </Component>
</template>

<script>
  const Align = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
    JUSTIFY: 'justify',
  }

  const Type = {
    BOLD: 'bold',
    NORMAL: 'normal',
    OVERLINE: 'overline',
    STRIKETHROUGH: 'strikethrough',
    ITALIC: 'italic',
    QUOTE: 'quote',
  }

  const Size = {
    XXXXS: 'xxxxs',
    XXXS: 'xxxs',
    XXS: 'xxs',
    XS: 'xs',
    S: 's',
    M: 'm',
    L: 'l',
    XL: 'xl',
    XXL: 'xxl',
    XXXL: 'xxxl',
    XXXXL: 'xxxxl',
  }

  // For now, it's still possible to use colors as well
  // The ones that are not here are deprecated.
  // Use the theme here instead
  const Theme = {
    DEFAULT: 'default',
    PRIMARY: 'primary',
    PRIMARY_ALT: 'primary-alt',
    DUAL: 'dual',
    INFORMATION: 'information',
    INFORMATION_ALT: 'information-alt',
    WARNING: 'warning',
    WARNING_ALT: 'warning-alt',
    SUCCESS: 'success',
    SUCCESS_ALT: 'success-alt',
    DANGER: 'danger',
    DANGER_ALT: 'danger-alt',
    CONTRAST: 'contrast',
    CONTRAST_SOLID: 'contrast-solid',
    BRAND: 'brand',
    // Colors
    BLACK: 'black',
    GREY: 'grey',
    GREY_MEDIUM: 'grey-medium',
    WHITE: 'white',
    YELLOW_ALT: 'yellow-alt',
    BLUE_DARK: 'blue-dark',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Align,Type,Size,Theme,
    name: 'Txt',
    __enums: {
      Align,
      Type,
      Size,
      Theme,
    },
    inheritAttrs: false,
    props: {
      /**
       * Size of the text to display, can be:
       * [xxxs, xxs, xs, s, m, l, xl, xxl]
       */
      size: {
        type: String,
        default: Size.XXS,
      },
      /**
       * Value to display
       */
      value: {
        type: [String, Number],
        default: '',
      },
      /**
       * Type of text to display, can be: [bold, normal, overline]
       * This will determine the value of different CSS property
       */
      type: {
        type: String,
        default: Type.NORMAL,
      },
      /**
       * Superscript to display after text
       */
      sup: {
        type: String,
        default: '',
      },
      /**
       * Check if display the text inline (span) or in block (div)
       */
      inline: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the title ends with an ellipsis if can't be displayed
       * entirely
       */
      ellipsis: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the text is underlined
       */
      underline: {
        type: Boolean,
        default: false,
      },
      /**
       * Determine where the text should be align, can be:
       * [left, center, right, justify]
       */
      align: {
        type: String,
        default: Align.LEFT,
      },
      /**
       * Theme
       * @type {Txt.Theme}
       */
      theme: {
        type: String,
        default: Theme.DEFAULT,
      },
      /**
       * If 'true, v-html will be used for the value
       */
      raw: {
        type: Boolean,
        default: false,
      },
      /**
       * There is an icon at the left of the text
       */
      leftIcon: {
        type: Boolean,
        default: false,
      },
      /**
       * There is an icon at the right of the text
       */
      rightIcon: {
        type: Boolean,
        default: false,
      },
      /**
       * Tag we want to use for the text, div by default
       */
      tag: {
        type: String,
        default: 'div',
      },
    },
    computed: {
      /**
       * CSS class to applied to the text
       * @type {Object.<Boolean>}
       */
      classes() {
        const { size, ellipsis, align, underline, theme, type, leftIcon, rightIcon } = this

        return {
          [`txt--size-${size}`]: true,
          [`txt--theme-${theme}`]: !!theme,
          [`txt--align-${align}`]: !!align,
          [`txt--type-${type}`]: !!type,
          'txt--ellipsis': ellipsis,
          'txt--underline': underline,
          'txt--icon-left': leftIcon,
          'txt--icon-right': rightIcon,
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .txt {
    &--size {
      &-xxxxs {
        font-size: 9px
        line-height: 12px
      }

      &-xxxs {
        font-size: 12px
        line-height: 16px
      }

      &-xxs {
        font-size: 14px
        line-height: 20px
      }

      &-xs {
        font-size: 16px
        line-height: 24px
      }

      &-s {
        font-size: 20px
        line-height: 26px
      }

      &-m {
        font-size: 24px
        line-height: 32px
      }

      &-l {
        font-size: 32px
        line-height: 48px
      }

      &-xl {
        font-size: 40px
        line-height: 56px
      }

      &-xxl {
        font-size: 48px
        line-height: 64px
      }

      &-xxxl {
        font-size: 56px
        line-height: 72px
      }

      &-xxxxl {
        font-size: 64px
        line-height: 80px
      }
    }

    &--theme {
      &-grey {
        color: var(--color-font-light)
      }

      &-grey-medium {
        color: var(--color-grey-30)
      }

      &-white {
        color: var(--color-white)

        &.txt--type-quote {
          border-left-color: var(--color-grey-20)
        }
      }

      &-red {
        color: var(--color-negative)
      }

      &-yellow-alt {
        color: var(--color-font)
      }

      &-blue-dark {
        color: var(--color-blue-80)
      }

      &-black,
      &-default {
        color: var(--color-font)

        &.txt--type-quote {
          color: var(--color-font-light)
        }
      }

      &-blue,
      &-brand,
      &-information,
      &-information-alt,
      &-primary,
      &-primary-alt {
        color: var(--color-brand)
      }

      &-dual {
        color: var(--color-font)
      }

      &-warning,
      &-warning-alt {
        color: var(--color-warning)
      }

      &-green,
      &-success,
      &-success-alt,
      &-success-void {
        color: var(--color-positive)
      }

      &-danger,
      &-danger-alt {
        color: var(--color-negative)
      }

      &-contrast {
        color: var(--color-font-contrast)
      }

      &-contrast-solid {
        color: var(--color-contrast)
      }
    }

    &&--type {
      &-overline {
        text-transform: uppercase
        font-weight: $font-bold
        letter-spacing: 6px

        &^[0]--size {
          &-xxs {
            letter-spacing: 4px
          }

          &-xxxs {
            font-weight: $font-medium
            letter-spacing: 2px
          }

          &-xxxxs {
            font-weight: $font-medium
            letter-spacing: 1px
          }
        }
      }

      &-normal {
        letter-spacing: 0.5px
        font-weight: $font-regular
      }

      &-strikethrough {
        letter-spacing: 0.5px
        font-weight: $font-regular
        text-decoration: line-through;
      }

      &-italic {
        font-style: italic
      }

      &-bold {
        letter-spacing: 1px
        font-weight: $font-semi-bold

        & ^[0]--size {
          &-xxxxl,
          &-xxxl,
          &-xxl,
          &-xl {
            font-weight: $font-extra-bold
          }

          &-l,
          &-m {
            font-weight: $font-bold
          }
        }
      }

      &-quote {
        font-style: italic
        padding-left: 8px
        white-space: pre-line
        border-left: 3px solid currentColor
      }
    }

    &--ellipsis {
      ellipsis()
    }

    &--underline {
      text-decoration: underline
    }

    &--align {
      &-justify {
        text-align: justify
      }

      &-center {
        text-align: center
      }

      &-left {
        text-align: left
      }

      &-right {
        text-align: right
      }
    }

    &.txt--icon-left,
    &.txt--icon-right {
      display: flex !important
    }

    &--icon {
      &-left > :first-child {
        margin-right: 8px
      }

      &-right > :last-child {
        margin-left: 8px
      }
    }
  }
</style>
