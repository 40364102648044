<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment-timezone'
  import _isDate from 'lodash/isDate'
  import _isString from 'lodash/isString'

  export default {
    computed: {
      ...mapGetters({
        ClockMixin_diff: 'selectAppTimeDiff',
      }),
    },
    methods: {
      /**
       * Returns now date as a Moment Date
       * @returns {Moment}
       */
      now() {
        const { ClockMixin_diff: diff } = this

        return moment().add(diff, 'milliseconds')
      },
      /**
       * Returns yesterday's date as a Moment Date
       */
      yesterday() {
        return this.now().subtract(1, 'day')
      },
      /**
       * Returns tomorrow's date as a Moment Date
       */
      tomorrow() {
        return this.now().add(1, 'day')
      },
      /**
       * Returns 'now' as a JS Date
       * @type {Date}
       */
      nowDate() {
        return this.now().toDate()
      },
      /**
       * Returns 'yesterday' as a JS Date
       * @type {Date}
       */
      yesterdayDate() {
        return this.yesterday().toDate()
      },
      /**
       * Returns the duration ellapsed from now according to the given 'date'
       * @param {String|Date} date
       * @param {String} unit
       * @returns {Duration}
       */
      fromNow(date, unit = 'milliseconds') {
        const { now } = this

        return date ? moment(date).diff(now(), unit) : 0
      },
      /**
       * Returns the date of a diff from now
       * @returns {Date}
       */
      dateFrowNow(diff, unit) {
        return this.now().add(diff, unit)
      },
      /**
       * Returns a humanized format of a relative date (compared to now) or
       * a duration.
       * @param {*} value A relative date (Date, Moment, String) or a
       * duration (Number, String)
       * @param {String} unit Unit of measurement of the duration. Ignored
       * when handling a relative date.
       * @returns {String}
       */
      humanize(value, unit) {
        const { now } = this

        // If the given 'value' is a date
        if (_isDate(value) || moment.isMoment(value) || (_isString(value) && !unit)) {
          return moment(value).from(now())
        }

        // If 'value' is a duration
        return moment.duration(value, unit || 'hours').humanize()
      },
      /**
       * Returns 'true' if the given 'date' is after now
       * @param {String|Date} date
       * @returns {Boolean}
       */
      isAfterNow(date) {
        const { now } = this

        return date ? moment(date).isAfter(now()) : false
      },
      /**
       * Returns 'true' if the given 'date' is equal or after now
       * @param {String|Date} date
       * @returns {Boolean}
       */
      isSameOrAfterNow(date) {
        const { now } = this

        return date ? moment(date).isSameOrAfter(now()) : false
      },
      /**
       * Returns 'true' if the given 'date' is before now
       * @param {String|Date} date
       * @returns {Boolean}
       */
      isBeforeNow(date) {
        const { now } = this

        return date ? moment(date).isBefore(now()) : false
      },
      /**
       * Returns 'true' if the given 'date' is equal or after now
       * @param {String|Date} date
       * @returns {Boolean}
       */
      isSameOrBeforeNow(date) {
        const { now } = this

        return date ? moment(date).isSameOrBefore(now()) : false
      },
      /**
       * Returns the given 'str' string date as a JS Date object
       * @param {String}
       * @returns {Date}
       */
      toDate(str) {
        return str ? moment(str).toDate() : undefined
      },
      /**
       * Check if the date is before today less some hours/days/months...
       * @param  {Date}  date - the date to compare
       * @param  {Number}  toSubstract - the number to substract to today
       * @param  {String}  unit - the unit to substract
       * Can be : years, months, days, hours, minutes, seconds, milliseconds
       * @returns {Boolean}
       */
      isBeforeDate(date, toSubstract, unit = 'days') {
        const { now } = this

        return date && toSubstract
          ? moment(date).isBefore(now().subtract(toSubstract, unit))
          : false
      },
      /**
       * Get time duration between two dates
       * @param  {String|Date}  start - start date
       * @param  {String|Date}  end - end date
       * @returns {Duration}
       */
      betweenDates(start, end, unit = 'milliseconds') {
        const now = this.now()

        // Ignores DST for large time differences
        // @see https://github.com/moment/moment/pull/747
        const fn = ['days', 'weeks', 'months', 'years'].includes(unit) ? moment.utc : moment

        return fn(end || now).diff(start || now, unit)
      },
      /**
       * Get representative time duration between two dates (month or year interval)
       * @param  {String|Date}  start - start date
       * @param  {String|Date}  end - end date
       * @returns {String}
       */
      durationBetween(start, end) {
        const durationInYears = this.betweenDates(start, end, 'years')

        if (durationInYears > 0) {
          return `${durationInYears} years`
        }

        return `${this.betweenDates(start, end, 'months')} months`
      },
    },
  }
</script>
