import _get from 'lodash/get'

import AppTypes from '@/types/app'
import config from '@/config'
import { int } from '@/utils/transform'

/**
 * Request types
 * @type {String}
 */
const QUERY = 'query'
const MUTATION = 'mutation'

/**
 * App message types
 * @type {String}
 */
const { ERROR } = AppTypes.MessageType

/**
 * App errors to handle globally
 */
const {
  OUTDATED_VERSION,
  NOT_FOUND,
  UNAUTHORIZED,
  FORBIDDEN,
  TOO_MANY_LINKEDIN_IMPORTS,
} = AppTypes.AppError

/**
 * App errors that should not display an error to the user
 */
const SILENT_ERRORS = []

/**
 * Router log activation / deactivation
 * @type {Boolean}
 */
const LOGS_ENABLED = config.logs.apollo

/**
 * Apollo "afterware" which is in charge of logging out the user
 * if the session has expired or any authaurization error, and displaying
 * an app error message in other cases.
 * @param {Object} store
 * @param {Object} router
 * @param {Object} request
 * @returns {void}
 */
function errors(store, router, request) {
  const { networkError, graphQLErrors, operation } = request
  const { dispatch } = store

  // Determines the request type ("query" or "mutation") from the operation
  // details.
  const type = _get(operation, 'query.definitions', []).reduce(
    (res, def) => (def.operation === MUTATION ? MUTATION : res || def.operation),
    undefined,
  )

  if (networkError) {
    // If an HTTP error occured, we stop the request evaluation, and lead to a
    // different behaviour according to the case
    const status = int(networkError.status || networkError.statusCode)
    const context = operation.getContext()

    if (LOGS_ENABLED) {
      console.warn('[Apollo] Network Error (%s) : %s', status, networkError)
    }

    switch (status) {
      case UNAUTHORIZED:
        // Unathorized  ->  Disconnected
        dispatch('revoke')
        break
      default:
        // Others  ->  Error message
        if (type === QUERY && !context.silent) {
          dispatch('addMessage', {
            type: ERROR,
            error: networkError,
          })
        }
        break
    }
  }

  // If the HTTP response is ok but any GraphQL error is provided in the
  // response, we handle it here
  if (graphQLErrors) {
    graphQLErrors.some(error => {
      const code =
        error.code ||
        error.status ||
        error.statusCode ||
        _get(error, 'extensions.exception.code') ||
        _get(error, 'extensions.code')

      const message = error.message || 'unknown'
      const { path = [] } = error

      if (LOGS_ENABLED) {
        console.warn(`[Apollo] GraphQL Error (${code}) on ${path.join('.')} : "${message}"`)
      }

      switch (code) {
        case OUTDATED_VERSION:
          dispatch('toggleOutdatedVersion')
          return true
        case FORBIDDEN:
          break
        case UNAUTHORIZED:
          // Unathorized  ->  Disconnected
          dispatch('revoke')
          return true
        case NOT_FOUND:
          // Resource not found -> 404
          if (type === QUERY) {
            router.replace('/404')
            return true
          }
          break
        default:
          if (error.extensions.code === TOO_MANY_LINKEDIN_IMPORTS) {
            dispatch('addMessage', {
              type: ERROR,
              error,
            })
          }
          // Others  ->  Do nothing here (let the app handling the errors)
          if (type === QUERY) {
            if (!SILENT_ERRORS.includes(code)) {
              dispatch('addMessage', {
                type: ERROR,
                error,
              })
            }
            return true
          }
      }

      return false
    })
  }
}

export default errors
