<template>
  <span
    v-if="activityLabel"
    :class="[activityLabel.class, 'caption', $style.label]"
    v-text="activityLabel.text"
  />
</template>

<script>
  import { FreelancerStatus } from '@comet/types'

  const { WRONG_SCOPE, VALIDATED, BANNED } = FreelancerStatus

  export default {
    name: 'FreelancerActivityLabel',
    props: {
      /**
       * Related Freelancer resource
       * @type {Freelance}
       */
      freelancer: {
        type: Object,
        default: null,
      },
    },
    computed: {
      /**
       * Return activity label class and text
       * @type {Object}
       */
      activityLabel() {
        const { freelancer, __ } = this

        const isPreUser = freelancer?.preUser ?? false
        if (isPreUser) {
          return {
            text: __('cp:common:freelancer-activity-label:pre-user'),
            class: 'warning--text',
          }
        }

        if (freelancer?.status === BANNED) {
          return {
            text: __('cp:common:freelancer-activity-label:banned'),
            class: 'negative--text',
          }
        }

        if (freelancer?.status === WRONG_SCOPE) {
          return {
            text: __('cp:common:freelancer-activity-label:wrong-scope'),
            class: 'negative--text',
          }
        }

        if (freelancer?.status !== VALIDATED) {
          return {
            text: __('cp:common:freelancer-activity-label:not-validated'),
            class: 'primary--text',
          }
        }

        return null
      },
    },
  }
</script>

<style lang="stylus" module>
  .label {
    white-space: nowrap
    cursor: default
  }
</style>
