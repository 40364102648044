<template>
  <div :class="classes">
    <div ref="tabsMenuWrapper" class="tabs-menu-wrapper">
      <ul ref="tabMenu">
        <li v-for="(option, index) in computedOptions" :key="index" :class="option.classes">
          <Icon v-if="option.icon" :name="option.icon" :class="$('wrapper-icon')" />

          <AppLink
            v-bind="{ [option.dataCY]: !!option.dataCY }"
            :to="option.to"
            :disabled="option.disabled"
            :text="option.label"
            :size="AppLink.Size.XS"
            :type="option.type"
            data-cy-tabsmenu-link
            @click="onTabPress && onTabPress(index, $event)"
          />

          <Txt
            v-if="option.badge"
            :class="[
              $('wrapper-badge'),
              $(`wrapper-badge--${option.badgeTheme || BadgeTheme.PRIMARY}`),
            ]"
            :value="option.badge"
            :size="Txt.Size.XXXS"
            theme="white"
          />

          <Txt
            v-if="option.sup"
            :class="$('wrapper-sup')"
            :value="option.sup"
            :size="Txt.Size.XXXXS"
            class="tab-menu-sup"
            theme="white"
          />

          <Icon name="chevron-right" :theme="Icon.Theme.BRAND" :class="$('wrapper-chevron')" />
        </li>
      </ul>

      <span :class="$('wrapper-underline')" :style="underlineStyle"></span>
    </div>

    <Transition name="fade">
      <button
        v-if="isOverflowing && scrolledLeft"
        :class="[$('overflow-arrow'), $('overflow-arrow-left')]"
        @click="() => onArrowPress('left')"
      >
        <i class="icon-chevron-left" />
      </button>
    </Transition>

    <Transition name="fade">
      <button
        v-if="isOverflowing && !scrolledRight"
        :class="[$('overflow-arrow'), $('overflow-arrow-right')]"
        @click="() => onArrowPress('right')"
      >
        <i class="icon-chevron-right" />
      </button>
    </Transition>
  </div>
</template>

<script>
  import _get from 'lodash/get'

  import ResizeObserver from 'resize-observer-polyfill'

  import AppLink from '@/core/controls/AppLink/AppLink'
  import Icon from '@/core/graphics/Icon/Icon'
  import RouterMixin from '@/mixins/RouterMixin'
  import Txt from '@/core/text/Txt/Txt'
  import { mapGetters } from 'vuex'
  import query from './query.gql'

  /**
   * Compute the style of the unerline of the selected tab
   * @param {Object} selectedTab is the HTML element of only the selected tan
   * @return {Object.<String>} representing the top / left / width of underline
   */
  function computeUnderlineStyle(selectedTab) {
    const left = selectedTab.offsetLeft
    const top = selectedTab.clientHeight + selectedTab.offsetTop
    const width = selectedTab.clientWidth

    return {
      left: `${left}px`,
      top: `${top}px`,
      width: `${width}px`,
    }
  }

  const Align = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
  }

  const BadgeTheme = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
  }

  const Layout = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  Txt: Txt.__enums,
  Icon: Icon.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Align,BadgeTheme,Layout,
    name: 'TabsMenu',
    __enums: {
      Align,
      BadgeTheme,
      Layout,
    },
    components: {
      AppLink,
      Txt,
      Icon,
    },
    mixins: [RouterMixin],
    props: {
      /**
       * The options of the menu
       */
      options: {
        type: Array,
        default: null,
      },
      /**
       * Allows to display the tabs with a specific layout
       */
      layout: {
        type: String,
        default: Layout.HORIZONTAL,
      },
      /**
       * TabsMenu's horizontal alignment (left, right, center)
       */
      justify: {
        type: String,
        default: Align.LEFT,
      },
      /**
       * Should the menu wrap if needed
       */
      wrap: {
        type: Boolean,
        default: false,
      },
      /**
       * Possibility of custom @click that overrides AppLink behaviour
       */
      onTabPress: {
        type: Function,
        default: null,
      },
    },
    constants: {
      BadgeTheme,
    },
    apollo: {
      user: {
        query,
        update(data) {
          return _get(data, 'me')
        },
        fetchPolicy: 'cache-only',
      },
    },
    data() {
      return {
        /**
         * Object that will contain the style of the underline of the selected
         * tabs
         * @type {Object.<String>}
         */
        underlineStyle: {},
        isOverflowing: false,
        scrolledRight: false,
        scrolledLeft: false,
        badgeNumber: null,
      }
    },
    computed: {
      ...mapGetters(['isFreelancer']),
      /**
       * CSS classes applied to the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { layout, justify, wrap, isOverflowing } = this

        return {
          'tabs-menu': true,
          [`tabs-menu--layout-${layout}`]: !!layout,
          [`tabs-menu--justify-${justify}`]: !!justify,
          'tabs-menu--wrap': !!wrap,
          'tabs-menu--overflowing': !!isOverflowing,
        }
      },
      /**
       * Enrich the options with a data for e2e testing
       */
      computedOptions() {
        const { options } = this

        return options.map(o => {
          const dataCY = o.id ? `data-cy-tab-${o.id.replace(/ /g, '').toLowerCase()}` : null

          return {
            ...o,
            dataCY,
          }
        })
      },
    },
    watch: {
      options: {
        deep: true,
        immediate: true,
        handler() {
          //  Update the style of the underline according to the options
          this.updateUnderlineStyle()
        },
      },
    },
    mounted() {
      // Wait a short delay before updating the underline position as the
      // "offsetLeft" value can be wrong until the entire page drawing
      setTimeout(this.updateUnderlineStyle, 500)

      // Add a event listenner to recompute the style when the page rezie
      window.addEventListener('resize', this.updateUnderlineStyle)

      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          const { scrollLeft, offsetWidth, clientWidth, scrollWidth } = entry.target

          this.isOverflowing = clientWidth < scrollWidth
          this.scrolledRight = scrollLeft + offsetWidth >= scrollWidth
          this.scrolledLeft = scrollLeft > 0
        })
      })

      this.$refs.tabsMenuWrapper.addEventListener('scroll', e => {
        const { scrollLeft, offsetWidth, scrollWidth } = e.target

        this.scrolledRight = scrollLeft + offsetWidth >= scrollWidth
        this.scrolledLeft = scrollLeft > 0
      })

      observer.observe(this.$refs.tabsMenuWrapper)
    },
    beforeDestroy() {
      // Remove the event litenner added before
      window.removeEventListener('resize', this.updateUnderlineStyle)
    },
    methods: {
      /**
       * Compute the style of the underline of the selected tabs according to
       * the options and of the page. Change the data underlineStyle.
       */
      updateUnderlineStyle() {
        const tabMenu = _get(this, '$refs.tabMenu')
        const options = _get(this, 'options', [])

        // Check the option to find the index of the selected tab
        const selectedTabIndex = options.findIndex(o => o.classes && o.classes.active)
        const selectTab = _get(tabMenu, `children[${selectedTabIndex}]`)

        if (selectTab) {
          this.underlineStyle = computeUnderlineStyle(selectTab)
        } else {
          this.underlineStyle = {
            ...this.underlineStyle,
            width: '0px',
          }
        }
      },
      /**
       * Triggered on overflow arrow press
       * Scroll 150px intots the arrow direction
       * @param {String} direction
       * @return {void}
       */
      onArrowPress(direction) {
        if (direction === 'right') {
          this.$refs.tabsMenuWrapper.scrollLeft += 150
        } else if (direction === 'left') {
          this.$refs.tabsMenuWrapper.scrollLeft -= 150
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_variables.styl'

  .tabs-menu {
    position: relative
    height: $app-navbar-height
    overflow-y: hidden

    .tabs-menu-wrapper {
      position: relative
      width: 100%
      height: "calc(%s + 40px)" % $app-navbar-height
      overflow-x: auto
      overflow-y: hidden

      ul {
        align-self: center
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        list-style-type: none
        margin: 0
        padding: 0

        li {
          // We need box-shadow on li (even if already on ul) in case ul wraps
          position: relative
          display: flex
          flex-direction: row
          align-items: center
          white-space: nowrap
          box-shadow: inset 0 -2px 0 var(--color-border)
          opacity: .72
          transition: all .2s ease-in-out

          > a.app-link {
            display: block
            padding: 20px
            font-family: $font
            letter-spacing: .5px
            color: var(--color-font)
          }

          &.disabled {
            opacity: .25
          }

          &:not(.disabled) {
            &:hover {
              cursor: pointer
              opacity: 1
            }

            &.active {
              opacity: 1
              z-index: 2

              > a.app-link {
                text-shadow: .5px 0px 0px currentColor
              }
            }
          }
        }
      }

      &-icon {
        position: absolute
        top: 0
        left: 0
        width: 18px
        height: 100%
        display: flex
        justify-content: center
        align-items: center
      }

      &-badge {
        position: relative
        left: -20px
        min-width: 22px
        height: 22px
        display: flex
        justify-content: center
        align-items: center
        padding: 2px 6px
        margin-left: 8px
        border-radius: $radius-small
        box-sizing: border-box
        font-weight: $font-semi-bold

        &--primary {
          background-color: var(--color-brand)
        }
        &--secondary {
          background-color: var(--color-grey-50)
        }
        &--success {
          background-color: var(--color-positive)
        }
        &--warning {
          background-color: var(--color-warning)
        }
        &--danger {
          background-color: var(--color-negative)
        }
      }

      &-sup {
        position: absolute
        top: 8px
        right: 0
        padding: 1px 6px
        background-color: var(--color-brand)
        border-radius: $radius-large
      }

      &-underline {
        background: var(--color-brand)
        height: 2px
        position: absolute
        // To have the same Y position as the box-shadow
        transform: translateY(-100%)
        transition: all $anim-tabs-menu-duration $anim-tabs-menu-timing
        width: 0
        z-index: 2
      }

      &-chevron {
        display: none
      }
    }

    // LAYOUT
    &--layout-vertical {
      @media laptop-up {
        height: auto
        padding: 24px 0

        .tabs-menu-wrapper {
          height: auto
          overflow-x: hidden

          ul {
            flex-direction: column
            box-shadow: none !important

            li {
              box-shadow: inset 0 -1px 0 var(--color-border)

              &:last-child {
                box-shadow: none
              }

              > a.app-link {
                flex: 1
                padding: 12px 16px
              }

              &:not(.disabled).active > a.app-link,
              &:not(.disabled).active > a.app-link >>> div {
                color: var(--color-brand)
              }

              &:not(.disabled).active .tabs-menu-wrapper-chevron {
                opacity: 1
              }
            }
          }

          &-badge {
            position: static
          }

          &-underline {
            display: none
          }

          &-chevron {
            position: relative
            right: 0
            width: 24px
            height: 24px
            display: block
            opacity: 0
            transition: all .2s ease-in-out
          }
        }

        .tabs-menu-overflow-arrow {
          display: none
        }
      }
    }

    // JUSTIFY
    &--justify {
      &-left .tabs-menu-wrapper ul {
        justify-content: left
        box-shadow: inset 0 -2px 0 var(--color-border)
      }

      &-center .tabs-menu-wrapper ul {
        justify-content: center
      }

      &-right .tabs-menu-wrapper ul {
        justify-content: right
        box-shadow: inset 0 -2px 0 var(--color-border)
      }
    }

    // WRAP
    &--wrap .tabs-menu-wrapper ul {
      flex-wrap: wrap
    }

    &-overflow-arrow {
      position: absolute
      height: 20px
      width: 20px
      top: 22px
      z-index: 3
      display: flex
      align-items: center
      justify-content: center
      border-radius: 5px
      background-color: var(--color-brand)
      border: 0
      padding: 0
      font-size: 16px

      i {
        color: var(--color-font-contrast)
      }

      &-left {
        left: 0
      }

      &-right {
        right: 0
      }
    }
  }
</style>
