<script>
  import { __, format } from '@comet/i18n'

  export default {
    computed: {
      /**
       * Current locale value
       * @type {String}
       */
      locale() {
        const { $i18n } = this

        return $i18n ? $i18n.locale : null
      },
    },
    methods: {
      /**
       * Translate the given 'key' message via our i18n module (vue-i18n)
       * @param {String} key
       * @param {Number} quantity (for pluralization) can be omitted
       * @param {String|Array|Object} values to extend the translation with
       * @returns {String}
       */
      __,
      /**
       * Format the given value in string according to the specified 'type' and
       * 'options'
       * @param {String} type
       * @param {*} value
       * @param {Object} options
       * @returns {String}
       */
      format,
    },
  }
</script>
