<template>
  <div :class="['banner', `banner--theme-${theme}`]">
    <AppLink
      v-if="to"
      :to="to"
      :blank="isExternalLink"
      :type="isExternalLink ? 'external' : 'router'"
      class="banner-content banner-link"
      @click="onContentPress"
    >
      <slot />
    </AppLink>

    <div v-else class="banner-content">
      <slot />
    </div>

    <NavButton
      v-if="closable"
      icon="icon-remove"
      size="medium"
      :theme="theme === DEFAULT ? NavButton.Theme.PRIMARY : NavButton.Theme.WARNING"
      @click="onClosePress"
    />
  </div>
</template>

<script>
  import AppLink from '@/core/controls/AppLink/AppLink'
  import NavButton from '@/core/controls/NavButton/NavButton'

  const Theme = {
    DEFAULT: 'default',
    WARNING: 'warning',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  NavButton: NavButton.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme,
    name: 'Banner',
    __enums: {
      Theme,
    },
    components: {
      AppLink,
      NavButton,
    },
    props: {
      /**
       * Link to which the banner should lead
       */
      to: {
        type: String,
        default: null,
      },
      /**
       * If 'true', the banner display a cross emiting a "close" event on click
       * Else, display nothing
       */
      closable: {
        type: Boolean,
        default: true,
      },
      /**
       * Theme
       * @type {Txt.Theme}
       */
      theme: {
        type: String,
        default: Theme.DEFAULT,
      },
    },
    constants: {
      DEFAULT: Theme.DEFAULT,
    },
    computed: {
      /**
       * Check if 'to' is an external link or not
       * @type {Boolean}
       */
      isExternalLink() {
        const { to } = this

        return to && to.startsWith('https')
      },
    },
    methods: {
      /**
       * Transmit the Vue-event "click" when the content is pressed
       * @returns {void}
       */
      onContentPress() {
        this.$emit('click')
      },
      /**
       * Transmit the Vue-event "close" when clicking the close button
       * @returns {void}
       */
      onClosePress() {
        this.$emit('close')
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .banner {
    width: 100%
    display: flex
    flex-direction: row
    align-items: center
    font-size: 14px
    line-height: 24px
    color: var(--color-font-contrast)
    box-sizing: border-box

    &--theme-default {
      background-color: var(--color-brand)
    }
    &--theme-warning {
      background-color: var(--color-warning)
    }

    .banner-content {
      position: relative
      flex: 1
      padding: 8px 32px
      text-align: center

      >>> a:not(.banner-link) {
        font-family: inherit
        text-decoration: underline
        margin: 0 2px
      }

      .nav-button {
        position: absolute
        right: -24px
        top: 0
        bottom: 0
        display: flex
        align-items: center
        color: inherit

        &.small {
          height: 100%
        }
      }
    }
  }
</style>
