<template>
  <div v-if="show" class="browser-compatibility-banner">
    <Box :theme="BoxTheme.WARNING_SOLID" :class="$('box')">
      <Txt :theme="Txt.Theme.WHITE" v-html="text" />
      <NavButton
        :text="__('co:action:close')"
        theme="secondary"
        :class="$('button')"
        @click="onClose"
      />
    </Box>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { detect } from 'detect-browser'

  import Box, { BoxTheme } from '@/core/layout/Box/Box'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import Txt from '@/core/text/Txt/Txt'

  import I18nMixin from '@/mixins/I18nMixin'

  const SUPPORTED_BROWSERS = [
    'android',
    'chrome',
    'chromium-webview',
    'firefox',
    'ios',
    'ios-webview',
    'opera',
    'safari',
  ]

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Box: Box.__enums,
  NavButton: NavButton.__enums,
  Txt: Txt.__enums,
},

    name: 'BrowserCompatibilityBanner',
    components: {
      Box,
      NavButton,
      Txt,
    },
    mixins: [I18nMixin],
    constants: {
      BoxTheme,
    },
    computed: {
      ...mapGetters({
        banners: 'selectBanners',
      }),
      /**
       * Is "true" if the banner may be displayed according to the stored
       * value
       * @type {Boolean}
       */
      show() {
        return this.banners.browserCompatibility === true
      },
      /**
       *
       * @type {String}
       */
      text() {
        const { __ } = this
        const blank = "target='blank' rel='noopener noreferrer'"

        return __('cp:app:browser-compatibility-banner:text', {
          chrome: `<a href='https://www.google.fr/chrome/' ${blank}>Google Chrome</a>`,
          safari: `<a href='https://www.apple.com/fr/safari/' ${blank}>Safari</a>`,
          firefox: `<a href='https://www.mozilla.org/' ${blank}>Firefox</a>`,
          opera: `<a href='http://www.opera.com/' ${blank}>Opera</a>`,
        })
      },
    },
    created() {
      const { banners, showBanner } = this
      const browser = detect()

      if (banners.browserCompatibility !== false && !SUPPORTED_BROWSERS.includes(browser.name)) {
        showBanner('browserCompatibility')
      }
    },
    methods: {
      ...mapActions(['showBanner', 'dismissBanner']),
      /**
       * Mark the banner as "treated" when it is closed
       * @returns {void}
       */
      onClose() {
        this.dismissBanner('browserCompatibility')
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_layout.styl'

  .browser-compatibility-banner {
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%
    background: var(--color-background-overlay)
    z-index: 12

    &-box {
      max-width: 600px
      display: flex
      flex-direction: row
      align-items: center
      margin: 8px auto 0

      a {
        text-decoration: underline
      }
    }

    &-button {
      margin-left: 32px
    }

    @media phone {
      &-box {
        max-width: none
        flex-direction: column
        margin: 8px 8px 0
      }

      &-button {
        margin-top: 16px
        margin-left: 0
      }
    }
  }
</style>
