<template>
  <Field :label="label" :error="error" :disabled="disabled" class="country-field">
    <CountrySelector :value="value" :disabled="disabled" :error="error" @input="onInput" />
  </Field>
</template>

<script>
  import CountrySelector from '@/core/inputs/CountrySelector/CountrySelector'
  import Field from '@/core/layout/Field/Field'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  CountrySelector: CountrySelector.__enums,
  Field: Field.__enums,
},

    name: 'CountryField',
    components: {
      CountrySelector,
      Field,
    },
    props: {
      /**
       * Address input value
       */
      value: {
        type: String,
        default: undefined,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Field's error if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      /**
       * On input, emit event to notify the parent
       * @returns {void}
       */
      onInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>
