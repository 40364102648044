<template>
  <div
    class="d-flex flex-row align-center"
    :class="$style.root"
  >
    <template v-for="button in buttons">
      <VTooltip :key="button.id" bottom>
        <template v-slot:activator="{ on }">
          <VBtn
            :key="button.id"
            v-bind="{ xSmall, small, large, xLarge }"
            icon
            color="blue-grey"
            :disabled="button.disabled"
            @click.stop="onClick(button, $event)"
            v-on="on"
          >
            <VIcon
              v-bind="{ xSmall, small, dense, large, xLarge }"
              color="blue-grey"
              v-text="button.icon"
            />
          </VBtn>
        </template>

        <!-- Tooltip Content -->
        <div v-if="button.isLink">
          <span class="font-weight-bold" v-text="__('cp:contact-buttons:go-to')" />
          <span class="ml-2" v-text="button.label" />
        </div>

        <div v-else class="d-flex">
          <div class="d-flex flex-column align-end font-weight-bold">
            <span v-text="__('cp:contact-buttons:click')" />
            <span v-text="__('cp:contact-buttons:cmd-click')" />
          </div>
          <div class="d-flex flex-column ml-2">
            <span v-html="__('cp:contact-buttons:to-copy-field', { label: button.label })" />
            <span v-text="__('cp:contact-buttons:external-open')" />
          </div>
        </div>
      </VTooltip>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'ContactButtons',
    props: {
      /**
       * Buttons to display
       * @type {Array}
       *
       * Example
       * [{
            id: 'email',
            icon: 'mdi-email-outline',
            label: email,
            isLink: false, // when link, not useful to copy it
            href: `mailto:${email}`,
            disabled: !email,
          }]
       */
      buttons: {
        type: Array,
        default: () => [],
      },
      /**
       * Custom sizes applied to buttons and their icons
       */
      xSmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      xLarge: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      /**
       * Defines behavior when clicking on button
       * depending on button isLink type and CMD key pressed
       * (Or for Windows weirdos, CTRL key)
       *
       * - if button is a direct link, open in a new tab
       * - Otherwise, button is a contact info
       *     - by default, copy it
       *     - if CMD key is active, open link in a new tab
       */
      onClick(button, event) {
        if (button.isLink || event.metaKey || event.ctrlKey) {
          window.open(button.href,'_blank').focus()
          return
        }

        return navigator.clipboard.writeText(button.label)
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    > *:not(:last-child) {
      margin-right: 4px
    }
  }
</style>
