<template>
  <label class="radio-button" :class="classes">
    <span />
    <input
      class="radio-button-input"
      type="radio"
      :checked="value === expectedValue"
      :disabled="disabled"
      @change="onChange"
    />
    <div class="radio-button-label">
      <slot v-if="$slots.label" name="label" />
      <Txt v-else :value="label" :size="Txt.Size.XS" :raw="raw" />
    </div>
    <div class="radio-button-sublabel">
      <slot name="sublabel" />
    </div>
  </label>
</template>

<script>
  import Txt from '@/core/text/Txt/Txt'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

    name: 'RadioButton',
    components: {
      Txt,
    },
    props: {
      /**
       * value that will be updated (also allow a initial value)
       */
      value: {
        type: [String, Number, Boolean],
        default: undefined,
      },
      /**
       * Expected value to be considered "checked"
       */
      expectedValue: {
        type: [String, Number, Boolean],
        required: true,
      },
      /**
       * Allow to disable the radio-button
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Label to display near the checkbox
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * If the label in the text should be display as vhtml or not
       */
      raw: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied on the radio-button button in order to easily apply
       * styles according to its value
       * @type {Object.<Boolean>}
       */
      classes() {
        const { value, expectedValue } = this

        return {
          'radio-button--on': value === expectedValue,
          'radio-button--off': value !== expectedValue,
        }
      },
    },
    methods: {
      /**
       * Fired when the radio-button is clicked (indeed value is toggled)
       * @returns {void}
       */
      onChange() {
        const { expectedValue } = this

        this.$emit('input', expectedValue)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'
  @import '~@/assets/css/_grid.styl'

  .radio-button {
    position: relative
    display: inline-block
    margin-bottom: 0
    padding-left: 1.8em
    font-family: inherit
    font-size: inherit
    line-height: inherit
    text-transform: inherit
    letter-spacing: inherit
    font-weight: $font-regular
    color: inherit
    cursor: pointer

    /* Hide default HTML radio-button */
    > input {
      width: 1.2em
      height: 1.2em
      position: absolute
      top: .1em
      left: 0
      margin: 0
      opacity: 0
      cursor: pointer
    }

    .radio-button-input {
      margin-right: 10px
    }

    .radio-button-label {
      line-height: 1.4em
    }

    .radio-button-sublabel {
      color: var(--color-font-light)
    }

    > span:first-child {
      width: 1.2em
      height: 1.2em
      display: inline-block
      position: absolute
      top: .1em
      left: 0
      vertical-align: bottom
      background-repeat: no-repeat
      background-size: cover
    }

    &.radio-button--on > span:first-child {
      background-image: url('../../../assets/images/controls/radio-button-on.svg')
    }

    &.radio-button--off > span:first-child {
      background-image: url('../../../assets/images/controls/radio-button-off.svg')
    }

    > span:first-child + *:not(:last-child) {
      line-height: 1.4em
      vertical-align: bottom
    }

    &:hover, &:focus {
      > span:first-child {
        transform: scale(1.1)
      }
    }
  }
</style>
