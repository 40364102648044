import { computeBackofficeUrl, resources } from '@comet/urls'
import { __ } from '@comet/i18n'

import UserTypes from '@/types/user'
import { extract } from '@/utils/app'
import { int } from '@/utils/transform'

const BackofficeLayout = () => import('@/layouts/BackofficeLayout/BackofficeLayout')
const ChatView = () => import('@/views/common/ChatView/ChatView')
const CompanyCreateView = () => import('@/views/backoffice/CompanyCreateView/CompanyCreateView')
const CompanyListView = () => import('@/views/backoffice/CompanyListView/CompanyListView')
const CompanyView = () => import('@/views/backoffice/CompanyView/CompanyView')
const ConversationListView = () =>
  import('@/views/backoffice/ConversationListView/ConversationListView')
const CorporateCreateView = () =>
  import('@/views/backoffice/CorporateCreateView/CorporateCreateView')
const CorporateListView = () => import('@/views/backoffice/CorporateListView/CorporateListView')
const CorporateView = () => import('@/views/backoffice/CorporateView/CorporateView')
const DealView = () => import('@/views/backoffice/DealView/DealView')
const DashboardView = () => import('@/views/backoffice/DashboardView/DashboardView')
const MatchView = () => import('@/views/backoffice/MatchView/MatchView')
const PreFreelancerCreateView = () =>
  import('@/views/backoffice/PreFreelancerCreateView/PreFreelancerCreateView')
const PreFreelancerBulkImportView = () =>
  import('@/views/backoffice/PreFreelancerBulkImportView/PreFreelancerBulkImportView')
const FreelancerListView = () => import('@/views/backoffice/FreelancerListView/FreelancerListView')
const FreelancerView = () => import('@/views/backoffice/FreelancerView/FreelancerView')
const FreelancerEditView = () => import('@/views/backoffice/FreelancerEditView/FreelancerEditView')
const InvoicingListView = () => import('@/views/backoffice/InvoicingListView/InvoicingListView')
const TeamMemberDetailsView = () =>
  import('@/views/backoffice/TeamMemberDetailsView/TeamMemberDetailsView')
const MissionCreateView = () => import('@/views/backoffice/MissionCreateView/MissionCreateView')
const MissionListView = () => import('@/views/backoffice/MissionListView/MissionListView')
const MissionView = () => import('@/views/backoffice/MissionView/MissionView')
const SearchView = () => import('@/views/backoffice/SearchView/SearchView')
const TeamListView = () => import('@/views/backoffice/TeamListView/TeamListView')
const MissionFunnel = () => import('@/components/backoffice/MissionFunnel/MissionFunnel')
const FreelancerAgencyView = () =>
  import('@/views/backoffice/FreelancerAgencyView/FreelancerAgencyView')
const TelescopeView = () => import('@/views/backoffice/TelescopeView/TelescopeView')
const DocumentationView = () => import('@/views/documentation/DocumentationView/DocumentationView')
const ColorsCheatsheetView = () =>
  import('@/views/documentation/ColorsCheatsheetView/ColorsCheatsheetView')
const ControlsCheatsheetView = () =>
  import('@/views/documentation/ControlsCheatsheetView/ControlsCheatsheetView')
const EventsCheatsheetView = () =>
  import('@/views/documentation/EventsCheatsheetView/EventsCheatsheetView')
const IconsCheatsheetView = () =>
  import('@/views/documentation/IconsCheatsheetView/IconsCheatsheetView')

const { TEAM_MEMBER } = UserTypes.UserRole

/**
 * Format the route's title into the "Enterprise" env including the given string
 * @param {String} title
 * @returns {String}
 */
function formatTitle(title) {
  return `${__('app:backoffice:title')} - ${title}`
}

/**
 * Routes specific to the "/backoffice" environment
 */
export default [
  // Private
  {
    path: '/backoffice',
    component: BackofficeLayout,
    meta: {
      private: true,
      authorized: [TEAM_MEMBER],
      redirect: '',
      title: to => formatTitle(to.path),
    },
    children: [
      {
        path: '',
        alias: resources.backoffice.FUNNEL,
        component: MissionFunnel,
      },
      {
        path: computeBackofficeUrl('dashboard'),
        component: DashboardView,
      },
      {
        path: computeBackofficeUrl('freelancerAgency'),
        component: FreelancerAgencyView,
      },
      {
        path: computeBackofficeUrl('telescope'),
        component: TelescopeView,
      },
      {
        path: computeBackofficeUrl('telescopeSearch'),
        component: TelescopeView,
        props: extract(['freelanceSearchId'], [], { freelanceSearchId: int }),
      },
      {
        path: computeBackofficeUrl('search'),
        component: SearchView,
        props: extract([], ['q']),
      },
      {
        path: computeBackofficeUrl('companies'),
        component: CompanyListView,
      },
      {
        path: computeBackofficeUrl('company'),
        component: CompanyView,
        props: extract(['id'], [], { id: int }),
      },
      {
        path: computeBackofficeUrl('companyAdd'),
        component: CompanyCreateView,
        props: extract(null, ['to']),
      },
      {
        path: computeBackofficeUrl('corporates'),
        component: CorporateListView,
      },
      {
        path: computeBackofficeUrl('corporate'),
        component: CorporateView,
        props: extract(['id'], [], { id: int }),
        meta: {
          bookmark: view => view.corporate?.teamMemberBookmark,
        },
      },
      {
        path: computeBackofficeUrl('corporateAdd'),
        component: CorporateCreateView,
        props: extract(null, ['company', 'to'], { company: int }),
      },
      {
        path: computeBackofficeUrl('freelancerAdd'),
        component: PreFreelancerCreateView,
      },
      {
        path: computeBackofficeUrl('preFreelancerBulkImport'),
        component: PreFreelancerBulkImportView,
      },
      {
        path: computeBackofficeUrl('freelancers'),
        component: FreelancerListView,
        props: extract([], ['mode']),
      },
      {
        path: computeBackofficeUrl('freelancer'),
        component: FreelancerView,
        props: extract(['id'], [], { id: int }),
      },
      {
        path: computeBackofficeUrl('freelancerEdit'),
        component: FreelancerEditView,
        props: extract(['id'], [], { id: int }),
      },
      {
        path: computeBackofficeUrl('missionAdd'),
        component: MissionCreateView,
        props: extract(null, ['company', 'corporate'], { company: int, corporate: int }),
      },
      {
        path: computeBackofficeUrl('missions'),
        component: MissionListView,
        props: extract([], ['mode']),
      },
      {
        path: computeBackofficeUrl('mission'),
        component: MissionView,
        props: extract(['id'], [], { id: int }),
        meta: { missionContext: true },
      },
      {
        path: computeBackofficeUrl('funnel'),
        component: MissionView,
        props: extract(['id'], [], { id: int }),
        meta: { missionContext: true },
      },
      {
        path: computeBackofficeUrl('match'),
        props: extract(['id', 'freelancerId', 'section'], null, { id: int, freelancerId: int }),
        component: MatchView,
        meta: { missionContext: true },
      },
      {
        path: computeBackofficeUrl('invoicing'),
        component: InvoicingListView,
      },
      {
        path: computeBackofficeUrl('deal'),
        component: DealView,
        props: extract(['missionId', 'id'], null, { id: int }),
      },
      {
        path: computeBackofficeUrl('teams'),
        component: TeamListView,
      },
      {
        path: computeBackofficeUrl('team'),
        component: TeamMemberDetailsView,
        props: extract(['id'], [], { id: int }),
      },
      {
        path: computeBackofficeUrl('conversations'),
        component: ConversationListView,
      },
      {
        path: computeBackofficeUrl('conversation'),
        component: ChatView,
        meta: {
          title: () => __('app:route:public:chat'),
        },
        props: extract(['env', 'chatConversationId']),
      },
      /**
       * Documentation routes
       */
      {
        path: 'docs',
        component: DocumentationView,
      },
      {
        path: 'docs/colors',
        component: ColorsCheatsheetView,
      },
      {
        path: 'docs/controls',
        component: ControlsCheatsheetView,
      },
      {
        path: 'docs/events',
        component: EventsCheatsheetView,
      },
      {
        path: 'docs/icons',
        component: IconsCheatsheetView,
      },
    ],
  },
]
