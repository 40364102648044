import { __ } from '@comet/i18n'

import UserTypes from '@/types/user'
import { extract } from '@/utils/app'
import { bool, int } from '@/utils/transform'
import FreelancerTypes from '@/types/freelancer'

const SigninView = () => import('@/views/freelancer/SigninView/SigninView')
const CompanyView = () => import('@/views/freelancer/CompanyView/CompanyView')
const SignupView = () => import('@/views/freelancer/SignupView/SignupView')
const ChatView = () => import('@/views/common/ChatView/ChatView')
const DashboardView = () => import('@/views/freelancer/DashboardView/DashboardView')
const ExploreView = () => import('@/views/freelancer/ExploreView/ExploreView')
const ExploreMatchedView = () => import('@/views/freelancer/ExploreMatchedView/ExploreMatchedView')
const ExploreUnmatchedView = () =>
  import('@/views/freelancer/ExploreUnmatchedView/ExploreUnmatchedView')
const MissionView = () => import('@/views/freelancer/MissionView/MissionView')
const ConnectMissionView = () => import('@/views/freelancer/ConnectMissionView/ConnectMissionView')
const MatchListView = () => import('@/views/freelancer/MatchListView/MatchListView')
const EndorsingBase = () => import('@/components/freelancer/EndorsingBase/EndorsingBase')
const EndorsingErrorView = () => import('@/views/freelancer/EndorsingErrorView/EndorsingErrorView')
const EndorsingThanksView = () =>
  import('@/views/freelancer/EndorsingThanksView/EndorsingThanksView')
const FreelancerPublicView = () =>
  import('@/views/common/FreelancerPublicView/FreelancerPublicView')
const SponsorshipView = () => import('@/views/freelancer/SponsorshipView/SponsorshipView')
const MeView = () => import('@/views/freelancer/MeView/MeView')
const ParametersView = () => import('@/views/freelancer/ParametersView/ParametersView')
const OutOfScopeView = () => import('@/views/freelancer/OutOfScopeView/OutOfScopeView')
const RegisterView = () => import('@/views/freelancer/RegisterView/RegisterView')
const ConversationListView = () =>
  import('@/views/freelancer/ConversationListView/ConversationListView')
const FreelancerLayout = () => import('@/layouts/FreelancerLayout/FreelancerLayout')

const { WRONG_SCOPE, BANNED } = FreelancerTypes.FreelancerStatus
const { FREELANCER } = UserTypes.UserRole
const { PREVENT, PUBLIC } = UserTypes.UserFetchPolicy

/**
 * Append a Freelancer environment label at the end of the given string
 * @param {String} title
 * @returns {String}
 */
function formatTitle(title) {
  return `${title} - ${__('app:freelancer:title')}`
}

export default [
  /**
   * Freelancer's environment views
   * (public and private views commonly used by the freelancers)
   */
  {
    path: '/freelancer/profile/:publicId',
    component: FreelancerPublicView,
    props: extract(['publicId'], ['readOnly', 'params'], { readOnly: bool }),
    meta: {
      title: () => formatTitle(__('app:route:freelancer:my-profile')),
      userPolicy: PUBLIC,
    },
  },
  {
    path: '/freelancer/signin',
    component: SigninView,
    meta: {
      authorized: [FREELANCER],
      title: () => formatTitle(__('app:route:freelancer:signin')),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/freelancer/signup',
    component: SignupView,
    props: extract(null, ['email']),
    meta: {
      authorized: [FREELANCER],
      title: () => formatTitle(__('app:route:freelancer:signin')),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/freelancer/register',
    component: RegisterView,
    props: extract(null, ['phoneNumber', 'firstName', 'lastName', 'email']),
    meta: {
      title: () => formatTitle(__('app:route:freelancer:signin')),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/freelancer',
    component: FreelancerLayout,
    meta: {
      private: true,
      authorized: [FREELANCER],
      banned: [WRONG_SCOPE, BANNED],
      redirect: '/freelancer/signin',
      title: () => formatTitle(__('app:route:freelancer:default')),
    },
    children: [
      // NavIndex : the order of the route in the navMenu (for animation)
      // !! Beware of copy/pasting
      {
        path: '',
        redirect: 'dashboard',
      },
      {
        path: 'dashboard',
        component: DashboardView,
        meta: {
          navIndex: 1,
          title: () => formatTitle(__('app:route:freelancer:signin')),
        },
      },
      {
        path: 'profile',
        alias: 'me',
        component: MeView,
        meta: {
          navIndex: 2,
          title: () => formatTitle(__('app:route:freelancer:me')),
        },
      },
      {
        path: 'explore',
        component: ExploreView,
        meta: {
          navIndex: 3,
          title: () => formatTitle(__('app:route:freelancer:explore')),
        },
      },
      {
        path: 'explore/match/:id',
        component: ExploreMatchedView,
        meta: {
          title: to => formatTitle(__('app:route:freelancer:explore-match', { id: to.params.id })),
        },
        props: extract(['id'], null, { id: int }),
      },
      {
        path: 'explore/mission/:id',
        component: ExploreUnmatchedView,
        meta: {
          title: to =>
            formatTitle(__('app:route:freelancer:explore-mission', { id: to.params.id })),
        },
        props: extract(['id'], null, { id: int }),
      },
      {
        path: 'missions',
        component: MatchListView,
        meta: {
          navIndex: 4,
          title: () => formatTitle(__('app:route:freelancer:missions')),
        },
      },
      {
        path: 'company',
        component: CompanyView,
        meta: {
          navIndex: 5,
          title: () => formatTitle(__('app:route:freelancer:company')),
        },
      },
      {
        path: 'missions/:id(\\d+)/:section?',
        props: extract(['id', 'section'], null, { id: int }),
        component: MissionView,
        meta: {
          title: () => formatTitle(__('app:route:freelancer:missions')),
          transitions: {
            in: 'fade',
            out: 'fade',
          },
        },
      },
      {
        path: 'messages',
        component: ConversationListView,
        meta: {
          navIndex: 6,
          title: () => formatTitle(__('app:route:freelancer:messages')),
        },
      },
      {
        path: 'messages/:chatConversationId',
        component: ChatView,
        meta: {
          title: () => __('app:route:public:chat'),
        },
        props: extract(['env', 'chatConversationId']),
      },
      {
        path: 'sponsorship',
        component: SponsorshipView,
        meta: {
          title: () => formatTitle(__('app:route:freelancer:sponsorship')),
        },
      },
      {
        path: 'parameters',
        component: ParametersView,
        meta: {
          title: () => formatTitle(__('app:route:freelancer:parameters')),
        },
      },
      {
        path: 'connect',
        component: ConnectMissionView,
        meta: {
          title: () => formatTitle(__('app:route:freelancer:missions')),
        },
      },
    ],
  },

  /**
   * Only Wrong Scope views
   */
  {
    path: '/freelancer',
    component: FreelancerLayout,
    meta: {
      private: true,
      authorized: [FREELANCER],
      only: [WRONG_SCOPE],
      redirect: '/freelancer/signin',
      title: () => formatTitle(__('app:route:freelancer:default')),
    },
    children: [
      {
        path: 'sorry',
        component: OutOfScopeView,
        meta: {
          title: () => formatTitle(__('app:route:freelancer:out-of-scope')),
        },
      },
    ],
  },

  /**
   * Endorsement of the Freelancer's professional experiences
   * (public views accessible by anyone)
   */
  {
    path: '/freelancer/endorse/error',
    component: EndorsingErrorView,
    meta: {
      title: () => formatTitle(__('app:route:freelancer:endorsing')),
    },
  },
  {
    path: '/freelancer/endorse/thanks',
    component: EndorsingThanksView,
    meta: {
      title: () => formatTitle(__('app:route:freelancer:endorsing')),
    },
  },
  {
    path: '/freelancer/endorse/:publicId?',
    component: EndorsingBase,
    meta: {
      title: () => formatTitle(__('app:route:freelancer:endorsing')),
    },
    props: extract(['publicId'], ['experienceId']),
  },
]
