<template>
  <div class="upload-input">
    <AppLink v-if="value" type="external" :to="_get(value, 'url')" :blank="true">
      <Txt
        :class="$('label')"
        :value="label || _get(value, 'name')"
        :size="Txt.Size.XS"
        theme="blue"
      />
    </AppLink>
    <Txt
      v-else
      :value="
        label
          ? __('cp:form:upload-input:empty-with-label', { label })
          : __('cp:form:upload-input:empty')
      "
      :size="Txt.Size.XS"
    />
    <NavButton
      v-if="value && editable"
      icon="icon-trash"
      theme="secondary-void"
      :class="$('button')"
      @click="onDelete"
    />
    <NavButton
      v-else-if="!value && editable"
      left-icon="download"
      :theme="NavButton.Theme.SECONDARY"
      :class="$('button')"
      :text="__('cp:form:upload-input:add-button')"
      @click="onUpload"
    />
  </div>
</template>

<script>
  import gql from 'graphql-tag'
  import _get from 'lodash/get'

  import I18nMixin from '@/mixins/I18nMixin'
  import ModalMixin from '@/mixins/ModalMixin'

  import AppLink from '@/core/controls/AppLink/AppLink'
  import FreelanceDocumentUploadModal from '@/modals/freelancer/FreelanceDocumentUploadModal/FreelanceDocumentUploadModal'
  import FileUploadModalLayout from '@/modals/layout/FileUploadModalLayout/FileUploadModalLayout'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import Txt from '@/core/text/Txt/Txt'

  export const UPLOAD_INPUT_FRAGMENT = gql`
    fragment UploadInput on File {
      id
      name
      url
    }
  `

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  NavButton: NavButton.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ MIME_TYPES:FileUploadModalLayout.MIME_TYPES,
    name: 'UploadInput',
    __enums: {
      MIME_TYPES: FileUploadModalLayout.MIME_TYPES,
    },
    components: {
      AppLink,
      NavButton,
      Txt,
    },
    mixins: [I18nMixin, ModalMixin],
    props: {
      /**
       * File object
       */
      value: {
        type: [File, Object],
        default: null,
      },
      /**
       * File type
       */
      type: {
        type: String,
        default: null,
      },
      /**
       * Label to display instead of the file name
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Editable file
       */
      editable: {
        type: Boolean,
        default: true,
      },
      /**
       * List of valid mime types
       */
      acceptedMimeTypes: {
        type: Object,
        default: () => FileUploadModalLayout.MIME_TYPES,
      },
    },
    methods: {
      _get,
      /**
       * Delete selected file
       * @returns {void}
       */
      onDelete() {
        this.$emit('input', null)
      },
      /**
       * Upload new file
       * @returns {void}
       */
      async onUpload() {
        const { acceptedMimeTypes, openModal, type } = this

        const params = {
          acceptedMimeTypes,
          document: {
            type,
          },
        }

        const [action, file] = await openModal(FreelanceDocumentUploadModal, params)

        if (action === 'submit') {
          this.$emit('input', file)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .upload-input {
    display: flex
    flex: 1
    align-items: center

    & ^[0]-button {
      display: flex
      flex: 0.01
      margin-left: auto
    }
  }
</style>
