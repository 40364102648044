import { __ } from '@comet/i18n'

import CorporateTypes from '@/types/corporate'

const { CorporateAcquisition, CorporateRole } = CorporateTypes

function formatCorporateAcquisition(key) {
  const trans = {
    [CorporateAcquisition.EVENT]: __('ty:corporate:acquisition:event'),
    [CorporateAcquisition.SOCIAL_NETWORK]: __('ty:corporate:acquisition:social'),
    [CorporateAcquisition.INTERNET_SEARCH]: __('ty:corporate:acquisition:internet'),
    [CorporateAcquisition.PRESS_ARTICLE]: __('ty:corporate:acquisition:press'),
    [CorporateAcquisition.SPONSORSHIP]: __('ty:corporate:acquisition:sponsorship'),
    [CorporateAcquisition.WORD_OF_MOUTH]: __('ty:corporate:acquisition:word'),
    [CorporateAcquisition.COMMERCIAL_MEETING]: __('ty:corporate:acquisition:commercial'),
    [CorporateAcquisition.PARTNERSHIP]: __('ty:corporate:acquisition:partnership'),
    [CorporateAcquisition.OTHER]: __('ty:corporate:acquisition:other'),
  }

  return trans[key] || undefined
}

function formatCorporateRole(key) {
  const trans = {
    [CorporateRole.ADMIN]: __('ty:corporate:role:admin'),
    [CorporateRole.MANAGER]: __('ty:corporate:role:manager'),
  }

  return trans[key] || undefined
}

export { formatCorporateAcquisition, formatCorporateRole }
