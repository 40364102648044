<template>
  <div
    :class="{
      [$style.root]: true,
      [$style.collapsed]: collapsed,
    }"
  >
    <div v-if="!collapsed" :class="$style.panel">
      <slot :collapse="toggleSidePanel" :active-index="activeIndex" />
    </div>

    <div v-else :class="$style.bar">
      <VBtn
        v-for="(button, i) in buttons"
        :key="button.id"
        :class="$style.button"
        @click="onClick(button, i)"
      >
        <VIcon
          small
          color="grey darken-3"
          v-text="button.icon"
        />
        <span v-text="button.label" />
      </VBtn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CSidePanel',
    props: {
      buttons: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        activeIndex: 0,
      }
    },
    computed: {
      collapsed() {
        return this.$store.state.layout.backoffice.collapseSidePanel
      },
    },
    methods: {
      onClick(button, index) {
        this.toggleSidePanel()
        this.activeIndex = index
      },
      toggleSidePanel() {
        this.$store.dispatch('updateBackofficeLayout', {
          collapseSidePanel: !this.$store.state.layout.backoffice.collapseSidePanel,
        })
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_shadow.styl'

  .root {
    position: sticky
    top: 60px
    height: fit-content

    &.collapsed {
      margin-right: -20px
    }
  }

  .panel {
    min-width: 320px
    max-width: 320px

    :global(.v-tabs) {
      margin-bottom: 0 !important
      max-width: none !important
    }

    @media (max-width: 1300px) {
      position: fixed
      right: 0
      top: 60px
      shadow('dark', 'small')
    }
  }

  .bar {
    display: flex
    flex-direction: column
    height: fit-content

    > *:not(:first-child) {
      margin-top: 16px
    }

    .button {
      max-width: 32px
      min-width: unset !important
      padding: 8px 8px 8px 6px !important
      height: fit-content !important
      background: var(--color-grey-30) !important
      border-radius: 4px
      shadow('dark', 'small')

      :global(.v-btn__content) {
        display: flex
        flex-direction: column
        height: fit-content
        max-width: 32px

        span {
          display: block
          margin-top: 8px
          height: fit-content
          writing-mode: vertical-rl
          text-transform: none !important
        }
      }
    }
  }
</style>
