<template>
  <div :class="$style.task">
    <Icon name="check-circle" :class="$style.icon" />
    <Txt :value="label" :size="Txt.Size.XS" :inline="true" :class="$style.label" />
    <NavButton
      v-if="closable"
      :class="$style.closeButton"
      :size="NavButton.Size.SMALL"
      :theme="NavButton.Theme.SECONDARY_VOID"
      :disabled="disabled"
      :on-click="() => $emit('close')"
      icon="icon-remove"
    />
  </div>
</template>

<script>
  import Icon from '@/core/graphics/Icon/Icon'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import Txt from '@/core/text/Txt/Txt'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Icon: Icon.__enums,
  NavButton: NavButton.__enums,
  Txt: Txt.__enums,
},

    name: 'Task',
    components: {
      Icon,
      NavButton,
      Txt,
    },
    props: {
      /**
       * Label displayed in the task's body
       * @type {String}
       */
      label: {
        type: String,
        default: '',
      },
      /**
       * If 'true', a cross is displayed and trigger a
       * "close" event when clicked.
       */
      closable: {
        type: Boolean,
        default: false,
      },
      /**
       * Disable the task, if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="stylus" module>
  .task {
    flex: 1
    display: flex
    flex-direction: row
    align-items: flex-start
  }

  .icon {
    color: var(--color-task)
    margin: 2px 8px 0 0
  }

  .label {
    flex: 1
  }

  .closeButton >>> button {
    width: 24px
    height: 24px
  }
</style>
