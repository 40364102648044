<template>
  <VChip
    v-bind="$attrs"
    outlined
    color="primary"
    :class="$style.root"
    :small="dense || $attrs.small"
    v-on="_omit($listeners, ['input'])"
  >
    <span
      v-if="label"
      v-text="label"
    />
    <slot
      v-else
    />

    <VIcon
      dense
      :class="$style.icon"
      v-text="'mdi-plus-circle'"
    />
  </VChip>
</template>

<script>
  import _omit from 'lodash/omit'

  export default {
    name: 'CChipSuggest',
    props: {
      /**
       * Suggestion label that can be passed as a prop instead of in the main slot
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Reduces the chip's height
       */
      dense: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      _omit,
    },
  }
</script>

<style lang="stylus" module>
  .root {
    border-style: dashed !important
    border-color: var(--color-blue-20) !important
    background: var(--color-background) !important
    cursor: pointer !important
    pointer-events: all !important
    opacity: 1 !important

    &:hover {
      border-color: var(--color-blue-40) !important
    }

    .icon {
      position: relative
      right: -4px
    }
  }
</style>
