<template>
  <Component :is="tag" class="heading" :class="classes">
    <i v-if="leftIcon" :class="[leftIcon, 'left-icon']" />
    <span v-else-if="$slots.left" class="heading-left"> <slot name="left" /> </span>{{ text
    }}<slot />

    <i v-if="rightIcon" :class="[rightIcon, 'right-icon']" />

    <span v-else-if="$slots.right" class="heading-right">
      <slot name="right" />
    </span>
  </Component>
</template>

<script>
  const Theme = {
    DEFAULT: 'default',
    INFORMATION: 'information',
    INFORMATION_ALT: 'information-alt',
    SUCCESS: 'success',
    SUCCESS_ALT: 'success-alt',
    WARNING: 'warning',
    WARNING_ALT: 'warning-alt',
    DANGER: 'danger',
    DANGER_ALT: 'danger-alt',
    CONTRAST: 'contrast',
    YELLOW_ALT: 'yellow-alt',
  }

  const Level = {
    HERO: 'hero',
    LEVEL1: '1',
    LEVEL2: '2',
    LEVEL3: '3',
    LEVEL4: '4',
    LEVEL5: '5',
  }

  const Align = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
  }

  export const HeadingTheme = Theme
  export const HeadingLevel = Level

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Align,Level,Theme,
    name: 'Heading',
    __enums: {
      Align,
      Level,
      Theme,
    },
    props: {
      /**
       * Level of this heading in the title hierarchy. Equivalent to h1, h2,
       * h3, ...
       * Headings with different levels could be tied in a same "header"
       * @type {HeadingLevel}
       */
      level: {
        type: String,
        required: true,
      },
      /**
       * Content text of the heading
       */
      text: {
        type: [String, Number],
        default: null,
      },
      /**
       * Theme (see 'Theme' enum)
       */
      theme: {
        type: String,
        default: null,
      },
      /**
       * Display the heading in caps or not
       */
      capitalize: {
        type: Boolean,
        default: false,
      },
      /*
       * Icon to display on the left of the title
       */
      leftIcon: {
        type: String,
        default: null,
      },
      /**
       * Icon to display on right of the title
       */
      rightIcon: {
        type: String,
        default: null,
      },
      /**
       * Align the text according to this prop
       */
      align: {
        type: String,
        default: null,
      },
      /**
       * Display an ellipsis ('...')
       */
      ellipsis: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Tag used to display the heading (h1, h2, h3, ...)
       * @type {String}
       */
      tag() {
        const { level } = this

        return level === 'hero' ? 'h1' : `h${level}`
      },
      /**
       * CSS classes applied to the heading
       * @type {Object.<Boolean>}
       */
      classes() {
        const { align, capitalize, level, color, theme, ellipsis } = this

        return {
          [`heading--align-${align}`]: !!align,
          [`heading--level-${level}`]: true,
          [`heading--theme-${theme}`]: !!theme,
          [`heading--color-${color}`]: !!color && !theme,
          'heading--capitalize': capitalize,
          'heading--ellipsis': ellipsis,
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .heading {
    /* Reset */
    font-family: $font
    font-size: medium
    font-weight: normal
    text-transform: none
    line-height: normal
    letter-spacing: 0.5px
    color: var(--color-font)
    padding: 0
    margin: 0
    opacity: 1
    min-height: min-content

    &.heading--align-left {
      text-align: left
    }

    &.heading--align-center {
      text-align: center
    }

    &.heading--align-right {
      text-align: right
    }

    &&--level {
      &-hero {
        font-size: 64px
        line-height: 80px
        letter-spacing: 0.5px
        font-weight: $font-extra-bold
      }

      &-1 {
        font-size: 48px
        line-height: 60px
        letter-spacing: 0.5px
        font-weight: $font-extra-bold

        @media phone {
          font-size: 40px
          line-height: 50px
        }
      }

      &-2 {
        font-size: 40px
        line-height: 50px
        letter-spacing: 0.5px
        font-weight: $font-extra-bold

        @media phone {
          font-size: 32px
          line-height: 40px
          font-weight: $font-bold
        }
      }

      &-3 {
        font-size: 32px
        line-height: 40px
        letter-spacing: 0.5px
        font-weight: $font-bold

        @media phone {
          font-size: 24px
          line-height: 30px
        }
      }

      &-4 {
        font-size: 24px
        line-height: 30px
        letter-spacing: 0.5px
        font-weight: $font-bold

        @media phone {
          font-size: 20px
          line-height: 26px
          font-weight: $font-semi-bold
        }
      }

      &-5 {
        font-size: 20px
        line-height: 26px
        letter-spacing: 0.5px
        font-weight: $font-semi-bold

        @media phone {
          font-size: 16px
          line-height: 24px
        }
      }
    }

    &&--theme-blue {
      color: var(--color-brand)
    }

    &&--theme-grey {
      color: var(--color-font-light)
    }

    &&--theme-white {
      color: var(--color-font-contrast)
    }

    &&--theme-green {
      color: var(--color-positive)
    }

    &&--theme-yellow {
      color: var(--color-contrast)
    }

    &&--theme-yellow-alt {
      color: var(--color-contrast-opaque)
    }

    &&--theme-dual {
      color: var(--color-font)
    }

    &&--theme-black,
    &&--theme-primary,
    &&--theme-default {
      color: var(--color-font)
    }

    &&--theme-information,
    &&--theme-information-alt {
      color: var(--color-brand)
    }

    &&--theme-success,
    &&--theme-success-alt {
      color: var(--color-positive)
    }

    &&--theme-warning,
    &&--theme-warning-alt {
      color: var(--color-warning)
    }

    &&--theme-danger,
    &&--theme-danger-alt {
      color: var(--color-negative)
    }

    &&--theme-contrast {
      color: var(--color-font-contrast)
    }

    &&--capitalize {
      text-transform: uppercase
    }

    &&--ellipsis {
      ellipsis()
    }

    &-left {
      margin-right: 8px

      &:empty {
        display: none
      }

      i {
        position: relative
        top: 1px
      }
    }

    &-right {
      margin-left: 8px

      &:empty {
        display: none
      }
    }

    .right-icon {
      margin-left: 8px
    }

    .left-icon {
      margin-right: 8px
    }
  }
</style>
