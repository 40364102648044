<template>
  <div :class="$style.root">
    <VMenu
      ref="menu1"
      v-model="expanded"
      open-on-click
      :open-on-hover="false"
      close-on-click
      :close-on-content-click="false"
      max-width="290px"
      min-width="290px"
      offset-y
      transition="scale-transition"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on }">
        <VTextField
          v-bind="$attrs"
          :value="text"
          :disabled="disabled"
          :class="$style.textfield"
          readonly
          v-on="on"
          @input="onTextFieldInput"
          @change="onTextFieldChange"
          @focus="$emit('focus', $event)"
          @keyup="onTextFieldKeyUp"
          @click:clear="$emit('input')"
        >
          <template v-slot:label>
            <slot name="label" />
          </template>

          <template v-slot:prepend-inner>
            <slot name="prepend-inner" />
          </template>
        </VTextField>
      </template>

      <VDatePicker
        :value="formattedValue"
        :type="month ? 'month' : 'date'"
        :color="color"
        :min="min"
        :max="max"
        :allowed-dates="allowedDates"
        :first-day-of-week="1"
        no-title
        show-week
        scrollable
        :disabled="disabled"
        @change="onDatePickerChange"
      />
    </VMenu>
  </div>
</template>

<script>
  import moment from 'moment-timezone'

  export default {
    name: 'CFieldDate',
    inheritAttrs: false,
    props: {
      /**
       * Current DatePicker's value as an ISO string (with time included)
       * @type {String}
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Disable the whole component (DatePicker, TextField, Menu, ...)
       * @type {Boolean}
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Custom color of the DatePicker elements
       * @type {String}
       */
      color: {
        type: String,
        default: 'primary',
      },
      /**
       * Built-in DatePicker's "min" property as an ISO date
       * @type {String}
       */
      min: {
        type: String,
        default: null,
      },
      /**
       * Built-in DatePicker's "max" property as an ISO date
       * @type {String}
       */
      max: {
        type: String,
        default: null,
      },
      /**
       * Built-in DatePicker's "alloowed-dates" function
       * @type {String}
       */
      allowedDates: {
        type: Function,
        default: null,
      },
      /**
       * Custom function allowing to format how the date should be displayed
       * in the TextField
       * @type {Function}
       */
      formatText: {
        type: Function,
        default: null,
      },
      /**
       * Custom function allowing to parse the content of the TextField (useful when
       * editing the DateField textually)
       * @type {Function}
       */
      parseText: {
        type: Function,
        default: null,
      },
      /**
       * True if the picker should be a month picker
       */
      month: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        /**
         * Boolean describing if the DatePicker must be visible or not, according to the
         * user actions
         * @type {Boolean}
         */
        expanded: false,
        /**
         * Formatted date as displayed in the text field.
         * This property is reactive in order to make the text field editable.
         * @type {String}
         */
        text: null,
      }
    },
    computed: {
      /**
       * Current value formatted as an ISO date (instead of an ISO datetime) as expected
       * by the DatePicker component
       * @type {String}
       */
      formattedValue() {
        const { value } = this
        const { format } = this.$i18n

        return format('date', value, { to: 'isodate' })
      },
      /**
       * Formatting function used to display the text field's value.
       * Is the result of the given formatting function as prop,  or the default one.
       * @type {Function}
       */
      customFormatText() {
        const { formatText } = this
        const { format } = this.$i18n

        return formatText || (v => format('date', v, { to: 'isodate' }))
      },
      /**
       * Parsing function used to grab the text field's value when straight altered by the
       * user himself.
       * Is the result of the given parsing function as prop, or the default one.
       * @type {Function}
       */
      customParseText() {
        const { parseText } = this
        const { format } = this.$i18n

        return parseText || (v => format('date', v, { from: 'isodate' }))
      },
    },
    watch: {
      value: {
        immediate: true,
        handler(next) {
          this.text = this.customFormatText(next)
        },
      },
    },
    methods: {
      /**
       * Exposed method used to manually open the DatePicker
       */
      open() {
        this.expanded = true
      },
      /**
       * Fired when the DatePicker's value actually changes (i.e. when the user "validates"
       * the input, not reactive).
       * Update the text field with the right text and emit a relevant event.
       * @param {String} value
       */
      onDatePickerChange(value) {
        const { format } = this.$i18n
        const isoValue = format('date', value, 'isodate')

        this.expanded = false
        this.$emit('input', isoValue)
      },
      /**
       * Fired upon a new value input. (i.e. when the date is edited straight in the text input).
       * Emit a relevant event, with the new parsed value, that will lead to a real value
       * update then.
       */
      onTextFieldInput(value) {
        if (moment(value).isValid()) {
          this.$emit('input', this.customParseText(value))
        }
      },
      /**
       * Fired when the TextField's value is changed. Close the date-picker
       */
      onTextFieldChange(value) {
        this.expanded = false
        if (moment(value).isValid()) {
          this.$emit('input', this.customParseText(value))
        }
      },
      /**
       * Capture up/down keys to add or subtract days
       */
      onTextFieldKeyUp(event) {
        const { value, customFormatText } = this
        const unit = event.altKey ? 'month' : event.shiftKey ? 'year' : 'day'

        if (!value) {
          return
        }

        // Key up
        if (event.keyCode === 38) {
          this.$emit('input', customFormatText(moment(value).add(1, unit)))
        }

        // Key down
        if (event.keyCode === 40) {
          this.$emit('input', customFormatText(moment(value).subtract(1, unit)))
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  :global(#app) {
    .root {
      .textfield:global(.v-input.v-input--is-disabled:not(.v-input--is-readonly)) {
        pointer-events: auto

        .v-text-field__slot {
          pointer-events: none
        }
      }

      .textfield:global(.v-input.v-input--is-readonly:not(.v-input--is-disabled)) {
        :global(.v-input__slot) {
          background: var(--color-white) !important
        }
      }
    }
  }
</style>
