<template>
  <Icon
    :theme="status ? Icon.Theme.POSITIVE : Icon.Theme.NEGATIVE"
    :name="status ? 'check' : 'cross'"
  />
</template>

<script>
  import _omit from 'lodash/omit'

  import Icon from './Icon'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Icon: Icon.__enums,
},

    name: 'StatusIcon',
    components: { Icon },
    props: {
      /**
       * Status of the Icon, if true display a success icon with positive theme
       * If false display error icon with theme negative
       */
      status: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      _omit,
    },
  }
</script>
