var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VDialog',_vm._b({attrs:{"max-width":500,"scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,{"on":Object.assign({}, on,
          {click: function (event) {
            if (event) {
              event.preventDefault()
              event.stopImmediatePropagation()
            }
            _vm.isModalOpen = true
            _vm.$emit('click')
          }})})]}}],null,true),model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},'VDialog',_vm.$attrs,false),[_c('VCard',{staticClass:"pa-2"},[_c('VCardTitle',{staticClass:"headline",domProps:{"innerHTML":_vm._s(_vm.__('cp:freelancer-optim-need-modal:title'))}}),_c('VCardText',[_c('form',{staticClass:"freelancer-header-optim-needs-message"},[_c('VTextarea',{attrs:{"dense":"","auto-grow":"","hide-details":""},model:{value:(_vm.form.fields.message),callback:function ($$v) {_vm.$set(_vm.form.fields, "message", $$v)},expression:"form.fields.message"}})],1)]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{staticClass:"mx-2",attrs:{"text":"","small":"","color":"secondary"},domProps:{"textContent":_vm._s(_vm.__('cp:freelancer-optim-need-modal:actions:cancel'))},on:{"click":function($event){$event.preventDefault();return (function () { return (_vm.isModalOpen = false); })($event)}}}),_c('VBtn',{staticClass:"mx-2",attrs:{"text":"","small":"","color":'primary'},domProps:{"textContent":_vm._s(_vm.__('cp:freelancer-optim-need-modal:actions:share'))},on:{"click":_vm.onSharePress}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }