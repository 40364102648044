/* eslint-disable import/prefer-default-export */

/**
 * Ensures that the value given to the prop is included in an enum
 * @param {*} Enum
 * @returns {Boolean}
 */
function enumValidator(Enum) {
  return value => {
    const valid = Object.values(Enum).includes(value)

    if (!valid) {
      console.warn(`enum - should be one of "${Object.keys(Enum).join('", "')}`)
    }

    return valid
  }
}

export { enumValidator }
