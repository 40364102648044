<template>
  <Component
    :is="!!onClick ? 'button' : 'div'"
    :class="classes"
    @click="!!onClick ? onClick : $emit('click')"
  >
    <Icon
      v-if="icon"
      :name="icon"
      v-bind="$attrs"
      :class="{ [$style.labeledIcon]: !!name || $slots.default || onDelete }"
    />

    <span v-if="name" :class="$style.name">{{ name }}</span>

    <slot />

    <button v-if="onDelete" type="button" :class="$style.deleteButton" @click.prevent="onDelete">
      <Icon name="cross-circle" :class="$style.deleteIcon" />
    </button>
  </Component>
</template>

<script>
  import _camelCase from 'lodash/camelCase'

  import Icon from '@/core/graphics/Icon/Icon'

  const Size = {
    XS: 'xs',
    S: 's',
    M: 'm',
    L: 'l',
  }

  const Theme = {
    // Should try to avoid using colors but still there to handle legacy code
    BLUE: 'blue',
    BLUE_LIGHT: 'blue-light',
    BLUE_DARK: 'blue-dark',
    BLUE_GREY: 'blue-grey',
    RED: 'red',
    GREEN: 'green',
    ORANGE: 'orange',
    GREY: 'grey',

    PRIMARY_ALT: 'primary-alt',
    SECONDARY: 'secondary',
    SECONDARY_ALT: 'secondary-alt',
    NEGATIVE: 'negative',
    NEGATIVE_ALT: 'negative-alt',
    WARNING: 'warning',
    WARNING_ALT: 'warning-alt',
    POSITIVE: 'positive',
    DARK: 'dark',
    DARK_ALT: 'dark-alt',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Icon: Icon.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,Theme,
    name: 'Tag',
    __enums: {
      Size,
      Theme,
    },
    components: {
      Icon,
    },
    props: {
      /**
       * Label to display in the tag
       */
      name: {
        type: [String, Number],
        default: null,
      },
      /**
       * Size of the tag, defined in the enum Size
       */
      size: {
        type: String,
        default: Size.S,
      },
      /**
       * Color theme applied to the tag
       * Can apply different color but also different style like outline
       */
      theme: {
        type: String,
        default: Theme.BLUE,
      },
      /**
       * Icon to display on the left of the component
       */
      icon: {
        type: String,
        default: null,
      },
      /**
       * Function called when the user click on cross on the right of the
       * component. The cross is displayed only if the function is defined
       */
      onDelete: {
        type: Function,
        default: null,
      },
      /**
       * Function called when the user click on the tag.
       * If a function is given, then the root of the component is a "button"
       */
      onClick: {
        type: Function,
        default: null,
      },
      /**
       * Disable the component which means he doesn't send event anymore and
       * it add opacity
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {
        const { size, theme, disabled, $style } = this

        return {
          [$style.tag]: true,
          [$style.disabled]: disabled,
          [$style[_camelCase(`size-${size}`)]]: true,
          [$style[_camelCase(`theme-${theme}`)]]: true,
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  .tag {
    max-width: 100%
    box-sizing: border-box
    display: flex
    padding: 0 16px
    border-radius: 32px
    position: relative
  }

  .name {
    display: flex
    align-items: center
    letter-spacing: 0.5px
    margin-right: auto
  }

  .labeledIcon {
    margin-right: 8px
  }

  .deleteButton {
    padding: 0
    background: none
    border: none
    display: flex
  }

  /*****************************************/
  /*     CSS classes to handle the size    */
  /*****************************************/

  .sizeXs {
    height: 20px

    & .name {
      height: 20px
      font-size: 12px
      line-height: 20px
      border-radius: 12px
    }

    & .icon,
    & .deleteIcon {
      line-height: 16px
      margin-top: 1px
      font-size: 12px
    }
  }

  .sizeS {
    height: 24px

    & .name {
      height: 24px
      font-size: 12px
      line-height: 24px
      border-radius: 12px
    }

    & .icon,
    & .deleteIcon {
      line-height: 16px
      margin-top: 1px
      font-size: 12px
    }
  }

  .sizeM {
    height: 32px

    // Add those padding on the root in addition to the left / right
    // padding of 16px
    padding-top: 7px
    padding-bottom: 7px

    & .name {
      height: 18px
      font-size: 14px
      line-height: 18px
    }

    & .icon,
    & .deleteIcon {
      line-height: 16px
      margin-top: 1px
      font-size: 12px
    }
  }

  .sizeL {
    height: 40px
    border-radius: 20px

    // Add those padding on the root in addition to the left / right
    // padding of 16px
    padding-top: 7px
    padding-bottom: 7px

    & .name {
      font-size: 16px
      line-height: 26px
    }

    & .icon,
    & .deleteIcon {
      line-height: 16px
      margin-top: 1px
      font-size: 12px
    }
  }

  /*****************************************/
  /*     CSS classes to handle the theme   */
  /*****************************************/

  .themeBlue {
    background-color: var(--color-brand)
    color: var(--color-font-contrast)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-font-contrast)
    }
  }

  .themeBlueDark {
    background-color: var(--color-brand-opaque)
    color: var(--color-font-contrast)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-font-contrast)
    }
  }

  .themeBlueLight {
    background-color: var(--color-blue-20)
    color: var(--color-font)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-font)
    }
  }

  .themeOrange,
  .themeWarning {
    background-color: var(--color-warning)
    color: var(--color-font-contrast)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-font-contrast)
    }
  }

  .themeWarningAlt {
    background-color: var(--color-background)
    color: var(--color-warning)
    border: 1px solid var(--color-warning)

    & .icon,
    & .deleteIcon {
      color: var(--color-warning)
    }
  }

  .themeGrey,
  .themeSecondary {
    background-color: var(--color-button-secondary)
    color: var(--color-font)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-font)
    }
  }

  .themeBlueGrey {
    background-color: var(--color-button-secondary)
    color: var(--color-brand)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-brand)
    }
  }

  .themeRed,
  .themeNegative {
    background-color: var(--color-negative)
    color: var(--color-font-contrast)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-font-contrast)
    }
  }

  .themeNegativeAlt {
    background-color: var(--color-background)
    color: var(--color-negative)
    border: 1px solid var(--color-negative)

    & .icon,
    & .deleteIcon {
      color: var(--color-negative)
    }
  }

  .themeGreen,
  .themePositive {
    background-color: var(--color-positive)
    color: var(--color-font-contrast)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-font-contrast)
    }
  }

  .themePrimaryAlt {
    background-color: var(--color-background)
    color: var(--color-brand)
    border: 1px solid var(--color-brand)

    & .icon,
    & .deleteIcon {
      color: var(--color-brand)
    }
  }

  .themeSecondaryAlt {
    background-color: transparent
    border: 1px solid var(--color-skill-secondary)
    color: var(--color-grey-60)

    & .icon,
    & .deleteIcon {
      color: var(--color-font)
    }
  }

  .themeDark {
    background-color: var(--color-black)
    color: var(--color-font-contrast)
    border: none

    & .icon,
    & .deleteIcon {
      color: var(--color-font-contrast)
    }
  }

  .themeDarkAlt {
    background-color: transparent
    border: 1px solid var(--color-font)
    color: var(--color-font)

    & .icon,
    & .deleteIcon {
      color: var(--color-font)
    }
  }

  /*****************************************/
  /*     CSS classes to handle the state   */
  /*****************************************/

  .disabled {
    opacity: .5

    & .name {
      font-style: italic
    }
  }
</style>
