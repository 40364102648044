import _get from 'lodash/get'

/**
 * Returns a validator for every form.fields,
 * even if no validator has been declared.
 * By default, an empty validator will be provided.
 * @returns {Object} validators
 */
function getValidators() {
  const fields = _get(this, 'form.fields', {})
  const formValidators = _get(this, 'form.validators', {})

  return Object.keys(fields).reduce(
    (validators, fieldKey) => ({
      ...validators,
      [fieldKey]: formValidators[fieldKey] || {},
    }),
    {},
  )
}

export default getValidators
