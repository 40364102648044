import _invoke from 'lodash/invoke'
import * as Sentry from '@sentry/browser'
import { v4 as uuid } from 'uuid'

/**
 * Apollo middleware that appends the FullStory session URL (when available)
 * Also add a transactionid for Sentry (used to link to backend issues)
 * to every request header so that the back-end can trace back errors
 * @param {Object} operation
 * @param {Function} forward
 */
function tracking(operation, forward) {
  const { headers } = operation.getContext()
  const fsSessionUrl = _invoke(window, 'FS.getCurrentSessionURL')

  // generate unique transactionId and set as Sentry tag
  const transactionId = uuid()
  Sentry.configureScope(scope => {
    scope.setTag('transaction_id', transactionId)
  })

  operation.setContext({
    headers: {
      ...headers,
      ...(fsSessionUrl && {
        'fs-session-url': fsSessionUrl,
      }),
      'X-Transaction-ID': transactionId,
    },
  })

  return forward(operation)
}

export default tracking
