<template>
  <div :class="$style.moreActions">
    <NavButton :class="$style.dots" :theme="NavButton.Theme.SECONDARY_VOID" icon="dot-menu" />
    <div :class="$style.actions">
      <NavButton
        v-for="(action, i) in actions"
        :key="i"
        v-tooltip="$mq.phone ? action.label : null"
        :size="NavButton.Size.SMALL"
        :text="$mq.phone ? null : action.label"
        :theme="NavButton.Theme.SECONDARY_VOID"
        :left-icon="$mq.phone ? null : action.icon"
        :icon="$mq.phone ? action.icon : null"
        @click="action.onClick"
      />
    </div>
  </div>
</template>

<script>
  import NavButton from '@/core/controls/NavButton/NavButton'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
},

    name: 'MoreActions',
    components: {
      NavButton,
    },
    props: {
      actions: {
        type: Array,
        default: null,
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_colors.styl'
  @import '~@/assets/css/_layout.styl'

  @media tablet-up {
    .moreActions {
      z-index: 1
    }

    .actions {
      transition: .2s ease-out
      transition-properties: transform, opacity
      position: absolute
      top: 100%
      right: 0
      opacity: 0
      display: flex
      flex-direction: column
      align-items: flex-start
      background: var(--color-background)
      z-index: 100
      transform-origin: top right
      transform: scale(0)
      border-radius: 3px
      border: 1px solid var(--color-border)

      // Actions' triangle
      &::after,
      &::before {
        content: ""
        position: absolute
        width: 0
        height: 0
        top: -5px
        right: 15px
        border-style: solid
        border-color: transparent
      }

      &::after {
        border-width: 0 4px 6px 4px
        border-bottom-color: var(--color-background)
      }

      &::before {
        border-width: 0 5px 7px 5px
        border-bottom-color: var(--color-border)
        margin-right: -1px
        margin-top: -2px
      }
    }

    .moreActions:not(:hover) .actions {
      transition-delay: 1s
    }

    .moreActions:hover .actions {
      opacity: 1
      transform: scale(1)
    }
  }

  @media phone {
    .moreActions {
      order: -1
      display: flex
      border: 1px solid var(--color-border)
      border-radius: 6px
      margin-bottom: 24px
      align-self: flex-end
      background: var(--color-background)
    }

    .dots {
      display: none
    }
  }
</style>
