<template>
  <Transition name="modal" :appear="true">
    <dialog :class="['modal', classes]" :open="true">
      <div :class="$('layout')" @click.self="onOverlayPress">
        <div :class="$('wrapper')">
          <!-- close button -->
          <NavButton
            v-if="closable"
            theme="secondary-void"
            :class="$('close-button')"
            icon="icon-cross"
            @click="onClose"
          />

          <Box :padding="boxPadding" :class="$('container')">
            <WithLoading :loading="loading" :class="$('loading')">
              <!-- head -->
              <div
                v-if="
                  !blank &&
                    !loading &&
                    (title || $slots.sup || $slots.title || $slots.sub || $slots.head)
                "
                :class="$('head')"
              >
                <Headings
                  v-if="!$slots.head"
                  :level="level"
                  :title="title"
                  :sub="subtitle"
                  :ellipsis="ellipsis"
                >
                  <slot v-if="$slots.sup" slot="sup" name="sup" />
                  <slot v-if="$slots.title" slot="title" name="title" />
                  <slot v-if="$slots.sub" slot="sub" name="sub" />
                </Headings>

                <slot name="head" />
              </div>

              <!-- body -->
              <div v-if="!loading" :class="$('content')">
                <slot />
              </div>

              <!-- actions -->
              <div v-if="!blank && !loading && $slots.actions" :class="$('actions')">
                <slot name="actions" />
              </div>
            </WithLoading>
          </Box>
        </div>
      </div>
    </dialog>
  </Transition>
</template>

<script>
  import Box from '@/core/layout/Box/Box'
  import Headings from '@/core/text/Headings/Headings'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import WithLoading from '@/core/layout/WithLoading/WithLoading'

  import ModalMixin from '@/mixins/ModalMixin'

  const Level = { ...Headings.Level }

  const Size = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    X_LARGE: 'x-large',
    FIT_CONTENT: 'fit-content',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Box: Box.__enums,
  Headings: Headings.__enums,
  NavButton: NavButton.__enums,
  WithLoading: WithLoading.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Level,Size,
    name: 'Modal',
    __enums: {
      Level,
      Size,
    },
    components: {
      Box,
      Headings,
      NavButton,
      WithLoading,
    },
    mixins: [ModalMixin],
    props: {
      /**
       * Text displayed as the modal's title
       */
      title: {
        type: String,
        default: null,
      },
      /**
       * Text displayed as the modal's subtitle
       */
      subtitle: {
        type: String,
        default: null,
      },
      /**
       * Headings level
       */
      level: {
        type: String,
        default: Level.LEVEL3,
      },
      /**
       * If 'true', the titles end with an ellipsis if it can't be
       * displayed entirely
       */
      ellipsis: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the modal is not dissmissable by clicking the overlay. It
       * requires an action to be chosen.
       */
      dismissable: {
        type: Boolean,
        default: true,
      },
      /**
       * If 'true', it displays a clickable arrow in the top right corner
       */
      closable: {
        type: Boolean,
        default: true,
      },
      /**
       * If 'true', the content will be scrollable, the overflow not visible
       */
      scrollable: {
        type: Boolean,
        default: false,
      },
      /**
       * Enum value defining the width of the modal.
       * One of : "small", "medium", "large", "x-large", "fit-content"
       */
      size: {
        type: String,
        default: Size.FIT_CONTENT,
      },
      /**
       * If 'true', the modal is displayed without content and without padding.
       */
      blank: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', the view is considered as loading and displays a
       * spinner instead of its content
       */
      loading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied on the root element
       * @type {Object}
       */
      classes() {
        const { $, blank, size, scrollable } = this

        return {
          [$(`-${size}`)]: !!size,
          [$('-blank')]: !!blank,
          [$('-scrollable')]: !!scrollable,
        }
      },
      /**
       * Compute the Box padding according to the Modal's own "size" property
       * @type {String}
       */
      boxPadding() {
        const { size } = this

        return [Size.SMALL, Size.MEDIUM].includes(size) ? Box.Padding.M : Box.Padding.L
      },
    },
    methods: {
      /**
       * Called when the modal overlay is clicked. This must acts only if
       * the modal is a "dismissable" modal.
       * @param {Event} e
       * @returns {void}
       */
      onOverlayPress(e) {
        const { dismissable, onClose } = this

        if (dismissable) {
          onClose(e)
        }
      },
      /**
       * Called when a close instruction is sent
       * @param {Event} e
       * @returns {void}
       */
      onClose(e) {
        const { dismissModal } = this

        // Ask the RouterMixin to dismiss the modal
        dismissModal()

        // Emit a 'close' event, if one day, someone needs it
        this.$emit('close', e)
      },
    },
  }
</script>

<style lang="stylus">
  @import '~@/assets/css/_grid.styl'
  @import '~@/assets/css/_shadow.styl'

  .modal {
    position: fixed
    z-index: 100
    top: 0
    left: 0
    right: 0
    bottom: 0
    min-width: 100%
    max-width: 100%
    min-height: 100%
    max-height: 100%
    background-color: var(--color-background-overlay)
    border: none
    display: flex
    transition: opacity .3s ease
    padding: 0

    & &-layout {
      display: flex
      flex: 1
      overflow: auto

      & ^[0]-wrapper {
        max-width: 100vw
        position: relative
        padding: 16px 0
        margin: auto
        box-sizing: border-box
        transition: all .3s ease

        ^[0]--scrollable& {
          max-height: 100vh
        }

        @media phone-tablet {
          padding: 8px
          margin: auto 0
        }

        & ^[0]-close-button {
          width: 32px
          height: 32px
          position: absolute
          top: 32px
          right: 16px
          z-index: 2
          grid-column: none
          background-color: var(--color-background)
          border: 1px solid var(--color-border)
          border-radius: $radius-circle
          shadow('dark' ,'small')

          @media phone-tablet {
            top: 16px
            right: 16px
          }

          & button {
            width: 32px
            height: 32px
            font-size: 24px
            line-height: 26px
            font-weight: normal
            padding: 0
          }
        }

        // This accurate selector is usefull for some scenarii of css override
        & ^[0]-container {
          width: 100%
          max-width: inherit
          position: relative
          text-align: left
          box-sizing: border-box
          overflow: visible
          transition: all .3s ease

          ^[0]--scrollable& {
            max-height: calc(100vh - 48px)
            overflow: hidden
          }

          & ^[0]-loading {
            min-height: 120px
          }

          & ^[0]-content {
            position: relative
            left: -16px
            width: calc(100% + 32px)
            // The min height correspond to the min height of a field
            padding: 8px 16px 0
            box-sizing: border-box

            &:not(:first-child) {
              margin: 16px 0 0
            }

            ^[0]--scrollable& {
              min-height: 48px
              max-height: calc(100vh - 288px)
              overflow: auto
            }

            ^[0]--blank& {
              left: 0
              width: 100%
            }
          }
        }
      }
    }

    // This less accurate selector is usefull for some scenarii of css override
    & &-container {
      & ^[0]-actions {
        // Next line should remain if we want the old modal to be changed easily into new modals
        text-align: right

        > *:not(:last-child) {
          margin-right: 12px
        }
      }

      .spinner {
        margin: auto
      }
    }

    &--blank {
      & ^[0]-container {
        & ^[0]-head,
        & ^[0]-actions {
          display: none
        }
      }
    }

    /**
     * Transitions
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     */
    &-enter {
      opacity: 0
    }

    &-leave-active {
      opacity: 0
    }

    &-enter &-wrapper,
    &-leave-active &-wrapper {
      -webkit-transform: scale(1.1)
      transform: scale(1.1)
    }

    &--small {
      & ^[0]-wrapper {
        width: 400px
      }
    }
    &--medium {
      & ^[0]-wrapper {
        width: 640px
      }
    }
    &--large {
      & ^[0]-wrapper {
        width: 900px
      }
    }
    &--x-large {
      & ^[0]-wrapper {
        width: 1080px
      }
    }

    &--small,
    &--medium,
    &--large,
    &--x-large {
      & ^[0]-wrapper {
        & ^[0]-container {
          > * {
            grid-column: 1 / -1
          }
        }
      }

      & ^[0]-actions {
        margin-top: 24px
        // Avoids to get modal & action footer margins at the same time
        .action-footer {
          padding-bottom: 0
        }
      }
    }
  }
</style>
