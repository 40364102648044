<template>
  <Component :is="component" class="box" :class="classes" v-bind="$attrs">
    <template v-if="$slots.section">
      <div v-for="(section, index) in $slots.section" :key="index" class="box-section">
        <!-- Display slot content -->
        <VNode :node="section" />
      </div>
    </template>

    <slot v-else />
  </Component>
</template>

<script>
  import AppLink from '@/core/controls/AppLink/AppLink'

  const Padding = {
    XXS: 'xxs',
    XS: 'xs',
    S: 's',
    M: 'm',
    L: 'l',
    XL: 'xl',
    NONE: 'none',
  }

  const Theme = {
    DUAL: 'dual',
    PRIMARY: 'primary',
    PRIMARY_ALT: 'primary-alt',
    PRIMARY_VOID: 'primary-void',
    INFORMATION: 'information',
    INFORMATION_ALT: 'information-alt',
    INFORMATION_VOID: 'information-void',
    INFORMATION_SOLID: 'information-solid',
    INFORMATION_TRANSPARENT: 'information-transparent',
    INFORMATION_LIGHT: 'information-light',
    SUCCES: 'success',
    SUCCESS_ALT: 'success-alt',
    SUCCESS_VOID: 'success-void',
    WARNING: 'warning',
    WARNING_ALT: 'warning-alt',
    WARNING_VOID: 'warning-void',
    WARNING_SOLID: 'warning-solid',
    DANGER: 'danger',
    DANGER_ALT: 'danger-alt',
    DANGER_VOID: 'danger-void',
    NEUTRAL: 'neutral',
    GRID: 'grid',
  }

  const HoverTheme = {
    ...Theme,
  }

  const Border = {
    NONE: 'none',
    SOLID: 'solid',
    DASHED: 'dashed',
  }

  const Shadow = {
    LIGHT: 'light',
    DARK: 'dark',
    NONE: 'none',
  }

  const Radius = {
    MEDIUM: 'medium',
    SMALL: 'small',
    NONE: 'none',
  }

  export const BoxPadding = Padding
  export const BoxTheme = Theme
  export const BoxBorder = Border

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Border,Padding,Theme,Shadow,Radius,HoverTheme,
    name: 'Box',
    __enums: {
      Border,
      Padding,
      Theme,
      Shadow,
      Radius,
      HoverTheme,
    },
    components: {
      AppLink,
    },
    inheritAttrs: false,
    props: {
      /**
       * Padding to put on the box can be: [xs, s, m, l, xl, none]
       */
      padding: {
        type: String,
        default: Padding.S,
      },
      /**
       * Theme to define the colors used in the box, can be:
       * dual", "primary", "primary-alt", "information", "information-alt"
       * "information-solid", "success", "success-alt", "warning",
       * "warning-alt", "danger", "danger-alt"
       */
      theme: {
        type: String,
        default: Theme.PRIMARY,
      },
      /**
       * Type of border on the box, can be [none, solid, dashed]
       */
      border: {
        type: String,
        default: Border.NONE,
      },
      /**
       * Tag that will be used at the root of the component.
       */
      tag: {
        type: String,
        default: 'div',
      },
      /**
       * If the box is clickable an Applink is put at the root
       */
      clickable: {
        type: Boolean,
        default: false,
      },
      /**
       * Theme to apply to the border of the box on hover (if clickable)
       */
      hoverTheme: {
        type: String,
        default: null,
      },
      /**
       * Shadow to apply on box
       */
      shadow: {
        type: String,
        default: Shadow.LIGHT,
      },
      /**
       * Radius to apply on box
       */
      radius: {
        type: String,
        default: Radius.MEDIUM,
      },
    },
    computed: {
      /**
       * Binding function of functional component also used to declare CSS
       * classes to apply on the root element
       * @param {Object} context
       * @type {Object}
       */
      classes() {
        const { padding, theme, border, hoverTheme, clickable, shadow, radius, $slots } = this

        return {
          [`box--padding-${padding}`]: true,
          'box--with-sections': !!$slots.section,
          [`box--theme-${theme}`]: true,
          [`box--border-${border}`]: true,
          [`box--shadow-${shadow}`]: true,
          [`box--radius-${radius}`]: true,
          [`box--hover-theme-${hoverTheme}`]: clickable,
        }
      },
      /**
       * Component to display at the root of the component
       * @type {VueComponent}
       */
      component() {
        const { clickable, tag } = this

        return clickable ? AppLink : tag
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_shadow.styl'
  @import '~@/assets/css/_variables.styl'

  $padding-xxs = 10px 16px
  $padding-xs = 16px 16px 16px 16px
  $padding-s = 20px 24px 24px 24px
  $padding-m = 24px 32px 32px 32px
  $padding-l = 40px 48px 48px 48px
  $padding-xl = 48px 56px 56px 56px

  .box {
    margin: 0 // TODO - Until we remove the .box class in _layout
    padding: 0
    box-sizing: content-box

    &-section:not(:last-child) {
      border-bottom: solid 1px var(--color-border)
    }

    &--radius {
      &-medium {
        border-radius: $radius-medium
      }
      &-small {
        border-radius: $radius-small
      }
    }

    /**
     * Box padding displayed without sections
     */
    &:not(&--with-sections) {
      &^[0]--padding-xxs {
        padding: $padding-xxs
        max-width: calc(100% - 28px)
        max-height: calc(100% - 28px)
      }
      &^[0]--padding-xs {
        padding: $padding-xs
        max-width: calc(100% - 32px)
        max-height: calc(100% - 32px)
      }
      &^[0]--padding-s,
      &^[0]--padding-m {
        padding: $padding-s
        max-width: calc(100% - 48px)
        max-height: calc(100% - 44px)
      }
      &^[0]--padding-l {
        padding: $padding-m
        max-width: calc(100% - 64px)
        max-height: calc(100% - 56px)
      }
      &^[0]--padding-xl {
        padding: $padding-l
        max-width: calc(100% - 96px)
        max-height: calc(100% - 88px)
      }

      &-none {
        padding: 0px
      }
    }

    @media tablet-up {
      &:not(&--with-sections) {
        &^[0]--padding-s {
          padding: $padding-s
          max-width: calc(100% - 48px)
          max-height: calc(100% - 44px)
        }
        &^[0]--padding-m {
          padding: $padding-m
          max-width: calc(100% - 64px)
          max-height: calc(100% - 56px)
        }
        &^[0]--padding-l {
          padding: $padding-l
          max-width: calc(100% - 96px)
          max-height: calc(100% - 88px)
        }
        &^[0]--padding-xl {
          padding: $padding-xl
          max-width: calc(100% - 112px)
          max-height: calc(100% - 104px)
        }
      }
    }

    /**
     * Box section's padding
     */
    &--with-sections {
      &^[0]--padding-xxs {
        padding: $padding-xxs
        max-width: calc(100% - 30px)
      }
      &^[0]--padding-xs {
        & ^[0]-section {
          padding: $padding-xs
          max-width: calc(100% - 36px)
        }
      }

      &^[0]--padding-s,
      &^[0]--padding-m {
        & ^[0]-section {
          padding: $padding-s
          max-width: calc(100% - 48px)
        }
      }
      &^[0]--padding-l {
        & ^[0]-section {
          padding: $padding-m
          max-width: calc(100% - 64px)
        }
      }
      &^[0]--padding-xl {
        & ^[0]-section {
          padding: $padding-l
          max-width: calc(100% - 96px)
        }
      }
    }

    &--shadow {
      &-light {
        shadow('light', 'small')
      }

      &-dark {
        shadow('dark', 'medium')
      }
    }

    &--border {
      &-solid {
        border: solid 1px var(--color-border)

        ^[0]--theme {
          &-information,
          &-information-alt {
            border-color: var(--color-brand)
          }

          &-grid {
            border-color: var(--color-grey-30)
          }

          &-success,
          &-success-alt {
            border-color: var(--color-positive)
          }

          &-warning,
          &-warning-alt {
            border-color: var(--color-warning)
          }

          &-danger,
          &-danger-alt {
            border-color: var(--color-negative)
          }
        }
      }

      &-dashed {
        border: dashed 1px var(--color-border)
      }

      &-none {
        border: solid 1px transparent
      }
    }

    &--theme {
      &-default,
      &-primary,
      &-information
      &-success,
      &-warning,
      &-danger,
      &-neutral, {
        background-color: var(--color-background)
      }

      &-dual {
        background-color: var(--color-background-dual)
      }

      &-grid {
        background-color: var(--color-grey-20)
      }

      &-information-alt,
      &-information-void {
        background-color: var(--color-brand-transparent)
      }

      &-information-solid {
        background-color: var(--color-brand)
      }

      &-information-transparent {
        background-color: var(--color-brand-transparent)
      }

      &-information-light {
        background-color: var(--color-brand-highlight)
      }

      &-yellow-alt {
        background-color: var(--color-contrast-transparent)
      }

      &-success-alt,
      &-success-void {
        background-color: var(--color-positive-transparent)
      }

      &-warning-alt,
      &-warning-void {
        background-color: var(--color-warning-transparent)
      }

      &-warning-solid {
        background-color: var(--color-warning)
      }

      &-danger-alt,
      &-danger-void {
        background-color: var(--color-negative-transparent)
      }
    }

    &--hover-theme {
      &-default,
      &-primary,
      &-dual,
      &-information,
      &-information-alt {
        &:hover {
          border-color: var(--color-brand)
        }
      }

      &-success,
      &-success-alt {
        &:hover {
          border-color: var(--color-positive)
        }
      }

      &-warning,
      &-warning-alt {
        &:hover {
          border-color: var(--color-warning)
        }
      }

      &-danger,
      &-danger-alt {
        &:hover {
          border-color: var(--color-negative)
        }
      }

      &-neutral:hover {
        border-color: var(--color-idle)
        shadow('dark', 'medium')
      }
    }
  }
</style>
