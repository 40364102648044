<template>
  <Field
    class="checkboxes-field"
    :class="classes"
    :label="label"
    :sup-label="supLabel"
    :sup-type="supType"
    :error="error"
    :disabled="disabled"
    :helper="helperText"
  >
    <MultipleCheckboxes v-model="selection" :class="$('checkboxes')" :options="options" />
  </Field>
</template>

<script>
  import MultipleCheckboxes from '@/core/inputs/MultipleCheckboxes/MultipleCheckboxes'
  import Field from '@/core/layout/Field/Field'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  MultipleCheckboxes: MultipleCheckboxes.__enums,
  Field: Field.__enums,
},

    name: 'CheckboxesField',
    components: {
      MultipleCheckboxes,
      Field,
    },
    props: {
      /**
       * Field's value
       */
      value: {
        type: Array,
        default: () => [],
      },
      /**
       * All options
       * @type {Array.<{name: String, text: String, sub: String}>}
       */
      options: {
        type: Array,
        default: null,
      },
      /**
       * Text field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript
       */
      supLabel: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript color
       */
      supType: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Text field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Helper text to display under the textarea
       */
      helperText: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * Selected options
       */
      selection: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
      /**
       * CSS classes applied on the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        return {
          'checkboxes-field--invalid': !!this.error,
          'checkboxes-field--light': true,
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_grid.styl'

  .checkboxes-field {
    .multiple-checkboxes {
      content-grid()
      justify-self: stretch
      grid-row-gap: 12px

      & >>> * {
        content-grid-column(2, 4, 4)
      }
    }

    >>> .field-error {
      bottom: -25px
    }
  }
</style>
