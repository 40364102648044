<template>
  <div
    class="d-flex flex-column align-stretch"
    :class="{
      [$style.root]: true,
      [$style.dense]: dense || $attrs.dense,
    }"
  >
    <VAutocomplete
      v-if="!disabled && canChangeSelection"
      v-bind="$attrs"
      :value="value"
      :items="results"
      item-value="id"
      item-text="name"
      :search-input.sync="search"
      :disabled="disabled"
      :dense="dense"
      :no-data-text="
        search && search.length
          ? __('cp:freelancer-skills-field:autocomplete:no-data')
          : __('cp:freelancer-skills-field:autocomplete:no-search')
      "
      :placeholder="__('cp:freelancer-skills-field:autocomplete:placeholder')"
      :menu-props="{
        allowOverflow: true,
        bottom: true,
        closeOnClick: true,
        closeOnContentClick: false,
        disableKeys: true,
        maxHeight: 384,
        openOnClick: false,
      }"
      :error-messages="errorMessages"
      :hide-details="!errorMessages"
      prepend-inner-icon="mdi-magnify"
      return-object
      no-filter
      v-on="_omit($listeners, ['input'])"
      @input="onAutocompleteInput"
    >
      <template v-slot:item="{ item, on, attrs }">
        <VListItem v-if="item" v-bind="attrs" v-on="on">
          <VListItemContent style="max-width: 700px">
            <VListItemTitle>
              <strong>{{ item.name }}</strong>
              <span v-if="item.aliases && item.aliases.length > 0">
                • {{ item.aliases.join(', ') }}
              </span>
            </VListItemTitle>
            <VListItemSubtitle v-if="item.popularity != undefined">
              {{
                __('cp:fields:mission-form:mission-skill-field:popularity', {
                  popularity: item.popularity,
                })
              }}
            </VListItemSubtitle>
          </VListItemContent>
        </VListItem>
      </template>

      <template v-if="canAddSkill" v-slot:no-data>
        <VListItem v-if="search" :key="search" clickable @click="onAddSkills">
          <VListItemContent>
            <VListItemTitle>
              <strong>{{
                __('cp:fields:mission-form:mission-skill-field:add-skill', { skill: search })
              }}</strong>
            </VListItemTitle>
          </VListItemContent>
        </VListItem>
      </template>
    </VAutocomplete>

    <div v-if="maxCount" class="secondary--text ml-1" style="font-size: 10px">
      {{ value.length }} / {{ maxCount }}
    </div>

    <template v-if="!disabled && showSuggestions && showSuggestionAbove">
      <div class="d-flex flex-row flex-wrap mt-1" :class="$style.suggestions">
        <CChipSuggest
          v-for="item in suggestions"
          :key="item.id"
          :dense="dense"
          :label="item.name"
          @click="() => onSuggestionPress(item)"
        />
      </div>
    </template>

    <div class="d-flex flex-row flex-wrap align-center mt-1" :class="$style.skills">
      <template v-for="item in displayedSkills">
        <CChipTalent
          :key="item.id"
          :dense="dense"
          :label="item.name"
          :close="!disabled && canChangeSelection"
          :class="$style.chip"
          close-icon="mdi-close-circle"
          @click:close="() => onRemovePress(item)"
        >
          <template v-if="withPrimary" v-slot:primary>
            <div
              class="border px-2 ml-2 d-flex align-center"
              :class="$style.primaryButton"
              @click="() => onPrimaryInput(item)"
            >
              <VIcon
                small
                v-text="item.primary ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'"
              />
              <span
                class="caption"
                v-text="__('cp:freelancer-skills-field:autocomplete:primary')"
              />
            </div>
          </template>

          <template v-if="!withPrimary && withWished" v-slot:wished>
            <VTooltip right>
              <template v-slot:activator="{ on }">
                <VBtn
                  v-if="!disabled"
                  icon
                  small
                  class="color-current"
                  :class="$style.wished"
                  @click="() => onWishedPress(item)"
                  v-on="on"
                >
                  <VIcon small v-text="item.wishedInMissions ? 'mdi-heart' : 'mdi-heart-outline'" />
                </VBtn>
                <VIcon
                  v-else-if="item.wishedInMissions"
                  class="mx-1"
                  small
                  v-on="on"
                  v-text="'mdi-heart'"
                />
              </template>
              <span
                v-text="
                  item.wishedInMissions
                    ? __('cp:freelancer-skills-field:wished:on')
                    : __('cp:freelancer-skills-field:wished:off')
                "
              />
            </VTooltip>
          </template>

          <template v-if="!withPrimary" v-slot:duration>
            <CFieldSelect
              v-if="!disabled && canChangeSelection"
              mini
              solo
              color="secondary"
              :value="item.duration == null ? FreelancerSkillDuration[0] : item.duration"
              :options="FreelancerSkillDuration"
              :formatter="formatFreelancerSkillDuration"
              :menu-props="{ contentClass: $style.menu }"
              :disabled="disabled"
              :class="[$style.duration, { [$style.durationWithoutWished]: !withWished }]"
              @input="v => onDurationInput(v, item)"
              @click.native="e => e.stopImmediatePropagation()"
            />
            <span
              v-else-if="item.duration !== null"
              class="ml-1"
              v-text="
                `· ${formatFreelancerSkillDuration(item.duration) || FreelancerSkillDuration[0]}`
              "
            />
          </template>
        </CChipTalent>
      </template>

      <VBtn v-if="displayShowAllButtons" text color="secondary" small @click="showAll = true">
        <VIcon small v-text="'mdi-eye-plus-outline'" />
        <span class="ml-1" v-text="showMoreLabel" />
      </VBtn>

      <VBtn
        v-if="showAll"
        :class="$style.noPrint"
        text
        color="secondary"
        small
        @click="showAll = false"
      >
        <VIcon small v-text="'mdi-eye-minus-outline'" />
        <span class="ml-1" v-text="__('cp:freelancer-skills-field:show-less')" />
      </VBtn>
    </div>

    <template v-if="!disabled && showSuggestions && !showSuggestionAbove">
      <div class="d-flex flex-row flex-wrap mt-1" :class="$style.suggestions">
        <CChipSuggest
          v-for="item in suggestions"
          :key="item.id"
          :dense="dense"
          :label="item.name"
          @click="() => onSuggestionPress(item)"
        />
      </div>
    </template>

    <VBtn
      v-if="canChangeSelection && value && value.length > 0"
      class="mr-auto ml-n3 text-none"
      text
      dense
      @click="clear"
    >
      Tout supprimer
    </VBtn>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _differenceBy from 'lodash/differenceBy'
  import _omit from 'lodash/omit'
  import _uniqBy from 'lodash/uniqBy'

  import { formatFreelancerSkillDuration } from '@comet/i18n'
  import { FreelancerSkillDuration } from '@comet/types'

  import ApolloMixin from '@/mixins/ApolloMixin'

  import searchQuery from './search.gql'
  import recommendedQuery from './recommended.gql'

  import config from '@/config'

  export default {
    name: 'FreelancerSkillsField',
    mixins: [ApolloMixin],
    props: {
      maxCount: {
        type: Number,
        default: 0,
      },
      /**
       * List of the currently selected skills
       * @type {Array.<Skill>}
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Disable all the inner inputs when enabled
       * @type {Boolean}
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Reduces the component's height
       */
      dense: {
        type: Boolean,
        default: false,
      },
      /**
       * Whether to show suggestion chips
       */
      showSuggestions: {
        type: Boolean,
        default: true,
      },
      /**
       * Will display the primary slot, and remove the duration and wished one.
       */
      withPrimary: {
        type: Boolean,
        default: false,
      },
      /**
       * Will display the wisheed slot.
       */
      withWished: {
        type: Boolean,
        default: true,
      },
      /**
       * Show the suggested skills above the selected skills or not
       */
      showSuggestionAbove: {
        type: Boolean,
        default: true,
      },
      /**
       * Maximal number of skills displayed without putting a "show more" button
       */
      maxSkillDisplayed: {
        type: Number,
        default: 8,
      },
      /**
       * Can the user add a skill that don't exist yet
       */
      canAddSkill: {
        type: Boolean,
        default: false,
      },
      /**
       * Quick & dirty fix to disable the ability to change the selection
       */
      canChangeSelection: {
        type: Boolean,
        default: true,
      },
      /**
       * Error messages
       */
      errorMessages: {
        type: String,
        default: null,
      },
    },
    constants: {
      FreelancerSkillDuration,
    },
    data() {
      return {
        /**
         * Currently entered text in the autocomplete component that is not matching
         * yet a skill (text source transmitted to the search request)
         * @type {String}
         */
        search: null,
        /**
         * List of all available skills (fetched from Apollo)
         * @type {Array.<Skill>}
         */
        rawResults: [],
        /**
         * List of skills to suggest (fetched from Apollo)
         * @type {Array.<Skill>}
         */
        rawSuggestions: [],
        /**
         * Whether to show all skills in the list
         */
        showAll: false,
      }
    },
    computed: {
      ...mapGetters({
        user: 'selectUser',
      }),
      /**
       * Search results fetched from Apollo, then enriched and filtered to avoid duplicates.
       * @type {Array.<Skill>}
       */
      results() {
        const { value, search, suggestions, rawResults } = this

        const results = _uniqBy(
          [
            // Display suggestions in the autocomplete menu if there is no current search term entered
            ...(search ? rawResults || [] : suggestions || []),
          ],
          'id',
        )

        return _differenceBy(results, value, 'id')
      },
      /**
       * Suggestions fetched from Apollon then filtered to avoid duplicates with
       * already selected skills (in "value").
       * @type {Array.<Skill>}
       */
      suggestions() {
        const { value, rawSuggestions } = this

        return _differenceBy(rawSuggestions, value, 'id')
      },
      /**
       * Label used for the "show more" button
       * @type {String}
       */
      showMoreLabel() {
        const { value, displayedSkills, __ } = this
        const count = value.length - displayedSkills.length

        return __('cp:freelancer-skills-field:show-more', count, {
          count,
        })
      },
      /**
       * Whether the show all button should be displayed.
       */
      displayShowAllButtons() {
        const { value, displayedSkills } = this

        if (!displayedSkills || !value) {
          return false
        }

        return displayedSkills.length < value.length
      },
      skills() {
        const { value } = this

        if (!value) {
          return []
        }

        return [...value.filter(s => s.wishedInMissions), ...value.filter(s => !s.wishedInMissions)]
      },
      /**
       * Skills displayed in the list.
       * @type {Array.<Skill>}
       */
      displayedSkills() {
        const { skills, showAll, maxSkillDisplayed } = this

        if (!skills) {
          return []
        }

        return !showAll ? skills.slice(0, maxSkillDisplayed) : skills
      },
    },
    apollo: {
      rawResults: {
        query: searchQuery,
        variables() {
          return { search: this.search || '' }
        },
        update(data) {
          return data?.searchSkills
        },
        debounce: 100,
        fetchPolicy: 'network-only',
      },
      rawSuggestions: {
        query: recommendedQuery,
        variables() {
          const { value } = this

          return { skills: (value || []).map(s => s.id) }
        },
        update(data) {
          return data?.recommendedSkills
        },
        fetchPolicy: 'network-only',
      },
    },
    methods: {
      _omit,
      formatFreelancerSkillDuration,
      clear() {
        this.search = ''
        this.$emit('input', [])
      },
      /**
       * Fired when the combobox's input changes (i.e. a skill is picked)
       * @param {Skill} item
       */
      onAutocompleteInput(item) {
        const { value } = this

        const newValue = [
          {
            ...item,
            wishedInMissions: item.wishedInMissions || false,
            duration: item.duration || FreelancerSkillDuration[0],
          },
          ...(value || []),
        ]

        this.search = ''
        this.$emit('input', newValue)
      },
      /**
       * Fired when the "wished" button of a skill is pressed
       * @param {Skill} item
       */
      onWishedPress(item) {
        const { value } = this

        const newValue = value.map(s => ({
          ...s,
          wishedInMissions: s.id === item.id ? !item.wishedInMissions : s.wishedInMissions,
        }))

        this.$emit('input', newValue)
      },
      /**
       * Fired when primary is ticked for a given item
       * @param {Number} primary
       * @param {Skill} item
       */
      onPrimaryInput(item) {
        const { value } = this

        const newValue = value.map(s => ({
          ...s,
          primary: s.id === item.id ? !s.primary : s.primary,
        }))

        this.$emit('input', newValue)
      },
      /**
       * Fired when a duration is picked for a given item
       * @param {Number} duration
       * @param {Skill} item
       */
      onDurationInput(duration, item) {
        const { value } = this

        const newValue = value.map(s => ({
          ...s,
          duration: s.id === item.id ? duration : s.duration,
        }))

        this.$emit('input', newValue)
      },
      /**
       * Fired when the "close" button is pressed on a skill chip
       * @param {Skill} item
       */
      onRemovePress(item) {
        const { value } = this

        const newValue = value.filter(s => s.id !== item.id)

        this.$emit('input', newValue)
      },
      /**
       * Fired when a suggested skill is pressed
       * @param {Skill} item
       */
      onSuggestionPress(item) {
        const { value } = this

        const newValue = [
          {
            ...item,
            wishedInMissions: item.wishedInMissions || false,
            duration: item.duration || FreelancerSkillDuration[0],
          },
          ...value,
        ]

        this.$emit('input', newValue)
      },
      /**
       * Fired when the user click to add a skill that don't exist yet
       */
      async onAddSkills() {
        const { user } = this
        const addSkillUrl = config.urls.addSkill
          .replace('{userId}', user.id)
          .replace('{userEmail}', user.email)
          .replace('{origin}', window.location.href)

        window.open(addSkillUrl, '_blank')
      },
    },
  }
</script>

<style lang="stylus" module>
  :global(#app) {
    .root {
      --input-icon-size: 24px

      .suggestions > *:not(:first-child) {
        margin-left: 8px
      }

      .chip {
        height: 30px
      }

      &.dense .chip {
        height: 24px
      }

      .primaryButton {
        border-radius: 10px
      }

      .durationWithoutWished {
        margin-left: 4px
      }

      .skills > * {
        margin: 4px 9px 4px 0
        padding: 0 9px

        :global(.v-select__selection) {
          color: var(--color-grey-70) !important
        }
      }

      @media print {
        .noPrint {
          display: none
        }
      }
    }
  }
</style>
