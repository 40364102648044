<template>
  <Box
    v-bind="_omit($attrs, ['border'])"
    :border="border"
    :class="classes"
    :padding="padding"
    :radius="radius"
    class="foldable-card"
  >
    <button
      v-if="!bottomButton"
      :class="$('button')"
      :disabled="disabled"
      @click.prevent="onHeaderPress"
    >
      <div :class="$('header')">
        <div :class="$('header-slot')">
          <slot name="header" />
        </div>
        <i :class="[$('header-arrow'), 'icon-chevron-top']" />
      </div>
    </button>

    <div v-else :class="$('header')">
      <slot name="header" />
    </div>

    <div v-if="$slots.default" :class="[$('container'), { [$('-hidden')]: overflowIsHidden }]">
      <div :class="$('content')">
        <slot />
      </div>
    </div>

    <NavButton
      v-if="bottomButton && mode !== DUMMY"
      :theme="NavButton.Theme.SECONDARY_VOID"
      :class="$('button--bottom')"
      :disabled="disabled"
      :text="buttonLabel"
      :right-icon="isFolded ? 'icon-chevron-bottom' : 'icon-chevron-top'"
      @click.prevent="onHeaderPress"
    />
  </Box>
</template>

<script>
  import _get from 'lodash/get'
  import _omit from 'lodash/omit'

  import Box from '@/core/layout/Box/Box'
  import NavButton from '@/core/controls/NavButton/NavButton'

  import I18nMixin from '@/mixins/I18nMixin'
  import RouterMixin from '@/mixins/RouterMixin'

  const Mode = {
    CONNECTED: 'connected',
    SELF: 'self',
    DUMMY: 'dummy',
  }

  const Theme = {
    INFORMATION: 'information',
    PRIMARY: 'primary',
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
  }

  export const FoldableCardTheme = Theme
  export const FoldableCardMode = Mode

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Box: Box.__enums,
  NavButton: NavButton.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme,Mode,Border:Box.Border,Padding:Box.Padding,Shadow:Box.Shadow,Radius:Box.Radius,
    name: 'FoldableCard',
    __enums: {
      Theme,
      Mode,
      Border: Box.Border,
      Padding: Box.Padding,
      Shadow: Box.Shadow,
      Radius: Box.Radius,
    },
    components: {
      Box,
      NavButton,
    },
    mixins: [I18nMixin, RouterMixin],
    props: {
      /**
       * If 'connected', the folded state of this card depends only on the value
       * present in the URL (`folded`).
       * If 'self', the card keeps itself its own state as a data prop and uses
       * the given folded prop as an initial value.
       * If 'dummy', the card relies only on the folded prop.
       */
      mode: {
        type: String,
        default: Mode.SELF,
      },
      /**
       * Used only in 'connected' mode in order to identify the card among
       * others in the URL query params
       */
      name: {
        type: String,
        default: null,
      },
      /**
       * Whether or not the card should be folded when the component is created
       */
      folded: {
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * True if there is a button "see more" at the bottom
       */
      bottomButton: {
        type: Boolean,
        default: false,
      },
      theme: {
        type: String,
        default: Theme.INFORMATION,
      },
      border: {
        type: String,
        default: Box.Border.SOLID,
      },
      /**
       * Radius to apply on box
       */
      radius: {
        type: String,
        default: Box.Radius.MEDIUM,
      },
      /**
       * Padding forwarded to the box
       */
      padding: {
        type: String,
        default: Box.Padding.S,
      },
    },
    constants: {
      DUMMY: Mode.DUMMY,
    },
    data() {
      return {
        /**
         * Dynamic card folded state (used only in 'self' mode)
         * @type {Boolean}
         */
        isSelfFolded: this.folded,
        /**
         * Property that we allow to change the overflow dynamically
         * @type {Boolean}
         */
        overflowIsHidden: false,
      }
    },
    computed: {
      /**
       * True if the component is folded
       * @type {Boolean}
       */
      isFolded() {
        const { mode, name, folded, isSelfFolded, route } = this

        switch (mode) {
          case Mode.CONNECTED:
            return !!_get(route, 'query.folded', []).includes(name)
          case Mode.DUMMY:
            return folded
          case Mode.SELF:
          default:
            return isSelfFolded
        }
      },
      /**
       * Label displayed in the "view more" button according to the card's state
       * @type {String}
       */
      buttonLabel() {
        const { __, isFolded } = this

        return isFolded
          ? __('core:cards:foldable-card:collapsed-button')
          : __('core:cards:foldable-card:expanded-button')
      },
      /**
       * CSS classes applied on the component
       * @type {Object.<Boolean>}
       */
      classes() {
        const { mode, disabled, isFolded, theme } = this

        return {
          'foldable-card--folded': isFolded,
          'foldable-card--disabled': disabled,
          [`foldable-card--${mode}`]: true,
          [`foldable-card-theme--${theme}`]: true,
        }
      },
    },
    watch: {
      folded(next, prev) {
        const { mode } = this

        if (mode === 'self' && next !== prev) {
          this.isSelfFolded = next
        }
      },
      /**
       * Make sure to change overflow after the transition has ended
       */
      isFolded: {
        immediate: true,
        handler(next) {
          setTimeout(() => {
            this.overflowIsHidden = next
          }, 250)
        },
      },
    },
    methods: {
      _omit,
      /**
       * Fired when the card's header is pressed
       * @returns {void}
       */
      onHeaderPress() {
        const { mode, name, folded, isSelfFolded, route, navigate } = this

        switch (mode) {
          case 'connected':
            navigate({
              path: route.path,
              query: {
                ...route.query,
                folded: [name],
              },
            })
            break
          case 'dummy':
            this.$emit('update:folded', !folded)
            break
          case 'self':
          default:
            this.isSelfFolded = !isSelfFolded
            break
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .foldable-card {
    &.box.box--padding-s {
      padding-top: 24px
    }

    &-button {
      width: 100%
      height: auto
      display: block
      padding: 0
      margin: 0
      background-color: transparent
      border: none
      text-transform: none
      font-family: inherit
      font-weight: inherit
      font-size: inherit
      line-height: inherit
      text-align: inherit

      &--bottom {
        display: flex
        margin-top: 16px
      }

      ^[0]-header-slot {
        width: 100%
        padding-right: 32px
      }
    }

    &-header {
      position: relative
      display: flex
      flex-direction: row
      align-items: center
      cursor: pointer

      &-arrow {
        position: absolute
        top: calc(50% - 12px)
        right: 0
        font-size: 24px
        color: var(--color-font-light)
        transition: transform .2s ease-in
      }
    }

    &-container {
      max-height: 10000px      // Required by the transition below
      transition: max-height .5s ease-in

      &.foldable-card--hidden {
        overflow: hidden
      }
    }

    &-content {
      padding-top: 24px
      padding-bottom: 6px
    }

    &&--folded {
      .foldable-card-header-arrow {
        transform: rotate(180deg)
        transition: transform .2s ease-out
      }

      .foldable-card-container {
        max-height: 0
        transition: max-height .5s ease-out
      }
    }

    &^[0]--disabled {
      button {
        opacity: 1
      }

      .foldable-card-header {
        cursor: initial
        opacity: 0.5
      }
    }

    // Themes
    &-theme {
      &--information {
        border-left-width: 1px
      }

      &--primary {
        border-left: 3px solid var(--color-brand)
      }

      &--success {
        border-left: 3px solid var(--color-positive)
      }

      &--warning {
        border-left: 3px solid var(--color-warning)
      }

      &--danger {
        border-left: 3px solid var(--color-negative)
      }
    }
  }
</style>
