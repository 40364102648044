<template>
  <Transition name="slide-y" :appear="true">
    <div v-click-outside="() => $emit('close')" :class="$style.notificationCenter">
      <div :class="$style.header">
        <Txt
          :size="Txt.Size.XXXS"
          :type="Txt.Type.OVERLINE"
          :theme="Txt.Theme.GREY"
          :value="__('cp:common:notification-center:title')"
        />
        <NavButton
          v-if="hasUnread"
          :theme="NavButton.Theme.PRIMARY_VOID"
          :text="__('cp:common:notification-center:mark-as-read')"
          :size="NavButton.Size.SMALLER"
          @click="markAllAsRead"
        />
      </div>

      <InfiniteScroll
        v-if="notifications && notifications.length"
        :class="$style.list"
        :items="notifications"
        :total="total"
        :loading="loading"
        :show-end="false"
        @bottom-reached="onBottomReached"
      >
        <div
          v-for="notification in notifications"
          :key="`notification-center-${notification.id}`"
          :class="getNotificationClasses(notification)"
        >
          <AppLink :to="notification.url" @click="() => markAsRead(notification)">
            <Txt
              :size="Txt.Size.XXXS"
              :value="notification.description"
              :class="$style.notificationText"
              :raw="true"
            />
            <Txt
              :size="Txt.Size.XXXXS"
              :type="Txt.Type.ITALIC"
              :theme="Txt.Theme.GREY"
              :value="humanize(notification.createdAt)"
            />
          </AppLink>
          <button
            :class="$style.marker"
            @click.prevent="() => toggleNotificationUnseen(notification)"
          >
            <ColorMarker
              :color="ColorMarker.Color.BLUE"
              :size="ColorMarker.Size.SMALLER"
              :circle="true"
              :filled="notification.unseen"
            />
          </button>
        </div>
      </InfiniteScroll>

      <div v-else :class="$style.placeholder">
        <Txt
          :class="$style.placeholderText"
          :size="Txt.Size.XXS"
          :value="__('cp:common:notification-center:placeholder')"
        />
        <Illustration
          animation="badge"
          path="illustrations/spacecraft"
          :class="$style.placeholderIllustration"
        />
      </div>
    </div>
  </Transition>
</template>

<script>
  import AppLink from '@/core/controls/AppLink/AppLink'
  import ColorMarker from '@/core/controls/ColorMarker/ColorMarker'
  import Illustration from '@/core/graphics/Illustration/Illustration'
  import InfiniteScroll from '@/core/layout/InfiniteScroll/InfiniteScroll'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import Txt from '@/core/text/Txt/Txt'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import ClockMixin from '@/mixins/ClockMixin'
  import I18nMixin from '@/mixins/I18nMixin'
  import RouterMixin from '@/mixins/RouterMixin'

  import markAllUserNotificationsAsRead from './markAllUserNotificationsAsRead.gql'
  import updateUnseenUserNotification from './updateUnseenUserNotification.gql'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  ColorMarker: ColorMarker.__enums,
  Illustration: Illustration.__enums,
  InfiniteScroll: InfiniteScroll.__enums,
  NavButton: NavButton.__enums,
  Txt: Txt.__enums,
},

    name: 'NotificationCenter',
    components: {
      AppLink,
      ColorMarker,
      Illustration,
      InfiniteScroll,
      NavButton,
      Txt,
    },
    mixins: [ApolloMixin, ClockMixin, I18nMixin, RouterMixin],
    props: {
      notifications: {
        type: Array.Object,
        default: null,
      },
      hasUnread: {
        type: Boolean,
        default: false,
      },
      total: {
        type: Number,
        default: 0,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onBottomReached() {
        this.$emit('load-more')
      },
      /**
       * Generate notification classes
       * @param   {Object}
       * @returns {Object}
       */
      getNotificationClasses(notification) {
        const { $style } = this

        return {
          [$style.notification]: true,
          [$style.unseen]: notification.unseen,
        }
      },
      /**
       * Mark all notifications as read
       * @returns {Promise}
       */
      async markAllAsRead() {
        await this.mutate(markAllUserNotificationsAsRead)
        this.$emit('update')
      },
      /**
       * Mark all notifications as read and close pannel
       * @param {Object}
       * @returns {Promise|void}
       */
      async markAsRead(notification) {
        if (!notification.unseen) {
          return
        }

        await this.mutate(updateUnseenUserNotification, {
          variables: { id: notification.id, unseen: false },
        })

        this.$emit('update')
        this.$emit('close')
      },
      /**
       * Toggle notification seen setting
       * @param   {Object}
       * @returns {Promise}
       */
      async toggleNotificationUnseen(notification) {
        await this.mutate(updateUnseenUserNotification, {
          variables: { id: notification.id, unseen: !notification.unseen },
        })
        this.$emit('update')
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_shadow.styl'
  @import '~@/assets/css/_variables.styl'

  $scrollbarWidth = 7px

  .notificationCenter {
    flex: 0
    position: fixed
    top: $app-navbar-height + 8px
    right: 16px
    width: $app-notif-center-width
    display: flex
    flex-direction: column
    border-radius: $radius-medium
    background: var(--color-background)
    box-sizing: border-box
    box-shadow: 0px 2px 14px var(--color-shadow)
    overflow: hidden
    z-index: 5

    @media tablet-up {
      max-height: 70vh
    }

    @media phone {
      top: 72px
      left: 16px
      width: calc(100vw - 32px)
    }

    .header {
      min-height: 30px
      display: flex
      justify-content: space-between
      align-items: center
      padding: 0 0 0 12px
      shadow('light', 'small')
    }

    .placeholder {
      height: $app-notif-center-width

      .placeholderText {
        padding-left: 12px
      }

      .placeholderIllustration {
        margin: 0 auto
        width: $app-notif-center-width
        height: $app-notif-center-width
        margin-top: 32px
        opacity: 0.3 !important
      }
    }



    .list {
      box-sizing: border-box
      height: 100%
      overflow-x: hidden
      overflow-y: auto

      .notification {
        padding: 8px 32px 8px 12px
        position: relative

        &:not(:last-child) {
          border-bottom: 1px solid var(--color-border)
        }

        &.unseen {
          background-color: var(--color-background-dual)
        }

        .notificationText {
          white-space: pre-line
        }

        .marker {
          display: none
          position: absolute
          top: 12px
          right: 5px

          &:hover {
            transform: scale(1.3);
          }
        }

        &:hover .marker {
          display: initial
        }
      }
    }
  }
</style>
