<template>
  <div class="slider">
    <input data-cy-slider-value type="hidden" @change="setSliderValue" />
    <VueSlider
      ref="slider"
      :height="4"
      direction="ltr"
      :value="normalizedValue"
      :min="min"
      :max="max"
      :interval="interval"
      :data="normalizedData"
      :contained="true"
      :clickable="true"
      :disabled="disabled"
      :marks="marks"
      :dot-options="dotOptions"
      :rail-style="railStyle"
      :process-style="processStyle"
      :style="sliderStyle"
      :tooltip="tooltip"
      tooltip-placement="bottom"
      @change="onInput"
      @drag-start="onDragStart"
      @drag-end="onDragEnd"
    />
  </div>
</template>

<script>
  import _get from 'lodash/get'
  import _range from 'lodash/range'

  import VueSlider from 'vue-slider-component'

  import CssMixin from '@/mixins/CssMixin'

  /**
   * Normalize value according to the authorized
   * 'min' and 'max' values
   * @param {Number} min
   * @param {Number} min
   * @param {Number} max
   * @returns {Number}
   */
  function normalize(value, min, max) {
    return Math.min(Math.max(min, value), max)
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  VueSlider: VueSlider.__enums,
},

    name: 'Slider',
    components: {
      VueSlider,
    },
    mixins: [CssMixin],
    props: {
      /**
       * Slider current (initial) value(s)
       * If 'value' is an Array, the slider will be displayed with two
       * thumbs, one minor and one major.
       */
      value: {
        type: [Number, String, Array],
        default: 0,
      },
      /**
       * Minimum acceptable value
       */
      min: {
        type: Number,
        default: undefined,
      },
      /**
       * Maximum acceptable value
       */
      max: {
        type: Number,
        default: undefined,
      },
      /**
       * Interval
       */
      interval: {
        type: Number,
        default: undefined,
      },
      /**
       * If 'true', the slider displays a bubble for each step
       * on the track line
       */
      showMarks: {
        type: Boolean,
        default: false,
      },
      /**
       * Labels to display instead of min and max values
       * if set
       * @type {Array.<String>}
       */
      labels: {
        type: Array,
        default: null,
      },
      /**
       * List of available values (as strings or object with id/name)
       * @type {Array.<String|Object>}
       */
      data: {
        type: Array,
        default: null,
      },
      /**
       * Disable the slider if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * When to display the tooltip can be ['none', 'always', 'focus']
       */
      tooltip: {
        type: String,
        default: 'always',
      },
      /**
       * Theme in which the Slider is integrated (can be "light" or "dark")
       */
      theme: {
        type: String,
        default: 'light',
      },
    },
    data() {
      return {
        /**
         * Flag use to know if we are facing a dragging action or a simple click
         * @type {Boolean}
         */
        dragging: false,
      }
    },
    computed: {
      /**
       * Normalized value
       * @type {Array.<Object>}
       */
      normalizedValue() {
        const { min, max, value, data } = this

        if (data && data.length) {
          const normalizedItem = data.find(item =>
            typeof item === 'object' ? item.id === value : item === value,
          )

          return _get(normalizedItem, 'name', null)
        }

        if (typeof value === 'string') {
          return value
        }

        return typeof value === 'number'
          ? normalize(value, min, max)
          : value.map(v => normalize(v, min, max))
      },
      /**
       * Normalized data
       * @type {Array.<Object>}
       */
      normalizedData() {
        const { data } = this

        if (!data || !data.length) {
          return null
        }

        return data.map(item => (typeof item === 'object' ? item.name : item))
      },
      sliderStyle() {
        return {
          width: 'calc(100% - 16px)',
          padding: '0 8px',
        }
      },
      railStyle() {
        const { disabled, getCssProperty } = this

        return {
          backgroundColor: getCssProperty('--color-button-secondary'),
          borderRadius: '2px',
          opacity: disabled ? 0.5 : 1,
        }
      },
      processStyle() {
        const { disabled, getCssProperty } = this

        return {
          backgroundColor: getCssProperty('--color-input-highlight'),
          borderRadius: '2px',
          opacity: disabled ? 0.5 : 1,
        }
      },
      dotOptions() {
        const { disabled, getCssProperty } = this
        const borderColor = getCssProperty('--color-input-border')
        const highlightColor = getCssProperty('--color-input-highlight')
        const shadowColor = getCssProperty('--color-shadow')
        const backgroundColor = getCssProperty('--color-input-background')

        return [
          {
            disabled,
            style: {
              width: '18px',
              height: '18px',
              position: 'absolute',
              top: '-2px',
              border: `solid 1px ${borderColor}`,
              borderRadius: '8px',
              boxShadow: `0 1px 2px ${shadowColor}`,
              background: backgroundColor,
              boxSizing: 'border-box',
            },
            focusStyle: {
              border: `solid 1px ${highlightColor}`,
              boxShadow: `0 1px 4px ${shadowColor}`,
            },
            tooltipStyle: {
              fontSize: '13px',
              padding: '0 8px',
              color: highlightColor,
            },
            tooltipFocusStyle: {
              fontWeight: '700',
            },
          },
        ]
      },
      marks() {
        const { min, max, interval, showMarks, normalizedData, getCssProperty } = this
        const railColor = getCssProperty('--color-button-secondary')
        const processColor = getCssProperty('--color-input-highlight')
        const values = normalizedData || _range(min, max + 1, interval).map(v => ({ name: `${v}` }))
        const result = {}

        values.forEach((item, index, list) => {
          result[item.name] = {
            label: index === 0 || index === list.length - 1 ? item.name : ' ',
            labelStyle: {
              fontSize: '13px',
              padding: '5px 0',
            },
            style: showMarks
              ? {
                  display: 'block',
                  top: '-50%',
                  left: '-50%',
                  width: '8px',
                  height: '8px',
                  backgroundColor: railColor,
                  borderRadius: '4px',
                  visibility: 'visible',
                }
              : {
                  display: 'none',
                },
            activeStyle: {
              backgroundColor: processColor,
            },
          }
        })

        return result
      },
    },
    methods: {
      /**
       * Set slider value
       * Used for tests purpose mainly
       */
      setSliderValue(e) {
        this.$refs.slider.setValue(e.target.value)
      },
      /**
       * Fired when the slider is moving (during dragging) or clicked.
       * During dragging moves, we don't want to trigger 'change' events, but
       * only 'input' events.
       * When the slider is cliked, we want to trigger an 'input' event (because
       * the value was altered) and a 'change' event (because the action is
       * over).
       * That's why we need the 'dragging' flag
       * @param {Number|Array} value
       * @returns {void}
       */
      onInput(value) {
        const { data, dragging } = this

        let newValue = value

        if (data && data.length) {
          const selected = data.find(item =>
            typeof item === 'object' ? item.name === value : item === value,
          )

          if (typeof selected === 'object') {
            newValue = selected.id
          } else {
            newValue = selected
          }
        }

        this.$emit('input', newValue)

        if (!dragging) {
          // As the transition duration of the slider is 500ms, we dispatch the
          // "change" event after this delay to avoid a component to execute
          // something ("heavy") at this time. This would damage the fluidity
          // of the animation.
          setTimeout(() => {
            this.$emit('change', newValue)
          }, 500)
        }
      },
      /**
       * Fired when the slider's dragging is ended. Means a "change"
       * event must be triggered.
       * @returns {void}
       */
      onDragStart() {
        this.dragging = true
      },
      /**
       * Fired when the slider's dragging is just starting.
       * @returns {void}
       */
      onDragEnd() {
        this.dragging = false

        this.$emit('change', this.value, this.dragging)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .slider {
    position: relative
    height: 40px
    padding-top: 10px
    box-sizing: border-box
    color: var(--color-input-font)
  }

  // Fix slider text wrapping
  >>> .vue-slider-dot-tooltip-inner-bottom {
    width: 100%
  }
</style>
