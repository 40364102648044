<template>
  <div
    v-if="!$list.collapsable || !$list.collapsed"
    :class="$style.root"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'CKanbanContent',
    inject: [
      '$list',
    ],
  }
</script>

<style lang="stylus" module>
  .root {
    padding: 1px
    overflow-y: auto
    height: 100%
  }
</style>
