<template>
  <Transition name="fade" :appear="true">
    <ul v-if="shown" :class="$style.root">
      <Txt
        v-for="(message, code) in items"
        :key="code"
        tag="li"
        :value="message || code"
        :size="Txt.Size.XS"
        :type="Txt.Type.NORMAL"
        :theme="Txt.Theme.DANGER"
        :raw="!!AppError[code]"
        :class="$style.label"
      />
    </ul>
  </Transition>
</template>

<script>
  import _get from 'lodash/get'
  import _isArray from 'lodash/isArray'

  import Txt from '@/core/text/Txt/Txt'

  import AppTypes from '@/types/app'
  import { formatAppError } from '@/utils/format/app'

  const { AppError } = AppTypes
  const { UNKNOWN_ERROR } = AppError

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

    name: 'AsyncErrors',
    components: {
      Txt,
    },
    props: {
      /**
       * Hash containing errors to display with code / translated message
       * as key / value (like "ApolloMixin.mutateErrors")
       * OR
       * Array containing raw "errors" received straight from the API
       */
      errors: {
        type: [Object, Array],
        default: null,
      },
    },
    constants: {
      AppError,
    },
    computed: {
      /**
       * Returns 'true' if the error panel must be visible (i.e. actually
       * contains errors)
       * @type {Boolean}
       */
      shown() {
        const { errors } = this

        return !!(errors && Object.keys(errors).length)
      },
      /**
       * Formatted items, especially when receiving raw errors.
       * @type {Object}
       */
      items() {
        const { errors } = this
        const items = {}

        if (_isArray(errors)) {
          errors.forEach(error => {
            const code = _get(error, 'extensions.code', null)
            const formatted = formatAppError(code)

            if (code && !!AppError[code] && formatted) {
              // Check if the received code is an identified error and not
              // a compromised message (containing potentially SQL injection)
              items[code] = formatted
            } else {
              items[UNKNOWN_ERROR] = formatAppError(UNKNOWN_ERROR)
            }
          })

          return items
        }

        return errors
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_colors.styl'
  @import '~@/assets/css/_variables.styl'
  @import '~@/assets/css/_transitions.styl'

  :global(.v-application) {
    .root {
      padding: 12px 16px !important
      border-radius: $radius-medium
      background-color: var(--color-negative-transparent)
    }

    .label {
      white-space: pre-line
    }

    .label:not(:last-child) {
      margin-bottom: 8px
    }
  }
</style>
