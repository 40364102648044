import config from '@/config'

/**
 * Namespace appended to the pairs' key
 */
const APP_NAMESPACE = config.storage.namespace

/**
 * Enable or disable console logs display
 */
const LOGS_ENABLED = config.logs.storage

/**
 * Namespace appended to the pairs' key
 */
const storage = window.localStorage

/**
 * Fetches an item for a given key in the local storage
 * @param {String} key
 * @returns {String}
 */
function getItem(key) {
  let name = null
  let value = null

  try {
    // Appends the app namespace to the pair key
    name = `${APP_NAMESPACE}:${key}`
    // Performs the pair fetch
    value = storage.getItem(name)
    // Parse the stored JSON string to get an object / value
    value = JSON.parse(value)

    if (LOGS_ENABLED) {
      console.info('[Storage] %s  ->  ', name, value)
    }
  } catch (e) {
    // Do nothing
  }

  return value !== 'undefined' ? value : null
}

/**
 * Removes an item for a given key from the local storage
 * @param {String} key
 * @returns {void}
 */
function removeItem(key) {
  let name = null

  try {
    // Appends the app namespace to the pair key
    name = `${APP_NAMESPACE}:${key}`
    // Performs the pair deletion
    storage.removeItem(name)

    if (LOGS_ENABLED) {
      console.info('[Storage] %s  X  ', name)
    }
  } catch (e) {
    // Do nothing
  }
}

/**
 * Sets the specified value for a given key in the local storage
 * @param {String} key
 * @param {*} value
 * @returns {void}
 */
function setItem(key, val) {
  let name = null
  let value = null

  try {
    // Appends the app namespace to the pair key
    name = `${APP_NAMESPACE}:${key}`

    // Set a value to null value leads to a key removal
    if (val === null) {
      removeItem(name)
    }

    if (LOGS_ENABLED) {
      console.info('[Storage] %s  <-  ', name, val)
    }

    // Converts object / value to a JSON string
    value = JSON.stringify(val)

    // Performs the pair save
    storage.setItem(name, value)
  } catch (e) {
    // Do nothing
  }
}

export default {
  getItem,
  setItem,
  removeItem,
}
