
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"Notification"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserNotification"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unseen"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"category"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"plainTextDescription"},"arguments":[],"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotificationCenter"},"directives":[]},{"kind":"FragmentSpread","name":{"kind":"Name","value":"NotificationStack"},"directives":[]}]}}],"loc":{"start":0,"end":279}};
    doc.loc.source = {"body":"#import '../../../components/common/NotificationCenter/fragment.gql'\n#import '../../../components/common/NotificationStack/fragment.gql'\n\nfragment Notification on UserNotification {\n  id\n  unseen\n  category\n  plainTextDescription\n  ...NotificationCenter\n  ...NotificationStack\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('../../../components/common/NotificationCenter/fragment.gql').definitions));
doc.definitions = doc.definitions.concat(unique(require('../../../components/common/NotificationStack/fragment.gql').definitions));


      module.exports = doc;
    
