import _pick from 'lodash/pick'
import _range from 'lodash/range'

/**
 * Status
 */
const FreelancerStatus = {
  NEW: 'new',
  WRONG_SCOPE: 'wrongScope',
  VALIDATED: 'validated',
  BANNED: 'banned',
}

/**
 * Grade
 */
const FreelancerGrade = {
  REFERENCED: 'referenced',
  ASTRONAUT: 'astronaut',
  GRADUATE: 'graduate',
  EXPLORER: 'explorer',
  CANDIDATE: 'candidate',
  NO_GRADE: 'noGrade',
}

/**
 * Freelancer Status for Hunters
 */
const FreelanceHuntingInfoFreelanceStatus = {
  FREELANCER: 'freelance',
  FREELANCER_STATUS_PENDING: 'freelanceStatusPending',
  NOT_FREELANCER: 'notFreelance',
  NOT_FREELANCER_BUT_INTERESTED: 'notFreelanceButInterested',
}

/**
 * Groups of freelancers (according to their status)
 */
const FreelancerMode = {
  FAVORITES: 'favorites',
  ALL: 'all',
  ARCHIVED: 'archived',
  NEW: 'new',
  ON_MISSION: 'on-mission',
  PRE_USERS: 'pre-users',
  USERS: 'users',
  TO_REVIEW: 'to-review',
}

/**
 * Enum of filters for freelancers
 */
const FreelancerFilters = {
  ..._pick(FreelancerStatus, ['WRONG_SCOPE']),
  IS_FAVORITED: 'isFavorited',
  IS_FAVORITED_BY_ME: 'isFavoritedByMe',
  DROPPED: 'droppedOnboarding',
  BANNED: 'banned',
  IN_MY_ON_GOING_MISSION: 'inMyOnGoingMission',
}

/**
 * Enum for the qualification status
 */
const FreelancerQualification = {
  QUALIFIED: 'qualified',
  DISQUALIFIED: 'disqualified',
  PENDING: 'pending',
}

/**
 * Enum of the freelancer mode filters
 * @type {Object}
 */
const FreelancerModeFilters = {
  [FreelancerMode.FAVORITES]: [FreelancerFilters.IS_FAVORITED_BY_ME],
  [FreelancerMode.NEW]: [FreelancerFilters.DROPPED],
  [FreelancerMode.ON_MISSION]: [FreelancerFilters.IN_MY_ON_GOING_MISSION],
  [FreelancerMode.ARCHIVED]: [FreelancerFilters.WRONG_SCOPE, FreelancerFilters.BANNED],
}

/**
 * Signup steps
 */
const SignUpStep = {
  PREFERENCES: 'preferences',
  SKILLS: 'skills',
  CONFIRM_EMAIL: 'confirmEmail',
  CONTACT_INFO: 'contactInfo',
  ENTERPRISE_INFO: 'enterpriseInfo',
  DONE: 'done',
}

/**
 * Freelance preference choices about company's environments
 * GraphQL: freelanceEnvironmentEnum
 */
const Environment = {
  START_UP: 'startUp',
  BIG_COMPANY: 'bigCompany',
  NONE: 'none',
}

/**
 * Freelance preference choices about workplaces
 * GraphQL: freelanceWorkplaceEnum
 */
const Workplace = {
  ON_SITE: 'onSite',
  REMOTE: 'remote',
  PARTIAL_REMOTE: 'partialRemote',
  NONE: 'none',
}

/**
 * Maximum and minimum authorized TJM
 */
const RetributionBounds = {
  MAX: 2000,
  MIN: 200,
}

/**
 * Freelance preference choices about contracts
 * GraphQL: freelanceContractEnum
 */
const Contract = {
  NONE: 'none',
  PART_TIME: 'partTime',
  FULL_TIME: 'fullTime',
}

/**
 * Enum representing the possible moments during which
 * the freelance would be called
 */
const CallAvailability = {
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
  EVENING: 'evening',
  ALL_DAY: 'allDay',
  NONE: null,
}

/**
 * Enum representing the level of a freelance regarding
 * its years of experience
 */
const ExperienceLevel = {
  JUNIOR: 'junior',
  MIDDLE: 'middle',
  EXPERIENCED: 'experienced',
  SENIOR: 'senior',
}

/**
 * Enum representing the level of a freelance regarding
 * its years of experience
 */
const QualificationLevel = {
  NOT_VERIFIED: 'notVerified',
  ON_GOING: 'onGoing',
  JUNIOR: 'junior',
  INTERMEDIATE: 'intermediate',
  SENIOR: 'senior',
  EXPERT: 'expert',
}

/**
 * Enum defining the sort of prefered mission regarding to its
 * duration
 */
const PrefTime = {
  SHORT: 'short',
  LONG: 'long',
  NONE: 'none',
}

/**
 * Enum defining how the freelancer would accept a mission regarding to
 * its location
 */
const Mobility = {
  CITY: 'city',
  REGION: 'region',
  COUNTRY: 'country',
}

/**
 * Enum defining the level a user would have about a language
 */
const LanguageLevel = {
  BASIC: 'basic',
  PRO: 'pro',
  NATIVE: 'native',
}

/**
 * Enum for freelancer acquisition types
 */
const FreelancerAcquisition = {
  EVENT: 'event',
  SOCIAL_NETWORK: 'socialNetwork',
  INTERNET_SEARCH: 'internetSearch',
  PRESS_ARTICLE: 'pressArticle',
  SPONSORSHIP: 'sponsorship',
  WORD_OF_MOUTH: 'wordOfMouth',
  COMET_CONTENT: 'cometContent',
  ADVERTISING: 'advertising',
  LINKEDIN_CONTACT: 'linkedinContact',
  EMAIL_CONTACT: 'emailContact',
  OTHER: 'other',
}

/**
 * Enum for freelancer's permissions
 */
const FreelancerPermissions = {
  SHOW_PROFILE: 'showProfile',
  SHOW_MISSIONS: 'showMissions',
  SHOW_STORE: 'showStore',
  SHOW_INFOS: 'showInfos',
  SHOW_EXPERIENCES: 'showExperiences',
  SHOW_QUALIFICATION: 'showQualification',
  SHOW_INSTRUCTOR: 'showInstructor',
  ASK_FOR_QUALIFICATION: 'askForQualification',
  SHOW_PREFERENCES: 'showPreferences',
  SHOW_COMPANY: 'showCompany',
}

/**
 * Freelancer document types
 */
const FreelancerDocumentType = {
  CRA: 'cra',
  INVOICE: 'invoice',
}

/**
 * Status of the request for a referent done by a freelancer
 */
const FreelancerExperienceReferentRequestStatus = {
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  DENIED: 'denied',
  COMPLETED: 'completed',
}

/**
 * Freelancer skill levels
 */
const FreelancerSkillDuration = [null, ..._range(25)]

/**
 * Freelancer profile sections
 */
const FreelancerProfileSections = {
  EDUCATION: 'education',
  EXPERIENCES: 'experiences',
}

/**
 * Linkedin profile import status
 */
const LinkedInImportStatus = {
  NONE: 'none',
  DOING: 'doing',
  FAILED: 'failed',
  DONE: 'done',
}

/**
 * Periodic rating enum
 */
const PeriodicRatingEnums = {
  BAD: 'bad',
  GOOD: 'good',
}

/**
 * List of notifications the freelancer can receive
 */
const FreelancerNotification = {
  PROPOSAL: 'proposal',
}

/**
 * List of media from which the freelancer can receive notifications
 */
const FreelancerNotificationMedium = {
  SMS: 'sms',
  SLACK: 'slack',
  EMAIL: 'email',
  PHONE: 'phone',
  LINKEDIN: 'linkedin',
}

/**
 * List of profile elements that could be missing
 */
const FreelancerProfileElements = {
  TAG_LINE: 'hasTagline',
  BIOGRAPHY: 'hasBiography',
  PROFILE_PIC: 'hasProfilePic',
  MISSION_PREFERENCES: 'hasMissionPreferences',
  PRO_EXPERIENCES: 'hasMoreThanOneXP',
  TOP_SKILLS: 'hasOneTopSkill',
}

/**
 * Worker position
 */
const AdditionalWorkerPosition = {
  EMPLOYEE: 'employee',
  ESN_EMPLOYEE: 'esnEmployee',
  RECRUITER: 'recruiter',
  STUDENT: 'student',
  OTHER: 'other',
}

const SlackStatus = {
  INITIAL: 'initial',
  PENDING: 'pending',
  VALIDATED: 'validated',
  REFUSED: 'refused',
}

/**
 * Root FreelancerTypes object
 */
const FreelancerTypes = {
  CallAvailability,
  Contract,
  Environment,
  ExperienceLevel,
  FreelancerAcquisition,
  FreelancerDocumentType,
  FreelancerExperienceReferentRequestStatus,
  FreelancerFilters,
  FreelancerMode,
  FreelancerModeFilters,
  FreelancerNotification,
  FreelancerNotificationMedium,
  FreelancerPermissions,
  FreelancerProfileElements,
  FreelancerProfileSections,
  FreelancerQualification,
  FreelancerSkillDuration,
  FreelancerStatus,
  FreelancerGrade,
  FreelanceHuntingInfoFreelanceStatus,
  AdditionalWorkerPosition,
  LanguageLevel,
  LinkedInImportStatus,
  Mobility,
  PeriodicRatingEnums,
  PrefTime,
  QualificationLevel,
  RetributionBounds,
  SlackStatus,
  SignUpStep,
  Workplace,
}

export default FreelancerTypes
