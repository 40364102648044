<template>
  <Field :label="label" class="task-field" :disabled="disabled">
    <div v-if="editable" :class="$('input')">
      <TextField
        v-model="input"
        :class="$('input-field')"
        :error="error"
        :disabled="inputDisabled"
        :placeholder="inputPlaceholder"
        :maxlength="maxItemLength"
        @enter="onAddPress"
      />
      <NavButton
        :class="$('input-button')"
        theme="secondary"
        type="submit"
        :disabled="disabled"
        @click.prevent="onAddPress"
      >
        {{ __('cp:form:task-field:add-button') }}
      </NavButton>
    </div>

    <TaskList
      :tasks="value"
      :limit="limit"
      :removable="editable"
      :disabled="disabled"
      :empty="empty"
      @change="onListChange"
      @remove="onRemove"
    />
  </Field>
</template>

<script>
  import Field from '@/core/layout/Field/Field'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import TaskList from '@/core/badges/TaskList/TaskList'
  import TextField from '@/core/fields/TextField/TextField'

  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
  NavButton: NavButton.__enums,
  TaskList: TaskList.__enums,
  TextField: TextField.__enums,
},

    name: 'TaskField',
    components: {
      Field,
      NavButton,
      TaskList,
      TextField,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Ordered list of tasks (as simple String)
       * @type {Array.<String>}
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Limit the number of visible elements
       */
      maxItems: {
        type: Number,
        default: null,
      },
      /**
       * Limit the number of visible elements
       */
      maxReached: {
        type: String,
        default: null,
      },
      /**
       * Limit the number of visible elements
       */
      maxItemLength: {
        type: Number,
        default: null,
      },
      /**
       * Limit the number of visible elements
       */
      limit: {
        type: Number,
        default: 20,
      },
      /**
       * Input placeholder
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Disable the selector if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * If false the user only see the list of task and the field
       */
      editable: {
        type: Boolean,
        default: true,
      },
      /**
       * Error message to display
       */
      error: {
        type: String,
        default: undefined,
      },
      /**
       * Label text
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * The message to display if the list is empty
       */
      empty: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        /**
         * New task currently entered in the text field
         * @type {String}
         */
        input: null,
      }
    },
    computed: {
      /**
       * Check if a placeholder have been specified in the props otherwise
       * return a translated placeholder
       * @type {String}
       */
      inputPlaceholder() {
        const { placeholder, maxItems, maxReached, value, __ } = this

        if (maxItems && value.length >= maxItems && maxReached) {
          return maxReached
        }

        return placeholder || __('cp:form:task-field:placeholder-default')
      },
      inputDisabled() {
        const { disabled, value, maxItems } = this

        if (disabled) {
          return disabled
        }
        if (maxItems) {
          if (value.length >= maxItems) {
            return true
          }
        }
        return false
      },
    },
    methods: {
      /**
       * Fired when new input is entered or the add button clicked
       * @returns {void}
       */
      onAddPress() {
        const { value, input } = this

        if (!input) {
          return
        }

        const newValue = value ? [...value, input] : [input]
        const index = newValue.length - 1

        // Emits an "input" event with the new list
        this.$emit('input', newValue)

        // Emits also an "add" event exposing only the new element
        this.$emit('add', input, index)

        // Empty the input when it is submitted
        this.input = ''
      },
      /**
       * Fired when a task closing cross is clicked (i.e. a task is removed)
       * @param {Array.<String>} list
       * @returns {void}
       */
      onListChange(list) {
        this.$emit('input', list)
      },
      /**
       * Fired when a task closing cross is clicked (i.e. a task is removed)
       * @param {String} input
       * @returns {void}
       */
      onRemove(input, index) {
        this.$emit('remove', input, index)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .task-field {
    position: relative

    &-input {
      display: flex
      padding-bottom: 8px

      &-field {
        flex: 1
      }

      &-button {
        width: 140px
        margin-left: 16px
      }
    }
  }
</style>
