<template>
  <CFieldDate
    ref="dateField"
    v-bind="$attrs"
    :value="availabilityDate"
    :label="availabilityLabel"
    :placeholder="availabilityPlaceholder"
    :disabled="!value || isAvailable || disabled"
    :dense="dense"
    :min="$clock.tomorrow().toISOString()"
    color="error"
    :class="classes"
    v-on="_omit($listeners, ['input'])"
    @input="onDateFieldInput"
  >
    <template v-slot:prepend-inner>
      <VSwitch
        :input-value="isAvailable"
        :disabled="!value || disabled"
        :dense="dense"
        hide-details
        :class="$style.switch"
        class="ml-1"
        @change="onSwitchChange"
      />

      <span
        :class="$style.text"
        class="body-2 text--nowrap ml-1"
        @click="onAvailabilityTextPress"
        v-html="availabilityText"
      />
    </template>
  </CFieldDate>
</template>

<script>
  import _omit from 'lodash/omit'

  export default {
    name: 'FreelancerAvailabilityField',
    inheritAttrs: false,
    props: {
      /**
       * Object containing both the "isAvailable" and "availabilityDate" fields
       * @type {Object}
       */
      value: {
        type: Object,
        default: null,
      },
      /**
       * Date input label
       * @type {String}
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Disable the whole component (DateField, Switch, ...)
       * @type {Boolean}
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Reduces the field's height
       */
      dense: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { isAvailable, $style } = this

        return {
          [$style.root]: true,
          [$style.available]: isAvailable,
          [$style.unavailable]: !isAvailable,
        }
      },
      /**
       * Safe availability boolean passed to the DateField component
       * @type {Boolean}
       */
      isAvailable() {
        const { value } = this

        return !!(value?.isAvailable ?? false)
      },
      /**
       * Safe availability date passed to the DateField component
       * @type {String}
       */
      availabilityDate() {
        const { value } = this

        return value?.availabilityDate ?? null
      },
      /**
       * Formatted DateField label providing a default value if no one is passed
       * @type {String}
       */
      availabilityLabel() {
        const { label } = this
        const { __ } = this.$i18n

        return label || __('cp:availability-field:label')
      },
      /**
       * Placeholder passed to the DateField when it is flagged as unavailable but without
       * a specified availability date.
       * This helps to know that there is an editable date input.
       * @type {String}
       */
      availabilityPlaceholder() {
        const { isAvailable, availabilityDate } = this
        const { __ } = this.$i18n

        return !isAvailable && !availabilityDate
          ? __('cp:availability-field:placeholder')
          : null
      },
      /**
       * Formatted label according to the gioven values
       * @type {String}
       */
      availabilityText() {
        const { isAvailable, availabilityDate } = this
        const { __ } = this.$i18n

        return isAvailable
          ? __('cp:availability-field:state:available')
          : availabilityDate
            ? __('cp:availability-field:state:unavailable-until')
            : __('cp:availability-field:state:unavailable')
      },
    },
    methods: {
      _omit,
      /**
       * Fired when the availability switching button changes.
       * @param {Boolean} value
       */
      onSwitchChange(available) {
        const { availabilityDate } = this
        const { dateField } = this.$refs

        if (!available) {
          // If not available, pop the date picker to select a date
          dateField.open()
        } else {
          // Remove the focus
          document.activeElement?.blur()
        }

        this.$emit('input', {
          isAvailable: !!available,
          availabilityDate: !available
            ? availabilityDate
            : null,
        })
      },
      /**
       * Fired when a date is entered or changed by the user.
       * @param {String} date
       */
      onDateFieldInput(date) {
        this.$emit('input', {
          isAvailable: !date,
          availabilityDate: date,
        })
      },
      /**
       * Fired when the user presses the label describing the current state.
       * It should open the DatePicker panel.
       */
      onAvailabilityTextPress() {
        const { isAvailable } = this
        const { dateField } = this.$refs

        if (isAvailable) {
          this.$emit('input', {
            isAvailable: false,
            availabilityDate: null,
          })
        } else {
          dateField.open()
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_variables.styl'

  :global(#app) {
    .root {
      .switch {
        padding-top: 0 !important

        :global(.v-input--switch__thumb) {
          color: var(--color-white) !important
        }
      }

      .text {
        cursor: pointer
      }

      > :global(.v-input.v-text-field > .v-input__control > .v-input__slot) {
        background: var(--color-input-background) !important
      }

      &.available .switch {
        :global(.v-input--selection-controls__ripple),
        :global(.v-input--switch__track) {
          color: var(--color-positive) !important
        }
      }

      &.unavailable .switch {
        :global(.v-input--selection-controls__ripple),
        :global(.v-input--switch__track) {
          color: var(--color-negative) !important
        }
      }

      &.available {
        :global(.v-input.v-text-field .v-text-field__slot) input {
          color: var(--color-positive) !important
          caret-color: var(--color-positive) !important
          cursor: pointer
        }
      }

      &.unavailable {
        :global(.v-input.v-text-field .v-text-field__slot) input {
          margin-bottom: 1px
          font-weight: $font-weight-semi-bold
          color: var(--color-negative) !important
          caret-color: var(--color-negative) !important
          cursor: pointer

          &::placeholder {
            font-weight: $font-weight-regular
          }
        }
      }
    }
  }
</style>
