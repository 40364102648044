<template>
  <div class="polar-input" :class="classes">
    <div v-if="isBlockVisible(false)" class="polar-input-block polar-input-block-no">
      <div class="polar-input-icon" />
      <NavButton
        v-if="size === 'big'"
        class="polar-input-button"
        :disabled="disabled"
        theme="primary"
        @click="onPress(false)"
      >
        {{ __('cp:form:polar-input:no') }}
      </NavButton>
    </div>

    <div v-if="isBlockVisible(true)" class="polar-input-block polar-input-block-yes">
      <div class="polar-input-icon" />
      <NavButton
        v-if="size === 'big'"
        class="polar-input-button"
        theme="primary"
        :disabled="disabled"
        @click="onPress(true)"
      >
        {{ __('cp:form:polar-input:yes') }}
      </NavButton>
    </div>
  </div>
</template>

<script>
  import I18nMixin from '@/mixins/I18nMixin'
  import NavButton from '@/core/controls/NavButton/NavButton'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
},

    name: 'PolarInput',
    components: {
      NavButton,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Pre-filled value
       */
      value: {
        type: Boolean,
        default: undefined,
      },
      /**
       * Display size
       * Available values: "big", "small"
       */
      size: {
        type: String,
        default: 'big',
      },
      /**
       * Disable polar input. If so, only display the reponse
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Theme of the input can be dark or light
       */
      theme: {
        type: String,
        default: 'light',
      },
    },
    computed: {
      /**
       * CSS classes applied on the PolarInput parent tag
       * @type {Object.<Boolean>}
       */
      classes() {
        const { value, theme } = this

        return {
          'polar-input--light': theme === 'light',
          'polar-input--dark': theme === 'dark',
          'polar-input--yes': value === true,
          'polar-input--no': value === false,
        }
      },
    },
    methods: {
      /**
       * Display both buttons if not Disabled
       * Otherwiser display only the one selected
       * @param  {Boolean}  buttonValue
       * @returns {Boolean}
       */
      isBlockVisible(buttonValue) {
        const { value, disabled } = this

        return !disabled || (disabled && value === buttonValue)
      },
      /**
       * This function handle the click on the buttons and emit the event to
       * the parent component
       * @param {Object} event on click
       */
      onPress(value) {
        this.$emit('input', value)
      },
    },
  }
</script>

<style lang="stylus" scoped>


  $icon-no-dark = url('../../../assets/images/controls/polar-input-no-dark.svg')
  $icon-no-light = url('../../../assets/images/controls/polar-input-no-light.svg')
  $icon-yes-dark = url('../../../assets/images/controls/polar-input-yes-dark.svg')
  $icon-yes-light = url('../../../assets/images/controls/polar-input-yes-light.svg')

  .polar-input {
    width: 100%
    height: 100%
    display: flex
    flex-direction: row
    justify-content: center

    .polar-input-block {
      display: flex
      flex-direction: column

      &:not(:last-child) {
        margin-right: 20%
      }

      .polar-input-icon {
        height: 64px
        width: 95px
        margin-bottom: 16px
        background-position: center
        background-repeat: no-repeat
      }

      .polar-input-button {
        margin-top: 24px
      }
    }

    &.polar-input--no .polar-input-block-yes,
    &.polar-input--yes .polar-input-block-no {
      opacity: 0.6

      &:hover {
        opacity: 1
      }
    }

    &.polar-input--light {
      .polar-input-block-yes .polar-input-icon {
        background-image: $icon-yes-light
      }

      .polar-input-block-no .polar-input-icon {
        background-image: $icon-no-light
      }
    }

    &.polar-input--dark {
      .polar-input-block-yes .polar-input-icon {
        background-image: $icon-yes-dark
      }

      .polar-input-block-no .polar-input-icon {
        background-image: $icon-no-dark
      }
    }
  }
</style>
