/* eslint-disable no-underscore-dangle */

import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import _merge from 'lodash/merge'
import _values from 'lodash/values'

import { getSyncedFields, createSyncedForm, updateSyncedForm, destroySyncedForm } from './sync'
import { subscribeWatchers } from './watch'
import AlertOnLeave from './alertOnLeave'

/**
 * Callback fired when the component is created (just after data props
 * instanciation).
 * The form base configuration (named "FORM") is provided as argument.
 * @param {Object} config
 * @returns {void}
 */
function created(config) {
  return function wrappedCreated() {
    const { $options, $v, $router } = this

    const optionsForm =
      $options.form && _isFunction($options.form) ? $options.form.call(this) : $options.form

    const declaredForm = optionsForm || this.form

    this.form = _merge(
      {},
      // Form base structure containing states, configuration & methods
      config,
      // Form defined in data with all the utils function already defined
      // if we don't add it all the function will be lost and it need to be
      // defined after the config otherwise it will be overwritten
      this.form,
      // Form declared by the developer in the extended component
      declaredForm,
      // The values synced in the store and potentially load from
      // the local storage
      {
        initial: _merge({}, _get(declaredForm, 'fields', {}), this.form.fields),
        fields: _merge(
          {},
          _get(declaredForm, 'fields', {}),
          this.form.fields,
          getSyncedFields.call(this, declaredForm.name),
        ),
        validators: {},
        watchers: {},
        watch: null,
      },
    )

    if (!$v) {
      // Ensure that our "form component" always have a $v data prop accessible,
      // even if no validation is set
      this.$v = {}
    }

    // Keep a clone of the last updated form, to compare it then on every
    // modifications in the 'form' watcher. With this approach, we determine
    // which field(s) changed.
    // It is important to keep a clone of the form, and not the form itself
    // (if it is the the same reference, prev === next, so
    // _isEqual(next, prev) === true)
    this.FormMixin_prev = {
      form: this.form,
      $v: null,
    }

    // Dictionnary of debouncing functions (one for each field)
    this.FormMixin_debouncers = {}

    // Dictionnary of unsubscribe functions of every active field watchers
    this.FormMixin_subscriptions = {}

    // List of pending requests (usefull only in a 'debounce' environment)
    this.FormMixin_queue = []

    // Create the form in Vuex
    createSyncedForm.call(this, { ...this.form, meta: this.$v })

    // Subscribe to detect user navigation / leave
    if (this.form.alertOnLeave) {
      AlertOnLeave.created.call(this)

      $router.beforeEach(beforeRouteChange.bind(this))
    }

    // Add watchers on every identified fields (deeply if they have validators)
    subscribeWatchers.call(this)
  }
}

/**
 * Callback fired just before the component destroy
 * @returns {void}
 */
function beforeDestroy() {
  const { FormMixin_debouncers: debouncers } = this

  // Flush all the debouncers in order to performs the potential requests
  // (or actions) before the component destroy
  _values(debouncers).forEach(d => d.flush())
}

/**
 * Callback fired when the component is definitely destroyed
 * @returns {void}
 */
function destroyed() {
  const { form, $v } = this

  if (form) {
    if (form.alertOnLeave) {
      AlertOnLeave.destroyed.call(this)
    }

    if (form.destroyOnUnmount) {
      this.form = {}

      // Just remove the synced form on Vuex, if it can be removed on unmount
      destroySyncedForm.call(this, form.name)
    } else {
      // Else, well sync it before the component is destroyed (beacause the
      // watch won't be called anymore)
      // But if it has been already unsynced / destroyed (i.e. there is no
      // form on Vuex), do nothing
      const synced = getSyncedFields.call(this, form.name)

      if (synced) {
        updateSyncedForm.call(this, { ...form, meta: $v })
      }
    }
  }
}

/**
 * This hook is called before navigating to a route
 * on a different component
 * (for example from /backoffice/freelancers to /backoffice/missions)
 */
function beforeRouteChange(to, from, next) {
  if (
    this.form.alertOnLeave &&
    (!this.form.shouldAlertOnLeave || this.form.shouldAlertOnLeave({ from, to }))
  ) {
    AlertOnLeave.onNavigate.call(this, next)
  } else {
    next()
  }
}

export { created, beforeDestroy, destroyed }
