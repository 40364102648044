import moment from 'moment-timezone'
import _isDate from 'lodash/isDate'
import _isString from 'lodash/isString'

/**
 * Returns now date as a Moment Date
 * @returns {Moment}
 */
function now() {
  const diff = this.$store.getters.selectAppTimeDiff || 0

  return moment().add(diff, 'milliseconds')
}

/**
 * Returns yesterday's date as a Moment Date
 */
function yesterday() {
  return this.$clock.now().subtract(1, 'day')
}

/**
 * Returns tomorrow's date as a Moment Date
 */
function tomorrow() {
  return this.$clock.now().add(1, 'day')
}

/**
 * Returns 'now' as a JS Date
 * @type {Date}
 */
function nowDate() {
  return this.$clock.now().toDate()
}

/**
 * Returns 'yesterday' as a JS Date
 * @type {Date}
 */
function yesterdayDate() {
  return this.$clock.yesterday().toDate()
}

/**
 * Returns the duration ellapsed from now according to the given 'date'
 * @param {String | Date} date
 * @param {String} unit
 * @returns {Duration}
 */
function fromNow(date, unit = 'milliseconds') {
  const now = this.$clock.now()

  return date ? moment(date).diff(now, unit) : 0
}

/**
 * Returns the date of a diff from now
 * @returns {Date}
 */
function dateFrowNow(diff, unit) {
  return this.$clock.now().add(diff, unit)
}

/**
 * Returns a humanized format of a relative date (compared to now) or
 * a duration.
 * @param {*} value A relative date (Date, Moment, String) or a
 * duration (Number, String)
 * @param {String} unit Unit of measurement of the duration. Ignored
 * when handling a relative date.
 * @returns {String}
 */
function humanize(value, unit) {
  const now = this.$clock.now()

  // If the given 'value' is a date
  if (_isDate(value) || moment.isMoment(value) || (_isString(value) && !unit)) {
    return moment(value).from(now)
  }

  // If 'value' is a duration
  return moment.duration(value, unit || 'hours').humanize()
}

/**
 * Returns 'true' if the given 'date' is after now
 * @param {String | Date} date
 * @returns {Boolean}
 */
function isAfterNow(date) {
  const now = this.$clock.now()

  return date ? moment(date).isAfter(now) : false
}

/**
 * Returns 'true' if the given 'date' is equal or after now
 * @param {String | Date} date
 * @returns {Boolean}
 */
function isSameOrAfterNow(date) {
  const now = this.$clock.now()

  return date ? moment(date).isSameOrAfter(now) : false
}

/**
 * Returns 'true' if the given 'date' is before now
 * @param {String | Date} date
 * @returns {Boolean}
 */
function isBeforeNow(date) {
  const now = this.$clock.now()

  return date ? moment(date).isBefore(now) : false
}

/**
 * Returns 'true' if the given 'date' is equal or after now
 * @param {String | Date} date
 * @returns {Boolean}
 */
function isSameOrBeforeNow(date) {
  const now = this.$clock.now()

  return date ? moment(date).isSameOrBefore(now) : false
}

/**
 * Returns the given 'str' string date as a JS Date object
 * @param {String}
 * @returns {Date}
 */
function toDate(str) {
  return str ? moment(str).toDate() : undefined
}

/**
 * Check if the date is before today less some hours/days/months...
 * @param  {Date}  date - the date to compare
 * @param  {Number}  toSubstract - the number to substract to today
 * @param  {String}  unit - the unit to substract
 * Can be : years, months, days, hours, minutes, seconds, milliseconds
 * @returns {Boolean}
 */
function isBeforeDate(date, toSubstract, unit = 'days') {
  const now = this.$clock.now()

  return date && toSubstract ? moment(date).isBefore(now.subtract(toSubstract, unit)) : false
}

/**
 * Get time duration between two dates
 * @param  {String | Date}  start - start date
 * @param  {String | Date}  end - end date
 * @returns {Duration}
 */
function betweenDates(start, end, unit = 'milliseconds') {
  const now = this.$clock.now()

  // Ignores DST for large time differences
  // @see https://github.com/moment/moment/pull/747
  const fn = ['days', 'weeks', 'months', 'years'].includes(unit) ? moment.utc : moment

  return fn(end || now).diff(start || now, unit)
}

/**
 * Get representative time duration between two dates (month or year interval)
 * @param  {String | Date}  start - start date
 * @param  {String | Date}  end - end date
 * @returns {String}
 */
function durationBetween(start, end) {
  const durationInYears = this.$clock.betweenDates(start, end, 'years')

  if (durationInYears > 0) {
    return `${durationInYears} years`
  }

  return `${this.$clock.betweenDates(start, end, 'months')} months`
}

/**
 * VueClock plugin definition
 */
const VueClock = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        const { $parent } = this

        this._clockRoot = ($parent && $parent._clockRoot) || this

        if (!$parent) {
          this._clock = {
            now: now.bind(this),
            yesterday: yesterday.bind(this),
            tomorrow: tomorrow.bind(this),
            nowDate: nowDate.bind(this),
            yesterdayDate: yesterdayDate.bind(this),
            fromNow: fromNow.bind(this),
            dateFrowNow: dateFrowNow.bind(this),
            humanize: humanize.bind(this),
            isAfterNow: isAfterNow.bind(this),
            isSameOrAfterNow: isSameOrAfterNow.bind(this),
            isBeforeNow: isBeforeNow.bind(this),
            isSameOrBeforeNow: isSameOrBeforeNow.bind(this),
            toDate: toDate.bind(this),
            isBeforeDate: isBeforeDate.bind(this),
            betweenDates: betweenDates.bind(this),
            durationBetween: durationBetween.bind(this),
          }
        }
      },
    })

    Object.defineProperty(Vue.prototype, '$clock', {
      get() {
        return this._clockRoot._clock
      },
    })
  },
}

export default VueClock
