/* eslint-disable no-param-reassign */
export const SWITCH_OPEN_FREELANCER_AVAILABILITY = 'SWITCH_OPEN_FREELANCER_AVAILABILITY'

const SET_SWITCH = 'SET_SWITCH'

const switches = {
  // ====================  STATE  ==================== //

  state: {
    switches: {},
  },

  // ====================  GETTERS  ==================== //

  getters: {
    /**
     * Get the value of the switch of a given 'key'
     * @returns {Boolean}
     */
    switchIsSet: state => key => state.switches[key] === true,
  },

  // ====================  MUTATIONS  ==================== //

  mutations: {
    /**
     * Set the switch of a given 'key' to a given value
     * @param {Object} state
     * @param {String} key
     * @returns {void}
     */
    [SET_SWITCH](state, payload) {
      const { key, value } = payload

      state.switches = {
        ...state.switches,
        [key]: value === null || value === undefined ? true : value,
      }
    },
  },

  // ====================  ACTIONS  ==================== //

  actions: {
    /**
     * Set the switch of a given 'key' to 'true'
     * @param {Object} context
     * @param {Object} key
     */
    setSwitch(context, key) {
      const { commit } = context

      commit(SET_SWITCH, {
        key,
        value: true,
      })
    },
    /**
     * Set the switch of a given 'key' to 'false'
     * @param {Object} context
     * @param {Object} key
     */
    unsetSwitch(context, key) {
      const { commit } = context

      commit(SET_SWITCH, {
        key,
        value: false,
      })
    },
    /**
     * Toggle the switch of a given 'key'
     * @param {Object} context
     * @param {Object} key
     */
    toggleSwitch(context, key) {
      const { commit, state } = context
      const value = !state.switches[key]

      commit(SET_SWITCH, { key, value })
    },
  },
}

export default switches
