<template>
  <div :class="classes">
    <VTooltip bottom>
      <template v-slot:activator="{ on }">
        <VIcon :color="color" :size="size" :class="$style.icon" v-on="on" v-text="'mdi-circle'" />
      </template>

      {{ label }}
    </VTooltip>
  </div>
</template>

<script>
  import query from './query.gql'

  export default {
    name: 'FreelancerAvailabilityBadge',
    props: {
      /**
       * Related Freelancer resource
       * @type {Freelance}
       */
      freelancer: {
        type: Object,
        default: null,
      },
      /**
       * Sizes
       */
      xSmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      xLarge: {
        type: Boolean,
        default: false,
      },
    },
    apollo: {
      freelance: {
        query,
        variables() {
          return { id: this.freelancer.id }
        },
        update(data) {
          return data.freelance
        },
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { xSmall, small, large, xLarge, $style } = this

        return {
          [$style.root]: true,
          [$style.xSmall]: xSmall,
          [$style.small]: small,
          [$style.large]: large,
          [$style.xLarge]: xLarge,
        }
      },
      /**
       * Size mapped to a numeric px value expected by Vuetify
       * @type {Number}
       */
      size() {
        const { xSmall, small, large, xLarge } = this

        switch (true) {
          case xSmall:
            return 10
          case small:
            return 12
          case large:
            return 24
          case xLarge:
            return 32
          default:
            return 16
        }
      },
      /**
       * Color used to represent the actual availability state of this freelancer
       * @type {String}
       */
      color() {
        const { isAvailable } = this

        return isAvailable ? 'green' : 'red'
      },
      /**
       * Label displayed to give more information about why and when the freelancer
       * is, is not or would be available.
       * @type {String}
       */
      label() {
        const { freelance, isAvailable } = this
        if (!freelance) {
          return ''
        }

        const { isSameOrBeforeNow } = this.$clock
        const { format, __ } = this.$i18n

        if (isAvailable) {
          return __('cp:freelancer-availability-badge:on:label')
        }

        const availabilityDate = freelance?.availabilityDate
        const safeAvailabilityDate = isSameOrBeforeNow(availabilityDate) ? null : availabilityDate

        return safeAvailabilityDate
          ? __('cp:freelancer-availability-badge:off:label-with-date', {
              date: format('date', safeAvailabilityDate, 'longdate'),
            })
          : __('cp:freelancer-availability-badge:off:label')
      },
      /**
       * Whether or not isAvailable is true or availabilityDate is in the past
       * @returns {Boolean}
       */
      isAvailable() {
        const { freelance } = this
        if (!freelance) {
          return false
        }

        const { isSameOrBeforeNow } = this.$clock

        const isAvailable = freelance?.isAvailable
        const availabilityDate = freelance?.availabilityDate

        return !!isAvailable || isSameOrBeforeNow(availabilityDate)
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    --freelancer-availability-badge-size: 16px
    --freelancer-availability-badge-padding: 2px

    width: var(--freelancer-availability-badge-size)
    height: var(--freelancer-availability-badge-size)
    display: flex

    .icon {
      width: var(--freelancer-availability-badge-size)
      height: var(--freelancer-availability-badge-size)
      line-height: var(--freelancer-availability-badge-size)
      padding: var(--freelancer-availability-badge-padding)
      background: var(--color-white)
      border-radius: 50%
      cursor: pointer
    }

    &.xSmall {
      --freelancer-availability-badge-size: 10px
      --freelancer-availability-badge-padding: 1px
    }

    &.small {
      --freelancer-availability-badge-size: 12px
      --freelancer-availability-badge-padding: 2px
    }

    &.large {
      --freelancer-availability-badge-size: 24px
      --freelancer-availability-badge-padding: 2px
    }

    &.xLarge {
      --freelancer-availability-badge-size: 32px
      --freelancer-availability-badge-padding: 2px
    }
  }
</style>
