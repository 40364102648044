<template>
  <Banner
    class="app-banner"
    :class="positionClass"
    :to="to"
    :theme="theme"
    :closable="closable"
    @click="onPress"
    @close="onClose"
  >
    <slot />
  </Banner>
</template>

<script>
  import Banner from '@/core/layout/Banner/Banner'

  const Position = {
    TOP: 'top',
    BOTTOM: 'bottom',
  }

  export const AppBannerPosition = Position

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Banner: Banner.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme:Banner.Theme,Position,
    name: 'AppBanner',
    __enums: {
      Theme: Banner.Theme,
      Position,
    },
    components: {
      Banner,
    },
    props: {
      /**
       * Link to which the banner should lead
       */
      to: {
        type: String,
        default: null,
      },
      /**
       * Position of the banner
       */
      position: {
        type: String,
        default: AppBannerPosition.TOP,
      },
      /**
       * If 'true', the banner display a cross emiting a "close" event on click
       * Else, display nothing
       */
      closable: {
        type: Boolean,
        default: true,
      },
      /**
       * Banner theme
       * @type {Object}
       */
      theme: {
        type: String,
        default: undefined,
      },
    },
    computed: {
      /**
       * Compute AppBanner classes according to props
       * @type {Object.<Boolean>}
       */
      positionClass() {
        const { position } = this

        return {
          [`app-banner--${position}`]: true,
        }
      },
    },
    methods: {
      /**
       * Transmit the Vue-event "click" when the content is pressed
       * @returns {void}
       */
      onPress() {
        this.$emit('click')
      },
      /**
       * Transmit the Vue-event "close" when clicking the close button
       * @returns {void}
       */
      onClose() {
        this.$emit('close')
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .app-banner.banner {
    position: fixed
    z-index: 100

    &.app-banner--top {
      top: 0
    }

    &.app-banner--bottom {
      bottom: 0
    }
  }

  @media print {
    .app-banner.banner {
      display: none
    }
  }
</style>
