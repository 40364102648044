/**
 * Status of a document
 */
const UserKycStatus = {
  UPLOADED: 'uploaded',
  NOT_UPLOADED: 'notUploaded',
  UPLOAD_FAIL: 'uploadFail',
  PENDING_COMET_REVIEW: 'pendingCometReview',
  COMET_ACCEPTED: 'cometAccepted',
  COMET_REFUSED: 'cometRefused',
  EXPIRED: 'expired',
}

/**
 * Documents types
 */
const UserKycType = {
  ID: 'id',
  ID_SECONDARY: 'idSecondary',
  KBIS: 'kbis',
  BANK_ACCOUNT_DETAILS: 'bankAccountDetails',
  PROOF_OF_ADDRESS: 'proofOfAddress',
  INSEE: 'insee',
  URSSAF: 'urssaf',
  RC_PRO: 'rcPro',
  STATUSES: 'statuses',
  OWNERSLIST: 'ownersList',
}

/**
 * Possible actions to perform on document
 */
const UserKycAction = {
  UPLOAD: 'upload',
  EDIT: 'edit',
  DOWNLOAD: 'download',
  VALIDATE: 'validate',
  DELETE: 'delete',
  REFUSE: 'refuse',
}

/**
 * Reasons for kyc refusal
 */
const KYCRefusalReason = {
  EXPIRED: 'expired',
  UNREADABLE: 'unreadable',
  INCOMPLETE: 'incomplete',
  UNSIGNED: 'unsigned',
  UNOFFICIAL: 'unofficial',
  INCORRECT: 'incorrect',
  OTHER: 'other',
}

export default {
  UserKycStatus,
  UserKycType,
  UserKycAction,
  KYCRefusalReason,
}
