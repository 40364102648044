import _values from 'lodash/values'

/**
 * Available order types
 */
const MissionOrder = {
  START_DATE: 'startDate',
  CREATED_AT: 'createdAt',
  CREATED_DURATION: 'createdDuration',
  BUSINESS_VOLUME: 'businessVolume',
  TITLE: 'title',
  EXPERIENCE_LEVEL: 'experienceLevel',
  LOCATION: 'location',
  DAYS_PER_WEEK: 'daysPerWeek',
  DURATION_IN_DAYS: 'durationInDays',
  FREELANCE: 'freelance',
  HUNTING_PRIORITY_SCORE: 'huntingPriorityScore',
  COMPANY: 'company',
  ESTIMATED_END_DATE: 'estimatedEndDate',
}

/**
 * Available order types
 */
const MissionOrderEnterprise = {
  DATE: 'date',
  STATUS: 'status',
}

/**
 * Category of the expertise category required
 */
const MissionCategory = {
  DATA: 'data',
  TECH: 'tech',
}

/**
 * Status of a mission
 */
const MissionStatus = {
  NOT_FINALIZED: 'notFinalized',
  TO_APPROVE: 'toApprove',
  APPROVAL_DECLINED: 'approvalDeclined',
  TO_VALIDATE: 'toValidate',
  REFUSED: 'refused',
  SEEKING: 'seeking',
  FAILED: 'failed',
  QUOTATION: 'quotation',
  ON_GOING: 'onGoing',
  FINISHED: 'finished',
  REVIEWED: 'reviewed',
}

/**
 * Status of an ongoing mission
 */
const MissionOngoingStatus = {
  ON_GOING: 'ongoing',
  RENEWING: 'renewing',
  ENDING: 'ending',
}

/**
 * Global status of a mission
 */
const MissionGlobalStatus = {
  MISSION_REVIEWED: 'missionReviewed',
  MISSION_FINISHED_WITH_ONE_PENDING_EVALUATION: 'missionFinishedWithOnePendingEvaluation',
  MISSION_ONGOING_CLOSURE_PROCESS_FINALIZED: 'missionOngoingClosureProcessFinalized',
  MISSION_ONGOING_CLOSURE_PROCESS_STARTED: 'missionOngoingClosureProcessStarted',
  DEAL_EXT_SIGNING: 'dealExtSigning',
  DEAL_EXT_AGREED: 'dealExtAgreed',
  DEAL_EXT_TERMS_SENT: 'dealExtTermsSent',
  DEAL_EXT_CREATED: 'dealExtCreated',
  MISSION_ON_GOING: 'missionOnGoing',
  DEAL_SIGNING: 'dealSigning',
  DEAL_AGREED: 'dealAgreed',
  DEAL_TERMS_SENT: 'dealTermsSent',
  DEAL_CREATED: 'dealCreated',
  MATCHING_MEETING: 'matchingMeeting',
  MATCHING_SLOTS: 'matchingSlots',
  MATCHING_CLIENT_FURTHERED: 'matchingClientFurthered',
  MATCHING_SELECTION_SENT: 'matchingSelectionSent',
  MATCHING_ONE_PLUS_CANDIDATE: 'matchingOnePlusCandidate',
  MATCHING_MATCH_POST_MEETING_CONFIRMED: 'matchingMatchPostMeetingConfirmed',
  MATCHING: 'matching',
  MISSION_REFUSED: 'missionRefused',
  MISSION_TO_VALIDATE: 'missionToValidate',
  MISSION_APPROVAL_DECLINED: 'missionApprovalDeclined',
  MISSION_TO_APPROVE: 'missionToApprove',
  MISSION_NOT_FINALIZED: 'missionNotFinalized',
  MISSION_KYC_CLIENT_PENDING: 'missionKycClientPending',
  MISSION_KYC_FREELANCE_PENDING: 'missionKycFreelancePending',
  MISSION_INVOICING_CLIENT_PENDING: 'missonInvoicingClientPending',
  MISSION_FAILED: 'missionFailed',
}

/**
 * Unfulfilled conditions to apply to a mission or a match
 */
const MissionApplyConditions = {
  HAS_APPLIED: 'hasApplied',
  INVALID_FREELANCE_STATE: 'invalidFreelanceState',
  INVALID_MISSION_MATCH_STATE: 'invalidMissionMatchState',
  INVALID_MISSION_STATE: 'invalidMissionState',
}

/**
 * Mission filters
 */
const MissionFilter = {
  ...MissionStatus,
  SEEKING_TO_HUNT: 'seekingToHunt',
  RENEWAL: 'renewal',
  CLOSING: 'closing',
  IN_PROGRESS: 'inProgress',
}

/**
 * Groups of missions (according to their status)
 */
const MissionMode = {
  ALL: 'all',
  ARCHIVED: 'archived',
  CARE: 'care',
  DRAFT: 'draft',
  MATCHING: 'matching',
  ONGOING: 'ongoing',
  VALIDATION: 'validation',
}

/**
 * Relevant mode of the mission for the corporate
 */
const CorporateMissionMode = {
  ALL: 'all',
  DEPOSIT: 'deposit',
  CONTRACTED: 'contracted',
  ARCHIVED: 'archived',
}

/**
 * Category (group) of missions (according to their status)
 */
const MissionStatusByCategory = {
  [MissionMode.ALL]: _values(MissionStatus),
  [MissionMode.VALIDATION]: [MissionStatus.TO_VALIDATE],
  [MissionMode.MATCHING]: [MissionStatus.SEEKING],
  [MissionMode.CARE]: [MissionStatus.QUOTATION],
  [MissionMode.ONGOING]: [MissionStatus.ON_GOING],
  [MissionMode.ARCHIVED]: [
    MissionStatus.APPROVAL_DECLINED,
    MissionStatus.REFUSED,
    MissionStatus.FAILED,
    MissionStatus.FINISHED,
    MissionStatus.REVIEWED,
  ],
  [MissionMode.DRAFT]: [MissionStatus.NOT_FINALIZED],
}

/**
 * Category (group) of missions relevant for the corporate
 */
const MissionStatusByCorporateCategory = {
  [CorporateMissionMode.ALL]: _values(MissionStatus),
  [CorporateMissionMode.DEPOSIT]: [
    MissionStatus.TO_APPROVE,
    MissionStatus.TO_VALIDATE,
    MissionStatus.SEEKING,
    MissionStatus.QUOTATION,
  ],
  [CorporateMissionMode.CONTRACTED]: [MissionStatus.ON_GOING],
  [CorporateMissionMode.ARCHIVED]: [
    MissionStatus.APPROVAL_DECLINED,
    MissionStatus.REFUSED,
    MissionStatus.FAILED,
    MissionStatus.FINISHED,
    MissionStatus.REVIEWED,
  ],
}

/**
 * Assignation status of a mission
 */
const MissionAssignationStatus = {
  NOT_ASSIGNED: 'notAssigned',
  TEMPORARILY_ASSIGNED: 'temporarilyAssigned',
  PERMANENTLY_ASSIGNED: 'permanentlyAssigned',
}

/**
 * Method to send the invoices
 */
const InvoiceSendMethod = {
  NONE: null,
  EMAIL: 'email',
  MAIL: 'mail',
}

/**
 * Reasons for mission refusal
 */
const MissionRefusalReason = {
  OUT_OF_SCOPE: 'outOfScope',
  SSII: 'ssii',
  UNREACHABLE: 'unreachable',
  LOW_VALUE: 'lowValue',
  FAKE: 'fake',
  FOREIGN_COMPANY: 'foreignCompany',
  OTHER: 'other',
}

/**
 * Reasons why a TeamMember fails a mission
 */
const MissionFailureReason = {
  NO_NEWS: 'noNews',
  AN_OTHER_SOLUTION: 'anOtherSolution',
  STAND_BY: 'standBy',
  BUDGET_ISSUE: 'budgetIssue',
  CANT_FIND_FREE: 'cantFindFree',
  OTHER_SOLUTION_COMPETITOR: 'otherSolutionCompetitor',
  OTHER_SOLUTION_FIXED_CONTRACT: 'otherSolutionFixedContract',
  OTHER_SOLUTION_AGENCY: 'otherSolutionAgency',
  OTHER_SOLUTION_SSII: 'otherSolutionSsii',
  OTHER_SOLUTION_INTERNAL: 'otherSolutionInternal',
  OTHER_SOLUTION_NETWORK: 'otherSolutionNetwork',
  UNSATISFIED: 'unsatisfied',
  FREE_ANOTHER_MISSION: 'freeAnotherMission',
  NON_SPECIFIED: 'nonSpecified',
}

const MissionFailureReasonByTeamMember = {
  MATCHING_COMET_WONT_FIND_SOLUTION: 'matchingCometWontFindSolution',
  MATCHING_COMET_CANT_REACH_CLIENT: 'matchingCometCantReachClient',
  MATCHING_CORPO_FOUND_SOLUTION_WITH_COMPETITION: 'matchingCorpoFoundSolutionWithCompetition',
  MATCHING_CORPO_FOUND_SOLUTION_INTERNALY: 'matchingCorpoFoundSolutionInternaly',
  MATCHING_CORPO_OFFER_CANCELED: 'matchingCorpoOfferCanceled',
  MATCHING_CORPO_OFFER_HAS_CHANGED: 'matchingCorpoOfferHasChanged',
  PROCESS_COMET_NO_MORE_SOLUTION_AVAILABLE: 'processCometNoMoreSolutionAvailable',
  PROCESS_COMET_CANT_REACH_CLIENT: 'processCometCantReachClient',
  PROCESS_COMET_DEPRIORITIZED_INTERNALLY: 'processCometDeprioritizedInternally',
  PROCESS_CORPO_FOUND_SOLUTION_WITH_COMPETITION: 'processCorpoFoundSolutionWithCompetition',
  PROCESS_CORPO_OFFER_CANCELED: 'processCorpoOfferCanceled',
  PROCESS_CORPO_OFFER_HAS_CHANGED: 'processCorpoOfferHasChanged',
  INTERVIEW_COMET_NO_MORE_SOLUTION_AVAILABLE: 'interviewCometNoMoreSolutionAvailable',
  INTERVIEW_COMET_CANT_REACH_CLIENT: 'interviewCometCantReachClient',
  INTERVIEW_COMET_DEPRIORITIZED_INTERNALLY: 'interviewCometDeprioritizedInternally',
  INTERVIEW_CLIENT_FOUND_SOLUTION_WITH_COMPETITION: 'interviewClientFoundSolutionWithCompetition',
  INTERVIEW_CLIENT_OFFER_CANCELED: 'interviewClientOfferCanceled',
  INTERVIEW_CLIENT_OFFER_HAS_CHANGED: 'interviewClientOfferHasChanged',
  CONTRACT_COMET_CANT_AGREE_ON_CONTRACT_TERMS: 'contractCometCantAgreeOnContractTerms',
  CONTRACT_COMET_IMPOSSIBLE_TO_CONTRACT: 'contractCometImpossibleToContract',
  CONTRACT_FREELANCE_CANT_AGREE_ON_CONTRACT_TERMS: 'contractFreelanceCantAgreeOnContractTerms',
  CONTRACT_FREELANCE_OTHER_MISSION_CONTRACTED: 'contractFreelanceOtherMissionContracted',
  CONTRACT_CORPO_CANT_AGREE_ON_CONTRACT_TERMS: 'contractCorpoCantAgreeOnContractTerms',
  CONTRACT_CORPO_OFFER_CANCELED: 'contractCorpoOfferCanceled',
}

const MissionFailureReasonByCorporate = {
  CONTRACT_CORPO_CANT_AGREE_ON_CONTRACT_TERMS: 'contractCorpoCantAgreeOnContractTerms',
  CONTRACT_CORPO_OFFER_CANCELED: 'contractCorpoOfferCanceled',
  INTERVIEW_CLIENT_FOUND_SOLUTION_WITH_COMPETITION: 'interviewClientFoundSolutionWithCompetition',
  INTERVIEW_CLIENT_OFFER_CANCELED: 'interviewClientOfferCanceled',
  INTERVIEW_CLIENT_OFFER_HAS_CHANGED: 'interviewClientOfferHasChanged',
  PROCESS_CORPO_FOUND_SOLUTION_WITH_COMPETITION: 'processCorpoFoundSolutionWithCompetition',
  PROCESS_CORPO_OFFER_CANCELED: 'processCorpoOfferCanceled',
  PROCESS_CORPO_OFFER_HAS_CHANGED: 'processCorpoOfferHasChanged',
  MATCHING_CORPO_FOUND_SOLUTION_WITH_COMPETITION: 'matchingCorpoFoundSolutionWithCompetition',
  MATCHING_CORPO_FOUND_SOLUTION_INTERNALY: 'matchingCorpoFoundSolutionInternaly',
  MATCHING_CORPO_OFFER_CANCELED: 'matchingCorpoOfferCanceled',
  MATCHING_CORPO_OFFER_HAS_CHANGED: 'matchingCorpoOfferHasChanged',
}

/**
 * Mission rating of the freelancer
 */
const FreelancerPeriodicRating = {
  GOOD: 'good',
  AVERAGE: 'average',
  BAD: 'bad',
}

/**
 * Existing legal models for a mission (basically "new" or "ex")
 */
const LegalModel = {
  NEW: 'new',
  EX: 'ex',
}

/**
 * Different type of project a corporate can specify on a mission
 */
const ProjectType = {
  NEW: 'new',
  EXISTING: 'existing',
}

/**
 * Different of rythme that a corporate can choose for a mission
 */
const MissionRhythm = {
  FULL_TIME: 'fullTime',
  PARTIAL_TIME: 'partialTime',
}

/**
 * Different value available for a corporate about the propability to renew
 * a mission
 */
const MissionRenewable = {
  HIGH: 'high',
  POSSIBLE: 'possible',
  NONE: 'none',
}

/*
 * Factoring eligibility status of a company for a mission
 */
const FactoringEligibility = {
  OK: 'ok',
  DEBTOR_ELIGIBLE_BUT_AMOUNT_NOT_VALIDATED: 'debtorEligibleButAmountNotValidated',
  DEBTOR_NOT_ELIGIBLE: 'debtorNotEligible',
  DEBTOR_ELIGIBLE_WITH_AMOUNT_VALIDATION_PENDING: 'debtorEligibleWithAmountValidationPending',
  NOT_FOUND: 'notFound',
}

const MissionSubmissionSteps = {
  STEP_TIMING: 0,
  STEP_SKILLS: 1,
  STEP_DETAILS: 2,
  STEP_OVERVIEW: 3,
}

/**
 * Maximum and minimum maxWeeksAfterStartDate in days
 */
const MaxWeeksAfterStartDateBounds = {
  MAX: 16,
  MIN: 0,
}

/**
 * Maximum and minimum authorized duration in days
 */
const DurationInDaysBounds = {
  MAX: 720,
  MIN: 1,
}

/**
 * Corporate commission applied to missions by default
 */
const DEFAULT_CORPORATE_COMMISSION = 0.15

/**
 * Default corporate flat fee
 */
const DEFAULT_CORPORATE_FLAT_FEE = 0

/**
 * Freelancer commission applied to missions by default
 */
const DEFAULT_FREELANCER_COMMISSION = 0.05

/**
 * Default freelancer flat fee
 */
const DEFAULT_FREELANCER_FLAT_FEE = 0

/*
 * Could be removed in 2023
 */
const DEFAULT_FREELANCER_FLAT_FEE_BEFORE_20220901 = 29

/**
 * Mission's working time per week
 */
const WorkingTime = {
  ONE_DAY: 1,
  TWO_DAYS: 2,
  THREE_DAYS: 3,
  FOUR_DAYS: 4,
  FULL_TIME: 5,
}

const MissingRating = {
  BOTH: 'both',
  FREELANCER: 'freelance',
  CORPORATE: 'corporate',
}

const MissionOffer = {
  BOOST: 'boost',
  CONNECT: 'connect',
  FLEET: 'fleet',
  EXCHANGE: 'exchange',
}

const AvailableMissionOffer = {
  BOOST: 'boost',
  CONNECT: 'connect',
}

const MissionOrigin = {
  DIRECT_INBOUND: 'directInbound',
  PUBLIC_INBOUND: 'publicInbound',
  EXCLUSIVE_INBOUND: 'exclusiveInbound',
  OUTBOUND: 'outbound',
  BUSINESS_PROVIDER: 'businessProvider',
}

/**
 * Mission skill levels
 */
const MissionSkillDuration = [1, 2, 3, 4, 5, 7, 10, 15]

/**
 * Search active filters: Sourcing tab
 */
const MissionSourcingActiveFilters = {
  status: {
    type: 'all',
  },
  preUser: {
    type: 'all',
  },
  mission_matches: {
    type: 'all',
  },
  wished_skills: {
    type: 'all',
  },
  skills: {
    type: 'all',
  },
  availability: {
    type: 'all',
  },
  retribution: {
    type: 'all',
  },
  location: {
    type: 'all',
  },
  prefWorkplace: {
    type: 'all',
  },
  prefContract: {
    type: 'all',
  },
  optInMissionEsn: {
    type: 'all',
  },
}

/**
 * Search active filters: missionCreation
 */
const MissionCreationActiveFilters = {
  status: {
    type: 'all',
  },
  preUser: {
    type: 'all',
  },
  prefContract: {
    type: 'all',
  },
  retribution: {
    type: 'all',
  },
  prefWorkplace: {
    type: 'all',
  },
  missionCreationDisjunctiveExperienceSkills: {
    type: 'all',
  },
  availability: {
    type: 'all',
  },
  optInMissionEsn: {
    type: 'all',
  },
}

/**
 * Method to send the invoices
 */
const MissionSource = {
  COMET_SERVICES: 'cometServices',
  FREELANCE_INFO: 'freelanceInfo',
}

/**
 * Root MissionTypes object
 */
const MissionTypes = {
  CorporateMissionMode,
  DEFAULT_CORPORATE_COMMISSION,
  DEFAULT_CORPORATE_FLAT_FEE,
  DEFAULT_FREELANCER_COMMISSION,
  DEFAULT_FREELANCER_FLAT_FEE,
  DEFAULT_FREELANCER_FLAT_FEE_BEFORE_20220901,
  DurationInDaysBounds,
  MaxWeeksAfterStartDateBounds,
  FactoringEligibility,
  FreelancerPeriodicRating,
  InvoiceSendMethod,
  LegalModel,
  MissionApplyConditions,
  MissingRating,
  MissionCategory,
  MissionFailureReason,
  MissionFailureReasonByCorporate,
  MissionFailureReasonByTeamMember,
  MissionFilter,
  MissionMode,
  MissionOrder,
  MissionOrderEnterprise,
  MissionRefusalReason,
  MissionRenewable,
  MissionRhythm,
  MissionSkillDuration,
  MissionStatus,
  MissionOngoingStatus,
  MissionStatusByCategory,
  MissionStatusByCorporateCategory,
  MissionGlobalStatus,
  MissionSubmissionSteps,
  MissionAssignationStatus,
  ProjectType,
  WorkingTime,
  MissionOffer,
  AvailableMissionOffer,
  MissionOrigin,
  MissionSourcingActiveFilters,
  MissionCreationActiveFilters,
  MissionSource,
}

export default MissionTypes
