import Vue from 'vue'

/**
 * Register all application modals globally
 */
export function registerModals() {
  // app
  Vue.component('FeedbackModal', () => import('@/modals/app/FeedbackModal/FeedbackModal'))
  Vue.component('OutdatedVersionModal', () =>
    import('@/modals/app/OutdatedVersionModal/OutdatedVersionModal'),
  )

  // backoffice
  Vue.component('ActionSnoozeModal', () =>
    import('@/modals/backoffice/ActionSnoozeModal/ActionSnoozeModal'),
  )
  Vue.component('AddWorklogModal', () =>
    import('@/modals/backoffice/AddWorklogModal/AddWorklogModal'),
  )
  Vue.component('CompanyValidationRefusalModal', () =>
    import('@/modals/backoffice/CompanyValidationRefusalModal/CompanyValidationRefusalModal'),
  )
  Vue.component('QualificationReviewDoubtModal', () =>
    import('@/modals/backoffice/QualificationReviewDoubtModal/QualificationReviewDoubtModal'),
  )
  Vue.component('ResetMatchesModal', () =>
    import('@/modals/backoffice/ResetMatchesModal/ResetMatchesModal'),
  )
  Vue.component('WorklogsRemindModal', () =>
    import('@/modals/backoffice/WorklogsRemindModal/WorklogsRemindModal'),
  )
  Vue.component('WorklogsRequestModal', () =>
    import('@/modals/backoffice/WorklogsRequestModal/WorklogsRequestModal'),
  )
  Vue.component('CandidatesPresentationModal', () =>
    import('@/modals/backoffice/CandidatesPresentationModal/CandidatesPresentationModal'),
  )
  Vue.component('GenerateMissionLinkModal', () =>
    import('@/modals/backoffice/GenerateMissionLinkModal/GenerateMissionLinkModal'),
  )

  // common
  Vue.component('InformativeModal', () =>
    import('@/modals/common/InformativeModal/InformativeModal'),
  )
  Vue.component('ConfirmationModal', () =>
    import('@/modals/common/ConfirmationModal/ConfirmationModal'),
  )

  // company
  Vue.component('KycDocumentRefusalModal', () =>
    import('@/modals/company/KycDocumentRefusalModal/KycDocumentRefusalModal'),
  )
  Vue.component('KycDocumentUploadModal', () =>
    import('@/modals/company/KycDocumentUploadModal/KycDocumentUploadModal'),
  )

  // deal
  Vue.component('DealDocumentUploadModal', () =>
    import('@/modals/deal/DealDocumentUploadModal/DealDocumentUploadModal'),
  )
  Vue.component('DeclineRenewalModal', () =>
    import('@/modals/deal/DeclineRenewalModal/DeclineRenewalModal'),
  )

  // enterprise
  Vue.component('EnterpriseAcceptTermsModal', () =>
    import('@/modals/enterprise/EnterpriseAcceptTermsModal/EnterpriseAcceptTermsModal'),
  )
  Vue.component('EditCorporateModal', () =>
    import('@/modals/enterprise/EditCorporateModal/EditCorporateModal'),
  )
  Vue.component('InviteCorporateModal', () =>
    import('@/modals/enterprise/InviteCorporateModal/InviteCorporateModal'),
  )
  Vue.component('ReworkWithFreelancerModal', () =>
    import('@/modals/enterprise/ReworkWithFreelancerModal/ReworkWithFreelancerModal'),
  )
  Vue.component('MaybeWrongEnvModal', () =>
    import('@/modals/enterprise/MaybeWrongEnvModal/MaybeWrongEnvModal'),
  )

  // follow
  Vue.component('FollowUpDisapproveModal', () =>
    import('@/modals/follow/FollowUpDisapproveModal/FollowUpDisapproveModal'),
  )

  // freelancer
  Vue.component('AcceptTermsModal', () =>
    import('@/modals/freelancer/AcceptTermsModal/AcceptTermsModal'),
  )
  Vue.component('ApplyToMatchedMissionModal', () =>
    import('@/modals/freelancer/ApplyToMatchedMissionModal/ApplyToMatchedMissionModal'),
  )
  Vue.component('ApplyToUnmatchedMissionModal', () =>
    import('@/modals/freelancer/ApplyToUnmatchedMissionModal/ApplyToUnmatchedMissionModal'),
  )
  Vue.component('CoverLetterModal', () =>
    import('@/modals/freelancer/CoverLetterModal/CoverLetterModal'),
  )
  Vue.component('DeclineMissionModal', () =>
    import('@/modals/freelancer/DeclineMissionModal/DeclineMissionModal'),
  )
  Vue.component('DeleteFreelancerModal', () =>
    import('@/modals/freelancer/DeleteFreelancerModal/DeleteFreelancerModal'),
  )
  Vue.component('ExperienceDescriptionModal', () =>
    import('@/modals/freelancer/ExperienceDescriptionModal/ExperienceDescriptionModal'),
  )

  Vue.component('LanguagesModal', () => import('@/modals/freelancer/LanguagesModal/LanguagesModal'))
  Vue.component('ExperienceReferentModal', () =>
    import('@/modals/freelancer/ExperienceReferentModal/ExperienceReferentModal'),
  )
  Vue.component('FreelanceDocumentUploadModal', () =>
    import('@/modals/freelancer/FreelanceDocumentUploadModal/FreelanceDocumentUploadModal'),
  )
  Vue.component('InstructorInterviewModal', () =>
    import('@/modals/freelancer/InstructorInterviewModal/InstructorInterviewModal'),
  )
  Vue.component('JobTitleEditionModal', () =>
    import('@/modals/freelancer/JobTitleEditionModal/JobTitleEditionModal'),
  )
  Vue.component('RequestMissingQualificationSkillModal', () =>
    import(
      '@/modals/freelancer/RequestMissingQualificationSkillModal/RequestMissingQualificationSkillModal'
    ),
  )
  Vue.component('WelcomeToCommunityModal', () =>
    import('@/modals/freelancer/WelcomeToCommunityModal/WelcomeToCommunityModal'),
  )
  Vue.component('QualificationBookingConfirmationModal', () =>
    import(
      '@/modals/freelancer/QualificationBookingConfirmationModal/QualificationBookingConfirmationModal'
    ),
  )
  Vue.component('FreelancerAvailabilityModal', () =>
    import('@/modals/freelancer/FreelancerAvailabilityModal/FreelancerAvailabilityModal'),
  )
  Vue.component('FreelancerProfilePreferencesModal', () =>
    import(
      '@/modals/freelancer/FreelancerProfilePreferencesModal/FreelancerProfilePreferencesModal'
    ),
  )

  // match
  Vue.component('DuplicateMissionWithMatchModal', () =>
    import('@/modals/match/DuplicateMissionWithMatchModal/DuplicateMissionWithMatchModal'),
  )
  Vue.component('PostMeetingRefusalFreelancerModal', () =>
    import('@/modals/match/PostMeetingRefusalFreelancerModal/PostMeetingRefusalFreelancerModal'),
  )
  Vue.component('PostMeetingRefusalModal', () =>
    import('@/modals/match/PostMeetingRefusalModal/PostMeetingRefusalModal'),
  )
  Vue.component('PreMeetingRefuseModal', () =>
    import('@/modals/match/PreMeetingRefuseModal/PreMeetingRefuseModal'),
  )
  Vue.component('ProcessCandidateModal', () =>
    import('@/modals/match/ProcessCandidateModal/ProcessCandidateModal'),
  )
  Vue.component('RefuseMatchModal', () =>
    import('@/modals/match/RefuseMatchModal/RefuseMatchModal'),
  )
  Vue.component('CancelMatchModal', () =>
    import('@/modals/match/CancelMatchModal/CancelMatchModal'),
  )
  Vue.component('AccountManagerInsightsModal', () =>
    import('@/modals/match/AccountManagerInsightsModal/AccountManagerInsightsModal'),
  )
  Vue.component('HighlightExperienceReasonModal', () =>
    import('@/modals/match/HighlightExperienceReasonModal/HighlightExperienceReasonModal'),
  )

  // mission
  Vue.component('CancelMissionModal', () =>
    import('@/modals/mission/CancelMissionModal/CancelMissionModal'),
  )
  Vue.component('CorporateMissionReviewModal', () =>
    import('@/modals/mission/CorporateMissionReviewModal/CorporateMissionReviewModal'),
  )
  Vue.component('FreelancerMissionReviewModal', () =>
    import('@/modals/mission/FreelancerMissionReviewModal/FreelancerMissionReviewModal'),
  )
  Vue.component('MissionFailModal', () =>
    import('@/modals/mission/MissionFailModal/MissionFailModal').then(d => d.default),
  )
  Vue.component('MissionRefusalModal', () =>
    import('@/modals/mission/MissionRefusalModal/MissionRefusalModal'),
  )
  Vue.component('ContactModal', () => import('@/modals/mission/ContactModal/ContactModal'))
  Vue.component('AttachmentsCreationModal', () =>
    import('@/modals/backoffice/AttachmentsCreationModal/AttachmentsCreationModal'),
  )
  Vue.component('MissionConnectModal', () =>
    import('@/modals/mission/MissionConnectModal/MissionConnectModal'),
  )

  Vue.component('BackofficeMissionPipelineApplyModal', () =>
    import(
      '@/modals/backoffice/missionPipeline/BackofficeMissionPipelineApplyModal/BackofficeMissionPipelineApplyModal'
    ),
  )
  Vue.component('BackofficeMissionPipelineProposalModal', () =>
    import(
      '@/modals/backoffice/missionPipeline/BackofficeMissionPipelineProposalModal/BackofficeMissionPipelineProposalModal'
    ),
  )
  Vue.component('BackofficeMissionPipelineProcessModal', () =>
    import(
      '@/modals/backoffice/missionPipeline/BackofficeMissionPipelineProcessModal/BackofficeMissionPipelineProcessModal'
    ),
  )
  Vue.component('BackofficeMissionPipelineMeetingModal', () =>
    import(
      '@/modals/backoffice/missionPipeline/BackofficeMissionPipelineMeetingModal/BackofficeMissionPipelineMeetingModal'
    ),
  )
  Vue.component('BackofficeMissionPipelineConfirmModal', () =>
    import(
      '@/modals/backoffice/missionPipeline/BackofficeMissionPipelineConfirmModal/BackofficeMissionPipelineConfirmModal'
    ),
  )
  Vue.component('BackofficeMissionPipelineArchiveModal', () =>
    import(
      '@/modals/backoffice/missionPipeline/BackofficeMissionPipelineArchiveModal/BackofficeMissionPipelineArchiveModal'
    ),
  )
}
