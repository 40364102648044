<template>
  <svg :class="classes" :width="width" :height="height" v-html="svg" />
</template>

<script>
  import { loadSvg } from '@/utils/image'

  const Speed = {
    FAST: 'fast',
    MEDIUM: 'medium',
    SLOW: 'slow',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Speed,
    name: 'Illustration',
    __enums: {
      Speed,
    },
    props: {
      /**
       * Should the animation be running
       */
      animate: {
        type: Boolean,
        default: true,
      },
      /**
       * Animation to run
       * can be:
       *   - badge
       */
      animation: {
        type: String,
        default: null,
      },
      /**
       * Speed of the animation
       */
      speed: {
        type: String,
        default: Speed.MEDIUM,
      },
      /**
       * Path from image (without the extension)
       * Ex: 'badges/community-dark' or 'illustrations/ufo'
       */
      path: {
        type: String,
        default: null,
      },
      /**
       * Width to be passed down to svg
       */
      width: {
        type: String,
        default: '160',
      },
      /**
       * Height to be passed down to svg
       */
      height: {
        type: String,
        default: '160',
      },
    },
    data() {
      return {
        /**
         * Svg content (from cache or from network)
         * @type{?String}
         */
        svg: null,
      }
    },
    computed: {
      /**
       * Dynamically computes required classes
       * @type {Object}
       */
      classes() {
        const { animate, animation, speed, svg } = this

        return {
          illustration: true,
          'illustration--active': !!animate,
          [`illustration--${animation}-animation`]: !!animation,
          [`illustration--speed-${speed}`]: !!speed,
          visible: !!svg,
        }
      },
    },
    watch: {
      path: {
        immediate: true,
        handler(path) {
          loadSvg(path).then(svg => {
            this.svg = svg
          })
        },
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_animations.styl'

  .illustration {
    // Apply animations according to dynamic classes
    &&--badge-animation {
      &&^[0]--speed-fast {
        badge-animation()
      }

      &&^[0]--speed-medium {
        badge-animation(1s)
      }

      &&^[0]--speed-slow {
        badge-animation(1.6s)
      }
    }

    // 'Visible' animation:
    // ('transiton' component has not been used in order to keep the 'width' and 'height'
    // for the empty space while loading)
    opacity: 0
    transition-property: opacity .2s

    &.visible {
      opacity: 1
    }
  }
</style>
