<template>
  <div :class="['skill-input', { 'skill-input-with-search': isOpen }]">
    <div class="skill-input-filter">
      <Multiselect
        :id="id"
        ref="skillInput"
        data-cy-freelancer-skills
        :value="value"
        :options="options"
        :multiple="true"
        :placeholder="__('cp:form:skill-input:placeholder')"
        :select-label="__('cp:form:skill-input:select-label')"
        :selected-label="__('cp:form:skill-input:selected-label')"
        :deselect-label="__('cp:form:skill-input:deselect-label')"
        :disabled="disabled"
        label="name"
        :max="max"
        track-by="id"
        :internal-search="false"
        :close-on-select="true"
        :clear-on-select="true"
        :hide-selected="false"
        :keep-focus="true"
        :preserve-search="true"
        :block-keys="['Delete']"
        class="skill-multiselect"
        :loading="fetching"
        @input="onChange"
        @remove="onMultiselectRemove"
        @search-change="newSearch => (search = newSearch)"
        @open="() => (isOpen = true)"
        @close="() => (isOpen = false)"
      >
        <template v-slot:option="{ option }">
          <div>
            <div :class="$('skill-names')">
              <Txt :size="Txt.Size.XXS" :value="option.name" />

              <div
                v-if="option.aliases && option.aliases.length"
                :class="$('skill-secondary-names')"
              >
                <Txt
                  :size="Txt.Size.XXS"
                  :theme="Txt.Theme.GREY"
                  :value="`• ${option.aliases.join(', ')}`"
                />
              </div>
            </div>

            <Txt
              v-if="option.popularity"
              :size="Txt.Size.XXXS"
              :theme="Txt.Theme.GREY"
              :value="
                __('cp:form:skill-input:option-popularity', { popularity: option.popularity })
              "
            />
          </div>
        </template>

        <span slot="noResult">
          {{ __('cp:form:skill-input:no-result:placeholder') }}
        </span>

        <template slot="afterList">
          <span
            v-if="search && fetching"
            :class="$('add-skill')"
            v-html="
              isTeamMember
                ? __('cp:form:skill-input:after-list:loading:team-member')
                : __('cp:form:skill-input:after-list:loading:users')
            "
          />
          <span
            v-else-if="search"
            :class="$('add-skill')"
            @click="onOptionSuggestion"
            v-html="__('cp:form:skill-input:after-search', { search })"
          />
        </template>

        <div slot="maxElements">
          {{ __('cp:form:skill-search:fields-maximum') }}
        </div>
      </Multiselect>

      <span v-if="error" :class="$('error')">
        {{ error }}
      </span>
    </div>

    <div :class="$('lists')">
      <SkillList
        :value="value"
        :display="inline ? SkillList.Display.INLINE : SkillList.Display.COLUMN"
        :context="context"
        :with-primary="withPrimary"
        :editable="true"
        :disabled="disabled"
        :primary-max="primaryMax"
        :size="size"
        :theme="SkillList.Theme.BLUE"
        :class="{ 'list-with-placeholder': withSkillProposition && context === 'default' }"
        @change="onChange"
        @delete="onDelete"
      />

      <SkillList
        v-if="withSkillProposition"
        :value="recommendedSkills"
        :display="SkillList.Display.INLINE"
        :editable="false"
        :disabled="disabled"
        :primary-max="primaryMax"
        :size="size"
        :theme="SkillList.Theme.PLACEHOLDER_GREY"
        :class="{ 'list-with-placeholder': withSkillProposition }"
        @add="skill => onChange([...value, skill])"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _differenceWith from 'lodash/differenceWith'
  import _orderBy from 'lodash/orderBy'

  import Multiselect from 'vue-multiselect'
  import Txt from '@/core/text/Txt/Txt'
  import SkillList from '@/core/badges/SkillList/SkillList'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import I18nMixin from '@/mixins/I18nMixin'
  import ModalMixin from '@/mixins/ModalMixin'
  import RouterMixin from '@/mixins/RouterMixin'

  import query from './query.gql'
  import recommendedSkills from './recommendedSkills.gql'

  import config from '@/config'

  const { Context, Size } = SkillList

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Multiselect: Multiselect.__enums,
  SkillList: SkillList.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Context,Size,
    name: 'SkillInput',
    __enums: {
      Context,
      Size,
    },
    components: {
      Multiselect,
      SkillList,
      Txt,
    },
    mixins: [ApolloMixin, I18nMixin, ModalMixin, RouterMixin],
    props: {
      /**
       * Unique identifier used to match the input and peer it with its label
       * (randomly generated if null or undefined)
       */
      id: {
        type: String,
        default: null,
      },
      /**
       * List of selected skills
       * @type {Array.<Object>}
       */
      value: {
        type: Array,
        default: () => [],
      },
      /**
       * List of available skills provided to the autocomplete field.
       * If it is 'null', all existing skills are fetched when initializing.
       * @type {Array.<Object>}
       */
      source: {
        type: Array,
        default: null,
      },
      /**
       * If 'true', the skill list is displayed inline
       */
      inline: {
        type: Boolean,
        default: true,
      },
      /**
       * Define in which context this skill input is used
       */
      context: {
        type: String,
        default: Context.DEFAULT,
      },
      /**
       * If present, each skill will display a checkbox allowing to
       * make a skill mandatory or not
       */
      withPrimary: {
        type: Boolean,
        default: true,
      },
      /**
       * Disable the input if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Error message to display below the input
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Maximum number of skills one can select
       * before disabling the multiselect
       */
      max: {
        type: Number,
        default: null,
      },
      /**
       * Maximum number of primary skills that can be selected before removing
       * the primary checkbox
       */
      primaryMax: {
        type: Number,
        default: null,
      },
      /**
       * Size applied on each skill
       * Can be: "small", "medium", "big"
       */
      size: {
        type: String,
        default: null,
      },
      /**
       * If 'true', the user can suggest a skill
       */
      suggestion: {
        type: Boolean,
        default: true,
      },
      /**
       * Allow to go on previous route when suggesting a skill
       */
      backOnSuggest: {
        type: Boolean,
        default: false,
      },
      /**
       * Display or not the proposed skills
       */
      withSkillProposition: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        /**
         * List of all the existing skills (fetched by Apollo)
         * @type {Array.<Object>}
         */
        skills: [],
        /**
         * List of recommended skills
         * @type {Array.<Object>}
         */
        recommendedSkills: [],
        /**
         * Search typed by the user in the input
         * @type {String}
         */
        search: '',
        /**
         * Is the input filter open or not
         * @type {Boolean}
         */
        isOpen: false,
      }
    },
    apollo: {
      skills: {
        query,
        variables() {
          return { search: this.search }
        },
        update(data) {
          return data && data.searchSkills ? [...data.searchSkills] : []
        },
        fetchPolicy: 'network-only',
        debounce: 100,
      },
      recommendedSkills: {
        query: recommendedSkills,
        fetchPolicy: 'network-only',
        variables() {
          return { skills: this.value.map(s => s.id) }
        },
        update(data) {
          return data && data.recommendedSkills
            ? data.recommendedSkills.map(s => ({ ...s, action: 'add' }))
            : []
        },
      },
    },
    computed: {
      ...mapGetters({
        isTeamMember: 'isTeamMember',
        user: 'selectUser',
      }),
      /**
       * Options passed to the multiselect components according to skills fetched
       * and what the user already typed
       * @type {Array.<Object>}
       */
      options() {
        const { search, skills, recommendedSkills } = this

        if (skills.length) {
          return skills
        }

        // If the user already type something in the search we don't want the
        // recommended skills, instead return empty array and propose to add the
        // written skill
        if (search) {
          return []
        }

        return recommendedSkills
      },
    },
    methods: {
      /**
       * Fired when a skill is added or moved in the list.
       * @param {Object} next
       * @returns {void}
       */
      onChange(next) {
        const { value: prev, withPrimary } = this

        // Clean the skills list everytime the user picked one
        this.skills = []
        let result = next || []

        // Diff will contain all elements in 'next' that are not
        // present in 'prev'
        const diff = _differenceWith(next, prev || [], (a, b) => a.id === b.id)

        if (withPrimary) {
          // Ensure that every items has a `primary` property in order to be
          // well ordered then
          result = result.map(s => ({
            ...s,
            primary: !!s.primary,
          }))
        }

        // If we just add a new item ...
        if (diff && diff.length) {
          // ... and there is less than primaryMax or by default,
          // we force the `primary` on them
          const primaries = result.filter(s => s.primary)

          if (withPrimary && primaries.length < 2) {
            result = result.map(s => (s.id === diff[0].id ? { ...s, primary: true } : s))
          }

          this.$emit('add', diff[0])
        }

        this.$emit('input', _orderBy(result, 'primary', 'desc'), prev)
      },
      /**
       * Fired when a tag closing cross is clicked
       * @param {Object} item
       * @param {Number} index
       * @returns {void}
       */
      onDelete(item, index) {
        this.$emit('remove', item, index)
      },
      /**
       * Fired when an item is removed from the multiselect
       * @param {Object} item
       * @returns {void}
       */
      onMultiselectRemove(item) {
        const { value } = this

        // We clone the skillList and remove the skill
        const index = value.findIndex(s => s.id === item.id)
        const newValue = value.slice()
        newValue.splice(index, 1)

        // We mimic the same flow as when a skill is deleted from the skillList
        // (change the skillList then emit the info)
        this.onChange(newValue)
        this.onDelete(item, index)
      },
      /**
       * Triggered when the user suggests a new skill to add
       * @param {String} skillName
       * @returns {Object}
       */
      async onOptionSuggestion() {
        const { user } = this
        const addSkillUrl = config.urls.addSkill
          .replace('{userId}', user.id)
          .replace('{userEmail}', user.email)
          .replace('{origin}', window.location.href)

        window.open(addSkillUrl, '_blank')
      },
    },
  }
</script>

<style lang="stylus">
  @import '~@/assets/css/_variables.styl'

  .skill-input {
    &-filter {
      position: relative
      margin-bottom: 16px
    }

    &-error {
      color: var(--color-input-error)
      font-size: 12px
    }

    &-lists {
      display: flex
      flex-wrap: wrap
    }

    &-add-skill {
      cursor: pointer
      padding: 8px 40px 8px 16px
      display: block
    }

    &-skill-names {
      display: flex
      margin-bottom: 2px
    }

    &-skill-secondary-names {
      margin-left: 8px
      display: flex

      *:not(:first-child) {
        margin-left: 8px
      }
    }

    .list-with-placeholder {
      &.skill-list,
      >>> .skill-list-container {
        display: contents
      }
    }

    &.skill-input-with-search {
      & .skill-multiselect.multiselect .multiselect__input {
        height: 50px
        border-radius: 12px 12px 0 0
        border-bottom: none !important
      }

      & .skill-multiselect.multiselect .multiselect__content {
        margin-top: -4px !important
        border-top: 1px solid #CBD7E4
      }
    }

    & .skill-multiselect {
      &.multiselect .multiselect__content {
        margin-top: 0px !important
        border-radius: 0px 0px 12px 12px
        border: 1px solid var(--color-brand)
        border-top: none
      }

      &,
      & .multiselect__input,
      & .multisellect__single {
        font-family: inherit
        font-size: 16px
        touch-action: manipulation
        color: var(--color-font)
        appearance: none
      }

      &-suggestion-prefix {
        font-weight: $font-bold
      }

      &-no-items .multiselect__content {
        display: none !important
      }

      input:placeholder-shown:not(:hover):not(:focus) {
        background: var(--color-input-background-idle)
        border-color: var(--color-input-background-idle)
      }

      &.multiselect {
        position: relative

        &:not(.multiselect--disabled) {
          &:hover .multiselect__input {
            border-color: var(--color-input-highlight)
          }

          &.multiselect--active .multiselect__input {
            border-color: var(--color-input-highlight)
          }
        }

        .multiselect__input {
          display: block !important
          width: 100% !important
          position: relative !important
          height: 40px
          padding: 0 44px 0 16px !important
          border-radius: $radius-medium
          background-color: var(--color-background) !important
          border: solid var(--color-input-border) 1px
          font-family: $font
          font-weight: $font-regular
          font-size: 16px
          box-sizing: border-box
          box-shadow: none
          appearance: none

          &:hover {
            border-color: rgba(0, 68, 255, 0.08)
          }

          &:disabled {
            opacity: .5
            background-color: transparent
          }
        }

        .multiselect__content {
          position: absolute
          width: 100%
          max-height: 191px
          padding: 0 !important
          margin: 8px 0
          background-color: var(--color-background)
          box-sizing: border-box
          overflow-y: scroll
          z-index: 2
        }

        .multiselect__option {
          display: block
          padding: 8px 24px 8px 16px
          font-weight: $font-regular
          border-bottom: 1px solid #CBD7E4

          &:after {
            font-size: 13px
            float: right
          }

          &--highlight {
            background: rgba(0, 68, 255, 0.08)
            color: var(--color-font-contrast)

            &:after {
              position: absolute
              right: 40px
              top: 16px
              color: var(--color-brand)
            }
          }

          &--selected {
            background-color: var(--color-positive-transparent)
            color: var(--color-positive)

            &:after {
              content: attr(data-selected)
            }
          }

          &--highlight.multiselect__option--selected {
            background: var(--color-negative-transparent)
            color: var(--color-negative)

            &:after {
              content: attr(data-deselect)
            }
          }
        }

        .multiselect__element {
          position: relative
        }

        .multiselect__option--selected:after {
          bottom: 16px
          right: 40px
          position: absolute
        }

        .multiselect__tags {
          &-wrap {
            display: none
          }

          span.multiselect__single {
            display: none
          }
        }

        .multiselect__placeholder {
          display: none
          color: var(--color-input-placeholder)
        }
      }
    }
  }
</style>
