<template>
  <div :class="rootClasses">
    <VTooltip
      bottom
      :disabled="!name"
    >
      <template v-slot:activator="{ on }">
        <div
          :class="iconClasses"
          v-on="on"
        >
          <img
            v-if="flagIconUrl"
            :src="flagIconUrl"
          />
        </div>
      </template>

      <span v-text="name" />
    </VTooltip>
  </div>
</template>

<script>
  import _camelCase from 'lodash/camelCase'

  const Size = {
    XL: 'xl',
    L: 'l',
    M: 'm',
    S: 's',
    XS: 'xs',
    XXS: 'xxs',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Size,
    name: 'CIconFlag',
    __enums: {
      Size,
    },
    props: {
      /**
       * Targeted country ISO code
       * @type {String}
       */
      iso: {
        type: String,
        default: null,
      },
      /**
       * Targeted country name (displayed in a tooltip)
       * @type {String}
       */
      name: {
        type: String,
        default: null,
      },
      /**
       * Custom size applied to the flag icon
       * @type {FalgIcon.Size}
       */
      size: {
        type: String,
        default: Size.M,
      },
      /**
       * If 'true' the flag is displayed as a bubble with a 1:1 ratio
       * @type {Boolean}
       */
      rounded: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      rootClasses() {
        const { iso, size, rounded, $style } = this

        return {
          [$style.root]: true,
          [$style.rounded]: !!rounded,
          [$style[_camelCase(`size-${size}`)]]: !!size,
          [$style.placeholder]: !iso,
        }
      },
      /**
       * CSS classes applied to the icon element
       * @type {Object}
       */
      iconClasses() {
        const { iso, rounded, $style } = this

        return {
          [$style.icon]: true,
          'flag-icon': true,
          [`flag-icon-${iso?.toLowerCase()}`]: !!iso,
          'flag-icon-squared': !!rounded,   // Squared in the flag-icon lib means not rectangular
        }
      },
      /**
       * Generate the URL to the flag icon
       * @type {String}
       */
      flagIconUrl() {
        const { iso } = this

        if (!iso) {
          return null
        }

        return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/1x1/${iso.toLowerCase()}.svg`
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    display: inline-block

    .icon {
      display: block
      border-radius: 2px
    }

    .icon img {
      display: block
    }

    &.rounded .icon {
      &,
      img {
        border-radius: 50%
      }
    }

    &.placeholder .icon {
      background-color: var(--color-grey-30)
    }

    &.sizeXl .icon {
      width: 64px
      min-width: 64px
      height: 64px
      min-height: 64px
    }

    &.sizeL .icon {
      width: 48px
      min-width: 48px
      height: 48px
      min-height: 48px
    }

    &.sizeM .icon {
      width: 32px
      min-width: 32px
      height: 32px
      min-height: 32px
    }

    &.sizeS .icon {
      width: 24px
      min-width: 24px
      height: 24px
      min-height: 24px
    }

    &.sizeXs .icon {
      width: 20px
      min-width: 20px
      height: 20px
      min-height: 20px
    }

    &.sizeXxs .icon {
      width: 16px
      min-width: 16px
      height: 16px
      min-height: 16px
    }
  }
</style>
