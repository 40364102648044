import _isEqual from 'lodash/isEqual'
import _isPlainObject from 'lodash/isPlainObject'
import _values from 'lodash/values'

import withParams from 'vuelidate/lib/withParams'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { isValidIBAN, isValidBIC } from 'ibantools'
import {
  between,
  decimal,
  integer,
  email,
  maxLength as vuelidateMaxLength,
  minLength,
  minValue,
  numeric,
  required,
  requiredIf,
  requiredUnless,
  sameAs,
  url,
} from 'vuelidate/lib/validators'

import FreelancerTypes from '@/types/freelancer'
import MissionTypes from '@/types/mission'

const { RetributionBounds, Workplace } = FreelancerTypes
const { DurationInDaysBounds } = MissionTypes

/**
 * Remove whitespaces
 * @param {String} value
 * @returns {String}
 */
function removeWhitespaces(v) {
  return v ? v.replace(/ /g, '') : null
}

/**
 * Remove whitespaces and -
 * @param {String} value
 * @returns {String}
 */
function removeWhitespacesAndSeparator(v) {
  return v ? v.replace(/ /g, '').replace(/-/g, '') : null
}

/**
 * Basic required validator to which a custom error message can be passed
 * @param {String} error
 * @returns {Function}
 */
function requiredWithError(error) {
  return withParams(
    {
      type: 'requiredWithError',
      error,
    },
    v => required(v),
  )
}

/**
 * Max count
 * @param {Number} max
 * @returns {Function}
 */
function maxCount(max) {
  return withParams(
    {
      type: 'maxCount',
      max,
    },
    v => maxLength(max)(v),
  )
}

/**
 * Check condition with error
 * @param {Function} condition
 * @param {String} error
 * @returns {Function}
 */
function checkConditionWithError(condition, error) {
  return withParams(
    {
      type: 'checkConditionWithError',
      error,
    },
    condition,
  )
}

/**
 * Basic requiredIf validator to which a custom error message can be passed
 * @param {String} error
 * @returns {Function}
 */
function requiredIfWithError(condition, error) {
  return withParams(
    {
      type: 'requiredIfWithError',
      error,
    },
    v => requiredIf(condition)(v),
  )
}

/**
 * Password validator
 */
const password = withParams(
  {
    type: 'password',
    min: 6,
  },
  v => minLength(6)(v),
)

/**
 * Phone number validator
 */
const phoneNumber = withParams({ type: 'phoneNumber' }, value => {
  try {
    return value && value.length
      ? parsePhoneNumberFromString(value, 'FR')?.isValid() ?? false
      : true
  } catch (e) {
    return false
  }
})

/**
 * TJM validator
 */
const tjm = withParams(
  {
    type: 'tjm',
    min: 0, // In some cases we want to allow value under the usual minimum retribution
    max: RetributionBounds.MAX,
  },
  v => numeric(v) && between(0, RetributionBounds.MAX)(v),
)

/**
 * TJM with decimal validator
 */
const decimalTjm = withParams(
  {
    type: 'decimalTjm',
    min: 0, // In some cases we want to allow value under the usual minimum retribution
    max: RetributionBounds.MAX,
  },
  v => decimal(v) && between(0, RetributionBounds.MAX)(v),
)

/**
 * Check if the given value is included in the given 'type' object
 * @param {Object|Array} type
 * @returns {Function}
 */
function ofType(type) {
  const values = _isPlainObject(type) ? _values(type) : type

  return withParams(
    {
      type: 'ofType',
      values,
    },
    v => !v || values.includes(v),
  )
}

/**
 * durationInDays validator
 */
const missionDuration = withParams(
  {
    type: 'missionDuration',
    min: DurationInDaysBounds.MIN,
    max: DurationInDaysBounds.MAX,
  },
  v => between(DurationInDaysBounds.MIN, DurationInDaysBounds.MAX)(v),
)

/**
 * IBAN validator
 */
const iban = withParams({ type: 'iban' }, v => !v || isValidIBAN(removeWhitespacesAndSeparator(v)))

/**
 * PERCENTAGE validator
 */
const percentage = withParams({ type: 'percentage' }, v => decimal(v) && between(0, 100)(v))

/**
 * BIC validator
 */
const bic = withParams({ type: 'bic' }, v => !v || isValidBIC(removeWhitespaces(v)))

/**
 * Address validator requiring at least a latitude and a longitude,
 * excluding (0, 0)
 * @type {Function}
 */
const address = withParams({ type: 'address' }, v => {
  const lat = v?.point?.latitude ?? null
  const long = v?.point?.longitude ?? null

  return !v || (lat !== null && long !== null && (lat !== 0 || long !== 0))
})

/**
 * Check if the given condition is true, if not don't check the address.
 * If yes, then check the latitude and longitude
 * is defined
 * @param {Function} condition
 * @returns {Function}
 */
function addressIf(condition) {
  return withParams({ type: 'addressIf' }, v => {
    return condition() ? address(v) : true
  })
}

/**
 * Address validator requiring at least a latitude and a longitude,
 * excluding (0, 0)
 * @type {Function}
 */
const addressFull = withParams({ type: 'addressFull' }, v => {
  const isAddressComplete =
    ['street', 'city', 'zipCode'].every(f => (v?.[f] ?? null) !== null) &&
    ['country', 'countryIso'].some(f => (v?.[f] ?? null) !== null)

  return !v || (address(v) && isAddressComplete)
})

/**
 * Check if the given condition is true, if not don't check the address.
 * If yes, then check that the street, city, zipCode and country of the address
 * are defined
 * @param {Function} condition
 * @returns {Function}
 */
function addressFullIf(condition) {
  return withParams({ type: 'addressFullIf' }, v => {
    return condition() ? addressFull(v) : true
  })
}

/**
 * Siret validator
 */
const siret = withParams(
  {
    type: 'siret',
    min: 14,
    max: 14,
  },
  v =>
    !v ||
    (minLength(14)(removeWhitespaces(v)) &&
      vuelidateMaxLength(14)(removeWhitespaces(v)) &&
      numeric(removeWhitespaces(v))),
)

/**
 * VAT number validator
 */
const vatNumber = withParams(
  {
    type: 'vatNumber',
    min: 13,
    max: 13,
  },
  v => {
    if (v?.startsWith('FR')) {
      return minLength(13)(removeWhitespaces(v)) && vuelidateMaxLength(13)(removeWhitespaces(v))
    }

    return true
  },
)

/**
 * Zip code validator
 */
const zipCode = withParams(
  {
    type: 'zipCode',
    min: 5,
    max: 5,
  },
  v => numeric(v) && minLength(5)(v) && maxLength(5)(v),
)

/**
 * Remote validator
 * It looks at 'mutateErrors' to return true if the error is actually raised.
 * @param {Object} vm
 * @param {AppTypes.AppError} code
 * @returns {Function}
 */
function remote(vm, code) {
  return withParams(
    {
      type: 'remote',
      code,
    },
    () => {
      if (vm) {
        if (vm.mutateErrors && !!vm.mutateErrors[code]) {
          return false
        }
        if (vm.routeError === code && !vm.form.touched) {
          return false
        }
      }

      return true
    },
  )
}

/**
 * Validator for workplace address if workplace does not exist
 * @param  {Object} vm
 * @param  {String} field - name of the field to check
 * @returns {Function}
 */
function workplaceAddress(vm, field) {
  return withParams({ type: 'workplaceAddress' }, v => {
    if (vm) {
      return requiredIf(() => vm.form.fields[field] !== Workplace.REMOTE)(v)
    }
    return true
  })
}

/**
 * Validator for amount of days input, can be a complete or half day
 * @returns {Function}
 */
const days = withParams({ type: 'days' }, v => v % 0.5 === 0)

/**
 * Validator for amount of worked days input, value should be up to 2 decimals
 * @returns {Function}
 */
const workedDays = withParams({ type: 'workedDays' }, v => {
  if (!v) {
    return true
  }

  const decimals = v.toString().split('.')[1]

  if (!decimals) {
    return true
  }

  return decimals.length < 3
})

/**
 * Validator for social capital input, value should be up to 2 decimals
 * @returns {Function}
 */
const socialCapital = withParams({ type: 'socialCapital' }, v => {
  if (v === 0) {
    return true
  }
  return v && v === +parseFloat(v).toFixed(2)
})

/**
 * Validator for comparing user's input with a predetermined value
 * @param {String|Number|Object} - value to compare the input with
 * @returns {Function}
 */
function is(value) {
  return withParams(
    {
      type: 'is',
      value,
    },
    v => _isEqual(v, value),
  )
}

function ifIs(condition, value) {
  return withParams({ type: 'ifIs' }, v => {
    if (condition()) {
      return _isEqual(v, value)
    }
    return true
  })
}

function maxLength(max, error) {
  return withParams(
    {
      type: 'maxLength',
      error,
    },
    v => vuelidateMaxLength(max)(v),
  )
}

/**
 * Checks that the URL is a valid LinkedIn profile URL
 * @param {String} url
 * @returns {String}
 */
const linkedInUrl = withParams({ type: 'linkedInUrl' }, v => {
  // Allow null and empty string because those case are already checked by another validator
  if (v === null || v === '') {
    return true
  }
  let profileId = false
  try {
    const normalizedUrl = decodeURI(v)

    const profilePrefix = '.*linkedin.com/(in|pub|comm/in)'
    const alphaNumericAndDash = '-\\wÀ-ÖØ-öø-ÿ\\d'
    const emoji =
      '\\u{1f300}-\\u{1f5ff}\\u{1f900}-\\u{1f9ff}\\u{1f600}-\\u{1f64f}\\u{1f680}-\\u{1f6ff}' +
      '\\u{2600}-\\u{26ff}\\u{2700}-\\u{27bf}\\u{1f1e6}-\\u{1f1ff}\\u{1f191}-\\u{1f251}' +
      '\\u{1f004}\\u{1f0cf}\\u{1f170}-\\u{1f171}\\u{1f17e}-\\u{1f17f}\\u{1f18e}\\u{3030}' +
      '\\u{2b50}\\u{2b55}\\u{2934}-\\u{2935}\\u{2b05}-\\u{2b07}\\u{2b1b}-\\u{2b1c}\\u{3297}' +
      '\\u{3299}\\u{303d}\\u{00a9}\\u{00ae}\\u{2122}\\u{23f3}\\u{24c2}\\u{23e9}-\\u{23ef}\\u{25b6}\\u{23f8}-\\u{23fa}'

    const re = new RegExp(`${profilePrefix}/([${alphaNumericAndDash}${emoji}]*)`, 'iu')

    if (!re.test(normalizedUrl)) {
      return false
    }
    ;[, , profileId] = re.exec(normalizedUrl)
  } catch (err) {
    // err can be URIError or a Regexp error
    return false
  }

  return profileId && profileId.length
})

export {
  address,
  addressIf,
  addressFull,
  addressFullIf,
  between,
  days,
  decimal,
  integer,
  decimalTjm,
  email,
  bic,
  iban,
  is,
  ifIs,
  linkedInUrl,
  maxLength,
  minLength,
  minValue,
  missionDuration,
  numeric,
  ofType,
  password,
  percentage,
  phoneNumber,
  remote,
  required,
  requiredIf,
  requiredUnless,
  requiredWithError,
  maxCount,
  checkConditionWithError,
  requiredIfWithError,
  sameAs,
  siret,
  socialCapital,
  tjm,
  url,
  vatNumber,
  withParams,
  workedDays,
  workplaceAddress,
  zipCode,
}
