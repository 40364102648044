<template>
  <div :class="$style.root">
    <div
      ref="scrollZone"
      :class="$style.wrapper"
      name="scroll-zone"
    >
      <VFadeTransition>
        <VBtn
          v-show="showLeftButton"
          :class="[$style.scrollButton, $style.leftButton]"
          icon
          color="grey"
          small
          outlined
          @click="onScrollLeftClick"
        >
          <VIcon small v-text="'mdi-chevron-double-left'" />
        </VBtn>
      </VFadeTransition>

      <div :class="$style.board">
        <slot />
      </div>

      <VFadeTransition>
        <VBtn
          v-show="showRightButton"
          :class="[$style.scrollButton, $style.rightButton]"
          icon
          small
          color="grey"
          outlined
          @click="onScrollRightClick"
        >
          <VIcon small v-text="'mdi-chevron-double-right'" />
        </VBtn>
      </VFadeTransition>
    </div>
  </div>
</template>

<script>
  import _debounce from 'lodash/debounce'

  export default {
    name: 'CKanban',
    props: {
      /**
       * Name used to identify this Kanban in Vuex and conserve its current state.
       * @type {String}
       */
      name: {
        type: String,
        default: 'kanban',
      },
      /**
       * When collapsable, the CKanbanHeader shows a arrow button allowing to control
       * the visibility state.
       *
       * If "true", this CKanbanList will strictly respect the "collapsed" prop value, and
       * emit "update:collapsed" events on changes, allowing to connect a reactive property
       * easily with "collapsed.sync".
       *
       * If "false", list are always expanded.
       *
       * if "auto", this list uses the "collapsed" value at initialization, and then manage
       * a local state to keep the current expansion state.
       * @type {Boolean|String}
       */
      collapsable: {
        type: [Boolean, String],
        default: false,
      },
    },
    data() {
      return {
        showLeftButton: false,
        showRightButton: true,
      }
    },
    computed: {
      scrollZone() {
        return this.$refs.scrollZone
      },
    },
    mounted() {
      this.$refs.scrollZone.addEventListener('scroll', this.onScroll)

      this.$once('hook:beforeDestroy', () => {
        this.$refs.scrollZone.removeEventListener('scroll', this.onScroll)
      })
    },
    provide() {
      const { name, collapsable } = this

      return {
        $kanban: {
          name,
          collapsable,
        },
      }
    },
    methods: {
      onScroll: _debounce(function () {
        this.showLeftButton = this.scrollZone.scrollLeft !== 0
        this.showRightButton = this.scrollZone.scrollLeft + this.scrollZone.offsetWidth < this.scrollZone.scrollWidth
      }, 100),

      onScrollLeftClick() {
        this.scrollZone.scrollTo({
          left: 0,
          behavior: 'smooth',
        })
      },

      onScrollRightClick() {
        this.scrollZone.scrollTo({
          left: this.scrollZone.scrollWidth - this.scrollZone.offsetWidth,
          behavior: 'smooth',
        })
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    flex: 1
    width: 100%
    min-width: 100%
    max-width: 100%
    max-height: 100%
    display: flex
    flex-direction: column
    overflow: hidden
    position: relative

    .wrapper {
      flex: 1
      display: flex
      flex-direction: column
      overflow-x: auto
      overflow-y: hidden

      .scrollButton {
        position: absolute
        bottom: 14px
        z-index: 10
        background: white

        &:hover {
          background: var(--color-grey-20)
        }

        &.leftButton {
          left: 4px
        }

        &.rightButton {
          right: 4px
        }
      }

      .board {
        height: 100%
        flex: 1
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: stretch
      }
    }
  }
</style>
