import Vue from 'vue'
import URI from 'urijs'
import { camelizeKeys } from 'humps'
import _get from 'lodash/get'
import _merge from 'lodash/merge'

import config from '@/config'

/**
 * Enable or disable console logs display
 */
const LogsEnabled = config.logs.http

/**
 * Counter allowing to distinguish the requests in logs
 */
let RequestCounter = 0

/**
 * Complete eventually the endpoint with the given host URL
 * if it doesn't contains a protocol
 * @param {String} host
 * @param {String} endpoint
 * @returns {URI}
 */
function getRemoteURL(host, endpoint) {
  const url = endpoint.indexOf('://') !== -1 ? endpoint : host + endpoint

  return new URI(url)
}

/**
 * Returns 'true' if the given type is accepted
 * @param {String} type
 * @returns {Boolean}
 */
function isValidActionType(type) {
  return typeof type === 'symbol' || typeof type === 'string' || type === null || type === undefined
}

/**
 * Translate the response to JSON object
 * @param {Object} response
 * @returns {}
 */
function translateToJson(response) {
  return response
    .json()
    .then(json => ({ response, json }))
    .catch(() => ({ response, json: undefined }))
}

/**
 * Format a response object containing JSON body and header values
 */
const formatResponse = (rid, { response, json }) => {
  if (LogsEnabled) {
    console.info('>>> [%i] : (%i) ', rid, response.status, json)
  }

  if (!response.ok) {
    // Request Failure
    /* eslint-disable prefer-promise-reject-errors */
    return Promise.reject({
      error:
        json && json.errors && json.errors.length ? camelizeKeys(json.errors) : camelizeKeys(json),
    })
    /* eslint-enable prefer-promise-reject-errors */
  }

  // Request Success
  return {
    headers: response.headers,
    response: json ? camelizeKeys(json) : response,
  }
}

/**
 * Fetches an HTTP response and normalizes the result JSON.
 * This makes every HTTP response have the same shape,
 * regardless of how nested it was.
 * @param {String} url
 * @param {Boolean} authenticated
 * @param {Object} options
 * @returns {Promise}
 */
function sendRequest(url, authenticated = false, options = {}) {
  let uri = url

  // Merge default request options (headers, method, ...)
  const params = _merge(
    {},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
      withCredentials: authenticated,
      // responseType: 'json',   // Could be used instead of .json()
      // and directly set in the request params
    },
    options,
  )

  const method = params.method.toUpperCase()
  const { body } = params

  if (body) {
    // Transform body as query params in GET request
    if (method === 'GET') {
      uri = uri.query(body)
      delete params.body
    }

    // Convert body to stringed JSON
    if (
      (method === 'POST' || method === 'PATCH') &&
      params.headers['Content-Type'] === 'application/json'
    ) {
      params.body = JSON.stringify(body)
    }
  }

  params.url = uri.toString()

  if (LogsEnabled) {
    // Only in debug mode, display the request in the console
    console.info('<<< [%i] %s : ', RequestCounter, uri.toString(), params)
    RequestCounter += 1
  }

  return Vue.http(params)
    .then(translateToJson)
    .then(formatResponse.bind(null, RequestCounter))
}

/**
 * A service that interprets and execute HTTP request from specified info.
 * The "store" param is actually a store's context instance.
 * @param {Object} store
 * @param {Object} request
 * @returns {Promise}
 */
function http(store, request) {
  // Ignored if malformed action
  if (typeof request === 'undefined') {
    return null
  }

  const { types, authenticated, options } = request
  const { commit } = store
  let { endpoint } = request

  // If the endpoint is a function, execute it
  if (typeof endpoint === 'function') {
    endpoint = endpoint(store)
  }

  // endpoint must be a String
  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  // The request types must be specified
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.')
  }

  // The request types must be a String, a Symbol or null
  if (!types.every(isValidActionType)) {
    throw new Error('Expected action types to be strings or symbols.')
  }

  const [requestType, successType, failureType] = types

  // Dispatch the 'REQUEST' mutation
  if (requestType) {
    commit(requestType, request)
  }

  // Define the URL which must be reached
  const url = getRemoteURL(config.urls.api, endpoint)

  return sendRequest(url, authenticated, options)
    .then(({ response, headers }) => {
      if (successType) {
        commit(successType, {
          request,
          response,
          headers,
        })
      }

      return response
    })
    .catch(error => {
      const errorMessage = _get(error, 'body.error')

      if (failureType) {
        commit(failureType, {
          request,
          error: errorMessage || 'Something bad happened',
        })
      }

      throw error
    })
}

export default http
