<template>
  <div :class="$style.badgeTxt">
    <Illustration
      :path="`badges/${badge}`"
      animation="badge"
      :animate="animate"
      :width="null"
      :height="null"
      :class="$style.illustration"
    />

    <div :class="$style.description">
      <Txt
        v-if="text"
        :value="text"
        :size="Txt.Size.XS"
        :theme="Txt.Theme.GREY"
        :raw="true"
        :class="$style.text"
      />

      <slot name="text" />
    </div>

    <div v-if="$slots.default" :class="$style.body">
      <slot />
    </div>

    <div v-if="$slots.actions" :class="$style.actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
  import Illustration from '@/core/graphics/Illustration/Illustration'
  import Txt from '@/core/text/Txt/Txt'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Illustration: Illustration.__enums,
  Txt: Txt.__enums,
},

    name: 'BadgeTxt',
    components: {
      Illustration,
      Txt,
    },
    props: {
      /**
       * A required badge to display on the left side
       */
      badge: {
        type: String,
        required: true,
      },
      /**
       * Allows to animate the specified badge (if the svg provides animation)
       */
      animate: {
        type: Boolean,
        default: false,
      },
      /**
       * The body text displayed just next to the badge
       * Can be substituted by the "text" slot
       */
      text: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_layout.styl'

  .badgeTxt {
    display: flex
    flex-direction: row
    align-items: center
  }

  .illustration {
    width: 110px
    height: 110px
    min-width: 110px
    min-height: 110px
    margin-right: 40px
  }

  .description {
    position: relative
    display: flex
    flex-direction: column

    .text {
      max-width: 700px
      white-space: pre-line
    }
  }

  .actions {
    display: flex
    flex-direction: row
    margin-left: auto
    padding-left: 40px

    > * {
      min-width: 190px
      margin: 8px
    }
  }

  @media laptop {
    .actions {
      flex-direction: column
      padding-left: 32px
    }
  }

  @media phone-tablet {
    .badgeTxt {
      flex-wrap: wrap
    }

    .description {
      flex: 1
    }

    .actions {
      min-width: 100%
      justify-content: flex-end
      padding-top: 16px
      padding-left: 0
    }
  }

  @media phone {
    .badgeTxt {
      flex-wrap: wrap
    }

    .illustration {
      width: 80px
      height: 80px
      min-width: 80px
      min-height: 80px
      align-self: flex-start
      margin-right: 16px
    }

    .actions {
      flex-wrap: wrap
      justify-content: space-evenly

      > * {
        min-width: calc(100% - 64px)
      }
    }
  }
</style>
