<template>
  <Field
    class="search-field"
    :label="label"
    :error="error"
    :disabled="disabled"
    :helper="helper"
    :raw="raw"
  >
    <SearchSelect
      :value="value"
      :options="options"
      :placeholder="placeholder"
      :clear-on-select="clearOnSelect"
      :allow-empty="allowEmpty"
      :disabled="disabled"
      @input="onInput"
    >
      <slot slot="left" name="left" />
      <slot slot="right" name="right" />
    </SearchSelect>
  </Field>
</template>

<script>
  import Field from '@/core/layout/Field/Field'
  import SearchSelect from '@/core/inputs/SearchSelect/SearchSelect'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
  SearchSelect: SearchSelect.__enums,
},

    name: 'SearchField',
    components: {
      Field,
      SearchSelect,
    },
    props: {
      /**
       * Model value
       */
      value: {
        type: [String, Number],
        default: null,
      },
      /**
       * Available options
       * @type {Array.<Object>}
       */
      options: {
        type: Array,
        default: () => [],
      },
      /**
       * Allow to create a new options in the search select
       */
      canCreate: {
        type: Boolean,
        default: false,
      },
      /**
       * Placeholder string to display when empty
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Disable the search input
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Error if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Field's helper
       */
      helper: {
        type: String,
        default: null,
      },
      /**
       * Whether the search should be cleared when the input is focused
       */
      clearOnSelect: {
        type: Boolean,
        default: false,
      },
      /**
       * Whether it's possible to select no value
       */
      allowEmpty: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true, v-html will be used for the label values
       */
      raw: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      /**
       * Fired when the select's value changes
       */
      onInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>
