<template>
  <div class="toast">
    <div class="toast-container" :class="type">
      <i v-if="icon" class="toast-icon" :class="icon" />
      <span v-if="title" class="toast-title">
        {{ title }}
      </span>
      <span>
        <slot />
      </span>
      <button v-if="removable" type="button" class="toast-close" @click="onRemove">
        <i class="icon-remove" />
      </button>
    </div>
  </div>
</template>

<script>
  import AppTypes from '@/types/app'

  const Type = AppTypes.MessageType

  export const ToastType = Type

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Type,
    name: 'Toast',
    __enums: {
      Type,
    },
    props: {
      /**
       * Easy way to add a yellow icon on le left side of the toast.
       */
      icon: {
        type: String,
        default: null,
      },
      /**
       * Easy way to add words as bold on the left side of the toast.
       * Between the icon and the content.
       */
      title: {
        type: String,
        default: null,
      },
      /**
       * Type of the toast, defining its style
       * One of: "info", "success", "default" or "error"
       */
      type: {
        type: String,
        default: ToastType.INFO,
      },
      /**
       * Specify if the toast can be remove or not
       */
      removable: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      /**
       * Function called when the user click on the cross
       */
      onRemove() {
        this.$emit('remove')
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .toast {
    display: flex
    flex-direction: row
    justify-content: center

    @media print {
      display: none
    }

    .toast-container {
      ellipsis()
      height: 48px
      max-width: 800px
      display: flex
      flex-direction: row
      align-items: center
      font-size: 14px
      line-height: 24px
      border-radius: 24px
      padding: 12px 16px
      margin: auto
      box-sizing: border-box

      .toast-icon {
        width: 24px
        height: 24px
        font-size: 24px
        color: inherit
        margin-right: 8px
      }

      .toast-title {
        font-weight: $font-bold
        margin-right: 4px
      }

      &.info {
        color: var(--color-font-contrast)
        background: var(--color-black)

        .toast-icon {
          color: var(--color-contrast)
        }
      }

      &.default {
        color: var(--color-font-contrast)
        background: var(--color-brand)
      }

      &.success {
        color: var(--color-font-contrast)
        background: var(--color-positive)
      }

      &.error {
        background: var(--color-negative)
        color: var(--color-font-contrast)
      }

      .toast-close {
        width: 24px
        height: 24px
        padding: 0
        margin-left: 16px
        font-size: 24px
        line-height: 24px
        color: inherit
        background-color: transparent
        border: none
      }
    }
  }
</style>
