import _get from 'lodash/get'
import { detect } from 'detect-browser'

/**
 * These browsers won't trigger a modal but do a plain redirection
 */
const UNSUPPORTED_BROWSERS = ['safari', 'opera', 'ie', 'ios']

const ACTION = 'oauth-success'

const WIDTH = 600

const HEIGHT = 700

let modal = null

let interval = null

let previousUrl = null

/**
 * Open a sign-in window
 * @param {String} url
 * @param {String} name
 * @returns {Promise}
 */
async function openOAuthWindow(url, name) {
  // Some browser don't (or badly) support new windows. Just redirect them.
  const browser = detect()
  // If the browser is run by puppeeter for end2end tests on signup
  const isRunningTests = navigator.userAgent === 'PuppeteerAgent'
  if (isRunningTests || UNSUPPORTED_BROWSERS.includes(browser.name)) {
    window.location.replace(url)
    return null
  }

  const dualScreenLeft = window.screenLeft || window.screenX
  const dualScreenTop = window.screenTop || window.screenY
  const left = (window.innerWidth - WIDTH) / 2 + dualScreenLeft
  const top = (window.innerHeight - HEIGHT) / 2 + dualScreenTop
  const options = `dialog=yes, modal=yes, minimizable=no, location=no, toolbar=no, menubar=no, width=${WIDTH}, height=${HEIGHT}, top=${top}, left=${left}`

  if (modal === null || modal.closed) {
    modal = window.open(url, name, options)
    if (!modal) {
      // Plain redirection when the browser cannot open a window
      window.location.replace(url)
      return null
    }
  } else if (previousUrl !== url) {
    modal = window.open(url, name, options)
    modal.focus()
  } else {
    modal.focus()
  }

  setTimeout(() => {
    modal.opener.oauth = true
  }, 100)

  return new Promise((resolve, reject) => {
    if (interval) {
      clearInterval(interval)
    }

    // Check if modal is closed regularly
    interval = setInterval(() => {
      if (modal.closed) {
        clearInterval(interval)
        reject(new Error('Modal closed'))
      }
    }, 1000)

    window.addEventListener(
      'message',
      event => {
        const action = _get(event, 'data.action')
        const path = _get(event, 'data.path', true)
        const error = _get(event, 'data.error')

        if (action === ACTION) {
          clearInterval(interval)
          resolve(error ? `${path}?apperror=${error}` : path)
          modal.close()
        }
      },
      false,
    )

    previousUrl = url
  })
}

/**
 * When called from a sign-in window,
 * Send a redirection action to the main window
 */
function handleOAuthRedirection() {
  if (!window.opener || !window.opener.oauth) {
    return
  }

  const urlParams = new URLSearchParams(window.location.search)
  const error = urlParams.get('error')

  window.opener.postMessage({
    action: ACTION,
    path: window.location.pathname,
    error,
  })
}

export default {
  openOAuthWindow,
  handleOAuthRedirection,
}
