import createPersistedState from 'vuex-persistedstate'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _omit from 'lodash/omit'

import Storage from '@/utils/storage'

/**
 * Array of any paths to partially persist from the state.
 * @type {Array.<String>}
 */
const PERSISTED_PATHS = [
  'app.banners',
  'app.referral',
  'app.blacklist',
  'app.isFirstLogin',
  'auth',
  'layout.backoffice',
  'missions.creation',
  'syncform.apply-to-matched-mission-modal.fields',
  'syncform.apply-to-unmatched-mission-modal.fields',
  'syncform.backoffice-mission-lists.fields',
  'syncform.backoffice-company-list-view.fields',
  'syncform.backoffice-corporate-list-view.fields',
  'syncform.backoffice-freelancer-list-view-archived.fields',
  'syncform.backoffice-freelancer-list-view-new.fields',
  'syncform.backoffice-freelancer-list-view-on-mission.fields',
  'syncform.backoffice-freelancer-list-view-users.fields',
  'syncform.backoffice-freelancer-list-view.fields',
  'syncform.backoffice-invoicing-list-view.fields',
  'syncform.backoffice-mission-list-view-archived.fields',
  'syncform.backoffice-mission-list-view-care.fields',
  'syncform.backoffice-mission-list-view-matching.fields',
  'syncform.backoffice-mission-list-view-ongoing.fields',
  'syncform.backoffice-mission-list-view-validation.fields',
  'syncform.backoffice-mission-list-view.fields',
  'syncform.backoffice-signin.fields',
  'syncform.backoffice-team-list-view.fields',
  'syncform.enterprise-mission-list-view.fields',
  'syncform.enterprise-signin.fields',
  'syncform.enterprise-signup-company.fields',
  'syncform.enterprise-signup-password.fields',
  'syncform.enterprise-signup-user.fields',
  'syncform.experience-modal-new.fields',
  'syncform.experience-modal.fields',
  'syncform.freelancer-decline-mission-modal.fields',
  'syncform.freelancer-mission-review.fields',
  'syncform.team-member-mission-funnel.fields',
  'syncform.candidates-presentation-modal.fields',
  'syncform.on-going-missions-list.fields',
]

/**
 * Mutation types leading to a store saving on the local storage
 * @type {Array.<String>}
 */
const PERSISTENCE_MUTATIONS = [
  'APP_BANNERS_SET',
  'APP_BLACKLIST_SET',
  'APP_REFERRAL_CODE_SET',
  'APP_SET_IS_FIRST_LOGIN',
  'APP_BACKOFFICE_LAYOUT_UPDATE',
  'AUTH_USER_REVOKE',
  'AUTH_USER_INFO_UPDATE',
  'FORM_DESTROY',
  'FORM_UPDATE',
  'SYNC_FORM_DESTROY',
  'SYNC_FORM_UPDATE',
]

/**
 * Function that will be called to filter any mutations which will
 * trigger setState on storage eventually.
 * @param {String} mutation
 * @returns {Boolean}
 */
function filter(mutation) {
  return mutation ? PERSISTENCE_MUTATIONS.includes(mutation.type) : false
}

/**
 * Remove the 'password' values in the payload to persist
 * @param {Object} value
 * @returns {Object}
 */
function omitPassword(value) {
  return _omit(value, ['password'])
}

/**
 * Transform functions applied on the picked state fragment according
 * to the treated path, during the persistence phasis.
 * @type {Object.<Function>}
 */
const PERSISTENCE_MODIFIERS = {
  // Security reasons
  'syncform.backoffice-signin.fields': omitPassword,
  'syncform.enterprise-signin.fields': omitPassword,
  'syncform.enterprise-signup.fields': omitPassword,
  // Compatibility due to old versions
  auth: v => _omit(v, ['authenticated']),
}

/**
 * Function doing nothing, except returning the passed value 'v'
 * @param {*} v
 * @returns {*}
 */
const noop = v => v

/**
 * Function that will be called to reduce the state to persist, based
 * on the given paths.
 * @param {Object} state
 * @param {Array.<String>} paths
 * @returns {Object}
 */
function reducer(state, paths) {
  let modifier = null

  return !paths || !paths.length
    ? state
    : paths.reduce((res, path) => {
        modifier = PERSISTENCE_MODIFIERS[path] || noop

        return _set(res, path, modifier(_get(state, path)))
      }, {})
}

/**
 * Initialize how and what "vuex-persistedstate" should save and when.
 * @returns {Object}
 */
function createPersistence() {
  return createPersistedState({
    key: 'state',
    storage: Storage,
    paths: PERSISTED_PATHS,
    reducer,
    filter,
  })
}

export default createPersistence
