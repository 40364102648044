<template>
  <TextField
    v-bind="$attrs"
    autocomplete="off"
    :name="name"
    :type="isVisible ? 'text' : 'password'"
    @input="onInput"
  >
    <NavButton
      slot="right"
      :class="$style.reveal"
      :icon="isVisible ? 'eye-blocked' : 'eye'"
      :size="NavButton.Size.SMALL"
      :theme="NavButton.Theme.SECONDARY_VOID"
      :tabindex="-1"
      @click="() => (isVisible = !isVisible)"
    />
  </TextField>
</template>

<script>
  import NavButton from '@/core/controls/NavButton/NavButton'
  import TextField from '@/core/fields/TextField/TextField'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
  TextField: TextField.__enums,
},

    name: 'PasswordField',
    components: {
      NavButton,
      TextField,
    },
    inheritAttrs: false,
    props: {
      /**
       * Give a default name to the field to be easier to select
       */
      name: {
        type: String,
        default: 'password',
      },
      /**
       * Initial value of data isVisible
       */
      initialVisibility: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      const { initialVisibility } = this

      return {
        /**
         * Make the password visible or not by changing the type of input
         * (text or password)
         */
        isVisible: initialVisibility,
      }
    },
    methods: {
      /**
       * Fired when the select value changes
       * @param {Object} event
       * @returns {void}
       */
      onInput(password) {
        this.$emit('input', password)
      },
    },
  }
</script>

<style lang="stylus" module>
  .reveal {
    position: absolute
    right: 8px
    bottom: 8px
  }
</style>
