<template>
  <VThemeProvider
    :light="light"
    :dark="dark"
  >
    <div :class="classes">
      <slot />
    </div>
  </VThemeProvider>
</template>

<script>
  /**
   * Should be used only at the root of a layout component.
   * It allows to well ensure the auto-sizing of VContent according to VApp and
   * the potentially used navigation components (VNavigationDrawer, VAppBar, ...)
   */
  export default {
    name: 'CLayout',
    inject: ['theme'],
    props: {
      /**
       * Applies the light theme variant to the component.
       */
      light: {
        type: Boolean,
        default: false,
      },
      /**
       * Applies the dark theme variant to the component.
       */
      dark: {
        type: Boolean,
        default: false,
      },
      /**
       * Do not apply maximum restrictions to width and height
       */
      fluid: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { dark, fluid, theme, $style } = this

        return {
          [$style.root]: true,
          [$style.fluid]: !!fluid,
          'theme--dark': !!dark || !!(theme?.isDark),
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    min-width: 100%
    min-height: 100%
    max-width: 100%
    max-height: 100%
    display: flex
    flex-direction: column
    flex: 1 1 auto
    background-color: var(--color-background)

    &.fluid {
      min-width: auto
      min-height: auto
      max-width: none
      max-height: none
    }
  }
</style>
