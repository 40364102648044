<template>
  <VTextField
    v-bind="$attrs"
    type="number"
    :value="value"
    v-on="_omit($listeners, ['input'])"
    @input="onTextFieldInput"
  />
</template>

<script>
  import _omit from 'lodash/omit'

  import { float } from '@comet/utils'

  export default {
    name: 'CFieldNumber',
    props: {
      /**
       * Value passed to the TextField without type conversion
       * @type {Number}
       */
      value: {
        type: Number,
        default: null,
      },
    },
    methods: {
      _omit,
      /**
       * Fired when the TextField is edited
       * @param {String} text
       */
      onTextFieldInput(text) {
        const val =  text !== undefined && text !== null && text !== ''
          ? float(text)
          : null

        this.$emit('input', val)
      },
    },
  }
</script>
