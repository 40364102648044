<template>
  <div :class="classes">
    <VAvatar :size="size">
      <VImg
        v-if="url"
        class="secondary lighten-5"
        :src="url"
      />

      <VIcon
        v-else
        :size="size"
        color="secondary lighten-4"
        v-text="'mdi-account-circle'"
      />
    </VAvatar>

    <div :class="$style.badge">
      <slot name="badge" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FreelancerAvatar',
    props: {
      /**
       * Related Freelancer resource
       * @type {Freelance}
       */
      freelancer: {
        type: Object,
        default: null,
      },
      /**
       * Custom sizes applied to the avatar
       */
      xSmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      xLarge: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { xSmall, small, large, xLarge, $style } = this

        return {
          [$style.root]: true,
          [$style.xSmall]: xSmall,
          [$style.small]: small,
          [$style.large]: large,
          [$style.xLarge]: xLarge,
        }
      },
      /**
       * Size mapped to a numeric px value expected by Vuetify
       * @type {Number}
       */
      size() {
        const { xSmall, small, large, xLarge } = this

        switch (true) {
          case xSmall:
            return 24
          case small:
            return 32
          case large:
            return 72
          case xLarge:
            return 120
          default:
            return 48
        }
      },
      /**
       * Freelancer's picture URL, if defined.
       * @type {String}
       */
      url() {
        return this.freelancer?.user?.profilePictureUrl
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    --freelancer-avatar-size: 48px
    --freelancer-avatar-badge-size: 16px
    --freelancer-avatar-badge-mudge: -2px

    position: relative
    width: var(--freelancer-avatar-size)
    height: var(--freelancer-avatar-size)
    display: flex

    .badge {
      position: absolute
      bottom: var(--freelancer-avatar-badge-mudge)
      right: var(--freelancer-avatar-badge-mudge)
      width: var(--freelancer-avatar-badge-size)
      height: var(--freelancer-avatar-badge-size)
      display: flex
      align-items: center
      justify-content: center
    }

    &.xSmall {
      --freelancer-avatar-size: 24px
      --freelancer-avatar-badge-size: 10px
    }

    &.small {
      --freelancer-avatar-size: 32px
      --freelancer-avatar-badge-size: 12px
    }

    &.large {
      --freelancer-avatar-size: 72px
      --freelancer-avatar-badge-size: 24px
    }

    &.xLarge {
      --freelancer-avatar-size: 120px
      --freelancer-avatar-badge-size: 38px
    }
  }

</style>
