<script>
  /* eslint-disable camelcase */

  /**
   * Return the current value of the CSS variable identified by 'name'
   * according to the given DOM 'node'
   * @param {String} name
   * @param {Node} node (default: the component itself)
   * @return {?String}
   */
  function getProperty(name, node) {
    const { CssMixin_element } = this

    if (!CssMixin_element && !node) {
      return null
    }

    return getComputedStyle(CssMixin_element || node).getPropertyValue(name)
  }

  export default {
    data() {
      return {
        /**
         * Equivalent to `$el` once the component is mounted.
         * We need this data because `$el` is not reative, and has a value
         * only when the component is mounted.
         * @type {?Object}
         */
        CssMixin_element: null,
      }
    },
    computed: {
      /**
       * Return `getProperty` function.
       * This function will change when the component is mounted
       * because it waits for `$el`to be defined
       * @return {Function}
       */
      getCssProperty() {
        return getProperty.bind(this)
      },
    },
    mounted() {
      this.CssMixin_element = this.$el
    },
  }
</script>
