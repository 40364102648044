import Vue from 'vue'
import VueRouter from 'vue-router'

import { int } from '@/utils/transform'
import config from '@/config'

import routes from './routes'

export { default as useRouterGuards } from './guards'

/**
 * Define the scroll behavior in the whole app
 * @param {Route} to
 * @param {Route} from
 * @param {Object} savedPosition
 */
function scrollBehavior(to, from, savedPosition) {
  return savedPosition || (to && from && to.path === from.path ? null : { x: 0, y: 0 })
}

/**
 * Initiliaze a new VueRouter instance handling the whole app routing
 * @returns {Object}
 */
export default function createRouter() {
  Vue.use(VueRouter)

  // Instanciate a new router
  const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior,
  })

  /**
   * Returns the first error found in the current route's query parameters
   * @returns {Number}
   */
  router.error = () => {
    const { error } = router.currentRoute.query

    if (!error) {
      null
    }

    return int(Array.isArray(error) ? error[0] : error)
  }

  /**
   * Used to execute a push action on the router
   * @returns {Promise}
   */
  router.navigate = (location, replace = false) => {
    if (config.logs.router) {
      console.info('[Router] navigate : ', location)
    }

    // Handle 'back' location to retreive the previous view
    if (location === 'back') {
      return router.back()
    }

    if (replace) {
      return router.replace(location)
    }

    return router.push(location)
  }

  Vue.mixin({
    /**
     * Capture all clicks on the page. If the click targets a link with a data-router
     * attribute, use router navigation instead of classic browser redirection.
     * Thanks to it, you can use {olink}some text{elink} into your trads, and then
     * replace those with:
     * {
     *   olink: '<a href="/freelancer" data-router>',
     *   elink: '</a>',
     * }
     */
    mounted() {
      const { $parent, $router } = this

      if (!$parent) {
        const onElementClick = event => {
          let target = event?.target

          if (target) {
            while (
              target &&
              (target.tagName !== 'A' ||
                !target.hasAttribute('href') ||
                !target.hasAttribute?.('data-router'))
            ) {
              target = target.parentNode || null
            }

            if (target) {
              const href = target?.href || ''
              const origin = target?.origin || ''
              const path = href.substring(origin.length)

              if (path.length) {
                event.preventDefault()

                $router.push(path)
              }
            }
          }
        }

        document.addEventListener('click', onElementClick)

        this.$once('hook:destroyed', () => {
          document.removeEventListener('click', onElementClick)
        })
      }
    },
  })

  return router
}
