<template>
  <div ref="singleSelect" class="single-select" :class="classes">
    <!-- We can pass an id to activate the input control
    (a click on the label focuses on the field) but it only
    works when :searchable=true, so never here -->
    <Multiselect
      :value="computedValue"
      track-by="id"
      label="name"
      :placeholder="placeholder"
      :options="computedOptions"
      :searchable="false"
      :disabled="disabled"
      :allow-empty="false"
      :show-labels="false"
      :close-on-select="true"
      :class="{ 'icon-left': iconSide === 'left' }"
      :name="name"
      @input="onInput"
      @select="onSelect"
      @open="onOpen"
    >
      <template slot="option" slot-scope="multiselect">
        {{ multiselect.option.name }}
        <span v-if="multiselect.option.note !== undefined" :class="$('option-note')">
          ({{ multiselect.option.note }})
        </span>
      </template>

      <template slot="caret" slot-scope="multiselect">
        <i v-if="iconIsOld(icon)" :class="icon" @mousedown.prevent.stop="multiselect.toggle()" />
        <Icon
          v-else
          :name="icon"
          :class="{
            [$('icon')]: true,
            [$('chevron')]: icon === 'chevron-bottom',
          }"
          @mousedown.prevent.stop="multiselect.toggle()"
        />
      </template>
    </Multiselect>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'

  import Icon from '@/core/graphics/Icon/Icon'

  import { iconIsOld } from '@/utils/image'

  const Theme = {
    TRANSLUCENT: 'translucent',
    VOID: 'void',
  }

  /**
   * Some CSS classes could be applied on this object
   * One of : 'translucent'
   */
  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Icon: Icon.__enums,
  Multiselect: Multiselect.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme,
    name: 'SingleSelect',
    __enums: {
      Theme,
    },
    components: {
      Icon,
      Multiselect,
    },
    props: {
      /**
       * Model value
       */
      value: {
        type: [String, Number, Boolean, Object],
        default: null,
      },
      /**
       * Available options
       * @type {Array.<Object>}
       */
      options: {
        type: Array,
        default: () => [],
      },
      /**
       * If 'true', it can't have a nullable value, and no "placeholder value"
       * is provided after a value is first selected
       */
      required: {
        type: Boolean,
        default: true,
      },
      /**
       * Disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Name (used in HTTP query params)
       */
      name: {
        type: String,
        default: 'single-select',
      },
      /**
       * Placeholder
       */
      placeholder: {
        type: String,
        default: 'Sélectionner',
      },
      /**
       * Icon display in the single select
       */
      icon: {
        type: String,
        default: 'chevron-bottom',
      },
      /**
       * To force direction : 'top' or 'bottom'
       */
      forceDirection: {
        type: String,
        default: null,
      },
      /**
       * Side of the icon: 'left' or 'right'
       */
      iconSide: {
        type: String,
        default: 'right',
      },
      /**
       * Theme, can be translucent|dark
       */
      theme: {
        type: String,
        default: null,
      },
      /**
       * Select error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Class that will be used to determine if the dropdown should open
         * on top or on the bottom of it's parent
         * @type {String}
         */
        dropDownClass: 'single-select--bottom',
      }
    },
    computed: {
      /**
       * Pick the right object among options comparing 'value' and options' 'id'
       * @type {Object}
       */
      computedValue() {
        const { value, computedOptions } = this

        return computedOptions.find(o => o.id === value)
      },
      /**
       * If a value is required, return options
       * If not, return the options with an empty value at the beggining
       * @type {Array}
       */
      computedOptions() {
        const { options, required, placeholder } = this

        return required ? options : [{ id: null, name: placeholder }, ...options]
      },
      /**
       * Classes of the component
       * @type {Object}
       */
      classes() {
        const { theme, disabled, computedValue, dropDownClass, error } = this

        return {
          [`single-select--${theme}`]: !!theme,
          'single-select--empty': computedValue === undefined,
          'single-select--disabled': disabled,
          'single-select--invalid': !!error,
          [dropDownClass]: true,
        }
      },
    },
    methods: {
      iconIsOld,
      /**
       * On single-select click, check its position.
       * If the space between it and the bottom of the page is inferior to 300px
       * then display the dropdown on top, otherwise, above it.
       * @returns {void}
       */
      onOpen() {
        const { forceDirection, $refs } = this

        if (forceDirection) {
          this.dropDownClass = `single-select--${forceDirection}`
        } else {
          const boundings = $refs.singleSelect.getBoundingClientRect()
          const height = window.innerHeight
          const bottom = height - boundings.bottom

          this.dropDownClass =
            bottom > 300 || bottom > boundings.top ? 'single-select--bottom' : 'single-select--top'
        }
      },
      /**
       * Fired when the select value changes
       * @input receive the whole list of selected values
       * @param {Object} event
       * @param {Object} value
       * @returns {void}
       */
      onInput(value) {
        if (value) {
          this.$emit('input', value.id, value.name)
        } else {
          this.$emit('input', undefined, undefined)
        }
      },
      /**
       * Fired when the select value changes
       * @select only receives the currently selected value
       * @param {Object} event
       * @param {Object} value
       * @returns {void}
       */
      onSelect(value) {
        if (value) {
          this.$emit('select', value.id, value.name)
        } else {
          this.$emit('select', undefined, undefined)
        }
      },
    },
  }
</script>

<style lang="stylus">

  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .single-select {
    position: relative
    font-size: 16px
    color: var(--color-font)
    max-width: 100%

    ul {
      list-style-type: none
    }

    &&--invalid {
      & .multiselect {
        border-color: var(--color-input-error)
      }
    }

    .multiselect:not(.multiselect--disabled) {
      &:hover {
        border-color: var(--color-input-highlight)
      }

      &.multiselect--active {
        border-color: var(--color-input-highlight)
      }
    }

    .multiselect {
      border: 1px solid var(--color-input-border)
      border-radius: $radius-medium
      // min-width: 150px
      height: 40px
      position: relative
      font-weight: $font-regular
      background-color: var(--color-background)
      cursor: pointer
      outline: none
      -webkit-appearance: none
      -moz-appearance: none

      &.multiselect--disabled {
        opacity: .5
      }

      &.icon-left {
        > i,
        > .single-select-icon {
          left: 8px
          right: auto
        }

        .multiselect__tags {
          margin-right: 0
          text-align: left
          margin-left: 48px

          .multiselect__single {
            padding-right: 20px
            padding-left: 0px
          }
        }
      }

      > i,
      .single-select-icon {
        position: absolute
        top: 0
        right: 8px
        bottom: 0
        line-height: 40px
        text-align: center
        transition: transform 0.2s ease
      }

      > i {
        font-size: 22px
      }

      .single-select-icon {
        font-size: 15px
      }

      &.multiselect--active > i,
      &.multiselect--active > .single-select-icon {
        color: var(--color-input-highlight)

        &.single-select-chevron {
          transform: rotateZ(180deg)
        }
      }
    }

    .multiselect__input,
    .multiselect__single {
      display: block
      width: 100%
      padding-left: 20px
      box-sizing: border-box
      appearance: none
    }

    .multiselect__option,
    .multiselect__single {
      font-family: $font
    }

    &:not(.single-select--disabled) {
      &:hover i,
      &:hover .single-select-icon,
      &:focus i,
      &:focus .single-select-icon {
        color: var(--color-input-highlight) !important
      }
    }

    &.single-select--empty .multiselect:not(:hover):not(:focus),
    &.single-select--disabled .multiselect {
      color: var(--color-input-placeholder)
      background: var(--color-input-background-idle)
      border-color: var(--color-input-background-idle)
    }

    .multiselect__single {
      line-height: 40px
      white-space: nowrap
    }

    .multiselect__tags {
      ellipsis()
      margin-right: 40px
      text-align: left

      .multiselect__placeholder {
        padding: 0 16px
        line-height: 40px
        color: var(--color-input-placeholder)
      }
    }

    .multiselect__tags-wrap {
      display: none
    }

    &.single-select--top .multiselect__content {
      bottom: 40px
    }

    &.single-select--bottom .multiselect__content {
      position: absolute
      top: 50px
    }

    .multiselect__content {
      width: 100%
      position: absolute
      min-width: 230px // temp quick fix for 4036
      max-height: 300px
      left: 0
      padding: 8px !important
      margin: 8px 0
      box-sizing: border-box
      background-color: var(--color-background)
      box-shadow: 0px 2px 8px rgba(0, 0, 0, .2)
      overflow-y: scroll
      z-index: 11

      li {
        min-height: 25px
      }
    }

    .multiselect__element {
      position: relative
      height: 35px
    }

    .multiselect__option {
      ellipsis()
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      display: block
      padding: 8px 24px
      font-weight: $font-regular
      cursor: pointer

      span {
        display: block
        line-height: 19px
      }

      .single-select-option-note {
        display: inline-block
        color: var(--color-brand)
        font-size: 12px
        font-style: italic
        line-height: 28px
        float: right
      }
    }

    .multiselect__option:hover,
    .multiselect__option--highlight {
      background-color: var(--color-input-options-hover)
    }

    .multiselect__option--selected {
      color: var(--color-input-highlight)
    }

    &.inline {
      .multiselect {
        min-width: 3em
        height: 24px
        padding: 0 0 0 8px
      }

      .multiselect__tags {
        margin-right: 30px
        text-align: right
      }

      .multiselect__single,
      .multiselect > i,
      .multiselect > .single-select-icon {
        line-height: 24px
      }

      .multiselect > i,
      .multiselect > .single-select-icon {
        right: 2px
      }

      .multiselect__content {
        margin: 0
      }

      .multiselect__element {
        height: 27px
      }

      .multiselect__option {
        padding: 4px 8px
        text-align: right
      }
    }

    &.translucent, &--translucent {
      .multiselect {
        font-weight: $font-bold
        font-size: 12px
        background-color: transparent
      }

      .multiselect::after,
      .multiselect__tags,
      .multiselect > i,
      .multiselect > .single-select-icon {
        color: --color-input-icon
      }

      .multiselect__tags {
        text-transform: uppercase
        text-align: right
        margin-right: 48px
      }

      .multiselect__content {
        font-size: 14px
        margin: 0
      }
    }

    &.void, &--void {
      display: flex
      align-items: center

      .multiselect {
        border: none
        height: auto
      }

      .multiselect__tags {
        margin-right: 24px
      }

      .multiselect__single {
        line-height: 1
        padding-left: 0
      }
    }
  }
</style>
