<template>
  <img
    v-if="!image || useFallback"
    :src="require(`!file-loader!./../../../assets/images/${fallback}`)"
    :class="classes"
  />

  <img v-else :src="image" :class="classes" @error="onLoadError" />
</template>

<script>
  import _camelCase from 'lodash/camelCase'

  const Radius = {
    SQUARE: 'square',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    EXTRA_LARGE: 'extra-large',
    CIRCLE: 'circle',
  }

  const Border = {
    NONE: 'none',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    BACKGROUND: 'background',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Border,Radius,
    name: 'ImageFallback',
    __enums: {
      Border,
      Radius,
    },
    props: {
      image: {
        type: String,
        default: null,
      },
      /**
       * The fallback path must be only the end after '/assets/images/'
       */
      fallback: {
        type: String,
        required: true,
      },
      radius: {
        type: String,
        default: Radius.CIRCLE,
      },
      border: {
        type: String,
        default: Border.NONE,
      },
    },
    data() {
      return {
        useFallback: false,
      }
    },
    computed: {
      /**
       * Common CSS classes applied to the root image element whatever the context is.
       * @type {Array.<String>}
       */
      classes() {
        const { radius, border, $style } = this

        return [
          $style.image,
          $style[_camelCase(`radius-${radius}`)],
          $style[_camelCase(`border-${border}`)],
        ]
      },
    },
    methods: {
      /**
       * If image didn't load, toggle "useFallback" so the
       * default image is displayed
       * @returns {void}
       */
      onLoadError() {
        this.useFallback = true
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_variables.styl'

  .image {
    position: static
    object-fit: cover
    box-sizing: border-box

    // Radius
    &.radiusSmall {
      border-radius: $radius-small
    }

    &.radiusMedium {
      border-radius: $radius-medium
    }

    &.radiusLarge {
      border-radius: $radius-large
    }

    &.radiusExtraLarge {
      border-radius: $radius-extra-large
    }

    &.radiusCircle {
      border-radius: $radius-circle
    }

    // Border
    &.borderPrimary {
      border: 1px solid var(--color-brand)
    }

    &.borderSecondary {
      border: 1px solid var(--color-grey-30)
    }

    &.borderBackground {
      border: 1px solid var(--color-background)
    }
  }
</style>
