<!-- eslint-disable vue/multiline-html-element-content-newline -->
<!--Bad indentation necessary when white-space:pre -->
<template>
  <div :class="classes">
    <Txt
      v-if="sup || $slots.sup"
      :class="$style.sup"
      :align="align"
      :size="supSize"
      :theme="supTheme"
      :type="Txt.Type.OVERLINE"
    >
      {{ sup }}<slot name="sup" />
    </Txt>

    <Heading
      v-if="title || $slots.title"
      :level="level"
      :theme="theme"
      :ellipsis="ellipsis"
      :align="align"
    >
      {{ title }}
      <slot name="title" />

      <slot slot="right" name="title-right" />
    </Heading>

    <Txt
      v-if="sub || $slots.sub"
      :class="$style.sub"
      :theme="subTheme"
      :align="align"
      :ellipsis="ellipsis"
      :size="subSize"
    >
      {{ sub }}<slot name="sub" />
    </Txt>
  </div>
</template>

<script>
  import _camelCase from 'lodash/camelCase'

  import Txt from '@/core/text/Txt/Txt'
  import Heading from '@/core/text/Heading/Heading'

  const { Level } = Heading

  // Custom theme currently supported by Headings in addition of HeadingTheme
  const Theme = {
    DEFAULT: 'default',
    COLORED: 'colored',
    SUP_INFO: 'sup-info',
    SUP_SUCCESS: 'sup-success',
    SUP_WARNING: 'sup-warning',
    SUP_DANGER: 'sup-danger',
    MONOCHROME: 'monochrome',
    ...Heading.Theme,
  }

  const Align = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Heading: Heading.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Align,Level,Theme,
    name: 'Headings',
    __enums: {
      Align,
      Level,
      Theme,
    },
    components: {
      Heading,
      Txt,
    },
    props: {
      /**
       * Level is determinating the importance of this group of headings in
       * the page.
       */
      level: {
        type: String,
        required: true,
      },
      /**
       * Main title (the bigger one)
       */
      title: {
        type: String,
        default: null,
      },
      /**
       * Subtitle (below, smaller than the main title)
       */
      sub: {
        type: String,
        default: null,
      },
      /**
       * Upper small label
       */
      sup: {
        type: String,
        default: null,
      },
      /**
       * Theme in which the heading is inserted
       * @type {Theme}
       */
      theme: {
        type: String,
        default: Theme.DEFAULT,
      },
      /**
       * If 'true', the title ends with an ellipsis if it can't be
       * displayed entirely
       */
      ellipsis: {
        type: Boolean,
        default: true,
      },
      /**
       * Align the titles according to this prop
       * @type {Align}
       */
      align: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * CSS classes applied at the root of the component
       * @return {Object.<Boolean>}
       */
      classes() {
        const { level, $style, ellipsis } = this

        return {
          [$style.headings]: true,
          [$style[_camelCase(`level-${level}`)]]: true,
          [$style.ellipsis]: ellipsis,
        }
      },
      /**
       * Sup theme deduced from Theme to match the TxtThemes
       * @type {String}
       */
      supTheme() {
        const { theme } = this
        const { GREY, BLACK, WHITE, SUCCESS_ALT, WARNING_ALT, DANGER, INFORMATION_ALT } = Txt.Theme

        const supThemeFromTheme = {
          [Theme.DEFAULT]: GREY,
          [Theme.COLORED]: WHITE,
          [Theme.SUP_SUCCESS]: SUCCESS_ALT,
          [Theme.SUP_WARNING]: WARNING_ALT,
          [Theme.SUP_DANGER]: DANGER,
          [Theme.SUP_INFO]: INFORMATION_ALT,
          [Theme.MONOCHROME]: BLACK,
        }

        // If there is no subtheme for the given theme we pass the theme because
        // it's support old colors value
        return supThemeFromTheme[theme] || theme
      },
      /**
       * The sub theme according to the general theme
       * @type {String}
       */
      subTheme() {
        const { theme } = this
        const { GREY, BLACK, WHITE } = Txt.Theme

        const subThemeFromTheme = {
          [Theme.DEFAULT]: GREY,
          [Theme.SUP_SUCCESS]: GREY,
          [Theme.SUP_WARNING]: GREY,
          [Theme.SUP_DANGER]: GREY,
          [Theme.SUP_INFO]: GREY,
          [Theme.MONOCHROME]: BLACK,
          [Theme.CONTRAST]: WHITE,
        }

        return subThemeFromTheme[theme] || undefined
      },
      /**
       * Compute the size of the sup according to the given level
       * @type {String}
       */
      supSize() {
        const { level } = this
        const { XXXS, XXS, XS } = Txt.Size

        switch (level) {
          case '1':
            return XS
          case '2':
            return XXS
          default:
            return XXXS
        }
      },
      /**
       * Compute the size of the sub according to the given level
       * @type {String}
       */
      subSize() {
        const { level } = this
        const { XXS, XS, S } = Txt.Size

        switch (level) {
          case '5':
            return XXS
          case '4':
            return XS
          default:
            return S
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  .headings {
    &.ellipsis {
      overflow: hidden
    }

    .sup {
      margin-bottom: 8px
    }

    &.level {
      &1 .sub,
      &2 .sub {
        margin-top: 12px
      }

      &3 .sub {
        margin-top: 8px
      }

      &4 .sub,
      &5 .sub {
        margin-top: 6px
      }
    }
  }
</style>
