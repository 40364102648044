/**
 * Apollo middleware that appends the commit SHA (when available)
 * to every request header so that the back-end can trace back errors
 * @param {Object} operation
 * @param {Function} forward
 */
export default function middleware(operation, forward) {
  const { headers } = operation.getContext()

  operation.setContext({
    headers: {
      ...headers,
      'X-Version': process.env.VUE_APP_VERSION,
      'X-Platform': window.navigator.appCodeName,
      'X-Client-Name': 'frontend',
    },
  })

  return forward(operation)
}
