/**
 * The acquisition options a coporate may come from when posting its first
 * mission
 */
const CorporateAcquisition = {
  EVENT: 'event',
  SOCIAL_NETWORK: 'socialNetwork',
  INTERNET_SEARCH: 'internetSearch',
  PRESS_ARTICLE: 'pressArticle',
  SPONSORSHIP: 'sponsorship',
  WORD_OF_MOUTH: 'wordOfMouth',
  COMMERCIAL_MEETING: 'commercialMeeting',
  PARTNERSHIP: 'partnership',
  OTHER: 'other',
}

/**
 * The roles a corporate may have in its company
 */
const CorporateRole = {
  ADMIN: 'admin',
  MANAGER: 'manager',
}

/**
 * Corporate permissions
 */
const CorporatePermissions = {
  SHOW_PROFILE: 'showProfile',
  SHOW_MISSIONS: 'showMissions',
  SHOW_ADMINISTRATION: 'showAdministration',
  SHOW_COMMUNITY: 'showCommunity',
  SHOW_CREW: 'showCrew',
}

const CorporateTypes = {
  CorporateAcquisition,
  CorporatePermissions,
  CorporateRole,
}

export default CorporateTypes
