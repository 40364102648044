/**
 * Convert to a boolean
 * @param {String} src
 * @returns {boolean}
 */
function bool(src) {
  if (src === 'true') {
    return true
  }

  if (src === 'false') {
    return false
  }

  return undefined
}

/**
 * Convert to an integer
 * @param {*} src
 * @returns {Number}
 */
function int(src) {
  return parseInt(src, 10)
}

function intOrNull(src) {
  return !src && src !== 0 ? null : int(src)
}

/**
 * Transform the given 'value' to a valid id string
 * @param {*} value
 * @returns {String}
 */
function idify(value) {
  return value
    .toString()
    .replace(/\s/g, '')
    .replace(/\.,/g, '-')
}

export { bool, int, intOrNull, idify }
