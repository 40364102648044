/**
 * Default message used if there is no I18nMixin's methods exposed
 * @type {String}
 */
const DEFAULT_MESSAGE =
  'Es-tu sûr de vouloir quitter cette page ?\n\n' +
  'Les modifications du champ sélectionné ne seront ' +
  'pas sauvegardées.'

/**
 * Will return true if the form is submitting, dirty,
 * or if a field is currently in focus
 */
function locked(form) {
  const dirty = form.dirty || false
  const submitting = form.submitting || false

  return dirty || submitting
}

/**
 * Message to display in the browser's boxes when trying to leave
 * an "active" form
 */
function message(form) {
  return form.alertOnLeaveMessage || DEFAULT_MESSAGE
}

/**
 * Called when the user tries to leave the page
 * through external navigation
 * (closing tab or navigating to a different URL)
 * @return {void}
 */
function onWindowUnload(e) {
  const { form } = this

  if (locked(form)) {
    e.returnValue = message(form)
  }
}

/**
 * Function to call in the created lifecycle hook
 */
function created() {
  // Register for the global "beforeunload" event, in order to catch
  // the attempt of leaving
  window.addEventListener('beforeunload', onWindowUnload.bind(this))
}

/**
 * Function to call in the destroyed lifecycle hook
 */
function destroyed() {
  // Unregister the event
  window.removeEventListener('beforeunload', onWindowUnload)
}

/**
 * Called when the user tries to leave the page
 * through internal navigation
 * (i.e. navigating to another page in the app)
 * @return {void}
 */
function onNavigate(next) {
  const { form } = this

  if (locked(form)) {
    /* eslint no-alert: "off" */
    if (window.confirm(message(form))) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
}

export default {
  created,
  destroyed,
  onNavigate,
}
