<template>
  <Field
    class="phone-number-field"
    :label="label"
    :error="error"
    :helper="helper"
    :disabled="disabled"
  >
    <slot v-if="$slots.label" slot="label" name="label" />

    <div :class="$('body')">
      <CallingCodeSelector
        data-calling-code
        :class="$('code')"
        :value="iso2"
        :disabled="disabled"
        @input="v => onInput('iso2', v)"
      />

      <TextField
        data-phone-number
        :value="number"
        :class="$('number')"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="tel-national"
        @input="v => onInput('number', v)"
      />
    </div>
  </Field>
</template>

<script>
  import { getCountryCallingCode, parsePhoneNumberFromString } from 'libphonenumber-js'

  import CallingCodeSelector from '@/core/inputs/CallingCodeSelector/CallingCodeSelector'
  import Field from '@/core/layout/Field/Field'
  import TextField from '@/core/fields/TextField/TextField'

  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  CallingCodeSelector: CallingCodeSelector.__enums,
  Field: Field.__enums,
  TextField: TextField.__enums,
},

    name: 'PhoneNumberField',
    components: {
      CallingCodeSelector,
      Field,
      TextField,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Address input value
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Field's error if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * PhoneNumber plaeholder
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Helper text display under the field
       */
      helper: {
        type: String,
        default: null,
      },
    },
    data() {
      const { value } = this
      const parsed = value ? parsePhoneNumberFromString(value) : null

      return {
        /**
         * International calling code
         * @type <String>
         */
        iso2: parsed?.country ?? 'FR',
        /**
         * Phone number
         * @type <String>
         */
        number: parsed?.nationalNumber ?? '',
      }
    },
    methods: {
      /**
       * Fired when any of the to fields are edited
       * Update according field's bound data value, then
       * emits an input event containing a properly formatted phone number
       * @params <String> field field to be updated
       * @params <String> v new value
       * @returns {void}
       */
      onInput(field, v) {
        this[field] = v
        const { iso2, number } = this
        const phonePrefix = getCountryCallingCode(iso2)

        this.$emit('input', number ? `+${phonePrefix}${number}` : null)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .phone-number-field {
    height: 71px

    .icon-info {
      color: var(--color-font-light)
      line-height: inherit
      padding-left: 5px
    }

    &-body {
      display: flex
    }

    &-code >>> .multiselect__tags {
      border-radius: var(--input-radius) !important
    }

    &-number {
      margin-left: 24px
      width: calc(100% - 110px)
      >>> input {
        border-radius: var(--input-radius) !important
      }
    }
  }
</style>
