import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isObject from 'lodash/isObject'
import _isPlainObject from 'lodash/isPlainObject'
import _omit from 'lodash/omit'
import _transform from 'lodash/transform'

const BLACKLIST_OBJECT_PROPS = [
  '__typename',
  'Symbol(id)',
  'missionSkillId',
  'freelanceExperienceSkillId',
  'freelanceSkillId',
]

/**
 * Return 'true' if the provided object is a Blob (or a File, which is actually
 * a Blob)
 * @param {Object} obj
 * @returns {Boolean}
 */
function isBlob(obj) {
  return obj instanceof Blob
}

/**
 * Deeply clean an object from all dirty unnecessary things (empty string,
 * empty objects, apollo meta props)
 * @param {*} value
 * @returns {*}
 */
function cleanObjectDeep(value, blacklist = []) {
  /**
   * Recursively clean the collection
   * @param {Object|Array} obj
   * @returns {Object|Array}
   */
  function recursiveCleanEmptyObjects(obj) {
    return _transform(obj, (res, val, key) => {
      const cleaned = _isObject(val) && !isBlob(val) ? recursiveCleanEmptyObjects(val) : val

      if (isBlob(val)) {
        res[key] = cleaned
        return
      }

      if (_isPlainObject(val) && _isEmpty(cleaned)) {
        return
      }

      if (_isArray(res)) {
        res.push(cleaned)
      }

      if (_isPlainObject(res)) {
        if (val !== '' && !BLACKLIST_OBJECT_PROPS.includes(key) && !blacklist.includes(key)) {
          res[key] = cleaned
        } else if (val === '') {
          res[key] = null
        }
      }
    })
  }

  return _isObject(value) ? recursiveCleanEmptyObjects(value) : value
}

/**
 * Return `true` if the two provided objects are equals, not regarding the
 * keys passed into `ignore`, or the one specifed in BLACKLIST_OBJECT_PROPS
 * @param {Object} obj1
 * @param {Object} obj2
 * @param {Array.<String>} ignore
 * @returns {Boolean}
 */
function isEqualObject(obj1, obj2, ignore = []) {
  const cleaned1 = cleanObjectDeep(obj1, ignore)
  const cleaned2 = cleanObjectDeep(obj2, ignore)

  return _isEqual(cleaned1, cleaned2)
}

/**
 * Omit object key/values recursively
 * @param {*} input
 * @param {*} props
 */
function omitDeep(input, props) {
  function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
  }

  function isNil(value) {
    return value === null || value === undefined
  }

  function omitDeepOnOwnProps(obj) {
    if (typeof input === 'undefined') {
      return input
    }

    if (!Array.isArray(obj) && !isObject(obj)) {
      return obj
    }

    if (Array.isArray(obj)) {
      return omitDeep(obj, props)
    }

    const o = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(obj)) {
      o[key] = !isNil(value) ? omitDeep(value, props) : value
    }

    return _omit(o, props)
  }

  if (arguments.length > 2) {
    // eslint-disable-next-line
    props = Array.prototype.slice.call(arguments).slice(1)
  }

  if (Array.isArray(input)) {
    return input.map(omitDeepOnOwnProps)
  }

  return omitDeepOnOwnProps(input)
}

export { cleanObjectDeep, isEqualObject, omitDeep }
