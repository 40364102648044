import { __ } from '@comet/i18n'

import FreelancerTypes from '@/types/freelancer'

const {
  FreelancerStatus,
  FreelancerGrade,
  Environment,
  Workplace,
  Contract,
  FreelancerFilters,
  ExperienceLevel,
  QualificationLevel,
  PrefTime,
  Mobility,
  LanguageLevel,
  CallAvailability,
  FreelancerAcquisition,
  FreelancerDocumentType,
  FreelancerExperienceReferentRequestStatus,
  AdditionalWorkerPosition,
  FreelanceHuntingInfoFreelanceStatus,
} = FreelancerTypes

/**
 * Returns the formatted label corresponding to the given freelancer's status
 * @param {FreelancerStatus} status
 * @param {Boolean} isPreUser
 * @return {String}
 */
function formatFreelancerStatus(status, isPreUser = false, verbose = false) {
  const trans = {
    [FreelancerStatus.NEW]: isPreUser
      ? __('ty:freelancer:status:new:pre-user')
      : verbose
      ? __('ty:freelancer:status:new:verbose')
      : __('ty:freelancer:status:new'),
    [FreelancerStatus.VALIDATED]: isPreUser
      ? __('ty:freelancer:status:validated:pre-user')
      : verbose
      ? __('ty:freelancer:status:validated:verbose')
      : __('ty:freelancer:status:validated'),
    [FreelancerStatus.WRONG_SCOPE]: verbose
      ? isPreUser
        ? __('ty:freelancer:status:wrong-scope:pre-user:verbose')
        : __('ty:freelancer:status:wrong-scope:verbose')
      : isPreUser
      ? __('ty:freelancer:status:wrong-scope:pre-user')
      : __('ty:freelancer:status:wrong-scope'),
    [FreelancerStatus.BANNED]: verbose
      ? __('ty:freelancer:status:banned:verbose')
      : __('ty:freelancer:status:banned'),
  }

  return trans[status] || undefined
}

/**
 * Freelancer grade
 * @type {String}
 */
function formatFreelancerGrade(key) {
  const trans = {
    [FreelancerGrade.REFERENCED]: __('ty:freelancer:grade:referenced'),
    [FreelancerGrade.ASTRONAUT]: __('ty:freelancer:grade:astronaut'),
    [FreelancerGrade.GRADUATE]: __('ty:freelancer:grade:graduate'),
    [FreelancerGrade.EXPLORER]: __('ty:freelancer:grade:explorer'),
    [FreelancerGrade.CANDIDATE]: __('ty:freelancer:grade:candidate'),
    [FreelancerGrade.NO_GRADE]: __('ty:freelancer:grade:no-grade'),
  }

  return trans[key] || undefined
}

/**
 * Freelancer status
 * @type {String}
 */
function formatFreelanceHuntingInfoFreelanceStatus(key) {
  const trans = {
    [FreelanceHuntingInfoFreelanceStatus.FREELANCER]: __(
      'ty:freelancer-hunting-info-freelance-status:freelancer',
    ),
    [FreelanceHuntingInfoFreelanceStatus.FREELANCER_STATUS_PENDING]: __(
      'ty:freelancer-hunting-info-freelance-status:freelancer-status-pending',
    ),
    [FreelanceHuntingInfoFreelanceStatus.NOT_FREELANCER]: __(
      'ty:freelancer-hunting-info-freelance-status:not-freelancer',
    ),
    [FreelanceHuntingInfoFreelanceStatus.NOT_FREELANCER_BUT_INTERESTED]: __(
      'ty:freelancer-hunting-info-freelance-status:not-freelancer-but-interested',
    ),
  }

  return trans[key] || undefined
}

/**
 * Company type environment
 * @type {String}
 */
function formatEnvironmment(key) {
  const trans = {
    [Environment.START_UP]: __('ty:freelancer:environment:small'),
    [Environment.BIG_COMPANY]: __('ty:freelancer:environment:big'),
    [Environment.NONE]: __('ty:freelancer:environment:whatever'),
  }

  return trans[key] || undefined
}

/**
 * Language level
 * @type {String}
 */
function formatLanguageLevel(key) {
  const trans = {
    [LanguageLevel.BASIC]: __('ty:freelancer:language:basic'),
    [LanguageLevel.PRO]: __('ty:freelancer:language:pro'),
    [LanguageLevel.NATIVE]: __('ty:freelancer:language:native'),
  }

  return trans[key] || undefined
}

/**
 * Workplace
 * @type {String}
 */
function formatWorkplace(key) {
  const trans = {
    [Workplace.ON_SITE]: __('ty:freelancer:pref-workplace:on-site'),
    [Workplace.PARTIAL_REMOTE]: __('ty:freelancer:pref-workplace:partial-remote'),
    [Workplace.REMOTE]: __('ty:freelancer:pref-workplace:remote'),
    [Workplace.NONE]: __('ty:freelancer:pref-workplace:none'),
  }

  return trans[key] || undefined
}

/**
 * Workplace
 * @type {String}
 */
function formatShortWorkplace(key) {
  const trans = {
    [Workplace.ON_SITE]: __('ty:freelancer:short-workplace:on-site'),
    [Workplace.PARTIAL_REMOTE]: __('ty:freelancer:short-workplace:partial-remote'),
    [Workplace.REMOTE]: __('ty:freelancer:short-workplace:remote'),
    [Workplace.NONE]: __('ty:freelancer:short-workplace:none'),
  }

  return trans[key] || undefined
}

/**
 * Contract days availability
 * @type {String}
 */
function formatContract(key) {
  const trans = {
    [Contract.NONE]: __('ty:freelancer:contract:none'),
    [Contract.PART_TIME]: __('ty:freelancer:contract:part-time'),
    [Contract.FULL_TIME]: __('ty:freelancer:contract:full-time'),
  }

  return trans[key] || undefined
}

/**
 * Experience level
 * @type {String}
 */
function formatExperienceLevel(key) {
  const trans = {
    [ExperienceLevel.JUNIOR]: __('ty:freelancer:experience-level:junior'),
    [ExperienceLevel.MIDDLE]: __('ty:freelancer:experience-level:middle'),
    [ExperienceLevel.EXPERIENCED]: __('ty:freelancer:experience-level:experienced'),
    [ExperienceLevel.SENIOR]: __('ty:freelancer:experience-level:senior'),
  }

  return trans[key] || undefined
}

/**
 * Experience level (in years)
 * @type {String}
 */
function formatExperienceLevelYears(key) {
  const trans = {
    [ExperienceLevel.JUNIOR]: __('ty:freelancer:experience-level-years:junior'),
    [ExperienceLevel.MIDDLE]: __('ty:freelancer:experience-level-years:middle'),
    [ExperienceLevel.EXPERIENCED]: __('ty:freelancer:experience-level-years:experienced'),
    [ExperienceLevel.SENIOR]: __('ty:freelancer:experience-level-years:senior'),
  }

  return trans[key] || undefined
}

/**
 * Experience level (in years)
 * @type {String}
 */
function formatQualificationLevel(key) {
  const trans = {
    [QualificationLevel.JUNIOR]: __('ty:freelancer:qualification-level:junior'),
    [QualificationLevel.INTERMEDIATE]: __('ty:freelancer:qualification-level:intermediate'),
    [QualificationLevel.SENIOR]: __('ty:freelancer:qualification-level:senior'),
    [QualificationLevel.EXPERT]: __('ty:freelancer:qualification-level:expert'),
  }

  return trans[key] || undefined
}

/**
 * Preferred time
 * @type {String}
 */
function formatPrefTime(key) {
  const trans = {
    [PrefTime.SHORT]: __('ty:freelancer:pref-time:short'),
    [PrefTime.LONG]: __('ty:freelancer:pref-time:long'),
    [PrefTime.NONE]: __('ty:freelancer:pref-time:none'),
  }

  return trans[key] || undefined
}

/**
 * Format Mission hunt FAQ item
 * @param {*} key FAQ trans key
 * @type {Object.<String>}
 */
function formatMissionHuntFAQ(key) {
  const trans = {
    whatIsComet: {
      title: __('vw:public:freelance-mission-hunt:FAQ:what-is-comet:title'),
      description: __('vw:public:freelance-mission-hunt:FAQ:what-is-comet:description'),
    },
    cometClients: {
      title: __('vw:public:freelance-mission-hunt:FAQ:comet-clients:title'),
      description: __('vw:public:freelance-mission-hunt:FAQ:comet-clients:description'),
    },
    whyComet: {
      title: __('vw:public:freelance-mission-hunt:FAQ:why-comet:title'),
      description: __('vw:public:freelance-mission-hunt:FAQ:why-comet:description'),
    },
    cometCommunity: {
      title: __('vw:public:freelance-mission-hunt:FAQ:comet-community:title'),
      description: __('vw:public:freelance-mission-hunt:FAQ:comet-community:description'),
    },
  }
  return trans[key] || undefined
}

/**
 * Mobility
 * @type {String}
 */
function formatMobility(key) {
  const trans = {
    [Mobility.CITY]: __('ty:freelancer:mobility:city'),
    [Mobility.REGION]: __('ty:freelancer:mobility:region'),
    [Mobility.COUNTRY]: __('ty:freelancer:mobility:country'),
  }

  return trans[key] || undefined
}

/**
 * Call availability
 * @type {String}
 */
function formatCallAvailability(key) {
  const trans = {
    [CallAvailability.NONE]: __('ty:freelancer:availability-call:none'),
    [CallAvailability.MORNING]: __('ty:freelancer:availability-call:morning'),
    [CallAvailability.AFTERNOON]: __('ty:freelancer:availability-call:afternoon'),
    [CallAvailability.EVENING]: __('ty:freelancer:availability-call:evening'),
    [CallAvailability.ALL_DAY]: __('ty:freelancer:availability-call:all-day'),
  }

  return trans[key] || undefined
}

/**
 * Freelance acquisition source
 * @type {String}
 */
function formatFreelancerAcquisition(key) {
  const trans = {
    [FreelancerAcquisition.WORD_OF_MOUTH]: __('ty:freelancer:acquisition:word-of-mouth'),
    [FreelancerAcquisition.SPONSORSHIP]: __('ty:freelancer:acquisition:sponsorship'),
    [FreelancerAcquisition.INTERNET_SEARCH]: __('ty:freelancer:acquisition:internet-search'),
    [FreelancerAcquisition.PRESS_ARTICLE]: __('ty:freelancer:acquisition:press-article'),
    [FreelancerAcquisition.EVENT]: __('ty:freelancer:acquisition:event'),
    [FreelancerAcquisition.SOCIAL_NETWORK]: __('ty:freelancer:acquisition:social-network'),
    [FreelancerAcquisition.COMET_CONTENT]: __('ty:freelancer:acquisition:comet-content'),
    [FreelancerAcquisition.ADVERTISING]: __('ty:freelancer:acquisition:advertising'),
    [FreelancerAcquisition.LINKEDIN_CONTACT]: __('ty:freelancer:acquisition:linkedin-contact'),
    [FreelancerAcquisition.EMAIL_CONTACT]: __('ty:freelancer:acquisition:email-contact'),
    [FreelancerAcquisition.OTHER]: __('ty:freelancer:acquisition:other'),
  }

  return trans[key] || undefined
}

/**
 * Freelancer mode filter
 * @type {String}
 */
function formatFreelancerModeFilter(key) {
  const trans = {
    [FreelancerFilters.IS_FAVORITED]: __('ty:freelancer:mode:filters:favorites'),
    [FreelancerFilters.IS_FAVORITED_BY_ME]: __('ty:freelancer:mode:filters:my-favorites'),
    [FreelancerFilters.WRONG_SCOPE]: __('ty:freelancer:mode:filters:wrong-scope'),
    [FreelancerFilters.BANNED]: __('ty:freelancer:mode:filters:banned'),
    [FreelancerFilters.DROPPED]: __('ty:freelancer:mode:filters:dropped'),
    [FreelancerFilters.IN_MY_ON_GOING_MISSION]: __(
      'ty:freelancer:mode:filters:in-my-on-going-missions',
    ),
  }

  return trans[key] || undefined
}

/**
 * Document type
 * @type {String}
 */
function formatDocumentType(key) {
  const trans = {
    [FreelancerDocumentType.CRA]: __('ty:freelancer:document-type:cra'),
    [FreelancerDocumentType.INVOICE]: __('ty:freelancer:document-type:invoice'),
  }

  return trans[key] || undefined
}

/**
 * Short form document type
 * @type {String}
 */
function formatShortDocumentType(key) {
  const trans = {
    [FreelancerDocumentType.CRA]: __('ty:freelancer:short-document-type:cra'),
    [FreelancerDocumentType.INVOICE]: __('ty:freelancer:short-document-type:invoice'),
  }

  return trans[key] || undefined
}

/**
 * Skill duration
 * @param {String} key
 * @returns {String}
 */
function formatFreelancerSkillDuration(duration) {
  if (duration === null) {
    return __('ty:freelancer:skill-duration:null')
  }

  return __('ty:freelancer:skill-duration', duration, { duration })
}

function formatFreelancerJobTitleDuration(duration) {
  if (duration === null || duration === undefined) {
    return __('ty:freelancer:job-title-duration:null')
  }

  return __('ty:freelancer:job-title-duration', duration, { duration })
}

/**
 * Format endorsment request status
 * @type {String}
 */
function formatFreelancerExperienceReferentRequestStatus(key) {
  const trans = {
    [FreelancerExperienceReferentRequestStatus.PENDING]: __(
      'ty:freelancer:experience-referent-request-status:pending',
    ),
    [FreelancerExperienceReferentRequestStatus.CANCELLED]: __(
      'ty:freelancer:experience-referent-request-status:cancelled',
    ),
    [FreelancerExperienceReferentRequestStatus.DENIED]: __(
      'ty:freelancer:experience-referent-request-status:denied',
    ),
    [FreelancerExperienceReferentRequestStatus.COMPLETED]: __(
      'ty:freelancer:experience-referent-request-status:completed',
    ),
  }

  return trans[key] || undefined
}

/**
 * Format freelancer evaluation reasons
 * @type {String}
 */
function formatFreelancerEvaluationReason(key) {
  const trans = {
    defaultUpvote: __('ty:freelancer:evaluation-reason:default-upvote:label'),
    defaultDownvote: __('ty:freelancer:evaluation-reason:default-downvote:label'),
    awesomeProfile: __('ty:freelancer:evaluation-reason:awesome-profile:label'),
    rareGem: __('ty:freelancer:evaluation-reason:rare-gem:label'),
    greatCommunication: __('ty:freelancer:evaluation-reason:great-communication:label'),
    verySkilled: __('ty:freelancer:evaluation-reason:very-skilled:label'),
    highlyMotivated: __('ty:freelancer:evaluation-reason:highly-motivated:label'),
    selfStarter: __('ty:freelancer:evaluation-reason:self-starter:label'),
    teamPlayer: __('ty:freelancer:evaluation-reason:team-player:label'),
    problemSolver: __('ty:freelancer:evaluation-reason:problem-solver:label'),
    fastPaced: __('ty:freelancer:evaluation-reason:fast-paced:label'),
    badQualityProfile: __('ty:freelancer:evaluation-reason:bad-quality-profile:label'),
    priceIssue: __('ty:freelancer:evaluation-reason:price-issue:label'),
    notSkilledEnough: __('ty:freelancer:evaluation-reason:not-skilled-enough:label'),
    presaleQuality: __('ty:freelancer:evaluation-reason:presale-quality:label'),
    missionQuality: __('ty:freelancer:evaluation-reason:mission-quality:label'),
    notFreelance: __('ty:freelancer:evaluation-reason:not-freelance:label'),
    wrongContacts: __('ty:freelancer:evaluation-reason:wrong-contacts:label'),
    fakeIdentity: __('ty:freelancer:evaluation-reason:fake-identity:label'),
    cometBypass: __('ty:freelancer:evaluation-reason:comet-bypass:label'),
  }

  return trans[key] || undefined
}

/**
 * Format freelancer evaluation reasons
 * @type {String}
 */
function formatFreelancerEvaluationSubReason(key) {
  const trans = {
    awesomeProfile: __('ty:freelancer:evaluation-reason:awesome-profile:sub-label'),
    rareGem: __('ty:freelancer:evaluation-reason:rare-gem:sub-label'),
    greatCommunication: __('ty:freelancer:evaluation-reason:great-communication:sub-label'),
    verySkilled: __('ty:freelancer:evaluation-reason:very-skilled:sub-label'),
    highlyMotivated: __('ty:freelancer:evaluation-reason:highly-motivated:sub-label'),
    selfStarter: __('ty:freelancer:evaluation-reason:self-starter:sub-label'),
    teamPlayer: __('ty:freelancer:evaluation-reason:team-player:sub-label'),
    problemSolver: __('ty:freelancer:evaluation-reason:problem-solver:sub-label'),
    fastPaced: __('ty:freelancer:evaluation-reason:fast-paced:sub-label'),
    badQualityProfile: __('ty:freelancer:evaluation-reason:bad-quality-profile:sub-label'),
    priceIssue: __('ty:freelancer:evaluation-reason:price-issue:sub-label'),
    notSkilledEnough: __('ty:freelancer:evaluation-reason:not-skilled-enough:sub-label'),
    presaleQuality: __('ty:freelancer:evaluation-reason:presale-quality:sub-label'),
    missionQuality: __('ty:freelancer:evaluation-reason:mission-quality:sub-label'),
    notFreelance: __('ty:freelancer:evaluation-reason:not-freelance:sub-label'),
    wrongContacts: __('ty:freelancer:evaluation-reason:wrong-contacts:sub-label'),
    fakeIdentity: __('ty:freelancer:evaluation-reason:fake-identity:sub-label'),
    cometBypass: __('ty:freelancer:evaluation-reason:comet-bypass:sub-label'),
  }

  return trans[key] || undefined
}

/**
 * Format worker position
 * @type {String}
 */
function formatAdditionalWorkerPosition(key) {
  const trans = {
    [AdditionalWorkerPosition.EMPLOYEE]: __('ty:freelancer:additional-worker-position:employee'),
    [AdditionalWorkerPosition.ESN_EMPLOYEE]: __(
      'ty:freelancer:additional-worker-position:esn-employee',
    ),
    [AdditionalWorkerPosition.RECRUITER]: __('ty:freelancer:additional-worker-position:recruiter'),
    [AdditionalWorkerPosition.STUDENT]: __('ty:freelancer:additional-worker-position:student'),
    [AdditionalWorkerPosition.OTHER]: __('ty:freelancer:additional-worker-position:other'),
  }

  return trans[key] || undefined
}

/**
 * Freelancer card color
 * @type {String}
 */
function getFreelancerColor(index) {
  const colors = ['yellow', 'green', 'orange']

  return colors[index % colors.length]
}

/**
 *  Return the minimum experience level in year
 * @param {String} experienceLevel
 */
function getMinExperienceLevelInYear(experienceLevel) {
  switch (experienceLevel) {
    case ExperienceLevel.JUNIOR:
      return 0
    case ExperienceLevel.MIDDLE:
      return 2
    case ExperienceLevel.EXPERIENCED:
      return 4
    default:
      return 6
  }
}

export {
  formatCallAvailability,
  formatContract,
  formatDocumentType,
  formatEnvironmment,
  formatExperienceLevel,
  formatExperienceLevelYears,
  formatQualificationLevel,
  formatFreelancerAcquisition,
  formatFreelancerEvaluationReason,
  formatFreelancerEvaluationSubReason,
  formatFreelancerModeFilter,
  formatFreelancerStatus,
  formatFreelancerGrade,
  formatFreelanceHuntingInfoFreelanceStatus,
  formatFreelancerExperienceReferentRequestStatus,
  formatAdditionalWorkerPosition,
  formatLanguageLevel,
  formatMissionHuntFAQ,
  formatMobility,
  formatPrefTime,
  formatShortDocumentType,
  formatShortWorkplace,
  formatFreelancerSkillDuration,
  formatFreelancerJobTitleDuration,
  formatWorkplace,
  getFreelancerColor,
  getMinExperienceLevelInYear,
}
