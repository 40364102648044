<template>
  <div :class="$style.expandableTextareaField">
    <TextareaField :class="$style.teaxtareaField" v-bind="$attrs" v-on="$listeners" />

    <div :class="$style.overlay" />
  </div>
</template>

<script>
  import TextareaField from './TextareaField'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  TextareaField: TextareaField.__enums,
},

    name: 'ExpandableTextareaField',
    components: {
      TextareaField,
    },
    inheritAttrs: false,
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_colors.styl'
  @import '~@/assets/css/_shadow.styl'
  @import '~@/assets/css/_variables.styl'

  .expandableTextareaField {
    position: relative
    width: 100%
    height: 100%
    min-height: 144px
  }

  .teaxtareaField {
    width: 100%
    height: 100%
    min-height: 140px
    border-radius: $radius-medium
    z-index: 0

    &:focus-within {
      shadow('dark', 'medium')

      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      height: 75vh
      overflow-y: hidden
      z-index: 103
      transition: height .3s ease

      textarea {
        background: var(--color-input-background-idle)
      }
    }
  }

  .overlay {
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: transparent
    transition: background .4s ease, z-index .4s ease
    user-select: none
    z-index: -1
  }

  .teaxtareaField:focus-within + .overlay {
    background: var(--color-overlay-light)
    z-index: 102
  }
</style>
