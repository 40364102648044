/**
 * Enum identifying the possible roles a
 * user can assume
 */
const UserRole = {
  TEAM_MEMBER: 'teamMember',
  FREELANCER: 'freelancer',
  CORPORATE: 'corporate',
  CHAT_USER: 'chatUser',
}

/**
 * Policies about how to fetch or not the current user info regarding to
 * the routes
 */
const UserFetchPolicy = {
  FETCH: 'fetch',
  FORCE: 'force-fetch',
  PREVENT: 'prevent-fetch',
  PUBLIC: 'public',
}

/**
 * Available providers to perform authentication
 */
const AuthProviders = {
  GOOGLE: 'googleTeamMember',
  GOOGLE_FREELANCE: 'googleFreelance',
  LINKED_IN: 'linkedIn',
}

const EvaluationType = {
  DOWNVOTE: 'downvote',
  UPVOTE: 'upvote',
}

/**
 * Root UserTypes object
 */
const UserTypes = {
  AuthProviders,
  EvaluationType,
  UserRole,
  UserFetchPolicy,
}

export default UserTypes
