/* eslint-disable no-underscore-dangle */

import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import _isPlainObject from 'lodash/isPlainObject'
import _merge from 'lodash/merge'
import _values from 'lodash/values'

import { extractErrors, extractClasses } from './errors'
import { submitWithDebounce } from './submit'
import { updateSyncedForm } from './sync'

/**
 * Watch the whole `form` structure in order to replicate (update) it in Vuex
 * @param {Object} next
 * @returns {void}
 */
function watchForm(next) {
  const { $v } = this

  const prev = this.FormMixin_prev.form

  if (next) {
    // Update the form in the Vuex store
    updateSyncedForm.call(this, { ...next, meta: $v })

    // If a 'form.watch' is provided by the developer, we call it
    if (next.watch && _isFunction(next.watch)) {
      next.watch(next, prev)
    }
  }

  this.FormMixin_prev.form = _merge({}, next)
}

/**
 * Watch only the 'form.fields' section to detect a field change and reactivate
 * the field watchers
 * @param {Object} next
 * @returns {void}
 */
function watchFormFields(next) {
  const prev = this.FormMixin_prev.fields
  const changed = !_isEqual(next, prev)

  if (changed) {
    // Put some watchers on every fields when the form is modified
    setTimeout(subscribeWatchers.bind(this), 0)
  }

  this.FormMixin_prev.fields = _merge({}, next)
}

/**
 * Watch the Vuelidate $v structure to detect changes and update the
 * properties provided in `form` (valid, submittable, errors, classes, ...)
 * @param {Object} next
 * @returns {void}
 */
function watchVuelidate(next) {
  const { form } = this

  const prev = this.FormMixin_prev.$v
  const changed = !_isEqual(next, prev)

  if (form && changed) {
    form.valid =
      !next ||
      !next.form ||
      !next.form.fields ||
      (!next.$invalid && !next.form.$invalid && !next.form.fields.$invalid)

    form.invalid = !form.valid

    form.submittable = form.valid && (form.debounce > 0 || (!form.submitting && !form.disabled))

    form.touched = next && next.form && next.form.fields && next.form.fields.$anyDirty

    form.dirty = form.touched && !_isEqual(form.fields, form.initial)

    form.errors = extractErrors(next)

    form.classes = extractClasses(form.errors)

    // Update the form in the Vuex store
    updateSyncedForm.call(this, { ...form, meta: next })
  }

  this.FormMixin_prev.$v = _merge({}, next)
}

/**
 * Watch the field identified in the form by the provided 'path'.
 * The passed 'next' is the new value of this field, on change.
 * @param {String} path
 * @param {Object} next
 * @returns {void}
 */
function watchField(path, next) {
  const { form, $v, invalidMutateErrors } = this

  const prev = _get(this, `FormMixin_prev.form.fields.${path}`)
  const changed = !_isEqual(next, prev)

  if (form && changed) {
    // If a watcher is declared by a developer in 'form.watchers', let's call it
    const watchers = _get(form, `watchers.${path}`)

    if (watchers) {
      if (_isArray(watchers)) {
        watchers.forEach(w => w(next, prev))
      } else if (_isFunction(watchers)) {
        watchers(next, prev)
      }
    }

    // Find the validation metadata associated to this field
    const meta = _get($v, `form.fields.${path}`.replace(/\.(\d+)/gi, '.$each.$1'))

    // Inform Vuelidate that we touched this field
    if (meta && meta.$touch) {
      meta.$touch()
    }

    // Erase remote errors
    if (invalidMutateErrors) {
      invalidMutateErrors(_get(meta, '$params.remote.code'))
    }

    // If the auto-save is activated (i.e. 'form.debounce' > 0), we call the
    // debouncer corresponding to this field.
    if (form.debounce) {
      const isValid = !meta || !meta.$invalid

      submitWithDebounce.call(this, path, next, isValid, meta)
    }
  }
}

/**
 * Inspect the given 'fields' and 'meta' object to determine if the fonction
 * must be called on its children (if it is a "branch") or if a watcher must
 * be subscribed (if it is a "leaf", i.e. a field)
 * The 'path' allows to know the route made, and is kept up to date when going
 * deeper.
 * @param {Object} fields
 * @param {Object} meta
 * @param {String} path
 * @returns {void}
 */
function subscribeDeepFieldWatchers(fields, meta, path) {
  const { $watch } = this

  if (meta) {
    // Collection validation (array / object)
    // See: https://monterail.github.io/vuelidate/#sub-collections-validation
    if (meta.$each) {
      subscribeDeepFieldWatchers.call(this, fields, meta.$each, path)
      return
    }

    // If 'fields' is just a branch containing other fields to inspect deeper
    const isBranch =
      !!meta.$iter || !!_values(meta).find(v => v && _isPlainObject(v) && !!v.$params)

    if (isBranch) {
      const keys = Object.keys(meta).filter(k => !k.startsWith('$') && !k.startsWith('__'))

      // Watch the children
      keys.forEach(k => subscribeDeepFieldWatchers.call(this, fields[k], meta[k], `${path}.${k}`))
      return
    }
  }

  // If a subscription already exists for this field, first unsubscribe it
  const subscriptions = this.FormMixin_subscriptions

  if (!subscriptions[path]) {
    // Subscribe a new watcher for this field
    subscriptions[path] = $watch.call(this, `form.fields.${path}`, watchField.bind(this, path), {
      deep: true,
      immediate: false,
    })
  }
}

/**
 * Add watchers on the two global objects 'form' and '$v'.
 * If watchers already exists for them, they are removed and recreated.
 *
 * /!\  We do that to ensure that the field watchers are created before, and
 *      so fired before these two.
 */
function subscribeGlobalWatchers() {
  const { $watch } = this

  const subscriptions = this.FormMixin_subscriptions

  const options = {
    deep: true,
    immediate: true,
  }

  const globals = ['__fields__', '__form__', '__$v__']

  globals.forEach(key => {
    if (subscriptions[key]) {
      subscriptions[key]()

      options.immediate = false
    }
  })

  // Watch the whole form in order to sync it with Vuex on every changes
  subscriptions.__fields__ = $watch.call(this, 'form.fields', watchFormFields.bind(this), options)

  // Watch the whole form in order to sync it with Vuex on every changes
  subscriptions.__form__ = $watch.call(this, 'form', watchForm.bind(this), options)

  // Watch the Vuelidate $v object in order to keep our form states (valid,
  // touched, ...) up-to-date
  subscriptions.__$v__ = $watch.call(this, '$v', watchVuelidate.bind(this), options)
}

/**
 * Add a watcher for every found fields (both inpected by Vuelidate or not)
 * @returns {void}
 */
function subscribeWatchers() {
  const fields = _get(this, 'form.fields', {})

  Object.keys(fields).forEach(key => {
    const field = _get(this, `form.fields.${key}`)
    const meta = _get(this, `$v.form.fields.${key}`)

    subscribeDeepFieldWatchers.call(this, field, meta, key)
  })

  subscribeGlobalWatchers.call(this)
}

/* eslint-disable import/prefer-default-export */
export { subscribeWatchers }
