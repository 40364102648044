<template>
  <Field class="slider-field" :label="label" :error="error" :disabled="disabled">
    <slot v-if="$slots.label" slot="label" name="label" />

    <Slider
      :value="value"
      :min="min"
      :max="max"
      :interval="interval"
      :data="options"
      :tooltip="tooltip"
      :theme="theme"
      :labels="labels"
      :show-marks="showMarks"
      :disabled="disabled"
      @input="onInput"
      @change="onChange"
    />
  </Field>
</template>

<script>
  import _values from 'lodash/values'

  import Field from '@/core/layout/Field/Field'
  import Slider from '@/core/inputs/Slider/Slider'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
  Slider: Slider.__enums,
},

    name: 'SliderField',
    components: {
      Field,
      Slider,
    },
    props: {
      /**
       * Field's value
       */
      value: {
        type: [Number, String, Array],
        default: null,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Enumeration used to fill in the slider's values
       */
      type: {
        type: [Object, Array],
        default: undefined,
      },
      /**
       * Formatting function providing translations for handled options
       */
      formatter: {
        type: Function,
        default: undefined,
      },
      /**
       * Field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Minimum acceptable value
       */
      min: {
        type: Number,
        default: undefined,
      },
      /**
       * Maximum acceptable value
       */
      max: {
        type: Number,
        default: undefined,
      },
      /**
       * Interval
       */
      interval: {
        type: Number,
        default: undefined,
      },
      /**
       * If 'true', the slider displays a bubble for each step
       * on the track line
       */
      showMarks: {
        type: Boolean,
        default: false,
      },
      /**
       * Labels to display instead of min and max values
       * if set
       * @type {Array.<String>}
       */
      labels: {
        type: Array,
        default: null,
      },
      /**
       * When to display the tooltip can be ['hover', 'always', false]
       */
      tooltip: {
        type: String,
        default: 'always',
      },
      /**
       * Theme in which the Slider is integrated (can be "light" or "dark")
       */
      theme: {
        type: String,
        default: 'light',
      },
    },
    computed: {
      /**
       * List of all the available options provided in the slider's dropdown
       * If its an object, get the values from it.
       * If it's an array, just map over it.
       * @type {Array.<Object>}
       */
      options() {
        const { type, formatter } = this

        if (type && formatter) {
          return typeof type === 'object'
            ? _values(type).map(id => ({ id, name: formatter(id) }))
            : type.map(id => ({ id, name: formatter(id) }))
        }

        return undefined
      },
    },
    methods: {
      /**
       * Fired when the slider's value changes
       */
      onInput(value) {
        this.$emit('input', value)
      },
      /**
       * Fired when there's a change by dragging
       */
      onChange(value, dragging) {
        this.$emit('change', value, dragging)
      },
    },
  }
</script>
