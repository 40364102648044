import { __ } from '@comet/i18n'

import UserTypes from '@/types/user'

const { AuthProviders } = UserTypes

/**
 * Format authentication provider
 * eg: googleFreelance => 'google'
 * @param {key} key
 * @returns {String}
 */
function formatAuthProvider(key) {
  const trans = {
    [AuthProviders.GOOGLE]: __('ty:user:auth-provider:google'),
    [AuthProviders.GOOGLE_FREELANCE]: __('ty:user:auth-provider:google-freelance'),
    [AuthProviders.LINKED_IN]: __('ty:user:auth-provider:linked-in'),
  }

  return trans[key]
}

/* eslint-disable import/prefer-default-export */
export { formatAuthProvider }
