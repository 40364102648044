import AppTypes from '@/types/app'

/**
 * Custom form validation error types
 */
const ValidationError = {
  // Vuelidate built-in validators
  REQUIRED: 'required',
  REQUIRED_IF: 'requiredIf',
  REQUIRED_UNLESS: 'requiredUnless',
  MIN_LENGTH: 'minLength',
  MAX_LENGTH: 'maxLength',
  MIN_VALUE: 'minValue',
  BETWEEN: 'between',
  NUMERIC: 'numeric',
  INTEGER: 'integer',
  DECIMAL: 'decimal',
  EMAIL: 'email',
  SAME_AS: 'sameAs',
  URL: 'url',

  // Custom validators
  ADDRESS: 'address',
  ADDRESS_IF: 'addressIf',
  ADDRESS_FULL: 'addressFull',
  ADDRESS_FULL_IF: 'addressFullIf',
  BIC: 'bic',
  IBAN: 'iban',
  IS: 'is',
  OF_TYPE: 'ofType',
  PASSWORD: 'password',
  PERCENTAGE: 'percentage',
  PHONE_NUMBER: 'phoneNumber',
  REMOTE: 'remote',
  MAX_COUNT: 'maxCount',
  CHECK_CONDITION_WITH_ERROR: 'checkConditionWithError',
  REQUIRED_WITH_ERROR: 'requiredWithError',
  REQUIRED_IF_WITH_ERROR: 'requiredIfWithError',
  SIRET: 'siret',
  TJM: 'tjm',
  MISSION_DURATION: 'missionDuration',
  VAT_NUMBER: 'vatNumber',
  WORKPLACE_ADDRESS: 'workplaceAddress',
  ZIPCODE: 'zipCode',
  DAYS: 'days',
  WORKED_DAYS: 'workedDays',
  SOCIAL_CAPITAL: 'socialCapital',
  LINKED_IN_URL: 'linkedInUrl',
  ...AppTypes.ErrorTypes,
}

/**
 * List of error that will not be formatted by the formMixin but directly by
 * a component
 */
const UnformattedError = {
  BOUNDED_FIELD: 'boundedField',
}

/**
 * Form types
 */
const FormTypes = {
  UnformattedError,
  ValidationError,
}

export default FormTypes
