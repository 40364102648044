<script>
  import _throttle from 'lodash/throttle'
  import { mapGetters } from 'vuex'

  export default {
    name: 'WithScrollValue',
    data() {
      return {
        /**
         * Value of the scrollY on the window
         */
        scrollY: 0,
        /**
         * Value of the scrollX on the window
         */
        scrollX: 0,
      }
    },
    computed: {
      ...mapGetters(['isTeamMember']),
    },
    created() {
      const { isTeamMember } = this

      const scrollElement = isTeamMember ? document.getElementById('backoffice-content') : window

      // Add a listener to update the value of scrollX and scrollX when
      // the user scroll
      const listener = _throttle(() => {
        this.scrollY = scrollElement.scrollTop
        this.scrollX = scrollElement.scrollLeft
      }, 20)

      scrollElement.addEventListener('scroll', listener)

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('scroll', listener)
      })
    },
    render() {
      const { scrollY, scrollX } = this

      return this.$scopedSlots.default({ scrollY, scrollX })
    },
  }
</script>
