<template>
  <VMenu
    v-bind="$attrs"
    open-on-hover
    :open-on-click="false"
    :open-delay="750"
    :close-delay="200"
  >
    <template v-slot:activator="{on}">
      <slot
        name="activator"
        :on="on"
      />
    </template>

    <iframe
      :src="`https://www.google.com/maps/embed/v1/view?zoom=11&center=${latitude},${longitude}&key=${GMAPS_API_KEY}`"
      :class="$style.iframe"
      frameborder="0"
      allowfullscreen
      :width="width"
      :height="height"
    ></iframe>
  </VMenu>
</template>

<script>
  export default {
    name: 'CTooltipMap',
    props: {
      /**
       * Latitude value used to center the map
       * @type {Number}
       */
      latitude: {
        type: Number,
        required: true,
      },
      /**
       * Longitude value used to center the map
       * @type {Number}
       */
      longitude: {
        type: Number,
        required: true,
      },
      /**
       * Width constraint applied to the map's tooltip
       * @type {Number}
       */
      width: {
        type: Number,
        default: 480,
      },
      /**
       * Height constraint applied to the map's tooltip
       * @type {Number}
       */
      height: {
        type: Number,
        default: 360,
      },
    },
    constants: {
      GMAPS_API_KEY: process.env.VUE_APP_GMAPS_APIKEY,
    },
  }
</script>

<style lang="stylus" module>
  .iframe {
    border: none !important
    pointer-events: auto
  }
</style>
