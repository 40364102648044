<template>
  <Multiselect
    :id="id"
    ref="multiselect"
    :class="{
      ['fuzzy-multiselect']: true,
      [$('no-items')]: items.length < 1,
    }"
    v-bind="$attrs || {}"
    :value="value"
    :internal-search="false"
    :options="items"
    v-on="forwardedListeners"
    @select="onSelect"
    @search-change="onSearchChange"
  >
    <template v-for="(slot, name) in $slots" :slot="name">
      <slot :name="name" />
    </template>

    <template v-for="(slot, name) in $scopedSlots" :slot="name" slot-scope="multiselect">
      <slot :name="name" v-bind="multiselect" />
    </template>

    <template slot="option" slot-scope="{ option }">
      <template v-if="option.id === SUGGESTED_OPTION_ID">
        <span :class="$('suggestion-prefix')">
          {{ __('cp:form:fuzzy-multiselect:suggestion-prefix') }}
        </span>
        <span>
          {{ option.name }}
        </span>
      </template>
      <span v-else>
        {{ option.name }}
      </span>
    </template>
  </Multiselect>
</template>

<script>
  import _pick from 'lodash/pick'
  import _omit from 'lodash/omit'
  import _uniqBy from 'lodash/uniqBy'

  import Multiselect from 'vue-multiselect'
  import FuzzySet from 'fuzzyset.js'

  import I18nMixin from '@/mixins/I18nMixin'

  // Temporary ID to assign to dynamic suggested options
  const SUGGESTED_OPTION_ID = -1

  // How many options to display
  const MAX_ITEMS = 10

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Multiselect: Multiselect.__enums,
},

    name: 'FuzzyMultiselect',
    components: {
      Multiselect,
    },
    mixins: [I18nMixin],
    inheritAttrs: false,
    props: {
      /**
       * Unique identifier used to link the Field's label to its input
       */
      id: {
        type: String,
        default: null,
      },
      /**
       * Currently selected values
       * @type {Array.<Object>}
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Initial options for the multiselect
       * Array of objects containing an "id" and "name"
       * @type {Array.<Object>}
       */
      options: {
        type: Array,
        required: true,
      },
      /**
       * Function to extract search keywords out of an option
       * Will extract the "name" property by default
       * @type {Function}
       */
      extractKeywords: {
        type: Function,
        default: option => [option.name],
      },
      /**
       * A function called whenever the user suggests a new option
       * The function should return a promise containing the added option
       * Omit this prop to disallow options suggestion
       * @type {Function}
       */
      onOptionSuggestion: {
        type: Function,
        default: null,
      },
      /**
       * Keep the focus on the multiselect while selecting.
       * This is only here because on /enterprise/mission/create
       * the remove button is not actionnable directly (in 1 click)
       * while the multiselect is focus.
       */
      keepFocus: {
        type: Boolean,
        default: false,
      },
      /**
       * Show all options before typing anything
       */
      showAllOptions: {
        type: Boolean,
        default: false,
      },
    },
    constants: {
      SUGGESTED_OPTION_ID,
    },
    data() {
      return {
        /**
         * Search query updated by the multiselect component
         * @type {String}
         */
        searchQuery: null,
      }
    },
    computed: {
      /**
       * Listeners that should be forwarded to the base Multiselect component
       * @type {Object}
       */
      forwardedListeners() {
        return _omit(this.$listeners, ['select', 'input'])
      },
      /**
       * Keywords object use to lookup options efficiently
       * @type {Object}
       */
      keywords() {
        const { options, extractKeywords } = this

        // Transforms the option array into an object
        // keyed by each keyword to its respective option
        return options.reduce((obj, option) => {
          extractKeywords(option).forEach(key => {
            obj[key] = option // eslint-disable-line no-param-reassign
          })
          return obj
        }, {})
      },
      /**
       * Fuzzy set with the entire list of options
       * @type {FuzzySet}
       */
      fuzzyset() {
        const { keywords } = this

        return new FuzzySet(Object.keys(keywords))
      },
      /**
       * Filtered options
       * @type {Array.<Object>}
       */
      items() {
        const {
          searchQuery,
          onOptionSuggestion,
          options,
          showAllOptions,
          plainSearch,
          fuzzySearch,
          exactSearch,
        } = this

        if (showAllOptions && !searchQuery) {
          return options
        }

        if (!searchQuery) {
          return []
        }

        const results = [...plainSearch(searchQuery), ...fuzzySearch(searchQuery)]

        // Check if there is a exact match for the text written by the user
        const exactMatch = exactSearch(searchQuery)

        // If there is then we put it in the first place in the result list
        if (exactMatch) {
          results.unshift(exactMatch)
        }

        // Remove duplicates & limit to 10 options
        const items = _uniqBy(results, option => option.id).slice(0, MAX_ITEMS)

        // Suggest the typed skill at the bottom of the list
        // if we can't find an exact match for it
        if (onOptionSuggestion && !exactMatch) {
          // Either replace the last element or append one based
          // on whether we've reached the limit
          const index = items.length < MAX_ITEMS ? items.length : items.length - 1

          items[index] = {
            id: SUGGESTED_OPTION_ID,
            name: searchQuery,
          }
        }

        return items
      },
    },
    methods: {
      /**
       * Filter options with a plain text search for the given query
       * @param {String} query
       * @returns {Array.<Object>}
       */
      plainSearch(query) {
        const { options, extractKeywords } = this

        return options.filter(option =>
          extractKeywords(option).find(
            keyword => keyword.toLowerCase().indexOf(query.toLowerCase()) > -1,
          ),
        )
      },
      /**
       * Find an exact match for the given query
       * @param {String} query
       * @returns {Object}
       */
      exactSearch(query) {
        const { options, extractKeywords } = this

        return options.find(option =>
          extractKeywords(option).find(keyword => keyword.toLowerCase() === query.toLowerCase()),
        )
      },
      /**
       * Filter options with a fuzzy search for the given query
       * @param {String} query
       * @returns {Array.<Object>}
       */
      fuzzySearch(query) {
        const { fuzzyset, keywords } = this

        // Apply filter and transform its output to an exploitable format
        // Fuzzyset.get() returns an array of results
        // Each element contains the string result along with its score
        // e.g: [[1, "JavaScript"], [0.78, "Java"]]
        // When no results are found, the method returns null
        // @see https://github.com/Glench/fuzzyset.js#methods
        const fuzzyResults = (fuzzyset.get(query) || []).map(([, result]) => result)

        return Object.values(_pick(keywords, fuzzyResults))
      },
      /**
       * Triggered when the search query changes
       * Update the search query from the component
       * @param {String} searchQuery
       * @returns {void}
       */
      onSearchChange(searchQuery) {
        this.searchQuery = searchQuery.trim()
      },
      /**
       * Triggered when an element of the list is select
       * Suggest new elements when applicable
       * Otherwise, forward the event up the chain
       * @async
       * @param {Object} item
       * @returns {Promise}
       */
      async onSelect(option) {
        const { onOptionSuggestion, keepFocus } = this
        const value = this.value || []

        if (keepFocus) {
          this.$refs.multiselect.$el.focus()
        }

        // Simply re-emit select/input events if
        // options suggestions is disabled or if the option already exists
        if (!onOptionSuggestion || option.id !== SUGGESTED_OPTION_ID) {
          this.$emit('select', option)
          this.$emit('input', [...value, option])
          return
        }

        // Suggest the option then re-emit select/input events with it
        const suggestedOption = await onOptionSuggestion(option.name)
        if (suggestedOption) {
          this.$emit('select', suggestedOption)
          this.$emit('input', [...value, suggestedOption])
        }
      },
    },
  }
</script>

<style lang="stylus">
  @import '~@/assets/css/_variables.styl'

  .fuzzy-multiselect {
    &-suggestion-prefix {
      font-weight: $font-bold
    }

    &-no-items .multiselect__content {
      display: none !important
    }

    input:placeholder-shown:not(:hover):not(:focus) {
      background: var(--color-input-background-idle)
      border-color: var(--color-input-background-idle)
    }

    &.multiselect {
      position: relative

      &:not(.multiselect--disabled) {
        &:hover .multiselect__input {
          border-color: var(--color-input-highlight)
        }

        &.multiselect--active .multiselect__input {
          border-color: var(--color-input-highlight)
        }
      }

      .multiselect__input {
        display: block !important
        width: 100% !important
        position: relative !important
        height: 40px
        padding: 0 44px !important
        border-radius: $radius-medium
        background-color: var(--color-background) !important
        border: solid var(--color-input-border) 1px
        font-family: $font
        font-weight: $font-regular
        font-size: 16px
        box-sizing: border-box
        box-shadow: none
        appearance: none

        &:hover {
          border-color: var(--color-input-highlight)
        }

        &:disabled {
          opacity: .5
          background-color: transparent
        }
      }

      .multiselect__content {
        position: absolute
        width: 100%
        max-height: 191px
        padding: 8px !important
        margin: 8px 0
        background-color: var(--color-background)
        box-sizing: border-box
        box-shadow: 0px 2px 8px rgba(0, 0, 0, .2)
        overflow-y: scroll
        z-index: 2
      }

      .multiselect__option {
        display: block
        padding: 8px 24px
        font-weight: $font-regular

        &:after {
          font-size: 13px
          float: right
        }

        &--highlight {
          background: var(--color-input-highlight)
          color: var(--color-font-contrast)

          &:after {
            content: attr(data-select)
          }
        }

        &--selected {
          background-color: var(--color-input-options-hover)
          color: var(--color-font)

          &:after {
            content: attr(data-selected)
          }
        }

        &--highlight.multiselect__option--selected {
          background: var(--color-negative)
          color: var(--color-font-contrast)

          &:after {
            content: attr(data-deselect)
          }
        }
      }

      .multiselect__tags {
        &-wrap {
          display: none
        }

        span.multiselect__single {
          display: none
        }
      }

      .multiselect__placeholder {
        display: none
        color: var(--color-input-placeholder)
      }
    }
  }
</style>
