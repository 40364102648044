<template>
  <div
    v-if="items.length > itemsPerPage"
  >
    <span
      class="grey--text mr-1"
      v-text="`${currentPage + 1} / ${totalPages}`"
    />

    <VBtn
      :icon="true"
      :x-small="true"
      :disabled="currentPage === 0"
      @click="onPreviousPage()"
    >
      <VIcon v-text="'$prev'" />
    </VBtn>

    <VBtn
      :icon="true"
      :x-small="true"
      :disabled="currentPage === totalPages - 1"
      @click="onNextPage()"
    >
      <VIcon v-text="'$next'" />
    </VBtn>
  </div>
</template>

<script>
  export default {
    name: 'CPagination',
    props: {
      /**
       * List of items
       * @type {Array.<Object>}
       */
      items: {
        type: Array,
        default: null,
      },
      /**
       * List of paginated items
       * @type {Array.<Object>}
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Number of items per page
       */
      itemsPerPage: {
        type: Number,
        default: 5,
      },
    },
    computed: {
      /**
       * Total pages number
       * @type {Number}
       */
      totalPages() {
        const { items, itemsPerPage } = this
        return items.length % itemsPerPage === 0
          ? items.length / itemsPerPage
          : Math.floor(items.length / itemsPerPage) + 1
      },
    },
    data() {
      return {
        /**
         * Current page for pagination
         * @type {Number}
         */
        currentPage: 0,
        /**
        * List of paginated items
        * @type {Array.<Object>}
        */
        paginatedItems: this.items.slice(0, this.itemsPerPage),
      }
    },
    mounted() {
      this.$emit('input', this.paginatedItems)
    },
    methods: {
      /**
       * Called when clicking on the pagination "Previous" button
       */
      onPreviousPage() {
        const { items, itemsPerPage } = this

        this.currentPage = this.currentPage - 1
        this.paginatedItems = items.slice(this.currentPage * itemsPerPage, (this.currentPage + 1) * itemsPerPage)

        this.$emit('input', this.paginatedItems)
      },
      /**
       * Called when clicking on the pagination "Next" button
       */
      onNextPage() {
        const { items, itemsPerPage } = this

        this.currentPage = this.currentPage + 1
        this.paginatedItems = items.slice(this.currentPage * itemsPerPage, (this.currentPage + 1) * itemsPerPage)

        this.$emit('input', this.paginatedItems)
      },
    },
  }
</script>
