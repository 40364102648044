<template>
  <div :class="$style.root">
    <VImg
      :src="img || require('./default.svg')"
      :width="160"
      :height="43"
      contain
      :class="$style.logo"
    />
  </div>
</template>

<script>
  export default {
    name: 'CSplash',
    props: {
      img: {
        type: Object,
        default: null,
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    min-width: 100vw
    min-height: 100vh
    flex: 1
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    box-sizing: border-box
  }

  .logo {
    margin-bottom: 20px
    opacity: 0
    animation: blink 2s linear infinite
  }

  @keyframes blink {
    0% {
      opacity: .4
    }
    5% {
      opacity: .4
    }
    25% {
      opacity: .55
    }
    45% {
      opacity: .9
    }
    55% {
      opacity: .9
    }
    75% {
      opacity: .55
    }
    95% {
      opacity: .4
    }
    100% {
      opacity: .4
    }
  }
</style>
