<template>
  <div v-if="shown" :class="classes">
    <button type="button" :class="$style.sign" @click="navigate('/')">
      {{ env }}
    </button>

    <button
      v-if="impersonating"
      type="button"
      :class="$style.impersonate"
      @click="restoreImpersonate"
      v-html="
        __('cp:core:safety-banner:impersonating', {
          impersonating: impersonating.firstName,
          impersonated: user.fullName,
        })
      "
    ></button>
  </div>
</template>

<script>
  import _get from 'lodash/get'
  import { mapGetters, mapActions } from 'vuex'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import I18nMixin from '@/mixins/I18nMixin'
  import RouterMixin from '@/mixins/RouterMixin'

  import config from '@/config'

  import restoreImpersonate from './restoreImpersonate.gql'

  export default {
    name: 'SafetyBanner',
    mixins: [I18nMixin, ApolloMixin, RouterMixin],
    computed: {
      ...mapGetters({
        user: 'selectUser',
      }),
      /**
       * Wether or not we're currently impersonating an user
       * @type {Boolean}
       */
      impersonating() {
        const { user } = this

        return _get(user, 'impersonating')
      },
      /**
       * Environment in which the banner is displayed
       * @type {String}
       */
      env() {
        const { impersonating } = this

        // Quick hack to have a different banner for staging and andromeda
        // without touching to the build conf (i.e. adding a new build
        // environment)
        if (config.publicURL.includes('-app.staging.comet.dev')) {
          return 'andromeda'
        }

        return config.banner === null && !!impersonating ? 'production' : config.banner || null
      },
      /**
       * Wether to show the safety banner or not according to the given props
       * @type {Boolean}
       */
      shown() {
        return !!(this.env || this.impersonating)
      },
      /**
       * Compute the root element classes according to the given props
       * @type {Object}
       */
      classes() {
        const { $style, env, impersonating } = this

        return {
          [$style.banner]: true,
          [$style[env]]: !!env,
          [$style.impersonating]: !!impersonating,
        }
      },
    },
    methods: {
      ...mapActions(['revoke']),
      /**
       * Restore to the impersonating user and redirect to the backoffice
       * @return {Promise}
       */
      async restoreImpersonate() {
        const { mutate, revoke, navigate } = this

        const options = {
          track: 'enterprise.nav.account.restore-impersonate',
        }

        const res = await mutate(restoreImpersonate, options)
        const path = _get(res, 'data.restoreImpersonate.metadata.path', '/backoffice')

        await revoke()
        await navigate(`/backoffice/redirect?to=${path}`)
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_variables.styl'
  @import '~@/assets/css/_layout.styl'

  .banner {
    width: 100%
    height: 8px
    position: fixed
    top: 0
    right: 0
    z-index: 100       // Volontary high to be sure to never be surpassed
                       // /!\ Do not do this in other use cases

    &.development {
      background: deepskyblue

      .impersonate {
        background: mediumblue
      }
    }
    &.dev {
      background: deepskyblue

      .impersonate {
        background: mediumblue
      }
    }

    &.andromeda {
      background: mediumpurple

      .impersonate {
        background: darkorchid
      }
    }

    &.staging {
      background: crimson

      .impersonate {
        background: darkred
      }
    }

    &.production {
      background: mediumseagreen

      .impersonate {
        background: forestgreen
      }
    }

    .sign,
    .impersonate {
      padding: 0
      color: white
      background: inherit
      border: none
      outline: none
    }

    .sign {
      width: 160px
      height: 24px
      position: absolute
      top: 0
      right: 0
      font-size: 14px
      line-height: 24px
      font-weight: $font-bold
      text-align: center
      text-transform: uppercase
    }

    .impersonate {
      width: auto
      height: 22px
      position: absolute
      top: 2px
      right: 160px
      font-size: 14px
      line-height: 22px
      padding: 0 12px
      background: var(--color-negative)

      @media phone {
        overflow: hidden
        font-size: 8px
      }
    }
  }

  @media print {
    .banner {
      display: none
    }
  }
</style>
