import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _values from 'lodash/values'

/**
 * Remove all the subscribed watchers
 * @returns {void}
 */
function unsubscribeWatchers() {
  const subscriptions = this.FormMixin_subscriptions
  const globals = ['__fields__', '__form__', '__$v__']
  const fields = _omit(subscriptions, globals)

  _values(fields).forEach(unsubscriber => unsubscriber())

  this.FormMixin_subscriptions = _pick(subscriptions, globals)
}

/* eslint-disable import/prefer-default-export */
export { unsubscribeWatchers }
