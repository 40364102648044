<template>
  <div class="language">
    <div class="language-name">
      <i class="icon-check-circle" />
      <span>{{ value.name }}</span>
      <NavButton
        type="button"
        :disabled="disabled"
        icon="icon-remove"
        theme="secondary-void"
        @click.prevent="onRemovePress"
      />
    </div>
    <div class="language-level">
      <SingleSelect :value="value.level" :options="levelOptions" @select="onSelectLevel" />
    </div>
  </div>
</template>

<script>
  import _values from 'lodash/values'

  import NavButton from '@/core/controls/NavButton/NavButton'
  import SingleSelect from '@/core/inputs/SingleSelect/SingleSelect'
  import FreelancerTypes from '@/types/freelancer'
  import { formatLanguageLevel } from '@/utils/format'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
  SingleSelect: SingleSelect.__enums,
},

    name: 'Language',
    components: {
      NavButton,
      SingleSelect,
    },
    props: {
      /**
       * A language
       * @type {Object}
       */
      value: {
        type: Object,
        default: () => {},
      },
      /**
       * Disable all the controls
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      levelOptions() {
        return _values(FreelancerTypes.LanguageLevel).map(id => ({
          id,
          name: formatLanguageLevel(id),
        }))
      },
    },
    methods: {
      /**
       * Handler called when the language level is modified
       * @param {String} level
       * @returns {void}
       */
      onSelectLevel(level) {
        const { value } = this

        const newValue = {
          ...value,
          level,
        }

        this.$emit('input', newValue)
      },
      /**
       * Handler called when the language is intended to be removed
       * @param {String} level
       * @returns {void}
       */
      onRemovePress() {
        const { value } = this

        this.$emit('remove', value.id)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_grid.styl'
  @import '~@/assets/css/_variables.styl'

  .language {
    content-grid()

    line-height: 40px

    i {
      font-size: 20px
    }

    .language-name {
      content-grid-column(4, 5, 7)

      display: flex
      flex-direction: row
      align-items center
      border: 1px solid var(--color-input-border)
      border-radius: $radius-medium
      padding-left: 16px

      > * {
        line-height: 24px
        vertical-align: middle
      }

      > span {
        flex: 1
      }

      > i {
        margin-right: 8px
      }
    }

    .language-level {
      content-grid-column(4, 3, 5)
    }
  }
</style>
