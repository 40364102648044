<template>
  <AppLink
    class="action-card"
    :class="classes"
    :disabled="disabled"
    :to="to"
    @mouseenter.native="isHovered = true"
    @mouseleave.native="isHovered = false"
    @click="onClick"
  >
    <Box v-bind="$props" :class="$('box')">
      <Illustration animation="badge" :path="icon" :animate="!disabled && isHovered" />

      <Headings
        :level="titleLevel"
        :ellipsis="false"
        :title="title"
        :sub="subtitle"
        :theme="textTheme"
        :align="Headings.Align.CENTER"
      />
    </Box>
  </AppLink>
</template>

<script>
  import AppLink from '@/core/controls/AppLink/AppLink'
  import Box from '@/core/layout/Box/Box'
  import Illustration from '@/core/graphics/Illustration/Illustration'
  import Headings from '@/core/text/Headings/Headings'

  const TitleLevel = Headings.Level
  const TextTheme = Headings.Theme

  export const ActionCardTitleLevel = TitleLevel
  export const ActionCardTextTheme = TextTheme

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  Box: Box.__enums,
  Illustration: Illustration.__enums,
  Headings: Headings.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ TitleLevel,TextTheme,
    name: 'ActionCard',
    __enums: {
      TitleLevel,
      TextTheme,
    },
    components: {
      AppLink,
      Box,
      Illustration,
      Headings,
    },
    props: {
      ...Box.props,
      /**
       * Icon of the card
       */
      icon: {
        required: true,
        type: String,
      },
      /**
       * Is the link disabled ?
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Title of the card
       */
      title: {
        type: String,
        default: null,
      },
      /**
       * Title Level of the card
       * @type {ActionCardTitleLevel}
       */
      titleLevel: {
        type: String,
        default: ActionCardTitleLevel.LEVEL4,
      },
      /**
       * Link of the card
       */
      to: {
        type: String,
        default: null,
      },
      /**
       * Subtitle of the card
       */
      subtitle: {
        type: String,
        default: null,
      },
      /**
       * Theme
       * @type {ActionCard.TextTheme}
       */
      textTheme: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Is the card hovered ?
         * @type {Boolean}
         * */
        isHovered: false,
      }
    },
    computed: {
      /**
       * CSS classes applied to the card
       * @type {Object.<Boolean>}
       */
      classes() {
        const { disabled } = this

        return {
          'action-card--disabled': disabled,
        }
      },
    },
    methods: {
      /**
       * Fired when the link is clicked
       * @returns {void}
       */
      onClick(event) {
        const { disabled } = this

        if (!disabled) {
          this.$emit('click', event)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_grid.styl'

  .action-card {
    .action-card-box {
      display: flex
      flex-direction: column
      align-items: center
      justify-items: center
      padding: 48px
    }

    &--disabled {
      opacity: .5
    }

    >>> .heading {
      text-align: center
    }
  }
</style>
