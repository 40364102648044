<template>
  <iframe :src="url" frameborder="0" style="width: 100%; min-height: 100vh;" />
</template>

<script>
  export default {
    name: 'CIFrameForV2FlexibleContent',
    props: {
      url: {
        type: String,
        required: true,
      },
    },
  }
</script>
