<template>
  <VInput
    ref="root"
    v-bind="$attrs"
    :class="{
      [$style.root]: true,
      [$style.empty]: empty,
      [$style.dense]: dense,
      [$style.autoGrow]: autoGrow,
    }"
    :dense="dense"
    v-on="_omit($listeners, ['input'])"
  >
    <div
      ref="container"
      :style="containerStyle"
      :class="$style.container"
    >
      <div
        ref="input"
        contenteditable
        :placeholder="placeholder"
        :style="inputStyle"
        :class="$style.input"
        @input="onContentInput"
        @focus="onContentFocus"
        @blur="onContentBlur"
        @click="onContentClick"
        @keydown="onContentKeyDown"
        @copy="onContentCopy"
        v-html="html"
      />

      <VMenu
        ref="menu"
        :value="suggestions && suggestions.length"
        rounded
        :nudge-left="-coordinates.x"
        :nudge-top="-coordinates.y"
        :min-width="360"
        :max-width="360"
        :max-height="240"
        :class="$style.menu"
      >
        <VList
          ref="list"
          dense
          class="pa-1"
        >
          <template v-for="(item, i) in items">
            <VListItem
              ref="items"
              :key="item.key"
              :input-value="i === selected"
              dense
              color="primary"
              class="py-0"
              @click="e => onSuggestionPress(e, item.suggestion)"
              @keydown="e => onSuggestionKeyDown(e, item.suggestion)"
            >
              <VListItemAvatar
                :size="24"
                class="my-1 mr-3"
              >
                <VImg
                  v-if="item.avatar"
                  :src="item.avatar"
                />
              </VListItemAvatar>

              <VListItemContent class="py-2">
                <VListItemTitle>
                  <span
                    class="subtitle-2"
                    v-html="item.title"
                  />

                  <VChip
                    pill
                    x-small
                    outlined
                    disabled
                    label
                    color="secondary"
                    class="font-weight-semi-bold px-1 ml-3"
                    v-text="item.type"
                  />
                </VListItemTitle>
              </VListItemContent>

              <VListItemAction class="my-1 ml-3">
                <div
                  class="caption font-weight-light secondary--text"
                  v-text="item.id"
                />
              </VListItemAction>
            </VListItem>
          </template>
        </VList>
      </VMenu>
    </div>
  </VInput>
</template>

<script>
  import { computeBackofficeUrl } from '@comet/urls'

  import _omit from 'lodash/omit'
  import _sortBy from 'lodash/sortBy'
  import _uniq from 'lodash/uniq'
  import _values from 'lodash/values'

  import query from './query.gql'

  const URL_REGEX = /((https?:\/\/)(www\.)?|(www.))[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/g
  const MENTION_REGEX = new RegExp('\\#\\{(\\w{1,2})\\|(\\d+)\\|([^}]+)\\}', 'g')
  const EMAIL_REGEX = /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/g
  const PHONE_REGEX = /(\+33\s?[1-9](\s?[1-9]{2}){4}|0[1-9]\s?(\s?[1-9]{2}){4})/g

  /**
   * Gather the different types of chunks used to cut the received
   * text ('value'), and then efficiently analyze and manipulate it.
   * @type {Object}
   */
  const CHUNK_TYPES = {
    TEXT: 'text',
    BREAK: 'break',
    MENTION: 'mention',
    EMAIL: 'email',
    URL: 'url',
    PHONE: 'phone',
  }

  /**
   * List of available mention prefix characters and their linked
   * query type send to the suggestion API endpoint.
   * @type {Object}
   */
  const MENTION_QUERY_SCOPE = {
    '@': 'users',
    '#': 'objects',
  }

  /**
   * Hashmap connecting the mention prefixes to the resource type patterns
   * used in the mention format stored in database.
   * (ex : "#{F|123|Jo Freelance}")
   * @type {Object}
   */
  const MENTION_PREFIXES = {
    F: '@',
    C: '@',
    T: '@',
    M: '#',
    CP: '#',
  }

  /**
   * List of recognized prefixes characters for mentions
   * @type {Array.<String>}
   */
  const MENTION_CHARACTERS = _uniq(_values(MENTION_PREFIXES))

  /**
   * Redirecting URLs according to the resource mentioned.
   * @type {Object}
   */
  const MENTION_URLS = {
    F: (id, name) => computeBackofficeUrl('freelancer', { freelancer: { id, user: {fullName: name} }}),
    C: (id, name) => computeBackofficeUrl('corporate', { corporate: { id, user: {fullName: name} }}),
    T: (id, name) => computeBackofficeUrl('team', { teamMember: { id, user: {fullName: name} }}),
    M: (id, name) => computeBackofficeUrl('mission', { mission: { id, title: name }}),
    CP: (id, name) => computeBackofficeUrl('company', { company: { id, name }}),
  }

  /**
   * Identity card tooltips according to the resource mentioned.
   * @type {Object}
   */
  const MENTION_TOOLTIPS = {
    F: 'freelancer',
    C: 'corporate',
    CP: 'company',
    T: 'teamMember',
    M: 'mission',
  }

  /**
   * Resource type patterns (used in the mention format stored in database)
   * according to the resource type (GraphQL type instead) received in
   * the 'suggestions'.
   * @type {Object}
   */
  const RESOURCE_TYPE_TO_MENTIONS = {
    Freelance: 'F',
    Corporate: 'C',
    Company: 'CP',
    TeamMember: 'T',
    Mission: 'M',
  }

  /**
   * Extract all the detected "tokens" (mentions, urls, line breaks) in
   * the given 'value' and returns related metadata, in the right order.
   * @param {String} value
   * @returns {Array.<Object>}
   */
  function extractTokens(value) {
    const tokens = []

    // URLS
    const urls = [...value.matchAll(URL_REGEX)]

    urls.forEach((match) => {
      const [ value ] = match

      const absoluteMatch = value.startsWith('http')
        ? value
        : `https://${value}`

      tokens.push({
        type: CHUNK_TYPES.URL,
        value,
        text: value,
        html: `<a \
          onclick="window.open('${absoluteMatch}', '_blank').focus()" \
          href="${absoluteMatch}" \
          target="_blank" \
          rel="external noopener noreferrer"\
        >${value}</a>`,
        offset: match.index,
      })
    })

    // EMAILS
    const emails = [...value.matchAll(EMAIL_REGEX)]

    emails.forEach((match) => {
      const [ value ] = match

      tokens.push({
        type: CHUNK_TYPES.EMAIL,
        value,
        text: value,
        html: `<a \
          href="mailto:${value}" \
          target="_blank" \
          rel="external noopener noreferrer"\
        >${value}</a>`,
        offset: match.index,
      })
    })

    // PHONES
    const phones = [...value.matchAll(PHONE_REGEX)]

    phones.forEach((match) => {
      const [ value ] = match
      const condensed = value.replace(/\s/g, '')

      tokens.push({
        type: CHUNK_TYPES.PHONE,
        value,
        text: value,
        html: `<a \
          href="tel:${condensed}" \
          target="_blank" \
          rel="external noopener noreferrer"\
        >${value}</a>`,
        offset: match.index,
      })
    })

    // MENTIONS
    const mentions = [...value.matchAll(MENTION_REGEX)]

    mentions.forEach((match) => {
      const [ value, type, id, name ] = match

      const url = MENTION_URLS[type]?.(id, name)
      const text = MENTION_PREFIXES[type] + name
      const html = `<a \
        data-tooltip="${MENTION_TOOLTIPS[type]}" \
        data-id="${id}"
        onclick="window.open('${url}', '_blank').focus()" \
        href="${url}" \
        target="_blank" \
        rel="external noopener noreferrer"\
      >${text}</a>`

      tokens.push({
        type: CHUNK_TYPES.MENTION,
        value,
        text,
        html: html.replace(/___/g, ' '),
        offset: match.index,
      })
    })

    // LINE BREAKS
    const breaks = [...value.matchAll(/\n/gm)]

    breaks.forEach((match) => {
      tokens.push({
        type: CHUNK_TYPES.BREAK,
        value: '\n',
        text: '\n',
        html: '</div><div>',
        offset: match.index,
      })
    })

    // Re-order before to return
    return _sortBy(tokens, t => t.offset)
  }

  /**
   * Split the given 'value' in an ordered list of chunks providing metadata for
   * each chunk (text, html, offsets, ...).
   * First, the "tokens" are extracted (mentions, urls & line breaks), and then
   * bounding and middle texts are extracted too.
   * @param {String} value
   * @returns {Array.<Object>}
   */
  function extractChunks(value) {
    const tokens = extractTokens(value)

    let part = null
    let valueOffset  = 0
    let textOffset = 0
    let htmlOffset = 0

    // No token detected (i.e. plain text)
    if (!tokens.length) {
      return [{
        type: CHUNK_TYPES.TEXT,
        value: value,
        text: value,
        html: value,
        valueOffset,
        textOffset,
        htmlOffset,
      }]
    }

    let chunks = []

    // Text detected before the first token
    if (tokens[0].offset !== 0) {
      part = value.substring(0, tokens[0].offset)

      chunks.push({
        type: CHUNK_TYPES.TEXT,
        value: part,
        text: part,
        html: part,
        valueOffset,
        textOffset,
        htmlOffset,
      })

      valueOffset = textOffset = htmlOffset += part.length
    }

    // For each token (and potential text settled next)
    tokens.forEach((t, i) => {
      chunks.push({
        type: t.type,
        value: t.value,
        text: t.text,
        html: t.html,
        valueOffset,
        textOffset,
        htmlOffset,
      })

      valueOffset += t.value.length
      textOffset += t.text.length
      htmlOffset += t.html.length

      if (i < tokens.length - 1 && valueOffset < tokens[i + 1].offset) {
        // Not the last token and text detected before the next token
        part = value.substring(valueOffset, tokens[i + 1].offset)
      } else if (i >= tokens.length - 1 && valueOffset < value.length) {
        // Last token and text detected until EOF
        part = value.substring(valueOffset)
      } else {
        part = null
      }

      if (part) {
        chunks.push({
          type: CHUNK_TYPES.TEXT,
          value: part,
          text: part,
          html: part,
          valueOffset,
          textOffset,
          htmlOffset,
        })

        valueOffset += part.length
        textOffset += part.length
        htmlOffset += part.length
      }
    })

    return chunks
  }

  /**
   * Returns the index of the chunk containing the given "text" 'offset'.
   * @param {Array.<Object>} chunks
   * @param {Number} offset
   * @returns {Number}
   */
  function findChunkFromTextOffset(chunks, offset) {
    if (!chunks?.length || offset < 0) {
      return -1
    }

    // When a caret is between to chunk, we consider it lies in the next
    // chunk (not the previous one)
    let index = chunks.findIndex(c => c.textOffset > offset)

    if (index < 0) {
      index = chunks.length - 1
    } else if (index > 0) {
      index -= 1
    }

    return index
  }

  /**
   * Extract the text content from the given HTML 'node', handling
   * manually every line breaks (as 'textContent' does not contain
   * them).
   * This is the text we use then in every event handlers to detect
   * changes, and emit the well formatted new 'value'.
   * @param {HTMLElement} node
   * @returns {String}
   */
  function extractTextFromNode(node) {
    const children = [...node?.childNodes]

    const transformTagsIntoText = (n) => {
      if (n.tagName === 'DIV' || n.tagName === 'A') {
        const next = [...n?.childNodes].map(n => transformTagsIntoText(n)).join('')

        // Add a line break for every encountered <div>,  except the first one
        return n.tagName === 'DIV' && n !== node.childNodes[0]
          ? `\n${next}`
          : next
      }

      return n.textContent
    }

    return children.map(n => transformTagsIntoText(n)).join('')
  }

  /**
   * Returns the cursor position from the given focused editablecontent
   * 'target', including the management of line breaks, not natively
   * done by the current selection range.
   * @param {HTMLElement} target
   * @returns {Number}
   */
  function getCaretPosition(target) {
    const range = document.getSelection().getRangeAt(0)

    // Retreive the cursor position according to the navigator
    const result = range.cloneRange()
    result.selectNodeContents(target)
    result.setEnd(range.endContainer, range.endOffset)

    // Find the line index and add all the line breaks that should be included.
    // Be careful. For example, if returned position above is 4 and there
    // are line breaks at index 2 and 5, it must return 6.
    const lines = [...(target?.childNodes || [])]
    const index = lines.findIndex(n => n.contains(range.endContainer))

    return result.toString().length + index
  }

  /**
   * Retrieve the right inner HTML element (deep inside the
   * given 'target' element) in which the cursor position must be
   * set.
   * Returns an object containing the related inner deep 'node', and
   * the relative 'index' corresponding to the given 'position'.
   * @param {HTMLElement} target
   * @param {Number} position
   * @returns {Object}
   */
  function getInnerNodePosition(target, position) {
    if (!target || position < 0) {
      return {
        node: null,
        index: -1,
      }
    }

    // This means finding first the right line, decrementing position
    // with the detected textContent (+1 for the line break), and then
    // the right HTML element inside the line (considered as a "column").
    // The rest, stored in 'start', gives the right offset inside this
    // deep element.
    let nodes = [...target.childNodes]
    let node = nodes[0]
    let index = position
    let line = 0
    let column = 0
    let inner = 0

    if (node.tagName !== 'DIV') {
      return { target, position }
    }

    // Go through line to find the selected one
    while (line < nodes.length && index > node.textContent.length) {
      line += 1
      index -= (node.textContent.length + 1)
      node = nodes[line]
    }

    nodes = [...node.childNodes]
    node = nodes[0]

    // Go through line's direct children, considered here as "column"
    while (column < nodes.length && index > node.textContent.length) {
      column += 1
      index -= node.textContent.length
      node = nodes[column]
    }

    // Adjust position at the beginning of the next node (or the end of the parent node)
    // if the cursor is settling just at the end.
    if (index === node.textContent.length) {   // Cursor at the end of the node
      if (column < nodes.length - 1) {         // And if it's not the last node ...
        node = nodes[column + 1]               // ... select the start of the next node
        index = 0
      } else {
        node = node.parentNode                // Else select the last index of its parent
        index = column + 1
      }
    } else {
      // Go deeper inside "columns" until finding a text node (nodeType === 3)
      while (node.nodeType !== Node.TEXT_NODE && node.childNodes?.length) {
        nodes = [...node.childNodes]
        node = nodes[0]

        while (inner < nodes.length && index > node.textContent.length) {
          inner += 1
          index -= node.textContent.length
          node = nodes[inner]
        }
      }
    }

    return { node, index }
  }

  /**
   * Returns the right avatar image URL according to the given 'item' resource type.
   * @param {*} item
   * @returns {String}
   */
  function formatSuggestionAvatar(item) {
    switch (item.__typename) {
      case 'Freelance':
      case 'TeamMember':
        return item?.user?.profilePictureUrl
      case 'Corporate':
        return item?.company?.logo?.url
      case 'Company':
        return item?.logo?.url
      case 'Mission':
        return item?.project?.company?.logo?.url
      default:
        return null
    }
  }

  /**
   * Format a suggestion title showing the right label according to the
   * given 'item' resource type.
   * @param {*} item
   * @returns {String}
   */
  function formatSuggestionTitle(item, pattern = '') {
    const type = item.__typename
    let title = null

    if (['Freelance', 'TeamMember', 'Corporate'].includes(type)) {
      title = item?.user?.fullName
    } else if ('Company' === type) {
      title = item?.name
    } else if ('Mission' === type) {
      title = item?.title
    }

    // Highlight the pattern currently searched.
    //    g = global, match all instances of the pattern in a string
    //    i = case-insensitive
    const regexp = new RegExp(pattern, 'gi')

    return title.replace(regexp, '<span class="font-weight-bold">$&</span>')
  }

  /**
   * Format a suggestion subtitle showing the right label according to the
   * given 'item' resource type.
   * @param {*} item
   * @returns {String}
   */
  function formatSuggestionType(item) {
    switch (item.__typename) {
      case 'Freelance':
        return 'Free'
      case 'TeamMember':
        return 'TM'
      case 'Corporate':
        return 'Client'
      case 'Company':
        return 'Company'
      case 'Mission':
        return 'Mission'
      default:
        return null
    }
  }

  /**
   * Format the label displayed in the contentieditable input when a mention is complete.
   * @param {*} suggestion
   * @returns {String}
   */
  function formatSuggestionLabel(item) {
    switch (item.__typename) {
      case 'Freelance':
      case 'TeamMember':
      case 'Corporate':
        return item?.user?.fullName
      case 'Company':
        return item?.name
      case 'Mission':
        return item?.title
      default:
        return null
    }
  }


  export default {
    name: 'RichTextField',
    inheritAttrs: false,
    props: {
      /**
       * Textarea current value, including specific "tokens" (like mentions)
       * in a specific format.
       * Ex : "See #{F|5|Yung Blud} in app.comet.co"
       * @type {String}
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Placeholder text displayed in the contenteditable element when it is empty.
       * @type {String}
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Fixed number of rows, or minimum number of rows if 'auto-grow' is enabled.
       * @type {Number}
       */
      rows: {
        type: Number,
        default: 1,
      },
      /**
       * Automatically grow the textarea depending on amount of text.
       * @type {Boolean}
       */
      autoGrow: {
        type: Boolean,
        default: false,
      },
      /**
       * Give the focus to the contenteditable element when mounted.
       * @type {Boolean}
       */
      autofocus: {
        type: Boolean,
        default: false,
      },
      /**
       * Reduces the component's height.
       */
      dense: {
        type: Boolean,
        default: false,
      },

    },
    data() {
      return {
        /**
         * Caret position stored everytime an input is emitted by the
         * contenteditable input
         * @type {Number}
         */
        position: -1,
        /**
         * At some time, we manually set the 'position' to a new place (ex: when completing
         * a new mention) after emitting a new value.
         * But as the 1-way binding is not as immediate as the computed evaluation, we lie
         * in a short moment during which the 'position' has been updated but not teh 'value'
         * yet. So computations would obviously fail.
         * So, this prop keeps the old position, well corresponding to the current value, not
         * yet updated, until it is synced (next tick).
         * @type {Boolean}
         */
        synchronizing: -1,
        /**
         * List of suggestions to display when a mention is entered (fetched by Apollo)
         * @type {Array.<*>}
         */
        suggestions: [],
        /**
         * Index of the currently selected suggestion in the suggestions menu.
         * @type {Number}
         */
        selected: 0,
      }
    },
    computed: {
      /**
       * Ordered list of the different chunks present in 'value' (mentions, URLs,
       * line breaks & texts).
       * This collection is used as a reference to well rebuild the new 'value' when
       * an event occur (edition, insertion of a mention, ...).
       * @type {Array.<Object>}
       */
      chunks() {
        const { value } = this

        return extractChunks(value || '')
      },
      /**
       * Value computed in an HTML format from the 'chunks' collection.
       *
       * The bounding <div> are included in there. So line break chunks only
       * close the current div and open a new one. Empty <div> are then filled
       * with a <br/> to be well considered in the layout.
       *
       * This value is then straigth provided to the editablecontent HTML element.
       * @type {String}
       */
      html() {
        const { chunks } = this

        const content = chunks.map(c => c.html).join('')
        const bounded = `<div>${content}</div>`

        return bounded.replace(/<div><\/div>/g, '<div><br/></div>')
      },
      /**
       * Returns 'true' if the contenteditable is empty (not text is shown, including
       * line breaks). Event, if some HTML is actually displayed in it (and so not
       * really empty, in a CSS point of view)
       * @type {Boolean}
       */
      empty() {
        const { chunks } = this
        const text = chunks.map(c => c.text).join('')

        return text.length <= 0
      },
      /**
       * Contains the mention that the user is currently editing (including the
       * mention prefix character). If no mention edition is in progess, or a
       * simple word, or whatever else,  it is null.
       * @type {String}
       */
      mention() {
        const { chunks, position } = this

        if (position < 0) {
          return null
        }

        // Rebuild the text value
        const text = chunks.map(c => c.text).join('')

        // Determines the word currently edited (between the current cursor
        // 'position' and the mention prefix character)
        const before = text.substring(0, position)

        // Find the last indexes of all the possible mention  characters
        const indexes = MENTION_CHARACTERS
          .map(c => before.lastIndexOf(c))

        // A mention is considered only if there is a space (including line breaks)
        // before the prefix character.
        // Keep only them at the beginning of the text or with a space / line break before
        const filteredIndexes = indexes.filter(i => i === 0 || (i > 0 && before[i - 1].match(/\s/g)))

        // Choose the closest one to the end (to the cursor)
        const closestIndex = filteredIndexes.length
          ? Math.max(...filteredIndexes)
          : -1

        // Get the chunk related to this mention start
        const prefixChunkIndex = findChunkFromTextOffset(chunks, closestIndex)
        // Get the chunk in which the cursor resides
        const cursorChunkIndex = findChunkFromTextOffset(chunks, position)
        // If no mention character  detected, no 'closestIndex', and so, no chunk.
        const chunk = prefixChunkIndex >= 0
          ? chunks[prefixChunkIndex]
          : null

        // We actually face a new mention only if :
        //    - there is an identified mention character, and its relative chunk
        //    - the "@" and the cursor are in this same chunk (or the cursor at the beginning of the next chunk)
        //    - this chunk is of type text (new mention) OR is an existing mention and the cursor is not at the end of the word
        let start = -1

        if (chunk &&
          (prefixChunkIndex === cursorChunkIndex ||
            (cursorChunkIndex === prefixChunkIndex + 1 &&
              position - chunks.slice(0, cursorChunkIndex).reduce((sum, c) =>  sum + c.text.length, 0) === 0)) &&
          (chunk.type === CHUNK_TYPES.TEXT ||
            (chunk.type === CHUNK_TYPES.MENTION && position < chunk.textOffset + chunk.text.length))
        ) {
          start = closestIndex
        }

        // No mention detected, finally.
        if (start < 0) {
          return null
        }

        // Catch only the mention text (including the prefix character)
        const word = start === 0
          ? before
          : before.substring(start)

        // If there is at least one letter typed after the mention prefix character
        // create a 'mention' object with metadata expected by the 'suggestions' query.
        return word && word.length > 1 && MENTION_CHARACTERS.includes(word?.[0])
          ? {
            scope: MENTION_QUERY_SCOPE[word[0]],
            text: word.substring(1),
            offset: start < 0 ? 0 : start,
          }
          : null
      },
      /**
       * Suggestions menu coordinates computed with the current cursor position
       * @type {Object.<Number>}
       */
      coordinates() {
        const { mention, position, synchronizing } = this
        const { input, list } = this.$refs

        let rect = null

        const offset = (mention && mention.offset) ||
          (synchronizing >= 0 && synchronizing) ||
          position

        // Inner (deep) HTML element, in the contenteditable input, on which the cursor
        // is actually located (determined by 'position').
        const { node, index } = getInnerNodePosition(input, offset)

        if (node) {
          // Text nodes don't provide a "getBoundingClientRect" function,
          // that's why we use a range to identify the right piosition of
          // the cursor in it.
          if (node.nodeType === Node.TEXT_NODE) {
            const range = document.createRange()
            range.selectNodeContents(node)
            range.setStart(node, index)

            rect = range.getBoundingClientRect()
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            rect = node.getBoundingClientRect()
          }

          if (rect) {
            const menuWidth = list?.$el?.getBoundingClientRect?.()?.width || 360
            const maxX = document.body.clientWidth - menuWidth

            return {
              x: Math.min(rect.left, maxX),
              y: rect.top + rect.height + 4, // margin
            }
          }
        }
        return { x: 0, y: 0 }
      },
      /**
       * Formatted list of suggestions that can be straight displayed in the
       * mention menu's list.
       * @type {Array.<Object>}
       */
      items() {
        const { mention, suggestions } = this

        return suggestions
          ? suggestions.map(s => ({
            key: `${s.__typename}-${s.id}`,
            id: `#${s.id}`,
            type: formatSuggestionType(s),
            title: formatSuggestionTitle(s, mention?.text),
            avatar: formatSuggestionAvatar(s),
            suggestion: s,
          }))
          : null
      },
      /**
       * Styles applied to the input's container.
       * Compute its min and max height according to the given props (autoGrow, rows, ...).
       * @type {Object}
       */
      containerStyle() {
        const { rows, autoGrow, dense } = this
        const height = (rows * 20) + (dense ? 14 : 18)
        const padding = dense ? 7 : 9

        return {
          minHeight: `${height}px`,
          maxHeight: autoGrow ? 'none' : `${height}px`,
          paddingTop: padding,
          paddingBottom: padding,
        }
      },
      /**
       * Styles applied to the input itself.
       * Compute its min and max height according to the given props (autoGrow, rows, ...).
       * @type {Object}
       */
      inputStyle() {
        const { rows, autoGrow } = this
        const height = rows * 20

        return {
          minHeight: `${height}px`,
          maxHeight: autoGrow ? 'none' : `${height}px`,
        }
      },
    },
    apollo: {
      suggestions: {
        query,
        variables() {
          const { mention } = this

          return {
            query: mention.text.trim(),
            scope: mention.scope,
          }
        },
        update(data) {
          return data.search
        },
        result(response) {
          const suggestions = response?.data?.search || null
          const { menu } = this.$refs

          if (suggestions && suggestions.length) {
            this.selected = 0

            this.$nextTick(() => {
              const content = menu?.$refs?.content

              if (content) {
                content.scrollTop = menu.calcScrollPosition()
              }
            })
          }
        },
        skip() {
          const { mention } = this

          return !mention || !mention.text || mention.text.length < 1
        },
        debounce: 200,
        fetchPolicy: 'network-only',
      },
    },
    watch: {
      /**
       * As we manually build the HTML content passed to the editablecontent element,
       * we must recompute the caret position everytime the value changes.
       * @param {String} next
       */
      value: {
        immediate: true,
        handler() {
          const { position } = this
          const { input } = this.$refs

          if (!input) {
            return
          }

          // Act in the next tick, to ensure that the selection has been already
          // well changed
          this.$nextTick(() => {
            if (input && document.activeElement === input) {
              const selection = window.getSelection()
              const range = document.createRange()

              // Let's retrieve the right inner HTML element (deep inside the
              // editablecontent element) in which the cursor position must be
              // set.
              // This means finding first the right line, decrementing position
              // with the detected textContent (+1 for the line break), and then
              // the right HTML element inside the line (considered as a "column").
              // The rest, stored in 'start', gives the right offset inside this
              // deep element.
              const { node, index: start } = getInnerNodePosition(input, position)

              range.setStart(node || input, Math.max(0, start))
              // Collapse to the end
              // https://developer.mozilla.org/en-US/docs/Web/API/Range/collapse
              range.collapse(false)
              selection.removeAllRanges()
              selection.addRange(range)

              // Mark back the compoenent as well synced (position & value)
              setTimeout(() => {
                this.synchronizing = -1
              }, 200)
            }
          })
        },
      },
      /**
       * If no 'mention' is detected anymore (because of content or cursor position
       * changes), we empty the suggestions list.
       * This leads to a menu collapsing, as it is displayed as soon as items
       * are present in the 'suggestions' collection.
       */
      mention(next, prev) {
        const { menu } = this.$refs

        if (prev && !next) {
          this.suggestions = []
          this.selected = 0

          this.$nextTick(() => {
            const content = menu?.$refs?.content

            if (content) {
              content.scrollTop = menu.calcScrollPosition()
            }
          })
        }
      },
    },
    mounted() {
      const { autofocus } = this
      const { input } = this.$refs

      if (autofocus && input) {
        input.focus()
      }
    },
    methods: {
      _omit,
      /**
       * Handle every "input" events emitted by the contenteditable element.
       * It rebuilds the new 'value' to emit, including the last changes in it.
       * @param {Event} event
       */
      onContentInput(event) {
        const { chunks } = this
        const { input } = this.$refs

        // Do nothing (keep the contenteditable normal behavior) when a composition
        // character is entered (accents, for example). Because our 1-way binding
        // override the entire input content.
        if (event.inputType === 'insertCompositionText') {
          return
        }

        // Get the text content (including line breaks)
        let newTextValue = extractTextFromNode(input)

        // Keep the last caret position to replace it when the value will change,
        // according to our 1-way binding system. The caret automatically goes
        // back to the start of a child because we override the whole content
        // everytime a change is made.
        this.position = getCaretPosition(input)

        let i = 0
        let offset = 0
        let chunk = null
        let dirty = null
        let newChunks = []

        // We go throught all the chunks
        while (offset < newTextValue.length) {
          chunk = i < chunks.length
            ? chunks[i]
            : null

          // Chunk well found at the right place
          if (chunk && newTextValue.substring(offset, offset + chunk.text.length) === chunk.text) {
            // If a "dirty" chunk is pending, close it
            if (dirty) {
              dirty.value = dirty.html = dirty.text = newTextValue.substring(dirty.textOffset, offset)
              dirty = null
            }

            // Add the chunk as it already exists because it had no change
            newChunks.push({
              ...chunk,
              textOffset: offset,
              dirty: false,
            })

            offset += chunk.text.length
            dirty = null
            i += 1
            continue
          }

          // If the text segment is not similar between the cuhunk and the input value, create
          // a new "dirty" chunk
          if (!dirty) {
            dirty = {
              type: CHUNK_TYPES.TEXT,
              text: '',
              textOffset: offset,
              dirty: true,
            }

            dirty.value = dirty.html = dirty.text
            dirty.valueOffset = dirty.htmlOffset = dirty.textOffset
            newChunks.push(dirty)
          }

          // Go to the next offset (and until the end), attempting to find the current
          // chunk in the new text.
          offset += 1

          // If we reach the end of the text, we go back to the start the dirty segment
          // and try to find the next chunk.
          if (offset >= newTextValue.length) {
            offset = dirty.textOffset
            i += 1

            // If there is also no more chunk to detect, we close the current "dirty" segment
            if (i >= chunks.length) {
              dirty.value = dirty.html = dirty.text = newTextValue.substring(dirty.textOffset)
              dirty = null
              break
            }
          }
        }

        // Remove empty text chunks
        newChunks = newChunks.filter(c => c.type !== CHUNK_TYPES.TEXT || !!c.text)

        const newValue = newChunks.map(c => c.value).join('')

        this.$emit('input', newValue)
      },
      /**
       * Fired when the editablecontent element gains the focus.
       * It updates the caret position (to ensure 'mention' to be well detected).
       * @param {Event} event
       */
      onContentFocus() {
        const { root, input } = this.$refs

        root.isFocused = true

        setTimeout(() =>  {
          this.position = getCaretPosition(input)
        }, 100)
      },
      /**
       *
       * @param {Event} event
       */
      onContentBlur() {
        const { root } = this.$refs

        root.isFocused = false
      },
      /**
       * Fired when the user places the cursor manually with the mouse.
       * It updates the caret position (to ensure 'mention' to be well detected).
       * @param {Event} event
       */
      onContentClick() {
        const { input } = this.$refs

        setTimeout(() =>  {
          this.position = getCaretPosition(input)
        }, 100)
      },
      /**
       * Fired when an arrow key or enter key is pressed on the contenteditable input.
       * If no 'mention' is detected, meaning the focus is entirely on the editablecontent
       * element, it just updates the caret position.
       * But if a mention is in progress, key actions must be forwarded to the suggestion
       * menu.
       * @param {Event} event
       */
      onContentKeyDown(event) {
        const { rows, autoGrow, mention, suggestions, selected } = this
        const { input, menu } = this.$refs

        const menuActive = !!(mention && suggestions?.length)

        // Update position when navigating from the input
        if (
          event.keyCode === 37 ||     // LEFT ARROW
          event.keyCode === 39 ||     // RIGHT ARROW
          (!menuActive && (
            event.keyCode === 38 ||   // UP ARROW
            event.keyCode === 40      // DOWN ARROW
          ))
        ) {
          setTimeout(() =>  {
            this.position = getCaretPosition(input)
          }, 100)
          return
        }

        // Deactivate line breaks for non auto-grow single-line input
        if (!menuActive && event.keyCode === 13 && rows <= 1 && !autoGrow) {
          event.preventDefault()
          event.stopPropagation()
        }

        // Handle suggestion menu navigation (while keeping focus on the contenteditable input)
        if (menuActive) {
          if ([9, 13, 27, 38, 40].includes(event.keyCode)) {
            event.preventDefault()
            event.stopPropagation()
          }

          if (event.keyCode === 9 || event.keyCode === 13) {    // TAB or ENTER
            this.onSuggestionPress(event, suggestions[selected])
          }

          if (event.keyCode === 27) {    // ESCAPE
            this.suggestions = []
            this.selected = 0
          }

          if (event.keyCode === 38) {    // UP ARROW
            const index = selected - 1

            this.selected = index < 0 ? suggestions.length - 1 : index
          }

          if (event.keyCode === 40) {    // DOWN ARROW
            this.selected = (selected + 1) % suggestions.length
          }

          this.$nextTick(() => {
            const content = menu?.$refs?.content

            if (content) {
              content.scrollTop = menu.calcScrollPosition()
            }
          })
        }
      },
      /**
       * Fired when a suggestion is selected from the corresponding menu.
       * It rebuilds the new 'value' from the chunks, detected the one altered
       * by the new mention, and well format it with our API mention format.
       * @param {Event} event
       * @param {*} suggestion
       */
      onSuggestionPress(event, suggestion) {
        const { chunks, mention, position } = this
        const { input } = this.$refs

        // Close the menu (and empty suggestions) if no editing mention is detected
        if (!mention) {
          this.suggestions = []
          return
        }

        // Find the chunk in which the new mention is placed
        const index = findChunkFromTextOffset(chunks, mention.offset)
        const chunk = chunks[index]

        // Format the new 'value' from the chunks
        const formatted = chunks.map(c => c.value)

        // Determine the index at which the mention starts in the "value"
        // format (not the "text" format, be careful)
        const start = mention.offset - chunks.slice(0, index).reduce((sum, c) => sum + c.text.length, 0)

        // Get the mention typed text
        const typed = mention.text

        // Modify the altered chunk, keeping the text before and after the mention
        // and inserting the new mention in "value" format in the middle.
        //
        // If the altered chunk is an existing mention, we erase (forget) the previous
        // text.
        const before = chunk.type === CHUNK_TYPES.MENTION
          ? ''
          : chunk.text.substring(0, start)
        const after = chunk.type === CHUNK_TYPES.MENTION
          ? ''
          : chunk.text.substring(start + typed.length + 1)

        const prefix = RESOURCE_TYPE_TO_MENTIONS[suggestion.__typename]
        const id = suggestion.id
        const label = formatSuggestionLabel(suggestion).replace(/\s/g, ' ')
        const value = `#{${prefix}|${id}|${label}}`

        formatted[index] = `${before}${value}${after}`

        // Glue back every formatted parts together
        const newValue = formatted.join('')

        this.$emit('input', newValue)

        // Mark the input as dirty / unsynced, as the 'position' will be updated next line,
        // but the 'value' will be updated later, according to the 1-way binding mechanism.
        // So we keep the 'position' corresponding to the current (old) 'value' to be able
        // determines the menu 'coordinates'.
        this.synchronizing = (mention && mention.offset >= 0 && mention.offset) || position

        // Manually update the cursor position then, to place it after the mention completion.
        // Changing 'this.position' will lead to a cursor move on next tick, in the 'value'
        // watcher (because 'value' has changed)
        this.position = position - typed.length + label.length

        // Give the focus back to the contenteditable element
        this.$nextTick(() => {
          input.focus()
        })
      },
      /**
       * Fired when the user copy the input's content (with Ctrl+C or from a contextual menu).
       *
       * Instead of storing only the selection as a 'text' in the clipboard, it also provides
       * an 'html' version which is actually the 'value' format (with mentions as specified
       * in the API format) of the curretn selection. So, when pasting in another RichTextField,
       * it preserves the copied mentions.
       * @param {Event} event
       */
      onContentCopy(event) {
        const { chunks } = this
        const { input } = this.$refs

        event.preventDefault()
        event.stopImmediatePropagation()

        this.position = getCaretPosition(input)

        // Get the current selected range
        const range = document.getSelection().getRangeAt(0)

        // Determines its length by adding the non-included line breaks
        const lines = [...(input?.childNodes || [])]
        const startLine = lines.findIndex(n => n.contains(range.startContainer))
        const endLine = lines.findIndex(n => n.contains(range.endContainer))
        const length = range.toString().length + (endLine - startLine)

        // Get the selection actual start and end offsets
        const endOffset = this.position
        const startOffset = endOffset - length

        // Find the related chunks
        const startChunk = findChunkFromTextOffset(chunks, startOffset)
        const endChunk = findChunkFromTextOffset(chunks, endOffset)

        const selected = chunks
          .slice(startChunk, endChunk + 1)
          .map((c, i) => {
            // First chunk not selected entirely
            if (i === 0 && startOffset > c.textOffset) {
              const from = startOffset - c.textOffset
              const to = from + Math.min(length, c.text.length)

              return {
                text: c.text.substring(from, to),
                value: c.text.substring(from, to),
              }
            }

            if (i === endChunk - startChunk && endOffset < c.textOffset + c.text.length) {
              const from = 0
              const to = endOffset - c.textOffset

              return {
                text: c.text.substring(from, to),
                value: c.text.substring(from, to),
              }
            }

            return {
              text: c.text,
              value: c.value,
            }
          })

        const text = selected.map(s => s.text).join('')
        const value = selected.map(s => s.value).join('')

        event.clipboardData.setData('text/plain', text)
        event.clipboardData.setData('text/html', value)
      },
    },
  }
</script>

<style lang="stylus" module>
  :global(#app.v-application .v-input).root {
    & {
      --input-font-size: .875rem
      --input-min-height: 38px
      --input-padding-vertical: 9px
      --input-padding-horizontal: 16px
      --input-radius: 8px
    }

    &:global(.v-input--dense).dense {
      --input-font-size: .875rem
      --input-min-height: 34px
      --input-padding-vertical: 7px
      --input-padding-horizontal: 12px
      --input-radius: 6px
    }
  }

  .root {
    .container {
      position: relative
      min-height: var(--input-min-height)
      display: flex
      flex-direction: row
      align-items: center
      padding: var(--input-padding-vertical) var(--input-padding-horizontal)
      background: var(--color-input-background)
      box-shadow: none
      border: 1px solid var(--color-input-border)
      border-radius: var(--input-radius)
      overflow-y: auto
      transition: all .2s linear

      .input {
        flex: 1
        padding: 0
        box-sizing: border-box
        box-shadow: none !important
        font-size: var(--input-font-size)
        appearance: none
        outline: none

        > * {
          line-height: 20px
        }

        a {
          color: var(--color-brand) !important
        }
      }
    }

    &:not(.autoGrow) .container .input > *:not(:first-child):last-child {
      padding-bottom: var(--input-padding-vertical)
    }

    // Idle
    &:global(:not(.v-input--is-focused)),
    &:global(.v-input--is-disabled) {
      .container {
        background: var(--color-input-background-idle)
      }
    }

    // HOVER
    &:global(:not(.v-input--is-disabled):not(.v-input--is-focused)):hover .container {
      border-color: var(--color-input-hover)
    }

    // FOCUS
    &:global(:not(.v-input--is-disabled):not(.v-input--has-state).v-input--is-focused) .container {
      border-color: var(--color-input-highlight)
    }

    // ERROR
    &:global(.v-input--has-state.v-input--is-focused) .container {
      border-color: var(--color-input-error)
    }

    // PLACEHOLDER
    &.empty .input:before {
      content: attr(placeholder)
      display: block /* For Firefox */
      position: absolute
      color: var(--color-input-placeholder)
      pointer-events: none
    }
  }

  .menu {
    z-index: 100
  }
</style>
