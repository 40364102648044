<template>
  <div class="with-loading" :class="classes">
    <Spinner v-if="loading" :size="Spinner.Size.BIG" :class="$('spinner')" />

    <slot v-else />
  </div>
</template>

<script>
  import Spinner from '@/core/controls/Spinner/Spinner'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Spinner: Spinner.__enums,
},

    name: 'WithLoading',
    components: {
      Spinner,
    },
    props: {
      /**
       * If 'true', the component displays a Spinner instead of its content
       */
      loading: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      classes() {
        const { loading } = this

        return {
          'with-loading--active': loading,
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .with-loading {
    display: flex
    flex-direction: column

    &-message {
      margin-top: 24px
    }

    &--active {
      height: 100%
      display: flex !important
      align-items: center
      justify-content: center
    }
  }
</style>
