<template>
  <div :class="classes">
    <VTabs
      ref="tabs"
      v-bind="_pick($attrs, ['centered', 'fixed-tabs', 'grow', 'icons-and-text', 'show-arrows'])"
      :value="Math.min(selectedIndex, max)"
      :background-color="backgroundColor"
      :vertical="vertical"
      data-cy-vtab
      @change="onTabsChange"
    >
      <div :class="$style.before">
        <slot v-if="$slots.before" name="before" />
      </div>

      <template v-for="(tab, index) in tabs">
        <VTab
          :key="`${index}-${tab.id}`"
          v-bind="_pick(tab, ['to', 'exact', 'href', 'target', 'disabled'])"
          :class="{
            'v-tab--active': selectedId === tab.id,
            [$style.withSlot]: $slots[`tab.${tab.id}`],
          }"
          :style="{
            minWidth: `${minTabWidth}px`,
          }"
          @click="() => onTabPress(tab)"
        >
          <VIcon v-if="tab.icon" in-text left v-text="tab.icon" />

          <span v-if="tab.label" v-html="tab.label" />

          <VChip
            v-if="tab.chip"
            x-small
            :color="selectedId === tab.id ? 'primary' : 'secondary'"
            class="caption-2 px-2 ml-2 flex-shrink-0"
            :class="$style.chip"
            v-text="tab.chip"
          />

          <span v-if="$slots[`tab.${tab.id}`]" class="ml-2">
            <slot :name="`tab.${tab.id}`" />
          </span>
        </VTab>
      </template>

      <VMenu
        v-if="list.length"
        v-model="expanded"
        bottom
        offset-y
        :nudge-bottom="8"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on }">
          <VTab
            key="more"
            :style="{
              minWidth: `${minTabWidth}px`,
            }"
            v-on="on"
          >
            <VIcon v-if="moreTab.icon" in-text left v-text="moreTab.icon" />

            <span v-html="moreTab.label || __('cp:c-tabs:more:label')" />

            <VChip
              v-if="moreTab.chip"
              x-small
              color="primary"
              class="caption-2 px-2 ml-2 flex-shrink-0"
              :class="$style.chip"
              v-text="moreTab.chip"
            />

            <VIcon :class="$style.moreIcon" v-text="'mdi-menu-down'" />
          </VTab>
        </template>

        <VList :dense="dense" class="pa-2">
          <template v-for="(item, index) in list">
            <VListItem
              :key="`${index}-${item.id}`"
              v-bind="_pick(item, ['to', 'exact', 'href', 'target', 'disabled'])"
              :input-value="index === selectedIndex - max"
              color="primary"
              @click="() => onMoreItemPress(item, index)"
            >
              <VListItemIcon v-if="item.icon" class="justify-center">
                <VIcon in-text v-text="item.icon" />
              </VListItemIcon>

              <VListItemTitle v-if="item.label" v-html="item.label" />

              <VChip
                v-if="item.chip"
                x-small
                :color="selectedId === item.id ? 'primary' : 'secondary'"
                class="caption-2 px-2 ml-4 flex-shrink-0"
                :class="$style.chip"
                v-text="item.chip"
              />
            </VListItem>
          </template>
        </VList>
      </VMenu>

      <div :class="$style.after">
        <slot v-if="$slots.after" name="after" />
      </div>
    </VTabs>

    <div :class="$style.content">
      <template v-for="item in items">
        <div
          v-show="anchors || selectedId === item.id"
          :id="item.id"
          :key="`${item.id}`"
          :ref="`${item.id}`"
          v-observe-visibility="(v, e) => onContentVisibilityChange(item.id, v, e)"
          :class="$style.board"
        >
          <slot
            v-if="(!deferred || mountable[item.id]) && (!lazy || anchors || selectedId === item.id)"
            :name="item.id"
            :item="item"
            :selected-index="selectedIndex"
            :selected-id="selectedId"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import _isString from 'lodash/isString'
  import _isNumber from 'lodash/isNumber'
  import _keyBy from 'lodash/keyBy'
  import _mapValues from 'lodash/mapValues'
  import _pick from 'lodash/pick'

  export default {
    name: 'CTabs',
    inheritAttrs: false,
    props: {
      /**
       * Initial value passed to the VTabs component. Does not change then,
       * as VTabs keeps its own local state with the current selected tab.
       * @type {Number}
       */
      value: {
        type: Number,
        default: 0,
      },
      /**
       * List of properties describing each tab to show.
       * @type {Array.<Object>}
       */
      items: {
        type: Array,
        required: true,
      },
      /**
       * Whether, or not, the tabs are linked to an URL query param.
       * If "true" is passed, the query param used is basically "tab".
       * If a String is passed, it will be used as query param.
       * @type {Boolean|String}
       */
      url: {
        type: [Boolean, String],
        default: false,
      },
      /**
       * Whether, or not, the tabs must act as anchors navigating in a scrolling content.
       * Meaning all the tab contents are always visible and displayed one after the other.
       * Tabs allows to quickly move from one section to another.
       * @type {Boolean}
       */
      anchors: {
        type: Boolean,
        default: false,
      },
      /**
       * Defines whether if every tabs must be always mounted in the DOM, or rendered on demand
       * when the associated tab is selectec.
       * @type {Boolean}
       */
      lazy: {
        type: [Boolean],
        default: false,
      },
      /**
       * Whether every tab contents should be mounted only when they have been selected once
       * or after a short delay.
       * Default delay if "lazy" is a boolean, or the specified duration in milliseconds.
       * @type {Boolean|Number}
       */
      deferred: {
        type: [Boolean, Number],
        default: false,
      },
      /**
       * Max number of tabs displayed. Others are stored in a "more" tab.
       * @type {Number}
       */
      max: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER,
      },
      /**
       * Display a "packed" version of the tabs, with a smaller height
       * @type {Boolean}
       */
      dense: {
        type: Boolean,
        default: false,
      },
      /**
       * The tabs are displayed vertically, instead of horizontally by default.
       * @type {Boolean}
       */
      vertical: {
        type: Boolean,
        default: false,
      },
      /**
       * The tabs are not filling all the entire width, but displayed inline on the left side.
       * @type {Boolean}
       */
      inline: {
        type: Boolean,
        default: false,
      },
      /**
       * Force the tabs to stand on the left side of the content.
       * Works only with "vertical".
       * @type {Boolean}
       */
      left: {
        type: Boolean,
        default: false,
      },
      /**
       * Force the tabs to stand on the right side of the content.
       * Works only with "vertical".
       * @type {Boolean}
       */
      right: {
        type: Boolean,
        default: false,
      },
      /**
       * Changes the background color of the component
       * @type {String}
       */
      backgroundColor: {
        type: String,
        default: 'transparent',
      },
      /**
       * Minimum width of each tab
       * @type {Number}
       */
      minTabWidth: {
        type: Number,
        default: null,
      },
    },
    data() {
      const { value } = this

      return {
        /**
         * Index of the currently selected tab.
         * @type {Number}
         */
        selected: value,
        /**
         * Determines whether the "More" menu is expanded, or not.
         * @type {Boolean}
         */
        expanded: false,
        /**
         * Hashmap referencing the tabs' ID as key and a Boolean as value.
         * Use to filter which tab content should be mounted or not. It allows to arrange
         * which tab could be mounted (and loaded) before others, and so be displayed faster.
         * @type {Object}
         */
        mountable: {},
        /**
         * Hashmap referencing the tabs' ID as key and a Boolean as value.
         * Use to keep track on the tabs that are currently displayed in the viewport, or not.
         * @type {Object}
         */
        visibility: {},
        /**
         * Lock employed in the "anchors" mode only to avoid a weird infinite loop between
         * the click on tabs changing the page's scroll and detection on scroll to update
         * the selected tab.
         * So it allows to suspend scroll detection when navigating after a click on tab.
         * "lock" receives the returned value of "setTimeout", that's why it's a number.
         * @type {Number}
         */
        lock: Number.MAX_SAFE_INTEGER,
      }
    },
    computed: {
      /**
       * CSS classes applied on the root element
       * @type {Object}
       */
      classes() {
        const { anchors, dense, vertical, inline, left, right, expanded, $style } = this

        return {
          [$style.root]: true,
          [$style.anchors]: !!anchors,
          [$style.dense]: !!dense,
          [$style.vertical]: !!vertical,
          [$style.inline]: !!inline,
          [$style.left]: !!left,
          [$style.right]: !!right,
          [$style.expanded]: !!expanded,
        }
      },
      /**
       * Tabs properties computed from the "items" prop, allowing to entirely
       * parameter tabs with revelant info (to, href, target, ...)
       * @type {Array.<Object>}
       */
      tabs() {
        const { items, max, mapItem } = this

        return items.slice(0, max).map(i => mapItem(i))
      },
      /**
       * "More" list items properties computed from the "items" prop.
       * @type {Array.<Object>}
       */
      list() {
        const { items, max, mapItem } = this

        return items.slice(max).map(i => mapItem(i))
      },
      /**
       * Right information to display in the "More" tab accoding to the currently selected index.
       * @type {Object}
       */
      moreTab() {
        const { max, selectedIndex, list } = this

        return list?.[selectedIndex - max] || {}
      },
      /**
       * Currently selected tab index determined from the chosen tabs
       * mode (auto, url or normal)
       * @type {Number}
       */
      selectedIndex() {
        const { items, selected, parameter, isQuery, isHash, $route } = this

        if (isQuery) {
          const index = items.findIndex(i => i.id === $route.query[parameter])

          return index >= 0 ? index : 0
        }

        if (isHash) {
          const index = items.findIndex(i => i.id === $route.hash?.substring(1))

          return index >= 0 ? index : 0
        }

        return selected
      },
      /**
       * Currently selected tab identifier, determined fom the URL query param, if it is
       * connected to the "url", or with a reactive local props instead.
       * @type {Number}
       */
      selectedId() {
        const { items, selected, parameter, isQuery, isHash, $route } = this

        const deflt = items?.[0]?.id

        if (isQuery) {
          return $route.query[parameter] || deflt
        }

        if (isHash) {
          return $route.hash?.substring(1) || deflt
        }

        return items?.[selected]?.id || deflt
      },
      /**
       * Name of the query parameter used in the URL to identoify the currently selected tab.
       * @type {String}
       */
      parameter() {
        const { url } = this

        return _isString(url) ? url : 'tab'
      },
      /**
       * Say wether or not the tabs should be AND are able to be connected to the router.
       * @type {Boolean}
       */
      isQuery() {
        const { url, $router, $route } = this

        return !!url && !!$router && !!$route
      },
      /**
       * Say wether or not the tabs works with an hash parameter in the URL and requires a router.
       * @type {Boolean}
       */
      isHash() {
        const { anchors, $router, $route } = this

        return !!anchors && !!$router && !!$route
      },
      /**
       * List of all the tabs' IDs, even the hidden ones.
       * @type {Array.<String>}
       */
      orderedIds() {
        const { items } = this

        return items.map(i => i.id)
      },
    },
    watch: {
      /**
       * Detect that the selected tab changed whatever the tabs' mode is (url, anchors, ...).
       * Update the "mountable" hashmap, allowing the selected tab's content to be displayed
       */
      selectedId: {
        immediate: true,
        handler(next) {
          if (!next) {
            return
          }

          this.mountable = {
            ...this.mountable,
            [next]: true,
          }
        },
      },
    },
    /**
     * Initiate, or not, a deferred mounting of the tabs' contents.
     * And scroll to the right selected section, in "anchors" mode.
     */
    mounted() {
      const {
        items,
        value,
        isQuery,
        parameter,
        deferred,
        anchors,
        selectedId,
        scrollToAnchor,
        $route,
        $router,
      } = this

      if (deferred) {
        const timeout = setTimeout(
          () => {
            // Fill the "mountable" hashmap with all registered tabs. Means "mount every tabs now".
            this.mountable = _mapValues(
              _keyBy(items, i => i.id),
              () => true,
            )
          },
          _isNumber(deferred) ? deferred : 3000,
        )

        this.$once('hook:beforeDestroy', () => {
          clearTimeout(timeout)
        })
      }

      if (anchors) {
        // Initialise the visibility dictionnary, acting that nothing is visible for now.
        this.visibility = _mapValues(
          _keyBy(items, i => i.id),
          () => false,
        )

        // Ensure to scroll to the selected section on first mount
        scrollToAnchor(selectedId, 'instant')
      }

      // Navigate to default tab when mounting the component, if applicable
      if (isQuery && $route.query[parameter] !== selectedId) {
        const tab = items[value]

        this.$nextTick(() => {
          const { $route } = this

          $router
            .replace({
              path: $route.path,
              query: {
                ...$route.query,
                [parameter]: tab.id,
              },
            })
            .catch(error => {
              // We might navigate to the exact same URL, which
              // will trigger a NavigationDuplicated error that
              // we can simply ignore
              if (error?.name != 'NavigationDuplicated') {
                throw error
              }
            })
        })
      }
    },
    methods: {
      _pick,
      /**
       * Map an item passed in "items" into an object containing all the necessary tab properties.
       * @param {Object} item
       * @return {Object}
       */
      mapItem(item) {
        const { isQuery, isHash, parameter, $route, $router } = this

        let to = item.to
        let href = item.href

        if (isQuery) {
          to = $router.resolve({
            ...$route,
            query: {
              ...$route.query,
              [parameter]: item.id,
            },
          })?.href
        }

        if (isHash) {
          to = $router.resolve({
            ...$route,
            hash: item.id,
          })?.href
        }

        return {
          id: item.id,
          label: item.label,
          icon: item.icon,
          chip: item.chip,
          to: to,
          exact: !!to,
          href: href,
          target: item.target,
          disabled: item.disabled,
        }
      },
      /**
       * Fired when the Tabs component emit a change event, meaning the selected tab as
       * been altered by a user action OR programmatically (by a route change, for example).
       * @param {Number} index
       */
      onTabsChange(index) {
        const { max, selectedIndex, isQuery, isHash } = this

        if (
          // If the tabs are connected to the URL (query or hash), there is nothing to do here.
          // Each tab works as a link containing the right "tab" (or customed) query parameter.
          !isQuery &&
          !isHash &&
          // The tab is not already considered as the current selected one.
          index !== selectedIndex &&
          // The clicked tab is well a visible tab (not one from the "More" list)
          index !== undefined &&
          (index !== null) & (index < max)
        ) {
          this.selected = index

          this.$emit('input', index)
        }

        // Force a VTabSlider redraw as the last "more" tab could have different size
        // according to the one selected.
        this.$refs.tabs.callSlider()
      },
      /**
       * Fired when a Tab component is clicked, on a user action only.
       * @param {Number} index
       */
      onTabPress(item) {
        const { isHash, scrollToAnchor } = this

        if (isHash) {
          scrollToAnchor(item.id)
        }
      },
      /**
       * Fired when an item from the "More" list is clicked.
       * @param {Object} item
       * @param {Number} index
       */
      onMoreItemPress(item, index) {
        const { max, isQuery, isHash } = this

        // If the tabs are connected to the URL (query or hash), there is nothing to do here.
        // Each tab works as a link containing the right "tab" (or customed) query parameter.
        if (!isQuery && !isHash) {
          this.selected = max + index
        }

        // Force a VTabSlider redraw as the last "more" tab could have different size
        // according to the one selected.
        this.$refs.tabs.callSlider()
      },
      /**
       * Scroll the page to show the section with the given 'id'
       * @param {String} id
       */
      scrollToAnchor(id, behavior = 'smooth') {
        const { anchors, vertical, $refs } = this

        if (!anchors || !vertical || !$refs[id]) {
          return
        }

        // Update the "lock" status to avoid scroll detection to interact
        // while moving to the right section
        if (this.lock) {
          clearTimeout(this.lock)
        }

        this.lock = setTimeout(() => {
          this.lock = null
        }, 1500)

        // Initiate a smooth programatic scroll
        $refs[id]?.[0]?.scrollIntoView({ behavior, block: 'start', inline: 'start' })
      },
      /**
       * Fired when the visibility of a tab content in the viewport changes, when turning
       * to visible as well as hidden.
       * @param {String} id
       * @param {Boolean} visible
       */
      onContentVisibilityChange(id, visible) {
        const { anchors, visibility, orderedIds, selectedId, $route, $router } = this

        if (!anchors) {
          return
        }

        visibility[id] = visible

        if (this.lock) {
          return
        }

        const visibles = orderedIds.filter(i => visibility[i])
        const result = visibles[0]

        if (result !== selectedId) {
          $router.replace({
            ...$route,
            hash: `#${result}`,
          })
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_variables.styl'

  .root {
    position: relative
    display: flex
    flex-direction: column

    // Normal
    :global(.v-tabs) {
      flex: 0

      &,
      :global(.v-tabs-bar) {
        height: 48px
      }

      :global(.v-tab) {
        font-size: 1rem
        line-height: 1.5rem
        font-weight: 300
        letter-spacing: .03125em
        text-transform: none

        &.withSlot {
          padding-right: 16px !important
        }

        &:before {
          border-radius: 4px
          opacity: 0
        }

        .chip {
          margin-top: 2px
          opacity: .4
        }

        &:hover {
          color: var(--color-font) !important

          &:before {
            opacity: 0.02 !important
          }
        }

        &:focus {
          color: var(--color-font) !important
          font-weight: 400

          &:before {
            color: var(--color-brand) !important
            opacity: 0.04 !important
          }
        }

        &:hover,
        &:focus {
          .chip {
            opacity: .55
          }
        }

        &:global(.v-tab--active) {
          color: var(--color-font)
          font-weight: 400

          &:before {
            color: var(--color-brand)
          }

          .chip {
            opacity: 1
          }

          &:hover:before,
          &:focus:before {
            opacity: 0.04 !important
          }
        }
      }
    }

    &.dense {
      :global(.v-tabs) {
        &,
        :global(.v-tabs-bar) {
          height: 40px
        }

        :global(.v-tab) {
          font-size: 0.875rem
          line-height: 1rem
        }
      }
    }

    // Not vertical
    &:not(.vertical) :global(.v-tabs:not(.v-tabs--vertical)) {
      margin-bottom: 15px
      border-bottom: 1px solid var(--color-border)

      :global(.v-tab) {
        padding: 0 24px
        border-bottom: 2px solid transparent

        &:hover {
          border-bottom: 2px solid var(--color-grey-40)
        }
      }
    }

    // Vertical
    &.vertical {
      &,
      &.left {
        flex-direction: row

        :global(.v-tabs.v-tabs--vertical) {
          left: 0
          margin-left: 0
          margin-right: 24px
        }
      }

      &.right {
        flex-direction: row-reverse

        :global(.v-tabs.v-tabs--vertical) {
          right: 0
          margin-left: 24px
          margin-right: 0
        }
      }

      :global(.v-tabs.v-tabs--vertical) {
        position: sticky
        top: 0
        border-left: 1px solid var(--color-border)

        &,
        :global(.v-tabs-bar) {
          height: auto
        }

        :global(.v-tabs-bar .v-tabs-bar__content) {
          transform: none !important
        }


        :global(.v-tab) {
          height: 40px
          justify-content: flex-start
          border-left: 2px solid transparent

          &:hover {
            border-left: 2px solid var(--color-grey-40)
          }
        }
      }

      &.dense :global(.v-tabs.v-tabs--vertical .v-tabs-bar .v-tab) {
        height: 32px
      }

      .before,
      .after {
        flex-direction: column
      }
    }

    &.inline {
      :global(.v-tabs) {
        max-width: fit-content
        align-self: flex-start
      }

      &.right :global(.v-tabs) {
        align-self: flex-end develop
      }
    }

    &.anchors {
      // The container has to fit its parent's height. We can't change the display to use flex.
      // Absolute position is required to allow partial scroll & full space assignation
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      overflow-y: auto
    }

    // More Tab
    .moreIcon {
      position: relative
      top: 1px
      right: -4px
    }

    &.expanded .moreIcon {
      color: var(--color-brand) !important
      transform: rotate(-180deg)
    }

    // "before" and "after" slots
    .before,
    .after {
      display: flex
      flex-direction: row
      white-space: normal
    }

    .after {
      flex: 1
    }

    // Content
    .content {
      // max-height: calc(100% - 64px)
      flex: 1
      display: flex
      flex-direction: column

      .board {
        flex: 1
        display: flex
        flex-direction: column

        > * {
          flex: 1
        }
      }
    }
  }
</style>
