import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { onError } from 'apollo-link-error'
import DebounceLink from 'apollo-link-debounce'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'

import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

// eslint-disable-next-line import/extensions
import fetch from 'unfetch'

import config from '@/config'

import dataIdFromObject from './dataIdFromObject'
import introspectionQueryResultData from './schema.json'

import errors from './middlewares/errors'
import serverTime from './middlewares/serverTime'
import tracking from './middlewares/tracking'
import version from './middlewares/version'
import whitelist from '../../generated/graphql-whitelist.json'

/**
 * Create a new Apollo's client instance
 * @param {Object} store
 * @param {Object} router
 * @returns {Object}
 */
function createApolloClient(store, router) {
  const options = {
    uri: config.urls.graphql,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      cache: 'no-cache',
    },
    credentials: 'include',
    // Allow apollo to use fetch on older browsers
    fetch: window.fetch ? window.fetch : fetch,
  }

  // HTTP Apollo link (applied on every request)
  //    /!\ Headers props must be in lowercase
  const uploadLink = createUploadLink(options)

  // Create the subscription websocket link
  const wsLink = new WebSocketLink({
    uri: config.urls.graphql.replace('http', 'ws'),
    options: {
      reconnect: true,
    },
  })

  const whitelistLink = new ApolloLink((operation, forward) => {
    const { headers } = operation.getContext()

    operation.setContext({
      headers: {
        ...headers,
        'X-Queryhash': whitelist[operation.operationName],
      },
    })

    return forward(operation)
  })

  // Split link based on operation type
  const terminatingLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    uploadLink,
  )

  // Send tracking data
  const trackingLink = new ApolloLink(tracking)

  // Send version data
  const versionLink = new ApolloLink(version)

  // Deal with the serverTime
  const serverTimeLink = new ApolloLink(serverTime.bind(null, store))

  // Errors handling afterware
  const errorsLink = onError(errors.bind(null, store, router))

  // Debounce some requests
  const debounceLink = new DebounceLink(300)

  const retryLink = new RetryLink({
    attempts: (count, operation, error) => {
      const context = operation.getContext()
      const { retryCount = 5, response } = context

      // We want o retry only if the server is laggy or not responding in time
      // For example we d'ont want to retry if the mutation or the query is invalid
      // https://fr.wikipedia.org/wiki/Liste_des_codes_HTTP#5xx_-_Erreur_du_serveur_/_du_serveur_d'application
      return (
        !!error && !!retryCount && count < retryCount && [502, 503, 504].includes(response?.status)
      )
    },
    delay: count => {
      return count * 1000
    },
  })

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  })

  const cache = new InMemoryCache({
    dataIdFromObject,
    fragmentMatcher,
  })

  // Instantiate a new Apollo client
  return new ApolloClient({
    link: ApolloLink.from([
      trackingLink,
      versionLink,
      serverTimeLink,
      debounceLink,
      errorsLink,
      retryLink,
      whitelistLink,
      terminatingLink,
    ]),
    cache,
    queryDeduplication: true,
    connectToDevTools: config.apollo.connectToDevTools,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  })
}

/**
 * Create a new VueApollo provider with the given client
 * @param {Object} client
 * @returns {Object}
 */
function createApolloProvider(client) {
  Vue.use(VueApollo)

  return new VueApollo({
    defaultClient: client,
  })
}

export { createApolloClient, createApolloProvider }
