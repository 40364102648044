<template>
  <div>
    <VMenu
      v-model="expanded"
      bottom
      offset-y
      :nudge-bottom="8"
      :close-on-content-click="false"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on: onMenu }">
        <VTooltip bottom>
          <template v-slot:activator="{ on: onTooltip }">
            <VBtn
              v-model="expanded"
              v-bind="{ xSmall, small, large, xLarge, ...$attrs }"
              :color="color"
              v-on="{ ...onTooltip, ...onMenu }"
            >
              <VIcon
                v-bind="{ xSmall, small, large, xLarge }"
                :color="color"
                v-text="'mdi-circle'"
              />
            </VBtn>
          </template>

          <span v-text="tooltip" />
        </VTooltip>
      </template>

      <VCard
        :max-width="480"
        class="d-flex flex-column py-4 px-6"
      >
        <div class="d-flex flex-row align-center subtitle-1 font-weight-semi-bold">
          <VIcon
            small
            :color="color"
            class="mr-2"
            v-text="'mdi-circle'"
          />

          <span v-text="title" />
        </div>

        <div
          class="my-2 mx-1 caption grey--text text--pre-line"
          v-text="__('cp:freelancer-availability-button:description')"
        />

        <div
          v-if="!isAvailable && !editing"
          class="d-flex flex-row justify-end mt-4"
        >
          <VBtn
            small
            text
            color="error"
            class="mr-4"
            @click="onChangePress"
            v-text="__('cp:freelancer-availability-button:actions:change')"
          />

          <VBtn
            small
            depressed
            color="success"
            @click="onAvailablePress"
            v-text="__('cp:freelancer-availability-button:actions:available')"
          />
        </div>

        <div
          v-else
          class="mt-4"
        >
          <form>
            <VInput
              :label="__('cp:freelancer-availability-button:form:availability-date')"
              :error-messages="form.errors.option"
            >
              <VRadioGroup
                v-model="form.fields.option"
                row
                hide-details
                :disabled="form.submitting"
              >
                <VContainer
                  fluid
                  class="py-1 px-4"
                >
                  <VRow>
                    <template v-for="option in options">
                      <VCol
                        :key="option.id"
                        :md="option.id === UnavailableOptions.CUSTOM ? 12 : 6"
                        sm="12"
                        class="pa-1"
                      >
                        <VRadio
                          :value="option.id"
                          color="error"
                        >
                          <template v-slot:label>
                            <span v-html="option.label" />

                            <CFieldDate
                              v-if="option.id === UnavailableOptions.CUSTOM"
                              v-model="form.fields.availabilityDate"
                              :placeholder="__('cp:freelancer-availability-button:form:custom:placeholder')"
                              :min="$clock.tomorrow().toISOString()"
                              :disabled="form.submitting"
                              prepend-inner-icon="mdi-calendar"
                              color="error"
                              dense
                              solo
                              class="ml-6"
                              @focus="() => { form.fields.option = UnavailableOptions.CUSTOM }"
                            />
                          </template>
                        </VRadio>
                      </VCol>
                    </template>
                  </VRow>
                </VContainer>
              </VRadioGroup>
            </VInput>
          </form>

          <div class="d-flex flex-row justify-end">
            <VBtn
              v-if="editing"
              small
              text
              color="secondary"
              class="mr-4"
              @click="onCancelPress"
              v-text="__('cp:freelancer-availability-button:actions:cancel')"
            />

            <VBtn
              small
              depressed
              :disabled="form.invalid"
              color="error"
              @click="() => form.submit(onUnavailablePress)"
              v-text="__('cp:freelancer-availability-button:actions:unavailable')"
            />
          </div>
        </div>
      </VCard>
    </VMenu>
  </div>
</template>

<script>
  import FormMixin from '@/mixins/FormMixin'

  import { required, requiredIf } from '@/utils/validators'

  import snoozeFreelance from './snoozeFreelance.gql'
  import unsnoozeFreelance from './unsnoozeFreelance.gql'

  const UnavailableOptions = {
    FOREVER: 'forever',
    MONTHS_1: 'months-1',
    MONTHS_3: 'months-3',
    MONTHS_6: 'months-6',
    CUSTOM: 'custom',
  }

  export default {
    name: 'FreelancerAvailabilityButton',
    mixins: [
      FormMixin,
    ],
    props: {
      /**
       * Related Freelancer resource
       * @type {Freelance}
       */
      freelancer: {
        type: Object,
        default: null,
      },
      /**
       * Sizes
       */
      xSmall: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      xLarge: {
        type: Boolean,
        default: false,
      },
    },
    constants: {
      UnavailableOptions,
    },
    form() {
      return {
        name: 'FreelancerAvailabilityButton',
        fields: {
          option: UnavailableOptions.FOREVER,
          availabilityDate: null,
        },
        validators: {
          option: { required },
          availabilityDate: { requiredIf: requiredIf(() => this.form.fields.option === UnavailableOptions.CUSTOM) },
        },
      }
    },
    data() {
      return {
        /**
         * Is set to "true", when the dropdown displaying the controls is expanded.
         * @type {Boolean}
         */
        expanded: false,
        /**
         * Is set to "true" to allow availability date editing
         * @type {Boolean}
         */
        editing: false,
      }
    },
    computed: {
      /**
       * Whether or not isAvailable is true or availabilityDate is in the past
       * @returns {Boolean}
       */
      isAvailable() {
        const { freelancer } = this
        const { isSameOrBeforeNow } = this.$clock

        const isAvailable = freelancer?.isAvailable
        const availabilityDate = freelancer?.availabilityDate

        return !!isAvailable || isSameOrBeforeNow(availabilityDate)
      },
      /**
       * Color used to represent the actual availability state of this freelancer
       * @type {String}
       */
      color() {
        const { isAvailable } = this

        return isAvailable ? 'green' : 'red'
      },
      /**
       * Text displayed in the main button's tooltip according to the current availability state
       * @type {String}
       */
      tooltip() {
        const { freelancer, isAvailable } = this
        const { isSameOrBeforeNow } = this.$clock
        const { format, __ } = this.$i18n

        if (isAvailable) {
          return __('cp:freelancer-availability-button:tooltip:available')
        }

        const availabilityDate = freelancer?.availabilityDate
        const safeAvailabilityDate = isSameOrBeforeNow(availabilityDate)
          ? null
          : availabilityDate

        return !safeAvailabilityDate
          ? __('cp:freelancer-availability-button:tooltip:unavailable')
          : __('cp:freelancer-availability-button:tooltip:unavailable-until', {
            date: format('date', safeAvailabilityDate, 'longdate'),
          })
      },
      /**
       * Text displayed as a title of the dropdown card exposing controls
       * @type {String}
       */
      title() {
        const { freelancer, isAvailable } = this
        const { isSameOrBeforeNow } = this.$clock
        const { format, __ } = this.$i18n

        if (isAvailable) {
          return __('cp:freelancer-availability-button:title:available')
        }

        const availabilityDate = freelancer?.availabilityDate
        const safeAvailabilityDate = isSameOrBeforeNow(availabilityDate)
          ? null
          : availabilityDate

        return !safeAvailabilityDate
          ? __('cp:freelancer-availability-button:title:unavailable')
          : __('cp:freelancer-availability-button:title:unavailable-until', {
            date: format('date', safeAvailabilityDate, 'longdate'),
          })
      },
      /**
       * List of proposed options when going unavailable.
       * @type {Array.<Object>}
       */
      options() {
        const { form } = this
        const { now } = this.$clock
        const { format, __ } = this.$i18n

        return [
          {
            id: UnavailableOptions.FOREVER,
            label: __('cp:freelancer-availability-button:options:forever'),
            value: null,
          },
          {
            id: UnavailableOptions.MONTHS_1,
            label: __('cp:freelancer-availability-button:form:options:1-month', {
              until: format('date', now().add(1, 'months'), 'longdate'),
            }),
            value: format('date', now().add(1, 'months'), 'iso'),
          },
          {
            id: UnavailableOptions.MONTHS_3,
            label: __('cp:freelancer-availability-button:form:options:3-months', {
              until: format('date', now().add(3, 'months'), 'longdate'),
            }),
            value: format('date', now().add(3, 'months'), 'iso'),
          },
          {
            id: UnavailableOptions.MONTHS_6,
            label: __('cp:freelancer-availability-button:form:options:6-months', {
              until: format('date', now().add(6, 'months'), 'longdate'),
            }),
            value: format('date', now().add(6, 'months'), 'iso'),
          },
          {
            id: UnavailableOptions.CUSTOM,
            label: __('cp:freelancer-availability-button:form:options:custom'),
            value: form.fields.availabilityDate,
          },
        ]
      },
    },
    methods: {
      /**
       * Fired when the user chooses to change the availability date while staying unavailable
       * @returns {Promise}
       */
      onChangePress() {
        this.editing = true
      },
      /**
       * Fired when the user chooses to change the availability date while staying unavailable
       * @returns {Promise}
       */
      onCancelPress() {
        this.editing = false
        this.form.reset()
      },
      /**
       * Fired when the unsnooze button is clicked
       * Performs mutation to unsnooze freelancer
       * @returns {Promise}
       */
      async onAvailablePress() {
        const { freelancer } = this

        await this.$apollo.mutate({
          mutation: unsnoozeFreelance,
          variables: {
            id: freelancer.id,
          },
          track: 'freelancer.availability.update',
        })

        this.expanded = false
        this.editing = false
        this.form.reset()
      },
      /**
       * Fired when the snooze button is clicked
       * Performs mutation to snooze freelancer
       * @returns {Promise}
       */
      async onUnavailablePress() {
        const { freelancer, form, options } = this

        await this.$apollo.mutate({
          mutation: snoozeFreelance,
          variables: {
            id: freelancer.id,
            until: options.find(o => o.id === form.fields.option)?.value || null,
          },
          track: 'freelancer.availability.update',
        })

        this.expanded = false
        this.editing = false
        this.form.reset()
      },
    },
  }
</script>
