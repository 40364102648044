import moment from 'moment-timezone'

import { __, format } from '@comet/i18n'

/**
 * Return a date with a defined time during the day
 * @param   {Date} date - must be encoded to the right time zone
 * @param   {Number} hour
 * @param   {Number} minute
 * @returns {Date}
 */
function dateWithTime(date, hour, minute) {
  return date && hour && minute
    ? moment(date)
        .hour(hour)
        .minute(minute)
        .format()
    : null
}

/**
 * Return humanized month duration
 * @param   {Date} duration
 * @param   {Boolean} short
 * @returns {String}
 */
function formatMonthDuration(duration, short) {
  const floored = Math.floor(duration)

  const years = floored >= 12 ? Math.floor(floored / 12) : 0
  const months = floored % 12

  const formattedMonths = __('co:duration:months', months, { value: months })
  const formattedYears = __('co:duration:years', years, { value: years })

  if (years) {
    // eslint-disable-next-line no-nested-ternary
    return months
      ? short
        ? `${formattedYears} +`
        : `${formattedYears} ${formattedMonths}`
      : formattedYears
  }

  if (months) {
    return short ? __('co:duration:less-than-year') : formattedMonths
  }
  return __('co:duration:none')
}

/**
 * Add working days to a given date based on a number of days per week.
 * @param {Number} days
 * @param {Moment} date
 * @param {Number} daysPerWeek
 * @returns {Moment}
 */
function calculateEstimatedEndDate(days, date, daysPerWeek = 5, formatter = 'writtendate') {
  const weeks = Math.floor(days / daysPerWeek)
  const remainingDays = days % daysPerWeek
  const estimated = moment(date)
    .add(weeks, 'weeks')
    .add(remainingDays, 'days')

  return estimated && formatter ? format('date', estimated, formatter) : estimated
}

/* eslint-disable import/prefer-default-export */
export { dateWithTime, formatMonthDuration, calculateEstimatedEndDate }
