<template>
  <div class="horizontal-stepper">
    <div :class="[$('options'), $(`-theme-${theme}`)]">
      <div
        v-for="(step, index) in safeComputedSteps"
        :key="step.id"
        :class="[$('step'), step.classes]"
      >
        <div v-if="index > 0" :class="$('step-separator')" />

        <AppLink
          :class="$('step-button')"
          :disabled="!step.clickable"
          @click="onStepPress(index, $event)"
        >
          <div :class="$('chip')">
            <template v-if="step.current">
              <div :class="$('circle--big')" />
              <div :class="$('circle--small')" />
            </template>

            <i v-if="step.icon" :class="[$('step-value'), step.icon]" />
            <Txt
              v-else
              :class="$('step-value')"
              :value="index + 1"
              :size="Txt.Size.XS"
              :theme="step.theme || step.active ? 'white' : 'grey'"
              :inline="true"
            />
          </div>

          <Txt
            :class="$('step-description')"
            :theme="step.theme || 'grey'"
            :inline="true"
            v-html="step.label"
          />
        </AppLink>
      </div>
    </div>

    <slot v-if="this.$slots.head" name="head" />

    <div v-if="displayContent">
      <div
        v-for="(step, index) in safeComputedSteps"
        v-show="step.remount ? true : index === active"
        :key="`content-${step.id}`"
        :class="$('content')"
      >
        <slot
          v-if="step.id && (step.remount ? index === active : true)"
          :name="step.id"
          :active="index === active"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import _keys from 'lodash/keys'

  import AppLink from '@/core/controls/AppLink/AppLink'
  import Txt from '@/core/text/Txt/Txt'

  import RouterMixin from '@/mixins/RouterMixin'

  import { int } from '@/utils/transform'

  const CURRENT_MODES = {
    WARNING: 'warning',
    FAILED: 'failed',
  }

  const ClickableMode = {
    PREVIOUS: 'previous',
    ALL: 'all',
    NULL: null,
  }

  const Theme = {
    DUAL: 'dual',
    PRIMARY_ALT: 'primary-alt',
  }

  export const HorizontalStepperClickableMode = ClickableMode
  export const HorizontalStepperTheme = Theme

  /**
   * WARNING : This component supports 1-based indexes
   */
  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ ClickableMode,Theme,
    name: 'HorizontalStepper',
    __enums: {
      ClickableMode,
      Theme,
    },
    components: {
      AppLink,
      Txt,
    },
    mixins: [RouterMixin],
    props: {
      /**
       * The current step displayed
       */
      value: {
        type: [Number, String],
        default: 1,
      },
      /**
       * Index of the last unlocked step
       * @type {Object}
       */
      maxUnlocked: {
        type: [Number, String],
        default: 0,
      },
      /**
       * Titles array
       * Can be an array of string or an array of object as following :
       * {
       *    label: 'labelDisplayed',
       *    clickable: true, // If this item is clickable
       *    mode: warning / failed // if nothing provide, then no mode
       * }
       * @type {Array.<String|Object>}
       */
      steps: {
        type: Array,
        required: true,
      },
      /**
       * Clickable mode:
       * - `all` : all the steps are clickable
       * - `previous` : only the steps before max unlocked are clickables
       * - default : stepper is not clickable
       */
      clickableMode: {
        type: String,
        default: null,
      },
      /**
       * Theme of the stepper
       * Can currently be :
       * "dual",
       * "primary-alt"
       */
      theme: {
        type: String,
        default: HorizontalStepperTheme.DUAL,
      },
      /**
       * As 'connected' Stepper relies on the URL query params to know its value
       * Name allows to distinguish potential differents Tabs in the same view.
       */
      name: {
        type: String,
        default: 'step',
      },
      /**
       * If 'true', the stepper is connected to the URL query params and handle
       * its currently selected value.
       * Else, it relies on the default value.
       */
      connected: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      /**
       * Display the content sections only if there is a slot to display
       * @type {Boolean}
       */
      displayContent() {
        return !!_keys(this.$slots).length
      },
      /**
       * Normalize the steps as an Array of Object
       * (instead of authorized Array of String)
       * @type {Array.<Object>}
       */
      safeSteps() {
        const { steps } = this

        return steps.map((o, index) => ({
          id: index,
          ...(typeof o === 'string' ? { label: o } : o),
        }))
      },
      /**
       * Steps with additionnal informations:
       * classes, icon, clickable, active
       * @type {Array.<Object>}
       */
      safeComputedSteps() {
        /* eslint-disable no-nested-ternary */
        const { safeSteps, active, clickableMode, safeMaxUnlocked, theme: stepperTheme } = this

        return safeSteps.map((s, i) => {
          const isActiveStep = i === active
          const clickableAndUnlocked = clickableMode === 'previous' && i <= safeMaxUnlocked
          const indivuallyClickable = typeof s === 'object' && !!s.clickable
          const isStepClickable =
            !isActiveStep &&
            (clickableMode === 'all' || clickableAndUnlocked || indivuallyClickable)

          // Compute the theme to apply on the step. If there is a mode on the
          // step then the theme is danger (because the stepper only handle
          // failed / warning which are red), if the step is reachable we apply
          // the stepper theme otherwise there is no theme
          const theme = s.mode ? 'danger' : i <= safeMaxUnlocked ? stepperTheme : null

          // Compute the icon to display: If the step is failed, then we display
          // the failed icon, if the step is reachable we display the check icon
          // otherwise we display the number of the step (1-based)
          const icon =
            s.mode === CURRENT_MODES.FAILED
              ? 'icon-cross'
              : i < safeMaxUnlocked
              ? 'icon-check'
              : null

          return {
            ...s,
            icon,
            theme,
            active: isActiveStep,
            current: i === safeMaxUnlocked,
            clickable: isStepClickable,
            classes: {
              'horizontal-stepper-step--clickable': isStepClickable,
              'horizontal-stepper-step--active': isActiveStep,
              'horizontal-stepper-step--current': i === safeMaxUnlocked,
              [`horizontal-stepper-step--${s.mode}`]: !!s.mode,
              'horizontal-stepper-step--done': i < safeMaxUnlocked,
            },
          }
        })
      },
      /**
       * The index of the currently active tab
       * If not connected, return the given value
       * @type {Number}
       */
      active() {
        const { name, value, connected, route, getIndex } = this
        /**
         * When not 'connected', the active step is the one specified as 'value'
         */
        if (!connected) {
          if (typeof value === 'string') {
            return getIndex(value)
          }
          return value
        }

        /**
         * If it is 'connected' but no URL query param is set,
         * select de value as default, the first one if nothing is specified
         */
        const param = route.query[name]
        if (!param) {
          if (typeof value === 'string') {
            return getIndex(value)
          }

          return value || 0
        }

        /**
         * Else it relies on the URL query params defined by 'name'
         * It's an integer, use it as index
         */
        const asInt = int(param)
        if (Number.isInteger(asInt)) {
          return asInt
        }

        /**
         * Else, find the option which has this 'name'
         */
        return getIndex(param)
      },
      /**
       * Safe index of the last unlocked step
       * @type {Number}
       */
      safeMaxUnlocked() {
        const { getIndex, maxUnlocked, active } = this
        if (!maxUnlocked) {
          return active
        }

        return typeof maxUnlocked === 'string' ? getIndex(maxUnlocked) : maxUnlocked
      },
    },
    methods: {
      /**
       * Get the index with defined default value
       * @param   {Number|String} - value
       * @param   {Number}        - defaultValue
       * @returns {Number}
       */
      getIndex(value, defaultValue = 0) {
        const { safeSteps } = this
        const index = safeSteps.findIndex(o => o.id === value)

        return index > -1 ? index : defaultValue
      },
      /**
       * On step press, emit the selected id.
       * In case the stepper is connected, navigate to the right step
       * @param {String|Number} - option id
       * @param {Object}         - event
       * @returns {void}
       */
      onStepPress(index, event) {
        const { connected, safeComputedSteps } = this
        const optionId = safeComputedSteps[index].id

        event.preventDefault()

        if (connected) {
          const { name, route, navigate } = this

          navigate({
            path: route.path,
            query: {
              ...route.query,
              [name]: optionId,
            },
          })
        }

        this.$emit('input', optionId)
        this.$emit('change', optionId)
        this.$emit('select', optionId)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'
  @import '~@/assets/css/_separator.styl'
  @import '~@/assets/css/_grid.styl'

  .horizontal-stepper {
    background: transparent
    layout-grid()

    &-options {
      color: var(--color-font-light)
      display: flex
      flex-direction: row
    }

    &-step {
      position: relative
      display: flex
      flex: 1
      flex-direction: row
      padding: 0
    }

    &-step:first-child {
      flex: 0
    }

    &-step--done,
    &-step--active {
      opacity: 1
    }

    &-step--clickable {
      .horizontal-stepper-step-button {
        transition: all .1s ease-in-out

        &:hover {
          transform: scale(1.08)

          .horizontal-stepper-step-description,
          .horizontal-stepper-circle--big,
          .horizontal-stepper-step-value {
            filter: brightness(90%)
          }
        }
      }
    }

    &-step-button {
      width: 53px
      display: flex
      flex-direction column
      align-items: center

      &:not(.disabled) {
        cursor: pointer
      }

      i {
        font-weight: bold
      }
    }

    &-step-value {
      width: 24px
      height: 24px
      text-align: center
      padding-left: 1px
    }

    &-chip {
      position: relative
      width: 36px
      height: 36px

      display: flex
      align-items: center
      justify-content: center

      ^[0]-step-value {
        position: absolute
        height: 24px
        width: 24px
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        color: var(--color-font-light)
        box-sizing: border-box
      }
    }

    &-step-description {
      width: 80px
      text-align: center
      word-break: normal
    }

    &-circle--big, &-circle--small {
      position: absolute
      border-radius: 50%
      box-sizing: border-box
      border-width: 1px
      border-style: solid
    }

    &-circle--big {
      width: 36px
      height: 36px
      opacity: 0.32
    }

    &-circle--small {
      width: 30px
      height: 30px
      opacity: 0.72
    }

    &-step-description {
      text-align: center
      color: var(--color-font-light)
    }

    &-step-separator {
      horizontal-dotted-separator(var(--color-stroke))
      background-position: 0 12px
      flex: 1
    }

    &-options&--theme-dual,
    .horizontal-stepper-options.horizontal-stepper--theme-primary-alt {
      & ^[0]-step--warning:not(^[0]-step--done) {
        & ^[0]-circle--big,
        & ^[0]-circle--small {
          border-color: var(--color-negative)
        }

        & ^[0]-step-description {
          color: var(--color-negative)
        }

        & ^[0]-step-value {
          color: var(--color-negative)
          border: 1px solid var(--color-negative)
        }

        &^[0]-step--active {
          & ^[0]-step-value {
            background-color: var(--color-negative)
            color: var(--color-font-contrast)
          }
        }
      }

      ^[0]-step--failed {
        & ^[0]-circle--small,
        & ^[0]-circle--big {
          border-color: var(--color-negative)
        }

        & ^[0]-step-value, & ^[0]-step-description {
          color: var(--color-negative)
        }
      }
    }

    // -------------------- Themes --------------------
    // Dual
    &-options&--theme-dual {
      ^[0]-step--active:not(^[0]-step--failed) {
        & ^[0]-step-value {
          color: var(--color-contrast)
          background-color: var(--color-black)
        }
      }

      & ^[0]-step--current {
        &:not(^[0]-step--active) {
          & ^[0]-step-value {
            border: 1px solid var(--color-font-light)
          }
        }
      }

      & ^[0]-step:not(^[0]-step--active):not(^[0]-step--current) {
        & ^[0]-step-value {
          border: 1px solid var(--color-border)
        }
      }

      ^[0]-step--active:not(^[0]-step--failed),
      ^[0]-step--done,
      ^[0]-step--warning {
        & ^[0]-step-separator {
          horizontal-dotted-separator(var(--color-black))
          background-position: 0 12px
        }
      }

      ^[0]-step--active:not(^[0]-step--failed),
      ^[0]-step--done {
        & ^[0]-step-description {
          color: var(--color-font)
        }
      }

      ^[0]-step--done {
        & ^[0]-step-value {
          color: var(--color-font)
        }
      }
    }

    // Primary-alt
    &-options.horizontal-stepper--theme-primary-alt {
      & ^[0]-step--active:not(^[0]-step--failed) {
        & ^[0]-step-value {
          color: var(--color-font-contrast)
          background-color: var(--color-brand)
        }
      }

      & ^[0]-step--active:not(^[0]-step--failed),
      & ^[0]-step--active:not(^[0]-step--warning),
      & ^[0]-step--done {
        & ^[0]-step-separator {
          horizontal-dotted-separator(var(--color-brand))
          background-position: 0 12px
        }
      }

      & ^[0]-step:not(^[0]-step--active):not(^[0]-step--done):not(^[0]-step--current) {
        & ^[0]-step-value {
          border: 1px solid var(--color-border)
        }
      }

      & ^[0]-step--done:not(^[0]-step--active) {
        & ^[0]-step-value {
          border: 1px solid var(--color-brand)
        }
      }

      & ^[0]-step--warning,
      & ^[0]-step--failed  {
        & ^[0]-step-separator {
          horizontal-dotted-separator(var(--color-negative))
          background-position: 0 12px
        }
      }

      & ^[0]-step--current {
        &:not(^[0]-step--failed) {
          & ^[0]-circle--big, & ^[0]-circle--small {
            border-color: var(--color-brand)
          }
        }

        &:not(^[0]-step--active) {
          & ^[0]-step-value {
            border: 1px solid var(--color-brand)
            color: var(--color-brand)
          }
        }

        & ^[0]-step-description {
          color: var(--color-brand)
        }
      }

      & ^[0]-step--active:not(^[0]-step--failed),
      & ^[0]-step--done {
        & ^[0]-step-description {
          color: var(--color-brand)
        }
      }

      & ^[0]-step--done:not(^[0]-step--active) {
        & ^[0]-step-value {
          color: var(--color-brand)
        }
      }
    }
  }
</style>
