<template>
  <div :class="$style.root">
    <slot
      name="activator"
      :disabled="active && showConfirm"
      :on="{ click: e => active && onButtonPress(e) }"
    />

    <VDialog
      v-if="active"
      v-model="showConfirm"
      :max-width="480"
      :persistent="persistent"
    >
      <VCard class="pa-2">
        <VCardTitle
          class="headline"
          :class="(
            color &&
            color !== 'secondary' &&
            (color.split(' ').length === 1
              ? `${color.split(' ')[0]}--text`
              : `${color.split(' ')[0]}--text text--${color.split(' ')[1]}`
            )
          )"
          v-text="title || __('cp:c-modal-confirm:title')"
        />

        <VCardText
          class="body-2 grey--text text--darken-3 text--pre-line mt-2"
          v-html="body || __('cp:c-modal-confirm:action:body')"
        />

        <VCardActions>
          <VSpacer />

          <VBtn
            text
            color="secondary"
            class="mx-2"
            @click="onCancelPress"
            v-text="cancelLabel || __('cp:c-modal-confirm:action:cancel')"
          />

          <VBtn
            text
            :color="color"
            class="mx-2"
            @click="onConfirmPress"
            v-text="confirmLabel || __('cp:c-modal-confirm:action:confirm')"
          />
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
  export default {
    name: 'CModalConfirm',
    inheritAttrs: false,
    props: {
      /**
       * If "false", the dialog is never displayed
       * @type {Boolean}
       */
      active: {
        type: Boolean,
        default: true,
      },
      /**
       * Clicking outside of the element will not deactivate it.
       * @type {Boolean}
       */
      persistent: {
        type: Boolean,
        default: false,
      },
      /**
       * Text displayed as title of the confirmation modal
       * @type {String}
       */
      title: {
        type: String,
        default: null,
      },
      /**
       * Text displayed in the body of this confirmation modal
       * @type {String}
       */
      body: {
        type: String,
        default: null,
      },
      /**
       * Color applied to the main action button and the related confirmation button
       * @type {String}
       */
      color: {
        type: String,
        default: 'primary',
      },
      /**
       * Label displayed in the confirmation button
       * @type {String}
       */
      confirmLabel: {
        type: String,
        default: null,
      },
      /**
       * Label displayed in the cancellation button
       * @type {String}
       */
      cancelLabel: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Determines if the confirmation modal must be shown or not (i.e. the related
         * action has been done or not)
         * @type {Boolean}
         */
        showConfirm: false,
      }
    },
    methods: {
      /**
       * Exposed method to programmatically simulate a button click
       */
      press() {
        this.onButtonPress()
      },
      /**
       * Fired when the main button is pressed following a user action
       * @param {Event} event
       */
      onButtonPress(event) {
        if (event)  {
          event.preventDefault()
          event.stopImmediatePropagation()
        }

        this.showConfirm = true

        this.$emit('click')
      },
      /**
       * Fired when the user chooses to cancel the pending action
       */
      onCancelPress() {
        this.showConfirm = false

        this.$emit('cancel')
      },
      /**
       * Fired when the user chooses to confirm the pending action
       */
      onConfirmPress() {
        this.showConfirm = false

        this.$emit('confirm')
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    .spinner {
      opacity: .8
    }
  }
</style>
