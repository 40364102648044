var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-nowrap"},[_vm._l((_vm.visibleButtons),function(button){return [(button.slot && _vm.$slots[button.slot])?_vm._t(button.slot):_c('CModalConfirm',{key:button.id,attrs:{"active":!!button.secure,"color":button.color,"title":button.secureTitle,"body":button.secureBody,"confirm-label":button.secureConfirmLabel,"cancel-label":button.secureCancelLabel},on:{"confirm":function (e) { return button.onPress && button.onPress(e, _vm.item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var disabled = ref.disabled;
var onConfirm = ref.on;
return [_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"ml-2 mb-2",attrs:{"outlined":_vm.precedence(button.outlined, _vm.outlined),"x-small":_vm.precedence(button.xSmall, _vm.xSmall),"small":_vm.precedence(button.small, _vm.small),"large":_vm.precedence(button.large, _vm.large),"x-large":_vm.precedence(button.xLarge, _vm.xLarge),"color":_vm.precedence(button.color, 'secondary'),"disabled":button.disabled || disabled},on:{"click":function (e) { return !button.secure && button.onPress && button.onPress(e, button); }}},'VBtn',_vm._pick(button, ['to', 'href', 'target']),false),Object.assign({}, onTooltip, onConfirm)),[(button.icon)?_c('VIcon',_vm._b({attrs:{"color":(
                  _vm.precedence(button.outlined, _vm.outlined)
                    ? button.color
                    : undefined
                )},domProps:{"textContent":_vm._s(button.icon)}},'VIcon',Object.assign({}, {xSmall: _vm.xSmall, small: _vm.small, large: _vm.large, xLarge: _vm.xLarge}, _vm.iconProps, button.iconProps),false)):_vm._e(),_vm._v(" "+_vm._s(button.label)+" ")],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(button.tooltip)}})])]}}],null,true)})]}),(_vm.moreButton)?_c('VMenu',{attrs:{"bottom":"","auto":!_vm.list,"left":_vm.list,"offset-y":"","nudge-bottom":8,"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var onMenu = ref.on;
return [_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var onTooltip = ref.on;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"ml-2 mb-2",model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},'VBtn',_vm._omit(_vm.moreButton, ['icon']),false),Object.assign({}, onTooltip, onMenu)),[_c('VIcon',_vm._b({attrs:{"color":"secondary"},domProps:{"textContent":_vm._s(_vm.moreButton.icon)}},'VIcon',_vm._pick(_vm.moreButton, ['xSmall', 'small', 'large', 'xLarge']),false))],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.__('cp:c-button-group:more'))}})])]}}],null,false,4263485896),model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},[(_vm.list)?_c('VList',[_vm._l((_vm.hiddenButtons),function(item){return [(item.slot && _vm.$slots[item.slot])?_vm._t(item.slot):_c('CModalConfirm',{key:item.id,attrs:{"active":!!item.secure,"color":item.color,"title":item.secureTitle,"body":item.secureBody,"confirm-label":item.secureConfirmLabel,"cancel-label":item.secureCancelLabel},on:{"confirm":function (e) { return item.onPress && item.onPress(e, item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var disabled = ref.disabled;
                var on = ref.on;
return [_c('VListItem',_vm._g(_vm._b({attrs:{"disabled":item.disabled || disabled,"dense":_vm.xSmall || _vm.small,"color":item.color},on:{"click":function (e) { return !item.secure && item.onPress && item.onPress(e, item); }}},'VListItem',_vm._pick(item, ['to', 'href', 'target']),false),on),[(item.icon)?_c('VListItemIcon',{staticClass:"justify-center"},[_c('VIcon',_vm._b({attrs:{"color":item.color},domProps:{"textContent":_vm._s(item.icon)}},'VIcon',Object.assign({}, {xSmall: _vm.xSmall, small: _vm.small, large: _vm.large, xLarge: _vm.xLarge}, _vm.iconProps, item.iconProps),false))],1):_vm._e(),_c('VListItemTitle',{class:(
                  item.color &&
                  item.color !== 'secondary' &&
                  (item.color.split(' ').length === 1
                    ? ((item.color.split(' ')[0]) + "--text")
                    : ((item.color.split(' ')[0]) + "--text text--" + (item.color.split(' ')[1]))
                  )
                ),domProps:{"innerHTML":_vm._s(item.label || item.tooltip)}})],1)]}}],null,true)})]})],2):_c('VList',[_vm._l((_vm.hiddenButtons),function(button){return [_c('VListItem',{key:button.id,attrs:{"dense":_vm.xSmall || _vm.small}},[(button.slot && _vm.$slots[button.slot])?_vm._t(button.slot):_c('CModalConfirm',{key:button.id,attrs:{"active":!!button.secure,"color":button.color,"title":button.secureTitle,"body":button.secureBody,"confirm-label":button.secureConfirmLabel,"cancel-label":button.secureCancelLabel},on:{"confirm":function (e) { return button.onPress && button.onPress(e, _vm.item); }},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var disabled = ref.disabled;
                var onConfirm = ref.on;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var onTooltip = ref.on;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"outlined":_vm.precedence(button.outlined, _vm.outlined),"x-small":_vm.precedence(button.xSmall, _vm.xSmall),"small":_vm.precedence(button.small, _vm.small),"large":_vm.precedence(button.large, _vm.large),"x-large":_vm.precedence(button.xLarge, _vm.xLarge),"color":_vm.precedence(button.color, 'secondary'),"disabled":button.disabled || disabled},on:{"click":function (e) { return !button.secure && button.onPress && button.onPress(e, button); }}},'VBtn',_vm._pick(button, ['to', 'href', 'target']),false),Object.assign({}, onTooltip, onConfirm)),[(button.icon)?_c('VIcon',_vm._b({attrs:{"color":button.color},domProps:{"textContent":_vm._s(button.icon)}},'VIcon',Object.assign({}, {xSmall: _vm.xSmall, small: _vm.small, large: _vm.large, xLarge: _vm.xLarge}, _vm.iconProps, button.iconProps),false)):_vm._e(),_vm._v(" "+_vm._s(button.label)+" ")],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(button.tooltip)}})])]}}],null,true)})],2)]})],2)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }