<template>
  <TransitionGroup
    name="notification-list"
    tag="div"
    :class="$style.notificationStack"
    :style="animationStyle"
    @after-leave="afterLeave"
  >
    <Box
      v-for="notification in stack"
      :key="`stack-${notification.id}`"
      :class="$style.item"
      :padding="Box.Padding.NONE"
    >
      <div :class="$style.notification">
        <AppLink :to="notification.url" @click="() => $emit('click', notification.id)">
          <Txt
            v-if="notification.id === SUM_UP_ID"
            :class="$style.description"
            :value="
              isCorporate
                ? __('cp:common:notification-stack:sum-up:corporate', notification.count, {
                    count: notification.count,
                  })
                : __('cp:common:notification-stack:sum-up:freelancer', notification.count, {
                    count: notification.count,
                  })
            "
            :size="Txt.Size.XXS"
            :raw="true"
          />

          <Txt
            v-else
            :class="$style.description"
            :value="notification.description"
            :size="Txt.Size.XXXS"
            :raw="true"
          />
        </AppLink>

        <NavButton
          :class="$style.closeButton"
          :theme="NavButton.Theme.SECONDARY_VOID"
          :size="NavButton.Size.SMALLER"
          icon="cross"
          @click="() => $emit('click', notification.id)"
        />
      </div>
    </Box>
  </TransitionGroup>
</template>

<script>
  import { mapGetters } from 'vuex'

  import AppLink from '@/core/controls/AppLink/AppLink'
  import Box from '@/core/layout/Box/Box'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import Txt from '@/core/text/Txt/Txt'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import I18nMixin from '@/mixins/I18nMixin'

  export const NOTIFICATION_DISPLAY_TIME = 8000

  // Used to identify the sum up card, that is not a real notification fetched from the api
  export const SUM_UP_ID = 'sumUp'

  /**
   * The notification stack will be displayed in app to show the user its new notifications
   * in real time.
   * But when the user only has the comet tab opened but not visible, when he.she'll be back,
   * we will display him a sum up of the number of new notifications.
   */
  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  Box: Box.__enums,
  NavButton: NavButton.__enums,
  Txt: Txt.__enums,
},

    name: 'NotificationStack',
    components: {
      AppLink,
      Box,
      NavButton,
      Txt,
    },
    mixins: [ApolloMixin, I18nMixin],
    props: {
      stack: {
        type: Array,
        default: null,
      },
    },
    constants: {
      SUM_UP_ID,
    },
    data() {
      return {
        /**
         * Whether to display the toasts container or not
         * @type {Boolean}
         */
        displayContainer: false,
        /**
         * Inline style to make the animation smooth
         */
        animationStyle: {},
      }
    },
    computed: {
      ...mapGetters(['isCorporate']),
    },
    created() {
      document.addEventListener('visibilitychange', this.tabVisibilityChange)
    },
    beforeDestroy() {
      document.removeEventListener('visibilitychange', this.tabVisibilityChange)
    },
    methods: {
      /**
       * When the tab is visible again, start the timout on the display of the sum up of the
       * number of notifications received during user absence.
       * @returns {void}
       */
      tabVisibilityChange() {
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            this.$emit('click', SUM_UP_ID)
          }, NOTIFICATION_DISPLAY_TIME)
        }
      },
      /**
       * Handler called after a message slide-out animation
       * If it's the last item, make sure the container is the right height long enough
       * for the animation ton complete
       * (not perfect, but couldn't find better. Feel free todo change it if you have a better idea)
       * @returns {void}
       */
      afterLeave() {
        if (!this.stack.length) {
          this.animationStyle = { minHeight: '100px' }

          setTimeout(() => {
            this.animationStyle = { minHeight: '0px' }
          }, 1000)
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_variables.styl'
  @import '~@/assets/css/_shadow.styl'

  .notificationStack {
    flex: 0
    position: fixed
    top: $app-navbar-height + 8px
    right: 16px
    width: 325px
    z-index: 5

    .item {
      shadow('darker', 'small')
      margin-bottom: 10px
    }

    .notification {
      position: relative
      min-height: 60px
      display: flex
      align-items: center

      .description {
        padding-right: 20px
        padding: 10px 20px 10px 16px
      }

      .closeButton {
        position: absolute
        top: 0
        right: 0
      }
    }
  }
</style>
