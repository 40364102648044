<template>
  <AppLink
    v-if="!hidden"
    class="social-link"
    type="external"
    :to="href"
    :blank="true"
    :class="classes"
    :disabled="disabled"
  >
    <i v-if="iconClass" :class="iconClass" />
    <span v-if="!short">
      {{ text }}
    </span>
  </AppLink>
</template>

<script>
  import _get from 'lodash/get'
  import config from '@/config'
  import AppLink from '@/core/controls/AppLink/AppLink'

  const Type = {
    BITBUCKET: 'bitbucket',
    GITLAB: 'gitlab',
    GITHUB: 'github',
    KAGGLE: 'kaggle',
    STACKOVERFLOW: 'stackoverflow',
    WEBSITE: 'website',
    SLACK: 'slack',
    LINKEDIN: 'linkedin',
    HUBSPOT: 'hubspot',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Type,
    name: 'SocialLink',
    __enums: {
      Type,
    },
    components: {
      AppLink,
    },
    props: {
      /**
       * Short version of the slack button (no id, no border)
       * @type {Object}
       */
      short: {
        type: Boolean,
        default: false,
      },
      /**
       * Type of targeted social network (one of "none", "slack", "linkedin")
       */
      type: {
        type: String,
        default: 'none',
      },
      /**
       * Targeted URL (usefull when no social network "type" is provided)
       * or identification on the specified social network (id, username, ...)
       */
      target: {
        type: [String, Number],
        default: null,
      },
      /**
       * Label displayed as body of the link
       */
      label: {
        type: String,
        default: '',
      },
      /**
       * Custom icon
       */
      icon: {
        type: String,
        default: null,
      },
      /**
       * Contains extra parameter
       * [slackTeam]
       */
      params: {
        type: Object,
        default: null,
      },
      /**
       * Theme of the icon can be "light" or "light-grey"
       */
      theme: {
        type: String,
        default: 'light',
      },
      /**
       * Disable link
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Icon text
       * @type {String}
       */
      text() {
        const { type, label, target } = this

        switch (type) {
          case 'slack':
            return `@${label || target}`
          default:
            return label || target.split('/').pop()
        }
      },
      /**
       * If slack link, overrides url with comet slack
       * @type {String}
       */
      href() {
        const { type, target, params } = this

        switch (type) {
          case 'slack':
            return config.methods.sendSlackDM(target)
          case 'hubspot':
            return `https://app.hubspot.com/contacts/${config.services.hubspot.portal}/${_get(
              params,
              'entity',
            )}/${target}`
          default:
            return target
        }
      },
      /**
       * Icon class
       * @type {String}
       */
      iconClass() {
        const { type, icon } = this

        switch (type) {
          case 'bitbucket':
            return 'icon-bitbucket'
          case 'gitlab':
            return 'icon-gitlab'
          case 'github':
            return 'icon-github'
          case 'kaggle':
            return 'icon-kaggle'
          case 'stackoverflow':
            return 'icon-stackoverflow'
          case 'website':
            return 'icon-sphere'
          case 'slack':
            return 'icon-slack'
          case 'linkedin':
            return 'icon-linkedin'
          case 'hubspot':
            return 'icon-hubspot'
          default:
            return icon
        }
      },
      /**
       * Displays the reconciliated retribution for the client,
       * commission included
       * @type {Object}
       */
      classes() {
        const { disabled, iconClass, short, theme, type } = this

        return {
          button: true,
          [type]: true,
          icon: !!iconClass,
          short,
          [`social-link--${theme}`]: true,
          'social-link--disabled': disabled,
        }
      },
      /**
       * Hide if no url and label
       * @type {Boolean}
       */
      hidden() {
        const { target, label } = this

        return !target && !label
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .social-link {
    height: 24px
    display: inline-block
    padding-left: 8px
    padding-right: 8px
    background-color: transparent
    border: 1px solid var(--color-font)
    border-radius: 24px
    font-family: $font
    font-weight: $font-regular
    font-size: 14px
    color: var(--color-font)
    text-decoration: none
    margin: 0 4px
    vertical-align: top
    opacity: .8
    white-space: nowrap

    > span,
    > i {
      display: inline-block
      line-height: 23px
      vertical-align: top
    }

    > span {
      ellipsis()
      max-width: 140px
    }

    &:hover:not(.social-link--disabled) {
      opacity: 1
    }

    &.short {
      width: 24px
      border: none
      padding: 0
      margin: 0
      text-align: center

      > i {
        font-size: 20px
        margin: 0
      }
    }

    &.linkedin {
      color: var(--color-linkedin)
    }

    &.kaggle {
      color: var(--color-kaggle)
    }

    &.gitlab {
      color: var(--color-gitlab)
    }

    &.bitbucket {
      color: var(--color-bitbucket)
    }

    &.stackoverflow {
      color: var(--color-stackoverflow)
    }

    &&--light-grey {
      border: solid var(--color-border) 2px
      border-radius: $radius-circle
      color: var(--color-font)

      i {
        font-size: 15px
      }
    }

    &:not(.short).linkedin {
      border: 1px solid var(--color-linkedin)

      i {
        font-size: 14px
        margin-left: 4px
      }
    }

    &--disabled {
      opacity: .3
    }
  }
</style>
