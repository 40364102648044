/* eslint-disable no-param-reassign */
import http from '@/store/services/http'
import config from '@/config'

export const SUBSCRIPTION_ADD = 'SUBSCRIPTION_ADD'

const app = {
  // ====================  STATE  ==================== //

  state: {},

  // ====================  GETTERS  ==================== //

  getters: {
    /**
     * Returns 'true' if the user has already subscribed this 'name'
     * @param {Object} state
     * @returns {Boolean}
     */
    hasSubscribed: state => name => !!state[name],
  },

  // ====================  MUTATIONS  ==================== //

  mutations: {
    /**
     * Flags the subscription in the sotre
     * @param {Object} state
     * @param {Object} payload
     * @returns {void}
     */
    [SUBSCRIPTION_ADD](state, { request }) {
      state[request.subscription] = true
    },
  },

  // ====================  ACTIONS  ==================== //

  actions: {
    /**
     * Subscribe to the given 'name'
     * @param {Object} context
     * @param {Object\String} payload
     * @returns {Promise}
     */
    subscribe(context, { name, body }) {
      const { portal, form } = config.services.hubspot
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portal}/${form}`

      return http(context, {
        types: [null, SUBSCRIPTION_ADD, null],
        endpoint: url,
        authenticated: false,
        subscription: name,
        options: {
          method: 'POST',
          body,
        },
        track: 'common.user.subscription.add',
      })
    },
  },
}

export default app
