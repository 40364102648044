<template>
  <div class="d-flex flex-row align-center" :class="$style.root">
    <template v-for="tag in tags">
      <VTooltip :key="tag.id" v-bind="{ [tooltipPosition]: true }" :max-width="400" :z-index="11">
        <template v-slot:activator="{ on }">
          <VIcon
            :key="tag.id"
            :size="14"
            class="mr-3"
            :class="[$style.icon, tag.disabled ? 'grey lighten-3' : tag.color]"
            v-on="on"
            v-text="tag.icon"
          />
        </template>

        <span class="text--pre-line white--text" v-html="tag.label" />
      </VTooltip>
    </template>
  </div>
</template>

<script>
  import _groupBy from 'lodash/groupBy'
  import _flatMap from 'lodash/flatMap'
  import _head from 'lodash/head'
  import _keyBy from 'lodash/keyBy'
  import _map from 'lodash/map'
  import _mapValues from 'lodash/mapValues'
  import _orderBy from 'lodash/orderBy'

  import {
    FreelancerMatchTags as MatchTags,
    FreelancerQualificationInterviewStatus as InterviewStatus,
    FreelancerQualificationFlagUrgency as FlagUrgency,
  } from '@comet/types'

  const INSTRUCTOR = 'instructor'
  const PENDING = 'pending'
  const FLAGGED_URGENT = FlagUrgency.URGENT
  const FLAGGED_NORMAL = FlagUrgency.NORMAL
  const FLAGGED_SELF = FlagUrgency.SELF
  const NOT_QUALIFIED = InterviewStatus.NOT_QUALIFIED
  const TO_REVIEW = InterviewStatus.TO_REVIEW
  const FROZEN = InterviewStatus.FROZEN
  const QUALIFIED = InterviewStatus.QUALIFIED

  export const TooltipPosition = {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ TooltipPosition,
    name: 'FreelancerActivityBadges',
    __enums: {
      TooltipPosition,
    },
    props: {
      /**
       * Related Freelancer resource
       * @type {Freelance}
       */
      freelancer: {
        type: Object,
        default: null,
      },
      /**
       * Custom tooltip position
       * @type {FreelancerActivityBadges.TooltipPosition}
       */
      tooltipPosition: {
        type: String,
        default: TooltipPosition.BOTTOM,
      },
    },
    computed: {
      /**
       * Exhaustive list of all the tags that must be displayed regarding the Freelancer's state
       * (qualification, last update, reactivity, ...)
       * @return {Array.<Object>}
       */
      tags() {
        const { freelancer, matchTags, qualificationTag } = this

        if (!freelancer || freelancer.preUser) {
          return []
        }

        return [qualificationTag, ...matchTags]
      },
      /**
       * List of tags concerning the Freelancer's availability, last update, reactivity, ...
       * @return {Array.<Object>}
       */
      matchTags() {
        const { freelancer } = this
        const { __ } = this.$i18n

        if (!freelancer) {
          return {}
        }

        const mappedMatchTags = freelancer.tagCategories.map((c) => {
          const grouped = _groupBy(c.tags, 'label')

          const mapped = _map(grouped, (tag, title) => ({
            title,
            labels: _flatMap(tag, (l) => l.subLabels),
          }))

          return {
            category: c.category,
            label: mapped
              .map((t) => `${t.labels.map((l) => `    • ${l}`).join('\n')}`)
              .join('\n\n'),
          }
        })
        const matchTagsLabels = _mapValues(_keyBy(mappedMatchTags, 'category'), 'label')

        return [
          {
            id: MatchTags.OPPORTUNITY,
            icon: 'mdi-lightbulb-on',
            color: 'primary',
            label:
              matchTagsLabels[MatchTags.OPPORTUNITY] ||
              __('cp:common:freelancer-activity-badges:match:opportunity:none'),
            disabled: !matchTagsLabels[MatchTags.OPPORTUNITY],
          },
          {
            id: MatchTags.MANUAL_INTERVENTION,
            icon: 'mdi-sleep',
            color: 'warning',
            label:
              matchTagsLabels[MatchTags.MANUAL_INTERVENTION] ||
              __('cp:common:freelancer-activity-badges:match:manual-intervention:none'),
            disabled: !matchTagsLabels[MatchTags.MANUAL_INTERVENTION],
          },
        ]
      },
      /**
       * List of tags concerning the Freelancer's qualification status
       * @return {Array.<Object>}
       */
      qualificationTag() {
        const { freelancer } = this
        const { isBeforeNowWithDelay } = this.$clock
        const { __ } = this.$i18n

        if (!freelancer) {
          return NOT_QUALIFIED
        }

        const interviews = freelancer.candidateInterviews || []
        const last = _head(_orderBy(interviews, ['date'], ['desc']))

        switch (true) {
          case freelancer.isInstructor:
            return {
              id: INSTRUCTOR,
              icon: 'mdi-medal',
              color: 'yellow darken-1',
              label: __('cp:common:freelancer-activity-badges:qualification:instructor'),
              disabled: false,
            }
          case freelancer.isQualified:
          case last && last.qualifStatus === InterviewStatus.QUALIFIED:
            return {
              id: QUALIFIED,
              icon: 'mdi-school',
              color: 'indigo',
              label: __('cp:common:freelancer-activity-badges:qualification:qualified', {
                skill: last.skill.name,
              }),
              disabled: false,
            }
          case last &&
            last.qualifStatus === InterviewStatus.FROZEN &&
            !isBeforeNowWithDelay(last.date, -2, 'months'): // Within the last 2 moths
            return {
              id: FROZEN,
              icon: 'mdi-school',
              color: 'error',
              label: __('cp:common:freelancer-activity-badges:qualification:frozen'),
              disabled: false,
            }
          case last && last.qualifStatus === InterviewStatus.TO_REVIEW:
            return {
              id: TO_REVIEW,
              icon: 'mdi-school',
              color: 'primary',
              label: __('cp:common:freelancer-activity-badges:qualification:to-review'),
              disabled: false,
            }
          case last && last.qualifStatus === InterviewStatus.NOT_QUALIFIED:
            return {
              id: PENDING,
              icon: 'mdi-school',
              color: 'primary',
              label: __('cp:common:freelancer-activity-badges:qualification:pending'),
              disabled: false,
            }
          case freelancer.flaggedForQualif === FlagUrgency.URGENT:
            return {
              id: FLAGGED_URGENT,
              icon: 'mdi-school',
              color: 'error',
              label: __('cp:common:freelancer-activity-badges:qualification:flagged:urgent'),
              disabled: false,
            }
          case freelancer.flaggedForQualif === FlagUrgency.NORMAL:
            return {
              id: FLAGGED_NORMAL,
              icon: 'mdi-school',
              color: 'warning',
              label: __('cp:common:freelancer-activity-badges:qualification:flagged:normal'),
              disabled: false,
            }
          case freelancer.flaggedForQualif === FlagUrgency.SELF:
            return {
              id: FLAGGED_SELF,
              icon: 'mdi-school',
              color: 'black',
              label: __('cp:common:freelancer-activity-badges:qualification:flagged:self'),
              disabled: false,
            }
          default:
            return {
              id: NOT_QUALIFIED,
              icon: 'mdi-school',
              color: 'black',
              label: __('cp:common:freelancer-activity-badges:qualification:not-qualified'),
              disabled: true,
            }
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    .icon {
      width: 20px
      height: 20px
      border-radius: 10px
      color: white
      cursor: default
    }
  }
</style>
