<template>
  <ul class="task-list">
    <!-- Items -->
    <li v-for="(task, index) in limitedTasks" :key="index" :class="$('task-line')">
      <Task
        :label="task"
        :closable="removable"
        :disabled="disabled"
        @close="onRemove(task, index)"
      />
    </li>

    <!-- Ellipsis -->
    <Tooltip v-if="!!ellipsis && hoverable" placement="bottom" theme="light">
      <li class="ellipsis">
        {{ __('cp:mission:task-list:ellipsis', { ellipsis }) }}
      </li>

      <div slot="tooltip" :class="$('tooltip')">
        <Task
          v-for="(task, index) in tooltipTasks"
          :key="index"
          :label="task"
          :disabled="disabled"
        />
      </div>
    </Tooltip>

    <!-- Empty -->
    <li v-if="!tasks || !tasks.length" :class="$('empty')">
      <Txt
        :value="empty || __('cp:mission:task-list:empty')"
        :size="Txt.Size.XS"
        :theme="Txt.Theme.GREY"
      />
    </li>
  </ul>
</template>

<script>
  import Tooltip from '@/core/layout/Tooltip/Tooltip'
  import Task from '@/core/badges/Task/Task'
  import Txt from '@/core/text/Txt/Txt'

  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Task: Task.__enums,
  Tooltip: Tooltip.__enums,
  Txt: Txt.__enums,
},

    name: 'TaskList',
    components: {
      Task,
      Tooltip,
      Txt,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Ordered list of tasks (as simple String)
       * @type {Array.<String>}
       */
      tasks: {
        type: Array,
        default: null,
      },
      /**
       * If 'true', the list is truncated
       */
      limit: {
        type: Number,
        default: 20,
      },
      /**
       * If 'true', the entered tasks can be removed
       */
      removable: {
        type: Boolean,
        default: false,
      },
      /**
       * Disable the items, if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * By default if there is more task than the limit in the task list
       * a tooltip appear to display the rest
       * Some components want to disable this behavior
       */
      hoverable: {
        type: Boolean,
        default: true,
      },
      /**
       * The message to display if the list is empty
       */
      empty: {
        type: String,
        default: '',
      },
    },
    computed: {
      /**
       * List of tasks cropped according to the 'limit' prop
       * @type {Array.<String>}
       */
      limitedTasks() {
        const { tasks, limit } = this

        return tasks ? tasks.slice(0, limit) : null
      },
      /**
       * List of taks that should be in the tooltip according to the limit
       * @type {Array.<String>}
       */
      tooltipTasks() {
        const { tasks, limit } = this

        return tasks ? tasks.slice(limit) : null
      },
      /**
       * Number of non-displayed (ellipsised) tasks according to the specified
       * 'limit' prop
       * @type {Number}
       */
      ellipsis() {
        const { tooltipTasks } = this

        return tooltipTasks ? tooltipTasks.length : 0
      },
    },
    methods: {
      /**
       * Fired when a task is closed
       * @param {Object} label
       * @param {Number} index
       * @returns {void}
       */
      onRemove(label, index) {
        const { tasks } = this

        if (tasks) {
          // Copy the list to avoid changing the initial object
          const copy = [...tasks]

          // Determines the new list without the item to remove
          copy.splice(index, 1)

          // And emit a "change" event with this new list
          this.$emit('change', copy)

          // And also a "remove" event
          this.$emit('remove', label, index)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .task-list {
    width: 100%
    list-style-type: none
    padding: 8px 0
    margin: 0

    &-tooltip {
      max-width: 500px
    }

    &-task-line:not(:first-child) {
      margin-top: 8px
    }

    li {
      &.ellipsis {
        position: relative
        margin-top: 8px
        line-height: 24px
        color: var(--color-brand)
        text-decoration: underline
      }
    }

    &-empty {
      text-align: center
      padding: 12px 0
    }
  }
</style>
