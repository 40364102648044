import { render, staticRenderFns } from "./NotificationCenter.vue?vue&type=template&id=499b724e&"
import script from "./NotificationCenter.vue?vue&type=script&lang=js&"
export * from "./NotificationCenter.vue?vue&type=script&lang=js&"
import style0 from "./NotificationCenter.vue?vue&type=style&index=0&lang=stylus&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
