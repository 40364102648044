<template>
  <div>
    <TransitionGroup
      v-show="displayContainer"
      name="message"
      tag="div"
      :class="$style.root"
      @before-enter="beforeEnter"
      @after-leave="afterLeave"
    >
      <VAlert
        v-for="message in messages"
        :key="message.id"
        dense
        border="left"
        colored-border
        :color="message.type === ERROR ? 'red' : 'green'"
        :elevation="2"
        :class="{
          [$style.alert]: true,
          [$style.clickable]: isClickable(message),
        }"
        :icon="getIcon(message)"
        :type="message.type || 'info'"
        @click.native="onClick(message)"
      >
        <span v-text="message.message" />
        <VBtn v-if="isClickable(message)" x-small icon class="ml-2">
          <VIcon color="grey--text" x-small v-text="'mdi-cursor-default-click-outline'" />
        </VBtn>
      </VAlert>
    </TransitionGroup>

    <VDialog v-if="selectedMessage" v-model="selectedMessage" :max-width="720">
      <VCard class="d-flex flex-column" :class="$style.modal">
        <div class="d-flex justify-space-between py-1 px-2" :class="$style.code">
          <span :class="$style.monospace" v-text="errorTitle" />
          <div>
            <VBtn small :icon="!copied" text @click="onCopyClick">
              <VIcon
                small
                color="white"
                v-text="copied ? 'mdi-bug-check-outline' : 'mdi-content-copy'"
              />
              <span v-if="copied" :class="$style.copied" class="ml-1" v-text="'Copied'" />
            </VBtn>

            <VBtn small icon @click="selectedMessage = null">
              <VIcon small color="white" v-text="'mdi-close'" />
            </VBtn>
          </div>
        </div>

        <div class="mt-1 px-3 py-2">
          <div class="flex align-center">
            <VIcon small color="red" v-text="'mdi-alert-rhombus-outline'" />
            <span
              class="ml-1"
              :class="[$style.title, $style.monospace]"
              v-text="selectedMessage.error.message"
            />
          </div>

          <div
            v-if="selectedMessage.error.extensions"
            class="mt-2 d-flex flex-column"
            :class="[$style.stacktrace, $style.monospace]"
          >
            <span
              v-for="(line, index) in selectedMessage.error.extensions.exception.stacktrace"
              :key="index"
              v-text="line"
            />
          </div>
        </div>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import moment from 'moment-timezone'

  import AppTypes from '@/types/app'

  /**
   * Message common Time-To-Live in seconds
   * @type {Number}
   */
  const MESSAGE_TTL = 5

  /**
   * Message Types
   * @type {String}
   */
  const { SUCCESS, ERROR } = AppTypes.MessageType

  export default {
    name: 'ToastMessages',
    data() {
      return {
        displayContainer: false,
        selectedMessage: null,
        copied: false,
      }
    },
    constants: {
      ERROR,
    },
    computed: {
      ...mapGetters({
        messages: 'selectMessages',
        isTeamMember: 'isTeamMember',
      }),

      errorTitle() {
        const { selectedMessage } = this

        if (!selectedMessage.error.extensions) {
          return 'Network error'
        }

        const message = selectedMessage.error.extensions.code
        const numeric = AppTypes.AppError[selectedMessage.error.extensions.code]

        if (message === numeric) {
          return message
        }

        return `${numeric} · ${message}`
      },

      errorText() {
        const { selectedMessage } = this

        return (
          selectedMessage.message +
          '\n\n' +
          selectedMessage.error.extensions.exception.stacktrace.join('\n')
        )
      },
    },
    watch: {
      messages(next, prev) {
        const { update } = this

        if ((!prev || !prev.length) && next && next.length) {
          // A new message arrived while the list was empty
          // Start the interval
          this.interval = setInterval(update, 1000)
        } else if (prev && prev.length && (!next || !next.length)) {
          // The last message just died
          // Stop the interval
          clearInterval(this.interval)
          this.interval = null
        }
      },
    },
    created() {
      // Set an interval property
      this.interval = null
    },
    methods: {
      ...mapActions(['deleteMessage']),

      isClickable(message) {
        return !!message.error && this.isTeamMember
      },

      onClick(message) {
        if (!this.isClickable(message)) {
          return
        }

        this.selectedMessage = message
      },

      onCopyClick() {
        navigator.clipboard.writeText(this.errorText)

        this.copied = true
      },

      update() {
        const { messages, deleteMessage } = this
        const ts = moment().unix()

        messages.forEach(n => {
          if (n.ts + MESSAGE_TTL <= ts) {
            deleteMessage(n.id)
          }
        })
      },

      getIcon(message) {
        if (message) {
          switch (message.type) {
            case ERROR:
              return 'mdi-alert-rhombus-outline'
            case SUCCESS:
            default:
              return 'mdi-alert-circle-check-outline'
          }
        }

        return null
      },

      beforeEnter() {
        this.displayContainer = true
      },

      afterLeave() {
        if (!this.messages.length) {
          this.displayContainer = false
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_text.styl'

  .root {
    position: fixed
    bottom: 0
    right: 100px
    z-index: 1000

    .alert {
      background: white !important

      &.clickable {
        cursor: pointer

        &:hover {
          background: var(--color-red-20) !important
        }
      }
    }
  }

  .modal {
    background-color: #222 !important

    .monospace {
      font-family: monospace !important
    }

    .code, .title {
      color: var(--color-red-40) !important
    }

    .stacktrace {
      color: white !important
      font-size: 12px
    }

    .copied {
      color: white !important
    }

    .code {
      background: var(--color-red-50) !important
      color: white !important
      font-weight: 800
    }

    .title {
      font-size: 16px
      font-weight: 600
    }
  }
</style>
