/* eslint-disable no-param-reassign */
import Vue from 'vue'
import hash from 'object-hash'
import moment from 'moment-timezone'
import _transform from 'lodash/transform'

import AppTypes from '@/types/app'
import { formatMessage } from '@/utils/format'

import { AUTH_USER_REVOKE } from './auth'

export const APP_REFERRAL_CODE_SET = 'APP_REFERRAL_CODE_SET'
export const APP_MESSAGE_ADD = 'APP_MESSAGE_ADD'
export const APP_MESSAGE_DELETE = 'APP_MESSAGE_DELETE'
export const APP_BANNERS_SET = 'APP_BANNERS_SET'
export const APP_BLACKLIST_SET = 'APP_BLACKLIST_SET'
export const APP_SERVER_TIME_SET = 'APP_SERVER_TIME_SET'
export const APP_SYNCED_SET = 'APP_SYNCED_SET'
export const APP_OUTDATED_VERSION_SET = 'APP_OUTDATED_VERSION_SET'
export const APP_OUTDATED_VERSION_RESET = 'APP_OUTDATED_VERSION_RESET'
export const APP_SET_REDIRECT_URL = 'APP_SET_REDIRECT_URL'
export const APP_SET_IS_FIRST_LOGIN = 'APP_SET_IS_FIRST_LOGIN'

/**
 * MessageType - Enum containing the message types
 * @type {String}
 */
const { DEFAULT } = AppTypes.MessageType

const app = {
  // ====================  STATE  ==================== //

  state: {
    time: {
      fetched: null,
      diff: 0,
    },
    synced: false,
    outdatedVersion: false,
    banners: {
      browserCompatibility: undefined,
      cookies: true,
    },
    messages: [],
    blacklist: {},
    referral: null,
    redirectUrl: null,
    isFirstLogin: false,
  },

  // ====================  GETTERS  ==================== //

  getters: {
    /**
     * Return the diff in milliseconds between the local and server times
     * @param {Object} state
     * @returns {Number}
     */
    selectAppTimeDiff: state => state.time.diff,
    /**
     * Return the stored last referral code
     * @param {Object} state
     * @returns {Number}
     */
    selectReferralCode: state => state.referral,
    /**
     * List of the messages which must be displayed at a time
     * @param {Object} state
     * @returns {Array}
     */
    selectMessages: state => state.messages,
    /**
     * Info about the existing app banners
     * @returns {Object}
     */
    selectBanners: state => state.banners,
    /**
     * Return 'true' if the given 'id' is actually blacklisted
     * @returns {Function}
     */
    isBlacklisted: state => id => state.blacklist[id] === true,
    /**
     * Return `true` if all "me" informations are fetched
     * @returns {Boolean}
     */
    isSynced: state => state.synced,
    /**
     * Return `true` if there's a schema version mismatch
     * @returns {Boolean}
     */
    isOutdated: state => state.outdatedVersion,
    /**
     * Return a redirection url if there's any
     * @returns {Boolean}
     */
    redirectUrl: state => state.redirectUrl,
    /**
     * True if logged in for the first time (used for welcome modal)
     * @returns {Boolean}
     */
    selectIsFirstLogin: state => state.isFirstLogin,
  },

  // ====================  MUTATIONS  ==================== //

  mutations: {
    /**
     * Add a new message (which specify itself its type and content)
     * @param {Object} state
     * @param {Object} payload
     * @returns {void}
     */
    [APP_MESSAGE_ADD](state, params) {
      if (typeof payload === 'string') {
        params = { message: params }
      }

      // If no message are defined then we use the default one according to
      // the message type
      if (typeof params.message !== 'string') {
        params.message = formatMessage(params.type) || undefined
      }

      const ts = moment().unix()
      const id = hash({
        ...params,
        random: Math.random(),
        ts,
      })

      state.messages = [
        ...state.messages,
        {
          type: DEFAULT,
          ...params,
          id,
          ts,
        },
      ].slice(state.messages.length - 3)
    },
    /**
     * Delete the message with the specified 'id'
     * @param {Object} state
     * @param {String} id
     * @returns {void}
     */
    [APP_MESSAGE_DELETE](state, id) {
      if (id) {
        state.messages = state.messages.filter(m => m.id !== id)
      }
    },
    /**
     * Update the dismissed banners state for given a banner
     * @param {Object} payload
     * @returns {void}
     */
    [APP_BANNERS_SET](state, payload) {
      const { banner, visible } = payload

      state.banners[banner] = visible
    },
    /**
     * Add the given 'id' to the blacklist
     * @param {Object} state
     * @param {String} id
     * @returns {void}
     */
    [APP_BLACKLIST_SET](state, payload) {
      const { id, value } = payload

      state.blacklist = {
        ...state.blacklist,
        [id]: value === null || value === undefined ? true : value,
      }
    },
    /**
     * Update the state with the given referral code
     * @param {Object} state
     * @param {String} code
     * @returns {void}
     */
    [APP_REFERRAL_CODE_SET](state, code) {
      Vue.set(state, 'referral', code)
    },
    /**
     * Called on logout request. Pass all the last "session" blacklisted items
     * to 'true' (i.e. trully blacklisted)
     * Also set the `synced` property to false to notify that the user
     * infos aren't fetched
     * @param {Object} state
     * @returns {void}
     */
    [AUTH_USER_REVOKE](state) {
      state.blacklist = {
        ...state.blacklist,
        ..._transform(
          state.blacklist,
          (res, v, k) => {
            res[k] = v ? true : v
          },
          {},
        ),
      }
      state.synced = false
    },
    /**
     * Set the server time
     * @param {Object} state
     * @param {Date} time
     * @returns {void}
     */
    [APP_SERVER_TIME_SET](state, time) {
      if (time) {
        state.time = {
          ...state.time,
          fetched: time,
          diff: moment(time) - moment(),
        }
      }
    },
    /**
     * Set the `synced` boolean
     * @param {Object} state
     * @returns {void}
     */
    [APP_SYNCED_SET](state) {
      state.synced = true
    },
    /**
     * Toggle the outdated version flag
     * @param {Object} state
     * @returns {void}
     */
    [APP_OUTDATED_VERSION_SET](state) {
      state.outdatedVersion = true
    },
    /**
     * Reset the outdated version flag
     * @param {Object} state
     * @returns {void}
     */
    [APP_OUTDATED_VERSION_RESET](state) {
      state.outdatedVersion = false
    },
    /**
     * Add a redirect url
     * @param {Object} state
     * @param {String} url
     * @returns {void}
     */
    [APP_SET_REDIRECT_URL](state, url) {
      state.redirectUrl = url
    },
    /**
     * Set first login
     * @param {Object} state
     * @returns {void}
     */
    [APP_SET_IS_FIRST_LOGIN](state, bool = false) {
      state.isFirstLogin = bool
    },
  },

  // ====================  ACTIONS  ==================== //

  actions: {
    /**
     * Add a new message containing at least a message (or with a specific
     * type)
     * @param {Object} context
     * @param {Object\String} payload
     * @returns {Promise}
     */
    addMessage(context, payload) {
      const { commit } = context

      commit(APP_MESSAGE_ADD, payload)
    },
    /**
     * Delete an existing notification from the list
     * @param {Object} context
     * @param {String} id
     */
    deleteMessage(context, id) {
      const { commit } = context

      commit(APP_MESSAGE_DELETE, id)
    },
    /**
     * Make the specified banner visible
     * @param {String} banner
     */
    showBanner(context, banner) {
      const { commit } = context

      commit(APP_BANNERS_SET, {
        banner,
        visible: true,
      })
    },
    /**
     * Dissmiss the specified banner
     * @param {String} banner
     */
    dismissBanner(context, banner) {
      const { commit } = context

      commit(APP_BANNERS_SET, {
        banner,
        visible: false,
      })
    },
    /**
     * Add the given 'id' to the blacklist and return if it was already
     * blacklisted or not before
     * @param {Object|String} payload
     */
    blacklist(context, payload) {
      const { getters, commit } = context
      const { isBlacklisted } = getters

      const params =
        typeof payload === 'string'
          ? {
              id: payload,
              value: true,
            }
          : {
              id: payload.id,
              value: payload.session ? 'session' : true,
            }

      if (!isBlacklisted(params.id)) {
        commit(APP_BLACKLIST_SET, params)
      }
    },
    /**
     * Set the given referral code in the store
     * @param {Object} context
     * @param {Object} code
     * @returns {Promise}
     */
    setReferralCode(context, code) {
      const { commit } = context

      commit(APP_REFERRAL_CODE_SET, code)
    },
    /**
     * Remove the eventually stored referral code
     * @param {Object} context
     * @returns {Promise}
     */
    deleteReferralCode(context) {
      const { commit } = context

      commit(APP_REFERRAL_CODE_SET, null)
    },
    /**
     * Keep the given server time in the store and especially the difference
     * with the user's local time
     * @param {Object} context
     * @param {Date} time
     * @returns {void}
     */
    setServerTime(context, time) {
      const { commit } = context

      commit(APP_SERVER_TIME_SET, time)
    },
    /**
     * Called when user informations has been fetched and synced with VueX
     * @param {Object} context
     * @returns {void}
     */
    hasBeenSynced(context) {
      const { commit } = context

      commit(APP_SYNCED_SET)
    },
    /**
     * Toggle the outdated version flag
     * @param {Object} context
     * @returns {void}
     */
    toggleOutdatedVersion(context) {
      const { commit } = context

      commit(APP_OUTDATED_VERSION_SET)
    },
    /**
     * Reset the outdated version flag
     * @param {Object} context
     * @returns {void}
     */
    resetOutdatedVersion(context) {
      const { commit } = context

      commit(APP_OUTDATED_VERSION_RESET)
    },
    /**
     * Add a redirect url
     * @param {Object} context
     * @param {String} url
     * @returns {void}
     */
    setRedirectUrl(context, url) {
      const { commit } = context

      commit(APP_SET_REDIRECT_URL, url)
    },
    /**
     * Toggle first login
     * @param {Object} context
     * @returns {void}
     */
    setIsFirstLogin(context, bool) {
      const { commit } = context

      commit(APP_SET_IS_FIRST_LOGIN, bool)
    },
  },
}

export default app
