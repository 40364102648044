<script>
  import _get from 'lodash/get'

  import { mapActions, mapGetters } from 'vuex'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import RouterMixin from '@/mixins/RouterMixin'

  import UserTypes from '@/types/user'
  import config from '@/config'

  import updateFreelancerReferralCode from './updateFreelancerReferralCode.gql'

  const { FREELANCER } = UserTypes.UserRole

  export default {
    mixins: [ApolloMixin, RouterMixin],
    computed: {
      ...mapGetters({
        user: 'selectUser',
        role: 'selectRole',
        [FREELANCER]: 'selectFreelancer',
      }),
    },
    methods: {
      ...mapActions(['deleteReferralCode']),
      /**
       * Transmit the referral code of the freelancer to Viral Loops
       * @returns {void}
       */
      async sendReferralCode() {
        const { user, freelancer, role, mutate, deleteReferralCode, me } = this
        const { email, firstName, lastName } = user
        const publicToken = _get(config, 'services.viralloops.campaignId')
        const referrerCode = _get(this, 'route.query.referralCode')

        if (role === FREELANCER) {
          // Check if a referral code is stored in Vuex (probably set before
          // a signup). If it is, and the user is a freelance,
          // send info to Viral Loops else
          // subscribe the user in the userbase anyway
          const body = {
            params: {
              event: 'registration',
              user: {
                firstname: firstName,
                lastname: lastName,
                email,
              },
              referrer: referrerCode ? { referralCode: referrerCode } : null,
            },
            publicToken,
          }

          try {
            const response = await this.$http.post(
              'https://app.viral-loops.com/api/v2/events',
              body,
            )
            const isNew = _get(response, 'body.isNew', false)
            const mustUpdateReferral = !_get(me, 'freelance.referralCode', false)

            if (isNew || mustUpdateReferral) {
              const newReferralCode = _get(response, 'body.referralCode')
              const options = {
                variables: {
                  id: freelancer.id,
                  info: {
                    referralCode: newReferralCode,
                    referrerCode,
                  },
                },
                track: 'common.identify.referral.send',
              }

              await mutate(updateFreelancerReferralCode, options)
              deleteReferralCode()
            }
          } catch (e) {
            // Do nothing
          }
        }
      },
    },
  }
</script>
