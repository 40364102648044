<template>
  <label class="icon-label">
    <i v-if="position === LEFT" :class="icon" />
    <slot />
    <i v-if="position === RIGHT" :class="icon" />
  </label>
</template>

<script>
  export default {
    name: 'IconLabel',
    props: {
      /**
       * Icon identifier inserted in this label
       */
      icon: {
        type: String,
        required: true,
      },
      /**
       * Position in which the icon is displayed ("left" or "right")
       */
      position: {
        type: String,
        default: 'left',
      },
    },
    constants: {
      LEFT: 'left',
      RIGHT: 'right',
    },
  }
</script>

<style lang="stylus" scoped>

  .icon-label {
    display: flex
    flex-direction: row
    align-items: center
    font-size: 14px
    line-height: 20px
    margin: 0

    i {
      &:first-child {
        margin-right: 12px
      }

      &:last-child {
        margin-left: 12px
      }
    }
  }
</style>
