import { __ } from '@comet/i18n'

import UserTypes from '@/types/user'
import { extract } from '@/utils/app'
import { bool, int, intOrNull } from '@/utils/transform'

const SignupValidationView = () =>
  import('@/views/enterprise/SignupValidationView/SignupValidationView')
const SigninView = () => import('@/views/enterprise/SigninView/SigninView')
const SignupView = () => import('@/views/enterprise/SignupView/SignupView')
const ChatView = () => import('@/views/common/ChatView/ChatView')
const EnterpriseLayout = () => import('@/layouts/EnterpriseLayout/EnterpriseLayout')
const DashboardView = () => import('@/views/enterprise/DashboardView/DashboardView')
const CompanyView = () => import('@/views/enterprise/CompanyView/CompanyView')
const ConversationListView = () =>
  import('@/views/enterprise/ConversationListView/ConversationListView')
const MissionView = () => import('@/views/enterprise/MissionView/MissionView')
const MatchView = () => import('@/views/enterprise/MatchView/MatchView')
const ProfileView = () => import('@/views/enterprise/ProfileView/ProfileView')
const MissionListView = () => import('@/views/enterprise/MissionListView/MissionListView')
const MissionPrepareView = () => import('@/views/enterprise/MissionPrepareView/MissionPrepareView')
const MissionConnectView = () => import('@/views/enterprise/MissionConnectView/MissionConnectView')
const MissionPublicView = () => import('@/views/enterprise/MissionPublicView/MissionPublicView')
const TeamView = () => import('@/views/enterprise/TeamView/TeamView')
const CommunityView = () => import('@/views/enterprise/CommunityView/CommunityView')
const ContractedFreelancerView = () =>
  import('@/views/enterprise/ContractedFreelancerView/ContractedFreelancerView')
const FreelancerProfileExportView = () =>
  import('@/views/enterprise/FreelancerProfileExportView/FreelancerProfileExportView')
const MissionFleetView = () => import('@/views/enterprise/MissionFleetView/MissionFleetView')

const { CORPORATE } = UserTypes.UserRole
const { PREVENT } = UserTypes.UserFetchPolicy

/**
 * Format the route's title into the "Enterprise" env including the given string
 * @param {String} title
 * @returns {String}
 */
function formatTitle(title) {
  return `${title} - ${__('app:enterprise:title')}`
}

/**
 * Restrictions rules for /enterprise routes
 */
const restrictions = {
  private: true,
  authorized: [CORPORATE],
  redirect: '/enterprise/signin',
}

/**
 * Routes specific to the "/enterprise" environment
 */
export default [
  {
    path: '/enterprise/signin',
    component: SigninView,
    props: extract(null, ['to']),
    meta: {
      authorized: [CORPORATE],
      title: () => formatTitle(__('app:route:enterprise:signin')),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/enterprise/signup',
    component: SignupView,
    props: extract(null, ['mtid', 'to', 'token']),
    meta: {
      authorized: [CORPORATE],
      title: () => formatTitle(__('app:route:enterprise:signup')),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/enterprise/email-validation',
    component: SignupValidationView,
    props: extract(null, ['email', 'token']),
    meta: {
      authorized: [CORPORATE],
      title: () => formatTitle(__('app:route:enterprise:email-validation')),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/enterprise/mission/:hash/export',
    component: MissionPublicView,
    props: extract(['hash']),
    meta: {
      title: () => formatTitle(__('app:route:enterprise:mission:public')),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/enterprise/join/:token',
    redirect: to => {
      // This is a temporary route, it should not be used in the new invitation
      // email, but we keep it for retro-compatibility. Should be remove later
      const { params } = to

      return { path: '/enterprise/signup', query: { token: params.token } }
    },
  },
  {
    path: '/enterprise/mission/create/:id?',
    component: MissionPrepareView,
    props: extract(['id'], null, { id: intOrNull }, { mode: 'create' }),
    meta: {
      title: () => formatTitle(__('app:route:enterprise:create')),
      ...restrictions,
    },
  },
  {
    path: '/enterprise/mission/duplicate',
    component: MissionPrepareView,
    props: extract(null, ['id'], { id: int }, { mode: 'duplicate' }),
    meta: {
      title: () => formatTitle(__('app:route:enterprise:create')),
      ...restrictions,
    },
  },
  {
    path: '/enterprise/mission/:id/edit',
    component: MissionPrepareView,
    props: extract(['id'], null, { id: int }, { mode: 'edit' }),
    meta: {
      title: () => formatTitle(__('app:route:enterprise:create')),
      ...restrictions,
    },
  },
  {
    path: '/enterprise/freelancer/:missionId/:freelancerId',
    component: FreelancerProfileExportView,
    props: extract(['missionId', 'freelancerId'], ['readOnly', 'params'], { readOnly: bool }),
    meta: {
      title: () => formatTitle(__('app:route:enterprise:freelancer')),
      notifications: true,
    },
  },
  {
    path: '/enterprise',
    redirect: '/enterprise/dashboard',
    component: EnterpriseLayout,
    meta: {
      private: true,
      authorized: [CORPORATE],
      redirect: '/enterprise/signin',
      title: () => __('app:route:enterprise:default'),
    },
    children: [
      // NavIndex : the order of the route in the navMenu (for animation)
      // !! Beware of copy/pasting
      {
        path: 'dashboard',
        component: DashboardView,
        meta: {
          navIndex: 1,
          title: () => formatTitle(__('app:route:enterprise:dashboard')),
        },
      },
      {
        path: 'missions',
        component: MissionListView,
        meta: {
          navIndex: 2,
          title: () => formatTitle(__('app:route:enterprise:missions')),
        },
      },
      {
        path: 'freelancers',
        alias: 'community',
        component: CommunityView,
        meta: {
          navIndex: 3,
          title: () => formatTitle(__('app:route:enterprise:freelancers')),
        },
      },
      {
        path: 'administration',
        component: CompanyView,
        meta: {
          title: () => formatTitle(__('app:route:enterprise:administration')),
        },
      },
      {
        path: 'crew',
        component: TeamView,
        meta: {
          title: () => formatTitle(__('app:route:enterprise:crew')),
        },
      },
      {
        path: 'profile',
        component: ProfileView,
        meta: {
          title: () => formatTitle(__('app:route:enterprise:profile')),
        },
      },
      {
        path: 'missions/:id(\\d+)/:section?',
        meta: {
          title: () => formatTitle(__('app:route:enterprise:mission')),
        },
        props: extract(['id', 'section'], ['backLink'], { id: int }),
        component: MissionView,
      },
      {
        path: 'mission/:id(\\d+)/freelancer/:freelancerId(\\d+)/:section?',
        meta: {
          title: () => formatTitle(__('app:route:enterprise:mission:match')),
        },
        props: extract(['id', 'freelancerId', 'section'], null, { id: int, freelancerId: int }),
        component: MatchView,
      },
      {
        path: 'freelancers/:publicId',
        meta: {
          title: () => formatTitle(__('app:route:enterprise:freelancers')),
        },
        props: extract(['publicId']),
        component: ContractedFreelancerView,
      },
      {
        path: 'messages',
        component: ConversationListView,
        meta: {
          navIndex: 4,
          title: () => formatTitle(__('app:route:enterprise:messages')),
        },
      },
      {
        path: 'mission/fleet/create',
        component: MissionFleetView,
        meta: {
          title: () => formatTitle(__('app:route:enterprise:create')),
          ...restrictions,
        },
      },
      {
        path: 'mission/connect/create',
        component: MissionConnectView,
        meta: {
          title: () => formatTitle(__('app:route:enterprise:create')),
          ...restrictions,
        },
      },
      {
        path: 'messages/:chatConversationId',
        component: ChatView,
        meta: {
          title: () => __('app:route:public:chat'),
        },
        props: extract(['env', 'chatConversationId']),
      },
    ],
  },
]
