<template>
  <VLazy
    v-model="active"
    tag="div"
    :transition="null"
    :min-height="minHeight"
    :options="{ threshold: 0 }"
    :class="$style.root"
  >
    <div :style="{ minHeight }">
      <slot />
    </div>
  </VLazy>
</template>

<script>
  export default {
    name: 'CKanbanItem',
    props: {
      /**
       * Minimum height of a Kanban item.
       * This property must be set to a realistic value to make VLazy
       * (lazy mounting) load properly.
       * @type {Number}
       */
      minHeight: {
        type: Number,
        default: 120,
      },
    },
    data() {
      return {
        /**
         * Reactive property plugged to VLazy and determining if the component must be
         * displayed or not, accoding to its visibility.
         * @type {Boolean}
         */
        active: false,
      }
    },
  }
</script>

<style lang="stylus" module>
  .root {
    padding: 4px
    width: 100%
  }
</style>
