<template>
  <div :class="classes">
    <ImageFallback
      :image="url"
      :radius="radius"
      :border="border"
      :fallback="fallback"
      :class="$style.image"
    />

    <Icon
      v-if="!!badge"
      v-tooltip="__('cp:profile-picture:tooltip:badge')"
      name="medal-comet"
      :class="$style.badge"
    />
  </div>
</template>

<script>
  import _camelCase from 'lodash/camelCase'

  import ImageFallback from '@/core/graphics/ImageFallback/ImageFallback'
  import Icon from '@/core/graphics/Icon/Icon'

  import UserTypes from '@/types/user'

  import I18nMixin from '@/mixins/I18nMixin'

  const { UserRole } = UserTypes
  const { Border, Radius } = ImageFallback

  const Size = {
    XXXXS: 'xxxxs',
    XXXS: 'xxxs',
    XXS: 'xxs',
    XS: 'xs',
    S: 's',
    M: 'm',
  }

  const Role = {
    FREELANCER: 'freelancer',
    COMPANY: 'company',
    CORPORATE: 'coporate',
    TEAM_MEMBER: 'teamMember',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  ImageFallback: ImageFallback.__enums,
  Icon: Icon.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Border,Size,Radius,Role,
    name: 'ProfilePicture',
    __enums: {
      Border,
      Size,
      Radius,
      Role,
    },
    components: {
      ImageFallback,
      Icon,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Url of the image to display
       */
      url: {
        type: String,
        default: null,
      },
      /**
       * Picture size
       * @type {Size}
       */
      size: {
        type: String,
        default: Size.M,
      },
      /**
       * True if should display certification badge
       */
      badge: {
        type: Boolean,
        default: false,
      },
      /**
       * Border value given to ImageFallback that will change the color of the
       * border (no border if not specified)
       */
      border: {
        type: String,
        default: Border.NONE,
      },
      /**
       * Border radius applied to the ImageFallback
       */
      radius: {
        type: String,
        default: Radius.CIRCLE,
      },
      /**
       * Role of the user to determine fallback URL
       */
      role: {
        type: String,
        default: Role.FREELANCER,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { badge, border, role, size, $style } = this

        return [
          $style.root,
          $style[_camelCase(`border-${border}`)],
          $style[_camelCase(`role-${role}`)],
          $style[_camelCase(`size-${size}`)],
          { [$style.withBadge]: !!badge },
        ]
      },
      /**
       * Fallback to be used depending on given role
       * @type {String}
       */
      fallback() {
        const { role } = this

        switch (role) {
          case Role.FREELANCER:
          case UserRole.FREELANCER:
          case 'freelance': // The back-end role for 'freelancer' is 'freelance'
            return 'freelancer/profile-picture-placeholder.png'
          case Role.COMPANY:
          case Role.CORPORATE:
          case UserRole.CORPORATE:
          case UserRole.CHAT_USER:
            return 'companies/profile-placeholder.svg'
          case Role.TEAM_MEMBER:
          case UserRole.TEAM_MEMBER:
          default:
            return 'freelancer/profile-picture-placeholder.png'
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_variables.styl'

  .root.withBadge {
    position: relative
  }

  .image {
    width: inherit
    height: inherit
    min-width: inherit
    min-height: inherit
    max-width: inherit
    max-height: inherit
    box-sizing: border-box
  }

  .badge {
    position: absolute
    color: var(--color-brand)
    font-size: 20px
    left: 65%
    bottom: -7%
  }

  .root:not(.withBadge) .badge {
    display: none
  }

    // Size
  .sizeXxxxs {
    width: 30px
    height: 30px
    min-width: 30px
    min-height: 30px
    max-width: 30px
    max-height: 30px
  }

  .sizeXxxs {
    width: 40px
    height: 40px
    min-width: 40px
    min-height: 40px
    max-width: 40px
    max-height: 40px
  }

  .sizeXxs {
    width: 48px
    height: 48px
    min-width: 48px
    min-height: 48px
    max-width: 48px
    max-height: 48px

    .badge {
      font-size: 18px
      bottom: -25%
      left: 60%
    }
  }

  .sizeXs {
    width: 80px
    height: 80px
    min-width: 80px
    min-height: 80px
    max-width: 80px
    max-height: 80px

    .badge {
      width: 25px
      height: 25px
    }
  }

  .sizeS {
    width: 100px
    height: 100px
    min-width: 100px
    min-height: 100px
    max-width: 100px
    max-height: 100px

    .badge {
      width: 30px
      height: 30px
    }
  }

  .sizeM {
    width: 120px
    height: 120px
    min-width: 120px
    min-height: 120px
    max-width: 120px
    max-height: 120px
  }
</style>
