<template>
  <div :class="classes">
    <TabsMenu
      :options="formattedOptionsWithActive"
      :layout="layout"
      :justify="justify"
      :on-tab-press="onTabPress"
      :class="$style.menu"
    />

    <div
      v-if="!_isEmpty(this.$slots)"
      :class="{
        [$style.content]: true,
        [$style.denseContent]: dense,
      }"
    >
      <WithSlide :index="withSlideIndex">
        <div v-if="!formattedOptions[active].preMount" :key="formattedOptions[active].id">
          <slot :name="formattedOptions[active].id" />
        </div>

        <!--PreMounted options (all rendered at init)-->
        <div v-for="option in preMountedOptions" v-show="option.index === active" :key="option.id">
          <slot :name="option.id" />
        </div>
      </WithSlide>
    </div>
  </div>
</template>

<script>
  import _camelCase from 'lodash/camelCase'
  import _isEmpty from 'lodash/isEmpty'
  import RouterMixin from '@/mixins/RouterMixin'

  import TabsMenu from '@/core/layout/TabsMenu/TabsMenu'
  import WithSlide from '@/core/layout/WithSlide/WithSlide'

  import { int } from '@/utils/transform'

  const { Align, BadgeTheme } = TabsMenu

  const Layout = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  TabsMenu: TabsMenu.__enums,
  WithSlide: WithSlide.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Align,BadgeTheme,Layout,
    name: 'Tabs',
    __enums: {
      Align,
      BadgeTheme,
      Layout,
    },
    components: {
      TabsMenu,
      WithSlide,
    },
    mixins: [RouterMixin],
    props: {
      /**
       * If 'true', Tabs is connected to the URL query params and handle its
       * currently selected value.
       * Else, Tabs relies only on info contained in 'tabs'.
       */
      connected: {
        type: Boolean,
        default: true,
      },
      /**
       * As 'connected' Tabs relies on the URL query params to know its value.
       * Name allows to distinguish potential differents Tabs in the same view.
       */
      name: {
        type: String,
        default: 'tab',
      },
      /**
       * Default value, if Tabs is not 'connected'
       */
      value: {
        type: [Number, String],
        default: null,
      },
      /**
       * List of labels to display as tabs
       * @type {Array.<Object|String>}
       */
      options: {
        type: Array,
        required: true,
      },
      /**
       * Allows to display the tabs with a specific layout
       */
      layout: {
        type: String,
        default: Layout.HORIZONTAL,
      },
      /**
       * TabsMenu's horizontal alignment (left, right, center)
       * */
      justify: {
        type: String,
        default: Align.LEFT,
      },
      /**
       * Property to densify the tabs view, for BackOffice mostly
       */
      dense: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Classes
       * @returns {Object}
       */
      classes() {
        const { layout, $style } = this

        return [$style.tabs, $style[_camelCase(`layout-${layout}`)]]
      },
      /**
       * Format the option obj
       * - add id
       * - array of obj (not an array of String)
       * @returns {Array.<Object>}
       */
      formattedOptions() {
        const { options } = this

        return options.map((o, index) => ({
          id: index, // will be override by option.id if present
          ...(typeof o === 'string' ? { label: o } : o),
        }))
      },
      /**
       * List of the preMounted options (witch are be rendered once
       * at init instead of when needed) with they index relative to
       * the formattedOptionsWithActive
       * !! -> should only be used when really needed (causes perf issue)
       * ex:  hack for provide/inject issues
       */
      preMountedOptions() {
        const { formattedOptionsWithActive } = this

        return formattedOptionsWithActive.reduce((acc, option, index) => {
          if (option.preMount) {
            acc.push({
              ...option,
              index,
            })
          }
          return acc
        }, [])
      },
      /**
       * Add the classes to the active option
       * !! => We can't do it in formattedOptions because it causes
       * a circular dependency between active and formattedOptions
       * @returns {Array.<Object>}
       */
      formattedOptionsWithActive() {
        const { active, formattedOptions } = this

        return formattedOptions.map((o, index) => ({
          ...o,
          classes: {
            ...o.classes,
            active: active === index,
            disabled: !!o.disabled,
          },
        }))
      },
      /**
       * The index of the currently active tab
       * If not connected, return the given value
       * @type {Number}
       */
      active() {
        const { name, value, connected, route, formattedOptions } = this
        let index = null

        // When not 'connected', the active tab is the one specified
        // as 'value'
        if (!connected) {
          index = formattedOptions.findIndex(o => o.id === value)
          return index > -1 ? index : 0
        }

        // If it is 'connected' but no URL query param is set, select the first
        // tab as default
        const param = route.query[name]
        if (!param) {
          return 0
        }

        // Else it relies on the URL query params defined by 'name'
        // It it is an integer, use it as index
        const asInt = int(param)
        if (Number.isInteger(asInt)) {
          return asInt
        }

        // Else, find the option which has this 'name'
        index = formattedOptions.findIndex(o => o.id === param)
        return index > -1 ? index : 0
      },
      /**
       * The index of the current Tab to directly serve to WithSlide.
       * In some cases, we don't want to perform a slide animation, but the default
       * fade (in vertical mode for example).
       * @type {Number}
       */
      withSlideIndex() {
        const { layout, active, $mq } = this

        return layout === Layout.HORIZONTAL || $mq.phoneTablet ? active : null
      },
    },
    methods: {
      _isEmpty,
      onTabPress(index, event) {
        const { connected, formattedOptions } = this
        const optionId = formattedOptions[index].id

        event.preventDefault()

        if (formattedOptions[index] && formattedOptions[index].disabled) {
          return
        }

        if (connected) {
          const { name, route, navigate } = this

          navigate({
            path: route.path,
            query: {
              ...route.query,
              [name]: optionId,
            },
          })
        }

        this.$emit('input', optionId)
        this.$emit('change', optionId)
        this.$emit('select', optionId)
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_layout.styl'

  // Properties used to display tabs horizontally
  horizontalLayout() {
    .content {
      width: 100%
      margin-top: 40px

      @media phone {
        margin-top: 24px
      }
    }

    .denseContent {
      margin-top: 8px
    }
  }

  // Properties used to display tabs vertically
  verticalLayout() {
    .menu {
      position: sticky
      top: 0
      left: 0
      float: left
      width: 18%
      min-width: 180px
    }

    .content {
      width: calc(82% - 40px)
      max-width: calc(100% - 220px)
      margin-top: 0
      margin-left: auto
    }
  }

  .tabs {
    justify-items: center
    width: 100%

    .content {
      box-sizing: border-box
    }

    &.layoutHorizontal {
      horizontalLayout()
    }

    &.layoutVertical {
      horizontalLayout()

      @media laptop-up {
        verticalLayout()
      }
    }
  }
</style>
