import hash from 'object-hash'
import moment from 'moment-timezone'
import _keys from 'lodash/keys'
import _values from 'lodash/values'
import _zipObject from 'lodash/zipObject'
import _isPlainObject from 'lodash/isPlainObject'

import AppTypes from '@/types/app'

/**
 * Returns a function which help to extract route parameters ('params')
 * and query parameters ('query') from the given 'route', and eventually
 * apply transformations on their values by specifying functions according
 * to the right key in the 'transform' object.
 *
 * This "double function" form allows to easily use it from the vue-router's
 * route definitions, this way :
 *     props: extract(['id'], ['to'], { id: int })
 *
 *  'params' can have 2 different forms :
 *    - a Boolean, leading to an extract of all the identified route parameters.
 *    - an Array, in mhich are specified only the keys we want to extract.
 *
 *  'query' can have 3 different forms:
 *    - a Boolean, leading to an extract of all the existing query parameters.
 *    - an Array, in which are specified only the keys we want to extract.
 *    - an Object, allowing to apply a mapping on the parameters' names (the
 *      key matches the query param, and the value is the transmitted prop)
 *
 * 'transform' allows to associate (and apply) transformation functions to
 * extracted keys (from the route as well as from the query parameters).
 * For example, if the expected value of a param is an integer, and you well
 * know that a route is actually a string, you probably would like to transform
 * this value to an integer, right now, during the extraction, with the "int"
 * function.
 *
 *  'base' (DEPRECATED) should not be used actually. It allows to insert "raw"
 * (and static) props, passed straight to the component, without any notion of
 * extraction.
 * So, this 'base' param is actually "polluting" this function while having no
 * direct relation with it, and bringing no real gain.
 * A smarter way to do it is to straight mention "raw" props in the route
 * definition, like this:
 *    props: (route) => ({
 *      myStaticProp: 'toto',
 *      ...extract([env], [to])(route),
 *    })
 * @param {Boolean|Array.<String>} params
 * @param {Boolean|Array.<String>|Object.<String>} query
 * @param {Object.<Function>} transform
 * @param {Object.<*>} base (DEPRECATED)
 * @returns {Function}
 */
function extract(params = [], query = [], transform = {}, base = {}) {
  return route => {
    const paramsKeys = params === true ? _keys(route.params) : params || []

    let queryKeys = query === true ? _keys(route.query) : query || []

    if (_isPlainObject(query)) {
      queryKeys = Object.keys(query)
    }

    return {
      ...base,
      ..._zipObject(
        paramsKeys,
        paramsKeys.map(p =>
          transform && transform[p] ? transform[p](route.params[p]) : route.params[p],
        ),
      ),
      ..._zipObject(
        _isPlainObject(query) ? queryKeys.map(key => query[key]) : queryKeys,
        queryKeys.map(q =>
          transform && transform[q] ? transform[q](route.query[q]) : route.query[q],
        ),
      ),
    }
  }
}

/**
 * Extract env value from the route object
 * @param {Object} route
 * @returns {String}
 */
function extractEnvFromRoute(route) {
  const matched = route.path.match('^/([a-z]*)(/(.*))?$')

  const env = matched && matched.length >= 2 ? matched[1] : null

  return _values(AppTypes.AppEnv).includes(env) ? env : null
}

/**
 * Generate a random unique identifier
 * @param {*} [context] More context to render the UUID "more" unique
 * @returns {String}
 */
function uuid(context) {
  return hash({
    ts: moment().unix(),
    rd: (Math.random() * Math.random()).toString(36).substr(2, 16),
    cx: context ? JSON.stringify(context) : undefined,
  })
}

export { extract, extractEnvFromRoute, uuid }
