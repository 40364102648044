<template>
  <VInput
    v-bind="$attrs"
    :disabled="disabled"
    class="mb-1"
  >
    <div class="d-flex flex-row flex-wrap justify-space-around align-center">
      <VCheckbox
        v-for="item in notificationChannels"
        :key="item.value"
        :input-value="value"
        :label="item.text"
        :value="item.value"
        :disabled="item.disabled || disabled"
        hide-details
        dense
        class="mx-2 my-1"
        @change="v => $emit('input', v)"
      />
    </div>
  </VInput>
</template>

<script>
  import _values from 'lodash/values'

  import { formatFreelancerChannel } from '@comet/i18n'
  import { FreelancerChannel } from '@comet/types'

  export default {
    name: 'FreelancerChannelsField',
    props: {
      /**
       * Array containing the currently selected notification channels
       * @type {Array.<FreelancerNotificationChannel>}
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Disable all the inner checkboxes when enabled
       * @type {Boolean}
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Object containing notification channels as keys and booleans as values
       * to manually disable some checkboxes according to data computed by the
       * parent component
       * @type {Object.<Boolean>}
       */
      rules: {
        type: Object,
        default: null,
      },
    },
    computed: {
      /**
       * List of available notification channels with a computed 'disabled' property
       * regarding the passed 'rules'.
       * @type {Array.<Object>}
       */
      notificationChannels() {
        const { rules } = this

        return _values(FreelancerChannel).map(c => ({
          value: c,
          text: formatFreelancerChannel(c),
          disabled: rules && rules[c] === false,
        }))
      },
    },
  }
</script>
