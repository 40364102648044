import _cloneDeepWith from 'lodash/cloneDeepWith'
import _cloneWith from 'lodash/cloneWith'
import _isPlainObject from 'lodash/isPlainObject'
import _isString from 'lodash/isString'
import _omit from 'lodash/omit'
import _values from 'lodash/values'
import _zipObject from 'lodash/zipObject'

import { formatValidationError } from '@/utils/format'
import FormTypes from '@/types/form'

const { ValidationError } = FormTypes
const { REMOTE } = ValidationError

/**
 * Deeply extract errors by travelling through the $v structure
 * @param {Object} value
 * @returns {*}
 */
function extractDeepErrors(value) {
  if (value) {
    // Collection validation (array / object)
    // See: https://monterail.github.io/vuelidate/#sub-collections-validation
    if (value.$each) {
      return _cloneWith(value.$each, extractDeepErrors.bind(this))
    }

    const validators = Object.values(ValidationError)
    const keys = Object.keys(value).filter(k => !k.startsWith('$'))
    const isBranch = !!_values(value).find(v => v && _isPlainObject(v) && !!v.$params)

    // If it is a "branch", containing other nested fields to inspect deeper
    if (isBranch) {
      return _zipObject(
        keys,
        keys.map(k => _cloneWith(value[k], extractDeepErrors.bind(this))),
      )
    }

    // Find a validator flagged as invalid (i.e. === false) and dirty
    const validator = validators.find(e => value[e] === false && (value.$anyDirty || e === REMOTE))

    if (validator) {
      const criteria = value.$params[validator]

      if (criteria) {
        return formatValidationError(validator, _omit(criteria, ['type', '$sub']))
      }
    }

    return null
  }

  return undefined
}

/**
 * Extract errors raised by Vuelidate ($v) or received through the last
 * performed mutation (errors)
 * @param {Object} $v
 * @returns {Object.<String>}
 */
function extractErrors($v) {
  return $v && $v.form ? _cloneWith($v.form.fields, extractDeepErrors.bind(this)) : {}
}

/**
 * Determines the CSS classes which would be applied to each field
 * @param {Object}
 * @returns {Object}
 */
function extractClasses(errors) {
  return errors
    ? _cloneDeepWith(errors, value => (_isString(value) ? { invalid: true } : undefined))
    : {}
}

export { extractErrors, extractClasses }
