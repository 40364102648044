/**
 * Type of documents potentially linked to a deal
 */
const DealDocumentType = {
  MISSION_STATEMENT: 'missionStatement',
  FRAMEWORK_CONTRACT: 'frameworkContract',
  QUOTE: 'quote',
  PURCHASE_ORDER: 'purchaseOrder',
  INVOICING_SLIP: 'invoicingSlip',
  PROGRESS_REPORT: 'progressReport',
  RIDER: 'rider',
  MISSION_ORDER: 'missionOrder',
  COMMITMENT_LETTER: 'commitmentLetter',
  MAIL: 'mail',
  OTHER: 'other',
}

const DealTypes = {
  DealDocumentType,
}

export default DealTypes
