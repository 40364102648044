import _includes from 'lodash/includes'
import moment from 'moment-timezone'

import { __, format } from '@comet/i18n'

import MissionTypes from '@/types/mission'
import UserTypes from '@/types/user'

const {
  CorporateMissionMode,
  FreelancerPeriodicRating,
  LegalModel,
  MissionStatus,
  MissionOngoingStatus,
  MissingRating,
  MissionOrder,
  MissionRefusalReason,
  MissionFailureReason,
  MissionFailureReasonByCorporate,
  MissionFailureReasonByTeamMember,
  MissionRenewable,
  MissionRhythm,
  ProjectType,
  WorkingTime,
  MissionOffer,
  MissionOrigin,
  MissionSource,
  MissionGlobalStatus,
} = MissionTypes
const { TO_APPROVE, SEEKING } = MissionStatus

const { TEAM_MEMBER, FREELANCER, CORPORATE } = UserTypes.UserRole
const { BOTH, FREELANCER: REVIEW_FREELANCER, CORPORATE: REVIEW_CORPORATE } = MissingRating

/**
 * Return the expected color class according to the given mission
 * @param {String} status
 * @returns {String}
 */
function getMissionColor(status) {
  const {
    APPROVAL_DECLINED,
    TO_APPROVE,
    TO_VALIDATE,
    FAILED,
    FINISHED,
    ON_GOING,
    NOT_FINALIZED,
    REVIEWED,
    REFUSED,
  } = MissionStatus
  const dealedStatus = [ON_GOING, FINISHED, REVIEWED]

  switch (true) {
    case status === APPROVAL_DECLINED:
    case status === REFUSED:
    case status === FAILED:
      return 'red'

    case status === NOT_FINALIZED:
    case status === TO_VALIDATE:
    case status === TO_APPROVE:
      return 'grey'

    case _includes(dealedStatus, status):
      return 'green'

    default:
      return 'blue'
  }
}

function getMissionTheme(status) {
  const { APPROVAL_DECLINED, REFUSED, FAILED, FINISHED, NOT_FINALIZED, REVIEWED } = MissionStatus
  const dealedStatus = [FINISHED, REVIEWED]

  switch (true) {
    case status === APPROVAL_DECLINED:
    case status === REFUSED:
    case status === FAILED:
      return 'danger'

    case status === NOT_FINALIZED:
      return 'neutral'

    case _includes(dealedStatus, status):
      return 'success'

    default:
      return 'information'
  }
}

/**
 * Format the status mission according to its status and deal state
 * @param   {String} status
 * @param   {Object} deal - needs the following properties :
 *  - canProposeTerms
 *  - canAgreeToTermsAsCorpo
 *  - canAgreeToTermsAsFree
 *  - teamMemberValidated
 *  - signing
 *  - signed
 * @param   {String} missingRating
 * @param   {String} role
 * @returns {String}
 */
function formatMissionStatus(status, deal = {}, missingRating, role) {
  switch (status) {
    case MissionStatus.NOT_FINALIZED:
      return __('ty:mission:status:to-complete')
    case MissionStatus.TO_APPROVE:
      return __('ty:mission:status:to-approve')
    case MissionStatus.APPROVAL_DECLINED:
      return __('ty:mission:status:approval-declined')
    case MissionStatus.TO_VALIDATE:
      return __('ty:mission:status:to-validate')
    case MissionStatus.REFUSED:
      return __('ty:mission:status:refused')
    case MissionStatus.SEEKING:
      return __('ty:mission:status:seeking')
    case MissionStatus.FAILED:
      return __('ty:mission:status:failed')
    case MissionStatus.QUOTATION:
      switch (true) {
        case deal.canProposeTerms:
        case deal.canAgreeToTermsAsCorpo:
          return __('ty:deal:status:created')
        case deal.canAgreeToTermsAsFree:
          return __('ty:deal:status:can-validate-free')
        case !deal.teamMemberValidated:
          return __('ty:deal:status:tm-validation')
        case deal.signing:
          return __('ty:deal:status:signing')
        case deal.signed:
          return __('ty:deal:status:signed')
        default:
          return __('ty:mission:status:quotation')
      }
    case MissionStatus.ON_GOING:
      return __('ty:mission:status:on-going')
    case MissionStatus.FINISHED:
      switch (true) {
        case role === TEAM_MEMBER && missingRating === BOTH:
          return __('ty:mission:status:missing-rating:both')
        case role !== CORPORATE && [BOTH, REVIEW_FREELANCER].includes(missingRating):
          return __('ty:mission:status:missing-rating:freelancer')
        case role !== FREELANCER && [BOTH, REVIEW_CORPORATE].includes(missingRating):
          return __('ty:mission:status:missing-rating:corporate')
        default:
          return __('ty:mission:status:finished')
      }
    case MissionStatus.REVIEWED:
      return __('ty:mission:status:reviewed')
    default:
      return status
  }
}

/**
 * Format the mission status according to its status
 * @param {String} status
 * @returns {String}
 */
function formatMissionShortStatus(status) {
  switch (status) {
    case MissionStatus.NOT_FINALIZED:
      return __('ty:mission:short-status:to-complete')
    case MissionStatus.TO_APPROVE:
      return __('ty:mission:short-status:to-approve')
    case MissionStatus.APPROVAL_DECLINED:
      return __('ty:mission:short-status:approval-declined')
    case MissionStatus.TO_VALIDATE:
      return __('ty:mission:short-status:to-validate')
    case MissionStatus.REFUSED:
      return __('ty:mission:short-status:refused')
    case MissionStatus.SEEKING:
      return __('ty:mission:short-status:seeking')
    case MissionStatus.FAILED:
      return __('ty:mission:short-status:failed')
    case MissionStatus.QUOTATION:
      return __('ty:mission:short-status:quotation')
    case MissionStatus.ON_GOING:
      return __('ty:mission:short-status:on-going')
    case MissionStatus.FINISHED:
      return __('ty:mission:short-status:finished')
    case MissionStatus.REVIEWED:
      return __('ty:mission:short-status:reviewed')
    default:
      return status
  }
}

/**
 * Format the mission ongoing status
 * @param {String} status
 * @returns {String}
 */
function formatMissionOngoingStatus(status) {
  switch (status) {
    case MissionOngoingStatus.ON_GOING:
      return __('ty:mission:ongoing-status:ongoing')
    case MissionOngoingStatus.RENEWING:
      return __('ty:mission:ongoing-status:renewing')
    case MissionOngoingStatus.ENDING:
      return __('ty:mission:ongoing-status:ending')
    default:
      return status
  }
}

function formatMissionEmoji(status) {
  switch (status) {
    case MissionStatus.NOT_FINALIZED:
      return __('ty:mission:status:to-complete-emoji')
    case MissionStatus.TO_APPROVE:
      return __('ty:mission:status:to-approve-emoji')
    case MissionStatus.APPROVAL_DECLINED:
      return __('ty:mission:status:approval-declined-emoji')
    case MissionStatus.TO_VALIDATE:
      return __('ty:mission:status:to-validate-emoji')
    case MissionStatus.REFUSED:
      return __('ty:mission:status:refused-emoji')
    case MissionStatus.SEEKING:
      return __('ty:mission:status:seeking-emoji')
    case MissionStatus.FAILED:
      return __('ty:mission:status:failed-emoji')
    case MissionStatus.QUOTATION:
    case MissionStatus.ON_GOING:
      return __('ty:mission:status:quotation-emoji')
    case MissionStatus.FINISHED:
      return __('ty:mission:status:finished-emoji')
    case MissionStatus.REVIEWED:
      return __('ty:mission:status:reviewed-emoji')
    default:
      return null
  }
}

function formatMissionOrder(key) {
  const trans = {
    [MissionOrder.START_DATE]: __('ty:mission:order:start-date'),
    [MissionOrder.CREATED_AT]: __('ty:mission:order:created-at'),
    [MissionOrder.BUSINESS_VOLUME]: __('ty:mission:order:business-volume'),
    [MissionOrder.HUNTING_PRIORITY_SCORE]: __('ty:mission:order:hunting-priority-score'),
  }

  return trans[key] || undefined
}

function formatCorporateMissionMode(key) {
  const trans = {
    [CorporateMissionMode.ALL]: __('ty:mission:mode:all'),
    [CorporateMissionMode.DEPOSIT]: __('ty:mission:mode:deposit'),
    [CorporateMissionMode.CONTRACTED]: __('ty:mission:mode:contracted'),
    [CorporateMissionMode.ARCHIVED]: __('ty:mission:mode:archived'),
  }

  return trans[key] || undefined
}

function formatMissionRefusalLabel(key) {
  const trans = {
    [MissionRefusalReason.OUT_OF_SCOPE]: __('ty:mission:mission-refusal-label:out-of-scope'),
    [MissionRefusalReason.SSII]: __('ty:mission:mission-refusal-label:ssii'),
    [MissionRefusalReason.UNREACHABLE]: __('ty:mission:mission-refusal-label:unreachable'),
    [MissionRefusalReason.LOW_VALUE]: __('ty:mission:mission-refusal-label:low-value'),
    [MissionRefusalReason.FAKE]: __('ty:mission:mission-refusal-label:fake'),
    [MissionRefusalReason.FOREIGN_COMPANY]: __('ty:mission:mission-refusal-label:foreign-company'),
    [MissionRefusalReason.OTHER]: __('ty:mission:mission-refusal-label:other'),
  }

  return trans[key] || undefined
}

function formatMissionRefusalComment(key) {
  const trans = {
    [MissionRefusalReason.OUT_OF_SCOPE]: __('ty:mission:mission-refusal-comment:out-of-scope'),
    [MissionRefusalReason.SSII]: __('ty:mission:mission-refusal-comment:ssii'),
    [MissionRefusalReason.UNREACHABLE]: __('ty:mission:mission-refusal-comment:unreachable'),
    [MissionRefusalReason.LOW_VALUE]: __('ty:mission:mission-refusal-comment:low-value'),
    [MissionRefusalReason.FAKE]: __('ty:mission:mission-refusal-comment:fake'),
  }

  return trans[key] || undefined
}

function formatMissionExpertise(key) {
  const trans = {
    1: __('ty:mission:expertise:back-end'),
    2: __('ty:mission:expertise:front-end'),
    3: __('ty:mission:expertise:full-stack'),
    4: __('ty:mission:expertise:devops'),
    5: __('ty:mission:expertise:mobile-app'),
    6: __('ty:mission:expertise:bi'),
    7: __('ty:mission:expertise:big-data'),
    8: __('ty:mission:expertise:data-science'),
    9: __('ty:mission:expertise:data-engineering'),
    10: __('ty:mission:expertise:web-analytics'),
    11: __('ty:mission:expertise:data-analysis'),
  }

  return trans[key] || undefined
}

/**
 * Return the formatted reason why a TeamMember fails a mission
 */
function formatTeamMemberMissionFailureReason(key) {
  const trans = {
    [MissionFailureReason.NO_NEWS]: __('ty:mission:team-member-mission-fail-reason:no-news'),
    [MissionFailureReason.AN_OTHER_SOLUTION]: __(
      'ty:mission:team-member-mission-fail-reason:an-other-solution',
    ),
    [MissionFailureReason.STAND_BY]: __('ty:mission:team-member-mission-fail-reason:stand-by'),
    [MissionFailureReason.BUDGET_ISSUE]: __(
      'ty:mission:team-member-mission-fail-reason:budget-issue',
    ),
    [MissionFailureReason.CANT_FIND_FREE]: __(
      'ty:mission:team-member-mission-fail-reason:cant-find-free',
    ),
    [MissionFailureReason.OTHER_SOLUTION_COMPETITOR]: __(
      'ty:mission:team-member-mission-fail-reason:competitor',
    ),
    [MissionFailureReason.OTHER_SOLUTION_FIXED_CONTRACT]: __(
      'ty:mission:team-member-mission-fail-reason:contract',
    ),
    [MissionFailureReason.OTHER_SOLUTION_AGENCY]: __(
      'ty:mission:team-member-mission-fail-reason:agency',
    ),
    [MissionFailureReason.OTHER_SOLUTION_SSII]: __(
      'ty:mission:team-member-mission-fail-reason:ssii',
    ),
    [MissionFailureReason.OTHER_SOLUTION_INTERNAL]: __(
      'ty:mission:team-member-mission-fail-reason:internal',
    ),
    [MissionFailureReason.OTHER_SOLUTION_NETWORK]: __(
      'ty:mission:team-member-mission-fail-reason:network',
    ),
    [MissionFailureReason.UNSATISFIED]: __(
      'ty:mission:team-member-mission-fail-reason:unstatisfied',
    ),
    [MissionFailureReason.FREE_ANOTHER_MISSION]: __(
      'ty:mission:team-member-mission-fail-reason:free-another-mission',
    ),
    [MissionFailureReason.NON_SPECIFIED]: __(
      'ty:mission:team-member-mission-fail-reason:non-specified',
    ),
    [MissionFailureReasonByTeamMember.MATCHING_COMET_WONT_FIND_SOLUTION]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:matching-comet-wont-find-solution',
    ),
    [MissionFailureReasonByTeamMember.MATCHING_COMET_CANT_REACH_CLIENT]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:matching-comet-cant-reach-client',
    ),
    [MissionFailureReasonByTeamMember.MATCHING_CORPO_FOUND_SOLUTION_WITH_COMPETITION]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:matching-corpo-found-solution-with-competition',
    ),
    [MissionFailureReasonByTeamMember.MATCHING_CORPO_FOUND_SOLUTION_INTERNALY]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:matching-corpo-found-solution-internaly',
    ),
    [MissionFailureReasonByTeamMember.MATCHING_CORPO_OFFER_CANCELED]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:matching-corpo-offer-canceled',
    ),
    [MissionFailureReasonByTeamMember.MATCHING_CORPO_OFFER_HAS_CHANGED]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:matching-corpo-offer-has-changed',
    ),
    [MissionFailureReasonByTeamMember.PROCESS_COMET_NO_MORE_SOLUTION_AVAILABLE]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:process-comet-no-more-solution-available',
    ),
    [MissionFailureReasonByTeamMember.PROCESS_COMET_CANT_REACH_CLIENT]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:process-comet-cant-reach-client',
    ),
    [MissionFailureReasonByTeamMember.PROCESS_COMET_DEPRIORITIZED_INTERNALLY]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:process-comet-deprioritized-internally',
    ),
    [MissionFailureReasonByTeamMember.PROCESS_CORPO_FOUND_SOLUTION_WITH_COMPETITION]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:process-corpo-found-solution-with-competition',
    ),
    [MissionFailureReasonByTeamMember.PROCESS_CORPO_OFFER_CANCELED]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:process-corpo-offer-canceled',
    ),
    [MissionFailureReasonByTeamMember.PROCESS_CORPO_OFFER_HAS_CHANGED]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:process-corpo-offer-has-changed',
    ),
    [MissionFailureReasonByTeamMember.INTERVIEW_COMET_NO_MORE_SOLUTION_AVAILABLE]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:interview-comet-no-more-solution-available',
    ),
    [MissionFailureReasonByTeamMember.INTERVIEW_COMET_CANT_REACH_CLIENT]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:interview-comet-cant-reach-client',
    ),
    [MissionFailureReasonByTeamMember.INTERVIEW_COMET_DEPRIORITIZED_INTERNALLY]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:interview-comet-deprioritized-internally',
    ),
    [MissionFailureReasonByTeamMember.INTERVIEW_CLIENT_FOUND_SOLUTION_WITH_COMPETITION]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:interview-client-found-solution-with-competition',
    ),
    [MissionFailureReasonByTeamMember.INTERVIEW_CLIENT_OFFER_CANCELED]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:interview-client-offer-canceled',
    ),
    [MissionFailureReasonByTeamMember.INTERVIEW_CLIENT_OFFER_HAS_CHANGED]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:interview-client-offer-has-changed',
    ),
    [MissionFailureReasonByTeamMember.CONTRACT_COMET_CANT_AGREE_ON_CONTRACT_TERMS]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:contract-comet-cant-agree-on-contract-terms',
    ),
    [MissionFailureReasonByTeamMember.CONTRACT_COMET_IMPOSSIBLE_TO_CONTRACT]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:contract-comet-impossible-to-contract',
    ),
    [MissionFailureReasonByTeamMember.CONTRACT_FREELANCE_CANT_AGREE_ON_CONTRACT_TERMS]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:contract-freelance-cant-agree-on-contract-terms',
    ),
    [MissionFailureReasonByTeamMember.CONTRACT_FREELANCE_OTHER_MISSION_CONTRACTED]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:contract-freelance-other-mission-contracted',
    ),
    [MissionFailureReasonByTeamMember.CONTRACT_CORPO_CANT_AGREE_ON_CONTRACT_TERMS]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:contract-corpo-cant-agree-on-contract-terms',
    ),
    [MissionFailureReasonByTeamMember.CONTRACT_CORPO_OFFER_CANCELED]: __(
      'ty:mission:team-member-mission-fail-reason-by-tm:contract-corpo-offer-canceled',
    ),
  }

  return trans[key] || undefined
}

/**
 * Return the formatted reason why the corporate cancel a mission
 */
function formatCorporateMissionFailureReason(key) {
  const trans = {
    [MissionFailureReasonByCorporate.CONTRACT_CORPO_CANT_AGREE_ON_CONTRACT_TERMS]: __(
      'ty:mission:corporate-mission-fail-reason:contract-corpo-cant-agree-on-contract-terms',
    ),
    [MissionFailureReasonByCorporate.CONTRACT_CORPO_OFFER_CANCELED]: __(
      'ty:mission:corporate-mission-fail-reason:contract-corpo-offer-canceled',
    ),
    [MissionFailureReasonByCorporate.INTERVIEW_CLIENT_FOUND_SOLUTION_WITH_COMPETITION]: __(
      'ty:mission:corporate-mission-fail-reason:interview-client-found-solution-with-competition',
    ),
    [MissionFailureReasonByCorporate.INTERVIEW_CLIENT_OFFER_CANCELED]: __(
      'ty:mission:corporate-mission-fail-reason:interview-client-offer-canceled',
    ),
    [MissionFailureReasonByCorporate.INTERVIEW_CLIENT_OFFER_HAS_CHANGED]: __(
      'ty:mission:corporate-mission-fail-reason:interview-client-offer-has-changed',
    ),
    [MissionFailureReasonByCorporate.PROCESS_CORPO_FOUND_SOLUTION_WITH_COMPETITION]: __(
      'ty:mission:corporate-mission-fail-reason:process-corpo-found-solution-with-competition',
    ),
    [MissionFailureReasonByCorporate.PROCESS_CORPO_OFFER_CANCELED]: __(
      'ty:mission:corporate-mission-fail-reason:process-corpo-offer-canceled',
    ),
    [MissionFailureReasonByCorporate.PROCESS_CORPO_OFFER_HAS_CHANGED]: __(
      'ty:mission:corporate-mission-fail-reason:process-corpo-offer-has-changed',
    ),
    [MissionFailureReasonByCorporate.MATCHING_CORPO_FOUND_SOLUTION_WITH_COMPETITION]: __(
      'ty:mission:corporate-mission-fail-reason:matching-corpo-found-solution-with-competition',
    ),
    [MissionFailureReasonByCorporate.MATCHING_CORPO_FOUND_SOLUTION_INTERNALY]: __(
      'ty:mission:corporate-mission-fail-reason:matching-corpo-found-solution-internaly',
    ),
    [MissionFailureReasonByCorporate.MATCHING_CORPO_OFFER_CANCELED]: __(
      'ty:mission:corporate-mission-fail-reason:matching-corpo-offer-canceled',
    ),
    [MissionFailureReasonByCorporate.MATCHING_CORPO_OFFER_HAS_CHANGED]: __(
      'ty:mission:corporate-mission-fail-reason:matching-corpo-offer-has-changed',
    ),
  }

  return trans[key] || undefined
}

/**
 * Format the wording according to the given key
 * @param {String} key missing fields
 * @returns {String}
 */
function formatMissingFields(key) {
  const trans = {
    activity: __('ty:mission:missing-fields:activity'),
    activityType: __('ty:mission:missing-fields:activity-type'),
    billingAddressId: __('ty:mission:missing-fields:billing-address'),
    commercialCourt: __('ty:mission:missing-fields:commercial-court'),
    headOfficeAddressId: __('ty:mission:missing-fields:head-office-address'),
    legalForm: __('ty:mission:missing-fields:legal-form'),
    siret: __('ty:mission:missing-fields:siret'),
    socialCapital: __('ty:mission:missing-fields:social-capital'),
    vatNumber: __('ty:mission:missing-fields:vat-number'),
    name: __('ty:mission:missing-fields:name'),
    legalName: __('ty:mission:missing-fields:legal-name'),
    iban: __('ty:mission:missing-fields:iban'),
    birthDate: __('ty:mission:missing-fields:birth-date'),
    nationalityIso: __('ty:mission:missing-fields:nationality'),
  }

  return trans[key] || undefined
}

function formatFreelancerPeriodicRating(key) {
  const trans = {
    [FreelancerPeriodicRating.GOOD]: __('ty:mission:periodic-freelancer-review:good'),
    [FreelancerPeriodicRating.AVERAGE]: __('ty:mission:periodic-freelancer-review:average'),
    [FreelancerPeriodicRating.BAD]: __('ty:mission:periodic-freelancer-review:bad'),
  }

  return trans[key] || undefined
}

function formatLegalModel(key) {
  const trans = {
    [LegalModel.NEW]: __('ty:mission:legal-model:new'),
    [LegalModel.EX]: __('ty:mission:legal-model:ex'),
  }

  return trans[key] || undefined
}

/**
 * Return the corresponding project type translation for the given key
 * @param {String} key
 */
function formatProjectType(key) {
  const trans = {
    [ProjectType.NEW]: __('ty:mission:project-type:new'),
    [ProjectType.EXISTING]: __('ty:mission:project-type:existing'),
  }

  return trans[key] || undefined
}

/**
 * Return the corresponding rhythm translation according to the given key
 * @param {String} key
 */
function formatProjectRhythm(key) {
  const trans = {
    [MissionRhythm.FULL_TIME]: __('ty:mission:project-rhythm:full-time'),
    [MissionRhythm.PARTIAL_TIME]: __('ty:mission:project-rhythm:partial-time'),
  }

  return trans[key] || undefined
}

function formatMissionRenewable(key) {
  const trans = {
    [MissionRenewable.HIGH]: __('ty:mission:mission-renewable:high'),
    [MissionRenewable.POSSIBLE]: __('ty:mission:mission-renewable:possible'),
    [MissionRenewable.NONE]: __('ty:mission:mission-renewable:null'),
  }

  return trans[key] || undefined
}

function formatMissionWorkingTime(key) {
  const trans = {
    [WorkingTime.ONE_DAY]: __('ty:mission:working-time:one-day'),
    [WorkingTime.TWO_DAYS]: __('ty:mission:working-time:two-days'),
    [WorkingTime.THREE_DAYS]: __('ty:mission:working-time:three-days'),
    [WorkingTime.FOUR_DAYS]: __('ty:mission:working-time:four-days'),
    [WorkingTime.FULL_TIME]: __('ty:mission:working-time:full-time'),
  }

  return trans[key] || undefined
}

function formatApplicantsReviewDate(date, status) {
  if (!date || ![TO_APPROVE, SEEKING].includes(status)) {
    return null
  }
  return moment().isSameOrAfter(moment(date))
    ? __('ty:mission:applicants-review-date-passed')
    : __('ty:mission:applicants-review-date', {
        date: format('date', date, 'shortdate'),
      })
}

/**
 * Skill duration
 * @type {String}
 */
function formatMissionSkillDuration(duration) {
  return __('ty:mission:skill-duration', duration, { duration })
}

/**
 * Format the mission offer
 * @returns {String}
 */
function formatMissionOffer(key) {
  const trans = {
    [MissionOffer.BOOST]: __('cp:mission:mission-offer-select:boost'),
    [MissionOffer.CONNECT]: __('cp:mission:mission-offer-select:connect'),
    [MissionOffer.FLEET]: __('cp:mission:mission-offer-select:fleet'),
    [MissionOffer.EXCHANGE]: __('cp:mission:mission-offer-select:exchange'),
  }
  return trans[key] || undefined
}

function formatMissionOfferDescription(key) {
  const trans = {
    [MissionOffer.BOOST]: __('cp:mission:mission-offer-select:boost:description'),
    [MissionOffer.CONNECT]: __('cp:mission:mission-offer-select:connect:description'),
  }
  return trans[key] || undefined
}

/**
 * Format the mission origin
 * @returns {String}
 */
function formatMissionOrigin(key) {
  const trans = {
    [MissionOrigin.DIRECT_INBOUND]: __('ty:mission:origin:directInbound'),
    [MissionOrigin.PUBLIC_INBOUND]: __('ty:mission:origin:publicInbound'),
    [MissionOrigin.EXCLUSIVE_INBOUND]: __('ty:mission:origin:exclusiveInbound'),
    [MissionOrigin.OUTBOUND]: __('ty:mission:origin:outbound'),
    [MissionOrigin.BUSINESS_PROVIDER]: __('ty:mission:origin:businessProvider'),
  }
  return trans[key] || undefined
}

/**
 * Format the mission origin in short
 * @returns {String}
 */
function formatMissionOriginShort(key) {
  const trans = {
    [MissionOrigin.DIRECT_INBOUND]: __('ty:mission:short-origin:directInbound'),
    [MissionOrigin.PUBLIC_INBOUND]: __('ty:mission:short-origin:publicInbound'),
    [MissionOrigin.EXCLUSIVE_INBOUND]: __('ty:mission:short-origin:exclusiveInbound'),
    [MissionOrigin.OUTBOUND]: __('ty:mission:short-origin:outbound'),
    [MissionOrigin.BUSINESS_PROVIDER]: __('ty:mission:short-origin:businessProvider'),
  }
  return trans[key] || undefined
}

/**
 * Format the mission origin description
 * @returns {String}
 */
function formatMissionOriginDescription(key) {
  const trans = {
    [MissionOrigin.DIRECT_INBOUND]: __('ty:mission:origin:description:directInbound'),
    [MissionOrigin.PUBLIC_INBOUND]: __('ty:mission:origin:description:publicInbound'),
    [MissionOrigin.EXCLUSIVE_INBOUND]: __('ty:mission:origin:description:exclusiveInbound'),
    [MissionOrigin.OUTBOUND]: __('ty:mission:origin:description:outbound'),
    [MissionOrigin.BUSINESS_PROVIDER]: __('ty:mission:origin:description:businessProvider'),
  }
  return trans[key] || undefined
}

/**
 * Format the mission short offer
 * @returns {String}
 */
function formatMissionShortOffer(key) {
  const trans = {
    [MissionOffer.BOOST]: __('ty:mission:short-offer:boost'),
    [MissionOffer.CONNECT]: __('ty:mission:short-offer:connect'),
    [MissionOffer.FLEET]: __('ty:mission:short-offer:fleet'),
    [MissionOffer.EXCHANGE]: __('ty:mission:short-offer:exchange'),
  }
  return trans[key] || undefined
}

/**
 * Format the mission short offer
 * @returns {String}
 */
function formatMissionOfferColor(key) {
  const trans = {
    [MissionOffer.BOOST]: 'yellow',
    [MissionOffer.CONNECT]: 'cyan',
    [MissionOffer.FLEET]: 'orange',
    [MissionOffer.EXCHANGE]: 'pink',
  }
  return trans[key] || undefined
}

/**
 * Format the mission global status
 * @returns {String}
 */
function formatMissionGlobalStatus(key) {
  const trans = {
    /**
     * Evaluation missing
     */
    [MissionGlobalStatus.MISSION_REVIEWED]: __('ty:mission:global-status:mission-reviewed'),
    [MissionGlobalStatus.MISSION_FINISHED_WITH_ONE_PENDING_EVALUATION]: __(
      'ty:mission:global-status:mission-finished-with-1-evaluation-pending',
    ),

    /**
     * Closure missing
     */
    [MissionGlobalStatus.MISSION_ONGOING_CLOSURE_PROCESS_FINALIZED]: __(
      'ty:global-status:mission-ongoing-closure-process-finalized',
    ),
    [MissionGlobalStatus.MISSION_ONGOING_CLOSURE_PROCESS_STARTED]: __(
      'ty:global-status:mission-ongoing-closure-process-started',
    ),

    /**
     * Renewal statuses
     */
    [MissionGlobalStatus.DEAL_EXT_SIGNING]: __('ty:global-status:deal-ext-signing'),
    [MissionGlobalStatus.DEAL_EXT_AGREED]: __('ty:global-status:deal-ext-agreed'),
    [MissionGlobalStatus.DEAL_EXT_TERMS_SENT]: __('ty:global-status:deal-ext-terms-sent'),
    [MissionGlobalStatus.DEAL_EXT_CREATED]: __('ty:global-status:deal-ext-created'),
    [MissionGlobalStatus.MISSION_ON_GOING]: __('ty:global-status:mission-ongoing'),

    /**
     * Quotation
     */
    [MissionGlobalStatus.DEAL_SIGNING]: __('ty:mission:global-status:deal-signing'),
    [MissionGlobalStatus.DEAL_AGREED]: __('ty:mission:global-status:deal-agreed'),
    [MissionGlobalStatus.DEAL_TERMS_SENT]: __('ty:mission:global-status:deal-terms-sent'),
    [MissionGlobalStatus.DEAL_CREATED]: __('ty:mission:global-status:deal-created'),

    /**
     * Matching
     */
    [MissionGlobalStatus.MATCHING_MEETING]: __('ty:global-status:matching-meeting'),
    [MissionGlobalStatus.MATCHING_SLOTS]: __('ty:global-status:matching-slots'),
    [MissionGlobalStatus.MATCHING_CLIENT_FURTHERED]: __(
      'ty:global-status:matching-client-furthered',
    ),
    [MissionGlobalStatus.MATCHING_SELECTION_SENT]: __('ty:global-status:matching-selection-sent'),
    [MissionGlobalStatus.MATCHING_ONE_PLUS_CANDIDATE]: __(
      'ty:global-status:matching-one-plus-candidate',
    ),
    [MissionGlobalStatus.MATCHING_MATCH_POST_MEETING_CONFIRMED]: __(
      'ty:global-status:matching-match-post-meeting-confirmed',
    ),
    [MissionGlobalStatus.MATCHING]: __('ty:global-status:matching'),

    /**
     * Pre-pipeline
     */
    [MissionGlobalStatus.MISSION_REFUSED]: __('ty:mission:global-status:mission-refused'),
    [MissionGlobalStatus.MISSION_TO_VALIDATE]: __('ty:mission:global-status:mission-to-validate'),
    [MissionGlobalStatus.MISSION_APPROVAL_DECLINED]: __(
      'ty:mission:global-status:mission-approval-declined',
    ),
    [MissionGlobalStatus.MISSION_TO_APPROVE]: __('ty:mission:global-status:mission-to-apprive'),
    [MissionGlobalStatus.MISSION_NOT_FINALIZED]: __(
      'ty:mission:global-status:mission-not-finalized',
    ),

    /**
     * Other
     */
    [MissionGlobalStatus.MISSION_KYC_CLIENT_PENDING]: __(
      'ty:mission:global-status:mission-kyc-client-pending',
    ),
    [MissionGlobalStatus.MISSION_KYC_FREELANCE_PENDING]: __(
      'ty:mission:global-status:mission-kyc-freelance-pending',
    ),
    [MissionGlobalStatus.MISSION_INVOICING_CLIENT_PENDING]: __(
      'ty:mission:global-status:mission-invoicing-client-pending',
    ),
    [MissionGlobalStatus.MISSION_FAILED]: __('ty:mission:global-status:mission-failed'),
  }
  return trans[key] || undefined
}

/**
 * Format the mission short offer
 * @returns {String}
 */
function formatMissionSource(key) {
  const trans = {
    [MissionSource.COMET_SERVICES]: __('ty:mission:source:comet-services'),
    [MissionSource.FREELANCE_INFO]: __('ty:mission:source:freelance-info'),
  }
  return trans[key] || undefined
}

export {
  formatApplicantsReviewDate,
  formatCorporateMissionFailureReason,
  formatCorporateMissionMode,
  formatFreelancerPeriodicRating,
  formatLegalModel,
  formatMissingFields,
  formatMissionEmoji,
  formatMissionExpertise,
  formatMissionOrder,
  formatMissionRefusalComment,
  formatMissionRefusalLabel,
  formatMissionRenewable,
  formatMissionSkillDuration,
  formatMissionStatus,
  formatMissionOngoingStatus,
  formatMissionShortStatus,
  formatMissionWorkingTime,
  formatProjectRhythm,
  formatProjectType,
  formatTeamMemberMissionFailureReason,
  getMissionColor,
  getMissionTheme,
  formatMissionOffer,
  formatMissionOfferDescription,
  formatMissionOrigin,
  formatMissionOriginShort,
  formatMissionOriginDescription,
  formatMissionShortOffer,
  formatMissionOfferColor,
  formatMissionGlobalStatus,
  formatMissionSource,
}
