import 'vuetify/dist/vuetify.min.css'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import VueConstants from 'vue-constants'
import VueDragDrop from 'vue-drag-drop'
import VueGeoSuggest from 'vue-geo-suggest'
import VueMatchMedia from 'vue-match-media'
import VueObserveVisibility from 'vue-observe-visibility'
import VuePortal from 'portal-vue'
import VueResource from 'vue-resource'
import VueScrollTo from 'vue-scrollto'
import VTooltip from 'v-tooltip'
import PortalVue from 'portal-vue'
import { sync } from 'vuex-router-sync'
import * as AwsAmplify from 'aws-amplify'

// [Global] shim for Object.assign (used by apollo-client)
import 'core-js/stable/object/assign'

import VueComet from '@comet/vue'
import createI18n, { setLocale } from '@comet/i18n'

import { createApolloClient, createApolloProvider } from '@/apollo'
import createStore from '@/store'
import createRouter, { useRouterGuards } from '@/router'
import { registerModals } from '@/modals'
import createVuetify, { mq, setVuetifyLocale } from '@/styles'

import VueApp from '@/plugins/app'
import VueAutoSize from '@/plugins/autosize'
import VueClock from '@/plugins/clock'

import App from '@/components/app/App/App'

import config from '@/config'
import { Amplify } from 'aws-amplify'
import './configs/axios'

/**
 * AWS amplify initialization
 */

Amplify.configure(config.amplify)

const VUE_GEO_SUGGEST_OPTIONS = {
  apiKey: config.services.maps.apiKey,
}

const VUE_TOOLTIP_OPTIONS = {
  defaultOffset: 8,
  defaultContainer: '#app',
  popover: {
    defaultContainer: '#app',
  },
}

/**
 * Initialize Vue and start the whole application
 */
async function init() {
  // Create the Vuex store
  const store = createStore()

  // Create the Vue router
  const router = createRouter()

  // Register modals globally
  registerModals()

  // Create a new Apollo client, subscribe the middlewares and generate a provider
  const client = createApolloClient(store, router)
  const apollo = createApolloProvider(client)

  // Create an i18n module
  const i18n = createI18n({
    ...config.i18n,
    onLocaleChange: (locale, language) => setVuetifyLocale(locale, language),
  })
  await setLocale('fr-FR')

  // Create and configure the Vuetify instance
  const vuetify = createVuetify()

  // Store the router state in Vuex and keep it synced
  sync(store, router)

  // Attach guards / hooks to the router
  useRouterGuards(router, store)

  // Vue plugins
  Vue.use(Vuelidate)
  Vue.use(VueClipboard)
  Vue.use(VueConstants)
  Vue.use(VueGeoSuggest, VUE_GEO_SUGGEST_OPTIONS)
  Vue.use(VueMatchMedia)
  Vue.use(VueObserveVisibility)
  Vue.use(VuePortal)
  Vue.use(VueResource)
  Vue.use(VueScrollTo)
  Vue.use(PortalVue)
  Vue.use(VTooltip, VUE_TOOLTIP_OPTIONS)
  Vue.use(VueDragDrop)

  // Comet plugins
  Vue.use(VueComet)
  Vue.use(VueApp)
  Vue.use(VueAutoSize)
  Vue.use(VueClock)

  // Vue configuration
  Vue.config.productionTip = false
  Vue.config.devtools = config.vue.devtools

  // Initialize and configure the error monitoring service
  window._axcb.push(function(axeptio) {
    axeptio.on('cookies:complete', function(choices) {
      if (choices.sentry && !window.sentryIsInit) {
        window.sentryIsInit = true
        import('@/utils/sentry').then(initSentry => {
          initSentry.default()
        })
      }
    })
  })

  // Register to Aws Amplify Auth status changes, save it in the store
  store.commit('AWS_AMPLIFY_AUTH_STATUS_UPDATE', null)
  AwsAmplify.Hub.listen('auth', data => {
    store.commit('AWS_AMPLIFY_AUTH_STATUS_UPDATE', data.payload.event)
  })

  // Start-up Vue targeting the main page's div
  new Vue({
    render: h => h(App),
    apolloProvider: apollo,
    i18n,
    mq,
    router,
    store,
    vuetify,
  }).$mount('#app')
}

init()
