<template>
  <div>
    <NavButton
      :class="[$style.notificationsButton, { [$style.active]: displayNotificationCenter }]"
      :size="NavButton.Size.SMALL"
      :disabled="disabled"
      :theme="NavButton.Theme.SECONDARY_VOID"
      @click="onOpen"
    >
      <Icon :class="$style.icon" name="bell" />

      <Txt
        v-if="unseenUserNotificationsCount"
        :class="$style.remaining"
        :size="Txt.Size.XXXXS"
        :type="Txt.Type.BOLD"
        :value="unseenUserNotificationsCount > 99 ? '+99' : unseenUserNotificationsCount"
      />
    </NavButton>

    <NotificationCenter
      v-if="displayNotificationCenter"
      :has-unread="!!unseenUserNotificationsCount"
      :notifications="userNotifications.items"
      :loading="$apollo.queries.userNotifications.loading"
      :total="total"
      @update="() => refetch('unseenUserNotificationsCount')"
      @close="() => (displayNotificationCenter = false)"
      @load-more="loadMore"
    />

    <NotificationStack
      :stack="Object.values(notificationStack)"
      @update="() => refetch('unseenUserNotificationsCount')"
      @click="onStackClick"
    />
  </div>
</template>

<script>
  import Vue from 'vue'
  import _uniqBy from 'lodash/uniqBy'

  import Icon from '@/core/graphics/Icon/Icon'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import NotificationCenter from '@/components/common/NotificationCenter/NotificationCenter'
  import NotificationStack, {
    SUM_UP_ID,
    NOTIFICATION_DISPLAY_TIME,
  } from '@/components/common/NotificationStack/NotificationStack'
  import Txt from '@/core/text/Txt/Txt'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import ClockMixin from '@/mixins/ClockMixin'

  import query from './query.gql'
  import queryUnseenCount from './queryUnseenCount.gql'
  import querySettings from './querySettings.gql'
  import markAsReadUserNotification from './markAsReadUserNotification.gql'
  import subscription from './subscription.gql'

  import config from '@/config'

  const NOTIFICATIONS_LIMIT = 10

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Icon: Icon.__enums,
  NavButton: NavButton.__enums,
  NotificationCenter: NotificationCenter.__enums,
  NotificationStack: NotificationStack.__enums,
  Txt: Txt.__enums,
},

    name: 'NotificationsButton',
    components: {
      Icon,
      NavButton,
      NotificationCenter,
      NotificationStack,
      Txt,
    },
    mixins: [ApolloMixin, ClockMixin],
    props: {
      /**
       * Disables the button
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        me: null,
        displayNotificationCenter: false,
        /**
         * All the notifications fetched
         * @type {Array.Object}
         */
        userNotifications: null,
        /**
         * Total number of conversations
         * @type {Number}
         */
        total: null,
        /**
         * Stack of the "real time received notifications".
         * This will contain the newly fetched notifications. But if user is on another tab,
         * it will also contain a made up notification with just the number of new ones.
         * Has to be an object because notifications can be closed. Manipulating the index oform
         * an array my be dangerous as this array can also be updated by the timeouts on each notif.
         * The format is as following :
         * {
         *   notificationId1: { id: notificationId1, ...notification1 },
         *   notificationId2: { id: notificationId2, ...notification2 },
         * }
         * @type {Object}
         */
        notificationStack: {},
        unseenUserNotificationsCount: 0,
      }
    },
    created() {
      // If not asked before, ask for html Notification permission
      if (window.Notification?.permission === 'default') {
        window.Notification.requestPermission()
      }
    },
    apollo: {
      me: {
        query: querySettings,
      },
      $subscribe: {
        newNotification: {
          query: subscription,
          variables() {
            return { userId: this.me.id }
          },
          result(res) {
            const { refetch, $apollo, notify } = this
            const { userNotification } = res?.data || {}

            const cacheQuery = {
              query,
              variables: { offset: 0, limit: NOTIFICATIONS_LIMIT },
            }

            const store = $apollo.getClient()
            const cache = store.readQuery(cacheQuery)

            cache.userNotifications.items.unshift(userNotification)
            store.writeQuery({
              ...cacheQuery,
              data: cache,
            })

            refetch('unseenUserNotificationsCount')

            notify([userNotification])
          },
          skip() {
            return !this.me?.id
          },
        },
      },
      /**
       * Query to fetch only the total of unseen. We have to do it seperately because of the
       * infinite scroll.
       */
      unseenUserNotificationsCount: {
        query: queryUnseenCount,
        fetchPolicy: 'network-only',
      },
      /**
       * Query to fetch user notifications (no long polling)
       */
      userNotifications: {
        query,
        variables() {
          return { offset: 0, limit: NOTIFICATIONS_LIMIT }
        },
        fetchPolicy: 'network-only',
        result(result) {
          if (result.data && result.data.userNotifications) {
            const { userNotifications } = result.data

            this.total = userNotifications.count
          }
        },
      },
    },
    beforeDestroy() {
      clearInterval(this.retryTimeout)
    },
    methods: {
      /**
       * On stack click, if it's not the sum up, mark notifications as read and refetch count
       * Then remove from stack.
       * Otherwise juste remove from stack
       * @param   {ID}  id
       * @returns {Promise}
       */
      async onStackClick(id) {
        const { refetch, mutate } = this

        if (id !== SUM_UP_ID) {
          await mutate(markAsReadUserNotification, { variables: { id } })
          refetch('unseenUserNotificationsCount')
        }

        Vue.delete(this.notificationStack, id)
      },
      /**
       * Open notification center and empty notificationStack
       * @returns {Void}
       */
      onOpen() {
        this.displayNotificationCenter = !this.displayNotificationCenter
        Vue.set(this, 'notificationStack', {})
      },
      /**
       * Notify the user according to its settings:
       * @param   {Object} userNotifications
       * @returns {void}
       */
      notify(newUserNotifications) {
        const { notificationMediumList, notificationCategoryList } = this.me || {}

        if (!notificationMediumList.includes('web')) {
          // Do not notify if web notifications are turned of
          return
        }

        newUserNotifications.map(n => {
          if (!notificationCategoryList.includes(n.category)) {
            // Do not notify if category is not allowed
            return
          }

          if (document.visibilityState === 'visible') {
            if (!this.displayNotificationCenter) {
              // if tab is visible and browser and notification center is closed, display it in-app
              Vue.set(this.notificationStack, n.id, n)

              setTimeout(() => {
                Vue.delete(this.notificationStack, n.id)
              }, NOTIFICATION_DISPLAY_TIME)
            }
          } else if (Notification?.permission === 'granted') {
            // else if html notifications are granted, send html notification
            const notification = new Notification(n.plainTextDescription || 'Comet', { tag: n.id })

            if (n.url) {
              notification.onclick = event => {
                event.preventDefault() // prevent the browser from focusing the Notification's tab
                window.open(`${config.publicURL}${n.url}`, '_blank')
              }
            }
          } else {
            // Otherwise, add a sum up notification that will display the number of new ones
            // while user was away
            const { count } = this.notificationStack[SUM_UP_ID] || {}
            const newCount = count ? count + 1 : 1

            Vue.set(this.notificationStack, SUM_UP_ID, { id: SUM_UP_ID, count: newCount })
          }
        })
      },
      /**
       * Load more notifications from where we stoped
       * @returns {Promise}
       */
      async loadMore() {
        const { fetchMore, userNotifications } = this

        await fetchMore('userNotifications', {
          variables: {
            offset: userNotifications.items.length,
            limit: NOTIFICATIONS_LIMIT,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => ({
            userNotifications: {
              ...previousResult.userNotifications,
              id: previousResult.userNotifications.id + fetchMoreResult.userNotifications.id,
              items: _uniqBy(
                [
                  ...previousResult.userNotifications.items,
                  ...(fetchMoreResult?.userNotifications?.items || []),
                ],
                e => e.id,
              ),
            },
          }),
        })
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_variables.styl'

  bounce() {
    animation: bounce 3s ease-in-out infinite
  }

  @keyframes bounce {
    0% {
      top: 5px
    }
    4% {
      top: 2px
    }
    8%, 100% {
      top: 6px
    }
  }

  .notificationsButton {
    border: 1px solid var(--color-button-secondary)
    border-radius: $radius-extra-large
    transition: all .3s ease
    height: 40px
    box-sizing: border-box

    &:hover:not(.active) {
      border-color: var(--color-button-secondary-hover)
    }

    .active {
      border-color: var(--color-brand)
    }

    .icon {
      font-size: 19px
    }

    .remaining {
      position: absolute
      background-color: var(--color-negative)
      color: var(--color-font-contrast)
      width: 16px
      height: 16px
      border-radius: 50%
      top: 5px
      right: 10px
      display: flex
      align-items: center
      justify-content: center
      bounce()
      letter-spacing: 0px !important
    }
  }
</style>
