<template>
  <div class="language-selector">
    <LanguageList :value="value" :disabled="disabled" @input="onListChange" />
    <div class="language-selector-container">
      <FuzzyMultiselect
        class="language-selector-multiselect"
        track-by="id"
        label="name"
        :placeholder="__('cp:form:language-selector:add:placeholder')"
        :options="availableLanguages"
        :searchable="true"
        :show-labels="false"
        :max-height="1"
        :multiple="true"
        :clear-on-select="true"
        :reset-after="true"
        :disabled="disabled"
        :class="error ? 'invalid' : ''"
        @select="onLanguageSelect"
      />
      <span v-if="error" class="error">
        {{ error }}
      </span>
      <i class="icon-plus-circle left" />
      <i class="icon-chevron-bottom right" />
    </div>
  </div>
</template>

<script>
  import _differenceWith from 'lodash/differenceWith'

  import FuzzyMultiselect from '@/core/inputs/FuzzyMultiselect/FuzzyMultiselect'

  import I18nMixin from '@/mixins/I18nMixin'
  import FreelancerTypes from '@/types/freelancer'

  import LanguageList from './LanguageList'
  import query from './query.gql'

  /**
   * Language levels enum
   * @type {String}
   */
  const { BASIC } = FreelancerTypes.LanguageLevel

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  FuzzyMultiselect: FuzzyMultiselect.__enums,
  LanguageList: LanguageList.__enums,
},

    name: 'LanguageSelector',
    components: {
      FuzzyMultiselect,
      LanguageList,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * List of languages
       * @type {Array.<Object>}
       */
      value: {
        type: Array,
        default: () => [],
      },
      /**
       * Disable all the controls
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Error message to display in the component
       */
      error: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Languages (fetched by Apollo)
         * @type {Array.<Object>}
         */
        languages: [],
      }
    },
    computed: {
      /**
       * New available options (not selected yet)
       * @type {Array.<Object>}
       */
      availableLanguages() {
        const { value, languages } = this

        return _differenceWith(languages, value, (a, b) => a.id === b.id)
      },
    },
    apollo: {
      languages: {
        query,
      },
    },
    methods: {
      /**
       * Handler fired when a language is picked from the autocomplete input
       * @param {Object} language
       * @returns {void}
       */
      onLanguageSelect(language) {
        const { value } = this
        const { id, name } = language

        const newValue = [...value, { id, name, level: BASIC }]

        this.$emit('input', newValue)
      },
      /**
       * Handler fired when the language list is modified
       * @param {Array.<Object>} list
       * @returns {void}
       */
      onListChange(list) {
        this.$emit('input', list)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'
  @import '~@/assets/css/_grid.styl'

  .language-selector {
    content-grid()

    .language-selector-container {
      position: relative
      content-grid-column(4, 6, 7)
    }

    .language-selector-multiselect {
      & ~ i {
        position: absolute
        top: 9px
        font-size: 20px
        line-height: 24px

        &.left {
          left: 16px
          color: var(--color-input-placeholder)
        }

        &.right {
          right: 0
          margin: 0 8px
          transition: transform 0.2s ease
        }
      }
    }
  }
</style>
