<script>
  import _get from 'lodash/get'

  /**
   * This component allow to dinamically add a Transition or a Transition Group
   * determined by the `show` prop and the number of children passed in.
   *
   * Examples:
   * <WithTransition :show="true"> renders the transition.
   * <WithTransition :show="false"> don't.
   */
  export default {
    name: 'WithTransition',
    functional: true,
    props: {
      /**
       * Conditionally display the first child
       */
      show: {
        type: Boolean,
        default: true,
      },
    },
    render(h, ctx) {
      const { show } = ctx.props

      const children = _get(ctx, 'children', [])

      if (!show || !children.length) {
        return children
      }

      return children.length === 1
        ? h('Transition', { props: ctx.data.attrs }, children)
        : h('TransitionGroup', { props: ctx.data.attrs }, children)
    },
  }
</script>
