/**
 * Enum identifying the possible roles a
 * user can assume
 */
const AppEnv = {
  BACKOFFICE: 'backoffice',
  ENTERPRISE: 'enterprise',
  FREELANCER: 'freelancer',
}

/**
 * Errors the back-end could return when trying to
 * signing in
 */
const AppError = {
  UNKNOWN_ERROR: -1,
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INTERNAL_ERROR: 1,
  OUTDATED_VERSION: 3,

  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INVALID_URL_DOMAIN: 405,
  INVALID_FILE_TYPE: 410,
  FAIL_LOGIN: 450,
  FAIL_REGISTER: 451,
  ALREADY_LOGGED_IN: 452,
  LOGIN_PROVIDER_DUPLICATED: 456,

  INVALID_EMAIL: 550,
  EMAIL_ALREADY_USED: 551,
  PHONE_ALREADY_USED: 552,
  INVALID_PASSWORD: 553,
  INVALID_TOKEN: 554,
  INVALID_CREDENTIALS: 555,
  USER_ACTIVATION_PENDING: 556,
  EXPIRED_TOKEN: 557,
  LINKEDIN_ALREADY_USED: 558,
  EMAIL_ALREADY_USED_WITH_GOOGLE: 637,
  EMAIL_ALREADY_USED_WITH_LINKEDIN: 638,
  LINKEDIN_URL_INVALID: 639,

  MISSION_RATING_PENDING: 600,
  INVALID_MISSION_STATE: 605,
  MEETING_NOT_PAST: 606,
  TOO_MANY_SELECTED_MATCHES: 611,
  MISSING_SELECTED_MATCHES: 612,
  PROFILES_SELECTION_LOCKED: 613,
  MISSION_MEETING_ALREADY_SELECTED: 614,
  MISSION_MEETING_UNAVAILABLE: 615,
  MISSING_MEETING_REFUSAL_REASONS: 616,
  MISSION_HAS_CHANGED: 622,
  FREELANCE_NOT_DELETABLE: 628,
  MISSION_NOT_APPLICABLE: 634,
  FREELANCE_NOT_SELECTABLE: 630,
  CORPORATE_IS_NOT_DELETABLE: 643,
  FREELANCER_PARENT_ASSOCIATION_FORBIDDEN: 644,
  TOO_MANY_LINKEDIN_IMPORTS: 645,
}

/**
 * Kind of width the app content must be fit
 */
const AppWidth = {
  NONE: 'none',
  LARGE: 'large',
  MEDIUM: 'medium',
  SLIM: 'slim',
  THIN: 'thin',
  THINNER: 'thinner',
}

/**
 * Error types for Sequelize error returns
 */
const ErrorTypes = {
  FORM: 'form',
  UNIQUE: 'unique violation',
}

/**
 * Flag level
 */
const FlagLevel = {
  HIGH: 'high',
  NORMAL: 'normal',
  WARNING: 'warning',
}

/**
 * Types of messages which would be displayed in the app
 */
const MessageType = {
  DEFAULT: 'default',
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
}

/**
 * Possible direction is order directives
 */
const OrderDirection = {
  ASC: 'asc',
  DESC: 'desc',
}

const AppTypes = {
  AppEnv,
  AppError,
  AppWidth,
  ErrorTypes,
  FlagLevel,
  MessageType,
  OrderDirection,
}

export default AppTypes
