var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes},[(_vm.$slots.head)?_c('div',{class:_vm.$('head')},[_vm._t("head")],2):_vm._e(),_c('div',{class:_vm.$('date-form')},[_c('div',{class:_vm.$('date-field-container')},[_c('DateField',{class:_vm.$('date-field'),attrs:{"range":_vm.range,"label":_vm.__('cp:form:time-slot-input:date:label'),"placeholder":_vm.__('cp:form:time-slot-input:date:placeholder'),"disabled":_vm.disabled || !_vm.editable,"pattern":"writtenfulldate"},on:{"input":_vm.onDateChange},model:{value:(_vm.form.fields.date),callback:function ($$v) {_vm.$set(_vm.form.fields, "date", $$v)},expression:"form.fields.date"}}),(_vm.$slots.actions)?_c('div',{class:_vm.$('actions')},[_vm._t("actions")],2):_vm._e()],1),_c('Field',{class:( _obj = {}, _obj[_vm.$('hours-field')] = true, _obj[_vm.$('hours-field-disabled')] = !_vm.form.fields.date, _obj ),attrs:{"disabled":_vm.disabled || !_vm.editable}},[_c('template',{slot:"label"},[_c('Txt',{class:_vm.$('hours-field-label'),attrs:{"size":_vm.Txt.Size.XXXS,"type":_vm.Txt.Type.OVERLINE,"theme":_vm.disabled || !_vm.editable ? _vm.Txt.Theme.GREY_MEDIUM : _vm.Txt.Theme.GREY,"value":_vm.__('cp:form:time-slot-input:hours:label')}}),(_vm.isTeamMember)?_c('SwitchButton',{class:_vm.$('night-switch'),attrs:{"disabled":_vm.disabled || _vm.form.invalid,"label":_vm.nightSwitchLabel},model:{value:(_vm.form.fields.nightTime),callback:function ($$v) {_vm.$set(_vm.form.fields, "nightTime", $$v)},expression:"form.fields.nightTime"}}):_vm._e()],1),_c('WithDimensions',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var _obj, _obj$1;

      var width = ref.width;return _c('div',{class:[
            _vm.$('hours'),
            ( _obj = {}, _obj[_vm.$('hours-small')] = width < 500, _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$('hours-xsmall')] = width < 300, _obj$1 ) ]},_vm._l((_vm.displayedSlots),function(slot,slotIndex){return _c('div',{key:slotIndex,class:_vm.hourClasses},[_c('input',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm._get(slot, 'label')),expression:"_get(slot, 'label')"}],class:_vm.getSlotClass(slotIndex),attrs:{"type":"button","disabled":_vm.disabled || _vm.form.invalid},on:{"click":function($event){return _vm.onSlotPress(slotIndex)}}}),_vm._v(" "+_vm._s(_vm.getSlotHour(slotIndex))+" ")])}),0)}}])})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }