<template>
  <SimpleSpinner
    :class="['spinner', $style[theme]]"
    :size="safeSize"
    :speed="1.2"
    :line-size="safeThickness"
  />
</template>

<script>
  import SimpleSpinner from 'vue-simple-spinner'

  const Size = {
    BIG: 'big',
    MEDIUM: 'medium',
    SMALL: 'small',
    SMALLER: 'smaller',
  }

  const Theme = {
    PRIMARY: 'primary',
    PRIMARY_ALT: 'primary-alt',
    SECONDARY: 'secondary',
    SECONDARY_ALT: 'secondary-alt',
    SUCCESS: 'success',
    SUCCESS_ALT: 'success-alt',
    WARNING: 'warning',
    WARNING_ALT: 'warning-alt',
    DANGER: 'danger',
    DANGER_ALT: 'danger-alt',
    CONTRAST: 'contrast',
    CONTRAST_ALT: 'contrast-alt',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  SimpleSpinner: SimpleSpinner.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,Theme,
    name: 'Spinner',
    __enums: {
      Size,
      Theme,
    },
    components: {
      SimpleSpinner,
    },
    props: {
      size: {
        type: String,
        default: Size.MEDIUM,
      },
      theme: {
        type: String,
        default: Theme.PRIMARY,
      },
    },
    computed: {
      /**
       * Size translated into an integer, readable by 'vue-simple-spinner'
       * @type {Number}
       */
      safeSize() {
        const { size } = this

        switch (size) {
          case Size.BIG:
            return 48
          case Size.MEDIUM:
            return 24
          case Size.SMALL:
            return 20
          case Size.SMALLER:
            return 8
          default:
            return size
        }
      },
      /**
       * Thickness determined according to the given "size"
       * @type {Number}
       */
      safeThickness() {
        const { size } = this

        switch (size) {
          case Size.BIG:
            return 3
          case Size.MEDIUM:
            return 2
          case Size.SMALL:
            return 2
          case Size.SMALLER:
            return 2
          default:
            return size
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  .primary > div {
    border-color: var(--color-brand) var(--color-brand-transparent) \
      var(--color-brand-transparent) !important
  }

  .primary-alt > div {
    border-color: var(--color-white) var(--color-brand-highlight) \
      var(--color-brand-highlight) !important
  }

  .secondary > div {
    border-color: var(--color-grey-80) var(--color-grey-30) \
      var(--color-grey-30) !important
  }

  .secondary-alt > div {
    border-color: var(--color-grey-80) var(--color-grey-40) \
      var(--color-grey-40) !important
  }

  .success > div {
    border-color: var(--color-positive) var(--color-positive-transparent) \
      var(--color-positive-transparent) !important
  }

  .success-alt > div {
    border-color: var(--color-white) var(--color-positive-highlight) \
      var(--color-positive-highlight) !important
  }

  .warning > div {
    border-color: var(--color-warning) var(--color-warning-transparent) \
      var(--color-warning-transparent) !important
  }

  .warning-alt > div {
    border-color: var(--color-white) var(--color-warning-highlight) \
      var(--color-warning-highlight) !important
  }

  .danger > div {
    border-color: var(--color-negative) var(--color-negative-transparent) \
      var(--color-negative-transparent) !important
  }

  .danger-alt > div {
    border-color: var(--color-white) var(--color-negative-highlight) \
      var(--color-negative-highlight) !important
  }

  .contrast > div {
    border-color: var(--color-contrast) var(--color-contrast-transparent) \
      var(--color-contrast-transparent) !important
  }

  .contrast-alt > div {
    border-color: var(--color-white) var(--color-contrast-highlight) \
      var(--color-contrast-highlight) !important
  }
</style>
