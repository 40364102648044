import { render, staticRenderFns } from "./WithSlide.vue?vue&type=template&id=bdf25126&scoped=true&"
import script from "./WithSlide.vue?vue&type=script&lang=js&"
export * from "./WithSlide.vue?vue&type=script&lang=js&"
import style0 from "./WithSlide.vue?vue&type=style&index=0&id=bdf25126&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdf25126",
  null
  
)

export default component.exports