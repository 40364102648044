<template>
  <AppLink
    class="nav-button"
    :class="classes"
    :to="link"
    :type="external || blank ? 'external' : 'router'"
    :blank="blank"
    :disabled="isDisabled"
    @click="onButtonPress"
  >
    <button
      :type="type"
      :disabled="isDisabled"
      :class="{ 'nav-button-icon-rounded': !!icon }"
      :aria-label="icon"
      v-bind="$attrs"
    >
      <Spinner
        v-if="loading || processing"
        :size="Spinner.Size.SMALL"
        :theme="spinnerTheme"
        :class="$('spinner')"
      />

      <!-- If the button is in icon mode then we only display the icon -->
      <i v-if="!!icon && iconIsOld(icon)" :class="[icon]" />
      <Icon v-else-if="!!icon" :name="icon" :class="$('icon')" />

      <!-- Otherwise, if needed we display the confirm message text -->
      <slot v-else-if="showConfirm" name="confirm">
        <span :class="$('secure-label')" class="text">
          {{ __('cp:core:controls:nav-button:confirm') }}
          <span :class="$('remaining')"> ({{ remaining }}) </span>
        </span>
      </slot>

      <!-- Finally display the button with its icons if needed -->
      <span :class="[$('label'), { 'nav-button-label--hidden': hideLabel }]">
        <i
          v-if="leftIcon && iconIsOld(leftIcon)"
          :class="[{ 'nav-button-label-left': $slots.default || text }, leftIcon]"
        />
        <Icon
          v-else-if="leftIcon"
          :name="leftIcon"
          :class="[{ 'nav-button-label-left': $slots.default || text }]"
        />

        <template v-if="text">
          {{ text }}
        </template>

        <slot />

        <i v-if="rightIcon && iconIsOld(rightIcon)" :class="[$('label-right'), rightIcon]" />
        <Icon v-else-if="rightIcon" :name="rightIcon" :class="$('label-right')" />
      </span>
    </button>
  </AppLink>
</template>

<script>
  import AppLink from '@/core/controls/AppLink/AppLink'
  import Icon from '@/core/graphics/Icon/Icon'
  import Spinner from '@/core/controls/Spinner/Spinner'

  import ClockMixin from '@/mixins/ClockMixin'
  import I18nMixin from '@/mixins/I18nMixin'

  import { enumValidator } from '@/utils/prop'
  import { iconIsOld } from '@/utils/image'

  /**
   * Delay in seconds during which the confirmation is suggested
   * @type {Number}
   */
  const CONFIRM_DELAY = 5

  const Size = {
    SMALLER: 'smaller',
    SMALL: 'small',
    MEDIUM: 'medium',
    BIG: 'big',
  }

  const Theme = {
    PRIMARY: 'primary',
    PRIMARY_ALT: 'primary-alt',
    PRIMARY_VOID: 'primary-void',
    SECONDARY: 'secondary',
    SECONDARY_ALT: 'secondary-alt',
    SECONDARY_VOID: 'secondary-void',
    SUCCESS: 'success',
    SUCCESS_ALT: 'success-alt',
    SUCCESS_VOID: 'success-void',
    WARNING: 'warning',
    WARNING_ALT: 'warning-alt',
    WARNING_VOID: 'warning-void',
    DANGER: 'danger',
    DANGER_ALT: 'danger-alt',
    DANGER_VOID: 'danger-void',
    CONTRAST: 'contrast',
    CONTRAST_ALT: 'contrast-alt',
    CONTRAST_VOID: 'contrast-void',
    SLACK_ALT: 'slack-alt',
    BLACK_VOID: 'black-void',
  }

  const Type = {
    BUTTON: 'button',
    SUBMIT: 'submit',
  }

  const SPINNER_THEME_MAPPING = {
    [Theme.PRIMARY]: Spinner.Theme.PRIMARY_ALT,
    [Theme.PRIMARY_ALT]: Spinner.Theme.PRIMARY,
    [Theme.PRIMARY_VOID]: Spinner.Theme.PRIMARY,
    [Theme.SECONDARY]: Spinner.Theme.SECONDARY_ALT,
    [Theme.SECONDARY_ALT]: Spinner.Theme.SECONDARY,
    [Theme.SECONDARY_VOID]: Spinner.Theme.SECONDARY,
    [Theme.SUCCESS]: Spinner.Theme.SUCCESS_ALT,
    [Theme.SUCCESS_ALT]: Spinner.Theme.SUCCESS,
    [Theme.SUCCESS_VOID]: Spinner.Theme.SUCCESS,
    [Theme.WARNING]: Spinner.Theme.WARNING_ALT,
    [Theme.WARNING_ALT]: Spinner.Theme.WARNING,
    [Theme.WARNING_VOID]: Spinner.Theme.WARNING,
    [Theme.DANGER]: Spinner.Theme.DANGER_ALT,
    [Theme.DANGER_ALT]: Spinner.Theme.DANGER,
    [Theme.DANGER_VOID]: Spinner.Theme.DANGER,
    [Theme.CONTRAST]: Spinner.Theme.CONTRAST_ALT,
    [Theme.CONTRAST_ALT]: Spinner.Theme.CONTRAST,
    [Theme.CONTRAST_VOID]: Spinner.Theme.CONTRAST,
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  Icon: Icon.__enums,
  Spinner: Spinner.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,Type,Theme,
    name: 'NavButton',
    __enums: {
      Size,
      Type,
      Theme,
    },
    components: {
      AppLink,
      Icon,
      Spinner,
    },
    mixins: [ClockMixin, I18nMixin],
    inheritAttrs: false,
    props: {
      /**
       * Whether or not the app link is external
       */
      external: {
        type: Boolean,
        default: false,
      },
      /**
       * A router link
       */
      to: {
        type: [String, Object],
        default: null,
      },
      /**
       * <button> type attribute (button, submit, ...)
       * @type {String}
       */
      type: {
        type: String,
        default: 'button',
      },
      /**
       * If 'true', the 'to' link provided must be opened in a separate
       * tab in the browser (i.e. app-link with type "external")
       */
      blank: {
        type: Boolean,
        default: false,
      },
      /**
       * Size of this button (having effects on the height and paddings)
       * Values are listed in enum 'Size'
       */
      size: {
        type: String,
        default: Size.MEDIUM,
        validator: enumValidator(Size),
      },
      /**
       * The 'theme' describes how the button must look like and how it must
       * react on the different user actions (hover, active, disabled)
       */
      theme: {
        type: String,
        required: true,
        validator: enumValidator(Theme),
      },
      /**
       * Optional icon displayed on the left side of the button
       */
      leftIcon: {
        type: String,
        default: null,
      },
      /**
       * Optional icon displayed on the right side of the button
       */
      rightIcon: {
        type: String,
        default: null,
      },
      /**
       * If 'true', the button must be clicked 2 times to apply
       * its attached action
       */
      secured: {
        type: Boolean,
        default: false,
      },
      /**
       * Set to 'true' if the button should display a
       * spinner instead of text
       */
      loading: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true', it basically disables the button
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Handler called on click (if it returns a promise, the spinner
       * is displayed during processing)
       */
      onClick: {
        type: Function,
        default: null,
      },
      /**
       * Icon class to give if you want to have only a rounded icon and no
       * text
       */
      icon: {
        type: String,
        default: null,
      },
      /**
       * Text to display in the button
       */
      text: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Contains the date when the button is first-clicked when it
         * is "secured". It is used to compute the "remaining" data prop.
         * @type {Date}
         */
        confirm: null,
        /**
         * Number of remaining seconds before the confirmation is hidden
         * (i.e. before the button goes back into its inital state).
         * @type {Number}
         */
        remaining: 0,
        /**
         * Interval support
         * @type {Number}
         */
        interval: null,
        /**
         * When 'onClick' is set and returns a promise, processing is 'true'
         * while this promise is pending
         * @type {Boolean}
         */
        processing: false,
      }
    },
    computed: {
      /**
       * CSS classes applied to the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { size, theme, secured, loading, processing, disabled, confirm } = this

        return {
          [`nav-button--${theme}`]: !!theme,
          [`nav-button--${size}`]: !!size,
          'nav-button--secured': !!secured,
          'nav-button--secured-confirm': !!confirm,
          'nav-button--loading': !!(loading || processing),
          'nav-button--disabled': !!disabled,
        }
      },
      /**
       * Says if the confirmation message must be shown, or not.
       * @type {Boolean}
       */
      showConfirm() {
        const { secured, confirm } = this

        return secured && !!confirm
      },
      /**
       * Returns the button potential link, but only if the button is in
       * "confirmation" state if it is "secured"
       * @type {Boolean}
       */
      link() {
        const { to, secured, confirm } = this

        return !secured || confirm ? to : null
      },
      /**
       * Return true if the label must be hidden, if the button is loading,
       * secured, or processing
       * @type {Boolean}
       */
      hideLabel() {
        const { processing, loading, showConfirm } = this

        return processing || loading || showConfirm
      },
      /**
       * Return true if button must be disabled (if loading, processing or
       * disabled by parent)
       * @type {Boolean}
       */
      isDisabled() {
        const { loading, processing, disabled } = this

        return loading || processing || disabled
      },
      /**
       * Compute the spinner's theme according to the given button's theme
       * @type {String}
       */
      spinnerTheme() {
        const { theme } = this

        return SPINNER_THEME_MAPPING[theme] || Spinner.Theme.PRIMARY
      },
    },
    destroyed() {
      // Stop the interval if leaving the component
      this.stopConfirm()
    },
    methods: {
      iconIsOld,
      /**
       * Start the confirmation process, leading to a confirmation message
       * display.
       * @returns {void}
       */
      startConfirm() {
        const { now, updateRemaining } = this

        this.confirm = now()
        this.remaining = CONFIRM_DELAY
        this.interval = setInterval(updateRemaining, 10)
      },
      /**
       * Stop the confirmation process, reitinitializing all state parameters.
       * @returns {void}
       */
      stopConfirm() {
        if (this.interval) {
          clearInterval(this.interval)
        }

        this.confirm = null
        this.remaining = 0
        this.interval = null
      },
      /**
       * Update the "remaining" data prop when the button is "secured"
       * and in "confirmation" state.
       * @returns {void}
       */
      updateRemaining() {
        const { confirm, fromNow, stopConfirm } = this

        const ellapsed = -fromNow(confirm, 'seconds')

        if (ellapsed >= CONFIRM_DELAY) {
          stopConfirm()
        } else {
          this.remaining = Math.ceil(CONFIRM_DELAY - ellapsed)
        }
      },
      /**
       * Emitted when button is clicked
       * @returns {void}
       */
      onButtonPress(e) {
        const { secured, confirm, startConfirm, stopConfirm, onClick, type } = this
        const clickHandler = onClick || this.$listeners.click

        if (secured && !confirm) {
          startConfirm()
        } else {
          stopConfirm()

          if (clickHandler) {
            const res = clickHandler(e)

            if (res && res instanceof Promise) {
              this.processing = true

              res
                .then(() => {
                  this.processing = false
                })
                .catch(() => {
                  this.processing = false
                })
            }
          } else {
            this.$emit('click', e)
          }
        }

        // If the button is of type BUTTON and we give him a onClick function
        // then we prevent the default behavior because it's already handled
        if (type === Type.BUTTON && onClick) {
          e.preventDefault()
        }
      },
    },
  }
</script>

<style lang="stylus">

  @import '~@/assets/css/_shadow.styl'
  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .nav-button {
    display: inline-block

    > button {
      height: 40px
      width: 100%
      display: block
      position: relative
      padding: 0 16px
      border: none
      border-radius: $radius-extra-large
      font-family: $font
      font-weight: $font-medium
      font-size: 14px
      line-height: 1
      transition: all .2s ease
      outline: none
      overflow: hidden
      box-sizing: border-box
      user-select: none
      -webkit-appearance: none
      -moz-appearance: none
      background-color: transparent

      &:active:not(:disabled) {
        transform: scale(.95)
      }

      &:disabled {
        opacity: .32
        cursor: not-allowed
      }

      ^[0]-spinner {
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: flex
        align-items: center
        justify-content: center
      }
    }

    &-label {
      font-size: 14px
      line-height: 18px
      letter-spacing: 0.5px
      font-weight: $font-semi-bold
    }

    & &-icon-rounded {
      height: 40px
      width: 40px
      border-radius: 50%
      padding: 0 8px

      i {
        display: block
        font-size: 22px
      }
    }

    &--small {
      & ^[0]-icon-rounded {
        height: 24px
        width: 24px
        padding: 0
      }
    }

    &-label {
      display: flex
      flex-direction: row
      align-items: center
      justify-content: center
      ellipsis()

      &--hidden {
        visibility: hidden
      }

      i {
        font-size: 22px
      }

      &-left {
        margin-right: 8px
      }

      &-right {
        margin-left: 8px
      }
    }

    &-secure-label {
      position: absolute
      top: calc(50% - 12px)
      left: 0
      right: 0
      line-height: 24px
    }

    // Size
    &--big > button {
      height: 64px
      padding: 2px 40px
    }

    &--big &-icon-rounded &-icon {
      font-size: 22px
    }

    &--big &-icon-rounded i {
      font-size: 32px
    }

    &--medium > button {
      padding: 2px 24px
    }

    &--small > button {
      padding: 0 16px
    }

    &--smaller > button {
      padding: 0 12px
      height: 30px
      border-radius: $radius-small
    }

    // With countdown secure confirm
    &--secured-confirm > button {
      opacity: .7

      .nav-button-remaining {
        margin-left: 8px
      }
    }

    /**
    > This function generates themes for components (see NavButton for use case)
    - name = the theme's name (ex: light--primary)
    - bg = the background color of the theme (ex : --color-brand)
    - txt = the text color of the theme (ex: --color-font-contrast)
    - hover-bg = background to display on the hover

    - alt-bg = the background color of the theme in -alt mode
    (If none is specified, the theme-alt has no bg, but a border instead)
    - alt-txt = the text color of the theme in -alt mode (bg by default)
    - alt-border = the border of the theme in alt-mode (only used if no bg is
                   specified, alt-txt by default))
      */
    nav-button-theme(name, bg, txt, hover-bg = bg, alt-hover, alt-txt = bg, alt-border = alt-txt) {

      // Theme
      &--{name} > button {
        background: bg
        color: txt
        shadow('dark', 'small')

        // The CSS is duplicated here and for the rest because we need to handle
        // when there is Txt component or just directly a text
        .text, .nav-button-label-right, .nav-button-label-left {
          color: txt
        }

        &:not(:disabled):hover {
          background: hover-bg
          shadow('darker', 'small')
          transform: translateY(-2px)
        }
      }

      // Theme-alt
      &--{name}-alt > button {
        color: alt-txt
        border: solid 1px alt-border
        shadow('light', 'small')
        if name != 'secondary' {
          background: txt
        }
        else {
          background: var(--color-background)
        }

        .text {
          color: alt-txt
        }

        &:not(:disabled):hover {
          border-color: alt-hover
          shadow('dark', 'small')
          transform: translateY(-2px)
        }
      }

      // Theme-void
      &--{name}-void > button {

        // For the secondary the theme don't respect the same logic
        if name == 'secondary' {
          color: var(--color-button-secondary-void)
        } else {
          color: alt-txt

          .text {
            color: alt-txt
          }
        }

        background: none
        box-shadow: none

        &:not(:disabled):hover {
          if name == 'secondary' {
            color: var(--color-font)
          } else {
            color: hover-bg
          }
        }
      }
    }

    // Themes
    nav-button-theme("primary",
      var(--color-button-primary),              // bg
      var(--color-font-contrast),               // txt
      var(--color-button-primary-hover),        // hover-bg
      var(--color-button-primary-alt-hover))    // alt-hover

    nav-button-theme("secondary",
      var(--color-button-secondary),            // bg
      var(--color-font),                        // txt
      var(--color-button-secondary-hover),      // hover-bg
      var(--color-button-secondary-alt-hover),  // alt-hover
      var(--color-font),                        // alt-txt
      var(--color-border))                      // alt-border

    nav-button-theme("success",
      var(--color-positive),                    // bg
      var(--color-font-contrast),               // txt
      var(--color-positive-lowlight),           // hover-bg
      var(--color-positive-opaque))             // alt-hover

    nav-button-theme("warning",
      var(--color-warning),                    // bg
      var(--color-font-contrast),               // txt
      var(--color-warning-lowlight),           // hover-bg
      var(--color-warning-opaque))             // alt-hover

    nav-button-theme("danger",
      var(--color-negative),                    // bg
      var(--color-font-contrast),               // txt
      var(--color-negative-lowlight),           // hover-bg
      var(--color-negative-opaque))             // alt-hover

    nav-button-theme("contrast",
      var(--color-contrast),                    // bg
      var(--color-font-contrast),               // txt
      var(--color-contrast-lowlight),           // hover-bg
      var(--color-contrast-opaque))             // alt-hover

    nav-button-theme("slack",
      var(--color-slack),                       // bg
      var(--color-white),                       // txt
      var(--color-slack),                       // hover-bg
      var(--color-slack),                       // alt-hover
      var(--color-slack),                       // alt-txt
      var(--color-slack))                       // alt-border

    nav-button-theme("black",
      var(--color-black),                       // bg
      var(--color-white),                       // txt
      var(--color-black),                       // hover-bg
      var(--color-black),                       // alt-hover
      var(--color-black),                       // alt-txt
      var(--color-black))                       // alt-border
  }
</style>
