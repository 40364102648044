<template>
  <Box
    data-cy-informative-card
    :theme="theme"
    :class="classes"
    :padding="Box.Padding.XS"
    class="informative-card"
  >
    <i v-if="iconIsOld(icon)" :class="icon" />
    <Icon v-else-if="icon" :class="$('icon')" :name="icon" />

    <div :class="$('body')">
      <Heading
        v-if="title"
        :class="$('title')"
        :text="title"
        :theme="theme"
        :ellipsis="ellipsis"
        :level="level"
      />

      <Txt
        v-if="description"
        :class="$('description')"
        :value="description"
        :size="Txt.Size.XXXS"
        :theme="txtTheme"
        :raw="raw"
      />

      <div v-if="$slots.default" :class="$('content')">
        <slot />
      </div>

      <div v-if="$slots.footer" :class="$('footer')">
        <slot name="footer" />
      </div>
    </div>
  </Box>
</template>

<script>
  import Box from '@/core/layout/Box/Box'
  import Heading from '@/core/text/Heading/Heading'
  import Txt from '@/core/text/Txt/Txt'
  import Icon from '@/core/graphics/Icon/Icon'

  import { iconIsOld } from '@/utils/image'

  const { Level } = Heading

  const Theme = {
    PRIMARY: 'primary',
    INFORMATION: 'information',
    INFORMATION_ALT: 'information-alt',
    INFORMATION_VOID: 'information-void',
    SUCCESS: 'success',
    SUCCESS_ALT: 'success-alt',
    SUCCESS_VOID: 'success-void',
    WARNING: 'warning',
    WARNING_ALT: 'warning-alt',
    WARNING_VOID: 'warning-void',
    DANGER: 'danger',
    DANGER_ALT: 'danger-alt',
    DUAL: 'dual',
    DANGER_VOID: 'danger-void',
    YELLOW_ALT: 'yellow-alt',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Box: Box.__enums,
  Heading: Heading.__enums,
  Icon: Icon.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme,Level,
    name: 'InformativeCard',
    __enums: {
      Theme,
      Level,
    },
    components: {
      Box,
      Heading,
      Icon,
      Txt,
    },
    props: {
      /**
       * Title of the card
       */
      title: {
        type: String,
        default: null,
      },
      /**
       * Icon on the left of the card
       */
      icon: {
        type: String,
        default: 'info',
      },
      /**
       * Description under the title
       */
      description: {
        type: String,
        default: null,
      },
      /**
       * Will change the background color / font color / border / color
       * @type {Theme}
       */
      theme: {
        type: String,
        default: Theme.PRIMARY,
      },
      /**
       * Will display the title in ellipsis
       */
      ellipsis: {
        type: Boolean,
        default: true,
      },
      /**
       * Display the description with the raw proper
       */
      raw: {
        type: Boolean,
        default: false,
      },
      /**
       * Title level
       */
      level: {
        type: String,
        default: Level.LEVEL5,
      },
    },
    computed: {
      /**
       * Theme of the Txt element according to the main theme
       * @type {String}
       */
      txtTheme() {
        const { theme } = this

        switch (theme) {
          case Theme.PRIMARY:
          case Theme.INFORMATION:
          case Theme.SUCCESS:
          case Theme.WARNING:
          case Theme.DANGER:
            return Txt.Theme.DEFAULT
          case Theme.INFORMATION_ALT:
          case Theme.INFORMATION_VOID:
            return Txt.Theme.PRIMARY
          case Theme.SUCCESS_ALT:
          case Theme.SUCCESS_VOID:
            return Txt.Theme.SUCCESS
          case Theme.WARNING_ALT:
          case Theme.WARNING_VOID:
            return Txt.Theme.WARNING
          case Theme.DANGER_ALT:
          case Theme.DANGER_VOID:
            return Txt.Theme.DANGER
          case Theme.YELLOW_ALT:
            return Txt.Theme.YELLOW_ALT
          default:
            return theme
        }
      },
      /**
       * CSS classes applied on the root element
       * @returns {Object.<Boolean>}
       */
      classes() {
        const { theme } = this

        return {
          [`informative-card--theme-${theme}`]: !!theme,
        }
      },
    },
    methods: {
      iconIsOld,
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_grid.styl'
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_variables.styl'

  .informative-card {
    display: flex
    flex-direction: row

    &-icon, i {
      flex-shrink: 0
      width: 32px
      height: 32px
      text-align: center
      line-height: 32px
      box-sizing: border-box
      border-radius: 100px
    }

    &-icon {
      display: flex
      align-items: center
      justify-content: center
      font-size: 14px
    }

    i {
      font-size: 22px
    }

    &-body {
      content-grid(8)
      grid-row-gap: 8px
      flex: 1
      margin-left: 16px
    }

    &-title {
      margin-top: 4px
    }

    &-title,
    &-description,
    &-content,
    &-footer {
      white-space: pre-line
    }

    &--theme {
      &-primary i, &-primary ^[0]-icon {
        background-color: var(--color-button-secondary)
        color: var(--color-font)
      }

      &-information i, &-information ^[0]-icon {
        background-color: var(--color-brand-transparent)
        color: var(--color-brand)
      }

      &-information-alt^[0]--skin-dark i, &-information-alt^[0]--skin-dark ^[0]-icon {
        background-color: var(--color-brand-transparent)
      }

      &-information-alt i, &-information-alt ^[0]-icon {
        background-color: var(--color-background)
        color: var(--color-brand)
      }

      &-success i, &-success ^[0]-icon {
        color: var(--color-positive)
        background-color: var(--color-positive-transparent)
      }

      &-success-alt i, &-success-alt ^[0]-icon {
        background-color: var(--color-background)
        color: var(--color-positive)
      }

      &-warning i, &-warning ^[0]-icon {
        background-color: var(--color-warning-transparent)
        color: var(--color-warning)
      }

      &-warning-alt i, &-warning-alt ^[0]-icon {
        background-color: var(--color-background)
        color: var(--color-warning)
      }

      &-danger i, &-danger ^[0]-icon {
        background-color: var(--color-negative-transparent)
        color: var(--color-negative)
      }

      &-danger-alt i, &-danger-alt ^[0]-icon {
        background-color: var(--color-background)
        color: var(--color-negative)
      }

      &-yellow i, &-yellow ^[0]-icon {
        background-color: var(--color-contrast-transparent)
        color: var(--color-contrast)
      }

      &-yellow-alt i, &-yellow-alt ^[0]-icon {
        background-color: var(--color-contrast-transparent)
        color: var(--color-contrast-opaque)
      }
    }
  }
</style>
