import { Auth, Signer } from 'aws-amplify'
import axios from 'axios'

export const createSignedRequest = ({
  method = 'GET',
  url,
  headers,
  data,
  credentials: { accessKeyId, secretAccessKey, sessionToken },
}) => {
  const realHeaders = {}
  for (const key in headers) {
    if (Object.prototype.hasOwnProperty.call(headers, key)) {
      const element = headers[key]
      if (typeof element === 'string') {
        realHeaders[key] = element
      }
    }
  }

  const request = {
    method: method.toUpperCase(),
    url: url,
    data: JSON.stringify(data),
    headers: realHeaders,
  }

  const serviceInfo = {
    service: 'execute-api',
    region: 'eu-west-3',
  }

  const accessInfo = {
    secret_key: secretAccessKey,
    access_key: accessKeyId,
    session_token: sessionToken,
  }

  const signed_params = Signer.sign(request, accessInfo, serviceInfo)
  if (signed_params.data) {
    signed_params.body = signed_params.data
  }
  delete signed_params.headers['host']
  return { ...headers, ...signed_params }
}

// Add a request interceptor
axios.interceptors.request.use(
  async config => {
    if (config) {
      const { url, method, headers, data } = config
      if (url && method && headers && url.startsWith('https://apipr.')) {
        const authInfo = await Auth.currentCredentials()
        const credentials = Auth.essentialCredentials(authInfo)
        const overrides = createSignedRequest({
          method,
          url,
          headers,
          data,
          credentials,
        })
        return { ...config, headers: overrides.headers }
      }
    }
    return config
  },
  async error => {
    throw error
  },
)
