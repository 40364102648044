var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.classes},[(_vm.$list)?_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$list.collapsable)?_c('VBtn',_vm._g({attrs:{"icon":"","small":"","color":"secondary"},on:{"click":_vm.onCollapsedPress}},on),[_c('VIcon',{class:_vm.$style.arrow,attrs:{"color":"secondary"},domProps:{"textContent":_vm._s('mdi-menu-right')}})],1):_vm._e()]}}],null,false,1762308944)},[_c('span',{domProps:{"textContent":_vm._s((
        _vm.$list.collapsed
          ? _vm.__('cp:c-kanban-header:action:expand')
          : _vm.__('cp:c-kanban-header:action:collapse')
      ))}})]):_vm._e(),(_vm.$slots.default)?[_vm._t("default")]:[_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.title},[_c('span',{staticClass:"subtitle-2 font-weight-semi-bold",domProps:{"textContent":_vm._s(_vm.title)}})]),(_vm.count >= 0)?_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({class:_vm.$style.count},on),[_c('span',[_c('span',{staticClass:"caption secondary--text mr-1",domProps:{"textContent":_vm._s(_vm.count)}}),_c('VIcon',{attrs:{"small":"","color":"secondary"},domProps:{"textContent":_vm._s('mdi-card-text-outline')}})],1)])]}}],null,false,854413333)},[_c('span',{domProps:{"textContent":_vm._s(_vm.countTooltip || _vm.__('cp:c-kanban-header:count:tooltip', _vm.count, { count: _vm.count }))}})]):_vm._e()],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }