import autosize from 'autosize'
import _get from 'lodash/get'

/**
 * Tiny plugin exposing exclusively a 'v-autosize' directive
 */
const VueAutoSize = {
  install(Vue) {
    /**
     * Vue directive to autosize textarea with 'v-autosize'
     *   - Stolen and simplified from https://github.com/mage3k/vue-autosize
     *
     * If the provided binding 'value' is falsy, except 'undefined', the
     * autosize is ignored. In every other cases, the autosize is made.
     */
    Vue.directive('autosize', {
      bind(el, binding) {
        const value = _get(binding, 'value', null)

        if (el.tagName === 'TEXTAREA' && (value || value === undefined)) {
          autosize(el)
        }
      },

      componentUpdated(el, binding) {
        const value = _get(binding, 'value', null)

        if (el.tagName === 'TEXTAREA' && (value || value === undefined)) {
          autosize.update(el)
        }
      },

      unbind(el) {
        autosize.destroy(el)
      },
    })
  },
}

export default VueAutoSize
