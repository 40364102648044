<template>
  <Field class="multiple-select-field" :label="label" :disabled="disabled" :error="error">
    <MultipleSelect
      :value="value"
      :options="options"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :icon="icon"
      :force-direction="forceDirection"
      :icon-side="iconSide"
      :theme="theme"
      :error="error"
      :reset-label="resetLabel"
      @input="onInput"
    />
  </Field>
</template>

<script>
  import Field from '@/core/layout/Field/Field'
  import MultipleSelect from '@/core/inputs/MultipleSelect/MultipleSelect'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
  MultipleSelect: MultipleSelect.__enums,
},

    name: 'MultipleSelectField',
    components: {
      Field,
      MultipleSelect,
    },
    props: {
      /**
       * Model value
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Available options
       * @type {Array.<Object>}
       */
      options: {
        type: Array,
        default: () => [],
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Field placeholder
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Name (used in HTTP query params)
       */
      name: {
        type: String,
        default: 'multiple-select',
      },
      /**
       * Icon display in the multiple select
       */
      icon: {
        type: String,
        default: 'chevron-bottom',
      },
      /**
       * To force direction : 'top' or 'bottom'
       */
      forceDirection: {
        type: String,
        default: null,
      },
      /**
       * Side of the icon: 'left' or 'right'
       */
      iconSide: {
        type: String,
        default: 'right',
      },
      /**
       * Theme, can be translucent|dark
       */
      theme: {
        type: String,
        default: null,
      },
      /**
       * Select error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * If there is a label, add a reset option
       */
      resetLabel: {
        type: String,
        default: null,
      },
    },
    methods: {
      onInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>
