<template>
  <VScaleTransition>
    <VCard
      v-show="forceShow || shown"
      outlined
      :class="$style.root"
      class="px-3 py-2"
    >
      <div class="mb-2 d-flex align-center">
        <VIcon
          small
          color="yellow darken-3"
          v-text="'mdi-crosshairs-question'"
        />
        <span class="ml-1 font-weight-medium" v-text="title" />
        <VChip
          outlined
          small
          class="mt-1 ml-2 grey--text"
        >
          <VIcon x-small v-text="'mdi-book-open-page-variant'" />
          <span class="ml-1" v-text="__('cp:team-member-tip:title')" />
        </VChip>
      </div>

      <div :class="$style.body">
        <slot v-if="$slots.body" name="body" />
        <span
          v-else
          class="body-2"
          v-text="body"
        />
      </div>

      <div v-if="withButtons" class="d-flex justify-space-between mt-4">
        <VBtn
          small
          class="ml-auto"
          color="yellow darken-1"
          @click="onClose"
        >
          <VIcon small v-text="'mdi-check'" />
          <span class="ml-1" v-text="__('cp:team-member-tip:got-it')" />
        </VBtn>
      </div>

      <div v-if="withButtons" :class="$style.closeButton">
        <VBtn
          small
          icon
          @click="onClose"
        >
          <VIcon small v-text="'mdi-close'" />
        </VBtn>
      </div>

      <Portal v-if="!shown" :to="portalName">
        <TeamMemberTooltipTrigger
          :name="name"
          :title="title"
          :body="body"
        />
      </Portal>
    </VCard>
  </VScaleTransition>
</template>

<script>
  import markTipAsRead from './markTipAsRead.gql'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
},

    name: 'TeamMemberTip',
    components: {
      TeamMemberTooltipTrigger: () => import('./TeamMemberTipTrigger'),
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      body: {
        type: String,
        default: null,
      },
      withButtons: {
        type: Boolean,
        default: true,
      },
      forceShow: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      shown() {
        const tips = this.$store.state.auth.teamMember?.tips || {}

        return !tips[this.name]
      },
      portalName() {
        return `team-member-tooltip:${this.name}`
      },
    },
    methods: {
      async onClose() {
        await this.$apollo.mutate({
          mutation: markTipAsRead,
          variables: {
            tip: this.name,
          },
        })
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    position: relative
    background-color: #ffffee !important
    border-left: 5px solid var(--v-warning-lighten1) !important
    white-space: pre-line

    .body {
      line-height: 1
    }

    .closeButton {
      position: absolute
      top: 4px
      right: 4px
    }
  }
</style>
