<template>
  <span
    :class="$style.root"
    class="text--pre-line"
    v-html="html"
  />
</template>

<script>
  import { computeBackofficeUrl } from '@comet/urls'

  const MENTION_REGEX = new RegExp('\\#\\{(\\w{1,2})\\|(\\d+)\\|([^}]+)\\}', 'g')
  const PHONE_REGEX = /(\+33\s?[1-9](\s?[1-9]{2}){4}|0[1-9]\s?(\s?[1-9]{2}){4})/g

  /**
   * Hashmap connecting the mention prefixes to the resource type patterns
   * used in the mention format stored in database.
   * (ex : "#{F|123|Jo Freelance}")
   * @type {Object}
   */
  const MENTION_PREFIXES = {
    F: '@',
    C: '@',
    T: '@',
    M: '#',
    CP: '#',
  }

  /**
   * Redirecting URLs according to the resource mentioned.
   * @type {Object}
   */
  const MENTION_URLS = {
    F: (id, name) => computeBackofficeUrl('freelancer', { freelancer: { id, user: {fullName: name} }}),
    C: (id, name) => computeBackofficeUrl('corporate', { corporate: { id, user: {fullName: name} }}),
    T: (id, name) => computeBackofficeUrl('team', { teamMember: { id, user: {fullName: name} }}),
    M: (id, name) => computeBackofficeUrl('mission', { mission: { id, title: name }}),
    CP: (id, name) => computeBackofficeUrl('company', { company: { id, name }}),
  }

  /**
   * Identity card tooltips according to the resource mentioned.
   * @type {Object}
   */
  const MENTION_TOOLTIPS = {
    F: 'freelancer',
    C: 'corporate',
    CP: 'company',
    T: 'teamMember',
    M: 'mission',
  }

  /**
   * Format the given phone number (issued from a regexp match) into an HTML link.
   * @param {String} phone
   * @returns {String}
   */
  function formatPhoneMatch(phone) {
    const condensed = phone.replace(/\s/g, '')

    return `<a \
      href="tel:${condensed}" \
      target="_blank" \
      rel="external noopener noreferrer"\
    >${phone}</a>`
  }

  /**
   * Format the given mention metadata (issued from a regexp match) into an HTML link.
   * @param {String} mention
   * @param {String} type
   * @param {String} id
   * @param {String} name
   * @returns {String}
   */
  function formatMentionMatch(mention, type, id, name) {
    const tooltip = MENTION_TOOLTIPS[type]
    const url = MENTION_URLS[type]?.(id, name)
    const label = name && name[0] === MENTION_PREFIXES[type]
      ? name.substring(1)
      : name
    const text = MENTION_PREFIXES[type] + label

    return `<a \
      data-tooltip="${tooltip}" \
      data-id="${id}"
      onclick="window.open('${url}', '_blank').focus()" \
      href="${url}" \
      target="_blank" \
      rel="external noopener noreferrer"\
    >${text}</a>`
  }

  export default {
    name: 'RichText',
    props: {
      /**
       * Text value that can contains mentions (with the specific format) and/or
       * links. These occurrences are translated into interactive links.
       * Ex : "See #{F|5|Yung Blud} in app.comet.co"
       * @type {String}
       */
      value: {
        type: String,
        default: '',
      },
    },
    computed: {
      /**
       * HTML version of the given 'value', replacing mentions and URLs
       * with appropriate links.
       * @type {String}
       */
      html() {
        const { value } = this

        // Emails and URLs are already transformed by the back-end code
        return value
          .replace(PHONE_REGEX, formatPhoneMatch)
          .replace(MENTION_REGEX, formatMentionMatch)
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    white-space: pre-line

    :global(a) {
      color: var(--v-anchor-base) !important
    }
  }
</style>
