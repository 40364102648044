<template>
  <ul :class="$style.expandableList">
    <Txt
      v-for="(val, i) in list"
      :key="i"
      tag="li"
      :value="val"
      v-bind="$attrs"
      :class="$style.element"
    />

    <Txt
      v-if="max && value.length > max + 1"
      :class="$style.button"
      :size="Txt.Size.XXXS"
      tag="button"
      :value="
        showAll
          ? __('cp:core:text:expandable-list:see-less')
          : __('cp:core:text:expandable-list:see-more')
      "
      @click.native="showAll = !showAll"
    />
  </ul>
</template>

<script>
  import _slice from 'lodash/slice'

  import Txt from '@/core/text/Txt/Txt'
  import I18nMixin from '@/mixins/I18nMixin'

  const { Size, Theme } = Txt

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,Theme,
    name: 'ExpandableList',
    __enums: {
      Size,
      Theme,
    },
    components: { Txt },
    mixins: [I18nMixin],
    inheritAttrs: false,
    props: {
      /**
       * List of element to display
       */
      value: {
        type: Array,
        required: true,
      },
      /**
       * Maximum amount of row we want to display before we crop
       * The rest of the list will be display wit
       */
      max: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Show all the list or not
         */
        showAll: false,
      }
    },
    computed: {
      /**
       * List to display according to the max props given and the showAll data
       * @type {Array.<String>}
       */
      list() {
        const { value, max, showAll } = this

        if (showAll || !max) {
          return value
        }

        return value.length === max + 1 ? value : _slice(value, 0, max)
      },
    },
  }
</script>

<style lang="stylus" module>
  .expandableList {
    & .element::before {
      content: "–"
      display: inline-block
      position: absolute
      margin-left: -16px
    }

    & .element {
      margin-left: 16px
      position: relative

      &:not(:last-child) {
        margin-bottom: 4px
      }
    }

    .button {
      border: none
      background: none
      padding: 0
      text-decoration: underline
      color: var(--color-brand)
    }
  }
</style>
