/**
 * Returns 'true' if the given 'parent' HTML element
 * contains, even deeply, the specified 'el' element.
 * @param {DOMElement} parent
 * @param {DOMElement} el
 * @returns {Boolean}
 */
function contains(parent, el) {
  let i = 0

  if (parent === el) {
    return true
  }

  if (parent.children) {
    for (i = 0; i < parent.children.length; i += 1) {
      if (contains(parent.children[i], el)) {
        return true
      }
    }
  }

  return false
}

/**
 * Initiate an HTML scrollTop on the element specified as 'ref'.
 * If 'ref' is a VueComponent, it applies the move on its root HTML element.
 * @param {Object} ref
 * @returns {void}
 */
function scrollTop(ref, duration, options) {
  // If 'ref' is a VueComponent and 'vue-scrollto' is installed
  if (ref && ref.$scrollTo) {
    ref.$scrollTo(ref, duration, options)
    return
  }

  let node = null

  if (!ref) {
    // There is no element specified
    node = document.documentElement || document.body
  } else if (ref.$el) {
    // 'ref' is a VueComponent, we get the root element
    node = ref.$el
  } else {
    node = ref
  }

  node.scrollTop = 0
}

function dispatchResize() {
  window.dispatchEvent(new Event('resize'))
}

/* eslint-disable import/prefer-default-export */
export { contains, scrollTop, dispatchResize }
