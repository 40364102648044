
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FreelancerNavBar"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"slackId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"profilePictureUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"unreadChatMessagesCount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"freelance"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":135}};
    doc.loc.source = {"body":"fragment FreelancerNavBar on User {\n  id\n  slackId\n  profilePictureUrl\n  unreadChatMessagesCount\n  freelance {\n    id\n    status\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
