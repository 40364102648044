<template>
  <div class="action-footer" :class="classes">
    <div :class="$('left')">
      <NavButton
        v-if="backParams"
        :size="backParams.size"
        :theme="backParams.theme"
        :left-icon="backParams.icon"
        :disabled="backParams.disabled"
        :on-click="backParams.onPress"
        :text="backParams.label"
      />
    </div>

    <div :class="$('right')">
      <slot v-if="$slots.right" name="right" />

      <NavButton
        v-if="secondaryParams"
        :size="secondaryParams.size"
        :theme="secondaryParams.theme"
        :disabled="secondaryParams.disabled"
        :on-click="secondaryParams.onPress"
        :text="secondaryParams.label"
        :secured="secondaryParams.secured"
        v-bind="secondaryParams.attributes"
      />

      <NavButton
        v-if="primaryParams.condition"
        :size="primaryParams.size"
        :theme="primaryParams.theme"
        :disabled="primaryParams.disabled"
        :on-click="primaryParams.onPress"
        :text="primaryParams.label"
        :secured="primaryParams.secured"
        v-bind="primaryParams.attributes"
      />
    </div>
  </div>
</template>

<script>
  import _isBoolean from 'lodash/isBoolean'
  import _isPlainObject from 'lodash/isPlainObject'
  import _isString from 'lodash/isString'
  import _omit from 'lodash/omit'

  import NavButton from '@/core/controls/NavButton/NavButton'

  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme:NavButton.Theme,
    name: 'ActionFooter',
    __enums: {
      Theme: NavButton.Theme,
    },
    components: {
      NavButton,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Label of the primary action
       */
      primary: {
        type: [Object, String],
        required: true,
      },
      /**
       * Label of the secondary action (optional)
       */
      secondary: {
        type: [Object, String],
        default: null,
      },
      /**
       * Display a "Back" button if set to "true"
       */
      back: {
        type: [Object, String, Boolean],
        default: null,
      },
      /**
       * Position explaing how the component should be placed
       * according to the window and its siblings.
       * Can be : "sticky", "fixed", "absolute" or "relative"
       */
      position: {
        type: String,
        required: true,
      },
    },
    computed: {
      /**
       * CSS classes applied on the root element
       * @type {Object.<Boolean>}
       */
      classes() {
        const { position } = this

        return {
          [`action-footer--${position}`]: !!position,
        }
      },
      /**
       * Parameters of the primary button computed from the 'primary' prop,
       * depending on its type (object or just a label), and the default
       * hard-coded values
       * @type {Object}
       */
      primaryParams() {
        const { primary } = this

        const defaultPrimary = {
          condition: true,
          label: null,
          size: 'medium',
          theme: 'primary',
          track: null,
          disabled: false,
          onPress: null,
          secured: false,
        }

        if (!primary) {
          return null
        }

        if (_isString(primary)) {
          return { ...defaultPrimary, label: primary }
        }

        if (_isPlainObject(primary)) {
          return {
            ...defaultPrimary,
            ...primary,
            attributes: _omit(primary, [
              'label',
              'size',
              'theme',
              'track',
              'disabled',
              'onPress',
              'secured',
            ]),
          }
        }

        return null
      },
      /**
       * Parameters of the secondary button computed from the 'secondary' prop,
       * depending on its type (object or just a label), and the default
       * hard-coded values
       * @type {Object}
       */
      secondaryParams() {
        const { secondary } = this

        const defaultSecondary = {
          label: null,
          size: 'medium',
          theme: 'secondary',
          track: null,
          disabled: false,
          onPress: null,
          secured: false,
        }

        if (!secondary) {
          return null
        }

        if (_isString(secondary)) {
          return { ...defaultSecondary, label: secondary }
        }

        if (_isPlainObject(secondary)) {
          return {
            ...defaultSecondary,
            ...secondary,
            attributes: _omit(secondary, [
              'label',
              'size',
              'theme',
              'track',
              'disabled',
              'onPress',
              'secured',
            ]),
          }
        }

        return null
      },
      /**
       * Parameters of the back button computed from the 'back' prop, depending
       * on its type (object, a label or just a flag), and the default
       * hard-coded values
       * @type {Object}
       */
      backParams() {
        const { back, __ } = this

        const defaultBack = {
          label: __('cp:layout:action-footer:back'),
          size: 'medium',
          theme: 'secondary',
          icon: 'icon-chevron-left',
          track: null,
          disabled: false,
          onPress: null,
        }

        if (!back) {
          return null
        }

        if (_isBoolean(back)) {
          return defaultBack
        }

        if (_isString(back)) {
          return { ...defaultBack, label: back }
        }

        if (_isPlainObject(back)) {
          return { ...defaultBack, ...back }
        }

        return null
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_variables.styl'

  .action-footer {
    width: 100%
    box-sizing: border-box
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 24px

    @media tablet-up {
      padding: 16px 48px 24px
      justify-content: space-between
    }

    @media web {
      margin: 0 auto
    }

    &--absolute,
    &--fixed,
    &--sticky {
      left: 0
      right: 0
      bottom: 0
      background: var(--color-background)
      box-shadow: inset 0px 0.5px 0px rgba(0, 0, 0, 0.15)
    }

    &--sticky {
      position: sticky
    }

    &--fixed {
      position: fixed
    }

    &--absolute {
      position: absolute
    }

    &--relative {
      padding: 0
      box-shadow: none
    }

    .nav-button:not(:last-child) {
      margin-right: 32px
    }

    &-right {
      display: flex
      flex-direction: row
      align-items: center
    }
  }
</style>
