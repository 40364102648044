<script>
  import {
    update,
    touch,
    resetValidation,
    reset,
    restart,
    destroy,
    isFieldDirty,
    getDirtyFields,
  } from './actions'
  import { created, beforeDestroy, destroyed } from './lifecycle'
  import { submit } from './submit'
  import getValidators from './validators'

  /**
   * Form configuration data  which must be overriden by every
   * components extending thin mixin.
   * @type {Object}
   */
  const FORM = {
    /**
     * Name used to identify the form (mainly for the
     * Vuex synchronization)
     * @type {String}
     */
    name: undefined,
    /**
     * If 'false', the form is kept in the store even after
     * leaving the view. Else, not.
     * @type {Boolean}
     */
    destroyOnUnmount: true,
    /**
     * If 'debounce' contains a non-nullable integer value,
     * the changes applied on fields are debounced with this
     * value used as delay.
     * @type {Number}
     */
    debounce: 0,
    /**
     * Form fields
     * @type {Object.<*>}
     */
    fields: {},
    /**
     * Initial values of every fields
     * @type {Object.<*>}
     */
    initial: {},
    /**
     * Is 'true' if all the fields with validators are actually
     * valid. Else, it is 'false'.
     * @type {Boolean}
     */
    valid: true,
    /**
     * Pure opposite of the 'valid' prop declared above
     * @type {Boolean}
     */
    invalid: false,
    /**
     * is 'true' if at least one form field has changed its value
     * @type {Boolean}
     */
    dirty: false,
    /**
     * is 'true' if at least one form field has been touched
     * @type {Boolean}
     */
    touched: false,
    /**
     * Is 'true' if the form might be submitted. Else, 'false'.
     * Means that the submit button might be accessible.
     * @type {Boolean}
     */
    submittable: false,
    /**
     * Is 'true' if the form is actually submitting its modification
     * remotely. Else 'false'.
     * @type {Boolean}
     */
    submitting: false,
    /**
     * Is 'true' if the form has ended submitting, whatever is the result.
     * @type {Boolean}
     */
    submitted: false,
    /**
     * Is 'true' if the form has ended submitting with failure.
     * @type {Boolean}
     */
    submitFailed: false,
    /**
     * Is 'true' if the form inputs must be disabled. Else, 'false'.
     * Means that all the form must be disabled, not only the submit button.
     * @type {Boolean}
     */
    disabled: false,
    /**
     * Add message on submit form
     * @type {Boolean}
     */
    notify: false,
    /**
     * Open an alert window if the user leaves the page with unsaved data
     * @type {Boolean}
     */
    alertOnLeave: false,
    /**
     * Override the default message of the alert window when
     * leaving with unsaved data
     * @type {String}
     */
    alertOnLeaveMessage: null,
    /**
     * Whether alert on leave should be triggered
     * @type {Function}
     * @returns {Boolean}
     */
    shouldAlertOnLeave: null,
    /**
     * Function to call (handling the submission) when the form is submitted.
     *
     * It's better to use the 'submit()' method by passing it an handling
     * function as unique parameter. But it could be usefull to specify a
     * default global handling function, especially when working with debounce.
     * @type {Function}
     */
    onSubmit: null,
    /**
     * Error messages for every fields
     * @type {Object.<String>}
     */
    errors: {},
    /**
     * CSS classes which should be applied for every fields
     * @type {Object}
     */
    classes: {},
    /**
     * Vuelidate validators to apply on fields
     * @type {Object}
     */
    validators: {},
    /**
     * Function called on every
     * @type {Object}
     */
    watch: null,
    /**
     * Watchers associated to a form field. Field as the key, watcher as the
     * value. It allows to watch only modification to a certain field.
     * @type {Object}
     */
    watchers: {},

    /**
     * Allows to modify some fields without triggering any watch or any
     * callbacks
     *
     * When updating a form with freshly received remote data, 'update' MUST be
     * used in order to keep your form pristine (i.e. not dirty)
     * @type {Function}
     */
    update: null,
    /**
     * Mark all the form as touched
     * @type {Function}
     */
    touch: null,
    /**
     * Returns 'true' if the given field has been already touched
     * @type {Function}
     */
    isFieldDirty: null,
    /**
     * Returns a subset of 'form.fields' including only the dirty fields
     * @type {Function}
     */
    getDirtyFields: null,
    /**
     * Reset all the form field values and the whole validation
     * @type {Function}
     */
    reset: null,
    /**
     * Reset only the validation metadata
     * @type {Function}
     */
    resetValidation: null,
    /**
     * Definitely destroy the form
     * @type {Function}
     */
    destroy: null,
  }

  export default {
    // Reactive Form
    data() {
      return {
        form: {
          ...FORM,
          submit: submit.bind(this),
          update: update.bind(this),
          touch: touch.bind(this),
          isFieldDirty: isFieldDirty.bind(this),
          getDirtyFields: getDirtyFields.bind(this),
          resetValidation: resetValidation.bind(this),
          reset: reset.bind(this),
          restart: restart.bind(this),
          destroy: destroy.bind(this),
        },
      }
    },
    // Lifecycle (placing watchers)
    created: created(FORM),
    beforeDestroy,
    destroyed,
    methods: {
      submit,
    },
    // Vuelidate
    validations() {
      return this.form
        ? {
            form: {
              fields: getValidators.call(this),
            },
          }
        : undefined
    },
  }
</script>
