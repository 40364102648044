/**
 * Format a given full address as string
 * @param {Object} address
 * @returns {String}
 */
function formatAddress(address) {
  let res = ''

  if (address) {
    const { street, city, zipCode, state, country, countryIso } = address

    if (street && street != city) {
      res += `${street}`
    }

    if (zipCode) {
      res += `${res ? ', ' : ''}${zipCode}`
    }

    if (city) {
      res += `${res ? ' ' : ''}${city}`
    }

    if (state && state != city) {
      res += `${res ? ', ' : ''}${state}`
    }

    if (country) {
      res += `${res ? ', ' : ''}${country?.name}`
    }

    if (!country && countryIso) {
      res += `${res ? ', ' : ''}${countryIso}`
    }
  }

  return res
}

/* eslint-disable import/prefer-default-export */
export { formatAddress }
