import { render, staticRenderFns } from "./CKanban.vue?vue&type=template&id=44f38a34&"
import script from "./CKanban.vue?vue&type=script&lang=js&"
export * from "./CKanban.vue?vue&type=script&lang=js&"
import style0 from "./CKanban.vue?vue&type=style&index=0&lang=stylus&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VFadeTransition,VIcon})
