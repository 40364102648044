import { idify } from '@/utils/transform'

/**
 * List of typenames which are provided without 'id'
 * @type {Object.<Boolean>}
 */
const RESOURCES_WITHOUT_ID = {}

/**
 * Used by Apollo to identify objects (and their uniqueness) and
 * handle its cache
 * (see http://dev.apollodata.com/react/cache-updates.html#normalization)
 * @param {Object} o
 * @returns {String}
 */
function dataIdFromObject(o) {
  const { __typename: typename, id, name } = o

  // Used for GraphQL types
  if (typename === '__Type') {
    return `type-${name}`
  }

  // Used for GraphQL enums
  if (typename === '__EnumValue') {
    return `enum-${name}`
  }

  // Handle the specific case of GraphQLCoordinates
  if (typename === 'GraphQLCoordinates') {
    return `coordinates-${idify(o.latitude)}#${idify(o.longitude)}`
  }

  // Specific case for FreelanceSkill, which must be requested with its
  // 'freelanceSkillId' field in order to be well stored and unique in the
  // Apollo cache  (not stored with the Skill id and potentially overriden)
  if (typename === 'FreelanceSkill') {
    if (!o.freelanceSkillId) {
      throw new Error(
        "A 'FreelanceSkill' resource must be absolutely requested with its 'freelanceSkillId' field.",
      )
    }

    return `${typename.toLowerCase()}-${o.freelanceSkillId}`
  }

  // Specific case for FreelanceExperienceSkill (see above)
  if (typename === 'FreelanceExperienceSkill') {
    if (!o.freelanceExperienceSkillId) {
      throw new Error(
        "A 'FreelanceExperienceSkill' resource must be absolutely requested with its 'freelanceExperienceSkillId' field.",
      )
    }

    return `${typename.toLowerCase()}-${o.freelanceExperienceSkillId}`
  }

  // Specific case for MissionSkill (see above)
  if (typename === 'MissionSkill') {
    if (!o.missionSkillId) {
      throw new Error(
        "A 'MissionSkill' resource must be absolutely requested with its 'missionSkillId' field.",
      )
    }

    return `${typename.toLowerCase()}-${o.missionSkillId}`
  }

  // Other entities with a typename
  if (typename) {
    if (!id) {
      if (!RESOURCES_WITHOUT_ID[typename]) {
        throw new Error(
          `A resource of type '${typename}' must be requested with at least its own 'id' to be well stored in the Apollo cache.`,
        )
      }

      return `${typename.toLowerCase()}`
    }

    return `${typename.toLowerCase()}-${id}`
  }

  // For all the rest ...
  return id
}

export default dataIdFromObject
