import { screenPhoneMax, screenTabletMax, screenLaptopMax, screenDesktopMax } from './config.json'

// Minimum sizes according to the device types
const screenTabletMin = screenPhoneMax + 1
const screenLaptopMin = screenTabletMax + 1
const screenDesktopMin = screenLaptopMax + 1
const screenLargeMin = screenDesktopMax + 1

// Private functions
const layoutMin = w => `(min-width: ${w}px)`

const layoutMax = w => `(max-width: ${w}px)`

const layoutMinMax = (minw, maxw) => `${layoutMin(minw)} and ${layoutMax(maxw)}`

const layoutScreenMin = w => `screen and ${layoutMin(w)}`

const layoutScreenMax = w => `screen and ${layoutMax(w)}`

const layoutScreenMinMax = (minw, maxw) => `screen and ${layoutMinMax(minw, maxw)}`

// Common media formats
const phone = layoutScreenMax(screenPhoneMax)
const tablet = layoutScreenMinMax(screenTabletMin, screenTabletMax)
const laptop = layoutScreenMinMax(screenLaptopMin, screenLaptopMax)
const desktop = layoutScreenMinMax(screenDesktopMin, screenDesktopMax)
const large = layoutScreenMin(screenLargeMin)

// Only phone + tablet
const phoneTablet = `${phone}, ${tablet}`

// Only tablet + laptop (i.e. intermediate formats)
const tabletLaptop = layoutScreenMinMax(screenTabletMin, screenLaptopMax)

// From tablet until inifinite (i.e. tablet + laptop + desktop + large)
const tabletUp = layoutScreenMin(screenTabletMin)

// All computer screens (i.e. laptop + desktop + large)
const laptopUp = layoutScreenMin(screenLaptopMin)

// Only large screens (i.e. desktop + large)
const desktopUp = layoutScreenMin(screenDesktopMin)

// All computers (i.e. laptop + desktop + large), same as "laptop-up"
const web = layoutScreenMin(screenLaptopMin)

export { screenPhoneMax, screenTabletMax, screenLaptopMax, screenDesktopMax }

export default {
  desktop,
  desktopUp,
  laptop,
  laptopUp,
  large,
  phone,
  phoneTablet,
  tablet,
  tabletLaptop,
  tabletUp,
  web,
}
