<template>
  <button
    class="checkbox-button"
    :class="classes"
    :disabled="disabled"
    type="button"
    @click="onClick"
  >
    <i :class="[$('icon'), icon]" />
    <span>
      {{ label }}
    </span>
  </button>
</template>

<script>
  export default {
    name: 'CheckboxButton',
    props: {
      /**
       * Label to display next to the checkbox
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Value that will be updated (also allow a initial value)
       */
      value: {
        type: Boolean,
        default: false,
      },
      /**
       * Allow to disable the checkbox
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Size of the button
       * Can be : "big", "medium" or "small"
       */
      size: {
        type: String,
        default: 'medium',
      },
      /**
       * The 'theme' describes how the button must look like
       * Can be :
       *    "primary",
       *    "light",
       */
      theme: {
        type: String,
        default: 'primary',
      },
      /**
       * Whether or not the button should be rounded
       */
      rounded: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { disabled, rounded, size, theme } = this

        return {
          [`checkbox-button--${theme}`]: !!theme,
          [`checkbox-button--${size}`]: !!size,
          'checkbox-button--disabled': !!disabled,
          'checkbox-button--rounded': !!rounded,
        }
      },
      /**
       * Dynamic icon CSS class according to value
       * @type {String}
       */
      icon() {
        const { value } = this

        return value ? 'icon-checkbox-on' : 'icon-checkbox-off'
      },
    },
    methods: {
      /**
       * Fired when the button is clicked
       * @returns {void}
       */
      onClick() {
        const { value } = this

        this.$emit('input', !value)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_variables.styl'

  .checkbox-button {
    display: flex
    justify-content: center
    align-items: center
    text-transform: capitalize
    border-radius: $radius-small
    line-height: initial
    font-weight: initial
    transition: all .2s ease
    border: none

    // Themes
    &&--primary {
      color: var(--color-font-contrast)
      background-color: var(--color-brand)
    }

    &&--light {
      color: var(--color-font-contrast)
      background-color: rgba(255,255,255, 0.08)

      &:not(:disabled):hover {
        background-color: var(--color-brand-highlight)
      }
    }

    // Sizes
    &&--big {
      height: 40px
      font-size: 14px
      padding: 0 10px

      .checkbox-button-icon {
        margin-right: 10px
        font-size: 14px
      }

    }

    &&--medium {
      height: 24px
      font-size: 12px
      padding: 0 10px

      .checkbox-button-icon {
        margin-right: 5px
        font-size: 12px
      }
    }

    &&--small {
      height: 20px
      font-size: 10px
      padding: 0 5px

      .checkbox-button-icon {
        margin-right: 5px
        font-size: 10px
      }
    }

    // Disabled
    &&--disabled {
      opacity: 0.7
      cursor: initial
    }

    // Rounded
    &&--rounded {
      border-radius: 35px
    }
  }
</style>
