/**
 * Structure types on a company
 */
const Structure = {
  START_UP: 'startUp',
  BIG_COMPANY: 'bigCompany',
  NONE: 'none',
}

/**
 * Legal forms a company could use
 */
const LegalForm = {
  SA: 'sa',
  SAS: 'sas',
  SARL: 'sarl',
  AE: 'ae',
  EI: 'ei',
  EIRL: 'eirl',
  EURL: 'eurl',
  SASU: 'sasu',
  SAU: 'sau',
  GIE: 'gie',
  SOCIETE_DROIT_ETRANGER: 'societeDroitEtranger',
  OTHER: 'other',
  NONE: 'none',
}

/**
 * Form under which the invoices could be transferred
 */
const InvoiceType = {
  EMAIL: 'email',
  PAPIER: 'papier',
  EMAIL_PAPIER: 'emailPapier',
  PLATFORM: 'platform',
}

/**
 * Enumeration of the different delays of payment
 */
const PaymentDelay = {
  NOW: 0,
  FOUR_WEEKS: 30,
  SIX_WEEKS: 45,
  HEIGHT_WEEKS: 60,
}

/**
 * Payment conditions available for the corporate
 */
const PaymentConditions = {
  UPON_RECEIPT: 'uponReceipt',
  END_OF_MONTH: 'endOfMonth',
  FIFTEENTH_OF_NEXT_MONTH: 'fifteenthOfNextMonth',
}

/**
 * Enumeration of possible company owner
 */
const CompanyOwner = {
  CORPORATE: 'corporate',
  FREELANCER: 'freelance',
}

/**
 * Enum of company activity
 * @type {Object}
 */
const CompanyActivity = {
  LIBERAL: 'liberal',
  COMMERCIAL: 'commercial',
}

const CompanyNeeds = {
  ONE_FOUR: 'oneToFour',
  FIVE_NINE: 'fiveToNine',
  TEN_TWENTYFOUR: 'tenToTwentyFour',
  TWENTYFIVE_FORTYNINE: 'twentyFiveToFortyNine',
  FIFTY_MORE: 'fiftyPlus',
}

const CompanySize = {
  NINE: 'nine',
  FORTY_NINE: 'fortyNine',
  NINETY_NINE: 'ninetyNine',
  TWO_HUNDRED_FORTY_NINE: 'twoHundredFortyNine',
  FOUR_HUNDRED_NINETY_NINE: 'fourHundredNinetyNine',
  NINE_HUNDRED_NINETY_NINE: 'nineHundredNinetyNine',
  FOUR_THOUSAND_NINE_HUNDRED_NINETY_NINE: 'fourThousandNineHundredNinetyNine',
  FIVE_THOUSANDS: 'fiveThousands',
}

const BillingMode = {
  MANUAL: 'manual',
  GOCARDLESS: 'gocardless',
}

/**
 * Used as a special value instead of Pandadoc template IDs
 */
const DO_NOT_GENERATE_DOCUMENT_KEY = 'DO_NOT_GENERATE'

const CompanyTypes = {
  CompanyActivity,
  CompanyOwner,
  BillingMode,
  Structure,
  LegalForm,
  InvoiceType,
  PaymentDelay,
  PaymentConditions,
  CompanyNeeds,
  CompanySize,
  DO_NOT_GENERATE_DOCUMENT_KEY,
}

export default CompanyTypes
