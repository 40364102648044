<template>
  <VFadeTransition>
    <div v-show="show" class="deep-orange accent-3" :class="$style.root">
      <VIcon :size="32" color="white" v-text="'mdi-flash-circle'" />
    </div>
  </VFadeTransition>
</template>

<script>
  const styleHMR = `
    padding: 2px 5px;
    background: linear-gradient(#666, #333);
    color: white;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;`

  const styleReloaded = `
    padding: 2px 5px;
    background: linear-gradient(#E65100, #EF6C00);
    color: white;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;`

  export default {
    name: 'HotReloadMonitor',
    data() {
      return {
        show: false,
      }
    },
    mounted() {
      if (window.monitoringHotReload) {
        return
      }

      module?.hot?.addStatusHandler(status => {
        if (status === 'check') {
          this.show = true
        } else if (status === 'apply') {
          console.info('%cHMR%c' + 'Reloaded', styleHMR, styleReloaded)
          setTimeout(() => {
            this.show = false
          }, 800)
        }
      })

      window.monitoringHotReload = true
    },
  }
</script>

<style lang="stylus" module>
  .root {
    position: fixed
    bottom: 8px
    left: 8px
    z-index: 100

    display: flex
    justify-content: center
    align-items: center

    height: 40px
    width: 40px

    border-radius: 50%
  }
</style>
