<!--
  This is just a wrapper to use Txt as a quote
  You can pass through it all the props defined in Txt
  Except `theme` and `type` which are pre-defined
-->
<template>
  <Txt v-bind="$attrs" :class="classes" :theme="textTheme" :type="type">
    <slot />
  </Txt>
</template>

<script>
  import Txt from '@/core/text/Txt/Txt'

  const { Align, Size, Type } = Txt

  export const Theme = {
    GREY: 'grey',
    WHITE: 'white',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Align,Size,Theme,Type,
    name: 'QuoteTxt',
    __enums: {
      Align,
      Size,
      Theme,
      Type,
    },
    components: {
      Txt,
    },
    inheritAttrs: false,
    // The props defined here are the one needing a default value
    // The others can be passed as attributes
    // so we don't need to re-define it here
    props: {
      theme: {
        type: String,
        default: Theme.GREY,
      },
      type: {
        type: String,
        default: Type.ITALIC,
      },
    },
    computed: {
      /**
       * CSS class applied to the quote element
       * @returns {Object.<Boolean>}
       */
      classes() {
        const { theme } = this

        return {
          'quote-txt': true,
          [`quote-txt--theme-${theme}`]: !!theme,
        }
      },
      /**
       * Compute Text theme depending on QuoteTxt Theme
       * @returns {String}
       */
      textTheme() {
        const { theme } = this

        switch (theme) {
          case Theme.WHITE:
            return Txt.Theme.WHITE

          default:
            return Txt.Theme.GREY
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .quote-txt {
    padding-left: 8px
    border-left: 3px solid var(--color-grey-40)
    white-space: pre-line

    &--theme {
      &-white {
        border-left-color: var(--color-grey-20)
      }
    }
  }
</style>
