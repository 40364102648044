<template>
  <Txt
    v-if="value"
    :size="Txt.Size.XS"
    :type="Txt.Type.NORMAL"
    v-bind="$attrs"
    :theme="theme"
    :class="[$style.txt, $style[theme]]"
    v-html="value"
  />
</template>

<script>
  import Txt from './Txt'

  const Theme = {
    PRIMARY: 'primary',
    WARNING: 'warning',
    SUCCESS: 'success',
    DANGER: 'danger',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme,
    name: 'HighlightTxt',
    __enums: {
      Theme,
    },
    components: {
      Txt,
    },
    inheritAttrs: false,
    props: {
      /**
       * Text value
       */
      value: {
        type: [String, Number],
        default: '',
      },
      /**
       * Theme applied to the Txt as well as the background
       */
      theme: {
        type: String,
        default: null,
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_colors.styl'
  @import '~@/assets/css/_variables.styl'
  @import '~@/assets/css/_transitions.styl'

  .txt {
    padding: 16px 18px 18px 18px
    border-radius: $radius-medium
    white-space: pre-line
  }

  .primary {
    background-color: var(--color-brand-transparent)
  }

  .success {
    background-color: var(--color-positive-transparent)
  }

  .warning {
    background-color: var(--color-warning-transparent)
  }

  .danger {
    background-color: var(--color-negative-transparent)
  }
</style>
