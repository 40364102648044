var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_vm._t("activator",null,{"disabled":_vm.active && _vm.showConfirm,"on":{ click: function (e) { return _vm.active && _vm.onButtonPress(e); } }}),(_vm.active)?_c('VDialog',{attrs:{"max-width":480,"persistent":_vm.persistent},model:{value:(_vm.showConfirm),callback:function ($$v) {_vm.showConfirm=$$v},expression:"showConfirm"}},[_c('VCard',{staticClass:"pa-2"},[_c('VCardTitle',{staticClass:"headline",class:(
          _vm.color &&
          _vm.color !== 'secondary' &&
          (_vm.color.split(' ').length === 1
            ? ((_vm.color.split(' ')[0]) + "--text")
            : ((_vm.color.split(' ')[0]) + "--text text--" + (_vm.color.split(' ')[1]))
          )
        ),domProps:{"textContent":_vm._s(_vm.title || _vm.__('cp:c-modal-confirm:title'))}}),_c('VCardText',{staticClass:"body-2 grey--text text--darken-3 text--pre-line mt-2",domProps:{"innerHTML":_vm._s(_vm.body || _vm.__('cp:c-modal-confirm:action:body'))}}),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{staticClass:"mx-2",attrs:{"text":"","color":"secondary"},domProps:{"textContent":_vm._s(_vm.cancelLabel || _vm.__('cp:c-modal-confirm:action:cancel'))},on:{"click":_vm.onCancelPress}}),_c('VBtn',{staticClass:"mx-2",attrs:{"text":"","color":_vm.color},domProps:{"textContent":_vm._s(_vm.confirmLabel || _vm.__('cp:c-modal-confirm:action:confirm'))},on:{"click":_vm.onConfirmPress}})],1)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }