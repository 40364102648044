import Vue from 'vue'

/* eslint-disable no-param-reassign */
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import moment from 'moment-timezone'

import http from '@/store/services/http'
import UserTypes from '@/types/user'
import CorporateType from '@/types/corporate'
import config from '@/config'

export const AWS_AMPLIFY_AUTH_STATUS_UPDATE = 'AWS_AMPLIFY_AUTH_STATUS_UPDATE'
export const AUTH_USER_REVOKE = 'AUTH_USER_REVOKE'
export const AUTH_USER_INFO_UPDATE = 'AUTH_USER_INFO_UPDATE'
export const AUTH_ANONYMIZED_UPDATE = 'AUTH_ANONYMIZED_UPDATE'

const { CORPORATE, FREELANCER, TEAM_MEMBER } = UserTypes.UserRole
const { ADMIN } = CorporateType.CorporateRole

/**
 * List of fields user fields defined by the backend
 * @type {Array}
 */
const USER_FIELDS = [
  'id',
  'email',
  'firstName',
  'lastName',
  'fullName',
  'phoneNumber',
  'slackId',
  'pendingActivation',
  'slackUsername',
  'permissions',
  'profilePictureUrl',
  'jobTitle',
  'termsValidatedAt',
  'termsValidated',
  'impersonating',
  'profilePictureUrl',
  'unreadChatMessagesCount',
  'mentionsViewedAt',
]

/**
 * Extract the data of the logged user
 * @param {Object} state
 * @returns {Object}
 */
function selectUser(state) {
  return _pick(state, USER_FIELDS)
}

const auth = {
  // ====================  STATE  ==================== //

  state: {
    awsAmplifyAuthStatus: null,
    // User info
    id: null,
    anonymized: false,
    email: null,
    firstName: null,
    lastName: null,
    fullName: null,
    permissions: {},
    profilePictureUrl: null,
    phoneNumber: null,
    slackId: null,
    slackUsername: null,
    jobTitle: null,
    termsValidatedAt: null,
    termsValidated: null,
    // Available roles
    corporate: null,
    freelancer: null,
    teamMember: null,
    // User impersonation
    impersonating: null,
  },

  // ====================  GETTERS  ==================== //

  getters: {
    /**
     * Return 'true' if the user is currently connected (i.e. a cookie
     * is saved in the store)
     * @param {Object} state
     * @returns {Boolean}
     */
    isAuthenticated: state => !!state.id,
    /**
     * Return 'true' if the user is a corporate
     * @param {Object} state
     * @returns {Boolean}
     */
    isCorporate: state => !!state.corporate && !state.anonymized,
    /**
     * Return 'true' if the user is a corporate with admin rights
     * @param {Object} state
     * @returns {Boolean}
     */
    isCorporateAdmin: state => _get(state, 'corporate.role') === ADMIN,
    /**
     * Return 'true' if the user is a freelance
     * @param {Object} state
     * @returns {Boolean}
     */
    isFreelancer: state => !!state.freelancer && !state.anonymized,
    /**
     * Return 'true' if the user is a Comet team member
     * @param {Object} state
     * @returns {Boolean}
     */
    isTeamMember: state => !!state.teamMember && !state.anonymized,
    /**
     * Return a function that returns 'true' if the user has the permission.s
     * @param {Object} state
     * @returns {Function}
     */
    hasPermission: state => (...permissions) => {
      // Grant all permissions to team members
      if (state.teamMember) {
        return true
      }

      // Param permissions can be: string, enum of strings, array of strings
      if (!state.permissions) {
        return false
      }

      const list = Array.isArray(permissions[0]) ? permissions[0] : permissions

      return list.every(permission => !!state.permissions[permission])
    },
    /**
     * Return the TeamMember 'id' of the user if it is actually one.
     * Else return 'null'.
     * @param {Object} state
     * @returns {Number}
     */
    selectTeamMemberId: state => _get(state, 'teamMember.id', null),
    /**
     * Return the Freelancer 'id' of the user if it is actually one.
     * Else return 'null'.
     * @param {Object} state
     * @returns {Number}
     */
    selectFreelancerId: state => _get(state, 'freelancer.id', null),
    /**
     * Return the Corporate 'id' of the user if it is actually one.
     * Else return 'null'.
     * @param {Object} state
     * @returns {Number}
     */
    selectCorporateId: state => _get(state, 'corporate.id', null),
    /**
     * Return the data about the user
     * @returns {Object}
     */
    selectUser: state => selectUser(state),
    /**
     * Return the corporate with user info inside
     * @returns {Object}
     */
    selectCorporate: state =>
      state.corporate
        ? {
            ...state.corporate,
            user: selectUser(state),
          }
        : null,
    /**
     * Return the freelancer with user info inside
     * @returns {Object}
     */
    selectFreelancer: (state, getters, rootState, rootGetters) => {
      const now = moment().add(rootGetters.selectAppTimeDiff, 'milliseconds')
      const availability = _get(state, 'freelancer.availabilityDate')

      return state.freelancer
        ? {
            ...state.freelancer,
            user: selectUser(state),
            available: moment(availability).diff(now, 'weeks') < 4,
          }
        : null
    },
    /**
     * Return the team member with user info inside
     * @returns {Object}
     */
    selectTeamMember: state =>
      state.teamMember
        ? {
            ...state.teamMember,
            user: selectUser(state),
          }
        : null,
    /**
     * Return the role associated to the current logged-in user
     * @param {Object} state
     * @returns {Array.<String>}
     */
    selectRole: state => {
      if (state.corporate) {
        return CORPORATE
      }

      if (state.freelancer) {
        return FREELANCER
      }

      if (state.teamMember) {
        return TEAM_MEMBER
      }

      return null
    },
    /**
     * True if the page must be anonymized
     * @param   {Object}  state
     * @returns {Boolean}
     */
    isAnonymized: state => state.anonymized,
  },

  // ====================  MUTATIONS  ==================== //

  mutations: {
    [AWS_AMPLIFY_AUTH_STATUS_UPDATE](state, status) {
      state.awsAmplifyAuthStatus = status
      console.info(window.location.href, status)
    },
    /**
     * Called on logout request
     */
    [AUTH_USER_REVOKE](state) {
      USER_FIELDS.forEach(field => {
        state[field] = null
      })
      state.corporate = null
      state.freelancer = null
      state.teamMember = null
    },
    /**
     * Update user informations from apollo
     */
    [AUTH_USER_INFO_UPDATE](state, info) {
      USER_FIELDS.forEach(field => {
        Vue.set(state, field, _get(info, field, state[field]))
      })

      if (info.freelance) {
        Vue.set(state, 'freelancer', info.freelance)
      }

      if (info.corporate) {
        Vue.set(state, 'corporate', info.corporate)
      }

      if (info.teamMember) {
        Vue.set(state, 'teamMember', info.teamMember)
      }
    },
    /**
     * Called to update anonymization
     */
    [AUTH_ANONYMIZED_UPDATE](state, anonymized) {
      Vue.set(state, 'anonymized', anonymized)
    },
  },

  // ====================  ACTIONS  ==================== //

  actions: {
    /**
     * Update the anonymization
     * @param   {Object} context
     * @param   {Boolean} anonymized
     * @returns {void}
     */
    updateAnonymization(context, anonymized) {
      const { commit } = context

      commit(AUTH_ANONYMIZED_UPDATE, anonymized)
    },
    /**
     * Logout the current user
     * @param {Object} context
     */
    revoke(context) {
      const { commit } = context

      commit(AUTH_USER_REVOKE)
    },
    /**
     * Update user informations from apollo
     * @param  {Object} context
     * @param  {Object} infos
     * @returns {void}
     */
    updateUserInfo(context, payload) {
      const { commit } = context

      commit(AUTH_USER_INFO_UPDATE, payload)
    },
    /**
     * Change the user's password
     * @param {Object} context
     * @returns {Promise}
     */
    changeUserPassword(context, { old, password, confirmation }) {
      return http(context, {
        types: [null, null, null],
        endpoint: `${config.urls.auth}/password/change`,
        authenticated: true,
        options: {
          method: 'POST',
          body: {
            oldPassword: old,
            newPassword: password,
            newPasswordConfirmation: confirmation,
          },
        },
        track: 'common.user.password.update',
        isPrivate: true,
      })
    },
    /**
     * Request a password reset token (sent by email)
     * @param {Object} context
     * @param {Object} email of the user
     * @returns {Promise}
     */
    requestNewPassword(context, { email }) {
      return http(context, {
        types: [null, null, null],
        endpoint: `${config.urls.auth}/password-reset`,
        authenticated: false,
        options: {
          method: 'POST',
          body: {
            email,
          },
        },
        track: 'common.user.password.request',
      })
    },
  },
}

export default auth
