import { __ } from '@comet/i18n'

import DealTypes from '@/types/deal'

const { DealDocumentType } = DealTypes

function formatDealDocumentType(key) {
  const trans = {
    [DealDocumentType.MISSION_STATEMENT]: __('ty:deal:deal-document-type:mission-statement'),
    [DealDocumentType.FRAMEWORK_CONTRACT]: __('ty:deal:deal-document-type:framework-contract'),
    [DealDocumentType.QUOTE]: __('ty:deal:deal-document-type:quote'),
    [DealDocumentType.PURCHASE_ORDER]: __('ty:deal:deal-document-type:purchase-order'),
    [DealDocumentType.INVOICING_SLIP]: __('ty:deal:deal-document-type:invoicing-slip'),
    [DealDocumentType.PROGRESS_REPORT]: __('ty:deal:deal-document-type:progress-report'),
    [DealDocumentType.RIDER]: __('ty:deal:deal-document-type:rider'),
    [DealDocumentType.MISSION_ORDER]: __('ty:deal:deal-document-type:mission-order'),
    [DealDocumentType.COMMITMENT_LETTER]: __('ty:deal:deal-document-type:commitment-letter'),
    [DealDocumentType.MAIL]: __('ty:deal:deal-document-type:mail'),
    [DealDocumentType.OTHER]: __('ty:deal:deal-document-type:other'),
  }

  return trans[key] || undefined
}

/* eslint-disable import/prefer-default-export */
export { formatDealDocumentType }
