<script>
  import { mapActions } from 'vuex'
  import _get from 'lodash/get'

  import AppTypes from '@/types/app'
  import { cleanObjectDeep } from '@/utils/transform/object'
  import { formatAppError } from '@/utils/format/app'

  // List of GraphQL errors we want to ignore when received in a successed
  // mutation because they don't impact the user
  const UNALLOWED_ERRORS = [AppTypes.AppError.FORBIDDEN]

  /**
   * Returns 'true' if the given GraphQL response message constains at least
   * one blocking error
   * @param {Object} response
   * @return {Boolean}
   */
  function containsAllowedErrors(response = {}, allowedErrors = []) {
    const { errors } = response
    let result = false

    if (!errors || !errors.length) {
      return false
    }

    errors.forEach(error => {
      const code = _get(error, 'extensions.code')

      if (!UNALLOWED_ERRORS.includes(code) || allowedErrors.includes(code)) {
        result = true
      }
    })

    return result
  }

  /**
   * MessageType - Enum contining the message types
   * @type {Object}
   */
  const { MessageType } = AppTypes

  export default {
    data() {
      return {
        /**
         * Mutation is in progress
         * @type {Boolean}
         */
        mutating: false,
        /**
         * Mutation is done
         * @type {Boolean}
         */
        mutated: false,
        /**
         * Mutation is fail
         * @type {Boolean}
         */
        mutateFailed: false,
        /**
         * Errors are stored here
         * @type {Object.<String>}
         */
        mutateErrors: {},
        /**
         * Active success message according to the "messages.success" option
         * passed to "mutate"
         * @type {Object.<String>}
         */
        mutateSuccess: null,
        /**
         * @type {Number} amount of query laoding
         */
        fetchingCount: 0,
      }
    },
    computed: {
      /**
       * @returns {Boolean} true if currently fetching a query, false otherwise
       */
      fetching() {
        const { fetchingCount } = this

        return !!fetchingCount
      },
    },
    apollo: {
      /**
       * Link to the data fetchingCount and cannot have '$' or '_' in the
       * beginning of the name
       */
      $loadingKey: 'fetchingCount',
    },
    methods: {
      ...mapActions({
        ApolloMixin_addMessage: 'addMessage',
      }),
      /**
       * Run a manual GraphQL query
       * @param {Object} options
       * @returns {Promise}
       */
      fetch(options) {
        const { $apollo } = this

        return $apollo.query({
          fetchPolicy: 'network-only',
          ...options,
        })
      },
      /**
       * Refetch the query identified by the specified 'name'.
       * This method is just a nicer way to access the $apollo query list.
       * @param {String} name
       * @param {Array.<*>} args
       * @returns {Promise}
       */
      refetch(name, ...args) {
        const query = _get(this, `$apollo.queries.${name}`)

        return query ? query.refetch(...args) : null
      },
      /**
       * Fetch more results for the query identified by the given `name`.
       * This method is just a nicer way to access the $apollo query list.
       * @param {String} name
       * @param {Object} options
       * @returns {Promise}
       */
      fetchMore(name, options = {}) {
        const query = _get(this, `$apollo.queries.${name}`)

        return query ? query.fetchMore(options) : null
      },
      /**
       * Generic function used to execute every mutation we need to do.
       * @param {Object} mutation
       * @param {Object.<*>} options
       * @returns {Promise}
       */
      mutate(mutation, options = {}) {
        const {
          $apollo,
          ApolloMixin_onMutateSuccess: onMutateSuccess,
          ApolloMixin_onMutateFailure: onMutateFailure,
        } = this

        if (!$apollo) {
          console.warn('ApolloMixin Error : No running Apollo instance found.')
          return Promise.reject()
        }

        // Change the mutation flags
        this.mutating = true
        this.mutateFailed = false
        this.mutateErrors = {}
        this.mutateSuccess = null

        const { variables, update, optimistic, refetchQueries, fetchPolicy } = options

        // Gather mutation parameters
        const params = {
          mutation,
          variables: cleanObjectDeep(variables),
          update,
          optimisticResponse: optimistic,
          refetchQueries,
          fetchPolicy,
        }

        return $apollo
          .mutate(params)
          .then(response => onMutateSuccess(response, options))
          .catch(error => onMutateFailure(error, options))
      },
      /**
       * Purge apollo store, including persisted store
       * @returns {Promise}
       */
      clearStore() {
        return this.$apollo.getClient().clearStore()
      },
      /**
       * Handler executed when the mutation ends with success.
       * It updates the mutation flags.
       * @param {Object} response
       * @param {Object} options
       * @returns {Object}
       */
      ApolloMixin_onMutateSuccess(response, options) {
        const { messages } = options

        // With our current config of ApolloClient, we can have a request that
        // succeed even with some part of the data missing. In this case,
        // we have graphql error but the mutation succeed.
        if (containsAllowedErrors(response, options.allowedErrors)) {
          throw response
        }

        this.mutating = false
        this.mutated = true
        this.mutateFailed = false
        this.mutateSuccess = null

        // If there is no blocking error and a custom success message is defined
        if (messages && messages.success) {
          if (messages.silent) {
            this.mutateSuccess = messages.success
          } else {
            this.ApolloMixin_addMessage({
              type: MessageType.SUCCESS,
              message: messages.success,
            })
          }
        }

        return response
      },
      /**
       * Handler executed when the mutation ends with failure.
       * It updates the mutation flags and rethrow the error.
       * @param {Object} error
       * @param {Object} options
       * @returns {void}
       */
      ApolloMixin_onMutateFailure(response, options) {
        const { ApolloMixin_trackMutate: trackMutate } = this
        const errors =
          _get(response, 'networkError.result.errors', null) || // Request errors (i.e. not HTTP 2xx)
          _get(response, 'errors', null) || // API errors in a successed request (i.e. HTTP 2xx)
          []
        const allowedErrors = _get(options, 'allowedErrors', [])
        const silent = _get(options, 'messages.silent', false)
        const mutateErrors = {}
        let firstError = null

        // Handle the received errors and populate the right props
        errors.forEach(error => {
          const code = _get(error, 'extensions.code')

          if (!UNALLOWED_ERRORS.includes(code) || allowedErrors.includes(code)) {
            // Populate the error messages
            mutateErrors[code] = formatAppError(code) || code

            // The silent option will inhibit global toasts on errors
            if (!silent) {
              this.ApolloMixin_addMessage({
                type: MessageType.ERROR,
                message: _get(options, 'messages.error') || formatAppError(code),
              })
            }

            // Keep the first blocking error to throw it at the end of the
            // method
            firstError = firstError || error
          }
        })

        this.mutating = false
        this.mutated = true
        this.mutateFailed = !!firstError

        // The object needs to be affected all at once, because
        // simply appending properties to it doesn't trigger a change
        // on the reactive variable's observer
        this.mutateErrors = mutateErrors

        if (firstError) {
          // Track the failure response
          trackMutate(options, false, true, firstError)
        }

        throw firstError
      },
      /**
       * Track the mutation request, success or failure (only if a tracking key
       * is provided).
       * @param {Object} options
       * @param {Boolean} pending
       * @param {Boolean} success
       * @returns {Object}
       */
      ApolloMixin_trackMutate() {},
    },
  }
</script>
