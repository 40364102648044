<template>
  <div
    v-if="!$list.collapsable || !$list.collapsed"
    :class="$style.root"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'CKanbanFooter',
    inject: [
      '$list',
    ],
  }
</script>

<style lang="stylus" module>
  .root {
    border-top: 1px solid var(--v-border-base)
    padding: 2px 8px 4px
    line-height: 1rem
    white-space: pre-line
  }
</style>
