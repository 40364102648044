<template>
  <form autocomplete="off" @submit.prevent>
    <VueGoogleAutocomplete
      :id="uuid"
      :key="uuid"
      :placeholder="placeholder"
      :country="iso2"
      :types="types"
      :value="formattedAddress"
      classname="form-control address-input"
      :class="classes"
      @placechanged="onPlaceChange"
      @inputChange="onInputChange"
    />

    <NavButton
      v-if="clearable && value"
      :class="$('clear')"
      icon="cross"
      :theme="NavButton.Theme.SECONDARY_VOID"
      :on-click="onClearPress"
    />
  </form>
</template>

<script>
  import _keyBy from 'lodash/keyBy'
  import _get from 'lodash/get'
  import gql from 'graphql-tag'

  import VueGoogleAutocomplete from 'vue-google-autocomplete'

  import NavButton from '@/core/controls/NavButton/NavButton'

  import { uuid } from '@/utils/app'
  import { formatAddress } from '@/utils/format'

  import query from './query.gql'

  export const ADDRESS_INPUT_FRAGMENT = gql`
    fragment AddressInput on Address {
      id
      street
      zipCode
      city
      state
      country {
        id
        iso2
        iso3
        name
      }
      point {
        latitude
        longitude
      }
    }
  `

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  VueGoogleAutocomplete: VueGoogleAutocomplete.__enums,
  NavButton: NavButton.__enums,
},

    name: 'AddressInput',
    components: {
      VueGoogleAutocomplete,
      NavButton,
    },
    props: {
      /**
       * Address input value
       */
      value: {
        type: Object,
        default: undefined,
      },
      /**
       * Placeholder text (displayed when empty)
       */
      placeholder: {
        type: String,
        default: 'Rechercher',
      },
      /**
       * If true, the field can be cleared
       */
      clearable: {
        type: Boolean,
        default: false,
      },
      /**
       * Expect a ISO3 country code. If set, the input is expecting a region
       * instead of a full address
       */
      countryCode: {
        type: String,
        default: null,
      },
      /**
       * Props telling the error in the input if there is one
       */
      error: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        /**
         * Countries by iso3 (fetched by Apollo)
         * @type {Object.<Object>}
         */
        countriesByIso3: [],
        /**
         * countries by iso2 (fetched by apollo)
         * @type {object.<object>}
         */
        countriesByIso2: [],
      }
    },
    computed: {
      /**
       * get iso2 country code according to iso3 country code passed in props
       * @type {String}
       */
      iso2() {
        const { countriesByIso3, countryCode } = this

        return _get(countriesByIso3[countryCode], 'iso2')
      },
      /**
       * Set the types to regions if a country code is provided
       * @type {String}
       */
      types() {
        const { iso2 } = this

        if (iso2) {
          return '(regions)'
        }

        return null
      },
      /**
       * Format the address
       * @type {String}
       */
      formattedAddress() {
        const { value } = this

        return formatAddress(value)
      },
      /**
       * Classes applyed to the root of the component
       * @type {Object.<Boolean>}
       */
      classes() {
        const { error } = this

        return {
          'address-input--invalid': !!error,
        }
      },
    },
    apollo: {
      countriesByIso3: {
        query,
        update(data) {
          return _keyBy(data.countries, 'iso3')
        },
      },
      countriesByIso2: {
        query,
        update(data) {
          return _keyBy(data.countries, 'iso2')
        },
      },
    },
    watch: {
      value() {
        this.uuid = uuid()
      },
      countryCode() {
        this.uuid = uuid()
      },
    },
    created() {
      // A unique ID passed to the VueGoogleAutocomplete component to avoid
      // potential conflicts between 2 addresses on the same page
      this.uuid = uuid()
    },
    methods: {
      /**
       * Extract iso2 from google place api result, then convert it to iso3
       * @param {Array.<Object>} addressComponents
       * @returns {String}
       */
      getCountryIso(addressComponents) {
        const country = addressComponents.find(a => a.types.includes('country'))

        return _get(country, 'short_name')
      },
      /**
       * Clear the input
       */
      onClearPress() {
        this.$emit('input', null)
      },
      /**
       * Called an address is picked from the 'vue-google-autocomplete' input
       * @param {Object} address
       * @param {Object} res
       * @returns {void}
       */
      onPlaceChange(address, res) {
        const { countriesByIso2, getCountryIso } = this

        const iso2 = getCountryIso(res.address_components) || null
        const iso3 = _get(countriesByIso2, `[${iso2}].iso3`)

        const {
          locality,
          postal_code: postalCode,
          administrative_area_level_1: administrativeArea,
          street_number: streetNumber,
          route,
          latitude,
          longitude,
          country,
        } = address

        const newValue = {
          street: null,
          city: locality || null,
          zipCode: postalCode || null,
          state: administrativeArea || null,
          country: {
            id: iso3,
            iso2,
            iso3,
            name: country,
          },
          point: {
            latitude: parseFloat(latitude.toFixed(6)),
            longitude: parseFloat(longitude.toFixed(6)),
          },
        }

        if (route) {
          if (streetNumber) {
            newValue.street = `${streetNumber} ${route}`
          } else {
            newValue.street = route
          }
        }

        this.$emit('input', newValue)
      },
      /**
       * Called everytime the input is modified with a unique parameter
       * containing both 'newVal' and 'oldVal'
       * @param {Object} values
       * @returns {void}
       */
      onInputChange(values) {
        if (values && values.oldVal && !values.newVal) {
          this.$emit('input', null)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .address-input {
    &-clear {
      position: absolute
      right: 8px
      top: 25px
      height: 32px
      background-color: var(--color-background)
    }
  }

  input.address-input {
    border: solid var(--color-input-border) 1px
    border-radius: $radius-medium
    box-shadow: none
    box-sizing: border-box
    caret-color: var(--color-input-highlight)
    font-family: $font
    font-size: 16px
    font-weight: $font-regular
    height: 40px
    padding: 0 16px
    width: 100%

    &:disabled {
      opacity: 0.32
    }

    &:not(:disabled):focus,
    &:not(:disabled):hover {
      box-shadow: none
      border: solid var(--color-input-highlight) 1px
    }

    &--invalid {
      border-color: var(--color-input-error)
    }

    &:placeholder-shown:not(:hover):not(:focus) {
      border-color: var(--color-input-background-idle)
    }

    ::placeholder {
      color: var(--color-input-placeholder)
    }
  }
</style>
