<!--eslint-disable max-len, vue/max-attributes-per-line-->
<template>
  <svg viewBox="0 0 120 120" fill="none" class="ghost">
    <g class="ghost-body">
      <path
        d="M36.0121 46.024C36.0121 33.4374 47.0204 24 60.0042 24C73.5637 24 84 33.8389 84 47.0251V80.067C84.0001 88.5703 78.0011 80.061 78.0011 80.061C78.0011 80.061 74.0018 75.0556 71.0024 81.0621C67.7014 87.6723 64.0036 81.0621 64.0036 81.0621C64.0036 81.0621 59.9644 74.669 56.005 81.0669C51.9754 87.5783 49.0062 81.0669 49.0062 81.0669C49.0062 81.0669 45.1978 75.22 41.0076 80.061C35.5087 88.0698 36.0121 81.0669 36.0121 81.0669V46.024Z"
        fill="#DDDDDD"
      />
      <g ref="eye" class="ghost-eye" :style="eyeStyle">
        <circle cx="69" cy="49" r="7" fill="white" />
        <circle cx="69" cy="46" r="3" fill="#888888" />
      </g>
      <g class="ghost-eye" :style="eyeStyle">
        <circle cx="53" cy="49" r="7" fill="white" />
        <circle cx="53" cy="46" r="3" fill="#888888" />
      </g>
    </g>
    <ellipse class="ghost-shadow" opacity="0.32" cx="60" cy="100" rx="20" ry="4" fill="#CCCCCC" />
  </svg>
</template>

<script>
  export default {
    name: 'Ghost',
    data() {
      return {
        /**
         * Style applied on the eyes of the animated ghost
         * @type {Object}
         */
        eyeStyle: null,
      }
    },
    computed: {
      /**
       * Need to define a compute to properly add and remove event listener
       * @type {Function}
       */
      onMouseMoveBind() {
        return this.onMouseMove.bind(this)
      },
    },
    mounted() {
      document.addEventListener('mousemove', this.onMouseMoveBind)
    },
    destroyed() {
      document.removeEventListener('mousemove', this.onMouseMoveBind)
    },
    methods: {
      /**
       * Compute the position of the mouse
       * @type {Object}
       */
      onMouseMove(event) {
        const { height, left, top, width } = this.$refs.eye.getBoundingClientRect()

        const x = left + width / 2
        const y = top + height / 2

        const rad = Math.atan2(event.pageX - x, event.pageY - y)
        const rotation = rad * (180 / Math.PI) * -1 + 180

        this.eyeStyle = {
          transform: `rotate(${rotation}deg)`,
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .ghost {
    display: block
    width: 160px
    height: 160px

    &-eye {
      transform-origin: 69px 49px

      &:last-child {
        transform-origin: 53px 49px
      }
    }

    &-body {
      animation: float .4s infinite alternate ease-in
    }

    &-shadow {
      animation: shadow .4s infinite alternate ease-in
      transform-origin: 50% 50%
    }
  }

  @keyframes float {
    0% {
      transform: none
    }
    100% {
      transform: translateY(3%)
    }
  }

  @keyframes shadow {
    0% {
      transform: none
    }
    100% {
      transform: scaleX(.85)
    }
  }
</style>
