<template>
  <div :class="$style.picker">
    <button
      v-for="(button, index) in buttons"
      :key="button.id"
      type="button"
      :class="button.classes"
      @click="onOptionPress(button.id, index)"
    >
      <Txt :value="button.name" :inline="true" :class="$style.name" />
      <Txt
        v-if="button.suffix"
        :value="`(${button.suffix})`"
        :inline="true"
        :class="$style.suffix"
      />
    </button>
  </div>
</template>

<script>
  import _get from 'lodash/get'

  import Txt from '@/core/text/Txt/Txt'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

    name: 'Picker',
    components: {
      Txt,
    },
    props: {
      /**
       * Current selected value. Should match an options' "id".
       */
      value: {
        type: [String, Number],
        default: null,
      },
      /**
       * List of the available values (as object : { id, name, suffix })
       * @type {Array.<Object>}
       */
      options: {
        type: Array,
        required: true,
      },
    },
    computed: {
      /**
       * Formatted data extracted from 'options' allowing to display buttons
       * @type {Array.<Object>}
       */
      buttons() {
        const { options, value, $style } = this

        if (!options || !options.length) {
          return []
        }

        return options.map(o => ({
          ...o,
          classes: {
            [$style.option]: true,
            [$style.selected]: _get(o, 'id') === value,
          },
        }))
      },
    },
    methods: {
      /**
       * When the user click on one of the buttons
       * @param {Object} option one of object given in props
       * @param {Number} index index of the object in the list of options
       */
      onOptionPress(id, index) {
        this.$emit('input', id, index)
      },
    },
  }
</script>

<style lang="stylus" module>

  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .picker {
    display: flex
    flex-wrap: wrap

    .option {
      ellipsis()
      margin: 4px
      padding: 8px 16px
      background-color: transparent
      border: 1px solid var(--color-input-border)
      border-radius: 18px
      font-family: inherit
      font-weight: $font-regular
      font-size: 14px
      text-transform: none
      line-height: 0
      box-sizing: border-box
    }

    .selected {
      background-color: var(--color-input-highlight)
      border: 1px solid var(--color-input-highlight)

      .name {
        color: var(--color-font-contrast)
      }

      .suffix {
        color: var(--color-font-contrast-light)
      }
    }

    .suffix {
      color: var(--color-font-light)
    }
  }
</style>
