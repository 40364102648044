<template>
  <div class="progress-bar" :class="classes">
    <svg width="100%" height="100%">
      <defs>
        <pattern
          id="gradient"
          width="18"
          height="15"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <line stroke="rgb(204, 204, 204)" stroke-width="15" width="20" y2="15" />
        </pattern>
      </defs>

      <rect
        id="progress-reference"
        :class="progressReferenceClasses"
        :rx="cornerRadius"
        :ry="cornerRadius"
        width="100%"
        height="100%"
      />
      <rect
        id="progress-value"
        :class="progressValueClasses"
        :rx="cornerRadius"
        :ry="cornerRadius"
        :width="`${widthProgress}%`"
        :x="offset"
        height="100%"
      />
    </svg>

    <div :class="$('labels')">
      <div v-for="(label, index) in labels" :key="index" :class="getLabelClass(index)">
        <span v-if="_isString(label)" v-text="label" />
        <span v-else :class="{ negative: label.negative }" v-text="label.text" />
      </div>
    </div>
  </div>
</template>

<script>
  import _isString from 'lodash/isString'

  const Size = {
    SMALLER: 'smaller',
    SMALL: 'small',
    MEDIUM: 'medium',
    BIG: 'big',
  }

  const Color = {
    TRANSPARENT: 'transparent',
    WHITE: 'white',
    BLUE: 'blue',
    GREY_LIGHT: 'grey-light',
    GREY_MEDIUM: 'grey-medium',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Size,Color,
    name: 'ProgressBar',
    __enums: {
      Size,
      Color,
    },
    props: {
      /**
       * Minimum value
       */
      min: {
        type: Number,
        default: 0,
      },
      /**
       * Maximum value
       */
      max: {
        type: Number,
        default: 100,
      },
      /**
       * Label custom size
       * Can be: 'smaller', 'small', 'medium' or 'big'
       */
      size: {
        type: String,
        default: Size.MEDIUM,
      },
      /**
       * Theme to be applied to the progress bar
       * Can be:
       *  'normal' bordered
       *  'filled' without borders, gap will be filled with linear gradient
       */
      theme: {
        type: String,
        default: 'bordered',
      },
      /**
       * Whether or not the progress bar should be revered
       * Usefull when trying to express a countdown
       */
      reverse: {
        type: Boolean,
        default: false,
      },
      /**
       * Current value
       */
      value: {
        type: Number,
        required: true,
      },
      /**
       * Whether the progress value should be displayed in red
       */
      warn: {
        type: Boolean,
        default: false,
      },
      /**
       * labels displayed in the progress bar from minimum to maximum
       * @type {array.<string>}
       */
      labels: {
        type: Array,
        default: null,
      },
      /**
       * Bar value color
       * @type {String}
       */
      valueColor: {
        type: String,
        default: Color.BLUE,
      },
      /**
       * Bar reference color
       * @type {String}
       */
      referenceColor: {
        type: String,
        default: Color.TRANSPARENT,
      },
    },
    computed: {
      /**
       * Dynamically computes css classes according to props
       * @type {Object}
       */
      classes() {
        const { size, theme, warn } = this

        return {
          [`progress-bar--${size}`]: !!size,
          [`progress-bar--${theme}`]: !!theme,
          'progress-bar--warn': !!warn,
        }
      },
      /**
       * Rect corner radius according to size property
       * @type {Number}
       */
      cornerRadius() {
        const { size } = this
        const { SMALLER, SMALL, BIG } = Size

        switch (size) {
          case SMALLER:
            return 4
          case SMALL:
            return 6
          case BIG:
            return 12
          default:
            return 8
        }
      },
      /**
       * By how much the progress value bar should be offset
       * @type {String}
       */
      offset() {
        const { reverse, widthProgress } = this

        if (reverse) {
          return `${100 - widthProgress}%`
        }

        return null
      },
      /**
       * Width of the "selected" track
       * @type {Number}
       */
      widthProgress() {
        const { min, max, value } = this

        const val = Math.max(min, Math.min(value, max))
        const total = max - min

        return total > 0 ? ((val - min) * 100) / total : 0
      },
      /**
       * Bar value classes
       * @type {Object}
       */
      progressValueClasses() {
        const { valueColor } = this

        return {
          [`progress-bar-value--color-${valueColor}`]: !!valueColor,
        }
      },
      /**
       * Bar reference classes
       * @type {Object}
       */
      progressReferenceClasses() {
        const { referenceColor } = this

        return {
          [`progress-bar-reference--color-${referenceColor}`]: !!referenceColor,
        }
      },
    },
    methods: {
      _isString,
      /**
       * Returns the CSS classes related to a label regarding the specified
       * 'index'
       * @param {Number} index
       * @returns {Object.<Boolean>}
       */
      getLabelClass(index) {
        const { value, max, labels } = this

        const labelPosition = ((index + 1) * 100) / max
        const currentPosition = (value * 100) / max

        return {
          // Whether or not a label is alone
          'progress-bar-label--alone': labels.length === 1,
          // Whether or not a label should be white
          'progress-bar-label--white': labelPosition <= currentPosition,
        }
      },
    },
  }
</script>

<style lang="stylus">

  @import '~@/assets/css/_variables.styl'

  .progress-bar {
    position: relative
    height: 16px

    svg {
      position: absolute
      box-sizing: border-box
      overflow: visible
      left: 0

      #progress-reference {
        stroke-width: 1
      }
    }

    &&--warn {
      #progress-value {
        fill: var(--color-negative)
      }
    }

    &&--filled {
      background-color: rgb(221, 221, 221)
      border-radius: $radius-large
      &&.big { border-radius: 12px }
      &&.small { border-radius: $radius-medium }
      &&.smaller { border-radius: $radius-small }

      #progress-reference {
        fill: url(#gradient)
        stroke: none
      }
    }

    &&--big {
      height: 25px
    }

    &&--small {
      height: 12px
    }

    &&--smaller {
      height: 8px
    }

    & &-labels {
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      padding: 0 10px

      .negative {
        color: var(--color-negative)
        text-decoration: line-through
      }

      > div {
        color: var(--color-brand)
        font-weight: $font-regular
        font-size: 12px

        &.progress-bar-label--alone {
          flex: 1
          text-align: center
        }

        &.progress-bar-label--white {
          color: var(--color-white)
        }

      }
    }

    // Progress bar value color
    & &-value {
      &--color-blue {
        fill: var(--color-brand)
      }

      &--color-white {
        fill: var(--color-white)
      }

      &--color-grey-light {
        fill: var(--color-grey-20)
      }

      &--color-grey-medium {
        fill: var(--color-grey-60)
      }
    }

    // Progress reference value color
    & &-reference {
      &--color-transparent {
        fill: transparent
        stroke: var(--color-brand)
      }

      &--color-blue {
        fill: var(--color-brand-opaque)
        stroke: var(--color-brand-opaque)
      }

      &--color-grey-light {
        fill: var(--color-grey-40)
      }

      &--color-grey-medium {
        fill: var(--color-grey-60)
      }
    }
  }
</style>
