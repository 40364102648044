<template>
  <div class="image-uploader" :style="imageStyle" @mouseover.self="toggleOverlay">
    <div v-if="uploading || displayOverlay" :class="$('overlay')" @mouseout="toggleOverlay">
      <Spinner v-if="uploading" />

      <span v-else :class="$('label')">
        {{ label || __('cp:layout:image-uploader:default-label') }}
      </span>

      <input
        :class="$('input')"
        type="file"
        accept="image/jpeg,image/png,image/jpg"
        :disabled="uploading"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import Spinner from '@/core/controls/Spinner/Spinner'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import I18nMixin from '@/mixins/I18nMixin'

  import AppTypes from '@/types/app'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Spinner: Spinner.__enums,
},

    name: 'ImageUploader',
    components: {
      Spinner,
    },
    mixins: [ApolloMixin, I18nMixin],
    props: {
      /**
       * Url of the image to display
       */
      image: {
        type: String,
        default: null,
      },
      /**
       * Label that will be displayed on the overdlay
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Maximum size of the file in bytes
       */
      maxSize: {
        type: Number,
        required: true,
      },
      /**
       * Function that should tbe called to upload the file
       */
      onUpload: {
        type: Function,
        required: true,
      },
      /*
       * Error message displayed in the toast in case of size error
       */
      sizeErrorText: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        /**
         * Will be set to true on hover
         * @type {Boolean}
         */
        displayOverlay: false,
        /**
         * True if user is uploading a file
         * @type {Boolean}
         */
        uploading: false,
      }
    },
    computed: {
      /**
       * Style to display the image
       * @type {String}
       */
      imageStyle() {
        const { image } = this

        return image ? { 'background-image': `url('${image}')` } : undefined
      },
    },
    methods: {
      ...mapActions(['addMessage']),
      /**
       * Toggle overlay property
       * @returns {void}
       */
      toggleOverlay() {
        const { displayOverlay } = this

        this.displayOverlay = !displayOverlay
      },
      /**
       * Triggered on image upload
       * Pass along the file to the onUpload handler
       * if the image is smaller than maxSize
       * @param {Event} event
       * @returns {void}
       */
      onChange(event) {
        const { onUpload, maxSize, addMessage, sizeErrorText, populateMutationErrors } = this

        const [file] = event.target.files

        if (file) {
          if (file.size > maxSize) {
            this.displayOverlay = false

            addMessage({
              type: AppTypes.MessageType.ERROR,
              message: sizeErrorText,
            })
          } else {
            this.uploading = true
            onUpload(file)
              .then(() => {
                this.uploading = false
              })
              .catch(e => {
                const { graphQLErrors = [] } = e

                this.uploading = false
                populateMutationErrors(graphQLErrors)
              })
          }
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>


  .image-uploader {
    position: relative

    .spinner {
      width: 100%
      height: 100%
      display: flex
      align-items: center
    }

    &-overlay {
      background-color: var(--color-background-overlay)
      color: var(--color-font-contrast)
      display: flex
      align-items: center
      text-align: center
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
    }

    &-label {
      margin: 10px
    }

    &-input {
      cursor: pointer
      height: 100%
      width: 100%
      position: absolute
      opacity: 0
    }
  }
</style>
