/**
 * Interview qualification status
 */
const InterviewQualificationStatus = {
  NOT_QUALIFIED: 'notQualified',
  CANCELLED_BY_CANDIDATE: 'cancelledByCandidate',
  CANCELLED_BY_INSTRUCTOR: 'cancelledByInstructor',
  CANCELLED_BY_TM: 'cancelledByTM',
  IS_INSTRUCTOR: 'isInstructor',
  PENDING: 'pending',
  FLAGGED_NORMAL: 'flaggedNormal',
  FLAGGED_URGENT: 'flaggedUrgent',
  FLAGGED_SELF: 'flaggedSelf',
  TO_REVIEW: 'toReview',
  FROZEN: 'frozen',
  QUALIFIED: 'qualified',
}

/**
 * Interview approval rating
 */
const InterviewApprovalRating = {
  STRONG_NO: 'strongNo',
  NO: 'no',
  YES: 'yes',
  STRONG_YES: 'strongYes',
}

/**
 * Interview expertise level
 */
const InterviewExpertiseLevel = {
  POOR: 'poor',
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  EXPERT: 'expert',
}

/**
 * Interview technical knowledge
 */
const InterviewTechnicalKnowledge = {
  SPECIALIZED: 'specialized',
  LIMITED: 'limited',
  POLYVALENT: 'polyvalent',
  JACK_OF_ALL_TRADES: 'jackOfAllTrades',
}

/**
 * Interview no show role
 */
const InterviewNoShow = {
  CANDIDATE: 'candidate',
  INSTRUCTOR: 'instructor',
}

/**
 * Interview no show role
 */
const InterviewDoubtCriteria = {
  EXPERTISE_LEVEL: 'expertiseLevel',
  KNOWLEDGE: 'knowledge',
  COMMUNICATION: 'communication',
  STRICTNESS: 'strictness',
  AUTONOMY: 'autonomy',
  PROFESSIONALISM: 'professionalism',
}

/**
 * Root FreelancerTypes object
 */
const QualificationTypes = {
  InterviewApprovalRating,
  InterviewDoubtCriteria,
  InterviewExpertiseLevel,
  InterviewTechnicalKnowledge,
  InterviewNoShow,
  InterviewQualificationStatus,
}

export default QualificationTypes
