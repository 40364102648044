<template>
  <div :class="classes" class="color-marker">
    <div
      :class="[
        $('indicator'),
        circle ? $('circle') : $('rect'),
        filled ? $('filled') : $('border'),
      ]"
    />

    <slot />
    <Txt
      v-if="label"
      :value="label"
      :theme="color"
      :size="txtSize"
      :ellipsis="ellipsis || $mq.phone"
      type="overline"
    />
  </div>
</template>

<script>
  import Txt from '@/core/text/Txt/Txt'

  const Color = {
    GREEN: 'green',
    ORANGE: 'orange',
    BLUE_DARK: 'blue-dark',
    BLUE: 'blue',
    GREY: 'grey',
    GREY_LIGHT: 'grey-light',
    LIGHT_BLUE: 'light-blue',
    RED: 'red',
    YELLOW: 'yellow',
  }

  const Size = {
    MEDIUM: 'medium',
    SMALL: 'small',
    SMALLER: 'smaller',
  }

  export const ColorMarkerColor = Color
  export const ColorMarkerSize = Size

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Color,Size,TxtSize:Txt.Size,
    name: 'ColorMarker',
    __enums: {
      Color,
      Size,
      TxtSize: Txt.Size,
    },
    components: {
      Txt,
    },
    props: {
      /**
       * ColorMarker color
       * Can be any of ColorMarkerColor
       */
      color: {
        type: String,
        default: Color.GREY_LIGHT,
      },
      /**
       * ColorMarker label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * ColorMarker size
       * Can be any of ColorMarkerSize
       */
      size: {
        type: String,
        default: 'medium',
      },
      /**
       * True if marker is a circle
       */
      circle: {
        type: Boolean,
        default: false,
      },
      /**
       * Shaped is filled with color if true
       * Just border otherwise
       */
      filled: {
        type: Boolean,
        default: true,
      },
      txtSize: {
        type: String,
        default: Txt.Size.XXXS,
      },
      ellipsis: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { color, size } = this

        return {
          [`color-marker--${color}`]: !!color,
          [`color-marker--${size}`]: !!size,
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .color-marker {
    align-items: center
    display: flex
    font-weight: $font-medium
    white-space: nowrap

    &-indicator {
      background-color: var(--color-idle)
      margin-right: 8px
    }

    // Size
    &--smaller .color-marker-indicator {
      &.color-marker-rect {
        border-radius: $radius-medium
        height: 4px
        width: 8px
      }

      &.color-marker-circle {
        border-radius: $radius-circle
        height: 8px
        width: 8px
      }
    }

    &--small .color-marker-indicator {
      &.color-marker-rect {
        border-radius: $radius-medium
        height: 4px
        width: 12px
      }

      &.color-marker-circle {
        border-radius: $radius-circle
        height: 12px
        width: 12px
      }
    }

    &--medium .color-marker-indicator {
      &.color-marker-rect {
        border-radius: $radius-large
        height: 8px
        width: 16px
      }

      &.color-marker-circle {
        border-radius: $radius-circle
        height: 16px
        width: 16px
      }
    }

    // Color
    &--blue &-filled.color-marker-indicator {
      background-color: var(--color-brand)
    }
    &--blue &-border.color-marker-indicator {
      border: 1px solid var(--color-brand)
      background-color: transparent
    }

    &--green &-filled.color-marker-indicator {
      background-color: var(--color-positive)
    }
    &--green &-border.color-marker-indicator {
      border: 1px solid var(--color-positive)
      background-color: transparent
    }

    &--grey &-filled.color-marker-indicator {
      background-color: var(--color-idle)
    }
    &--grey &-border.color-marker-indicator {
      border: 1px solid var(--color-idle)
      background-color: transparent
    }

    &--orange &-filled.color-marker-indicator {
      background-color: var(--color-warning)
    }
    &--orange &-border.color-marker-indicator {
      border: 1px solid var(--color-warning)
      background-color: transparent
    }

    &--red &-filled.color-marker-indicator {
      background-color: var(--color-negative)
    }
    &--red &-border.color-marker-indicator {
      border: 1px solid var(--color-negative)
      background-color: transparent
    }

    &--yellow &-filled.color-marker-indicator {
      background-color: var(--color-contrast)
    }
    &--yellow &-border.color-marker-indicator {
      border: 1px solid var(--color-contrast)
      background-color: transparent
    }
  }
</style>
