<template>
  <v-popover
    ref="popover"
    class="complex-tooltip"
    :class="trigger"
    :offset="8"
    :popover-class="theme"
    :placement="placement"
    :open="open"
    :auto-hide="autoHide"
    :trigger="trigger"
    :delay="delay"
    @apply-hide="$emit('hide')"
  >
    <slot />
    <slot slot="popover" name="tooltip" />
  </v-popover>
</template>

<script>
  const Placement = {
    AUTO: 'auto',
    TOP: 'top',
    LEFT: 'left',
    BOTTOM: 'bottom',
    RIGHT: 'right',
  }

  const Trigger = {
    HOVER: 'hover',
    CLICK: 'click',
    FOCUS: 'focus',
    MANUAL: 'manual',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Placement,Trigger,
    name: 'Tooltip',
    __enums: {
      Placement,
      Trigger,
    },
    props: {
      /**
       * Theme of the tooltip can be "dark" or "light"
       * @type {Object}
       */
      theme: {
        type: String,
        default: 'light',
      },
      /**
       * Tooltip position (top, left, bottom, right)
       */
      placement: {
        type: String,
        default: Placement.AUTO,
      },
      /**
       * Events triggering the tooltip separated with spaces:
       * 'hover', 'click', 'focus' or 'manual'
       */
      trigger: {
        type: String,
        default: Trigger.HOVER,
      },
      /**
       * Show / hide delay (in ms)
       * Format: { show: 270, hide: 800 }
       */
      delay: {
        type: Object,
        default: () => {},
      },
      /**
       * Show / hide tooltip programmatically
       */
      open: {
        type: Boolean,
        default: false,
      },
      /**
       * When manual mode is on, disable autohiding
       */
      autoHide: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .complex-tooltip {
    &.click {
      cursor: pointer
    }
  }
</style>
