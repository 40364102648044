import { __ } from '@comet/i18n'

import QualificationTypes from '@/types/qualification'

const {
  InterviewApprovalRating,
  InterviewDoubtCriteria,
  InterviewExpertiseLevel,
  InterviewQualificationStatus,
  InterviewTechnicalKnowledge,
} = QualificationTypes

/**
 * Format interview approval rating
 * @type {String}
 */
function formatInterviewApprovalRating(key) {
  const trans = {
    [InterviewApprovalRating.STRONG_NO]: __('ty:qualification:interview-approval-rating:strong-no'),
    [InterviewApprovalRating.NO]: __('ty:qualification:interview-approval-rating:no'),
    [InterviewApprovalRating.YES]: __('ty:qualification:interview-approval-rating:yes'),
    [InterviewApprovalRating.STRONG_YES]: __(
      'ty:qualification:interview-approval-rating:strong-yes',
    ),
  }

  return trans[key] || undefined
}

/**
 * Format freelancer interview expertise level
 * @type {String}
 */
function formatInterviewExpertiseLevel(key) {
  const trans = {
    [InterviewExpertiseLevel.POOR]: __('ty:qualification:interview-expertise-level:poor'),
    [InterviewExpertiseLevel.LOW]: __('ty:qualification:interview-expertise-level:low'),
    [InterviewExpertiseLevel.MEDIUM]: __('ty:qualification:interview-expertise-level:medium'),
    [InterviewExpertiseLevel.HIGH]: __('ty:qualification:interview-expertise-level:high'),
    [InterviewExpertiseLevel.EXPERT]: __('ty:qualification:interview-expertise-level:expert'),
  }

  return trans[key] || undefined
}

/**
 * Format freelancer interview technical knowledge
 * @type {String}
 */
function formatInterviewTechnicalKnowledge(key) {
  const trans = {
    [InterviewTechnicalKnowledge.SPECIALIZED]: __(
      'ty:qualification:interview-technical-knowledge:specialized',
    ),
    [InterviewTechnicalKnowledge.LIMITED]: __(
      'ty:qualification:interview-technical-knowledge:limited',
    ),
    [InterviewTechnicalKnowledge.POLYVALENT]: __(
      'ty:qualification:interview-technical-knowledge:polyvalent',
    ),
    [InterviewTechnicalKnowledge.JACK_OF_ALL_TRADES]: __(
      'ty:qualification:interview-technical-knowledge:jackOfAllTrades',
    ),
  }

  return trans[key] || undefined
}

/**
 * Format freelancer yes / no interview question
 * @type {String}
 */
function formatInterviewYesNo(key) {
  if (key === null || key === undefined) {
    return __('ty:qualification:interview-yes-no:not-tested')
  }
  return key
    ? __('ty:qualification:interview-yes-no:yes')
    : __('ty:qualification:interview-yes-no:no')
}

/**
 * Format freelancer interview expertise level
 * @type {String}
 */
function formatInterviewQualificationStatus(key) {
  const trans = {
    [InterviewQualificationStatus.NOT_QUALIFIED]: __(
      'ty:qualification:interview-qualification-status:not-qualified',
    ),
    [InterviewQualificationStatus.TO_REVIEW]: __(
      'ty:qualification:interview-qualification-status:to-review',
    ),
    [InterviewQualificationStatus.FROZEN]: __(
      'ty:qualification:interview-qualification-status:frozen',
    ),
    [InterviewQualificationStatus.CANCELLED_BY_CANDIDATE]: __(
      'ty:qualification:interview-qualification-status:cancelled-by-candidate',
    ),
    [InterviewQualificationStatus.CANCELLED_BY_INSTRUCTOR]: __(
      'ty:qualification:interview-qualification-status:cancelled-by-instructor',
    ),
    [InterviewQualificationStatus.CANCELLED_BY_TM]: __(
      'ty:qualification:interview-qualification-status:cancelled-by-tm',
    ),
    [InterviewQualificationStatus.QUALIFIED]: __(
      'ty:qualification:interview-qualification-status:qualified',
    ),
  }

  return trans[key] || undefined
}

/**
 * Format freelancer interview doubt criteria sent by TMs
 * @type {String}
 */
function formatInterviewDoubtCriteria(key) {
  const trans = {
    [InterviewDoubtCriteria.EXPERTISE_LEVEL]: __(
      'ty:qualification:interview-doubt-criteria:expertise-level',
    ),
    [InterviewDoubtCriteria.KNOWLEDGE]: __('ty:qualification:interview-doubt-criteria:knowledge'),
    [InterviewDoubtCriteria.COMMUNICATION]: __(
      'ty:qualification:interview-doubt-criteria:communication',
    ),
    [InterviewDoubtCriteria.STRICTNESS]: __('ty:qualification:interview-doubt-criteria:strictness'),
    [InterviewDoubtCriteria.AUTONOMY]: __('ty:qualification:interview-doubt-criteria:autonomy'),
    [InterviewDoubtCriteria.PROFESSIONALISM]: __(
      'ty:qualification:interview-doubt-criteria:professionalism',
    ),
  }

  return trans[key] || undefined
}

export {
  formatInterviewApprovalRating,
  formatInterviewDoubtCriteria,
  formatInterviewExpertiseLevel,
  formatInterviewTechnicalKnowledge,
  formatInterviewYesNo,
  formatInterviewQualificationStatus,
}
