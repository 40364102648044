<template>
  <div class="checkbox" :class="classes" @click="onClick">
    <span />

    <span v-if="raw" v-html="label" />
    <template v-else>{{ label }}</template>

    <slot />

    <input
      data-cy-checkbox
      type="checkbox"
      :class="$('input')"
      :checked="value"
      :disabled="disabled"
    />

    <Txt v-if="subText" :theme="Txt.Theme.GREY" :value="subText" :raw="raw" />
  </div>
</template>

<script>
  import Txt from '@/core/text/Txt/Txt'

  const Size = {
    S: 's',
    M: 'm',
  }

  export const CheckboxSize = Size

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,
    name: 'Checkbox',
    __enums: {
      Size,
    },
    components: {
      Txt,
    },
    props: {
      /**
       * Label to display next to the checkbox
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Text to display below the checkbox
       */
      subText: {
        type: String,
        default: null,
      },
      /**
       * Value that will be updated (also allow a initial value)
       */
      value: {
        type: Boolean,
        default: false,
      },
      /**
       * Size of the checkbox and its label
       * Can be : "m" or "s"
       */
      size: {
        type: String,
        default: CheckboxSize.M,
      },
      /**
       * Allow to disable the checkbox
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * If 'true, v-html will be used for the label
       */
      raw: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied on the checkbox button in order to easily apply
       * styles according to its value
       * @type {Object.<Boolean>}
       */
      classes() {
        const { value, disabled, size } = this

        return {
          'checkbox--on': !!value,
          'checkbox--off': !value,
          'checkbox--disabled': disabled,
          [`checkbox--${size}`]: !!size,
        }
      },
    },
    methods: {
      /**
       * Fired when the component is clicked
       * @returns {void}
       */
      onClick() {
        const { value, disabled } = this

        if (disabled) {
          return
        }
        this.$emit('input', !value)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .checkbox {
    display: inline-block
    position: relative
    display: inline-block
    margin-bottom: 0
    font-family: inherit
    font-size: inherit
    line-height: 24px
    text-transform: inherit
    letter-spacing: inherit
    font-weight: $font-regular
    user-select: none

    &:not(.checkbox--disabled) {
      cursor: pointer

      > input {
        cursor: pointer
      }
    }

    &--s {
      font-size: 14px
      padding-left: 27px

      > input,
      > span:first-child {
        top: 4px
        width: 18px
        height: 18px
      }

      &:hover, &:focus {
        > span:first-child {
          width: 20px
          height: 20px
          top: 3px
          left: -1px
        }
      }
    }

    &--m {
      font-size: 16px
      padding-left: 36px

      > input,
      > span:first-child {
        top: 0px
        width: 24px
        height: 24px
      }

      &:hover, &:focus {
        > span:first-child {
          width: 26px
          height: 26px
          top: -1px
          left: -1px
        }
      }
    }

    > input,
    > span:first-child {
      position: absolute
      left: 0
    }

    /* Hide default HTML checkbox */
    > input {
      margin: 0
      opacity: 0
    }

    > span:first-child {
      display: inline-block
      vertical-align: bottom
      background-repeat: no-repeat
      background-size: cover
    }

    &.checkbox--on > span:first-child {
      background-image: url('../../../assets/images/controls/checkbox-on.svg')
    }

    &.checkbox--off > span:first-child {
      background-image: url('../../../assets/images/controls/checkbox-off.svg')
    }

    &.checkbox--disabled {
      opacity: .4
    }

    > span:first-child + *:not(:last-child) {
      line-height: 24px
      vertical-align: bottom
    }
  }
</style>
