<template>
  <FileUploadModalLayout
    class="freelance-document-upload-modal"
    :title="
      __('md:freelance:freelance-document-upload-modal:main:title', {
        document: formatDocumentType(document.type),
      })
    "
    :on-submit="file => submit(onSubmit, file)"
    :accepted-mime-types="acceptedMimeTypes"
  />
</template>

<script>
  import FileUploadModalLayout from '@/modals/layout/FileUploadModalLayout/FileUploadModalLayout'

  import ApolloMixin from '@/mixins/ApolloMixin'
  import FormMixin from '@/mixins/FormMixin'
  import I18nMixin from '@/mixins/I18nMixin'
  import ModalMixin from '@/mixins/ModalMixin'

  import { formatDocumentType } from '@/utils/format/freelancer'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  FileUploadModalLayout: FileUploadModalLayout.__enums,
},

    name: 'FreelanceDocumentUploadModal',
    components: {
      FileUploadModalLayout,
    },
    mixins: [ApolloMixin, FormMixin, I18nMixin, ModalMixin],
    props: {
      /**
       * Document
       */
      document: {
        type: Object,
        required: true,
      },
      /**
       * List of valid mime types
       */
      acceptedMimeTypes: {
        type: Object,
        default: () => FileUploadModalLayout.MIME_TYPES,
      },
    },
    methods: {
      formatDocumentType,
      /**
       * Dismiss modal and return selected file
       * @param {File} file
       * @returns {Promise}
       */
      onSubmit(file) {
        const { dismissModal } = this

        return new Promise(() => {
          dismissModal('submit', file)
        })
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .freelance-document-upload-modal {
  }
</style>
