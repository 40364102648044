/**
 * Experience type
 */
const ExperienceType = {
  CONTRACT: 'contract',
  FREELANCING: 'freelancing',
  CONSULTING: 'consulting',
  PERSONAL_PROJECT: 'personalProject',
  FORMATION: 'formation',
  OTHER: 'other',
  NONE: 'none',
}

const ExperienceTypes = {
  ExperienceType,
}

export default ExperienceTypes
