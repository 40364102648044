import _mapValues from 'lodash/mapValues'
import _values from 'lodash/values'

import { __ } from '@comet/i18n'

const PasswordLostView = () => import('@/views/public/PasswordLostView/PasswordLostView')
const PasswordResetView = () => import('@/views/public/PasswordResetView/PasswordResetView')
const OutOfFranceView = () => import('@/views/public/OutOfFranceView/OutOfFranceView')
const RedirectView = () => import('@/views/public/RedirectView/RedirectView')
const UnknownView = () => import('@/views/public/UnknownView/UnknownView')
const SignoutView = () => import('@/views/public/SignoutView/SignoutView')
const HomeView = () => import(/* webpackPreload: true */ '@/views/public/HomeView/HomeView')
const FreelancerMissionHuntView = () =>
  import('@/views/public/FreelancerMissionHuntView/FreelancerMissionHuntView')
const PublicChatView = () => import('@/views/public/PublicChatView/PublicChatView')
const PublicChatConversationListView = () =>
  import('@/views/public/PublicChatConversationListView/PublicChatConversationListView')

import AppTypes from '@/types/app'
import UserTypes from '@/types/user'

import { extract } from '@/utils/app'

const { PREVENT, FORCE } = UserTypes.UserFetchPolicy

const envs = `${_values(AppTypes.AppEnv).join('|')}`

const defaultPaths = {
  [AppTypes.AppEnv.BACKOFFICE]: '/backoffice',
  [AppTypes.AppEnv.ENTERPRISE]: '/enterprise/dashboard',
  [AppTypes.AppEnv.FREELANCER]: '/freelancer',
}

export default [
  {
    path: '/',
    component: HomeView,
    meta: {
      userPolicy: PREVENT,
    },
  },
  {
    path: `/:env(${envs})?/signout`,
    component: SignoutView,
    meta: {
      userPolicy: PREVENT,
    },
    props: extract(['env'], ['to', 'forceTo']),
  },
  {
    path: `/:env(${envs})?/welcome/:signupHuntingToken`,
    component: FreelancerMissionHuntView,
    props: extract(['signupHuntingToken'], {
      utm_campaign: 'utmCampaign',
      utm_source: 'utmSource',
      utm_medium: 'utmMedium',
    }),
  },
  {
    path: `/:env(${envs})?/redirect`,
    component: RedirectView,
    meta: {
      title: () => __('app:route:public:redirect'),
      userPolicy: FORCE,
    },
    props: extract(null, ['to', 'env']),
  },
  {
    path: `/:env(${envs})/modal/:name`,
    component: {
      beforeRouteEnter(to, from, next) {
        const { params, query } = to

        // By default all the values in query are string, but sometimes we want
        // to be able to put number in it, that's why here we check every value
        // and if one is a number formatted as a string then we parse it
        const parsedQuery = _mapValues(query, v => (parseInt(v, 0) ? parseInt(v, 0) : v))

        const path = from ? from.path : defaultPaths[params.env]

        const fromQuery = from ? from.query : {}

        next({
          path,
          query: {
            ...fromQuery,
            modal: params.name,
            params: JSON.stringify({
              ...parsedQuery,
            }),
          },
          replace: true,
        })
      },
    },
  },
  {
    path: '/chat/messages',
    component: PublicChatConversationListView,
    meta: {
      title: () => __('app:route:public:public-chat'),
    },
  },
  {
    path: '/chat/:publicId/:userPublicId?',
    component: PublicChatView,
    meta: {
      title: () => __('app:route:public:public-chat'),
      userPolicy: PREVENT,
    },
    props: extract(['publicId', 'userPublicId']),
  },
  {
    path: '/password-lost',
    component: PasswordLostView,
    meta: {
      title: () => __('app:route:public:password:lost'),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/password-reset/:token',
    component: PasswordResetView,
    meta: {
      title: () => __('app:route:public:password:reset'),
      userPolicy: PREVENT,
    },
    props: extract(['token'], ['email']),
  },
  {
    path: '/out-of-france',
    component: OutOfFranceView,
    meta: {
      title: () => __('app:route:public:out-of-france'),
      userPolicy: PREVENT,
    },
  },
  {
    path: '/404',
    component: UnknownView,
    meta: {
      title: () => __('app:route:public:404'),
      userPolicy: PREVENT,
    },
  },
]
