<template>
  <VChip
    v-bind="$attrs"
    :small="dense || $attrs.small"
    outlined
    class="body-3"
    :class="classes"
    close-icon="mdi-close-circle-outline"
    v-on="_omit($listeners, ['input'])"
  >
    <slot
      v-if="$slots.label"
      name="label"
    />
    <span
      v-else-if="label"
      :title="label"
      class="font-weight-semi-bold text--ellipsis"
      v-text="label"
    />

    <slot
      v-if="$slots.wished"
      name="wished"
    />
    <VIcon
      v-else-if="wished"
      small
      class="ml-1"
      v-text="'mdi-heart'"
    />

    <slot
      v-if="$slots.duration"
      name="duration"
    />
    <span
      v-else-if="duration"
      class="ml-1"
      :class="$style.duration"
      v-text="__('co:duration:years', duration, { value: duration })"
    />

    <slot
      v-if="$slots.primary"
      name="primary"
    />

    <slot
      v-if="$slots.major"
      name="major"
    />
    <span
      v-else-if="major"
      class="caption-2"
      :class="$style.major"
    >
      <VIcon
        in-text
        class="mr-1"
        v-text="'mdi-check-box-outline'"
      />
      {{ __('co:label:major') }}
    </span>
  </VChip>
</template>

<script>
  import _omit from 'lodash/omit'

  export default {
    name: 'CChipTalent',
    inheritAttrs: false,
    props: {
      /**
       * Main talent label displayed as a title.
       * "label" slot also exists.
       * @type {String}
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Does this talent is a favorite / wished, or not ?
       * "wished" slot also exists.
       * @type {String}
       */
      wished: {
        type: Boolean,
        default: null,
      },
      /**
       * Does this talent is a major talent according to the context (experience, roles, ...)
       * "major" slot also exists.
       */
      major: {
        type: Boolean,
        default: null,
      },
      /**
       * Duration related to the talent, as an experience or a specific time lapse.
       * "duration" slot also exists.
       * @type {String}
       */
      duration: {
        type: Number,
        default: null,
      },
      /**
       * Reduces the chip's height
       */
      dense: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { dense, $style } = this

        return {
          [$style.root]: true,
          [$style.dense]: dense,
        }
      },
    },
    methods: {
      _omit,
    },
  }
</script>

<style lang="stylus" module>
  .root {
    &:global(.v-chip.v-chip--outlined) {
      border-color: var(--color-grey-40)
      background-color: var(--color-grey-10) !important
      color: var(--color-grey-70) !important
      opacity: 1 !important

      :global(.v-chip__close) {
        color: var(--color-grey-40)
        margin-left: 4px
        font-size: 21px !important

        &:hover {
          color: var(--color-grey-60)
        }
      }
    }

    .duration {
      height: 20px
      display: flex
      align-items: center

      &:before {
        content: ' '
        width: 3px
        height: 3px
        background: currentColor
        border-radius: 50%
        margin-right: 4px
      }
    }

    .major {
      position: relative
      left: 4px
      height: 20px
      display: flex
      align-items: center
      padding: 0 8px
      background: var(--color-grey-30)
      border-radius: 10px
    }

    &.dense .major {
      height: 16px
      left: 8px
    }
  }
</style>
