import { __ } from '@comet/i18n'

import FormTypes from '@/types/form'
import AppTypes from '@/types/app'

/**
 * List of available validators
 * @type {String}
 */
const {
  REQUIRED,
  REQUIRED_IF,
  REQUIRED_UNLESS,
  MIN_LENGTH,
  MAX_LENGTH,
  MIN_VALUE,
  BETWEEN,
  INTEGER,
  NUMERIC,
  EMAIL,
  SAME_AS,
  URL,
  PASSWORD,
  PHONE_NUMBER,
  BIC,
  IBAN,
  IS,
  SIRET,
  VAT_NUMBER,
  ZIPCODE,
  REMOTE,
  TJM,
  MISSION_DURATION,
  FORM,
  UNIQUE,
  WORKPLACE_ADDRESS,
  ADDRESS,
  PERCENTAGE,
  DECIMAL,
  REQUIRED_WITH_ERROR,
  MAX_COUNT,
  REQUIRED_IF_WITH_ERROR,
  DAYS,
  WORKED_DAYS,
  ADDRESS_FULL,
  ADDRESS_FULL_IF,
  SOCIAL_CAPITAL,
  LINKED_IN_URL,
} = FormTypes.ValidationError

/**
 * List of app errors which would be applied as validation errors
 * @type {Number}
 */
const {
  EMAIL_ALREADY_USED,
  PHONE_ALREADY_USED,
  INVALID_FILE_TYPE,
  LOGIN_PROVIDER_DUPLICATED,
} = AppTypes.AppError

/**
 * Format main form errors (grapgQL - VueLidate - custom)
 * @param  {String} key
 * @param  {Object} params - additionnal params needed for translation
 * @returns {String} translation
 */
function formatValidationError(key, params) {
  const defaultError = __('ty:form:validation-error:form')

  if (params && params.error) {
    return params.error
  }

  if (key === REMOTE) {
    return (
      {
        [EMAIL_ALREADY_USED]: __('ty:form:validation-error:email:already-used'),
        [PHONE_ALREADY_USED]: __('ty:form:validation-error:phone:already-used'),
        [INVALID_FILE_TYPE]: __('ty:form:validation-error:invalid-file-type'),
        [LOGIN_PROVIDER_DUPLICATED]: __('ty:app:error:login-provider-duplicated'),
      }[params.code] || defaultError
    )
  }

  return (
    {
      // Vuelidate built-in validators
      [REQUIRED]: __('ty:form:validation-error:required'),
      [REQUIRED_IF]: __('ty:form:validation-error:required-if'),
      [REQUIRED_UNLESS]: __('ty:form:validation-error:required-unless'),
      [MIN_LENGTH]: __('ty:form:validation-error:min-length', params),
      [MAX_LENGTH]: __('ty:form:validation-error:max-length', params),
      [MIN_VALUE]: __('ty:form:validation-error:min-value', params),
      [BETWEEN]: __('ty:form:validation-error:between', params),
      [INTEGER]: __('ty:form:validation-error:integer'),
      [NUMERIC]: __('ty:form:validation-error:numeric'),
      [DECIMAL]: __('ty:form:validation-error:decimal'),
      [EMAIL]: __('ty:form:validation-error:email'),
      [SAME_AS]: __('ty:form:validation-error:same-as'),
      [URL]: __('ty:form:validation-error:url'),
      [LINKED_IN_URL]: __('ty:form:validation-error:linkedin-url'),

      // Custom validators
      [ADDRESS]: __('ty:form:validation-error:address'),
      [ADDRESS_FULL]: __('ty:form:validation-error:address-full'),
      [ADDRESS_FULL_IF]: __('ty:form:validation-error:address-full-if'),
      [BIC]: __('ty:form:validation-error:bic'),
      [DAYS]: __('ty:form:validation-error:days'),
      [WORKED_DAYS]: __('ty:form:validation-error:worked-days'),
      [IBAN]: __('ty:form:validation-error:iban', params),
      [IS]: __('ty:form:validation-error:is', params),
      [PASSWORD]: __('ty:form:validation-error:password'),
      [PERCENTAGE]: __('ty:form:validation-error:percentage'),
      [PHONE_NUMBER]: __('ty:form:validation-error:phone-number'),
      [MAX_COUNT]: __('ty:form:validation-error:max-count', params),
      [REQUIRED_WITH_ERROR]: __('ty:form:validation-error:required'),
      [REQUIRED_IF_WITH_ERROR]: __('ty:form:validation-error:required-if'),
      [SIRET]: __('ty:form:validation-error:siret', params),
      [TJM]: __('ty:form:validation-error:tjm', params),
      [MISSION_DURATION]: __('ty:form:validation-error:mission-duration', params),
      [VAT_NUMBER]: __('ty:form:validation-error:vat-number', params),
      [WORKPLACE_ADDRESS]: __('ty:form:validation-error:wokplace-address'),
      [ZIPCODE]: __('ty:form:validation-error:zipcode', params),
      [SOCIAL_CAPITAL]: __('ty:form:validation-error:social-capital'),

      // GraphQL error returns
      [FORM]: __('ty:form:validation-error:form'),
      [UNIQUE]: __('ty:form:validation-error:unique'),
    }[key] || defaultError
  )
}

/* eslint-disable import/prefer-default-export */
export { formatValidationError }
