import { __ } from '@comet/i18n'

/**
 * @param {Number} rate
 * @returns {String}
 */
function formatSoftSkillsReview(rate) {
  const trans = [
    __('ty:review:soft-skill:bad-integration'),
    __('ty:review:soft-skill:no-effort'),
    __('ty:review:soft-skill:average'),
    __('ty:review:soft-skill:proactivity'),
    __('ty:review:soft-skill:leader'),
  ]

  return trans[rate - 1]
}

/**
 * @param {Number} rate
 * @returns {String}
 */
function formatProblemSolvingReview(rate) {
  const trans = [
    __('ty:review:problem-solving:giving-up'),
    __('ty:review:problem-solving:limited'),
    __('ty:review:problem-solving:moderate'),
    __('ty:review:problem-solving:initiative'),
    __('ty:review:problem-solving:ownership'),
  ]

  return trans[rate - 1]
}

/**
 * @param {Number} rate
 * @returns {String}
 */
function formatHardSkillReview(rate) {
  const trans = [
    __('ty:review:hard-skills:beginner'),
    __('ty:review:hard-skills:average'),
    __('ty:review:hard-skills:good'),
    __('ty:review:hard-skills:seasoned'),
    __('ty:review:hard-skills:expert'),
  ]

  return trans[rate - 1]
}

/**
 * @param {Number} rate
 * @returns {String}
 */
function formatResponsibilityReview(rate) {
  const trans = [
    __('ty:review:responsability:very-weak'),
    __('ty:review:responsability:weak'),
    __('ty:review:responsability:correct'),
    __('ty:review:responsability:high'),
    __('ty:review:responsability:too-high'),
  ]

  return trans[rate - 1]
}

/**
 * @param {Number} rate
 * @returns {String}
 */
function formatWorkplaceReview(rate) {
  const trans = [
    __('ty:review:workplace:really-not-enjoyable'),
    __('ty:review:workplace:not-enjoyable'),
    __('ty:review:workplace:correct'),
    __('ty:review:workplace:enjoyable'),
    __('ty:review:workplace:very-enjoyable'),
  ]

  return trans[rate - 1]
}
/**

 * @param {Number} rate
 * @returns {String}
 */
function formatWorkEnvironmentReview(rate) {
  const trans = [
    __('ty:review:work-environement:old-school'),
    __('ty:review:work-environement:not-innovative'),
    __('ty:review:work-environement:average'),
    __('ty:review:work-environement:innovative'),
    __('ty:review:work-environement:very-innovative'),
  ]

  return trans[rate - 1]
}

/**
 * @param {Number} rate
 * @returns {String}
 */
function formatTeamHardSkillsReview(rate) {
  const trans = [
    __('ty:review:team-hard-skills:very-bad'),
    __('ty:review:team-hard-skills:bad'),
    __('ty:review:team-hard-skills:correct'),
    __('ty:review:team-hard-skills:good'),
    __('ty:review:team-hard-skills:very-good'),
  ]

  return trans[rate - 1]
}

/**
 * @param {Number} rate
 * @returns {String}
 */
function formatTeamSpiritReview(rate) {
  const trans = [
    __('ty:review:team-spirit:very-bad'),
    __('ty:review:team-spirit:bad'),
    __('ty:review:team-spirit:correct'),
    __('ty:review:team-spirit:good'),
    __('ty:review:team-spirit:incredible'),
  ]

  return trans[rate - 1]
}

export {
  formatSoftSkillsReview,
  formatProblemSolvingReview,
  formatHardSkillReview,
  formatResponsibilityReview,
  formatWorkplaceReview,
  formatWorkEnvironmentReview,
  formatTeamHardSkillsReview,
  formatTeamSpiritReview,
}
