<template>
  <div>
    <VDialog v-model="isModalOpen" v-bind="$attrs" :max-width="500" scrollable>
      <template v-slot:activator="{ on }">
        <slot
          name="activator"
          :on="{
            ...on,
            click: (event) => {
              if (event) {
                event.preventDefault()
                event.stopImmediatePropagation()
              }
              isModalOpen = true
              $emit('click')
            },
          }"
        />
      </template>

      <VCard class="pa-2">
        <VCardTitle class="headline" v-html="__('cp:freelancer-optim-need-modal:title')" />

        <VCardText>
          <form class="freelancer-header-optim-needs-message">
            <VTextarea v-model="form.fields.message" dense auto-grow hide-details />
          </form>
        </VCardText>

        <VCardActions>
          <VSpacer />

          <VBtn
            text
            small
            color="secondary"
            class="mx-2"
            @click.prevent="() => (isModalOpen = false)"
            v-text="__('cp:freelancer-optim-need-modal:actions:cancel')"
          />

          <VBtn
            text
            small
            :color="'primary'"
            class="mx-2"
            @click="onSharePress"
            v-text="__('cp:freelancer-optim-need-modal:actions:share')"
          />
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _orderBy from 'lodash/orderBy'

  import { format, __ } from '@comet/i18n'
  import { FreelancerWorkplace as Workplace } from '@comet/types'
  import { computeBackofficeAbsUrl } from '@comet/urls'

  import FormMixin from '@/mixins/FormMixin'

  import { formatCompanyLegalForm } from '@/utils/format/company'
  import { formatWorkplace } from '@/utils/format/freelancer'
  import { required } from '@/utils/validators'

  import shareToOptimNeeds from './shareToOptimNeeds.gql'

  /**
   * Format the freelancer's skills (max.  6 skills).
   * @param {Freelancer} freelancer
   * @returns {String}
   */
  function formatSkills(freelancer) {
    if (!freelancer) {
      return '...'
    }

    const years = __(
      'cp:freelancer-optim-needs-modal:experiences:years',
      freelancer.experienceInYears,
      { count: freelancer.experienceInYears },
    )

    const count = __(
      'cp:freelancer-optim-needs-modal:experiences:count',
      freelancer.experiences.length,
      { count: freelancer.experiences.length },
    )

    const skills = _orderBy(freelancer.skills, (s) => s.duration, 'desc')
      .slice(0, 8)
      .map((s) => s.name)

    const more = freelancer.skills.length > 8 ? ['...'] : []

    return __('cp:freelancer-optim-needs-modal:experiences:value', {
      years,
      count,
      skills: [...skills, ...more].join(', '),
    })
  }

  /**
   * Label displayed to give more information about why and when the freelancer
   * is, is not or would be available.
   * @param {Freelancer} freelancer
   * @param {Clock} $clock
   * @returns {String}
   */
  function formatAvailability(freelancer, $clock) {
    const { isSameOrBeforeNow } = $clock

    const isAvailable = !!freelancer.isAvailable || isSameOrBeforeNow(freelancer.availabilityDate)

    if (isAvailable) {
      return __('cp:freelancer-optim-needs-modal:availability:on:label')
    }

    const availabilityDate = isSameOrBeforeNow(freelancer.availabilityDate)
      ? null
      : freelancer.availabilityDate

    return availabilityDate
      ? __('cp:freelancer-optim-needs-modal:availability:off:label-with-date', {
          date: format('date', availabilityDate, 'longdate'),
        })
      : __('cp:freelancer-optim-needs-modal:availability:off:label')
  }

  export default {
    name: 'FreelancerOptimNeedsModal',
    mixins: [FormMixin],
    inheritAttrs: false,
    props: {
      /**
       * The freelancer who will be shared
       */
      freelancer: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        /**
         * Determines if the modal should be shown or not
         * @type {Boolean}
         */
        isModalOpen: false,
      }
    },
    form() {
      const { message } = this

      return {
        name: 'freelancer-optim-needs-modal',
        fields: {
          message,
        },
        validators: {
          message: { required },
        },
      }
    },
    computed: {
      ...mapGetters({
        user: 'selectUser',
      }),
      /**
       * Return a preformated message in the modal
       * @type {String}
       */
      message() {
        const { freelancer, user, $clock } = this
        const { __ } = this.$i18n

        const cometUrl = computeBackofficeAbsUrl('freelancer', { freelancer })

        const lines = [
          // Full name
          __('cp:freelancer-optim-needs-modal:freelancer', {
            name: freelancer.user.fullName,
            url: cometUrl,
            preuser: freelancer.preUser
              ? __('cp:freelancer-optim-needs-modal:freelancer:pre-user')
              : '',
          }),
          '',
          // Skills
          __('cp:freelancer-optim-needs-modal:profile', {
            value: formatSkills(freelancer),
          }),
          // Retribution
          __('cp:freelancer-optim-needs-modal:retribution', {
            value: freelancer.retribution,
          }),
          // Availability
          __('cp:freelancer-optim-needs-modal:availability', {
            value: formatAvailability(freelancer, $clock),
          }),
          // Workplace
          __('cp:freelancer-optim-needs-modal:workplace', {
            workplace: formatWorkplace(freelancer.prefWorkplace),
            city:
              freelancer.prefWorkplace !== Workplace.REMOTE &&
              (freelancer.user.address || freelancer.company?.headOfficeAddress)
                ? __('cp:freelancer-optim-needs-modal:workplace:city', {
                    city:
                      freelancer.user.address?.city ||
                      freelancer.company?.headOfficeAddress?.city ||
                      '',
                  })
                : '',
          }),
          // Company
          __('cp:freelancer-optim-needs-modal:company', {
            legalForm: freelancer?.company?.legalForm
              ? formatCompanyLegalForm(freelancer.company.legalForm, true)
              : '-',
            umbrella: freelancer?.company.isUmbrella
              ? __('cp:freelancer-optim-needs-modal:company:umbrella')
              : '',
          }),
          // Opt-in
          __('cp:freelancer-optim-needs-modal:missions-opt-in'),
          // Comments
          ...(freelancer?.comments?.length
            ? [
                '',
                __('cp:freelancer-optim-needs-modal:comments', {
                  value: freelancer?.comments?.length
                    ? '> ' +
                      freelancer.comments
                        .map((attachment) => attachment.text)
                        .join('\n')
                        .replace(/\n/g, '\n> ')
                    : '',
                }),
              ]
            : []),
          '',
          // LinkedIn URL
          __('cp:freelancer-optim-needs-modal:linkedin', {
            url: freelancer.linkedInUrl,
          }),
          '',
          // Comet URL
          __('cp:freelancer-optim-needs-modal:comet', {
            status: freelancer.preUser
              ? __('cp:freelancer-optim-needs-modal:comet:pre-user')
              : __('cp:freelancer-optim-needs-modal:comet:user'),
            url: cometUrl,
          }),
          '',
          // Shared by
          __('cp:freelancer-optim-needs-modal:shared-by', {
            name: user?.fullName,
          }),
        ]

        return lines.join('\n')
      },
    },
    methods: {
      /**
       * Fired on modal submission.
       * Submit the entered text and publish it straight into the  #topic_optim_needs Slack channel.
       * @returns {Promise}
       */
      async onSharePress() {
        const { form } = this

        await this.$apollo.mutate({
          mutation: shareToOptimNeeds,
          variables: {
            message: form.fields.message,
          },
        })
        this.isModalOpen = false

        return true
      },
    },
  }
</script>
