import _get from 'lodash/get'

import { __, format } from '@comet/i18n'

/**
 * All entities which have an event explorer
 */
const Entity = {
  MISSION: 'mission',
  CORPORATE: 'corporate',
  FREELANCER: 'freelancer',
  TEAM_MEMBER: 'teamMember',
  COMPANY: 'company',
  MISSION_MATCH: 'missionMatch',
}

/**
 * All categories of an event
 */
const Category = {
  NAVIGATION: 'navigation',
  COMPANY: 'company',
  CORPORATE: 'corporate',
  FREELANCE: 'freelance',
  QUALIFICATION: 'qualification',
  INTERACTION: 'interaction',
  USER: 'user',
  MISSION: 'mission',
  MATCH: 'match',
  DEAL: 'deal',
  TEAM_MEMBER: 'team_member',
  COMMUNICATION: 'communication',
  WORKLOG: 'work_log',
  ATTACHMENT: 'attachment',
  TOOLS: 'tools',
  TIMESHEET: 'timesheet',
}

/**
 * All types of an event
 */
const Type = {
  // Specifying type for clarity in /* sections */
  /* Navigation */
  PAGE_VIEW: 'page_view',
  ELEMENT_CLICKED: 'element_clicked',
  CUSTOM_PAGE_VIEW: 'custom_page_view',
  PAGE_PING: 'page_ping',
  CRISP_CHATBOX_OPENED: 'crisp_chatbox_opened',
  CRISP_CHATBOX_CLOSED: 'crisp_chatbox_closed',
  CRISP_MESSAGE_SENT: 'crisp_message_sent',
  CRISP_MESSAGE_RECEIVED: 'crisp_message_received',
  /* Interaction */
  CHAT_MESSAGE_SENT: 'chat_message_sent',
  CONVERSATION_VIEWED: 'conversation_viewed',
  CONVERSATION_ARCHIVED: 'conversation_archived',
  CONVERSATION_REVIVED: 'conversation_revived',
  CHAT_ATTACHMENT_SENT: 'chat_attachment_sent',
  NEW_COLLABORATION_PROPOSED: 'new_collaboration_proposed', // Bromet • when client asks to rework with freelancer
  SLACK_MESSAGE: 'slack_message',
  SLACK_MESSAGE_THREAD: 'slack_message_thread', // to be merged with above
  SLACK_MESSAGE_CHANGED: 'slack_message_changed',
  SLACK_MESSAGE_CHANGED_THREAD: 'slack_message_changed_thread', // redundant
  SLACK_MESSAGE_REACTION: 'slack_reaction',
  /* Freelance */
  FREELANCE_PROFILE_UPDATED: 'freelance_profile_updated',
  FREELANCE_FAVORITE_ADDED: 'freelance_favorite_added',
  FREELANCE_FAVORITE_REMOVED: 'freelance_favorite_removed',
  FREELANCE_EXPERIENCE_ADDED: 'freelance_experience_added',
  FREELANCE_EXPERIENCE_UPDATED: 'freelance_experience_updated',
  FREELANCE_EXPERIENCE_DELETED: 'freelance_experience_deleted',
  FREELANCE_REFERENCE_ADDED: 'freelance_reference_added',
  FREELANCE_REFERENCE_COMPLETED: 'freelance_reference_completed',
  FREELANCE_REFERENCE_UPDATED: 'freelance_reference_updated',
  FREELANCE_REFERENCE_DENIED: 'freelance_reference_denied',
  FREELANCE_REFERENCE_REMOVED: 'freelance_reference_removed',
  FREELANCE_REFERENCE_CANCELLED: 'freelance_reference_cancelled',
  PROFILE_PICTURE_ADDED: 'profile_picture_added',
  FREELANCE_SLACK_LINKED: 'freelance_slack_linked',
  MISSION_PREFERENCES_COMPLETED: 'mission_preferences_completed',
  FREELANCE_BECAME_UNAVAILABLE: 'freelance_became_unavailable',
  FREELANCE_BECAME_AVAILABLE: 'freelance_became_available',
  FREELANCE_VALIDATED: 'freelance_validated',
  FREELANCE_UPVOTED: 'freelance_upvoted',
  FREELANCE_DOWNVOTED: 'freelance_downvoted',
  SPOTTED_AS_BEACON: 'spotted_as_beacon',
  SPOTTED_AS_TWO_TIER: 'spotted_as_two_tier',
  /* Freelancer V2 */
  FREELANCER_ENABLE_INVOICING: 'freelancer-enable-invoicing',
  FREELANCER_DISABLE_INVOICING: 'freelancer-disable-invoicing',
  /* Mission Match */
  MISSION_APPLICATION_STARTED: 'mission_application_started', // a freelancer opened the Apply to Mission Modal
  MISSION_MATCH_ACCEPTED: 'mission_match_accepted', // freelancer applied
  MISSION_MATCH_PROPOSED: 'mission_match_proposed',
  MISSION_MATCH_CREATED: 'mission_match_created',
  MISSION_MATCH_UPDATED: 'mission_match_updated',
  MISSION_MATCH_DECLINED: 'mission_match_declined',
  MISSION_MATCH_SELECTED: 'mission_match_selected',
  MISSION_MATCH_DESELECTED: 'mission_match_deselected',
  MISSION_MATCH_PRESENTED: 'mission_match_presented',
  MEETING_SLOTS_SET: 'meeting_slots_set',
  MEETING_SCHEDULED: 'meeting_scheduled',
  MEETING_RESCHEDULED: 'meeting_rescheduled',
  MISSION_MATCH_DELETED: 'mission_match_deleted',
  MISSION_MATCH_CONFIRMED_BY_FREELANCE: 'mission_match_confirmed_by_freelance',
  MISSION_MATCH_CONFIRMED_BY_CLIENT: 'mission_match_confirmed_by_client',
  MATCHING_LIST_REFRESHED: 'matching_list_refreshed',
  MATCHING_LIST_RESET: 'matching_list_reset',
  /* Communication */
  HUB_CONVERSATION_STARTED: 'hub_conversation_started',
  SLACK_INVITATION_SENT: 'slack_invitation_sent',
  NOTIFICATION_PARAMETERS_UPDATED: 'notification_parameters_updated',
  HUB_MESSAGE_SENT: 'hub_message_sent',
  HUB_MESSAGE_OPENED: 'hub_message_opened',
  HUB_MESSAGE_CLICKED: 'hub_message_clicked',
  HUB_MESSAGE_ANSWERED: 'hub_message_answered',
  // EMAIL_SENT: 'email_sent',
  // event sent by customer.io
  CUSTOMER_EMAIL_ATTEMPTED: 'email_attempted',
  CUSTOMER_EMAIL_CLICKED: 'email_clicked',
  CUSTOMER_EMAIL_DELIVERED: 'email_delivered',
  CUSTOMER_EMAIL_FAILED: 'email_failed',
  CUSTOMER_EMAIL_OPENED: 'email_opened',
  // CUSTOMER_EMAIL_SENT: 'email_sent',
  CUSTOMER_SLACK_CLICKED: 'slack_clicked',
  CUSTOMER_SLACK_FAILED: 'slack_failed',
  CUSTOMER_SLACK_SENT: 'slack_sent',
  CUSTOMER_SMS_ATTEMPTED: 'sms_attempted',
  CUSTOMER_SMS_DELIVERED: 'sms_delivered',
  CUSTOMER_SMS_FAILED: 'sms_failed',
  CUSTOMER_SMS_SENT: 'sms_sent',
  CUSTOMER_WEBHOOK_CLICKED: 'webhook_clicked',
  CUSTOMER_WEBHOOK_DRAFTED: 'webhook_drafted',
  CUSTOMER_WEBHOOK_FAILED: 'webhook_failed',
  CUSTOMER_WEBHOOK_SENT: 'webhook_sent',
  /* Corporate */
  CORPORATE_ROLE_UPDATED: 'corporate_role_updated',
  CORPORATE_ACCOUNT_REVIEWED: 'corporate_account_reviewed',
  CORPORATE_INVITATION_SENT: 'corporate_invitation_sent',
  CORPORATE_ACCOUNT_DELETED: 'corporate_account_deleted',
  CORPORATE_ACCOUNT_DISABLED: 'corporate_account_disabled',
  CORPORATE_ACCOUNT_REVIVED: 'corporate_account_revived',
  CORPORATE_ACCOUNT_CREATED: 'corporate_account_created',
  CORPORATE_PROFILE_UPDATED: 'corporate_profile_updated',
  /* Company */
  COMPANY_LOGO_ADDED: 'company_logo_added',
  COMPANY_LOGO_UPDATED: 'company_logo_updated',
  BILLING_METHOD_UPDATED: 'billing_method_updated',
  COMPANY_MERGED: 'company_merged',
  COMPANY_CREATED: 'company_created',
  COMPANY_UPDATED: 'company_updated',
  KYC_DOCUMENT_ACCEPTED: 'kyc_document_accepted',
  KYC_DOCUMENT_REFUSED: 'kyc_document_refused',
  KYC_DOCUMENT_UPDATED: 'kyc_document_updated',
  KYC_DOCUMENT_SUBMITTED: 'kyc_document_submitted',
  KYC_DOCUMENT_REQUESTED: 'kyc_document_requested',
  KYC_DOCUMENT_DELETED: 'kyc_document_deleted',
  COMPANY_CONTRACTING_VALIDATED: 'company_contracting_validated',
  COMPANY_CONTRACTING_INVALIDATED: 'company_contracting_invalidated',
  COMPANY_BILLING_VALIDATED: 'company_billing_validated',
  COMPANY_BILLING_INVALIDATED: 'company_billing_invalidated',
  /* User */
  AUTH_DELETED: 'auth_deleted',
  PASSWORD_RESET: 'password_reset',
  USER_LOGIN: 'user_login',
  USER_LOGOUT: 'user_logout',
  USER_ACTIVATED: 'user_activated',
  USER_ACTIVATION_FAILED: 'user_activation_failed',
  PREUSER_CREATED: 'preuser_created',
  USER_REGISTERED: 'user_registered',
  USER_IMPERSONATED: 'user_impersonated',
  USER_IMPERSONATION_STOPPED: 'user_impersonation_stopped',
  /* Team Member */
  SLACK_CONNECTED: 'slack_connected',
  TEAM_MEMBER_PROFILE_UPDATED: 'team_member_profile_updated',
  /* Qualification */
  QUALIFICATION_SLOT_BOOKED: 'qualification_slot_booked',
  QUALIFICATION_SLOT_UNAVAILABLE: 'qualification_slot_unavailable',
  QUALIFICATION_PASSED: 'qualification_passed',
  QUALIFICATION_FAILED: 'qualification_failed',
  QUALIFICATION_UPDATED: 'qualification_updated',
  QUALIFICATION_CANCELLED: 'qualification_cancelled',
  FREELANCE_FLAGGED_FOR_QUALIFICATION: 'freelance_flagged_for_qualification',
  QUALIFICATION_REQUEST_ACCEPTED: 'qualification_request_accepted',
  QUALIFICATION_REQUEST_DENIED: 'qualification_request_denied',
  /* Mission */
  MISSION_CREATED: 'mission_created',
  MISSION_UPDATED: 'mission_updated',
  MISSION_DRAFTED: 'mission_drafted', // TODO Sarah verify when this is triggered
  MISSION_DRAFT_FINALIZED: 'mission_draft_finalized',
  MISSION_APPROVED: 'mission_approved',
  MISSION_VALIDATED: 'mission_validated', // Mission was validated, matching starts
  MISSION_CONTRACTING_STARTED: 'mission_contracting_started',
  MISSION_CONTRACT_SIGNED: 'mission_contract_signed',
  MISSION_CONTRACTING_FAILED: 'mission_contracting_failed',
  MISSION_FAILED: 'mission_failed',
  MISSION_REVIVED: 'mission_revived',
  MISSION_REFUSED: 'mission_refused',
  MISSION_DELETED: 'mission_deleted',
  MISSION_FINISHED: 'mission_finished',
  MISSION_REOPENED: 'mission_reopened',
  MISSION_END_DATE_ADD: 'mission_end_date_add',
  MISSION_END_DATE_EDIT: 'mission_end_date_edit',
  MISSION_END_DATE_REMOVE: 'mission_end_date_remove',
  MISSION_REVIEWED: 'mission_reviewed', // No to be mixed with the REVIEWED status. Here it is one of these reviews has been made
  MISSION_FACTORING_CHECKED: 'mission_factoring_checked',
  MISSION_CONFLICT_ADDED: 'mission_conflict_added',
  MISSION_CONFLICT_RESOLVED: 'mission_conflict_resolved',
  MISSION_ACTION_RESOLVED: 'mission_action_resolved',
  MISSION_DUPLICATED: 'mission_duplicated',
  MISSION_HUNTING_TOKEN_GENERATED: 'mission_hunting_token_generated',
  /* Deal */
  DEAL_CREATED: 'deal_created',
  DEAL_TERMS_UPDATED: 'deal_terms_updated',
  DEAL_TERMS_ACCEPTED: 'deal_terms_accepted',
  DEAL_SIGNING_STARTED: 'deal_signing_started',
  // TODO signatures individuelles
  DEAL_DOCUMENT_DELETED: 'deal_document_deleted',
  DEAL_UPDATED: 'deal_updated',
  DEAL_INVALIDATED: 'deal_invalidated',
  DEAL_VALIDATED: 'deal_validated',
  DEAL_KYC_VALIDATED: 'deal_kyc_validated',
  DEAL_KYC_VALIDATION_BYPASSED: 'deal_kyc_validation_bypassed',
  DEAL_SPECIFIC_MENTIONS_BYPASSED: 'deal_specific_mentions_bypassed',
  DEAL_SIGNATURE_BYPASSED: 'deal_signature_bypassed',
  DEAL_SIGNED: 'deal_signed',
  DEAL_RENEWAL_DECLINED: 'deal_renewal_declined',
  // DEAL_TOKEN_VALIDATED: 'deal_token_validated',
  // KYC Validated for this deal
  /* Worklog */
  WORK_LOG_SUBMITTED: 'work_log_submitted',
  WORK_LOG_PAID: 'work_log_paid',
  WORK_LOG_LOCKED: 'work_log_locked',
  WORK_LOG_CREATED: 'work_log_created',
  WORK_LOG_UPDATED: 'work_log_updated',
  /* Tools */
  NETSUITE_SYNC_TRIGGERED: 'netsuite_sync_triggered',
  NETSUITE_SYNC_TRIGGER_FAILED: 'netsuite_sync_trigger_failed',
  EXTERNAL_EVENT_LOGGED: 'external_event_logged',
  LINKEDIN_SCRAPPING_SUCCEEDED: 'linkedin_scrapping_succeeded',
  LINKEDIN_SCRAPPING_FAILED: 'linkedin_scrapping_failed',
  /* Attachment */
  ATTACHMENT_TEXT_ADDED: 'attachment_text_added',
  ATTACHMENT_CALL_ADDED: 'attachment_call_added',
  ATTACHMENT_EMAIL_ADDED: 'attachment_email_added',
  ATTACHMENT_MEET_ADDED: 'attachment_meet_added',
  ATTACHMENT_LOG_ADDED: 'attachment_log_added',
  ATTACHMENT_FILE_ADDED: 'attachment_file_added',
  // INVOICE V2 - TIMESHEET
  TIMESHEET_EXPENSE_UPDATED: 'timesheet-expense-updated',
  TIMESHEET_UPDATED: 'timesheet-updated',
  TIMESHEET_SUBMITED: 'timesheet-submited',
  TIMESHEET_CRA_VALIDATED: 'timesheet-cra-validated',
  TIMESHEET_VALIDATED: 'timesheet-validated',
  TIMESHEET_STATUS_SYNCED_WITH_NETSUITE: 'timesheet-status-synced-with-netsuite',
  TIMESHEET_NETSUITE_INVOICE_GENERATED: 'timesheet-netsuite-invoice-generated',
  TIMESHEET_ENABLE_SAFE_MODE: 'timesheet-enable-safe-mode',
  TIMESHEET_DISABLE_SAFE_MODE: 'timesheet-disable-safe-mode',
  // INVOICE V2 - COMPANY
  COMPANY_PRESTATION_PARAM_UPDATED: 'company-prestation-param-updated',
  COMPANY_EXPENSE_PARAM_UPDATED: 'company-expense-param-updated',
  COMPANY_CRA_PARAM_UPDATED: 'company-cra-param-updated',
  COMPANY_PARAM_MENTION_UPDATED: 'company-param-mention-updated',
  COMPANY_INVOICE_GROUP_UPDATED: 'company-invoice-group-updated',
  COMPANY_INVOICE_SUBGROUP_UPDATED: 'company-invoice-subgroup-updated',
  COMPANY_INVOICE_PARAMS_VALIDATED: 'company-invoice-params-validated',
  // INVOICE V2 - MISSION
  MISSION_PARAM_MENTION_UPDATED: 'mission-param-mention-updated',
  // INVOICE V2 - DEAL
  DEAL_PARAM_MENTION_UPDATED: 'deal-param-mention-updated',
  // INVOICE V2 - WORK_LOG
  WORK_LOG_PARAM_MENTION_UPDATED: 'worklog-param-mention-updated',
  WORK_LOG_REOPONED_AFTER_SKIP: 'worklog-reopened-after-skip',
  WORK_LOG_SKIPPED: 'worklog-skipped',
  // INVOICE V2 - INVOICE
  INVOICE_ITEMS_CREATED: 'invoice-items-created',
  INVOICE_ORDERS_CREATED: 'invoice-orders-created',
}

/**
 * These types will be filtered out from the timeline by default
 */
const HiddenByDefaultTypes = [
  /**
   * To test, I'm commenting all events that will, in the future, be hidden
   * When test is done, uncomment these
   */
  Type.PAGE_VIEW,
  Type.PAGE_PING,
  Type.ELEMENT_CLICKED,
  Type.CRISP_CHATBOX_OPENED,
  Type.CRISP_CHATBOX_CLOSED,
  Type.CRISP_MESSAGE_RECEIVED,
  Type.CUSTOM_PAGE_VIEW,
  Type.CONVERSATION_VIEWED,
  Type.SLACK_MESSAGE,
  Type.SLACK_MESSAGE_THREAD,
  Type.SLACK_MESSAGE_CHANGED,
  Type.SLACK_MESSAGE_CHANGED_THREAD,
  Type.SLACK_MESSAGE_REACTION,
  Type.FREELANCE_EXPERIENCE_UPDATED,
  Type.FREELANCE_EXPERIENCE_DELETED,
  Type.FREELANCE_REFERENCE_UPDATED,
  Type.FREELANCE_REFERENCE_DENIED,
  Type.FREELANCE_REFERENCE_REMOVED,
  Type.FREELANCE_REFERENCE_CANCELLED,
  Type.PROFILE_PICTURE_ADDED,
  Type.SPOTTED_AS_BEACON,
  Type.SPOTTED_AS_TWO_TIER,
  Type.MISSION_APPLICATION_STARTED,
  Type.MISSION_MATCH_CREATED,
  Type.MISSION_MATCH_UPDATED,
  Type.MISSION_MATCH_DECLINED,
  Type.MISSION_MATCH_DESELECTED,
  Type.MISSION_MATCH_DELETED,
  Type.MATCHING_LIST_REFRESHED,
  Type.MATCHING_LIST_RESET,
  Type.HUB_CONVERSATION_STARTED,
  Type.SLACK_INVITATION_SENT,
  Type.NOTIFICATION_PARAMETERS_UPDATED,
  Type.HUB_MESSAGE_OPENED,
  Type.HUB_MESSAGE_CLICKED,
  Type.HUB_MESSAGE_ANSWERED,
  Type.COMPANY_LOGO_ADDED,
  Type.COMPANY_LOGO_UPDATED,
  Type.KYC_DOCUMENT_ACCEPTED,
  Type.KYC_DOCUMENT_REFUSED,
  Type.KYC_DOCUMENT_UPDATED,
  Type.KYC_DOCUMENT_REQUESTED,
  Type.KYC_DOCUMENT_DELETED,
  Type.AUTH_DELETED,
  Type.DEAL_DOCUMENT_DELETED,
  Type.WORK_LOG_PAID,
  Type.WORK_LOG_LOCKED,
  Type.WORK_LOG_CREATED,
  Type.WORK_LOG_UPDATED,
  Type.NETSUITE_SYNC_TRIGGER_FAILED,
  Type.NETSUITE_SYNC_TRIGGERED,
  Type.EXTERNAL_EVENT_LOGGED,
  Type.LINKEDIN_SCRAPPING_SUCCEEDED,
  Type.LINKEDIN_SCRAPPING_FAILED,
]

const SnowplowTypeCategoryMapping = {
  [Category.NAVIGATION]: [
    Type.PAGE_VIEW,
    Type.ELEMENT_CLICKED,
    Type.CUSTOM_PAGE_VIEW,
    Type.PAGE_PING,
    Type.CRISP_CHATBOX_OPENED,
    Type.CRISP_CHATBOX_CLOSED,
    Type.CRISP_MESSAGE_SENT,
    Type.CRISP_MESSAGE_RECEIVED,
  ],
  [Category.INTERACTION]: [
    Type.CHAT_MESSAGE_SENT,
    Type.CONVERSATION_VIEWED,
    Type.CONVERSATION_ARCHIVED,
    Type.CONVERSATION_REVIVED,
    Type.CHAT_ATTACHMENT_SENT,
    Type.NEW_COLLABORATION_PROPOSED,
    Type.SLACK_MESSAGE,
    Type.SLACK_MESSAGE_THREAD,
    Type.SLACK_MESSAGE_CHANGED,
    Type.SLACK_MESSAGE_CHANGED_THREAD,
    Type.SLACK_MESSAGE_REACTION,
  ],
  [Category.FREELANCE]: [
    Type.FREELANCE_PROFILE_UPDATED,
    Type.FREELANCE_FAVORITE_ADDED,
    Type.FREELANCE_FAVORITE_REMOVED,
    Type.FREELANCE_EXPERIENCE_ADDED,
    Type.FREELANCE_EXPERIENCE_UPDATED,
    Type.FREELANCE_EXPERIENCE_DELETED,
    Type.FREELANCE_REFERENCE_ADDED,
    Type.FREELANCE_REFERENCE_COMPLETED,
    Type.FREELANCE_REFERENCE_UPDATED,
    Type.FREELANCE_REFERENCE_DENIED,
    Type.FREELANCE_REFERENCE_REMOVED,
    Type.FREELANCE_REFERENCE_CANCELLED,
    Type.PROFILE_PICTURE_ADDED,
    Type.FREELANCE_SLACK_LINKED,
    Type.MISSION_PREFERENCES_COMPLETED,
    Type.FREELANCE_BECAME_UNAVAILABLE,
    Type.FREELANCE_BECAME_AVAILABLE,
    Type.FREELANCE_VALIDATED,
    Type.FREELANCE_UPVOTED,
    Type.FREELANCE_DOWNVOTED,
    Type.SPOTTED_AS_BEACON,
    Type.SPOTTED_AS_TWO_TIER,
    Type.FREELANCER_DISABLE_INVOICING,
    Type.FREELANCER_ENABLE_INVOICING,
  ],
  [Category.MATCH]: [
    Type.MISSION_APPLICATION_STARTED,
    Type.MISSION_MATCH_ACCEPTED,
    Type.MISSION_MATCH_PROPOSED,
    Type.MISSION_MATCH_CREATED,
    Type.MISSION_MATCH_UPDATED,
    Type.MISSION_MATCH_DECLINED,
    Type.MISSION_MATCH_SELECTED,
    Type.MISSION_MATCH_DESELECTED,
    Type.MISSION_MATCH_PRESENTED,
    Type.MEETING_SLOTS_SET,
    Type.MEETING_SCHEDULED,
    Type.MEETING_RESCHEDULED,
    Type.MISSION_MATCH_DELETED,
    Type.MISSION_MATCH_CONFIRMED_BY_FREELANCE,
    Type.MISSION_MATCH_CONFIRMED_BY_CLIENT,
    Type.MATCHING_LIST_REFRESHED,
    Type.MATCHING_LIST_RESET,
  ],
  [Category.COMMUNICATION]: [
    // Emails and notifications
    Type.HUB_CONVERSATION_STARTED,
    Type.SLACK_INVITATION_SENT,
    Type.NOTIFICATION_PARAMETERS_UPDATED,
    Type.HUB_MESSAGE_SENT,
    Type.HUB_MESSAGE_OPENED,
    Type.HUB_MESSAGE_CLICKED,
    Type.HUB_MESSAGE_ANSWERED,
    // Type.EMAIL_SENT,
    Type.CUSTOMER_EMAIL_ATTEMPTED,
    Type.CUSTOMER_EMAIL_CLICKED,
    Type.CUSTOMER_EMAIL_DELIVERED,
    Type.CUSTOMER_EMAIL_FAILED,
    Type.CUSTOMER_EMAIL_OPENED,
    // Type.CUSTOMER_EMAIL_SENT,
    Type.CUSTOMER_SLACK_CLICKED,
    Type.CUSTOMER_SLACK_FAILED,
    Type.CUSTOMER_SLACK_SENT,
    Type.CUSTOMER_SMS_ATTEMPTED,
    Type.CUSTOMER_SMS_DELIVERED,
    Type.CUSTOMER_SMS_FAILED,
    Type.CUSTOMER_SMS_SENT,
    Type.CUSTOMER_WEBHOOK_CLICKED,
    Type.CUSTOMER_WEBHOOK_DRAFTED,
    Type.CUSTOMER_WEBHOOK_FAILED,
    Type.CUSTOMER_WEBHOOK_SENT,
  ],
  [Category.CORPORATE]: [
    Type.CORPORATE_ROLE_UPDATED,
    Type.CORPORATE_ACCOUNT_REVIEWED,
    Type.CORPORATE_INVITATION_SENT,
    Type.CORPORATE_ACCOUNT_DELETED,
    Type.CORPORATE_ACCOUNT_DISABLED,
    Type.CORPORATE_ACCOUNT_REVIVED,
    Type.CORPORATE_ACCOUNT_CREATED,
    Type.CORPORATE_PROFILE_UPDATED,
  ],
  [Category.COMPANY]: [
    Type.COMPANY_LOGO_ADDED,
    Type.COMPANY_LOGO_UPDATED,
    Type.BILLING_METHOD_UPDATED,
    Type.COMPANY_MERGED,
    Type.COMPANY_CREATED,
    Type.COMPANY_UPDATED,
    Type.KYC_DOCUMENT_ACCEPTED,
    Type.KYC_DOCUMENT_REFUSED,
    Type.KYC_DOCUMENT_UPDATED,
    Type.KYC_DOCUMENT_SUBMITTED,
    Type.KYC_DOCUMENT_REQUESTED,
    Type.KYC_DOCUMENT_DELETED,
    Type.COMPANY_CONTRACTING_VALIDATED,
    Type.COMPANY_CONTRACTING_INVALIDATED,
    Type.COMPANY_BILLING_VALIDATED,
    Type.COMPANY_BILLING_INVALIDATED,
    Type.COMPANY_PRESTATION_PARAM_UPDATED,
    Type.COMPANY_EXPENSE_PARAM_UPDATED,
    Type.COMPANY_CRA_PARAM_UPDATED,
    Type.COMPANY_PARAM_MENTION_UPDATED,
    Type.COMPANY_INVOICE_GROUP_UPDATED,
    Type.COMPANY_INVOICE_SUBGROUP_UPDATED,
    Type.COMPANY_INVOICE_PARAMS_VALIDATED,
  ],
  [Category.USER]: [
    Type.AUTH_DELETED,
    Type.PASSWORD_RESET,
    Type.USER_LOGIN,
    Type.USER_LOGOUT,
    Type.USER_ACTIVATED,
    Type.USER_ACTIVATION_FAILED,
    Type.PREUSER_CREATED,
    Type.USER_REGISTERED,
    Type.USER_IMPERSONATED,
    Type.USER_IMPERSONATION_STOPPED,
  ],
  [Category.TEAM_MEMBER]: [Type.SLACK_CONNECTED, Type.TEAM_MEMBER_PROFILE_UPDATED],
  [Category.QUALIFICATION]: [
    Type.QUALIFICATION_SLOT_BOOKED,
    Type.QUALIFICATION_SLOT_UNAVAILABLE,
    Type.QUALIFICATION_PASSED,
    Type.QUALIFICATION_FAILED,
    Type.QUALIFICATION_UPDATED,
    Type.QUALIFICATION_CANCELLED,
    Type.FREELANCE_FLAGGED_FOR_QUALIFICATION,
    Type.QUALIFICATION_REQUEST_ACCEPTED,
    Type.QUALIFICATION_REQUEST_DENIED,
  ],
  [Category.MISSION]: [
    Type.MISSION_DRAFTED,
    Type.MISSION_DRAFT_FINALIZED,
    Type.MISSION_ACTION_RESOLVED,
    Type.MISSION_CREATED,
    Type.MISSION_UPDATED,
    Type.MISSION_VALIDATED,
    Type.MISSION_REVIVED,
    Type.MISSION_FAILED,
    Type.MISSION_REFUSED,
    Type.MISSION_DELETED,
    Type.MISSION_FINISHED,
    Type.MISSION_REOPENED,
    Type.MISSION_END_DATE_ADD,
    Type.MISSION_END_DATE_EDIT,
    Type.MISSION_REOPENED,
    Type.MISSION_END_DATE_REMOVE,
    Type.MISSION_REVIEWED, // Client and freelancer ratings genereate two mission_reviewed independent events
    Type.MISSION_APPROVED,
    Type.MISSION_FACTORING_CHECKED,
    Type.MISSION_CONFLICT_ADDED,
    Type.MISSION_CONFLICT_RESOLVED,
    Type.MISSION_DUPLICATED,
    Type.MISSION_CONTRACTING_FAILED,
    Type.MISSION_CONTRACTING_STARTED,
    Type.MISSION_CONTRACT_SIGNED,
    Type.MISSION_HUNTING_TOKEN_GENERATED,
    Type.TIMESHEET_EXPENSE_UPDATED,
    Type.MISSION_PARAM_MENTION_UPDATED,
    Type.WORK_LOG_REOPONED_AFTER_SKIP,
    Type.WORK_LOG_SKIPPED,
  ],
  [Category.DEAL]: [
    Type.DEAL_CREATED,
    Type.DEAL_TERMS_UPDATED,
    Type.DEAL_TERMS_ACCEPTED,
    Type.DEAL_SIGNED,
    // to add independent signatures
    Type.DEAL_SIGNING_STARTED,
    Type.DEAL_DOCUMENT_DELETED,
    Type.DEAL_UPDATED,
    Type.DEAL_RENEWAL_DECLINED,
    Type.DEAL_INVALIDATED,
    Type.DEAL_VALIDATED,
    // Type.DEAL_KYC_VALIDATION_VALIDATED, -- to add
    Type.DEAL_KYC_VALIDATION_BYPASSED,
    // Type.DEAL_SPECIFIC_MENTIONS_VALIDATED, -- to add
    Type.DEAL_SPECIFIC_MENTIONS_BYPASSED,
    Type.DEAL_SIGNATURE_BYPASSED,
    Type.DEAL_PARAM_MENTION_UPDATED,
  ],
  [Category.WORKLOG]: [
    Type.WORK_LOG_SUBMITTED,
    Type.WORK_LOG_PAID,
    Type.WORK_LOG_LOCKED,
    Type.WORK_LOG_CREATED,
    Type.WORK_LOG_UPDATED,
    Type.WORK_LOG_PARAM_MENTION_UPDATED,
    Type.WORK_LOG_REOPONED_AFTER_SKIP,
    Type.WORK_LOG_SKIPPED,
  ],
  [Category.TOOLS]: [
    Type.NETSUITE_SYNC_TRIGGER_FAILED,
    Type.NETSUITE_SYNC_TRIGGERED,
    Type.EXTERNAL_EVENT_LOGGED,
    Type.LINKEDIN_SCRAPPING_SUCCEEDED,
    Type.LINKEDIN_SCRAPPING_FAILED,
  ],
  [Category.ATTACHMENT]: [
    Type.ATTACHMENT_TEXT_ADDED,
    Type.ATTACHMENT_CALL_ADDED,
    Type.ATTACHMENT_EMAIL_ADDED,
    Type.ATTACHMENT_MEET_ADDED,
    Type.ATTACHMENT_LOG_ADDED,
    Type.ATTACHMENT_FILE_ADDED,
  ],
  [Category.TIMESHEET]: [
    Type.TIMESHEET_EXPENSE_UPDATED,
    Type.TIMESHEET_UPDATED,
    Type.TIMESHEET_SUBMITED,
    Type.TIMESHEET_CRA_VALIDATED,
    Type.TIMESHEET_VALIDATED,
    Type.TIMESHEET_STATUS_SYNCED_WITH_NETSUITE,
    Type.TIMESHEET_NETSUITE_INVOICE_GENERATED,
    Type.INVOICE_ITEMS_CREATED,
    Type.INVOICE_ORDERS_CREATED,
    Type.TIMESHEET_ENABLE_SAFE_MODE,
    Type.TIMESHEET_DISABLE_SAFE_MODE,
    Type.WORK_LOG_REOPONED_AFTER_SKIP,
    Type.WORK_LOG_SKIPPED,
  ],
}

/**
 * Iconography
 * Event Types & Categories: Try to use outline icons
 *
 * Comet objects and their icon interpretation
 * - Mission = a rocket (not so many icons with a rocket)
 * - Company = a home
 * - Corporate = a user, mdi-account-tie
 * - Freelancer = user ("account" in material icons) - Pre-user = a head ?
 * - Match = .. (used material "timeline" not fan)
 * - Deal = clipboard (not a 100% fan)
 * - Worklog = folder
 * - Chat = message
 * - KYC = file
 */

const SnowplowIconCategoryMapping = {
  [Category.NAVIGATION]: 'mdi-cursor-default-outline',
  [Category.INTERACTION]: 'mdi-message-text-outline',
  [Category.FREELANCE]: 'mdi-card-account-details-outline',
  [Category.MATCH]: 'mdi-timeline-outline',
  [Category.COMMUNICATION]: 'mdi-email-outline',
  [Category.CORPORATE]: 'mdi-account-tie-outline',
  [Category.COMPANY]: 'mdi-home-outline',
  [Category.USER]: 'mdi-account-circle-outline',
  [Category.TEAM_MEMBER]: 'mdi-account-heart-outline',
  [Category.QUALIFICATION]: 'mdi-school-outline',
  [Category.MISSION]: 'mdi-rocket-outline',
  [Category.DEAL]: 'mdi-clipboard-text-outline',
  [Category.WORKLOG]: 'mdi-folder-account-outline',
  [Category.TIMESHEET]: 'mdi-folder-account-outline',
  [Category.TOOLS]: 'mdi-cloud-outline',
  [Category.ATTACHMENT]: 'mdi-plus',
}

const SnowplowIconTypeMapping = {
  /* Navigation */
  [Type.PAGE_VIEW]: 'mdi-file-eye-outline',
  [Type.ELEMENT_CLICKED]: 'mdi-cursor-default-click-outline',
  [Type.CUSTOM_PAGE_VIEW]: 'mdi-file-eye-outline',
  [Type.CRISP_CHATBOX_OPENED]: 'mdi-comment-outline mdi-rotate-315',
  [Type.CRISP_CHATBOX_CLOSED]: 'mdi-comment-outline mdi-rotate-315',
  [Type.CRISP_MESSAGE_SENT]: 'mdi-comment-outline mdi-rotate-315',
  [Type.CRISP_MESSAGE_RECEIVED]: 'mdi-comment-outline mdi-rotate-315',
  /* Interaction */
  [Type.CHAT_MESSAGE_SENT]: 'mdi-message-arrow-right-outline',
  [Type.CONVERSATION_VIEWED]: 'mdi-comment-eye-outline',
  [Type.CONVERSATION_ARCHIVED]: 'mdi-comment-remove-outline',
  [Type.CONVERSATION_REVIVED]: 'mdi-comment-check-outline',
  [Type.CHAT_ATTACHMENT_SENT]: 'mdi-message-image-outline',
  [Type.NEW_COLLABORATION_PROPOSED]: 'mdi-hand-heart',
  [Type.SLACK_MESSAGE]: 'mdi-slack',
  [Type.SLACK_MESSAGE_THREAD]: 'mdi-slack',
  [Type.SLACK_MESSAGE_CHANGED]: 'mdi-slack',
  [Type.SLACK_MESSAGE_CHANGED_THREAD]: 'mdi-slack',
  [Type.SLACK_MESSAGE_REACTION]: 'mdi-slack',
  /* Freelance */
  [Type.FREELANCE_PROFILE_UPDATED]: 'mdi-account-edit-outline',
  [Type.COMPANY_UPDATED]: 'mdi-home-edit-outline',
  [Type.FREELANCE_EXPERIENCE_ADDED]: 'mdi-movie-filter-outline',
  [Type.FREELANCE_EXPERIENCE_UPDATED]: 'mdi-movie-edit-outline',
  [Type.FREELANCE_EXPERIENCE_DELETED]: 'mdi-movie-outline mdi-rotate-135',
  [Type.FREELANCE_REFERENCE_ADDED]: 'mdi-account-multiple-plus-outline',
  [Type.FREELANCE_REFERENCE_COMPLETED]: 'mdi-account-multiple-check-outline',
  [Type.FREELANCE_REFERENCE_UPDATED]: 'mdi-account-multiple-outline',
  [Type.FREELANCE_REFERENCE_DENIED]: 'mdi-account-multiple-remove-outline',
  [Type.FREELANCE_REFERENCE_REMOVED]: 'mdi-account-multiple-remove-outline',
  [Type.FREELANCE_REFERENCE_CANCELLED]: 'mdi-account-multiple-remove-outline',
  [Type.PROFILE_PICTURE_ADDED]: 'mdi-account-box-outline',
  [Type.FREELANCE_SLACK_LINKED]: 'mdi-slack',
  [Type.FREELANCE_BECAME_UNAVAILABLE]: 'mdi-alarm-off',
  [Type.FREELANCE_BECAME_AVAILABLE]: 'mdi-alarm-plus',
  [Type.FREELANCE_FAVORITE_ADDED]: 'mdi-account-star-outline',
  [Type.FREELANCE_FAVORITE_REMOVED]: 'mdi-account-star-outline',
  [Type.FREELANCE_UPVOTED]: 'mdi-menu-up',
  [Type.FREELANCE_DOWNVOTED]: 'mdi-menu-down',
  [Type.MISSION_PREFERENCES_COMPLETED]: 'mdi-briefcase-search-outline',
  [Type.FREELANCE_VALIDATED]: 'mdi-account-check-outline',
  /* Match */
  [Type.MISSION_MATCH_ACCEPTED]: 'mdi-timeline-plus-outline',
  [Type.MISSION_MATCH_PROPOSED]: 'mdi-timeline-help-outline',
  [Type.MISSION_MATCH_UPDATED]: 'mdi-pencil-box-outline',
  [Type.MISSION_MATCH_DECLINED]: 'mdi-timeline-alert-outline',
  [Type.MISSION_MATCH_DESELECTED]: 'mdi-timeline-alert-outline',
  [Type.MISSION_MATCH_DELETED]: 'mdi-timeline-alert-outline',
  [Type.MISSION_MATCH_SELECTED]: 'mdi-timeline-clock-outline',
  [Type.MISSION_MATCH_PRESENTED]: 'mdi-tie',
  [Type.MISSION_MATCH_CONFIRMED_BY_CLIENT]: 'mdi-thumb-up-outline',
  [Type.MISSION_MATCH_CONFIRMED_BY_FREELANCE]: 'mdi-thumb-up-outline',
  [Type.MATCHING_LIST_REFRESHED]: 'mdi-timeline-text-outline',
  [Type.MATCHING_LIST_RESET]: 'mdi-timeline-text-outline',
  [Type.MEETING_SLOTS_SET]: 'mdi-calendar-clock',
  [Type.MEETING_SCHEDULED]: 'mdi-calendar-check',
  [Type.MEETING_RESCHEDULED]: 'mdi-calendar-check',
  /* Communication */
  [Type.SLACK_INVITATION_SENT]: 'mdi-slack',
  [Type.HUB_CONVERSATION_STARTED]: 'mdi-email-sync-outline',
  [Type.HUB_MESSAGE_SENT]: 'mdi-email-send-outline',
  [Type.HUB_MESSAGE_OPENED]: 'mdi-email-search-outline',
  [Type.HUB_MESSAGE_CLICKED]: 'mdi-email-plus-outline',
  [Type.HUB_MESSAGE_ANSWERED]: 'mdi-email-edit-outline',
  [Type.NOTIFICATION_PARAMETERS_UPDATED]: 'mdi-message-cog-outline',
  // [Type.EMAIL_SENT]: 'mdi-gmail',
  [Type.CUSTOMER_EMAIL_ATTEMPTED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_EMAIL_CLICKED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_EMAIL_DELIVERED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_EMAIL_FAILED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_EMAIL_OPENED]: 'mdi-email-send-outline',
  // [Type.CUSTOMER_EMAIL_SENT]: 'mdi-email-send-outline',
  [Type.CUSTOMER_SLACK_CLICKED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_SLACK_FAILED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_SLACK_SENT]: 'mdi-email-send-outline',
  [Type.CUSTOMER_SMS_ATTEMPTED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_SMS_DELIVERED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_SMS_FAILED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_SMS_SENT]: 'mdi-email-send-outline',
  [Type.CUSTOMER_WEBHOOK_CLICKED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_WEBHOOK_DRAFTED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_WEBHOOK_FAILED]: 'mdi-email-send-outline',
  [Type.CUSTOMER_WEBHOOK_SENT]: 'mdi-email-send-outline',
  /* Corporate */
  [Type.CORPORATE_ACCOUNT_DELETED]: 'mdi-account-tie-voice-off-outline',
  [Type.CORPORATE_ACCOUNT_DISABLED]: 'mdi-account-tie-voice-off-outline',
  [Type.CORPORATE_ACCOUNT_REVIVED]: 'mdi-account-tie-voice-outline',
  [Type.CORPORATE_PROFILE_UPDATED]: 'mdi-account-edit-outline',
  /* Company */
  [Type.COMPANY_LOGO_ADDED]: 'mdi-home-heart',
  [Type.COMPANY_LOGO_UPDATED]: 'mdi-home-heart',
  [Type.BILLING_METHOD_UPDATED]: 'mdi-home-currency-usd',
  [Type.COMPANY_MERGED]: 'mdi-home-export-outline',
  [Type.COMPANY_CREATED]: 'mdi-home-circle-outline',
  [Type.COMPANY_UPDATED]: 'mdi-home-edit-outline',
  [Type.KYC_DOCUMENT_ACCEPTED]: 'mdi-file-check-outline',
  [Type.KYC_DOCUMENT_REFUSED]: 'mdi-file-remove-outline',
  [Type.KYC_DOCUMENT_UPDATED]: 'mdi-file-edit-outline',
  [Type.KYC_DOCUMENT_SUBMITTED]: 'mdi-file-move-outline',
  [Type.KYC_DOCUMENT_REQUESTED]: 'mdi-file-swap-outline',
  [Type.KYC_DOCUMENT_DELETED]: 'mdi-file-remove-outline',
  [Type.COMPANY_CONTRACTING_VALIDATED]: 'mdi-folder-home-outline',
  [Type.COMPANY_CONTRACTING_INVALIDATED]: 'mdi-folder-home-outline',
  [Type.COMPANY_BILLING_VALIDATED]: 'mdi-home-currency-usd',
  [Type.COMPANY_BILLING_INVALIDATED]: 'mdi-home-currency-usd',
  /* Mission */
  [Type.MISSION_UPDATED]: 'mdi-page-next-outline',
  [Type.MISSION_VALIDATED]: 'mdi-clock-start',
  [Type.MISSION_CONTRACT_SIGNED]: 'mdi-rocket',
  [Type.MISSION_DELETED]: 'mdi-rocket-outline mdi-rotate-135',
  [Type.MISSION_FAILED]: 'mdi-rocket-outline mdi-rotate-135',
  [Type.MISSION_REFUSED]: 'mdi-rocket-outline mdi-rotate-135',
  /* Deal */
  [Type.DEAL_CREATED]: 'mdi-clipboard-outline',
  [Type.DEAL_TERMS_UPDATED]: 'mdi-clipboard-list-outline',
  [Type.DEAL_TERMS_ACCEPTED]: 'mdi-clipboard-check-outline',
  [Type.DEAL_SIGNED]: 'mdi-clipboard-check',
  // to add independent signatures
  [Type.DEAL_SIGNING_STARTED]: 'mdi-clipboard-file-outline',
  [Type.DEAL_DOCUMENT_DELETED]: 'mdi-clipboard-file-outline',
  [Type.DEAL_UPDATED]: 'mdi-clipboard-flow-outline',
  [Type.DEAL_RENEWAL_DECLINED]: 'mdi-clipboard-alert-outline',
  [Type.DEAL_INVALIDATED]: 'mdi-clipboard-alert-outline',
  [Type.DEAL_VALIDATED]: 'mdi-clipboard-play-outline',
  // Type.DEAL_KYC_VALIDATION_VALIDATED, -- to add
  [Type.DEAL_KYC_VALIDATION_BYPASSED]: 'mdi-clipboard-pulse-outline',
  // Type.DEAL_SPECIFIC_MENTIONS_VALIDATED, -- to add
  [Type.DEAL_SPECIFIC_MENTIONS_BYPASSED]: 'mdi-clipboard-pulse-outline',
  [Type.DEAL_SIGNATURE_BYPASSED]: 'mdi-clipboard-pulse-outline',
  /* Worklog */
  [Type.WORK_LOG_SUBMITTED]: 'mdi-folder-download-outline',
  [Type.WORK_LOG_PAID]: 'mdi-cash-usd-outline',
  [Type.WORK_LOG_LOCKED]: 'mdi-folder-key',
  [Type.WORK_LOG_CREATED]: 'mdi-folder-alert-outline',
  [Type.WORK_LOG_UPDATED]: 'mdi-folder-edit-outline',
  /* Tools */
  [Type.LINKEDIN_SCRAPPING_SUCCEEDED]: 'mdi-linkedin',
  [Type.LINKEDIN_SCRAPPING_FAILED]: 'mdi-linkedin',
  /* Attachment */
  [Type.ATTACHMENT_TEXT_ADDED]: 'mdi-note-text-outline',
  [Type.ATTACHMENT_CALL_ADDED]: 'mdi-phone-outline',
  [Type.ATTACHMENT_EMAIL_ADDED]: 'mdi-email-outline',
  [Type.ATTACHMENT_MEET_ADDED]: 'mdi-calendar-blank',
  [Type.ATTACHMENT_LOG_ADDED]: 'mdi-playlist-check',
  [Type.ATTACHMENT_FILE_ADDED]: 'mdi-paperclip',
  /* User */
  [Type.USER_LOGIN]: 'mdi-location-enter',
  [Type.USER_LOGOUT]: 'mdi-location-exit',
  [Type.USER_ACTIVATED]: 'mdi-head-check-outline',
  [Type.PASSWORD_RESET]: 'mdi-account-key-outline',
  [Type.PREUSER_CREATED]: 'mdi-head-plus-outline',
  [Type.AUTH_DELETED]: 'mdi-shield-off-outline',
  [Type.USER_ACTIVATION_FAILED]: 'mdi-head-alert-outline',
  [Type.USER_REGISTERED]: 'mdi-head-lightbulb-outline',
  [Type.USER_IMPERSONATED]: 'mdi-incognito',
  [Type.USER_IMPERSONATION_STOPPED]: 'mdi-incognito-off',
}

/**
 * Format a snowplow event based on its type and payload
 * @param {Object} params
 * @returns {String}
 */
export function formatSnowplowEvent(params) {
  const translations = {
    /* Navigation */
    [Type.PAGE_VIEW]: __('ty:snowplow-event:page-view', params),
    [Type.ELEMENT_CLICKED]: __('ty:snowplow-event:element-clicked', params),
    [Type.CUSTOM_PAGE_VIEW]: __('ty:snowplow-event:custom-page-view', params),
    [Type.PAGE_PING]: __('ty:snowplow-event:page-ping', params),
    [Type.CRISP_CHATBOX_OPENED]: __('ty:snowplow-event:crisp-chatbox-opened', params),
    [Type.CRISP_CHATBOX_CLOSED]: __('ty:snowplow-event:crisp-chatbox-closed', params),
    [Type.CRISP_MESSAGE_SENT]: __('ty:snowplow-event:crisp-message-sent', params),
    [Type.CRISP_MESSAGE_RECEIVED]: __('ty:snowplow-event:crisp-message-received', params),
    /* Interaction */
    [Type.CHAT_MESSAGE_SENT]: __('ty:snowplow-event:chat-message-sent', params),
    [Type.CHAT_ATTACHMENT_SENT]: __('ty:snowplow-event:chat-attachment-sent', params),
    [Type.SLACK_MESSAGE]: __('ty:snowplow-event:slack-message', params),
    [Type.SLACK_MESSAGE_THREAD]: __('ty:snowplow-event:slack-message-thread', params), // to be merged with above
    [Type.SLACK_MESSAGE_CHANGED]: __('ty:snowplow-event:slack-message-changed', params),
    [Type.SLACK_MESSAGE_CHANGED_THREAD]: __(
      'ty:snowplow-event:slack-message-changed-thread',
      params,
    ), // redundant
    [Type.SLACK_MESSAGE_REACTION]: __('ty:snowplow-event:snowplow-event:slack-reaction', params),
    [Type.CONVERSATION_REVIVED]: __('ty:snowplow-event:conversation-revived', params),
    [Type.NEW_COLLABORATION_PROPOSED]: __('ty:snowplow-event:new-collaboration-proposed', params),
    [Type.CONVERSATION_VIEWED]: __('ty:snowplow-event:conversation-viewed', params),
    [Type.CONVERSATION_ARCHIVED]: __('ty:snowplow-event:conversation-archived', params),
    /* Freelance */
    [Type.FREELANCE_PROFILE_UPDATED]: __('ty:snowplow-event:freelance-profile-updated', params),
    [Type.FREELANCE_FAVORITE_ADDED]: __('ty:snowplow-event:freelance-favorite-added', params),
    [Type.FREELANCE_FAVORITE_REMOVED]: __('ty:snowplow-event:freelance-favorite-removed', params),
    [Type.FREELANCE_EXPERIENCE_ADDED]: __('ty:snowplow-event:freelance-experience-added', params),
    [Type.FREELANCE_EXPERIENCE_UPDATED]: __(
      'ty:snowplow-event:freelance-experience-updated',
      params,
    ),
    [Type.FREELANCE_EXPERIENCE_DELETED]: __(
      'ty:snowplow-event:freelance-experience-deleted',
      params,
    ),
    [Type.FREELANCE_REFERENCE_ADDED]: __('ty:snowplow-event:freelance-reference-added', params),
    [Type.FREELANCE_REFERENCE_COMPLETED]: __(
      'ty:snowplow-event:freelance-reference-completed',
      params,
    ),
    [Type.FREELANCE_REFERENCE_UPDATED]: __('ty:snowplow-event:freelance-reference-updated', params),
    [Type.FREELANCE_REFERENCE_DENIED]: __('ty:snowplow-event:freelance-reference-denied', params),
    [Type.FREELANCE_REFERENCE_REMOVED]: __('ty:snowplow-event:freelance-reference-removed', params),
    [Type.FREELANCE_REFERENCE_CANCELLED]: __(
      'ty:snowplow-event:freelance-reference-cancelled',
      params,
    ),
    [Type.PROFILE_PICTURE_ADDED]: __('ty:snowplow-event:profile-picture-added', params),
    [Type.FREELANCE_SLACK_LINKED]: __('ty:snowplow-event:freelance-slack-linked', params),
    [Type.MISSION_PREFERENCES_COMPLETED]: __(
      'ty:snowplow-event:mission-preferences-completed',
      params,
    ),
    [Type.FREELANCE_BECAME_UNAVAILABLE]: __(
      'ty:snowplow-event:freelance-became-unavailable',
      params,
    ),
    [Type.FREELANCE_BECAME_AVAILABLE]: __('ty:snowplow-event:freelance-became-available', params),
    [Type.FREELANCE_VALIDATED]: __('ty:snowplow-event:freelance-validated', params),
    [Type.FREELANCE_UPVOTED]: __('ty:snowplow-event:freelance-upvoted', params),
    [Type.FREELANCE_DOWNVOTED]: __('ty:snowplow-event:freelance-downvoted', params),
    [Type.SPOTTED_AS_BEACON]: __('ty:snowplow-event:spotted-as-beacon', params),
    [Type.SPOTTED_AS_TWO_TIER]: __('ty:snowplow-event:spotted-as-two-tier', params),
    /* Freelancer V2 */
    [Type.FREELANCER_ENABLE_INVOICING]: __('ty:snowplow-event:freelancer-enable-invoicing', params),
    [Type.FREELANCER_DISABLE_INVOICING]: __(
      'ty:snowplow-event:freelancer-disable-invoicing',
      params,
    ),
    /* Mission Match */
    [Type.MISSION_APPLICATION_STARTED]: __('ty:snowplow-event:mission-application-started', params), // a freelancer opened the Apply to Mission Modal
    [Type.MISSION_MATCH_ACCEPTED]: __('ty:snowplow-event:mission-match-accepted', params), // freelancer applied
    [Type.MISSION_MATCH_PROPOSED]: __('ty:snowplow-event:mission-match-proposed', params),
    [Type.MISSION_MATCH_CREATED]: __('ty:snowplow-event:mission-match-created', params),
    [Type.MISSION_MATCH_UPDATED]: __('ty:snowplow-event:mission-match-updated', params),
    [Type.MISSION_MATCH_DECLINED]: __('ty:snowplow-event:mission-match-declined', params),
    [Type.MISSION_MATCH_SELECTED]: __('ty:snowplow-event:mission-match-selected', params),
    [Type.MISSION_MATCH_DESELECTED]: __('ty:snowplow-event:mission-match-deselected', params),
    [Type.MISSION_MATCH_PRESENTED]: __('ty:snowplow-event:mission-match-presented', params),
    [Type.MISSION_MATCH_CONFIRMED_BY_FREELANCE]: __(
      'ty:snowplow-event:mission-match-confirmed-by-freelance',
      params,
    ),
    [Type.MISSION_MATCH_CONFIRMED_BY_CLIENT]: __(
      'ty:snowplow-event:mission-match-confirmed-by-client',
      params,
    ),
    [Type.MEETING_SLOTS_SET]: __('ty:snowplow-event:meeting-slots-set', params),
    [Type.MEETING_SCHEDULED]: __('ty:snowplow-event:meeting-scheduled', params),
    [Type.MEETING_RESCHEDULED]: __('ty:snowplow-event:meeting-rescheduled', params),
    [Type.MISSION_MATCH_DELETED]: __('ty:snowplow-event:mission-match-deleted', params),
    // TODO rajouter le confirmed
    [Type.MATCHING_LIST_REFRESHED]: __('ty:snowplow-event:matching-list-refreshed', params),
    [Type.MATCHING_LIST_RESET]: __('ty:snowplow-event:matching-list-reset', params),
    /* Communication */
    [Type.HUB_CONVERSATION_STARTED]: __('ty:snowplow-event:hub-conversation-started', params),
    [Type.SLACK_INVITATION_SENT]: __('ty:snowplow-event:slack-invitation-sent', params),
    [Type.NOTIFICATION_PARAMETERS_UPDATED]: __(
      'ty:snowplow-event:notification-parameters-updated',
      params,
    ),
    [Type.HUB_MESSAGE_SENT]: __('ty:snowplow-event:hub-message-sent', params),
    [Type.HUB_MESSAGE_OPENED]: __('ty:snowplow-event:hub-message-opened', params),
    [Type.HUB_MESSAGE_CLICKED]: __('ty:snowplow-event:hub-message-clicked', params),
    [Type.HUB_MESSAGE_ANSWERED]: __('ty:snowplow-event:hub-message-answered', params),
    [Type.EMAIL_SENT]: __('ty:snowplow-event:message-sent', params),
    /* Corporate */
    [Type.CORPORATE_ROLE_UPDATED]: __('ty:snowplow-event:corporate-role-updated', params),
    [Type.CORPORATE_ACCOUNT_REVIEWED]: __('ty:snowplow-event:corporate-account-reviewed', params),
    [Type.CORPORATE_INVITATION_SENT]: __('ty:snowplow-event:corporate-invitation-sent', params),
    [Type.CORPORATE_ACCOUNT_DELETED]: __('ty:snowplow-event:corporate-account-deleted', params),
    [Type.CORPORATE_ACCOUNT_DISABLED]: __('ty:snowplow-event:corporate-account-disabled', params),
    [Type.CORPORATE_ACCOUNT_REVIVED]: __('ty:snowplow-event:corporate-account-revived', params),
    [Type.CORPORATE_ACCOUNT_CREATED]: __('ty:snowplow-event:corporate-account-created', params),
    [Type.CORPORATE_PROFILE_UPDATED]: __('ty:snowplow-event:corporate-profile-updated', params),
    /* Company */
    [Type.COMPANY_LOGO_ADDED]: __('ty:snowplow-event:company-logo-added', params),
    [Type.COMPANY_LOGO_UPDATED]: __('ty:snowplow-event:company-logo-updated', params),
    [Type.BILLING_METHOD_UPDATED]: __('ty:snowplow-event:billing-method-updated', params),
    [Type.COMPANY_MERGED]: __('ty:snowplow-event:company-merged', params),
    [Type.COMPANY_CREATED]: __('ty:snowplow-event:company-created', params),
    [Type.COMPANY_UPDATED]: __('ty:snowplow-event:company-updated', params),
    [Type.KYC_DOCUMENT_ACCEPTED]: __('ty:snowplow-event:kyc-document-accepted', params),
    [Type.KYC_DOCUMENT_REFUSED]: __('ty:snowplow-event:kyc-document-refused', params),
    [Type.KYC_DOCUMENT_UPDATED]: __('ty:snowplow-event:kyc-document-updated', params),
    [Type.KYC_DOCUMENT_SUBMITTED]: __('ty:snowplow-event:kyc-document-submitted', params),
    [Type.KYC_DOCUMENT_REQUESTED]: __('ty:snowplow-event:kyc-document-requested', params),
    [Type.KYC_DOCUMENT_DELETED]: __('ty:snowplow-event:kyc-document-deleted', params),
    [Type.COMPANY_CONTRACTING_VALIDATED]: __(
      'ty:snowplow-event:company-contracting-validated',
      params,
    ),
    [Type.COMPANY_CONTRACTING_INVALIDATED]: __(
      'ty:snowplow-event:company-contracting-invalidated',
      params,
    ),
    [Type.COMPANY_BILLING_VALIDATED]: __('ty:snowplow-event:company-billing-validated', params),
    [Type.COMPANY_BILLING_INVALIDATED]: __('ty:snowplow-event:company-billing-invalidated', params),
    /* User */
    [Type.AUTH_DELETED]: __('ty:snowplow-event:auth-deleted', params),
    [Type.PASSWORD_RESET]: __('ty:snowplow-event:password-reset', params),
    [Type.USER_LOGIN]: __('ty:snowplow-event:user-login', params),
    [Type.USER_LOGOUT]: __('ty:snowplow-event:user-logout', params),
    [Type.USER_ACTIVATED]: __('ty:snowplow-event:user-activated', params),
    [Type.USER_ACTIVATION_FAILED]: __('ty:snowplow-event:user-activation-failed', params),
    [Type.PREUSER_CREATED]: __('ty:snowplow-event:preuser-created', params),
    [Type.USER_REGISTERED]: __('ty:snowplow-event:user-registered', params),
    [Type.USER_IMPERSONATED]: __('ty:snowplow-event:user-impersonated', params),
    [Type.USER_IMPERSONATION_STOPPED]: __('ty:snowplow-event:user-impersonation-stopped', params),
    /* Team Member */
    [Type.SLACK_CONNECTED]: __('ty:snowplow-event:slack-connected', params),
    [Type.TEAM_MEMBER_PROFILE_UPDATED]: __('ty:snowplow-event:team-member-profile-updated', params),
    /* Qualification */
    [Type.QUALIFICATION_SLOT_BOOKED]: __('ty:snowplow-event:qualification-slot-booked', params),
    [Type.QUALIFICATION_SLOT_UNAVAILABLE]: __(
      'ty:snowplow-event:qualification-slot-unavailable',
      params,
    ),
    [Type.QUALIFICATION_PASSED]: __('ty:snowplow-event:qualification-passed', params),
    [Type.QUALIFICATION_FAILED]: __('ty:snowplow-event:qualification-failed', params),
    [Type.QUALIFICATION_UPDATED]: __('ty:snowplow-event:qualification-updated', params),
    [Type.QUALIFICATION_CANCELLED]: __('ty:snowplow-event:qualification-cancelled', params),
    [Type.FREELANCE_FLAGGED_FOR_QUALIFICATION]: __(
      'ty:snowplow-event:freelance-flagged-for-qualification',
      params,
    ),
    [Type.QUALIFICATION_REQUEST_ACCEPTED]: __(
      'ty:snowplow-event:qualification-request-accepted',
      params,
    ),
    [Type.QUALIFICATION_REQUEST_DENIED]: __(
      'ty:snowplow-event:qualification-request-denied',
      params,
    ),
    /* Mission */
    [Type.MISSION_CREATED]: __('ty:snowplow-event:mission-created', params),
    [Type.MISSION_UPDATED]: __('ty:snowplow-event:mission-updated', params),
    [Type.MISSION_DRAFTED]: __('ty:snowplow-event:mission-drafted', params), // TODO Sarah verify when this is triggered
    [Type.MISSION_DRAFT_FINALIZED]: __('ty:snowplow-event:mission-draft-finalized', params),
    [Type.MISSION_APPROVED]: __('ty:snowplow-event:mission-approved', params),
    [Type.MISSION_VALIDATED]: __('ty:snowplow-event:mission-validated', params), // Mission was validated, matching starts
    [Type.MISSION_CONTRACTING_STARTED]: __('ty:snowplow-event:mission-contracting-started', params),
    [Type.MISSION_CONTRACT_SIGNED]: __('ty:snowplow-event:mission-contract-signed', params),
    [Type.MISSION_CONTRACTING_FAILED]: __('ty:snowplow-event:mission-contracting-failed', params),
    [Type.MISSION_FAILED]: __('ty:snowplow-event:mission-failed', params),
    [Type.MISSION_REVIVED]: __('ty:snowplow-event:mission-revived', params),
    [Type.MISSION_REFUSED]: __('ty:snowplow-event:mission-refused', params),
    [Type.MISSION_DELETED]: __('ty:snowplow-event:mission-deleted', params),
    [Type.MISSION_FINISHED]: __('ty:snowplow-event:mission-finished', params),
    [Type.MISSION_REOPENED]: __('ty:snowplow-event:mission-end-date-reopened', params),
    [Type.MISSION_END_DATE_ADD]: __('ty:snowplow-event:mission-end-date-add', {
      endDate: format('date', params?.payload?.source.mission?.[0]?.endDate, 'writtendate'),
    }),
    [Type.MISSION_END_DATE_EDIT]: __('ty:snowplow-event:mission-end-date-edit', {
      endDate: format('date', params?.payload?.source.mission?.[0]?.endDate, 'writtendate'),
    }),
    [Type.MISSION_REOPENED]: __('ty:snowplow-event:mission-reopened', params),
    [Type.MISSION_END_DATE_REMOVE]: __('ty:snowplow-event:mission-end-date-remove', params),
    [Type.MISSION_REVIEWED]: __('ty:snowplow-event:mission-reviewed', params), // No to be mixed with the REVIEWED status. Here it is one of these reviews has been made
    [Type.MISSION_FACTORING_CHECKED]: __('ty:snowplow-event:mission-factoring-checked', params),
    [Type.MISSION_CONFLICT_ADDED]: __('ty:snowplow-event:mission-conflict-added', params),
    [Type.MISSION_CONFLICT_RESOLVED]: __('ty:snowplow-event:mission-conflict-resolved', params),
    [Type.MISSION_ACTION_RESOLVED]: __('ty:snowplow-event:mission-action-resolved', params),
    [Type.MISSION_DUPLICATED]: __('ty:snowplow-event:mission-duplicated', params),
    [Type.MISSION_HUNTING_TOKEN_GENERATED]: __(
      'ty:snowplow-event:mission-hunting-token-generated',
      params,
    ),
    /* Deal */
    [Type.DEAL_CREATED]: __('ty:snowplow-event:deal-created', params),
    [Type.DEAL_TERMS_UPDATED]: __('ty:snowplow-event:deal-terms-updated', params),
    [Type.DEAL_TERMS_ACCEPTED]: __('ty:snowplow-event:deal-terms-accepted', params),
    [Type.DEAL_SIGNING_STARTED]: __('ty:snowplow-event:deal-signing-started', params),
    // TODO signatures individuelles + other events
    [Type.DEAL_DOCUMENT_DELETED]: __('ty:snowplow-event:deal-document-deleted', params),
    [Type.DEAL_UPDATED]: __('ty:snowplow-event:deal-updated', params),
    [Type.DEAL_RENEWAL_DECLINED]: __('ty:snowplow-event:deal-renewal-declined', params),
    [Type.DEAL_INVALIDATED]: __('ty:snowplow-event:deal-invalidated', params),
    [Type.DEAL_VALIDATED]: __('ty:snowplow-event:deal-validated', params),
    [Type.DEAL_KYC_VALIDATED]: __('ty:snowplow-event:deal-kyc-validated', params),
    [Type.DEAL_KYC_VALIDATION_BYPASSED]: __(
      'ty:snowplow-event:deal-kyc-validation-bypassed',
      params,
    ),
    [Type.DEAL_SPECIFIC_MENTIONS_BYPASSED]: __(
      'ty:snowplow-event:deal-specific-mentions-bypassed',
      params,
    ),
    [Type.DEAL_SIGNATURE_BYPASSED]: __('ty:snowplow-event:deal-signature-bypassed', params),
    [Type.DEAL_SIGNED]: __('ty:snowplow-event:deal-signed', params),
    /* Worklog */
    [Type.WORK_LOG_SUBMITTED]: __('ty:snowplow-event:worklog-submitted', params),
    [Type.WORK_LOG_PAID]: __('ty:snowplow-event:worklog-paid', params),
    [Type.WORK_LOG_LOCKED]: __('ty:snowplow-event:worklog-locked', params),
    [Type.WORK_LOG_CREATED]: __('ty:snowplow-event:worklog-created', params),
    [Type.WORK_LOG_UPDATED]: __('ty:snowplow-event:worklog-updated', params),
    /* Tools */
    [Type.NETSUITE_SYNC_TRIGGERED]: __('ty:snowplow-event:netsuite-sync-triggered', params),
    [Type.NETSUITE_SYNC_TRIGGER_FAILED]: __(
      'ty:snowplow-event:netsuite-sync-trigger-failed',
      params,
    ),
    [Type.EXTERNAL_EVENT_LOGGED]: __('ty:snowplow-event:external-event-logged', params),
    [Type.LINKEDIN_SCRAPPING_SUCCEEDED]: __(
      'ty:snowplow-event:linkedin-scrapping-succeeded',
      params,
    ),
    [Type.LINKEDIN_SCRAPPING_FAILED]: __('ty:snowplow-event:linkedin-scrapping-failed', params),
    /* Attachment */
    [Type.ATTACHMENT_TEXT_ADDED]: __('ty:snowplow-event:attachment-text-added', params),
    [Type.ATTACHMENT_CALL_ADDED]: __('ty:snowplow-event:attachment-call-added', params),
    [Type.ATTACHMENT_EMAIL_ADDED]: __('ty:snowplow-event:attachment-email-added', params),
    [Type.ATTACHMENT_MEET_ADDED]: __('ty:snowplow-event:attachment-meet-added', params),
    [Type.ATTACHMENT_LOG_ADDED]: __('ty:snowplow-event:attachment-log-added', params),
    [Type.ATTACHMENT_FILE_ADDED]: __('ty:snowplow-event:attachment-file-added', params),
    /* Customer.IO */
    [Type.CUSTOMER_EMAIL_ATTEMPTED]: __('ty:snowplow-event:customer-message:email-attempted', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_EMAIL_CLICKED]: __('ty:snowplow-event:customer-message:email-clicked', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_EMAIL_DELIVERED]: __('ty:snowplow-event:customer-message:email-delivered', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_EMAIL_FAILED]: __('ty:snowplow-event:customer-message:email-failed', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_EMAIL_OPENED]: __('ty:snowplow-event:customer-message:email-opened', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_EMAIL_SENT]: __('ty:snowplow-event:customer-message:email-sent', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_SLACK_CLICKED]: __('ty:snowplow-event:customer-message:slack-clicked', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_SLACK_FAILED]: __('ty:snowplow-event:customer-message:slack-failed', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_SLACK_SENT]: __('ty:snowplow-event:customer-message:slack-sent', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_SMS_ATTEMPTED]: __('ty:snowplow-event:customer-message:sms-attempted', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_SMS_DELIVERED]: __('ty:snowplow-event:customer-message:sms-delivered', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_SMS_FAILED]: __('ty:snowplow-event:customer-message:sms-failed', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_SMS_SENT]: __('ty:snowplow-event:customer-message:sms-sent', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_WEBHOOK_CLICKED]: __('ty:snowplow-event:customer-message:web-clicked', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_WEBHOOK_DRAFTED]: __('ty:snowplow-event:customer-message:web-drafted', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_WEBHOOK_FAILED]: __('ty:snowplow-event:customer-message:web-failed', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    [Type.CUSTOMER_WEBHOOK_SENT]: __('ty:snowplow-event:customer-message:web-sent', {
      campaign: _get(params, 'customerioMessage[0].campaignName'),
    }),
    // INVOICE V2
    [Type.TIMESHEET_EXPENSE_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:timesheet-expense-updated',
      params,
    ),
    [Type.COMPANY_PRESTATION_PARAM_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:company-prestation-param-updated',
      params,
    ),
    [Type.COMPANY_EXPENSE_PARAM_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:company-expense-param-updated',
      params,
    ),
    [Type.COMPANY_CRA_PARAM_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:company-cra-param-updated',
      params,
    ),
    [Type.COMPANY_PARAM_MENTION_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:company-param-mention-updated',
      params,
    ),
    [Type.COMPANY_INVOICE_GROUP_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:company-invoice-group-updated',
      params,
    ),
    [Type.COMPANY_INVOICE_SUBGROUP_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:company-invoice-subgroup-updated',
      params,
    ),
    [Type.COMPANY_INVOICE_PARAMS_VALIDATED]: __(
      'ty:snowplow-event:invoice-v2:company-invoice-params-validated',
      params,
    ),
    [Type.MISSION_PARAM_MENTION_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:mission-param-mention-updated',
      params,
    ),
    [Type.DEAL_PARAM_MENTION_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:deal-param-mention-updated',
      params,
    ),
    [Type.WORK_LOG_PARAM_MENTION_UPDATED]: __(
      'ty:snowplow-event:invoice-v2:worklog-param-mention-updated',
      params,
    ),
    [Type.TIMESHEET_UPDATED]: __('ty:snowplow-event:invoice-v2:timesheet-updated', params),
    [Type.TIMESHEET_SUBMITED]: __('ty:snowplow-event:invoice-v2:timesheet-submited', params),
    [Type.TIMESHEET_CRA_VALIDATED]: __(
      'ty:snowplow-event:invoice-v2:timesheet-cra-validated',
      params,
    ),
    [Type.TIMESHEET_VALIDATED]: __('ty:snowplow-event:invoice-v2:timesheet-validated', params),
    [Type.INVOICE_ITEMS_CREATED]: __('ty:snowplow-event:invoice-v2:invoice-items-created', params),
    [Type.INVOICE_ORDERS_CREATED]: __(
      'ty:snowplow-event:invoice-v2:invoice-orders-created',
      params,
    ),
    [Type.TIMESHEET_STATUS_SYNCED_WITH_NETSUITE]: __(
      'ty:snowplow-event:invoice-v2:timesheet-status-synced-with-netsuite',
      params,
    ),
    [Type.TIMESHEET_NETSUITE_INVOICE_GENERATED]: __(
      'ty:snowplow-event:invoice-v2:timesheet-netsuite-invoice-generated',
      params,
    ),
    [Type.TIMESHEET_ENABLE_SAFE_MODE]: __(
      'ty:snowplow-event:invoice-v2:timesheet-enable-safe-mode',
      params,
    ),
    [Type.TIMESHEET_DISABLE_SAFE_MODE]: __(
      'ty:snowplow-event:invoice-v2:timesheet-disable-safe-mode',
      params,
    ),
    [Type.WORK_LOG_REOPONED_AFTER_SKIP]: __(
      'ty:snowplow-event:invoice-v2:worklog-reopened-after-skip',
      params.deals?.[0]?.id
        ? {
            ...params,
            dealId: params.deals[0].id,
          }
        : params,
    ),
    [Type.WORK_LOG_SKIPPED]: __(
      'ty:snowplow-event:invoice-v2:worklog-skipped',
      params.deals?.[0]?.id
        ? {
            ...params,
            dealId: params.deals[0].id,
          }
        : params,
    ),
  }

  return (
    translations[params.type] || (params && params.type) || __('ty:snowplow-event:unknown', params)
  )
}

/**
 * Format the description of an event category
 */
export function formatSnowplowCategoryDescription(category) {
  const translations = {
    [Category.NAVIGATION]: __('ty:snowplow-category-description:navigation'),
    [Category.INTERACTION]: __('ty:snowplow-category-description:interaction'),
    [Category.FREELANCE]: __('ty:snowplow-category-description:freelance'),
    [Category.MATCH]: __('ty:snowplow-category-description:match'),
    [Category.COMMUNICATION]: __('ty:snowplow-category-description:communication'),
    [Category.CORPORATE]: __('ty:snowplow-category-description:corporate'),
    [Category.COMPANY]: __('ty:snowplow-category-description:company'),
    [Category.USER]: __('ty:snowplow-category-description:user'),
    [Category.TEAM_MEMBER]: __('ty:snowplow-category-description:team-member'),
    [Category.QUALIFICATION]: __('ty:snowplow-category-description:qualification'),
    [Category.MISSION]: __('ty:snowplow-category-description:mission'),
    [Category.DEAL]: __('ty:snowplow-category-description:deal'),
    [Category.WORKLOG]: __('ty:snowplow-category-description:worklog'),
    [Category.TOOLS]: __('ty:snowplow-category-description:tools'),
    [Category.ATTACHMENT]: __('ty:snowplow-category-description:attachment'),
  }

  return translations[category]
}

/**
 * Root SnowplowEventTypes object
 */
const SnowplowEventTypes = {
  SnowplowEventType: Type,
  SnowplowHiddenByDefaultTypes: HiddenByDefaultTypes,
  SnowplowEventCategory: Category,
  SnowplowEventEntity: Entity,
  SnowplowTypeCategoryMapping,
  SnowplowIconTypeMapping,
  SnowplowIconCategoryMapping,
}

export default SnowplowEventTypes
