import _get from 'lodash/get'
import _omit from 'lodash/omit'

/**
 * Values in the `form` we don't want to sync in Vuex (most of the time,
 * containing functions)
 * @type {Array.<String>}
 */
const NOT_SYNCED = ['onSubmit', 'validators', 'watch', 'watchers']

/**
 * Check if some actions exist in the store.
 * FormMixin synchronisation is tightly coupled to some actions in the store.
 * Problem is that the website won't implement them.
 * @param {Object} store
 * @returns {Boolean}
 */
function canSync(store) {
  const { createSyncForm, destroySyncForm, updateSyncForm } = _get(store, '_actions', {})

  return createSyncForm && destroySyncForm && updateSyncForm
}

/**
 * Returns the fields values stored in Vuex for the form identify by 'name'
 * @param {String} name
 * @returns {Object}
 */
function getSyncedFields(name) {
  const { form, $store } = this

  const getter = _get($store, 'getters.selectSyncFormValues', () => null)

  return getter(name || form.name)
}

/**
 * Easy access to the exposed Vuex action
 * @param {Object} form
 * @returns {void}
 */
function createSyncedForm(form) {
  const { $store } = this

  if (canSync($store)) {
    $store.dispatch('createSyncForm', _omit(form, NOT_SYNCED))
  }
}

/**
 * Easy access to the exposed Vuex action
 * @param {Object} form
 * @returns {void}
 */
function updateSyncedForm(form) {
  const { $store } = this

  if (canSync($store)) {
    $store.dispatch('updateSyncForm', _omit(form, NOT_SYNCED))
  }
}

/**
 * Easy access to the exposed Vuex action
 * @param {String} name
 * @returns {void}
 */
function destroySyncedForm(name) {
  const { $store } = this

  if (canSync($store)) {
    $store.dispatch('destroySyncForm', name)
  }
}

export { getSyncedFields, createSyncedForm, updateSyncedForm, destroySyncedForm }
