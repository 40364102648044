<template>
  <nav class="pagination">
    <div v-if="limitOptions.length" class="limits">
      <SingleSelect
        id="limit"
        name="limit"
        class="inline dark"
        placeholder=" "
        :value="safeLimit"
        :options="limitOptions"
        :disabled="disabled"
        @input="onLimitChange"
      />
      <span>{{ __('cp:components:layout:pagination:limits:label') }}</span>
    </div>

    <div v-if="areLinksVisible" class="pages">
      <AppLink v-if="previousPage" class="arrow" :to="previousPage">
        <i class="icon-chevron-left" />
      </AppLink>

      <span v-if="startPages">
        <AppLink v-for="page in startPages" :key="page" :to="getPageRoute(page)">
          {{ page }}
        </AppLink>
        ...
      </span>

      <AppLink
        v-for="page in currentPages"
        :key="page"
        :class="getPageClasses(page)"
        :to="getPageRoute(page)"
      >
        {{ page }}
      </AppLink>

      <span v-if="endPages">
        ...
        <AppLink v-for="page in endPages" :key="page" :to="getPageRoute(page)">
          {{ page }}
        </AppLink>
      </span>

      <AppLink v-if="nextPage" class="arrow" :to="nextPage">
        <i class="icon-chevron-right" />
      </AppLink>
    </div>
  </nav>
</template>

<script>
  import _get from 'lodash/get'
  import _head from 'lodash/head'
  import _isEqual from 'lodash/isEqual'
  import _last from 'lodash/last'
  import _range from 'lodash/range'

  import AppLink from '@/core/controls/AppLink/AppLink'
  import SingleSelect from '@/core/inputs/SingleSelect/SingleSelect'
  import I18nMixin from '@/mixins/I18nMixin'
  import RouterMixin from '@/mixins/RouterMixin'
  import { int } from '@/utils/transform'

  /**
   * List of the available limits
   * @type {Array.<Number>}
   */
  const LIMITS = [25, 50, 100]

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  SingleSelect: SingleSelect.__enums,
},

    name: 'Pagination',
    components: {
      AppLink,
      SingleSelect,
    },
    mixins: [I18nMixin, RouterMixin],
    props: {
      /**
       * Value object
       */
      value: {
        type: Object,
        default: null,
      },
      /**
       * Total items count
       */
      total: {
        type: Number,
        required: true,
      },
      /**
       * Number of links displayed around the current one
       */
      range: {
        type: Number,
        default: 3,
      },
      /**
       * Number of links displayed as margins (min and max)
       */
      margin: {
        type: Number,
        default: 1,
      },
      /**
       * If 'true', all the pagination controls are disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Currently selected page (1-based index)
       * @type {Number}
       */
      selected() {
        const { safeLimit, value } = this

        if (!value) {
          return 1
        }

        const current = value.offset + 1 // From "included" 0-based to 1-based
        const size = safeLimit || 1

        return Math.ceil(current / size)
      },
      /**
       * Max number of accessible pages
       * @type {Number}
       */
      max() {
        const { safeLimit, value, total } = this

        return value ? Math.ceil(total / (safeLimit || 1)) : 1
      },
      /**
       * Return the route of the previous page and determines the
       * visibility of the corresponding arrow
       * @type {String}
       */
      previousPage() {
        const { selected, getPageRoute } = this

        return selected > 1 ? getPageRoute(selected - 1) : null
      },
      /**
       * Return the route of the next page and determines the
       * visibility of the corresponding arrow
       * @type {String}
       */
      nextPage() {
        const { selected, max, getPageRoute } = this

        return selected < max ? getPageRoute(selected + 1) : null
      },
      /**
       * List of pages representing the current place
       * (ex: 1 2 ... 4 [5] 6 ... 8 9)
       * @type {Boolean}
       */
      currentPages() {
        const { selected, max, range } = this

        const gap = Math.floor(range / 2)
        const head = Math.max(1, Math.min(max - range + 1, selected - gap))
        const tail = Math.min(max, Math.max(range, selected + gap))

        return _range(head, tail + 1, 1)
      },
      /**
       * List of pages representing the beginning, if far from it
       * (ex: 1 2 ... 4 [5] 6 ... 8 9)
       * @type {Boolean}
       */
      startPages() {
        const { currentPages, margin } = this

        const head = _head(currentPages)

        return head > 1 ? _range(1, Math.min(margin, head - 1) + 1) : null
      },
      /**
       * List of pages representing the end, if far from it
       * (ex: 1 2 ... 4 [5] 6 ... 8 9)
       * @type {Boolean}
       */
      endPages() {
        const { currentPages, max, margin } = this

        const tail = _last(currentPages)

        return tail < max ? _range(Math.max(max - margin, tail) + 1, max + 1) : null
      },
      /**
       * Is 'true' if the page/arrow links should be visible or not
       * (i.e. there is more than one page)
       * @type {Boolean}
       */
      areLinksVisible() {
        const { max } = this

        return max > 1
      },
      /**
       * List of the limits provided in the select input, as per total
       * @type {Array.<Object>}
       */
      limitOptions() {
        const { total } = this

        return (
          LIMITS
            // Keep only the next value just higher than total
            .filter((limit, index, limits) =>
              index === 0 ? limit < total : limits[index - 1] < total,
            )
            .map(l => ({
              id: l,
              name: `${l}`,
            }))
        )
      },
      /**
       * Returns the bounded 'limit' value.
       * If 'limit' is bigger than the higher available value in 'limitOptions',
       * we cound it.
       * @type {String}
       */
      safeLimit() {
        const { value, limitOptions } = this

        if (!value) {
          return LIMITS[0]
        }

        const maxLimit = _get(_last(limitOptions), 'id', LIMITS[0])

        return Math.min(value.limit, maxLimit) || LIMITS[0]
      },
    },
    watch: {
      /**
       * The pagination component is watching the route changes in order to
       * keep its 'offset' and 'limit' up-to-date with path modifications.
       * This allows us to code the page/arrow links as simple web links.
       * @param {Object} next
       * @returns {void}
       */
      route: {
        deep: true,
        immediate: true,
        handler(next) {
          const { value, safeLimit, limitOptions } = this
          const { query } = next

          const limit = int(query.limit || safeLimit || limitOptions[0])
          const page = Math.max(0, int(query.page) - 1 || 0)
          const offset = page * limit
          const newValue = { offset, limit }

          if (!value || !_isEqual(value, newValue)) {
            this.$emit('input', newValue)
          }
        },
      },
    },
    methods: {
      /**
       * Returns the route path corresponding to the given 1-based 'page' index
       * @param {Number} page
       * @returns {String}
       */
      getPageRoute(page) {
        const { safeLimit, route } = this

        return {
          path: route.path,
          query: {
            ...route.query,
            page,
            limit: safeLimit,
          },
        }
      },
      /**
       * Returns the expected CSS class for the given 'page' link
       * @param {Number} page
       * @returns {Object}
       */
      getPageClasses(page) {
        const { selected } = this

        return {
          active: page === selected,
        }
      },
      /**
       * Navigate to the same path with new 'page' and 'limit' query Params
       * @param {Number} page
       * @param {Number} limit
       * @returns {void}
       */
      navigateToPage(page, limit) {
        const { route, navigate } = this

        navigate({
          path: route.path,
          query: {
            ...route.query,
            page,
            limit,
          },
        })
      },
      /**
       * Called when the value of the limit select is changing
       * @param {Number} limit
       * @returns {void}
       */
      onLimitChange(limit) {
        const { navigateToPage } = this

        navigateToPage(1, limit)
      },
    },
  }
</script>

<style lang="stylus">

  @import '~@/assets/css/_variables.styl'

  .pagination {
    display: flex
    justify-content: space-between
    align-items: center
    margin: 40px 0

    .limits {
      display: flex
      flex-direction: row
      align-items: center
      font-weight: $font-bold
      font-size: 12px
      color: var(--color-font-light)
      text-transform: uppercase

      > * {
        margin-right: 16px
      }
    }

    .pages {
      text-align: right

      a {
        display: inline-block
        font-family: inherit
        font-size: 14px
        line-height: 24px
        color: var(--color-font-light)
        padding: 0 8px
        vertical-align: top

        &.arrow {
          padding: 0
        }

        i {
          display: block
          font-size: 22px
          line-height: 24px
          color: var(--color-font)
        }
      }

      .active {
        color: var(--color-font-contrast) !important
        background-color: var(--color-black)
      }
    }
  }
</style>
