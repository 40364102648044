<template>
  <Transition name="slide-y" :appear="true">
    <div :class="[$style.breadcrumb, classes]">
      <ul :class="$style.list">
        <li v-for="(item, i) in items" :key="i" :class="$style.item">
          <Icon
            v-if="i && item.title"
            name="chevron-right"
            :theme="Icon.Theme.GREY"
            :class="$style.separator"
          />

          <AppLink
            :to="item.path"
            :class="{
              [$style.link]: true,
              [$style.active]: i !== items.length - 1,
            }"
          >
            <ProfilePicture
              v-if="item.image"
              :url="item.image"
              :size="ProfilePicture.Size.XXXXS"
              :border="ProfilePicture.Border.BACKGROUND"
              :class="$style.image"
            />

            <Txt
              :class="$style.title"
              :value="item.title"
              :size="Txt.Size.XS"
              :theme="i === items.length - 1 ? Txt.Theme.DEFAULT : Txt.Theme.GREY"
            />
          </AppLink>
        </li>
      </ul>
    </div>
  </Transition>
</template>

<script>
  import _camelCase from 'lodash/camelCase'

  import AppLink from '@/core/controls/AppLink/AppLink'
  import Icon from '@/core/graphics/Icon/Icon'
  import ProfilePicture from '@/components/freelancer/ProfilePicture/ProfilePicture'
  import Txt from '@/core/text/Txt/Txt'

  const Theme = {
    GRADIENT: 'gradient',
    NONE: 'none',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AppLink: AppLink.__enums,
  Icon: Icon.__enums,
  ProfilePicture: ProfilePicture.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Theme,
    name: 'Breadcrumb',
    __enums: {
      Theme,
    },
    components: {
      AppLink,
      Icon,
      ProfilePicture,
      Txt,
    },
    props: {
      /**
       * List of items displayed in the breadcrumb
       * Expected fields :
       *   - title
       *   - path (optional)
       *   - image (optional)
       *   - track (optional)
       */
      items: {
        type: Array,
        required: true,
      },
      /**
       * Theme applied to the breadcrumb
       * @type {Txt.Theme}
       */
      theme: {
        type: String,
        default: Theme.GRADIENT,
      },
    },
    computed: {
      /**
       * Compute the classes according to the given props
       * @type {Object}
       */
      classes() {
        const { $style, theme } = this

        return {
          [$style[_camelCase(`theme-${theme}`)]]: true,
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_variables.styl'

  $breacrumb-height = 50px

  .breadcrumb {
    position: relative
    height: $breacrumb-height
    min-width: 100%
    min-height: $breacrumb-height
    max-width: 100%
    max-height: $breacrumb-height
    display: flex
    flex-direction: row
    align-items: center
    box-sizing: border-box
    overflow-x: scroll
    overflow-y: hidden

    &.themeGradient {
      background-image: linear-gradient(
        to right,
        var(--color-background-dual),
        var(--color-white),
        var(--color-white)
      )

      .breadcrumb:after {
        content: ' '
        position: absolute
        bottom: 1px
        left: 0
        right: 0
        height: 1px
        background-image: linear-gradient(
          to right,
          var(--color-grey-30),
          var(--color-white),
          var(--color-white)
        )
      }
    }

    .themeNone {
      background-image: initial
      background: none
    }

    .list {
      display: flex
      flex-direction: row
      align-items: center
      padding: 4px 32px !important

      @media phone {
        padding: 4px 16px !important
      }
    }

    .item {
      display: flex
      align-items: center
      white-space: nowrap

      .image {
        margin-right: 12px
      }

      .title {
        max-width: 20em
        overflow-x: hidden
        text-overflow: ellipsis
      }

      .link {
        display: flex
        flex-direction: row
        align-items: center
        padding: 8px 12px

        &.active:hover {
          color: var(--color-black)

          > * {
            color: inherit
          }
        }

        &:not(.active) > * {
          font-weight: $font-semi-bold
        }
      }
    }
  }
</style>
