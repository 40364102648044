<template>
  <div class="card" :class="{ clickable }" @click="onPress">
    <div class="card-content" :class="`border--${border}`">
      <div class="card-head">
        <div v-if="withPicture" :class="$('picture-wrapper')">
          <ImageFallback
            :image="picture"
            :radius="ImageFallback.Radius.SQUARE"
            :fallback="fallbackPicture"
            class="card-picture"
          />
        </div>
        <div v-if="withPicture" :style="pictureStyle" />
        <div class="card-titles">
          <div class="card-titles-top">
            <Headings level="4" :title="title" :sub="subtitle" />
            <slot name="tags" />
          </div>
          <slot name="caption" />
        </div>
        <div class="card-infos">
          <slot name="infos" />
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
  import Headings from '@/core/text/Headings/Headings'
  import ImageFallback from '@/core/graphics/ImageFallback/ImageFallback'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Headings: Headings.__enums,
  ImageFallback: ImageFallback.__enums,
},

    name: 'Card',
    components: {
      Headings,
      ImageFallback,
    },
    props: {
      /**
       * Color of the border (see styles)
       */
      border: {
        type: String,
        default: 'blue',
      },
      title: {
        type: String,
        default: null,
      },
      /**
       * Subtitle (following the title on the same line)
       */
      subtitle: {
        type: String,
        default: null,
      },
      withPicture: {
        type: Boolean,
        default: false,
      },
      picture: {
        type: String,
        default: null,
      },
      fallbackPicture: {
        type: String,
        default: 'planets/green.svg',
      },
      /**
       * Display or not the cursor as pointer
       */
      clickable: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Define the picture URL as background-image
       * @returns {Object}
       */
      pictureStyle() {
        const { picture } = this

        return picture ? { 'background-image': `url(${picture})` } : undefined
      },
    },
    methods: {
      /**
       * Function called when the user click on the card
       */
      onPress() {
        const { clickable } = this

        if (clickable) {
          this.$emit('click')
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .card {
    width: 100%
    display: flex
    flex-direction: row
    box-shadow: 0px 1px 2px var(--color-brand-transparent)

    .card-content {
      display: flex
      flex: 1
      flex-direction: column
      align-self: stretch
      padding: 24px
      background-color: var(--color-background)
      border-radius: $radius-small
      border-left-width: 4px
      border-left-style: solid
      box-sizing: content-box
      max-width: calc(100% - 48px)

      &.border--red { border-color: var(--color-red-50) }
      &.border--blue { border-color: var(--color-blue-50) }
      &.border--grey { border-color: var(--color-grey-40) }
      &.border--green { border-color: var(--color-green-50) }
      &.border--orange { border-color: var(--color-orange-50) }
      &.border--yellow { border-color: var(--color-yellow-50) }

      .card-picture-wrapper {
        background-color: var(--color-background)
        padding: 12px
        width: 112px
        height: 112px
        border-radius: $radius-small
      }

      .card-head {
        display: flex
        flex-direction: row
        align-self: stretch
        position: relative

        .card-picture {
          width: 112px
          height: 112px
          display: flex

          + .card-titles {
            padding-left: 24px
          }
        }

        .card-titles {
          min-width: 140px
          display: flex
          flex: 1
          flex-direction: column
          overflow: hidden

          .card-titles-top {
            display: flex
            flex-direction: row

            h2 {
              margin: 0

              .thin {
                margin-left: 8px
              }

              &:not(:last-child) {
                margin-right: 16px
              }
            }
          }

          p {
            margin: 8px 0
            color: var(--color-font-light)
          }
        }

        .card-infos {
          display: flex
          flex-direction: column
          align-items: stretch
          text-align: right

          .value {
            font-weight: $font-bold
            font-size: 18px
            justify-content: flex-end
            margin: 12px 0
          }
        }
      }
    }

    &.black {
      h2, p {
        color: #e0e0e0;
      }

      .card-content {
        background-color: #232323

        .card-head .card-picture {
          background-color: #232323
        }
      }
    }

    &.clickable {
      cursor: pointer
    }
  }
</style>
