<template>
  <div :class="classes">
    <div v-if="$slots.head" :class="$('head')">
      <slot name="head" />
    </div>

    <div :class="$('date-form')">
      <div :class="$('date-field-container')">
        <DateField
          v-model="form.fields.date"
          :class="$('date-field')"
          :range="range"
          :label="__('cp:form:time-slot-input:date:label')"
          :placeholder="__('cp:form:time-slot-input:date:placeholder')"
          :disabled="disabled || !editable"
          pattern="writtenfulldate"
          @input="onDateChange"
        />
        <div v-if="$slots.actions" :class="$('actions')">
          <slot name="actions" />
        </div>
      </div>

      <Field
        :class="{
          [$('hours-field')]: true,
          [$('hours-field-disabled')]: !form.fields.date,
        }"
        :disabled="disabled || !editable"
      >
        <template slot="label">
          <Txt
            :size="Txt.Size.XXXS"
            :type="Txt.Type.OVERLINE"
            :class="$('hours-field-label')"
            :theme="disabled || !editable ? Txt.Theme.GREY_MEDIUM : Txt.Theme.GREY"
            :value="__('cp:form:time-slot-input:hours:label')"
          />

          <SwitchButton
            v-if="isTeamMember"
            v-model="form.fields.nightTime"
            :class="$('night-switch')"
            :disabled="disabled || form.invalid"
            :label="nightSwitchLabel"
          />
        </template>

        <WithDimensions>
          <div
            slot-scope="{ width }"
            :class="[
              $('hours'),
              { [$('hours-small')]: width < 500 },
              { [$('hours-xsmall')]: width < 300 },
            ]"
          >
            <div v-for="(slot, slotIndex) in displayedSlots" :key="slotIndex" :class="hourClasses">
              <input
                v-tooltip="_get(slot, 'label')"
                type="button"
                :class="getSlotClass(slotIndex)"
                :disabled="disabled || form.invalid"
                @click="onSlotPress(slotIndex)"
              />
              {{ getSlotHour(slotIndex) }}
            </div>
          </div>
        </WithDimensions>
      </Field>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment-timezone'
  import _get from 'lodash/get'

  import Field from '@/core/layout/Field/Field'
  import DateField from '@/core/fields/DateField/DateField'
  import SwitchButton from '@/core/inputs/SwitchButton/SwitchButton'
  import Txt from '@/core/text/Txt/Txt'
  import WithDimensions from '@/core/layout/WithDimensions/WithDimensions'

  import ClockMixin from '@/mixins/ClockMixin'
  import I18nMixin from '@/mixins/I18nMixin'
  import FormMixin from '@/mixins/FormMixin'

  import { uuid } from '@/utils/app'
  import { required } from '@/utils/validators'

  /**
   * Minimum bounding slot (in minutes)
   * @type {Number}
   */
  const TIME_SLOT_MIN = 480 // 8h00

  /**
   * Maximum bounding slot (in minutes)
   * @type {Number}
   */
  const TIME_SLOT_MAX = 1200 // 20h00

  /**
   * Time slot duration (in minutes)
   * @type {Number}
   */
  const TIME_SLOT_SIZE = 30

  /**
   * Number of slots displayed
   * @type {Number}
   */
  const NB_OF_DISPLAYED_SLOTS = Math.floor((TIME_SLOT_MAX - TIME_SLOT_MIN) / TIME_SLOT_SIZE)

  /**
   * Max number of slots in a day (counting also the not displayed ones)
   * @type {Number}
   */
  const MAX_OF_SLOTS = Math.floor(1440 / TIME_SLOT_SIZE)

  /**
   * Index of the first displayed slot into the whole day slots
   * @type {Number}
   */
  const FIRST_DISPLAYED_SLOT_INDEX = Math.floor(TIME_SLOT_MIN / TIME_SLOT_SIZE)

  /**
   * Generate a TimeSlotInput value (list of 'dateTime' and 'color' objects)
   * from the given 'date' (UTC) and the given 'slots'
   * @param {String} date
   * @param {Array.<Boolean>} slots
   * @returns {Array.<String>}
   */
  function serialize(date, slots) {
    const result = []

    // The 'date' is an "UTC date" formatted like "2018-08-09T00:00:00+00:00",
    // but here we need the beginning of the day but in local time, which is
    // "2018-08-09T00:00:00+02:00"
    const start = moment(moment(date).format('YYYY-MM-DD'))

    slots.forEach((slot, index) => {
      if (slot) {
        result.push({
          dateTime: start
            .clone()
            .add(index * TIME_SLOT_SIZE, 'minutes')
            .format(),
          booked: false,
          label: null,
        })
      }
    })

    return result
  }

  /**
   * Time slot duration (in minutes)
   * @type {Number}
   */
  const SLOT_LABEL_STEP = 60

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  DateField: DateField.__enums,
  Field: Field.__enums,
  SwitchButton: SwitchButton.__enums,
  Txt: Txt.__enums,
  WithDimensions: WithDimensions.__enums,
},

    name: 'TimeSlotInput',
    components: {
      DateField,
      Field,
      SwitchButton,
      Txt,
      WithDimensions,
    },
    mixins: [ClockMixin, I18nMixin, FormMixin],
    props: {
      /**
       * List of objects exposing 'dateTime' (Date) and 'booked' (Boolean).
       * All these 'dateTime' target the same day and different times.
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Index of the time input in a potential list of timeslots
       */
      index: {
        type: Number,
        default: 0,
      },
      /**
       * If 'true', only one slot can be selected at a time
       */
      unique: {
        type: Boolean,
        default: false,
      },
      /**
       * Boolean fixing if the user can change the value in the input
       */
      editable: {
        type: Boolean,
        default: true,
      },
      /**
       * If 'true', it disables all the timeslot input actions
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * List of dates that should not be selectable in the date picker
       * Every element of this list should be a moment object
       */
      unallowedDates: {
        type: Array,
        default: null,
      },
      /**
       *
       */
      display: {
        type: String,
        default: 'single-row',
      },
    },
    form() {
      const { now, value, index } = this

      // Initialize date to next monday (then add a day for each index)
      const date = value.length
        ? value[0]
        : now()
            .day(8 + index)
            .toISOString()

      return {
        name: `time-slot-input-${uuid()}`,
        fields: {
          date,
          nightTime: false,
        },
        validators: {
          date: { required },
        },
      }
    },
    computed: {
      ...mapGetters(['isTeamMember']),
      /**
       * CSS classes applied on the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { $, editable } = this

        return {
          'time-slot-input': true,
          [$('-editable')]: editable,
        }
      },
      /**
       * CSS classes applied on the 'hour' node
       * @type {Object.<Boolean>}
       */
      hourClasses() {
        const { $, disabled, editable } = this

        return {
          [$('hour')]: true,
          [$('hour--disabled')]: disabled || !editable,
        }
      },
      /**
       * Array of booleans representing all of the slots of a day.
       * If a slot is actually selected (from 'value'), the boolean
       * at the corresponding index is 'true'. Else, it is 'false'.
       * @type {Array.<String>}
       */
      slots() {
        const { value, isBeforeNow } = this

        const result = new Array(MAX_OF_SLOTS).fill(null)

        if (value && value.length) {
          value.forEach(item => {
            const start = moment(item.dateTime).startOf('day')
            const current = moment(item.dateTime)
            const diff = current.diff(start, 'minutes')
            const index = diff / TIME_SLOT_SIZE

            if (item.booked) {
              if (isBeforeNow(item.dateTime)) {
                result[index] = { color: 'passed' }
              } else {
                result[index] = { color: 'booked' }
              }
            } else {
              result[index] = { color: 'suggested' }
            }

            if (item.label) {
              result[index].label = item.label
            }
          })
        }

        return result
      },
      /**
       * Subset of 'slots' containing only the range of slots we must display
       * @type {Array.<String>}
       */
      displayedSlots() {
        const { slots, form } = this
        const { nightTime } = form.fields

        const start = nightTime
          ? -(MAX_OF_SLOTS - FIRST_DISPLAYED_SLOT_INDEX - NB_OF_DISPLAYED_SLOTS)
          : -(MAX_OF_SLOTS - FIRST_DISPLAYED_SLOT_INDEX)
        const size = nightTime ? MAX_OF_SLOTS - NB_OF_DISPLAYED_SLOTS : NB_OF_DISPLAYED_SLOTS
        const end = start + size

        return start < 0 && end > 0
          ? slots.slice(start).concat(slots.slice(0, end))
          : slots.slice(start, end)
      },
      /**
       * This returns a formatted date of the first time slot selected.
       * Displayed instead of the DateField if the input is not editable.
       * @type {String}
       */
      formattedDate() {
        const { value, format } = this

        const date = _get(value, '[0].dateTime')

        return date ? format('date', date, 'writtendatetime') : null
      },
      /**
       * Return an object for the datepicker containing all the date that
       * should not be selectable.
       * This object is compose of today date to ignore date in the past
       * and all the date in the props disabledDates
       */
      range() {
        const { unallowedDates, now, yesterdayDate, toDate } = this

        const dates = unallowedDates
          ? unallowedDates.filter(date => !!date).map(date => toDate(date))
          : []

        const nextYear = now()
          .add(1, 'year')
          .toDate()

        return { to: yesterdayDate(), from: nextYear, dates }
      },
      /**
       * Night switch label according to its value
       * @type {String}
       */
      nightSwitchLabel() {
        const { form, __ } = this

        return form.fields.nightTime
          ? __('cp:form:time-slot-input:night:label')
          : __('cp:form:time-slot-input:day:label')
      },
    },
    watch: {
      value: {
        deep: true,
        immediate: true,
        handler(next) {
          const { form, editable, displayedSlots, isTeamMember } = this

          // Compute the day date corresponding to the given timeslot
          const date = _get(next, '[0].dateTime')

          if (date) {
            form.fields.date = moment(date)
              .utc()
              .startOf('day')
              .format()
          }

          if (isTeamMember && !editable && !displayedSlots.find(s => !!s)) {
            form.fields.nightTime = !form.fields.nightTime
          }
        },
      },
    },
    methods: {
      _get,
      /**
       * Function returning the formatted corresponding hour according to the
       * given 'index'.
       * @param {Number} index
       * @returns {Object}
       */
      getSlotHour(index) {
        const { form, format } = this
        const { nightTime } = form.fields

        const start = FIRST_DISPLAYED_SLOT_INDEX + (nightTime ? NB_OF_DISPLAYED_SLOTS : 0)

        const minutes = ((start + index) % MAX_OF_SLOTS) * TIME_SLOT_SIZE

        return minutes % SLOT_LABEL_STEP === 0 ? format('duration', minutes, 'writtenhour') : null
      },
      /**
       * Function returning the good CSS classes according to the given 'index'.
       * @param {Number} index
       * @returns {Object}
       */
      getSlotClass(index) {
        const { slots, form } = this
        const { nightTime } = form.fields

        const start = FIRST_DISPLAYED_SLOT_INDEX + (nightTime ? NB_OF_DISPLAYED_SLOTS : 0)

        const slot = slots[(start + index) % MAX_OF_SLOTS]
        const color = slot ? slot.color : null

        return {
          'time-slot-input-hour-button': true,
          [`time-slot-input-hour-button--${color}`]: !!color,
        }
      },
      /**
       * Fired when the user selects a new date in the DateField. This emits an
       * event with the new computed value.
       * @param {String} date
       * @returns {void}
       */
      onDateChange(date) {
        const { slots } = this

        // Generate the new value from the date and the slots
        const newValue = serialize(date, slots)

        this.$emit('input', newValue)
      },
      /**
       * Fired when the user selects or deselects a time slot. This emits an
       * event with the new computed value.
       * @param {Number} index
       * @returns {void}
       */
      onSlotPress(index) {
        const { unique, editable, slots, form } = this
        const { nightTime } = form.fields

        if (editable) {
          const selected =
            (FIRST_DISPLAYED_SLOT_INDEX + (nightTime ? NB_OF_DISPLAYED_SLOTS : 0) + index) %
            MAX_OF_SLOTS

          // If it is flagged as unique, the slots are reset excepting the
          // pressed one
          const newSlots = unique ? slots.map((v, i) => (i === selected ? v : null)) : slots.slice()

          newSlots[selected] = !newSlots[selected] ? { color: 'suggested' } : null

          // Generate the new value from the date and the slots
          const newValue = serialize(form.fields.date, newSlots)

          this.$emit('input', newValue)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_grid.styl'
  @import '~@/assets/css/_layout.styl'
  @import '~@/assets/css/_variables.styl'

  .time-slot-input {
    position: relative

    h3 {
      padding: 0
      margin: 0
      font-size: 14px
    }

    &-date-form {
      content-grid()

      > * {
        require-input()
        align-self: center
      }
    }

    &-date-field-container {
      display: flex
      align-items: flex-end
    }

    &-actions {
      margin-bottom: 12px
      height: 20px
    }

    &-night-switch {
      font-size: 14px

      &.switch-button--on {
        color: var(--color-input-highlight)
      }
    }

    &-date-field {
      flex: 1
      grid-column: 1/-1
      align-self: start
    }

    &-hours-field {
      grid-column: 1 / -1

      &-disabled {
        opacity: 0.6
      }

      &-label {
        flex: 1
      }

      >>> label {
        justify-content: space-between
        align-items: center
      }
    }

    &-hours {
      display: grid
      grid-row-gap: 16px
      grid-column-gap: 4px
      grid-template-columns: repeat(12, 1fr)
      margin-bottom: 24px

      @media tablet {
        grid-template-columns: repeat(8, 1fr)
      }
    }

    &-hours-small {
      grid-template-columns: repeat(8, 1fr)

      @media tablet {
        grid-template-columns: repeat(4, 1fr)
      }
    }

    &-hours-xsmall {
      grid-template-columns: repeat(6, 1fr)

      @media tablet {
        grid-template-columns: repeat(4, 1fr)
      }
    }

    &-hour {
      font-size: 12px
      line-height: 16px
      min-width: 36px

      @media phone {
        max-width: 20px
        min-width: 16px
      }

      &&--disabled {
        opacity: 0.4
      }

      &-button {
        width: calc(100% - 2px)
        height: 43px
        margin-bottom: 0px
        background-color: var(--color-button-secondary)
        border-radius: 2px
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .24)
        transition: all .2s ease

        &--suggested {
          background-color: var(--color-brand)
        }

        &--booked {
          background-color: var(--color-positive)
        }

        &--passed {
          background-color: var(--color-negative)
        }
      }
    }

    &-error {
      font-size: 11px
      line-height: 22px
      color: var(--color-input-error)
    }

    &--editable {
      .time-slot-input-hour-button {
        cursor: pointer
        border: none

        &:disabled {
          opacity: .5
        }
      }
    }
  }
</style>
