import { __ } from '@comet/i18n'

import TeamMemberTypes from '@/types/teamMember'

const { TeamMemberActionReason } = TeamMemberTypes

/**
 * Format team member todo title
 * @param   {String}
 * @returns {String}
 */
function formatActionTitle(key, params) {
  const trans = {
    [TeamMemberActionReason.VALIDATION]: __('ty:backoffice:todo:title:validation'),
    [TeamMemberActionReason.MATCH_LIST_REVIEW]: __('ty:backoffice:todo:title:matchListReview'),
    [TeamMemberActionReason.PROFILES_SELECTION]: __(
      'ty:backoffice:todo:title:profilesSelection',
      params,
    ),
    [TeamMemberActionReason.MEETING_DATES_SELECTION]: __(
      'ty:backoffice:todo:title:meetingDatesSelection',
    ),
    [TeamMemberActionReason.MEETING_SLOT_SELECTION]: __(
      'ty:backoffice:todo:title:meetingSlotSelection',
    ),
    [TeamMemberActionReason.FREELANCE_MEETING_FEEDBACK]: __(
      'ty:backoffice:todo:title:freelanceMeetingFeedback',
    ),
    [TeamMemberActionReason.PROFILE_CONFIRMATION]: __(
      'ty:backoffice:todo:title:profileConfirmation',
    ),
    [TeamMemberActionReason.FREELANCE_MAIN_TERMS_VALIDATION]: __(
      'ty:backoffice:todo:title:freelanceMainTermsValidation',
    ),
    [TeamMemberActionReason.RATING]: __('ty:backoffice:todo:title:rating'),
    [TeamMemberActionReason.LOW_RATING_JUSTIFICATION]: __(
      'ty:backoffice:todo:title:lowRatingJustification',
    ),
    [TeamMemberActionReason.CUSTOM]: __('ty:backoffice:todo:title:custom'),
  }

  return trans[key] || undefined
}

/**
 * Format team member todo informations
 * @param   {String}
 * @param   {String}
 * @param   {Object}
 * @returns {String}
 */
function formatActionInfos(key, comment, params) {
  const trans = {
    [TeamMemberActionReason.VALIDATION]: __('ty:backoffice:todo:infos:validation', params),
    [TeamMemberActionReason.MATCH_LIST_REVIEW]: __(
      'ty:backoffice:todo:infos:matchListReview',
      params,
    ),
    [TeamMemberActionReason.PROFILES_SELECTION]: __(
      'ty:backoffice:todo:infos:profilesSelection',
      params,
    ),
    [TeamMemberActionReason.MEETING_DATES_SELECTION]: __(
      'ty:backoffice:todo:infos:meetingDatesSelection',
      params,
    ),
    [TeamMemberActionReason.MEETING_SLOT_SELECTION]: __(
      'ty:backoffice:todo:infos:meetingSlotSelection',
      params,
    ),
    [TeamMemberActionReason.FREELANCE_MEETING_FEEDBACK]: __(
      'ty:backoffice:todo:infos:freelanceMeetingFeedback',
      params,
    ),
    [TeamMemberActionReason.PROFILE_CONFIRMATION]: __(
      'ty:backoffice:todo:infos:profileConfirmation',
      params,
    ),
    [TeamMemberActionReason.FREELANCE_MAIN_TERMS_VALIDATION]: __(
      'ty:backoffice:todo:infos:freelanceMainTermsValidation',
    ),
    [TeamMemberActionReason.RATING]: __('ty:backoffice:todo:infos:rating', params),
    [TeamMemberActionReason.LOW_RATING_JUSTIFICATION]: __(
      'ty:backoffice:todo:infos:lowRatingJustification',
      params,
    ),
    [TeamMemberActionReason.CUSTOM]: comment,
  }

  return trans[key] || undefined
}

/* eslint-disable import/prefer-default-export */
export { formatActionTitle, formatActionInfos }
