<template>
  <div v-if="!hidden" class="pager" :class="classes">
    <Transition name="fade" :appear="true">
      <div :key="value" class="pager-panel">
        <slot :name="value" />
      </div>
    </Transition>

    <div class="pager-bar">
      <button
        v-for="page in count"
        :key="page"
        class="pager-page"
        :class="getButtonClasses(page)"
        @click="onPress(page)"
      >
        <div class="pager-page-line" />
      </button>
    </div>
  </div>
</template>

<script>
  /**
   * WARNING : The pager is a 1-based index control.
   */
  export default {
    name: 'Pager',
    props: {
      /**
       * Amount of pages display in the pager.
       */
      count: {
        type: Number,
        required: true,
      },
      /**
       * 1-based index of the currently selected page.
       */
      value: {
        type: Number,
        required: true,
      },
      /**
       * Index of the last accessible page (included).
       * All the next pages can't be reached by clicking the buttons.
       */
      limit: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER,
      },
      /**
       * Disable all the pager buttons
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Theme of the pager can be light or dark
       */
      theme: {
        type: String,
        default: 'light',
      },
    },
    computed: {
      /**
       * The pager is visible only if the value is well between the bounds
       * @type {Boolean}
       */
      hidden() {
        const { value, count } = this

        // This is a 1-based index component
        return value < 1 || value > count
      },
      /**
       * CSS classes applied on the Pager parent tag
       * @type {Object.<Boolean>}
       */
      classes() {
        const { theme } = this

        return {
          'pager--light': theme === 'light',
          'pager--dark': theme === 'dark',
        }
      },
    },
    methods: {
      /**
       * Return 'true', if the given page number is actually the currently
       * selected one. Else 'false'.
       * Used to display or not, the corresponding slot.
       * @param {Number} page
       * @returns {Boolean}
       */
      isContentVisible(page) {
        const { value } = this

        return page === value
      },
      /**
       * Compute the right CSS class according to the given 'page' number
       * @param {Number} page
       * @returns {Object}
       */
      getButtonClasses(page) {
        const { value, limit, disabled } = this

        return {
          'pager-page--selectable': page !== value && page <= limit,
          'pager-page--selected': page === value,
          'pager-page--disabled': disabled,
        }
      },
      /**
       * Emit the index of the page when the user click on the page bar
       * @param {Number} page
       * @returns {void}
       */
      onPress(page) {
        const { limit, disabled } = this

        if (!disabled && page <= limit) {
          this.$emit('input', page)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>


  .pager {
    position: relative
    display: flex
    flex-direction: column
    height: 100%
    width: 100%

    .pager-panel {
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      display: flex
      flex: 1
    }

    .pager-bar {
      position: absolute
      left: 0
      right: 0
      bottom: -18px
      display: flex
      flex-direction: row
      justify-content: space-between

      .pager-page {
        height: 28px
        flex: 1
        padding: 0
        background-color: transparent
        border-color: transparent

        .pager-page-line {
          height: 3px
        }

        &:not(:last-child) {
          margin-right: 8px
        }

        &.pager-page--disabled,
        &:not(.pager-page--selectable) {
          cursor: default
        }
      }
    }

    &.pager--dark {
      .pager-page {
        .pager-page-line {
          background-color: var(--color-grey-60)
        }

        &.pager-page--selected .pager-page-line {
          background-color: var(--color-brand)
        }
      }
    }

    &.pager--light {
      .pager-page {
        .pager-page-line {
          background-color: var(--color-grey-30)
        }

        &.pager-page--selected .pager-page-line {
          background-color: var(--color-black)
        }
      }
    }
  }
</style>
