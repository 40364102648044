import { __ } from '@comet/i18n'

import ExperienceTypes from '@/types/experience'

const { ExperienceType } = ExperienceTypes

function formatExperienceType(key) {
  const trans = {
    [ExperienceType.CONTRACT]: __('ty:experience:type:contract'),
    [ExperienceType.FREELANCING]: __('ty:experience:type:freelancing'),
    [ExperienceType.CONSULTING]: __('ty:experience:type:consulting'),
    [ExperienceType.PERSONAL_PROJECT]: __('ty:experience:type:personal-project'),
    [ExperienceType.FORMATION]: __('ty:experience:type:formation'),
    [ExperienceType.OTHER]: __('ty:experience:type:other'),
    [ExperienceType.NONE]: __('ty:experience:type:none'),
  }

  return trans[key] || undefined
}

/* eslint-disable import/prefer-default-export */
export { formatExperienceType }
