<template>
  <VCard outlined class="py-2 px-3" :class="classes">
    <div :class="$style.content">
      <div :class="$style.header">
        <div v-if="$slots.picture" :class="$style.picture">
          <slot name="picture" />
        </div>
        <div v-if="$slots.header" :class="$style.headerInfo">
          <slot name="header" />
        </div>
      </div>

      <div v-if="$slots.default || $slots.footer" :class="$style.info">
        <slot v-if="$slots.default" />
        <div :class="$style.footer">
          <slot v-if="$slots.footer" name="footer" />
        </div>
      </div>
    </div>
  </VCard>
</template>

<script>
  export default {
    name: 'PresentationCard',
    computed: {
      /**
       * Classes according to ratio
       * @type {Object}
       */
      classes() {
        const { $style } = this

        return {
          [$style.presentationCard]: true,
        }
      },
    },
  }
</script>

<style lang="stylus" module>
  @import '~@/assets/css/_grid.styl'

  .presentationCard {
    content-grid()
    padding-bottom: 20px !important

    .content {
      display: flex
      flex-direction: column

      .picture {
        margin-right: 24px
      }

      .header {
        display: flex
        flex-wrap: wrap

        .headerInfo {
          flex: 1
        }
      }

      .info {
        margin-top: 12px
        flex: 1
        display: flex;
        flex-direction: column
      }

      .footer {
        margin-top: auto
      }
    }
  }
</style>
