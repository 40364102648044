<template>
  <div class="grade-input" :class="[size]">
    <div v-for="index in max" :key="index" :class="$('section')">
      <div :class="[$('button')]" @click="onSelect(index)">
        <NavButton
          :class="[$('check'), txtColor(index)]"
          icon="icon-check-circle"
          :theme="index === value ? 'primary-void' : 'secondary-void'"
          :disabled="disabled"
          @click="onSelect(index)"
        />
        <Txt
          v-if="format"
          :class="$('button-label')"
          :value="format(index)"
          :theme="txtColor(index)"
        />
      </div>
      <div v-if="index < max" :class="$('separator')" />
    </div>
  </div>
</template>

<script>
  import NavButton from '@/core/controls/NavButton/NavButton'
  import Txt from '@/core/text/Txt/Txt'

  /**
   * WARNING : This component is support 1-based indexes
   */
  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
  Txt: Txt.__enums,
},

    name: 'GradeInput',
    components: {
      NavButton,
      Txt,
    },
    props: {
      /**
       * Current or pre-filled value
       */
      value: {
        type: Number,
        default: undefined,
      },
      /**
       * Max grade that could be given
       */
      max: {
        type: Number,
        default: 5,
      },
      /**
       * Display size (can be "big" or "small")
       */
      size: {
        type: String,
        default: 'big',
      },
      /**
       * Formatting function applied on the grade labels
       */
      format: {
        type: Function,
        default: null,
      },
      /**
       * Can't be touched at all if it is 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      /**
       * Compute the text of the color in function of the index
       * of the grade in the list and the value selected
       * @return {String}
       */
      txtColor(index) {
        const { $, value } = this

        return value !== index ? $('black') : $('blue')
      },
      /**
       * Called when a button is selected. It emits the value as 'input'.
       * @returns {void}
       */
      onSelect(index) {
        if (this.disabled) {
          return
        }

        const { max } = this

        if (max !== 3 || index % 2) {
          this.$emit('input', index)
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_separator.styl'

  .grade-input {
    display: flex
    color: var(--color-brand)
    flex-direction: row
    justify-content: flex-start

    &-section {
      display: flex

      &:not(:last-child) {
        flex: 1
      }
    }

    &-check&-blue >>> button.nav-button-icon-rounded i {
      color: var(--color-brand)
    }

    &-check >>> button.nav-button-icon-rounded {
      height: 48px
      width: 48px

      i {
        font-size: 40px
        color: var(--color-font)
      }
    }

    .grade-input-separator {
      flex: 1
      margin: 0 8px
      horizontal-dotted-separator(var(--color-font))
    }

    &-button {
      position: relative
      display: flex
      flex-direction: column
      justify-content: center

      &-label {
        width: 110px
        position: absolute
        top: calc(50% + 36px)
        left: -24px
        text-align: center
        font-size: 14px
        transition: all .2s ease
      }
    }
  }
</style>
