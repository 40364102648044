<template>
  <div
    class="d-flex flex-column align-stretch"
    style="position: relative; width: 100%;"
    :class="{
      [$style.root]: true,
      [$style.dense]: dense || $attrs.dense,
    }"
  >
    <VTextField
      v-if="!disabled && canChangeSelection"
      id="job-title"
      v-model="search"
      v-bind="$attrs"
      :disabled="disabled"
      :dense="dense"
      :outlined="outlined"
      clearable
      autocomplete="off"
      :error-messages="errorMessages"
      :hide-details="!errorMessages"
      :placeholder="placeholder || __('cp:freelancer-job-titles-field:autocomplete:placeholder')"
      @focus="isMenuOpen = true"
      @blur="isMenuOpen = false"
    />

    <div
      v-if="isMenuOpen"
      class="pa-2"
      :class="$style.customMenu"
      :style="{ top: outlined ? '30px' : '44px' }"
      @pointerdown.prevent
    >
      <VDivider class="mt-1" />

      <div :class="$style.customMenuContent">
        <div
          v-if="!results || !results.length"
          class="my-2 pa-2 ml-2 secondary--text"
          style="font-size: 0.875rem"
          v-text="
            search && search.length
              ? __('cp:freelancer-job-titles-field:autocomplete:no-data')
              : __('cp:freelancer-job-titles-field:autocomplete:no-search')
          "
        />
        <template v-else>
          <VListGroup v-for="category of categories" :key="category" :value="false">
            <template v-slot:activator>
              <VListItemContent>
                <VListItemTitle :class="$style.categoryGroup" style="font-size: 0.875rem">
                  {{ category }}
                </VListItemTitle>
              </VListItemContent>
            </template>

            <VListItem
              v-for="item of results.filter(el => el.category === category)"
              :key="item.id"
              clickable
              @click="onAutocompleteInput(item)"
            >
              <VListItemContent>
                <VListItemTitle class="pl-4" style="font-size: 0.875rem">
                  {{ item.name }}
                </VListItemTitle>
              </VListItemContent>
            </VListItem>
          </VListGroup>
        </template>

        <template v-if="canAddJobTitle && search">
          <VListItem v-if="search" :key="search" clickable @click="onAddJobTitle">
            <VListItemContent>
              <VListItemTitle>
                <span class="primary--text" style="font-size: 0.875rem">{{
                  __('cp:freelancer-job-titles-field:autocomplete:add-job-title')
                }}</span>
              </VListItemTitle>
            </VListItemContent>
          </VListItem>
        </template>
      </div>
    </div>

    <template
      v-if="!disabled && showSuggestions && showSuggestionAbove && (!value || value.length < 3)"
    >
      <div class="d-flex flex-row flex-wrap mt-1" :class="$style.suggestions">
        <CChipSuggest
          v-for="item in filteredSuggestions"
          :key="item.id"
          :dense="dense"
          :label="item.name"
          @click="() => onSuggestionPress(item)"
        />
      </div>
    </template>

    <div class="d-flex flex-row flex-wrap align-center mt-1" :class="$style.jobTitles">
      <template v-for="item in displayedJobTitles">
        <CChipTalent
          :key="item.id"
          :dense="dense"
          :label="item.name"
          :close="!disabled && canChangeSelection"
          :class="$style.chip"
          close-icon="mdi-close-circle"
          @click:close="() => onRemovePress(item)"
        >
          <template v-if="withDuration" v-slot:duration>
            <CFieldSelect
              v-if="!disabled && canChangeSelection"
              mini
              solo
              :value="item.duration || FreelancerJobTitleDuration[0]"
              :options="FreelancerJobTitleDuration"
              :formatter="formatFreelancerJobTitleDuration"
              :menu-props="{ contentClass: $style.menu }"
              :disabled="disabled"
              class="ml-1"
              :error="!item.duration"
              @input="v => onDurationInput(v, item)"
              @click.native="e => e.stopImmediatePropagation()"
            />
            <span
              v-else
              class="ml-1"
              v-text="`· ${formatFreelancerJobTitleDuration(item.duration)}`"
            />
          </template>
        </CChipTalent>
      </template>

      <VBtn v-if="displayShowAllButtons" text color="secondary" small @click="showAll = true">
        <VIcon small v-text="'mdi-eye-plus-outline'" />
        <span class="ml-1" v-text="showMoreLabel" />
      </VBtn>

      <VBtn
        v-if="showAll"
        :class="$style.noPrint"
        text
        color="secondary"
        small
        @click="showAll = false"
      >
        <VIcon small v-text="'mdi-eye-minus-outline'" />
        <span class="ml-1" v-text="__('cp:freelancer-jobTitles-field:show-less')" />
      </VBtn>
    </div>

    <template v-if="!disabled && showSuggestions && !showSuggestionAbove">
      <div class="d-flex flex-row flex-wrap mt-1" :class="$style.suggestions">
        <CChipSuggest
          v-for="item in filteredSuggestions"
          :key="item.id"
          :dense="dense"
          :label="item.name"
          @click="() => onSuggestionPress(item)"
        />
      </div>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import _differenceBy from 'lodash/differenceBy'
  import _omit from 'lodash/omit'
  import _uniqBy from 'lodash/uniqBy'

  import { formatFreelancerJobTitleDuration } from '@comet/i18n'
  import { FreelancerJobTitleDuration } from '@comet/types'

  import ApolloMixin from '@/mixins/ApolloMixin'

  import searchQuery from './search.gql'

  import config from '@/config'

  export default {
    name: 'JobTitlesField',
    mixins: [ApolloMixin],
    props: {
      outlined: {
        type: Boolean,
        default: false,
      },
      /**
       * List of the currently selected jobTitles
       * @type {Array.<JobTitle>}
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Disable all the inner inputs when enabled
       * @type {Boolean}
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Reduces the component's height
       */
      dense: {
        type: Boolean,
        default: false,
      },
      /**
       * Whether to show suggestion chips
       */
      showSuggestions: {
        type: Boolean,
        default: false,
      },
      /**
       * Show the suggested jobTitles above the selected jobTitles or not
       */
      showSuggestionAbove: {
        type: Boolean,
        default: true,
      },
      /**
       * Maximal number of jobTitles displayed without putting a "show more" button
       */
      maxJobTitleDisplayed: {
        type: Number,
        default: 8,
      },
      /**
       * Can the user add a jobTitle that don't exist yet
       */
      canAddJobTitle: {
        type: Boolean,
        default: false,
      },
      /**
       * Quick & dirty fix to disable the ability to change the selection
       */
      canChangeSelection: {
        type: Boolean,
        default: true,
      },
      /**
       * Max allowed
       */
      max: {
        type: Number,
        default: null,
      },
      /**
       * With duration
       */
      withDuration: {
        type: Boolean,
        default: false,
      },
      /**
       * Error messages
       */
      errorMessages: {
        type: String,
        default: null,
      },
      /**
       * Placeholder
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Suggestions
       */
      suggestions: {
        type: Array,
        default: null,
      },
    },
    constants: {
      FreelancerJobTitleDuration,
    },
    data() {
      return {
        isMenuOpen: false,
        /**
         * Currently entered text in the autocomplete component that is not matching
         * yet a jobTitle (text source transmitted to the search request)
         * @type {String}
         */
        search: null,
        /**
         * List of all available jobTitles (fetched from Apollo)
         * @type {Array.<JobTitle>}
         */
        rawResults: [],
        /**
         * Whether to show all jobTitles in the list
         */
        showAll: false,
      }
    },
    computed: {
      ...mapGetters({
        user: 'selectUser',
      }),
      /**
       * Search results fetched from Apollo, then enriched and filtered to avoid duplicates.
       * @type {Array.<JobTitle>}
       */
      results() {
        const { value, rawResults } = this

        const results = _uniqBy(rawResults || [], 'id')

        return _differenceBy(results, value, 'id')
      },
      categories() {
        const { results } = this

        const res = new Set()

        for (const item of results) {
          res.add(item.category)
        }

        return res
      },
      /**
       * Suggestions fetched from Apollo then filtered to avoid duplicates with
       * already selected jobTitles (in "value").
       * @type {Array.<JobTitle>}
       */
      filteredSuggestions() {
        const { value, suggestions } = this

        return _differenceBy(suggestions ?? [], value, 'id')
      },
      /**
       * Label used for the "show more" button
       * @type {String}
       */
      showMoreLabel() {
        const { value, displayedJobTitles, __ } = this
        const count = value.length - displayedJobTitles.length

        return __('cp:freelancer-jobTitles-field:show-more', count, {
          count,
        })
      },
      /**
       * Whether the show all button should be displayed.
       */
      displayShowAllButtons() {
        const { value, displayedJobTitles } = this

        if (!displayedJobTitles || !value) {
          return false
        }

        return displayedJobTitles.length < value.length
      },
      jobTitles() {
        const { value } = this

        if (!value) {
          return []
        }

        return [...value]
      },
      /**
       * JobTitles displayed in the list.
       * @type {Array.<JobTitle>}
       */
      displayedJobTitles() {
        const { jobTitles, showAll, maxJobTitleDisplayed } = this

        if (!jobTitles) {
          return []
        }

        return !showAll ? jobTitles.slice(0, maxJobTitleDisplayed) : jobTitles
      },
    },
    apollo: {
      rawResults: {
        query: searchQuery,
        variables() {
          return { search: this.search ?? '' }
        },
        update(data) {
          return data?.searchJobTitles
        },
        debounce: 100,
        fetchPolicy: 'network-only',
      },
    },
    methods: {
      _omit,
      formatFreelancerJobTitleDuration,
      clear() {
        this.search = ''
        this.$emit('input', [])
      },
      /**
       * Fired when the combobox's input changes (i.e. a jobTitle is picked)
       * @param {JobTitle} item
       */
      onAutocompleteInput(item) {
        const { value } = this

        this.isMenuOpen = false
        const newValue = [item, ...(value || [])]

        this.search = ''
        this.$emit('input', newValue)
      },
      /**
       * Fired when a duration is picked for a given item
       * @param {Number} duration
       * @param {JobTitle} item
       */
      onDurationInput(duration, item) {
        const { value } = this

        const newValue = value.map(s => ({
          ...s,
          duration: s.id === item.id ? duration : s.duration,
        }))

        this.$emit('input', newValue)
      },
      /**
       * Fired when the "close" button is pressed on a jobTitle chip
       * @param {JobTitle} item
       */
      onRemovePress(item) {
        const { value } = this

        const newValue = value.filter(s => s.id !== item.id)

        this.$emit('input', newValue)
      },
      /**
       * Fired when a suggested jobTitle is pressed
       * @param {JobTitle} item
       */
      onSuggestionPress(item) {
        const { value } = this

        const newValue = [item, ...(value || [])]

        this.$emit('input', newValue)
      },
      /**
       * Fired when the user click to add a jobTitle that don't exist yet
       */
      async onAddJobTitle() {
        const { user, search } = this
        const addJobTitleUrl = config.urls.addJobTitle
          .replace('{userId}', user.id)
          .replace('{userEmail}', user.email)
          .replace('{origin}', window.location.href)
          .replace('{submittedAt}', Date.now())
          .replace('{jobTitle}', search)

        this.isMenuOpen = false

        window.open(addJobTitleUrl, '_blank')
      },
    },
  }
</script>

<style lang="stylus" module>
  :global(#app) {
    .root {
      --input-icon-size: 24px

      .categoryGroup {
        color: var(--color-font)
        font-weight: 600
      }
      .customMenu {
        border-bottom-left-radius: 10px
        border-bottom-right-radius: 10px
        position: absolute
        background: var(--color-white)
        width: 100%
        border: 1px solid var(--color-blue-50)
        border-top: none
        z-index: 10
      }
      .customMenuContent {
        max-height: 580px
        overflow: hidden
        overflow-y: scroll
      }

      .suggestions > * {
        margin-right: 8px
        margin-top: 8px
      }

      .chip {
        height: 30px
      }

      &.dense .chip {
        height: 24px
      }

      .primaryButton {
        border-radius: 10px
      }

      .jobTitles > * {
        margin: 4px 9px 4px 0
        padding: 0 9px

        :global(.v-select__selection) {
          color: var(--color-grey-70) !important
        }
      }

      @media print {
        .noPrint {
          display: none
        }
      }
    }
  }
</style>
