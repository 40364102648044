<template>
  <div :class="classes">
    <VTooltip
      v-if="$list"
      bottom
    >
      <template v-slot:activator="{ on }">
        <VBtn
          v-if="$list.collapsable"
          icon
          small
          color="secondary"
          v-on="on"
          @click="onCollapsedPress"
        >
          <VIcon
            color="secondary"
            :class="$style.arrow"
            v-text="'mdi-menu-right'"
          />
        </VBtn>
      </template>

      <span
        v-text="(
          $list.collapsed
            ? __('cp:c-kanban-header:action:expand')
            : __('cp:c-kanban-header:action:collapse')
        )"
      />
    </VTooltip>

    <template v-if="$slots.default">
      <slot />
    </template>

    <template v-else>
      <div :class="$style.content">
        <div :class="$style.title">
          <span
            class="subtitle-2 font-weight-semi-bold"
            v-text="title"
          />
        </div>

        <VTooltip
          v-if="count >= 0"
          bottom
        >
          <template v-slot:activator="{ on }">
            <div
              :class="$style.count"
              v-on="on"
            >
              <span>
                <span
                  class="caption secondary--text mr-1"
                  v-text="count"
                />

                <VIcon
                  small
                  color="secondary"
                  v-text="'mdi-card-text-outline'"
                />
              </span>
            </div>
          </template>

          <span v-text="countTooltip || __('cp:c-kanban-header:count:tooltip', count, { count })" />
        </VTooltip>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'CKanbanHeader',
    props: {
      /**
       * Main title of this Kanban list
       * @type {String}
       */
      title: {
        type: String,
        default: null,
      },
      /**
       * Number of items present in the Kanban list.
       * Only used to display it in the header.
       * Hidden when having a negative value.
       * @type {Number}
       */
      count: {
        type: Number,
        default: -1,
      },
      /**
       * Text displayed in the tooltip visible when hovering the count area.
       * @type {String}
       */
      countTooltip: {
        type: String,
        default: null,
      },
    },
    inject: [
      '$list',
    ],
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { $list, $style } = this

        return {
          [$style.root]: true,
          [$style.collapsed]: !!$list?.collapsed,
        }
      },
    },
    methods: {
      /**
       * Fired when the "Collapsed" button is pressed.
       */
      onCollapsedPress() {
        const { $list } = this

        // Inform the CKanbanList that the collapsed state should change
        $list?.toggle?.()

        // Allows developer to directly listen to this header event
        this.$emit('toggle', !$list?.collapsed)
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    display: flex
    flex-direction: row
    align-items: center
    border-bottom: 1px solid var(--v-border-base)
    padding: 4px 4px

    .arrow {
      transform-origin: center
      transition: all .2s linear
    }

    .content {
      flex: 1
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      padding: 2px 4px

      .title {
        flex-grow: 1
        margin-right: 8px
        white-space: nowrap
        overflow: hidden

        > span {
          flex: 1
          display: flex
          flex-direction: row
          align-items: center
          min-height: 22px
        }
      }

      .count {
        margin-left: 8px
        white-space: nowrap
        cursor: default

        > span {
          flex: 1
          display: flex
          flex-direction: row
          align-items: center
        }
      }
    }

    &.collapsed {
      height: 100%
      max-width: 40px
      flex-direction: column

      .arrow {
        transform: rotate(90deg)
      }

      .content {
        flex-direction: column
        justify-content: flex-start

        .title {
          flex-grow: 0
        }

        .title,
        .count {
          margin: 0 0 16px 0

          > span {
            margin: calc(50% - 8px) 0
            transform: rotate(90deg)
            transform-origin: center
          }
        }
      }
    }
  }
</style>
