<template>
  <div
    :class="classes"
    :style="styles"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'CKanbanList',
    props: {
      /**
       * Fixed width of the list
       * @type {Number}
       */
      width: {
        type: Number,
        default: 250,
      },
      /**
       * When collapsable, the CKanbanHeader shows a arrow button allowing to control
       * the visibility state.
       *
       * If "true", this CKanbanList will strictly respect the "collapsed" prop value, and
       * emit "update:collapsed" events on changes, allowing to connect a reactive property
       * easily with "collapsed.sync".
       *
       * If "false", list are always expanded.
       *
       * if "auto", this list uses the "collapsed" value at initialization, and then manage
       * a local state to keep the current expansion state.
       * @type {Boolean|String}
       */
      collapsable: {
        type: [Boolean, String],
        default: false,
      },
      /**
       * Allows to manually set the expansion state of this list.
       * When the value is "null" as default, it is directly connected to a local state
       * in Vuex.
       * @type {Boolean}
       */
      collapsed: {
        type: Boolean,
        default: false,
      },
    },
    inject: [
      '$kanban',
    ],
    provide() {
      return {
        $list: this.list,
      }
    },
    data() {
      const { collapsable, collapsed, toggle, $kanban } = this

      return {
        /**
         * Reactive properties relative to this list. This properties are passed to children
         * throught "provide".
         */
        list: {
          collapsable: collapsable || $kanban.collapsable,
          collapsed,
          toggle,
        },
      }
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { list, $style } = this

        return {
          [$style.root]: true,
          [$style.collapsed]: !!list?.collapsed,
        }
      },
      /**
       * Custom CSS directives applied to the root element
       * @type {Object}
       */
      styles() {
        const { width, list } = this

        return list?.collapsed
          ? {
            minWidth: 'auto',
            maxWidth: 'auto',
          }
          : {
            minWidth: `${width}px`,
            maxWidth: `${width}px`,
          }
      },
    },
    watch: {
      collapsed: {
        immediate: true,
        handler(next) {
          const { list } = this

          if (list?.collapsable === true) {
            list.collapsed = next
          }
        },
      },
    },
    methods: {
      /**
       * Provided to the CKanbanHeader to let it toggle the expansion state with any control.
       */
      toggle() {
        const { list } = this

        if (!list) {
          return
        }

        const value = !list.collapsed

        if (list.collapsable === 'auto') {
          list.collapsed = value
        }

        this.$emit('update:collapsed', value)
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    display: flex
    flex-direction: column
    align-items: stretch
    margin: 8px
    background: var(--v-background-dual-base)
    border: 1px solid var(--v-border-base)
    border-radius: 4px

    &.collapsed {
      min-width: auto
      max-width: auto
    }
  }
</style>
