<template>
  <Field :label="label" :error="error" :disabled="disabled" class="address-field">
    <AddressInput
      :value="value"
      :disabled="disabled"
      :country-code="countryCode"
      :clearable="clearable"
      :error="error"
      @input="onInput"
    />
    <slot v-if="$slots.label" slot="label" name="label" />
  </Field>
</template>

<script>
  import AddressInput from '@/core/inputs/AddressInput/AddressInput'
  import Field from '@/core/layout/Field/Field'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  AddressInput: AddressInput.__enums,
  Field: Field.__enums,
},

    name: 'AddressField',
    components: {
      AddressInput,
      Field,
    },
    props: {
      /**
       * Code of the country to filter the response in the addressInput
       */
      countryCode: {
        type: String,
        default: null,
      },
      /**
       * Address input value
       */
      value: {
        type: Object,
        default: undefined,
      },
      /**
       * If true, the field can be cleared
       */
      clearable: {
        type: Boolean,
        default: false,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Field's error if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      /**
       * On input, emit event to notify the parent
       * @returns {void}
       */
      onInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>
