<script>
  import _throttle from 'lodash/throttle'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      /**
       * Tag we want to render at the root of the component
       */
      tag: {
        type: String,
        default: 'div',
      },
    },
    computed: {
      ...mapGetters(['isTeamMember']),
    },
    async mounted() {
      const { isTeamMember } = this

      // If we are a team member need to use the top value define by vuetify
      const topValue = isTeamMember ? this.$vuetify.application.top : 0

      const affixClass = isTeamMember ? 'backoffice--affixed' : 'affixed'

      // Define a function that will watch if the root element and add
      // the "affixed" class to his first child
      const listener = _throttle(() => {
        const elementRect = this.$el.getBoundingClientRect()

        if (elementRect.top <= topValue) {
          this.$el.children[0].classList.add(affixClass)
        } else {
          this.$el.children[0].classList.remove(affixClass)
        }
      }, 20)

      window.addEventListener('scroll', listener)

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('scroll', listener)
      })
    },
    render(createElement) {
      const { tag } = this

      return createElement(tag, {}, [this.$scopedSlots.default()])
    },
  }
</script>

<style lang="stylus" scoped>
  .affixed {
    position: fixed
    top: 8px
  }

  .backoffice--affixed {
    position: fixed
    top: 48px
  }
</style>
