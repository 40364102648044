<template>
  <div class="embed-document">
    <iframe :src="url" width="100%" height="1000px"> </iframe>
    <NavButton
      v-if="url && downloadUrl"
      :to="downloadUrl"
      :blank="true"
      :theme="NavButton.Theme.PRIMARY_VOID"
      class="embed-document-download"
      left-icon="download"
      :text="__('cp:mission:embed-document:download')"
    />
  </div>
</template>

<script>
  import NavButton from '@/core/controls/NavButton/NavButton'

  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  NavButton: NavButton.__enums,
},

    name: 'EmbedDocument',
    components: {
      NavButton,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * URL of the PandaDoc document
       */
      url: {
        type: String,
        default: null,
      },
      /**
       * Optional direct download URL for the document
       */
      downloadUrl: {
        type: String,
        default: null,
      },
    },
    created() {
      window.addEventListener('message', this.onEvent)
    },
    beforeDestroy() {
      window.removeEventListener('message', this.onEvent)
    },
    methods: {
      /**
       * Function called when an event is emit on the window
       * If the event is the one sent when the user finished to edit the
       * document then we emit the @event completed to the parent
       * @param {Event} event send on the window
       */
      onEvent(event) {
        const { id } = this

        if (event && event.data) {
          const message = typeof event.data === 'string' ? JSON.parse(event.data) : event.data

          if (message.event === 'session_view.document.completed') {
            this.$emit('completed', id)
          }
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .embed-document {
    position: relative

    .embed-document-download {
      position: absolute
      top: 8px
      right: 8px
    }
  }
</style>
