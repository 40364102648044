import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import _merge from 'lodash/merge'

import { updateSubmitInfo } from './submit'
import { getSyncedFields, destroySyncedForm } from './sync'
import { subscribeWatchers } from './watch'
import { unsubscribeWatchers } from './unwatch'

/**
 * Update the form fields with the given values, preventing the 'form'
 * watchers to treat these modifications.
 * This should be commonly used when initializing the form or when filling
 * it with data received from any query / mutation results.
 * @param {Object} values
 * @returns {void}
 */
function update(values) {
  const { form } = this
  const areEqualValuesAndFields = _isEqual(values, form.fields)

  if (form.debounce && form.submitting) {
    if (!areEqualValuesAndFields) {
      // When we are debouncing and already submitting data (editing the
      // form), we don't want to pollute our form with the received remote
      // values (from mutation responses), and so apply them later in the
      // 'submit' `then` handler.
      this.FormMixin_pendingValues = values
    }
  } else {
    // Unsubscribe all the field watchers to avoid any auto submit or
    // Vuelidate alteration when filling the fields with remote data.
    unsubscribeWatchers.call(this)

    this.form = {
      ...this.form,
      initial: {
        ...this.form.initial,
        ...values,
      },
      fields: {
        ...this.form.fields,
        ...values,
      },
    }

    if (areEqualValuesAndFields) {
      // If the values and fields are the same then we only modify form.initial
      // In this case, we want to put the watcher back to update the data that
      // will change later.
      subscribeWatchers.call(this)
    }
  }
}

/**
 * Allows to manually flag a field or the whole form as touched
 * @returns {void}
 */
function touch(field) {
  const { $v } = this

  // Find the scope related to the touch event (entire form or specific field)
  const scope = field ? _get($v, `form.fields.${field}`) : $v
  if (scope && scope.$touch) {
    scope.$touch()
  }
}

/**
 * Reset all the validation metadata (but not the form fields)
 * @returns {void}
 */
function resetValidation() {
  const { $v } = this

  if ($v && $v.$reset) {
    $v.$reset()
  }

  updateSubmitInfo.call(this, false, false, false)
}

/**
 * Reset the whole form metadata (state, validation, ...) and put all the fields values
 * back to the last "form.update" call (so potentially with remote data).
 * If a 'name' is specified, reset the form with the new name and initialize
 * it with the fields from vuex or an empty object
 * @param {String} name optional
 * @returns {void}
 */
function reset(name) {
  const { form } = this

  const initialFields = _merge({}, form.initial)

  if (name) {
    this.form = {
      ...form,
      name,
      fields: getSyncedFields.call(this, name) || initialFields,
    }
  } else if (form) {
    form.fields = initialFields
  }

  // The Vuelidate reset must be done on the "next tick" to be sure that the
  // form is well updated when Vuelidate will evaluate it.
  setTimeout(resetValidation.bind(this), 0)
}

/**
 * Reset the whole form metadata (state, validation) and put all the fields
 * values back to the form initialisation (made in the Vue component).
 * If no former "form" declaration is found, it simply empties the field.
 * @returns {void}
 */
function restart() {
  const { form, $options } = this

  const declaredForm =
    $options.form && _isFunction($options.form) ? $options.form.call(this) : $options.form

  // If a former "form" is reached, we use its values
  const newValues =
    _get(declaredForm, 'fields', null) ||
    // Else, we empty all the existing fields
    Object.keys(form.fields).reduce(
      (fields, key) => ({
        ...fields,
        [key]: null,
      }),
      {},
    )

  this.form = {
    ...form,
    fields: newValues,
  }

  // The Vuelidate reset must be done on the "next tick" to be sure that the
  // form is well updated when Vuelidate will evaluate it.
  setTimeout(resetValidation.bind(this), 0)
}

/**
 * Destroy the form from the Vuex store.
 * This action is usually done automatically on "destroyed" for default
 * forms (i.e. with 'destroyOnUnmount' to true). But for others, this
 * method is provided to force the form destruction.
 * @returns {void}
 */
function destroy() {
  const { form } = this

  if (form) {
    destroySyncedForm.call(this, form.name)
  }
}

/**
 * Check if a specified field is dirty. The provided 'field' can be a
 * deep path (ex: 'foo[3].bar')
 * @param  {String} field
 * @returns {Boolean}
 */
function isFieldDirty(field) {
  const { form, $v } = this

  return (
    _get($v, `form.fields.${field}.$anyDirty`, false) &&
    !_isEqual(_get(form, `fields.${field}`), _get(form, `initial.${field}`))
  )
}

/**
 * Returns a subset of 'form.fields' including only the dirty fields
 * @param  {String} field
 * @returns {Boolean}
 */
function getDirtyFields() {
  const { form } = this

  return Object.keys(form.fields).reduce((res, key) => {
    return isFieldDirty.call(this, key)
      ? {
          ...res,
          [key]: form.fields[key],
        }
      : res
  }, {})
}

export { update, touch, resetValidation, reset, restart, destroy, isFieldDirty, getDirtyFields }
