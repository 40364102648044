<template>
  <label class="switch-button" :class="state">
    <input type="checkbox" :checked="value" :disabled="disabled" @change="onChange" />

    <slot v-if="$slots.on && value" name="on" />
    <slot v-else-if="$slots.off && !value" name="off" />
    <span v-else class="switch-button-slider round"></span>

    <span>{{ label }}</span>

    <slot />
  </label>
</template>

<script>
  export default {
    name: 'SwitchButton',
    props: {
      /**
       * Label to display next to the switch
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Value that will be updated (also allow a initial value)
       */
      value: {
        type: Boolean,
        default: false,
      },
      /**
       * Allow to disable the switch
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied on the switch button in order to easily apply
       * styles according to its value
       * @type {Object.<Boolean>}
       */
      state() {
        const { value, disabled } = this
        return [value ? 'switch-button--on' : 'switch-button--off', { disabled }]
      },
    },
    methods: {
      /**
       * Fired when the switch is clicked (indeed value is toggled)
       * @param {Object} event
       * @returns {void}
       */
      onChange(e) {
        this.$emit('input', e.target.checked)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .switch-button {
    position: relative
    display: flex
    align-items: center
    height: 16px
    line-height: 20px
    padding-left: 40px
    margin-bottom: 0
    font-family: inherit
    font-size: inherit
    text-transform: inherit
    letter-spacing: inherit
    font-weight: $font-regular

    /* Hide default HTML checkbox */
    > input {
      display: none

      & + .switch-button-slider {
        width: 32px

        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: var(--color-idle)
        -webkit-transition: .4s
        transition: .4s

        &:before {
          position: absolute
          content: ""
          height: 10px
          width: 10px
          left: 4px
          top: 50%
          background-color: var(--color-background)
          -webkit-transition: .4s
          transition: .4s
          transform: translateY(-50%)
        }
      }

      &:checked + .switch-button-slider {
        background-color: var(--color-input-highlight)

        &:before {
          -webkit-transform: translateX(14px) translateY(-50%)
          -ms-transform: translateX(14px) translateY(-50%)
          transform: translateX(14px) translateY(-50%)
        }
      }

      &:focus + .switch-button-slider {
        box-shadow: 0 0 1px var(--color-input-highlight)
      }
    }

    > .switch-button-slider.round {
      border-radius: 34px

      &:before {
        border-radius: $radius-circle
      }
    }

    &.disabled > input {
      & + .switch-button-slider {
        background-color: var(--color-idle)
      }

      &:checked + .switch-button-slider {
        opacity: 0.3
        background-color: var(--color-brand)
      }

      &:focus + .switch-button-slider {
        box-shadow: 0 0 1px var(--color-brand-transparent)
      }
    }
  }
</style>
