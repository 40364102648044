<template>
  <JobTitlesField v-bind="$attrs" show-suggestions :suggestions="suggestions" v-on="$listeners" />
</template>

<script>
  import ApolloMixin from '@/mixins/ApolloMixin'
  import recommendedQuery from './recommended.gql'
  import JobTitlesField from '../JobTitlesField/JobTitlesField.vue'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  JobTitlesField: JobTitlesField.__enums,
},

    name: 'FreelancerJobTitlesField',
    components: {
      JobTitlesField,
    },
    mixins: [ApolloMixin],
    props: {
      freelancerId: {
        type: Number,
        default: null,
      },
      experienceId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        suggestions: [],
      }
    },
    apollo: {
      suggestions: {
        query: recommendedQuery,
        variables() {
          const { freelancerId, experienceId } = this
          return { freelancerId, experienceId }
        },
        update(data) {
          return data?.recommendedJobTitles
        },
        skip() {
          const { freelancerId } = this
          return !freelancerId
        },
        fetchPolicy: 'cache-first',
      },
    },
  }
</script>
