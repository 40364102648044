<template>
  <Field
    :id="uid"
    class="textarea-field"
    :class="[classes, $('-light')]"
    :label="label"
    :sup-label="supLabel"
    :sup-type="supType"
    :error="error"
    :disabled="disabled"
    :helper="helper"
  >
    <slot slot="label" name="label" />

    <slot name="before" />

    <textarea
      :id="uid"
      v-autosize="autosize"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :rows="rows"
      :maxlength="maxlength"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event.target.value)"
      @focus="$emit('focus')"
    ></textarea>

    <slot name="after" />
  </Field>
</template>

<script>
  import Field from '@/core/layout/Field/Field'

  import { uuid } from '@/utils/app'

  const { SupType } = Field

  const Background = {
    IDLE: 'idle',
    WHITE: 'white',
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Background,SupType,
    name: 'TextareaField',
    __enums: {
      Background,
      SupType,
    },
    components: {
      Field,
    },
    props: {
      /**
       * Unique identifier used to match the input and peer it with its label
       * (randomly generated if null or undefined)
       */
      id: {
        type: String,
        default: null,
      },
      /**
       * Text field's value
       */
      value: {
        type: String,
        default: null,
      },
      /**
       * Text field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript
       * @type {SupType}
       */
      supLabel: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript color
       */
      supType: {
        type: String,
        default: null,
      },
      /**
       * Text input's placeholder
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Text field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Number of rows the textarea should use
       */
      rows: {
        type: Number,
        default: 5,
      },
      /**
       * Renders the teaxarea autosizable according to its content
       * If 'true', the 'rows' props is ignored
       */
      autosize: {
        type: Boolean,
        default: false,
      },
      /**
       * Apply the 'autofocus' attribute on the textarea tag
       */
      autofocus: {
        type: Boolean,
        default: false,
      },
      /**
       * Maximum amount of char to put in the textarea
       */
      maxlength: {
        type: Number,
        default: null,
      },
      /**
       * Helper text to display under the textarea
       */
      helper: {
        type: String,
        default: null,
      },
      /**
       * Background style
       */
      background: {
        type: String,
        default: Background.IDLE,
      },
      /**
       * Can resize the textarea or not
       */
      resizable: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Unique identifier used to match the input and peer it with its label
       */
      uid() {
        const { id, _uid } = this

        return id || uuid(_uid)
      },
      /**
       * CSS classes applied on the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { error, background, resizable } = this

        return {
          'textarea-field--invalid': !!error,
          'textarea-field--resizable': !!resizable,
          [`textarea-field--background-${background}`]: true,
        }
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_variables.styl'

  .textarea-field {
    textarea {
      width: 100%
      height: calc(100% - 26px)
      font-size: 16px
      font-family: $font
      font-weight: $font-regular
      font-size: 16px
      box-shadow: none
      border-radius: $radius-medium
      padding: 8px 16px
      caret-color: var(--color-input-highlight)
      outline: none !important
      resize: none
      text-decoration: none
      white-space: pre-wrap
      box-sizing: border-box
      white-space: pre-wrap
      appearance: none

      &:disabled {
        opacity: .5
        background-color: transparent
      }

      &:placeholder-shown:not(:hover) {
        background: var(--color-input-background-idle)
        border-color: var(--color-input-background-idle)
      }
    }

    &--resizable textarea {
      resize: block
    }

    &--background-white {
      textarea {
        &:placeholder-shown:not(:hover) {
          background: white
        }
      }
    }

    ::placeholder {
      color: var(--color-input-placeholder)
    }

    &--light {
      textarea {
        border: solid var(--color-input-border) 1px

        &:not([disabled]) {
          &:hover,
          &:focus {
           border-color: var(--color-input-highlight)
          }
        }
      }

      &^[0]--invalid textarea {
        border-color: var(--color-input-error)

        &:hover:not(:focus) {
          border-color: var(--color-input-error)
        }
      }
    }
  }
</style>
