/* eslint-disable no-param-reassign */
export const APP_NAV_BAR_SET = 'APP_NAV_BAR_SET'
export const APP_BACKOFFICE_LAYOUT_UPDATE = 'APP_BACKOFFICE_LAYOUT_UPDATE'

const layout = {
  // ====================  STATE  ==================== //

  state: {
    navBar: {
      actionLabel: undefined,
      actionLink: undefined,
      title: undefined,
    },

    backoffice: {
      collapseSidebar: false,
      collapseFreelancers: true,
      collapseAdmin: true,
      collapseSidePanel: false,
      matchesLayout: 'kanban',
    },
  },

  // ====================  GETTERS  ==================== //

  getters: {
    /**
     * Return the configuration of the navbar
     * @returns {Object}
     */
    selectNavBar: state => state.navBar,

    /**
     * Return the layout configuration for the backoffice
     * @returns {Object}
     */
    selectBackofficeLayout: state => state.backoffice,
  },

  // ====================  MUTATIONS  ==================== //

  mutations: {
    /**
     * Set the navbar back link
     * @param {Object} state
     * @param {Object} back link params
     * @returns {void}
     */
    [APP_NAV_BAR_SET](state, payload) {
      state.navBar = {
        ...state.navBar,
        ...payload,
      }
    },

    /**
     * Update the backoffice layout preferences
     * @param {Object} state
     * @param {Object} back link params
     * @returns {void}
     */
    [APP_BACKOFFICE_LAYOUT_UPDATE](state, payload) {
      state.backoffice = {
        ...state.backoffice,
        ...payload,
      }
    },
  },

  // ====================  ACTIONS  ==================== //

  actions: {
    /**
     * Called to set the back link of the nav bar
     * @param {Object} context
     * @param {Object} payload
     * @returns {void}
     */
    setNavBar(context, payload) {
      const { commit } = context

      commit(APP_NAV_BAR_SET, payload)
    },

    /**
     * Update the backoffice layout preferences
     * @param {Object} context
     * @param {Object} payload
     * @returns {void}
     */
    updateBackofficeLayout(context, payload) {
      context.commit(APP_BACKOFFICE_LAYOUT_UPDATE, payload)
    },
  },
}

export default layout
