<template>
  <div ref="searchSelect" class="search-select" :class="dropDownClass">
    <div v-if="$slots.left" :class="$('slot-left')">
      <slot name="left" />
    </div>

    <Multiselect
      v-if="computedOptions"
      :value="computedValue"
      :allow-empty="allowEmpty"
      :close-on-select="true"
      :disabled="disabled"
      :options="computedOptions"
      :placeholder="placeholder"
      :preserve-search="true"
      :show-labels="false"
      :clear-on-select="clearOnSelect"
      track-by="id"
      label="name"
      @search-change="onSearchChange"
      @select="onSelect"
      @open="onOpen"
    />

    <div v-if="$slots.right" :class="$('slot-right')">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'

  /**
   * When user is entering a new element (not an existing option), we insert
   * it manually as option with a specific ID
   * @param {Number}
   */
  const NEW_ELEMENT = -1

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Multiselect: Multiselect.__enums,
},

    name: 'SearchSelect',
    components: {
      Multiselect,
    },
    props: {
      /**
       * Model value
       */
      value: {
        type: [String, Number],
        default: null,
      },
      /**
       * Available options
       * @type {Array.<Object>}
       */
      options: {
        type: Array,
        default: () => [],
      },
      /**
       * Disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Whether the search should be cleared when the input is focused
       */
      clearOnSelect: {
        type: Boolean,
        default: false,
      },
      /**
       * Whether it's possible to select no value
       */
      allowEmpty: {
        type: Boolean,
        default: false,
      },
      /**
       * Placeholder
       */
      placeholder: {
        type: String,
        default: 'Sélectionner',
      },
      /**
       * Allow to create a new options
       */
      canCreate: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        /**
         * Keys to search in the label and might create a new object
         * @type {String}
         */
        search: '',
        /**
         * Class that will be used to determine if the dropdown should open
         * on top or on the bottom of it's parent
         * @type {String}
         */
        dropDownClass: 'open-bottom',
      }
    },
    computed: {
      /**
       * Pick the right object among options comparing 'value' and options' 'id'
       * @type {Object}
       */
      computedValue() {
        const { value, computedOptions } = this

        return computedOptions?.find(o => o.id === value)
      },
      /**
       * If there is a search, return the options with the search at the begging
       * @type {Array}
       */
      computedOptions() {
        const { options, search, canCreate } = this

        return search && canCreate ? [{ id: NEW_ELEMENT, name: search }, ...options] : options
      },
    },
    methods: {
      /**
       * On search-select click, check its position.
       * If the space between it and the bottom of the page is inferior to 300px
       * then display the dropdown on top, otherwise, above it.
       * @returns {void}
       */
      onOpen() {
        const boundings = this.$refs.searchSelect.getBoundingClientRect()
        const height = window.innerHeight
        const bottom = height - boundings.bottom

        this.dropDownClass = bottom > 300 || bottom > boundings.top ? 'open-bottom' : 'open-top'
      },
      /**
       * Fired when the search value (entered wtraight by the user) changes
       * @param {String} value
       * @returns {void}
       */
      onSearchChange(value) {
        this.search = value.trim()
      },
      /**
       * Fired when the select value changes
       * Emit "select" on selection of existing element
       * If element is created, emit "create"
       * @param {Object} event
       * @param {Object} value
       * @returns {void}
       */
      onSelect(value) {
        if (value) {
          if (value.id === NEW_ELEMENT && this.canCreate) {
            this.$emit('create', value.name)
          }

          this.$emit('input', value.id, value.name)
        }
      },
    },
  }
</script>

<style lang="stylus">

  @import '~@/assets/css/_text.styl'
  @import '~@/assets/css/_variables.styl'

  .search-select {
    position: relative
    font-size: 16px
    color: var(--color-input-font)
    display: flex

    &-slot-right {
      margin-left: 16px
    }

    &-slot-left {
      margin-right: 16px
    }

    &-slot-right,
    &-slot-left {
      display: flex
      flex-direction: column
      align-self: center
    }

    .multiselect {
      flex: 1
    }

    .multiselect__tags {
      overflow: hidden
      white-space: nowrap
      line-height: 40px
    }

    .multiselect__single {
      padding-left: 20px
    }

    &.open-top .multiselect__content {
      bottom: 40px
    }

    &.open-bottom .multiselect__content {
      top: 40px
    }

    input {
      display: contents
      box-shadow: none
      border-radius: $radius-medium
      padding: 0 16px
      caret-color: var(--color-input-highlight)
      width: 100%
      height: 40px
      font-family: $font
      font-weight: $font-regular
      font-size: 16px
      border: none

      &:focus {
        box-shadow: none
      }
    }

    .multiselect:not(.multiselect--disabled) {
      &:hover {
        border-color: var(--color-input-highlight)
      }

      &.multiselect--active {
        border-color: var(--color-input-highlight)
      }
    }

    .multiselect {
      border: 1px solid var(--color-input-border)
      border-radius: $radius-medium
      min-width: 150px
      height: 40px
      position: relative
      font-weight: $font-regular
      background-color: var(--color-background)
      cursor: pointer
      outline: none
      -webkit-appearance: none
      -moz-appearance: none

      &.multiselect--disabled {
        opacity: .5
      }

      .multiselect__tags {
        margin-right: 40px
        text-align: left

        .multiselect__placeholder {
          padding: 0 16px
          line-height: 40px
        }
      }

      &__content {
        width: 100%
        position: absolute
        max-height: 300px
        left: 0
        padding: 8px
        margin: 8px 0
        box-sizing: border-box
        background-color: var(--color-background)
        box-shadow: 0px 2px 8px rgba(0, 0, 0, .2)
        overflow-y: scroll
        z-index: 11

        li {
          min-height: 25px
        }
      }

      &--disabled {
        opacity: .5
      }

      &__input {
        display: block
        width: 100%
        box-sizing: border-box
        appearance: none
      }

      &__element {
        position: relative
        height: 35px
      }

      &__option {
        ellipsis()
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        display: block
        padding: 8px 24px
        font-weight: $font-regular
        cursor: pointer

        &:hover, &--highlight {
          background-color: var(--color-input-options-hover)
        }

        &--selected {
          color: var(--color-input-highlight)
        }

        span {
          display: block
          line-height: 19px
        }
      }
    }
  }
</style>
