import _get from 'lodash/get'

/**
 * Get the time from the extensions sent by the server
 * Time represent the server's time
 * Once we receive it and extract it, we update VueX with it.
 * @param  {Object} store
 * @param  {Object} operation
 * @param  {Function} forward
 * @returns {Object}
 */
function serverTime(store, operation, forward) {
  return forward(operation).map(data => {
    const { dispatch } = store
    const time = _get(data, 'extensions.time')

    dispatch('setServerTime', time)

    return data
  })
}

export default serverTime
