<template>
  <Field
    :id="uid"
    class="text-field"
    :class="[classes, $('-light')]"
    :label="label"
    :sup-label="supLabel"
    :sup-type="supType"
    :ellipsis="ellipsis"
    :error="error"
    :disabled="disabled"
    :helper="helper"
  >
    <slot v-if="$slots.label" slot="label" name="label" />

    <div v-if="editable" class="text-field-content text-field-content--editable">
      <slot name="left" />

      <!-- lang="en" allow the user to use "." in separator for number  -->
      <!-- If there is no placeholder we put an empty string because we need to
      to style the element with `:placeholder-shown`  -->
      <input
        :id="uid"
        :type="type"
        :value="value"
        :placeholder="placeholder || ' '"
        :disabled="disabled"
        :name="name"
        :autofocus="autofocus"
        :step="step"
        :class="inputClasses"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        lang="en"
        @input="emitEvent('input', $event)"
        @change="emitEvent('change', $event)"
        @keyup.enter="emitEvent('enter', $event)"
      />

      <slot name="right" />

      <NavButton
        v-if="deletable && !!value"
        :disabled="disabled"
        :class="$('close')"
        icon="icon-cross-circle"
        theme="secondary-void"
        size="small"
        @click="emitEvent('input', null)"
      />

      <i v-else-if="icon" :class="[icon, $('right-icon')]" />
    </div>

    <div v-else class="text-field-content--not-editable">
      {{ value }}
      <slot name="right" />
    </div>
  </Field>
</template>

<script>
  import _get from 'lodash/get'

  import Field from '@/core/layout/Field/Field'
  import NavButton from '@/core/controls/NavButton/NavButton'

  import { uuid } from '@/utils/app'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
  NavButton: NavButton.__enums,
},

    name: 'TextField',
    components: {
      Field,
      NavButton,
    },
    props: {
      /**
       * Unique identifier used to match the input and peer it with its label
       * (randomly generated if null or undefined)
       */
      id: {
        type: String,
        default: null,
      },
      /**
       * Text input's name
       */
      name: {
        type: String,
        default: 'text-field',
      },
      /**
       * Text input's type
       */
      type: {
        type: String,
        default: 'text',
      },
      /**
       * Text field's value
       */
      value: {
        type: [String, Number],
        default: null,
      },
      /**
       * Text field's label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript text
       */
      supLabel: {
        type: String,
        default: null,
      },
      /**
       * Label's superscript color
       */
      supType: {
        type: String,
        default: null,
      },
      /**
       * Text input's placeholder
       */
      placeholder: {
        type: String,
        default: null,
      },
      /**
       * Class of the icon to display at the right of the field in the input
       */
      icon: {
        type: String,
        default: '',
      },
      /**
       * Text field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Precise if we can edit or not the text
       */
      editable: {
        type: Boolean,
        default: true,
      },
      /**
       * If 'true', the label ends with an ellipsis it can't be displayed
       * entirely
       */
      ellipsis: {
        type: Boolean,
        default: true,
      },
      /**
       * Text input's max length
       */
      maxlength: {
        type: Number,
        default: null,
      },
      /**
       * Text field should be focused
       */
      autofocus: {
        type: Boolean,
        default: false,
      },
      /**
       * Check if the field is deletable with a cross on the right or not
       */
      deletable: {
        type: Boolean,
        default: false,
      },
      /**
       * Step between value used in the numeric input
       */
      step: {
        type: String,
        default: '',
      },
      /**
       * Helper text display under the field
       */
      helper: {
        type: String,
        default: null,
      },
      /**
       * Tell the browser how the input could be autocompleted
       */
      autocomplete: {
        type: String,
        default: 'off',
      },
    },
    computed: {
      /**
       * Unique identifier used to match the input and peer it with its label
       */
      uid() {
        const { id, _uid } = this

        return id || uuid(_uid)
      },
      /**
       * CSS classes applied on the root node
       * @type {Object.<Boolean>}
       */
      classes() {
        const { editable, error, disabled } = this

        return {
          'text-field--editable': editable,
          'text-field--invalid': !!error,
          'text-field--disabled': disabled,
        }
      },
      /**
       * CSS classes to apply to the input
       * @type {Object.<Boolean>}
       */
      inputClasses() {
        const { deletable, $slots } = this

        return {
          'text-field-input--deletable': deletable,
          'text-field-input--with-left-icon': !!$slots.left,
        }
      },
    },
    methods: {
      /**
       * Emit an event with the passed event 'name' and the field value
       * according to its declared 'type'.
       * @param {String} name
       * @param {Object} event
       */
      emitEvent(name, event) {
        const { value } = _get(event, 'target', '')

        this.$emit(name, value)
      },
    },
  }
</script>

<style lang="stylus" scoped>

  @import '~@/assets/css/_variables.styl'

  .text-field {
    display: block
    position: relative

    >>> label {
      // We replace the margin (assets/css/_text.styl) by padding
      // or else the :hover effect breaks when hovering the margin
      // We can’t do it on Field yet, too much side effects
      margin-bottom: 0
      padding-bottom: 8px
    }

    &-content {
      &--editable {
        display: flex
        line-height: 40px
      }

      &--not-editable {
        margin: 8px 0
      }
    }

    input {
      width: 100%
      height: 40px
      font-size: 16px
      font-family: $font
      font-weight: $font-regular
      box-shadow: none
      border-radius: $radius-medium
      padding: 0 16px
      caret-color: var(--color-input-highlight)
      box-sizing: border-box
      appearance: none

      &:focus {
        box-shadow: none
      }

      &:placeholder-shown:not(:hover):not(:focus) {
        background: var(--color-input-background-idle)
        border-color: var(--color-input-background-idle)
      }
    }

    button {
      position: absolute
      right: 0px
      top: 24px
    }

    & &-close {
      position: absolute
      right: 0px

      >>> i {
        font-size: 16px
      }
    }

    i&-right-icon {
      font-size: 24px
      position: absolute
      top: 32px
      right: 8px
    }

    input.text-field-input--with-left-icon {
      padding-left: 40px
    }

    input.text-field-input--deletable {
      padding-right: 40px
    }

    input {
      background-color: var(--color-input-background)
      border: solid var(--color-input-border) 1px

      &:not([disabled]) {
        &:hover,
        &:focus {
          border-color: var(--color-input-highlight)
        }
      }
    }

    &^[0]--invalid input {
      border-color: var(--color-input-error)

      &:hover:not(:focus) {
        border-color: var(--color-input-error)
      }
    }

    &--disabled input:disabled {
      opacity: .32
    }

    ::placeholder {
      color: var(--color-input-placeholder)
    }
  }
</style>
