<template>
  <div class="skill-list" :class="classes">
    <div :class="$('container')">
      <div v-for="(skill, index) in skills" :key="skill.id" :class="$('item')">
        <Skill
          :id="skill.id"
          :name="skill.name"
          :data-dy-skill-list-skill-name="skill.name"
          :duration="skill.duration"
          :mission-duration="skill.missionDuration"
          :negative="skill.negative"
          :favorite="skill.favorite"
          :size="skill.size"
          :theme="skill.theme"
          :disabled="skill.disabled"
          :action="skill.action"
          :fixed-width="fixedWidth"
          :linked-in-endorsements="skill.linkedInEndorsements"
          :corporate-ratings="skill.corporateRatings"
          :full-width="true"
          @action="action => onSkillAction(action, skill, index)"
        >
          <CheckboxButton
            v-if="skill.primaryCheckbox"
            slot="suffix"
            :value="skill.primary"
            :label="skill.primaryLabel"
            :rounded="true"
            theme="light"
            :disabled="skill.primaryDisabled"
            :class="$('primary')"
            @input="value => onPrimaryChange(value, index)"
          />
          <Txt
            v-if="skill.primaryDisplay"
            slot="prefix"
            :value="skill.primaryLabel"
            :size="Txt.Size.XXXS"
            :inline="true"
            :class="$('primary-display')"
          />
        </Skill>

        <SingleSelect
          v-if="skill.durationShown"
          :value="skill.durationValue"
          :options="skill.durationOptions"
          :required="skill.durationRequired"
          :placeholder="skill.durationPlaceholder"
          :disabled="skill.durationDisabled"
          :class="$('duration')"
          @input="value => onDurationChange(value, index)"
        />
      </div>

      <template v-if="placeholders">
        <Txt
          v-for="(placeholder, index) in placeholders"
          :key="`placeholder-${index}`"
          :value="placeholder.text"
          :size="Txt.Size.XS"
          :theme="Txt.Theme.GREY_MEDIUM"
          :class="$('placeholder')"
        />
      </template>

      <NavButton
        v-if="expand"
        :text="expand.text"
        :size="NavButton.Size.SMALLER"
        :theme="NavButton.Theme.SECONDARY_VOID"
        :class="$('expand')"
        @click="onExpandPress"
      />
    </div>
  </div>
</template>

<script>
  import _get from 'lodash/get'
  import _keyBy from 'lodash/keyBy'
  import _orderBy from 'lodash/orderBy'
  import _range from 'lodash/range'

  import CheckboxButton from '@/core/inputs/CheckboxButton/CheckboxButton'
  import NavButton from '@/core/controls/NavButton/NavButton'
  import SingleSelect from '@/core/inputs/SingleSelect/SingleSelect'
  import Skill from '@/core/badges/Skill/Skill'
  import Txt from '@/core/text/Txt/Txt'

  import FreelancerTypes from '@/types/freelancer'
  import MissionTypes from '@/types/mission'

  import { formatFreelancerSkillDuration, formatMissionSkillDuration } from '@/utils/format'

  import I18nMixin from '@/mixins/I18nMixin'

  const Context = {
    DEFAULT: 'default',
    DECLARED: 'declared', // Freelancer's declared skills
    EXPERIENCES: 'experiences', // Freelancer's experiences skills
    MISSION: 'mission', // Mission's skills
    MATCH: 'match', // Ordered by mission skills but displaying freelancer's declared duration
  }

  export const Display = {
    INLINE: 'inline',
    COLUMN: 'column',
    DOUBLE_COLUMN: 'double-column',
  }

  const { FreelancerSkillDuration } = FreelancerTypes
  const { MissionSkillDuration } = MissionTypes
  const { Theme, Size } = Skill

  /**
   * Returns the given 'skills' list ordered according to the actual 'context'.
   * For example, if it targets mission's skills, it relies on the
   * 'yearsRequired' property.
   * @param {Array.<Object>} skills
   * @param {String} context
   * @return {Array.<Object>}
   */
  function orderSkillsFromContext(skills, context) {
    const durationMap = {
      [Context.DEFAULT]: 'duration',
      [Context.DECLARED]: 'duration',
      [Context.EXPERIENCES]: 'duration',
      [Context.MISSION]: 'yearsRequired',
      [Context.MATCH]: 'yearsRequired',
    }

    const mapped = skills.map(s => ({
      ...s,
      [durationMap[context]]: s[durationMap[context]] || 0,
    }))
    const ordered = _orderBy(mapped, ['primary', durationMap[context]], ['desc', 'desc'])
    const hashed = _keyBy(skills, 'id')

    return ordered.map(s => hashed[s.id])
  }

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  CheckboxButton: CheckboxButton.__enums,
  NavButton: NavButton.__enums,
  SingleSelect: SingleSelect.__enums,
  Skill: Skill.__enums,
  Txt: Txt.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Context,Display,Size,Theme,
    name: 'SkillList',
    __enums: {
      Context,
      Display,
      Size,
      Theme,
    },
    components: {
      CheckboxButton,
      NavButton,
      SingleSelect,
      Skill,
      Txt,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * List of skills (can contains many properties)
       * @type {Array.<Object>}
       */
      value: {
        type: Array,
        default: null,
      },
      /**
       * Define how the list should be displayed
       */
      display: {
        type: String,
        default: Display.INLINE,
      },
      /**
       * Define in which context this list of skills is used
       */
      context: {
        type: String,
        default: Context.DEFAULT,
      },
      /**
       * If present, each skill will display a checkbox allowing to
       * make a skill primary or not
       */
      withPrimary: {
        type: Boolean,
        default: false,
      },
      /**
       * Is the skills list editable or not (including primary, duration
       * & deletion) ?
       */
      editable: {
        type: Boolean,
        default: false,
      },
      /**
       * Disable the list items if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Maximum number of skills to show
       */
      max: {
        type: Number,
        default: null,
      },
      /**
       * Max primary skill number
       */
      primaryMax: {
        type: Number,
        default: null,
      },
      /**
       * Size applied on each skill
       * Can be: "small", "medium", "big"
       */
      size: {
        type: String,
        default: null,
      },
      /**
       * Color theme applied to the skills
       * Can be: "blue", "grey"
       */
      theme: {
        type: String,
        default: null,
      },
      /**
       * If 'true', fix the width of the duration
       */
      fixedWidth: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        /**
         * Is skill list expanded or not, according to the actual number of
         * skills and the "max" prop limit of rendered skills when collapsed
         * @type {Boolean}
         */
        expanded: false,
      }
    },
    computed: {
      /**
       * CSS classes applied to the list
       * @type {Object.<Boolean>}
       */
      classes() {
        const { value, display } = this

        const items = value || []
        const hasNotes = !!items.find(s => {
          const options = _get(s, 'durationOptions', [])

          return !!options.find(o => o.note)
        })

        return {
          [`skill-list--${display}`]: !!display,
          'skill-list--with-notes': hasNotes,
        }
      },
      /**
       * List of all the needed computed properties to properly display the
       * list of skills and the potential assets (primary checkbox,
       * duration select, ...)
       *
       * Let's make all the computations here, to keep everything in the same
       * place, avoid computation methods and maintain the template as clean
       * as possible. Thanks !
       */
      skills() {
        const {
          value,
          max,
          primaryMax,
          size,
          theme,
          withPrimary,
          display,
          disabled,
          context,
          editable,
          expanded,
          __,
        } = this

        const items = value || []
        const nbPrimary = items.filter(i => i.primary).length
        const primaryFull = !!primaryMax && primaryMax - nbPrimary <= 0

        const orderedItems = orderSkillsFromContext(items, context)

        const shownItems = !expanded && max ? orderedItems.slice(0, max) : orderedItems
        /* eslint-disable no-nested-ternary */
        return shownItems.map(item => ({
          ...item,
          // Skill
          duration: editable ? null : item.duration,
          missionDuration:
            !editable && context === Context.MISSION && item.primary
              ? item.yearsRequired || null
              : null,
          negative: item.negative,
          favorite: item.favorite,
          size: item.size || size,
          theme:
            withPrimary && item.primary
              ? Skill.Theme.BLUE_DARK // Force "blue-dark" for primary skills
              : item.theme || theme,
          disabled: item.disabled || disabled,
          action: editable
            ? Skill.Action.DELETE // Force "delete" if primary skill edition is on
            : item.action || null,
          // Primary Checkbox
          primaryCheckbox: editable && withPrimary && (item.primary || !primaryFull),
          primaryDisplay:
            withPrimary &&
            ((!editable && display === Display.COLUMN && item.primary) ||
              (editable && primaryFull && !item.primary)),
          primaryLabel:
            context === Context.MISSION
              ? item.primary
                ? __('cp:common:skill-list:checkbox-button:label:mandatory')
                : __('cp:common:skill-list:checkbox-button:label:optional')
              : item.primary
              ? __('cp:common:skill-list:checkbox-button:label:primary')
              : __('cp:common:skill-list:checkbox-button:label:secondary'),
          primaryDisabled: !editable,
          //  Duration Select
          durationShown: editable && [Context.MISSION, Context.DECLARED].includes(context),
          durationValue:
            context === Context.MISSION
              ? item.primary
                ? item.yearsRequired || null
                : null
              : item.duration || FreelancerSkillDuration[0],
          durationOptions:
            item.durationOptions ||
            (context === Context.MISSION
              ? MissionSkillDuration.map(v => ({
                  id: v,
                  name: formatMissionSkillDuration(v),
                }))
              : FreelancerSkillDuration.map(v => ({
                  id: v,
                  name: formatFreelancerSkillDuration(v),
                }))),
          durationRequired: context !== Context.MISSION,
          durationPlaceholder: item.primary
            ? __('cp:common:skill-list:duration-select:default')
            : '-',
          durationDisabled: context === Context.MISSION && !item.primary,
        }))
        /* eslint-enable no-nested-ternary */
      },
      /**
       * Properties of the placeholders.
       * In some cases, we want to show a placeholder for each skill that have
       * not been selected yet until the 'max' limit.
       * If "max = 5" and 2 skills are selected, we will display 3 placeholders.
       *
       * Amount of placeholder to display according to the amount of skills
       * already selected and the maximum amount of skill you can select
       * @type {Object}
       */
      placeholders() {
        const { value, max, display, __ } = this

        const count = _get(value, 'length', 0)
        const remaining = max - count

        if (!max || !value || !remaining || display !== Display.DOUBLE_COLUMN) {
          return null
        }

        return _range(count + 1, max + 1).map(index => ({
          text: __('cp:core:skill-list:placeholder', { index }),
        }))
      },

      /**
       * Properties of the expand button determining if it should be displayed
       * or not, and what it should tell.
       * @type {Object}
       */
      expand() {
        const { value, max, expanded, __ } = this

        const count = _get(value, 'length', 0)
        const remaining = max && count - max > 0 ? value.slice(max) : null

        if (!remaining) {
          // Display nothing if there is no more skills to show / expand
          return null
        }

        const text = expanded
          ? __('cp:core:skill-list:hide')
          : __('cp:core:skill-list:remaining', { remaining: remaining.length })

        return { text }
      },
    },
    methods: {
      /**
       * Fired when a skill is checked
       * Set item's primary to checkbox's value
       * Update the list using @change
       * @param {Boolean} primary
       * @param {Number} index
       * @returns {void}
       */
      onPrimaryChange(primary, index) {
        const { value, context, skills } = this
        const newValue = value.map(s => ({ ...s })) // We deep clone the skillList and emit it for update

        // As the skill list is ordered before to be displayed, 'value' and
        // 'skills' don't have the same order, and 'index' points the targeted
        // element according to the 'skills' order.
        const targetId = skills[index].id
        const item = value.find(s => s.id === targetId)
        const itemIndex = value.findIndex(s => s.id === targetId)

        if (!item) {
          return
        }

        // Remove the targeted element, and append it with the
        // appropriate changes.
        newValue.splice(itemIndex, 1, {
          ...item,
          primary,
          // eslint-disable-next-line no-nested-ternary
          yearsRequired: context === Context.MISSION ? 0 : item.yearsRequired,
        })

        this.$emit('change', orderSkillsFromContext(newValue, context))
      },
      /**
       * Fired when the XP duration associated to a skill changes
       * @param {String} duration
       * @param {Number} index
       * @returns {void}
       */
      onDurationChange(duration, index) {
        const { value, context, skills } = this
        const newValue = value.map(s => ({ ...s })) // We deep clone the skillList and emit it for update

        // The property to change is different according to the skill type
        const key = context === Context.MISSION ? 'yearsRequired' : 'duration'

        // As the skill list is ordered before to be displayed, 'value' and
        // 'skills' don't have the same order, and 'index' points the targeted
        // element according to the 'skills' order.
        const targetId = skills[index].id
        const itemIndex = value.findIndex(s => s.id === targetId)

        newValue[itemIndex][key] = duration

        this.$emit('change', orderSkillsFromContext(newValue, context))
      },
      /**
       * Fired when an action is performed on a tag
       * @param {Object} action
       * @param {Object} item
       * @param {Number} index
       * @returns {void}
       */
      onSkillAction(action, item) {
        const { value } = this

        // As the skill list is ordered before to be displayed, 'value' and
        // 'skills' don't have the same order, and 'index' points the targeted
        // element according to the 'skills' order.
        const index = value.findIndex(s => s.id === item.id)

        if (value && action === Skill.Action.DELETE) {
          // Modify the current list value by removing the selected item
          const result = value.slice()
          result.splice(index, 1)

          this.$emit('change', result)
        }

        this.$emit(
          action,
          value.find(s => s.id === item.id),
          index,
        )
      },
      /**
       * Fired when the expand button is pressed (hide or show remaining skills)
       * @returns {void}
       */
      onExpandPress() {
        this.expanded = !this.expanded
      },
    },
  }
</script>

<style lang="stylus" scoped>
  @import '~@/assets/css/_variables.styl'
  @import '~@/assets/css/_layout.styl'

  .skill-list {
    width: 100%
    box-sizing: border-box

    & &-container {
      list-style-type: none
      padding: 0
      margin: 0
    }

    & &-item {
      display: flex
      flex-wrap: wrap
      margin: 0 auto 0 0

      > *:first-child {
        flex: 1

        &:not(:last-child) {    // with a duration select as last child
          max-width: calc(100% - 148px)
        }
      }

      > *:not(:first-child) {   // duration select
        margin-left: 16px
      }
    }

    & &-primary {
      margin-right: 4px
    }

    & &-primary-display {
      line-height: 24px
      padding: 0 10px
      color: var(--color-font-contrast)
      background-color: rgba(255,255,255, 0.08)
      border-radius: $radius-medium
      opacity: .7
    }

    & &-duration {
      min-width: 140px
      max-width: 140px
    }

    &&--with-notes &-duration {
      min-width: 180px
      max-width: 180px
    }

    & &-expand {
      // Fix the button so it's aligned with skill items
      position: relative
      bottom: 4px
    }

    &&--inline {
      & ^[0]-container {
        display: flex
        flex-wrap: wrap
      }

      & ^[0]-item {
        display: inline-flex
        margin: 0 4px 0 0
        // Avoid text making container extend
        min-width: 0
      }
    }

    &&--double-column {
      & ^[0]-container {
        width: 100%
        display: flex
        flex-wrap: wrap

        // Skill items and placeholders

        & > * {
          width: calc(50% - 4px)

          &:nth-child(odd) {
            margin-right: 8px
          }
        }
      }

      & ^[0]-placeholder {
        box-sizing: border-box
        height: 40px
        padding-left: 16px
        border-radius: 20px
        margin-bottom: 8px
        border: dashed 1px var(--color-grey-30)
        line-height: 40px
      }
    }
  }
</style>
