import { __ } from '@comet/i18n'

import KYCTypes from '@/types/kyc'
import UserTypes from '@/types/user'

import config from '@/config'

const { UserKycStatus, UserKycType, KYCRefusalReason } = KYCTypes
const { UserRole } = UserTypes

/**
 * Return the expected color class according to the document's status
 * @param {Object} mission
 * @returns {String}
 */
function getKYCColor(status) {
  const {
    UPLOAD_FAIL,
    PENDING_COMET_REVIEW,
    COMET_ACCEPTED,
    COMET_REFUSED,
    EXPIRED,
  } = UserKycStatus

  switch (status) {
    case UPLOAD_FAIL:
    case COMET_REFUSED:
    case EXPIRED:
      return 'negative'
    case PENDING_COMET_REVIEW:
      return 'brand'
    case COMET_ACCEPTED:
      return 'positive'
    default:
      return 'idle'
  }
}

function formatKYCStatus(key) {
  return (
    {
      [UserKycStatus.UPLOADED]: __('ty:kyc:status:uploaded'),
      [UserKycStatus.UPLOAD_FAIL]: __('ty:kyc:status:upload-fail'),
      [UserKycStatus.PENDING_COMET_REVIEW]: __('ty:kyc:status:pending-review'),
      [UserKycStatus.COMET_ACCEPTED]: __('ty:kyc:status:accepted'),
      [UserKycStatus.COMET_REFUSED]: __('ty:kyc:status:refused'),
      [UserKycStatus.EXPIRED]: __('ty:kyc:status:expired'),
    }[key] || undefined
  )
}

function formatKYCType(type) {
  const trans = {
    [UserKycType.ID]: __('ty:kyc:type:id'),
    [UserKycType.ID_SECONDARY]: __('ty:kyc:type:id-secondary'),
    [UserKycType.KBIS]: __('ty:kyc:type:kbis'),
    [UserKycType.BANK_ACCOUNT_DETAILS]: __('ty:kyc:type:rib'),
    [UserKycType.PROOF_OF_ADDRESS]: __('ty:kyc:type:address-proof'),
    [UserKycType.INSEE]: __('ty:kyc:type:insee'),
    [UserKycType.URSSAF]: __('ty:kyc:type:urssaf'),
    [UserKycType.RC_PRO]: __('ty:kyc:type:rcpro'),
    [UserKycType.STATUSES]: __('ty:kyc:type:statuses'),
    [UserKycType.OWNERSLIST]: __('ty:kyc:type:ownerlist'),
  }

  return trans[type] || undefined
}

function formatKYCInfos(type) {
  const trans = {
    [UserKycType.ID]: __('ty:kyc:infos:id'),
    [UserKycType.ID_SECONDARY]: __('ty:kyc:infos:id-secondary'),
    [UserKycType.PROOF_OF_ADDRESS]: __('ty:kyc:infos:address-proof'),
    [UserKycType.INSEE]: __('ty:kyc:infos:insee', {
      olink: `<a href='${config.urls.sireneHelp}' target='_blank'>`,
      elink: '</a>',
    }),
    [UserKycType.URSSAF]: __('ty:kyc:infos:urssaf', {
      olink: `<a href='${config.urls.urssafHelp}' target='_blank'>`,
      elink: '</a>',
    }),
    [UserKycType.RC_PRO]: __('ty:kyc:infos:rcpro', {
      olink: `<a href='${config.urls.rcproHelp}' target='_blank'>`,
      elink: '</a>',
    }),
    [UserKycType.STATUSES]: __('ty:kyc:infos:statuses'),
    [UserKycType.OWNERSLIST]: __('ty:kyc:infos:ownerlist'),
  }

  return trans[type] || undefined
}

function formatKYCRefusalLabel(key) {
  const trans = {
    [KYCRefusalReason.EXPIRED]: __('ty:kyc:refusal-label:expired'),
    [KYCRefusalReason.UNREADABLE]: __('ty:kyc:refusal-label:unreadable'),
    [KYCRefusalReason.INCOMPLETE]: __('ty:kyc:refusal-label:incomplete'),
    [KYCRefusalReason.UNSIGNED]: __('ty:kyc:refusal-label:unsigned'),
    [KYCRefusalReason.UNOFFICIAL]: __('ty:kyc:refusal-label:unofficial'),
    [KYCRefusalReason.INCORRECT]: __('ty:kyc:refusal-label:incorrect'),
    [KYCRefusalReason.OTHER]: __('ty:kyc:refusal-label:other'),
  }

  return trans[key] || undefined
}

function formatKYCRefusalReason(key, maximumAge) {
  const trans = {
    [KYCRefusalReason.EXPIRED]: __('ty:kyc:refusal-reason:expired', { months: maximumAge }),
    [KYCRefusalReason.UNREADABLE]: __('ty:kyc:refusal-reason:unreadable'),
    [KYCRefusalReason.INCOMPLETE]: __('ty:kyc:refusal-reason:incomplete'),
    [KYCRefusalReason.UNSIGNED]: __('ty:kyc:refusal-reason:unsigned'),
    [KYCRefusalReason.UNOFFICIAL]: __('ty:kyc:refusal-reason:unofficial'),
    [KYCRefusalReason.INCORRECT]: __('ty:kyc:refusal-reason:incorrect'),
  }

  return trans[key] || undefined
}

function formatKYCRefusalAsked(key) {
  const trans = {
    [KYCRefusalReason.EXPIRED]: __('ty:kyc:refusal-asked:expired'),
    [KYCRefusalReason.UNREADABLE]: __('ty:kyc:refusal-asked:unreadable'),
    [KYCRefusalReason.OTHER]: __('ty:kyc:refusal-asked:other'),
  }

  return trans[key] || undefined
}

function formatKYCRefusalMail(ownerType, name, fileName, reason, asked) {
  const trans = {
    [UserRole.CORPORATE]: __('ty:kyc:refusal-mail:corporate', { name, fileName, reason, asked }),
    [UserRole.FREELANCER]: __('ty:kyc:refusal-mail:freelancer', { name, fileName, reason, asked }),
  }

  return trans[ownerType] || undefined
}

export {
  formatKYCInfos,
  formatKYCRefusalAsked,
  formatKYCRefusalLabel,
  formatKYCRefusalMail,
  formatKYCRefusalReason,
  formatKYCStatus,
  formatKYCType,
  getKYCColor,
}
