<script>
  export const Skin = {
    LIGHT: 'light',
    DARK: 'dark',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Skin,
    name: 'WithSkin',
    __enums: {
      Skin,
    },
    props: {
      /**
       * Skin applied to all the HTML tree
       */
      skin: {
        type: String,
        required: true,
      },
      /**
       * Create this tag at the root of the component if needed, otherwise
       * the component will not render any html and just add a CSS class on the
       * root of the slot
       */
      tag: {
        type: String,
        default: null,
      },
    },
    mounted() {
      const { skin } = this

      this.$el.classList.add(`with-skin--${skin}`)
    },
    render(createElement) {
      const { tag } = this

      if (tag) {
        return createElement(tag, {}, [this.$slots.default])
      }

      return this.$slots.default[0]
    },
  }
</script>

<style lang="stylus" scoped>
  .with-skin {
    &--dark {
      --color-background: var(--color-grey-80)
      --color-font: var(--color-white)
      --color-button-secondary: var(--color-grey-70)
      --color-button-secondary-hover: var(--color-grey-60)
      --color-border: var(--color-grey-70)
    }

    &--light {
      --color-background: var(--color-white)
      --color-font: var(--color-black)
      --color-button-secondary: var(--color-grey-30)
    }
  }
</style>
