<template>
  <span
    class="icon"
    :class="{
      [`icon--theme-${theme}`]: true,
      [`icon--size-${size}`]: true,
    }"
    v-html="svgContent"
  />
</template>

<script>
  import { loadSvg } from '@/utils/image'

  const Size = {
    SMALL: 'small',
    MEDIUM: 'medium',
  }

  const Theme = {
    DEFAULT: 'default',
    WARNING: 'warning',
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    BRAND: 'brand',
    GREY: 'grey',
    WHITE: 'white',
    BLUE_DARK: 'blue-dark',
    YELLOW_ALT: 'yellow-alt',
  }

  export default {
/* Injected by the custom 'enums' Webpack plugin */ Theme,Size,
    name: 'Icon',
    __enums: {
      Theme,
      Size,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      theme: {
        type: String,
        default: Theme.DEFAULT,
      },
      size: {
        type: String,
        default: Size.MEDIUM,
      },
    },
    data() {
      return {
        /**
         * Svg content (from cache or from network)
         * @type{?String}
         */
        svgContent: null,
      }
    },
    watch: {
      name: {
        immediate: true,
        handler(name) {
          loadSvg(`icons/${name}`).then(svg => {
            this.svgContent = svg
          })
        },
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .icon {
    display: inline-flex
    align-items: center
    width: fit-content

    >>> svg {
      fill: currentColor
    }

    &--size-medium >>> svg {
      width: 1.4em !important
      height: 1.4em !important
    }

    &--theme {
      &-warning {
        color: var(--color-warning)
      }

      &-positive {
        color: var(--color-positive)
      }

      &-negative {
        color: var(--color-negative)
      }

      &-brand {
        color: var(--color-brand)
      }

      &-grey {
        color: var(--color-font-light)
      }

      &-white {
        color: var(--color-white)
      }

      &-blue-dark {
        color: var(--color-blue-80)
      }

      &-yellow-alt {
        color: var(--color-contrast-opaque)
      }
    }
  }
</style>
