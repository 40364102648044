<template>
  <Tag v-bind="$attrs" :class="$style.durationSkill" v-on="$listeners">
    <span
      v-if="isTeamMember && (linkedInEndorsements || corporateRatings.length)"
      v-tooltip="endorsementTooltip"
    >
      <Icon :theme="Icon.Theme.POSITIVE" :size="Icon.Size.SMALL" name="certification" />
    </span>
    <div :class="$style.durations">
      <Txt
        v-if="duration"
        :class="$style.duration"
        :size="durationSize"
        :theme="txtTheme"
        :value="formatMonthDuration(duration, true)"
      />
      <Icon
        v-else
        v-tooltip="__('cp:core:skill:no-experience')"
        :class="$style.icon"
        :theme="iconTheme"
        name="hourglass"
      />
      <Txt
        v-if="declaredDuration"
        :size="durationSize"
        :value="
          __('core:badge:skill:declarative', {
            date: formatMonthDuration(declaredDuration * 12, true),
          })
        "
      />
    </div>
  </Tag>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Tag from '@/core/badges/Tag/Tag'
  import Txt from '@/core/text/Txt/Txt'
  import Icon from '@/core/graphics/Icon/Icon'

  import { formatMonthDuration, formatHardSkillReview } from '@/utils/format'

  import I18nMixin from '@/mixins/I18nMixin'

  const { Size, Theme } = Tag

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Tag: Tag.__enums,
  Txt: Txt.__enums,
  Icon: Icon.__enums,
},

/* Injected by the custom 'enums' Webpack plugin */ Size,Theme,
    name: 'DurationSkill',
    __enums: {
      Size,
      Theme,
    },
    components: {
      Tag,
      Txt,
      Icon,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Amount of month the skill has been "trained"
       * Need to be in month !
       */
      duration: {
        type: Number,
        default: null,
      },
      /**
       * Amount of month the free declared he practised
       * Need to be in month !
       */
      declaredDuration: {
        type: Number,
        default: null,
      },
      /**
       * Endorsements received on LinkedIn for this skill
       */
      linkedInEndorsements: {
        type: Number,
        default: null,
      },
      /**
       * Endorsements received on comet platform for this skill
       */
      corporateRatings: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapGetters(['isTeamMember']),
      /**
       * Compute the size of the text accodring to the size given in attributes
       * to the tag
       */
      durationSize() {
        const { size } = this.$attrs

        switch (size) {
          case Tag.Size.S:
            return Txt.Size.XXXS
          case Tag.Size.M:
            return Txt.Size.XXS
          case Tag.Size.L:
            return Txt.Size.XS
          default:
            return Txt.Size.XXXS
        }
      },
      /**
       * Compute the theme of the text according to the theme
       * of the tag
       */
      txtTheme() {
        const { theme } = this.$attrs

        switch (theme) {
          case Tag.Theme.BLUE_DARK:
            return Txt.Theme.BLUE_DARK
          default:
            return Txt.Theme.DEFAULT
        }
      },
      /**
       * Compute the theme of the text according to the theme
       * of the tag
       */
      iconTheme() {
        const { theme } = this.$attrs

        switch (theme) {
          case Tag.Theme.BLUE_DARK:
            return Icon.Theme.BLUE_DARK
          default:
            return Icon.Theme.DEFAULT
        }
      },
      /**
       * Displays the endorsements the freelancer has received, on LinkedIn or the comet platform
       * @returns {String}
       */
      endorsementTooltip() {
        const { linkedInEndorsements, corporateRatings, __ } = this

        const endorsements = []
        if (linkedInEndorsements) {
          endorsements.push(__('cp:core:skill:linkedin-endorsements', { linkedInEndorsements }))
        }
        if (corporateRatings.length) {
          const corporateRatingsList = corporateRatings
            .map(corporateRating => formatHardSkillReview(corporateRating.rating))
            .join(' / ')
          endorsements.push(__('cp:core:skill:corporate-ratings', { corporateRatingsList }))
        }

        return endorsements.join('\n')
      },
    },
    methods: {
      formatMonthDuration,
    },
  }
</script>

<style lang="stylus" module>
  .durationSkill {
    padding-right: 3px

    & .durations {
      background-color: var(--color-white)
      border: none
      padding: 0 12px
      border-radius: 32px
      height: 20px
      margin: auto 0 auto 16px
    }

    & .duration {
      line-height: 20px
    }

    & .icon {
      font-size: 12px
    }
  }
</style>
