<template>
  <div :class="classes">
    <span
      v-if="text"
      class="text--pre-line"
      :class="$style.text"
      v-html="text"
    />

    <VTooltip
      v-if="tooltip"
      :max-width="360"
      :right="inline"
      :bottom="!inline"
    >
      <template v-slot:activator="{ on }">
        <slot
          v-if="$slots.remaining"
          name="ellipse"
          :count="remaining"
        />

        <span
          v-else
          class="text--pre-line secondary--text"
          :class="$style.remaining"
          v-on="on"
          v-html="`+${remaining}`"
        />
      </template>

      <span
        v-html="tooltip"
      />
    </VTooltip>
  </div>
</template>

<script>
  export default {
    name: 'CListEllipsed',
    props: {
      /**
       * Flat list of String to display (for 2 dimensions ellipse, see CListEllipsedDeep)
       * @type {Array.<String>}
       */
      items: {
        type: Array,
        required: true,
      },
      /**
       * Number of items contantly displayed. Other ones are ellipsed and displayed with a tooltip.
       * @type {Number}
       */
      limit: {
        type: Number,
        default: 5,
      },
      /**
       * Display visible items horizontally, instead of vertically, by default.
       * @type {Boolean}
       */
      inline: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * CSS classes applied to the root element
       * @type {Object}
       */
      classes() {
        const { inline, $style } = this

        return {
          [$style.root]: true,
          [$style.inline]: !!inline,
        }
      },
      /**
       * Computed text to display against the passed 'items' and 'limit' properties.
       * @type {String}
       */
      text() {
        const { items, limit, inline } = this

        if (!items) {
          return null
        }

        const extract = items.slice(0, limit)

        if (!extract || !extract.length) {
          return null
        }

        return inline
          ? extract.join(', ')
          : extract.join('\n')
      },
      /**
       * Computed text displayed in the tooltip, showed when focusing the ellipse label
       * @type {String}
       */
      tooltip() {
        const { items, limit, inline } = this

        if (!items) {
          return null
        }

        const extract = items.slice(limit)

        if (!extract || !extract.length) {
          return null
        }

        return inline
          ? extract.join(', ')
          : extract.join('\n')
      },
      /**
       * Number of really ellipsed items according to the given properties
       * @type {Number}
       */
      remaining() {
        const { items, limit } = this

        return items
          ? items.length - limit
          : 0
      },
    },
  }
</script>

<style lang="stylus" module>
  .root {
    .text,
    .remaining {
      display: block
    }

    &.inline {
      display: inline-block

      .text,
      .remaining {
        display: inline-block
      }

      .remaining {
        margin-left: 4px
      }
    }
  }
</style>
