<template>
  <div class="multiple-checkboxes">
    <Checkbox
      v-for="(option, i) in computedOptions"
      :key="i"
      :value="option.selected"
      :label="option.text"
      :sub-text="option.sub"
      :disabled="option.disabled"
      @input="onInput(option, $event)"
    />
  </div>
</template>

<script>
  import Checkbox from '@/core/inputs/Checkbox/Checkbox'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Checkbox: Checkbox.__enums,
},

    name: 'MultipleCheckboxes',
    components: {
      Checkbox,
    },
    props: {
      /**
       * Selected options
       * @type {Array.<String>}
       */
      value: {
        type: Array,
        default: () => [],
      },
      /**
       * All options
       * @type {Array.<{value: String, text: String, sub: String}>}
       */
      options: {
        type: Array,
        default: null,
      },
    },
    computed: {
      /**
       * Options with selected
       * @type {Object}
       */
      computedOptions() {
        const { options, value } = this

        return options.map(option => ({
          ...option,
          selected: option ? value.includes(option.value) : false,
        }))
      },
    },
    methods: {
      /**
       * Fired when any checkbox's value changes
       * @param {Object} optionLabel
       * @param {Boolean} value
       */
      onInput(option, selected) {
        const { value } = this
        const optionValue = option ? option.value : null

        if (optionValue) {
          const inSelection = value.includes(optionValue)

          if (selected && !inSelection) {
            value.push(optionValue)
          } else if (inSelection) {
            const index = value.indexOf(optionValue)
            if (index > -1) {
              value.splice(index, 1)
            }
          }

          this.$emit('input', value)
        }
      },
    },
  }
</script>
