<template>
  <Field class="switch-field" :class="classes" :label="label" :error="error" :disabled="disabled">
    <SwitchButton :value="value" :label="switchText" :disabled="disabled" @input="onInput">
      <slot v-if="$slots.default" />
      <slot v-else-if="value" name="on" />
      <slot v-else name="off" />
    </SwitchButton>
  </Field>
</template>

<script>
  import Field from '@/core/layout/Field/Field'
  import SwitchButton from '@/core/inputs/SwitchButton/SwitchButton'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  Field: Field.__enums,
  SwitchButton: SwitchButton.__enums,
},

    name: 'SwitchField',
    components: {
      Field,
      SwitchButton,
    },
    props: {
      /**
       * Field's value
       */
      value: {
        type: Boolean,
        required: true,
      },
      /**
       * Field's label
       */
      label: {
        type: String,
        required: true,
      },
      /**
       * Text to display next to the on-switch
       */
      text: {
        type: String,
        default: null,
      },
      /**
       * Text to display next to the off-switch
       */
      offText: {
        type: String,
        default: null,
      },
      /**
       * Field's error message, if there is one
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disable the field's behaviors if 'true'
       */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Classes to be applied to the switch
       * @returns {Object}
       */
      classes() {
        const { value } = this

        return {
          'switch-field--on': value,
          'switch-field--off': !value,
        }
      },
      /**
       * Text to display next to the switch
       * @type {String}
       */
      switchText() {
        const { value, text, offText } = this

        if (value || !offText) {
          return text
        }

        return offText
      },
    },
    methods: {
      /**
       * Fired when the switch value changes
       */
      onInput(value) {
        this.$emit('input', value)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .switch-field {
    .switch-button {
      margin: 10px 0
    }
  }
</style>
