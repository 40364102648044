import { mapGetters } from 'vuex'

import SnowplowEventTypes, { formatSnowplowEvent } from '@/types/snowplowEvent'

import eventsSubscription from './eventsSubscription.gql'

// Always use production icon as browsers can't use local assets for notifications.
const ICON = 'https://app.comet.co/static/img/icons/icon-384.png'

const { SnowplowEventType, SnowplowEventCategory, SnowplowTypeCategoryMapping } = SnowplowEventTypes

const EVENTS = [
  SnowplowEventType.ATTACHMENT_TEXT_ADDED,
  SnowplowEventType.ATTACHMENT_CALL_ADDED,
  SnowplowEventType.ATTACHMENT_EMAIL_ADDED,
  SnowplowEventType.ATTACHMENT_MEET_ADDED,
  SnowplowEventType.ATTACHMENT_LOG_ADDED,
  SnowplowEventType.ATTACHMENT_FILE_ADDED,

  SnowplowEventType.MISSION_CREATED,
  SnowplowEventType.MISSION_FINISHED,
  SnowplowEventType.MISSION_FINISH_UPDATED,

  // SnowplowEventType.MISSION_MATCH_DECLINED,
  SnowplowEventType.MISSION_MATCH_CONFIRMED_BY_FREELANCE,
  SnowplowEventType.MISSION_MATCH_CONFIRMED_BY_CLIENT,

  SnowplowEventType.MEETING_SLOTS_SET,
  SnowplowEventType.MEETING_SCHEDULED,
  SnowplowEventType.MEETING_RESCHEDULED,

  SnowplowEventType.DEAL_CREATED,
  SnowplowEventType.DEAL_SIGNING_STARTED,
  SnowplowEventType.DEAL_SIGNED,
  SnowplowEventType.DEAL_RENEWAL_DECLINED,
]

export default {
  computed: {
    ...mapGetters({
      teamMember: 'selectTeamMember',
      isTeamMember: 'isTeamMember',
    }),
  },
  created() {
    if (!this.isTeamMember) {
      return
    }

    // If not asked before, ask for html Notification permission
    if (Notification.permission === 'default') {
      Notification.requestPermission()
    }
  },
  apollo: {
    $subscribe: {
      newEvent: {
        query: eventsSubscription,
        variables() {
          return {
            teamMemberId: this.teamMember.id,
            eventTypes: EVENTS,
          }
        },
        result(res) {
          const { formatNotification } = this
          const { snowplowEvent } = res.data

          if (Notification.permission !== 'granted') {
            return
          }

          const notificationPayload = formatNotification(snowplowEvent)
          if (!notificationPayload) {
            return null
          }

          const { title, onclick, ...options } = notificationPayload
          const notification = new Notification(title, options)

          notification.onclick = onclick
        },
        skip() {
          return !this.isTeamMember
        },
      },
    },
  },
  methods: {
    formatNotification(snowplowEvent) {
      const contextKeys = {
        missions: event => `${event.mission.title} · #${event.mission.id}`,
        freelances: event => `${event.freelance.user.fullName} · @${event.freelance.id}`,
        corporates: event => `${event.corporate.user.fullName} · @${event.corporate.id}`,
        teamMembers: event => `${event.teamMember.user.fullName} · @${event.teamMember.id}`,
      }

      const contextKey = Object.keys(contextKeys).find(key => {
        return !!snowplowEvent[key] && !!snowplowEvent[key].length
      })

      const formatter = contextKeys[contextKey]
      if (!formatter) {
        return null
      }

      const context = snowplowEvent[contextKey][0]
      const body = SnowplowTypeCategoryMapping[SnowplowEventCategory.ATTACHMENT].includes(
        snowplowEvent.type,
      )
        ? `${snowplowEvent.author.firstName} : "${snowplowEvent.attachments[0]?.attachment.plainTextWithMentions}"`
        : formatSnowplowEvent(snowplowEvent)

      return {
        title: formatter(context),
        tag: snowplowEvent.id,
        icon: ICON,
        body,
        onclick() {
          window.open(context.url, '_blank').focus()
        },
      }
    },
  },
}
