<template>
  <div>
    <Field :label="__('cp:fields:vat-field:submitted-to-vat:label')" class="mb-6">
      <div class="d-flex">
        <SwitchButton :value="hasVat" :disabled="disabled" @input="onVatSwitch" />
        <i
          v-if="individualCompany"
          v-tooltip="__('cp:fields:vat-field:tooltip')"
          class="icon-info"
        />
      </div>
    </Field>

    <TextField
      class="vat-field"
      :label="label || __('cp:fields:vat-field:label')"
      :disabled="disabled"
      :placeholder="placeholder"
      :value="value"
      :error="error"
      :helper="__('cp:fields:vat-field:helper', { number: deducedNumber })"
      @input="onVatValueInput"
    />
  </div>
</template>

<script>
  import _padStart from 'lodash/padStart'

  import SwitchButton from '@/core/inputs/SwitchButton/SwitchButton'
  import TextField from '@/core/fields/TextField/TextField'
  import Field from '@/core/layout/Field/Field'

  import I18nMixin from '@/mixins/I18nMixin'

  export default {

/* Injected by the custom 'enums' Webpack plugin */
__childrenEnums : {
  SwitchButton: SwitchButton.__enums,
  TextField: TextField.__enums,
  Field: Field.__enums,
},

    name: 'VatField',
    components: {
      SwitchButton,
      TextField,
      Field,
    },
    mixins: [I18nMixin],
    props: {
      /**
       * Optional label
       */
      label: {
        type: String,
        default: null,
      },
      /**
       * Optionnal text if not vat number
       */
      noVatLabel: {
        type: String,
        default: null,
      },
      /**
       * True if user can have a vatNumber
       */
      hasVat: {
        type: Boolean,
        default: false,
      },
      /**
       * Vat number value
       */
      vatNumber: {
        type: String,
        default: null,
      },
      /**
       * True if belongs to an "individual" company (AE/EI)
       */
      individualCompany: {
        type: Boolean,
        default: false,
      },
      /**
       * Error
       */
      error: {
        type: String,
        default: null,
      },
      /**
       * Disabled
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Company SIREN number
       */
      siren: {
        type: String,
        default: null,
      },
    },
    computed: {
      /**
       * Placeholder displayed only if company is individual
       * @type {String}
       */
      placeholder() {
        const { __, individualCompany } = this

        return individualCompany ? __('cp:fields:vat-field:placeholder') : null
      },
      /**
       * Value to display in the field
       * @type {String}
       */
      value() {
        const { vatNumber } = this

        return vatNumber
      },
      /**
       * VAT number for french companies, deduced from siren
       * @type {String}
       */
      deducedNumber() {
        const { siren } = this

        if (!siren) {
          return null
        }

        const vatKey = siren.length === 9 ? _padStart((12 + 3 * (siren % 97)) % 97, 2, '0') : 'XX'

        return `FR${vatKey}${siren}`
      },
    },
    methods: {
      /**
       * Update the hasVat value
       * @param {Boolean}
       */
      onVatSwitch(value) {
        const { deducedNumber, vatNumber } = this

        this.$emit('update:hasVat', value)
        this.$emit('update:vatNumber', !vatNumber && value ? deducedNumber : vatNumber)
      },
      /**
       * Update vat number value on input
       * @param  {String}
       */
      onVatValueInput(value) {
        this.$emit('update:vatNumber', value)
      },
    },
  }
</script>

<style lang="stylus" scoped>
  .vat-field {
    &-info {
      display: flex
      flex: 1
      justify-content: right
    }

    /* We replace the label’s padding by margin (cf. TextField)
    or else it stretches the field */
    >>> label {
      align-items: center
      padding-bottom: 0
      margin-bottom: 8px
    }

    label {
      display: flex
      align-items: center
      height: 21px
      margin-bottom: 6px

      i {
        margin-left: 8px
        font-size: 14px
      }

      .switch-button {
        margin: 1px 0 0 15px
      }

      & + span {
        display: flex
        margin-top: 15px
      }
    }

  }
</style>
