<script>
  import { mapActions } from 'vuex'

  import AppTypes from '@/types/app'
  import FreelancerTypes from '@/types/freelancer'

  import query from './query.gql'

  const { LinkedInImportStatus } = FreelancerTypes
  const { DOING, DONE, FAILED, NONE } = LinkedInImportStatus

  const POLLING_RATE = 5 * 1000 // 3 sec
  const POLLING_TIMEOUT = 6 * 60 * 1000 // 6 min

  export default {
    /**
     * LinkedInMixin is made to poll on Linkedin profile import state,
     * display toast messages depending on success / error and stop polling
     * when Linkedin import is not in progress, is done or is failed
     */
    data() {
      return {
        /**
         * Current freelancer (fetched by Apollo)
         * @type {Boolean}
         */
        freelancerForLinkedInFetchingStatus: false,
      }
    },
    created() {
      /**
       * Timeout used to stop the Apollo query polling, as the 'update' and 'result'
       * handlers are not called until the received response is the same.
       * So a 'setTimeout' is the only way to avoid polling endlessly.
       * @type {Number}
       */
      this.tmo = null
    },
    apollo: {
      freelancerForLinkedInFetchingStatus: {
        query,
        variables() {
          return { id: this.$store.getters?.selectFreelancerId }
        },
        update(data) {
          return data.freelance
        },
        result(response) {
          const { addMessage } = this
          const { __ } = this.$i18n

          const query = this.$apollo.queries?.freelancerForLinkedInFetchingStatus
          const status = response?.data?.freelance?.lastLinkedInImport?.status
          const fetching = response?.data?.freelance?.fetchingLinkedIn

          if (fetching || status === DOING) {
            if (!this.tmo) {
              query?.startPolling(POLLING_RATE)

              this.tmo = setTimeout(() => {
                query?.stopPolling()
                clearTimeout(this.tmo)
                this.tmo = null
              }, POLLING_TIMEOUT)
            }

            return
          }

          query?.stopPolling()
          clearTimeout(this.tmo)
          this.tmo = null

          if (status === DONE) {
            addMessage({
              type: AppTypes.MessageType.SUCCESS,
              message: __('co:linkedin-import:success'),
            })
            return
          }

          if (status === FAILED) {
            addMessage({
              type: AppTypes.MessageType.ERROR,
              message: __('co:linkedin-import:error'),
            })
            return
          }
        },
        skip() {
          const id = this.$store.getters?.selectFreelancerId
          const status = this.$store.getters?.selectFreelancer?.lastLinkedInImport?.status

          return !id || !status || [NONE, DONE, FAILED].includes(status)
        },
        fetchPolicy: 'network-only',
      },
    },
    methods: {
      ...mapActions(['addMessage']),
    },
  }
</script>
