import * as components from './library'

const VueComet = {
  install(Vue) {
    // Expose globally all the core components
    Object
      .keys(components)
      .forEach((name) => {
        Vue.component(name, components[name])
      })
  },
}

export default VueComet
