const icons = {}

function importAll(r) {
  r.keys().forEach(key => (icons[key] = r(key)))
}

if (require && require.context) {
  importAll(require.context('@/assets/images/', true, /\.svg$/))
}

/**
 * Check if icon is from the old system (starting with 'icon-') or the new one
 * @param   {String} name
 * @returns {Boolean}
 */
function iconIsOld(name) {
  return name ? name.startsWith('icon-') : false
}

/**
 * Lazy load svg from cache or network
 * @async
 * @param {String} path
 * @returns {Promise<?String>}
 */
async function loadSvg(path) {
  if (!path) {
    return null
  }

  let filePath = icons[`./${path}.svg`]
  if (!filePath) {
    const res = await import(`@/assets/images/${path}.svg`)

    // Get the file name from webpack (with the hash,
    // for a correct cache strategy)
    filePath = res.default
  }

  // Try to get it from the cache
  const cacheKey = `svg:${filePath}`
  const cache = sessionStorage.getItem(cacheKey)
  if (cache) {
    return cache
  }

  // If it is not in the cache, download it.
  const svg = await fetch(filePath).then(r => r.text())

  // And cache it.
  if (svg) {
    sessionStorage.setItem(cacheKey, svg)
  }

  return svg
}

export { iconIsOld, loadSvg }
